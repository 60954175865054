import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import PopupChange from "features/prescriptionForm/components/prescriptionPopupChange";

import right_arrow from "assets/icons/right_arrow.svg";
import { useHistory } from "react-router-dom";
import DurationSlider from "features/prescriptionForm/components/prescriptionPopupChange/DurationSlider";
import { useTranslation } from "react-i18next";

interface TestPageTreatmentProps {
  weeks: number;
}

const TestPageTreatment: React.FC<TestPageTreatmentProps> = ({ weeks }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Flex
      w="full"
      direction="column"
      align="center"
      justify="center"
      gap="120px"
    >
      <Flex w="full" direction="column" align="center">
        <Heading fontSize={{ base: "24px", sm: "36px" }} textAlign="center">
          {t("Thank you for completing the test.")}
        </Heading>
        <Heading fontSize={{ base: "24px", sm: "36px" }} textAlign="center">
          {t("Here is the treatment plan we suggest for you:")}
        </Heading>
      </Flex>

      <Flex w="full" align="center" justify="center" my="-2rem">
        <Text color="blueish.500" fontSize="16px" fontWeight={700} my="-2rem">
          {weeks} {t("WEEKS LONG")}
        </Text>
      </Flex>

      <Box w="100%">
        <DurationSlider
          min={12}
          max={56}
          defaultValue={weeks ?? 0}
          selectedPlan={weeks}
          onChange={() => {}}
          isAdjustable={false}
        />
      </Box>

      <Flex
        w="full"
        gap="20px"
        direction={{ base: "column", sm: "row" }}
        align={{ base: "none", sm: "center" }}
        justify="center"
      >
        <Button
          variant="adjust"
          onClick={() => history.push("/")}
          minH={{ base: "56px" }}
          fontSize={{ base: "16px" }}
        >
          {t("Learn more")}
        </Button>
        <Button
          gap="13px"
          minH={{ base: "56px" }}
          onClick={() => history.push("/login")}
          fontSize={{ base: "16px" }}
        >
          {t("Continue")}
          <Image src={right_arrow} />
        </Button>
      </Flex>
    </Flex>
  );
};

export default TestPageTreatment;
