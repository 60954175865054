import { Variants, motion, useAnimation, useInView } from "framer-motion";
import React, { ReactNode, useLayoutEffect, useRef } from "react";

type AnimatedBoxProps = {
  children: ReactNode;
  fromLeft?: boolean;
};

const AnimatedBox: React.FC<AnimatedBoxProps> = ({ children, fromLeft }) => {
  const controls = useAnimation();
  const boxRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(boxRef);

  const variants: Variants = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: fromLeft ? -50 : 50 },
  };

  useLayoutEffect(() => {
    if (isInView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, isInView]);

  return (
    <motion.div
      ref={boxRef}
      initial="hidden"
      animate={controls}
      variants={variants}
      transition={{ duration: 0.6 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedBox;
