import { defineStyleConfig } from "@chakra-ui/react";

export const Heading = defineStyleConfig({
  baseStyle: {
    fontFamily: "secondary",
  },

  sizes: {
    xxs: {
      fontSize: 'xs'
    },
    sm: {
      fontSize: "lg",
    },
    md: {
      fontSize: "xl",
    },
    lg: {
      fontSize: "xxl",
    },
    xl: {
      fontSize: "3xl",
    },
  },

  variants: {
    light: {
      fontSize: "lg",
      color: "white",
      textAlign: "left",
      lineHeight: "tall",
    },
    dark: {
      color: "#002637",
      textAlign: "left",
      lineHeight: "tall",
    },
  },
  defaultProps: {
    variant: "dark",
    size: 'sm'
  },
});
