import { FunctionComponent } from "react";
import { Button, CircularProgress, Flex } from "@chakra-ui/react";
import { AiOutlineDownload } from "react-icons/ai";
import { useFetchCSVFileFromServer } from "features/taperingCSV/api/client";
import { useTranslation } from "react-i18next";

interface DownloadButtonProps {
  onClick?: (
    medicineName: string,
    percentage: number,
    pharmacy: string
  ) => void;
  medicineName: string;
  percentage: number;
  pharmacy: string;
}

const DownloadCSVButton: FunctionComponent<DownloadButtonProps> = ({
  onClick,
  medicineName,
  percentage,
  pharmacy,
}) => {
  const { t } = useTranslation();

  const handleDownload = () => {
    if (onClick) {
      onClick(medicineName, percentage, pharmacy);
    }
  };

  const { mutate, isValidating } = useFetchCSVFileFromServer(
    medicineName,
    percentage,
    pharmacy
  );

  const downloadCSV = async () => {
    const data = await mutate();

    if (data) {
      const blob = new Blob([data], { type: "application/octet-stream" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${medicineName}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <Button
      mt="3 !important"
      variant={"ghost"}
      rightIcon={<AiOutlineDownload />}
      fontWeight={"400"}
      fontSize="1rem"
      onClick={() => {
        handleDownload();
        downloadCSV();
      }}
    >
      {isValidating ? (
        <Flex align="center">
          <CircularProgress
            size="20px"
            color="rgba(88, 154, 175, 1)"
            marginRight="2"
          />
          {t("Loading")}...
        </Flex>
      ) : (
        `${t("Download CSV")}`
      )}
    </Button>
  );
};

export default DownloadCSVButton;
