import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Image,
  List,
  ListIcon,
  ListItem,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from "@chakra-ui/react";

import app_store_1 from "../../../assets/icons/app_store_1.svg";
import google_play from "../../../assets/images/google-play-badge.svg";

import HomeIcon from "ui/iconComponents/HomeIcon";
import DashboardIcon from "ui/iconComponents/DashboardIcon";
import SettingsIcon from "ui/iconComponents/SettingsIcon";
import LogoutIcon from "ui/iconComponents/Logout";

import closeIcon from "assets/icons/closeIcon.svg";
import logo from "assets/images/logo.svg";
import { useLogOut } from "features/login/api/client";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
type ISidebarMobileViewProps = {
  onClose: () => void;
};

const SidebarMobileDrawer = ({ onClose }: ISidebarMobileViewProps) => {
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const { trigger } = useLogOut();

  const logOutFunction = () => {
    trigger();
    history.push("/");
  };
  return (
    <Flex
      justify={"space-between"}
      align={"center"}
      w={"100%"}
      p={"2%"}
      pt={0}
      direction="column"
    >
      <Flex mt={"0.5rem"} justify="space-between" w="100%">
        <Image
          src={logo}
          alt="Lamina Clinic logo"
          w={"calc(150px + 9%)"}
          minW={"100px"}
        />

        {/*TODO: uncomment language switch */}
        {/* <Flex ml={"4rem"} mt={"0.6rem"}>
          <Popover arrowShadowColor="transparent">
            <PopoverTrigger>
              <Button variant="ghost" color={"white"}>
                {i18n.language.toUpperCase()}
              </Button>
            </PopoverTrigger>
            <PopoverContent
              zIndex={4}
              bg={"#589AAF"}
              width={"80px"}
              border={"none"}
            >
              <PopoverArrow
                backgroundColor={"#589AAF"}
                border="none !important"
              />
              <PopoverBody
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Button
                  onClick={() => i18n.changeLanguage("en")}
                  variant="ghost"
                  color={"white"}
                >
                  EN
                </Button>
                <Button
                  onClick={() => i18n.changeLanguage("sv")}
                  variant="ghost"
                  color={"white"}
                >
                  SV
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex> */}

        <Image src={closeIcon} alt={"close icon"} onClick={onClose} />
      </Flex>
      {/* CONTENT */}
      <VStack w={"100%"} py={"7rem"} gap={"2.5rem"}>
        <Flex w="50%" justify="flex-start" align="flex-start">
          <List spacing={8}>
            <ListItem>
              <Flex align="center" gap={1}>
                <ListIcon>
                  <HomeIcon />
                </ListIcon>
                <Link to="/">
                  <Text
                    fontSize={"28px"}
                    fontWeight={location.pathname === "/" ? "bold" : "normal"}
                  >
                    {t("Home")}
                  </Text>
                </Link>
              </Flex>
            </ListItem>
            <ListItem>
              <Flex align="center" gap={1}>
                <ListIcon>
                  <DashboardIcon />
                </ListIcon>
                <Link to="/dashboard">
                  <Text
                    fontSize={"28px"}
                    fontWeight={
                      location.pathname === "/dashboard" ? "bold" : "normal"
                    }
                  >
                    {t("Dashboard")}
                  </Text>
                </Link>
              </Flex>
            </ListItem>
            {/*  <ListItem>
              <Flex align="center" gap={1}>
                <ListIcon>
                  <SettingsIcon />
                </ListIcon>
                <Link to="/settings">
                  <Text
                    fontSize={"28px"}
                    fontWeight={
                      location.pathname === "/settings" ? "bold" : "normal"
                    }
                  >
                    {t("Settings")}
                  </Text>
                </Link>
              </Flex>
            </ListItem> */}
            <ListItem>
              <Flex align="center" gap={1}>
                <ListIcon>
                  <LogoutIcon />
                </ListIcon>
                <Text
                  fontSize={"28px"}
                  fontWeight={
                    location.pathname === "/logout" ? "bold" : "normal"
                  }
                  onClick={logOutFunction}
                >
                  {t("Logout")}
                </Text>
              </Flex>
            </ListItem>
          </List>
        </Flex>
        <Box
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          gap={3}
        >
          <Image
            alt={"App store app link"}
            src={app_store_1}
            minW={"200px"}
            cursor={"pointer"}
          />
          <Image
            alt={"Play Market app link"}
            src={google_play}
            minW={"200px"}
            cursor={"pointer"}
          />
        </Box>
      </VStack>
    </Flex>
  );
};

export default SidebarMobileDrawer;
