import {
  Flex,
  Center,
  Heading,
  FormControl,
  FormLabel,
  Image,
  Text,
  Input,
  FormErrorMessage,
  Button,
  Box,
} from "@chakra-ui/react";
import logo_group from "assets/icons/logo_group.svg";
import LogInViaBankId from "features/login/components/LogInViaBankId/LogInViaBankId";
import StaffBankIDAuth from "features/staffCommons/components/StaffBankIDAuth";
import StaffBankidWrapped from "features/staffCommons/components/StaffBankIDAuthWrapped";
import { t } from "i18next";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import PasswordInput from "ui/components/PasswordInput";

interface StaffLoginProps {}

const StaffLogin: FunctionComponent<StaffLoginProps> = () => {
  return (
    <Flex
      w="full"
      direction="column"
      align="center"
      justify="center"
      mt={{ base: "-3rem", sm: "0rem" }}
    >
      <Image src={logo_group} />

      <Center
        flexDirection="column"
        w="full"
        py={{ base: ".5rem", sm: "", md: "4rem", lg: "4rem" }}
        px={{ base: "0rem", md: "4rem" }}
      >
        <Heading
          color="rgba(0, 38, 55, 1)"
          fontWeight={600}
          fontSize="48px"
          letterSpacing={1.6}
        >
          {t("Welcome")}!
        </Heading>
        <Text
          fontFamily="secondary"
          color="rgba(0, 38, 55, 0.4)"
          fontWeight={400}
          fontSize="16px"
        >
          {t("Please login")}.
        </Text>

        <Box
          w="full"
          py={{ base: "1rem", sm: "2rem", md: "", lg: "2.5rem" }}
          px={{ base: "1rem", sm: "10rem", md: "9.5rem", lg: "16.9rem" }}
        >
          <StaffBankidWrapped />
        </Box>
      </Center>
    </Flex>
  );
};

export default StaffLogin;
