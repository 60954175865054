import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { CalendarEvent } from "./WorkingCalendarWidget";
import {
  useCancelCalendarMeeting,
  useResheduleCalendarMeeting,
} from "features/superadmin/api/client";

import { forwardRef, useState } from "react";

import CalendlyEventModal from "./CalendlyEventModal";
import { useTranslation } from "react-i18next";
import { GiChoice } from "react-icons/gi";
import { Link, useHistory } from "react-router-dom";

type CalendarEventPopoverProps = {
  selectedEvent: CalendarEvent | null;
  setSelectedEvent: (event: CalendarEvent | null) => void;
  meetingId?: string;
  personelRole: string;
};

const CalendarEventModal = (
  {
    setSelectedEvent,
    meetingId,
    selectedEvent,
    personelRole,
  }: CalendarEventPopoverProps,
  ref: any
) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [calendlyUrl, setCalendlyUrl] = useState<string | undefined>("");
  const { trigger: cancelEvent, isMutating: isCancelMutating } =
    useCancelCalendarMeeting(meetingId || "");
  const { trigger: resheduleEvent, isMutating: isRescheduleMutating } =
    useResheduleCalendarMeeting(meetingId || "");

  const calendarModalIsOpen = !!selectedEvent && !!meetingId;

  const handleActionClick = (action: "cancel" | "reschedule") => {
    if (action === "cancel") {
      cancelEventAction();
    } else if (action === "reschedule") {
      resheduleEventAction();
    }
  };

  const cancelEventAction = () => {
    cancelEvent().then((response) => {
      if (response && response.link) {
        setCalendlyUrl(response.link);
      }
    });
  };

  const resheduleEventAction = () => {
    resheduleEvent().then((response: any) => {
      if (response && response.link) {
        setCalendlyUrl(response.link);
      }
    });
  };

  const handleEventGoToUserPage = (e: any) => {
    if (selectedEvent?.userId) {
      if (personelRole === "doctor") {
        history.push(`/doctor/${selectedEvent.userId}`);
      } else if (personelRole === "nurse") {
        history.push(`/nurse/patients/${selectedEvent.userId}`);
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={calendarModalIsOpen}
        onClose={() => setSelectedEvent(null)}
        initialFocusRef={ref}
        isCentered
      >
        <ModalOverlay />
        <ModalContent p={3} gap={2}>
          <ModalCloseButton />
          <ModalBody gap={7}>
            <Flex w="full" align="center" justify="center" gap={3}>
              <Text color="blueish.500" fontWeight={300} letterSpacing={0.7}>
                Mötesdetaljer
              </Text>
            </Flex>

            <Text
              mt="2"
              color="blackish.200"
              textAlign="center"
              fontSize="1rem"
              fontWeight={300}
            >
              {selectedEvent?.title}
            </Text>

            <Flex
              w="full"
              gap={5}
              align="center"
              justify="space-between"
              mt="5"
            >
              <Flex gap={"0.5rem"}>
                <Button
                  variant="edit"
                  fontWeight={300}
                  fontFamily="main"
                  onClick={() => handleActionClick("cancel")}
                  borderColor="#589AAF"
                  color="#589AAF"
                  _hover={{
                    transform: "scale(1.05)",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                    transition:
                      "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    backgroundColor: "#589AAF",
                    color: "white",
                  }}
                >
                  {isCancelMutating ? "Laddning..." : "Avboka"}
                </Button>
                <Button
                  variant="edit"
                  fontWeight={300}
                  fontFamily="main"
                  onClick={() => handleActionClick("reschedule")}
                  borderColor="#589AAF"
                  color="#589AAF"
                  _hover={{
                    transform: "scale(1.05)",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                    transition:
                      "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    backgroundColor: "#589AAF",
                    color: "white",
                  }}
                >
                  {isRescheduleMutating ? "Laddning..." : "Boka om"}
                </Button>
              </Flex>

              <Button
                fontSize="1rem"
                cursor="pointer"
                variant={"black"}
                fontWeight={600}
                onClick={handleEventGoToUserPage}
              >
                Till patientsidan
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <CalendlyEventModal
        isOpen={!!calendlyUrl}
        calendlyUrl={calendlyUrl}
        onClose={() => {
          setCalendlyUrl(undefined);
        }}
      />
    </>
  );
};

export default forwardRef(CalendarEventModal);
