import { Hide, Show, VStack } from "@chakra-ui/react";
import { FunctionComponent } from "react";

import GeneralNavbar from "../Navbar/GeneralNavbar/GeneralNavbar";
import GeneralFooter from "features/general/components/generalFooter/GeneralFooter";
import GeneralNavbarMobile from "../Navbar/GeneralNavbarMobile/GeneralNavbarMobile";

interface DefaultLayoutProps {
  children?: React.ReactNode[] | React.ReactNode;
  fullWidth?: boolean;
}

const GeneralLayout: FunctionComponent<DefaultLayoutProps> = ({
  children,
  fullWidth,
}) => {
  return (
    <VStack
      alignItems={"center"}
      justifyContent={"flex-start"}
      w={"100% !important"}
      minH={"100vh !important"}
      position={"relative"}
      bg="white"
      spacing={0}
      overflow="hidden"
    >
      <Show below="sm">
        <GeneralNavbarMobile />
      </Show>

      <Hide below="sm">
        <GeneralNavbar />
      </Hide>
      <VStack
        width={"100%"}
        justify="flex-start"
        align="center"
        maxWidth={{
          base: "100%",
          sm: "1250px",
          lg: "1680px",
        }}
        px={{ base: "3%", sm: "5%" }}
        flexGrow={"1"}
        pb={"6vh !important"}
      >
        <VStack
          width={fullWidth ? "100%" : { base: "98%", sm: "100%" }}
          flexGrow={"1"}
          pb={"0 !important"}
          justifyContent={"stretch"}
        >
          {children}
        </VStack>
      </VStack>
      <GeneralFooter />
    </VStack>
  );
};

export default GeneralLayout;
