import EditFormByPersonel from "features/editProfile/components/EditFormByPersonel";
import { FunctionComponent } from "react";

const EditProfileByPersonel: FunctionComponent = () => {
  return (
    <>
      <EditFormByPersonel />
    </>
  );
};

export default EditProfileByPersonel;
