import { Box } from "@chakra-ui/react";
import MemoizedMobileTypeform from "features/mobileTypeformHelper/components/MemoizedMobileTypeform";
import { FunctionComponent } from "react";

interface TypeformMobilePasserProps {}

const TypeformMobilePasser: FunctionComponent<
  TypeformMobilePasserProps
> = () => {
  return (
    <Box h={"100vh"} bg={'white'}>
      <MemoizedMobileTypeform />
    </Box>
  );
};

export default TypeformMobilePasser;
