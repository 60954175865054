import { Button, Flex, Heading, Image, Text, VStack } from "@chakra-ui/react";

import clock from "assets/icons/clock.svg";
import webinar_vector from "assets/icons/webinar_vector.svg";
import wood_mobile from "assets/images/wood_mobile.png";

const WebinarHeroMobile = () => {
  return (
    <Flex w="full" direction="column" position="relative">
      <Image
        src={wood_mobile}
        position="absolute"
        right="-4vw"
        left="-3vw"
        w={"100vw"}
        overflow={"hidden"}
        maxW={"none"}
        minW={"200px"}
      />

      <VStack
        w="full"
        align="center"
        justify="center"
        zIndex={1}
        position="relative"
        mt="10rem"
        top={160}
      >
        <Flex align="center" gap="13px">
          <Image src={clock} mb="3px" />
          <Text
            color="blueish.500"
            fontSize="16px"
            fontWeight={400}
            fontFamily="secondary"
          >
            TILLGÄNGLIGT FRÅN
          </Text>
          <Text
            color="blueish.500"
            fontSize="16px"
            fontWeight={600}
            fontFamily="secondary"
          >
            27 JUNI 19:00
          </Text>
        </Flex>

        <Flex
          w="full"
          align="center"
          justify="center"
          direction="column"
          gap="48px"
        >
          <Heading fontSize="40px" px="20px" textAlign="center">
            Webbinarium om nedtrappning av antidepressiva
          </Heading>
          {/* <Button
            as="a"
            href="https://zoom.us"
            target="_blank"
            minWidth="300px"
            minHeight="56px"
            color="rgba(255, 255, 255, 1)"
            bg="rgba(88, 154, 175, 1)"
            _hover={{ bg: "rgba(0, 38, 55, 1)" }}
            border="none"
            fontSize="16px"
          >
            Klicka för att starta webbinariet
            <Image src={webinar_vector} ml="1rem" />
          </Button> */}
        </Flex>
      </VStack>
    </Flex>
  );
};

export default WebinarHeroMobile;
