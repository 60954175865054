import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Notification } from "features/notifications/api/types";
import { formatDateTime } from "services/helpers/dateHelpers";

type NotificationWidgetProps = {
  noticationInfo: Notification;
  rolePath: string;
};

type MoodValue = {
  value: number;
  color: string;
};

const moodValue: MoodValue[] = [
  { value: 1, color: "#43B3BA" },
  { value: 2, color: "#589AAF" },
  { value: 3, color: "#568CB2" },
  { value: 4, color: "#647CAD" },
  { value: 5, color: "rgba(121, 106, 159, 1)" },
  { value: 6, color: "rgba(139, 86, 135, 1)" },
  { value: 7, color: "rgba(148, 67, 102, 1)" },
];

const getMoodColor = (value: number): string => {
  const mood = moodValue.find((mood) => mood.value === value);
  return mood ? mood.color : "gray";
};

const NotificationWidgetItem: React.FC<NotificationWidgetProps> = ({
  rolePath,
  noticationInfo,
}) => {
  const { t } = useTranslation();

  return (
    <Box w="full">
      <Link to={`${rolePath}/${noticationInfo.working_id}`}>
        <Flex
          align="stretch"
          transition="transform 0.2s, box-shadow 0.2s"
          _hover={{
            transform: "scale(1.02)",
            boxShadow: "lg",
          }}
        >
          <Text
            color="black"
            textAlign="left"
            w="28%"
            bg="rgba(88, 154, 175, 0.2)"
            p="2"
            fontSize="1.2rem"
            fontWeight={500}
            borderLeftRadius="0.5rem"
          >
            {noticationInfo.title}
          </Text>
          <Text
            color="black"
            textAlign="left"
            w="24%"
            bg="rgba(88, 154, 175, 0.2)"
            p="2"
            fontSize="1.2rem"
            fontWeight={500}
          >
            {noticationInfo.patient_first_name}{" "}
            {noticationInfo.patient_last_name}
          </Text>

          {/* MOOD */}
          <Flex
            w="35%"
            bg="rgba(88, 154, 175, 0.2)"
            p="2"
            gap="1.5"
            align="stretch"
            flexWrap={"wrap"}
          >
            {noticationInfo.mood && noticationInfo.mood.value && (
              <Text
                color="white"
                fontSize="1rem"
                p="1"
                bg={getMoodColor(noticationInfo.mood.value)}
                fontWeight={500}
                borderRadius="50%"
                width="2rem"
                height="2rem"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {noticationInfo.mood.value}
              </Text>
            )}

            {noticationInfo.mood &&
              !!noticationInfo.mood.comments_argent &&
              noticationInfo.mood.comments_argent.map((urgent, index) => (
                <Text
                  key={`urgent-${index}`}
                  color="rgba(213, 115, 138, 1)"
                  borderRadius="0.5rem"
                  bg="rgba(213, 115, 138, 0.3)"
                  p="1"
                  fontSize="1rem"
                  fontWeight={500}
                >
                  {urgent}
                </Text>
              ))}
            {noticationInfo.mood &&
              !!noticationInfo.mood.comments &&
              noticationInfo.mood.comments.slice(0, 3).map((normal, index) => (
                <Text
                  key={`normal-${index}`}
                  color="blueish.500"
                  fontSize="1rem"
                  fontWeight={500}
                  p="1"
                  borderRadius="0.5rem"
                  bg="rgba(67, 179, 186, 0.2)"
                >
                  {normal.toLowerCase()}
                </Text>
              ))}
          </Flex>

          <Text
            color="black"
            w="13%"
            bg="rgba(88, 154, 175, 0.2)"
            p="2"
            fontSize="1rem"
            fontWeight={500}
            borderRightRadius="0.5rem"
          >
            {formatDateTime(noticationInfo.created_at, t("locale" ?? ""))}
          </Text>
        </Flex>
      </Link>
    </Box>
  );
};

export default NotificationWidgetItem;
