import { Box } from "@chakra-ui/react";

type BoxWithStatusColorProps = {
  status: string;
  children: React.ReactNode;
};

const BoxWithStatusColor: React.FC<BoxWithStatusColorProps> = ({
  status,
  children,
}) => {
  const statusStyles: Record<
    BoxWithStatusColorProps["status"],
    { color: string; backgroundColor: string }
  > = {
    premium: {
      color: "#8568A1",
      backgroundColor:
        "linear-gradient(0deg, rgba(133, 104, 161, 0.20) 0%, rgba(133, 104, 161, 0.20) 100%), #FFF",
    },
    registered: {
      color: "#43B3BA",
      backgroundColor:
        "linear-gradient(0deg, rgba(67, 179, 186, 0.20) 0%, rgba(67, 179, 186, 0.20) 100%), #FFF",
    },
    meeting_with_nurse: {
      color: "#647CAD",
      backgroundColor:
        "linear-gradient(0deg, rgba(100, 124, 173, 0.20) 0%, rgba(100, 124, 173, 0.20) 100%), #FFF",
    },
    treatment_completed: {
      color: "#2C8475",
      backgroundColor:
        "linear-gradient(0deg, rgba(44, 132, 117, 0.20) 0%, rgba(44, 132, 117, 0.20) 100%), #FFF",
    },
    on_treatment: {
      color: "#E89B26",
      backgroundColor:
        "linear-gradient(0deg, rgba(232, 155, 38, 0.20) 0%, rgba(232, 155, 38, 0.20) 100%), #FFF",
    },
    prescription_approved: {
      color: "#619350",
      backgroundColor:
        "linear-gradient(0deg, rgba(97, 147, 80, 0.20) 0%, rgba(97, 147, 80, 0.20) 100%), #FFF",
    },
    prescription_reject: {
      color: "#D5738A",
      backgroundColor:
        "linear-gradient(0deg, rgba(213, 115, 138, 0.20) 0%, rgba(213, 115, 138, 0.20) 100%), #FFF",
    },
    meeting_with_doctor: {
      color: "#6196BD",
      backgroundColor:
        "linear-gradient(0deg, rgba(97, 150, 189, 0.20) 0%, rgba(97, 150, 189, 0.20) 100%), #FFF",
    },
  };
  const styles = statusStyles[status] || {
    color: "#000000",
    backgroundColor: "#FFFFFF",
  };
  return (
    <Box
      w="max-content"
      py="1"
      px="2"
      borderRadius=".5rem"
      fontWeight={700}
      color={styles.color}
      bg={styles.backgroundColor}
    >
      {children}
    </Box>
  );
};

export default BoxWithStatusColor;
