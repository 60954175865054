import { Flex, Text, Image, Heading } from "@chakra-ui/react";

import lamina_box from "assets/icons/lamina_box.svg";
import two_phones from "assets/icons/two_phones.svg";
import iMac from "assets/icons/iMac.svg";
import { useTranslation } from "react-i18next";

const OurSentencesMoblie = () => {
  const { t } = useTranslation();

  return (
    <Flex w="full" direction="column" gap="20px">
      <Flex
        align="center"
        bg="rgba(88, 154, 175, 1)"
        direction="column"
        p="20px"
        borderRadius="24px"
      >
        <Image src={lamina_box} w="50%" />
        <Flex direction="column" gap="20px">
          <Heading color="white" fontSize="36px" fontWeight={500}>
            {t("Medication strips")}
          </Heading>
          <Text
            fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
            fontWeight={400}
          >
            {t(
              "Lamina Clinic provides access to completely individualized doses of most antidepressant medications (SSRI, SNRI). 57% of those who taper off or try to stop taking their antidepressant medications experience withdrawal symptoms. This occurs because the tapering is done in too large steps."
            )}
          </Text>
          {/*  <Text
            fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
            fontWeight={400}
          >
            {t(
              "With Lamina Clinic, you have access to tapering doses that are completely tailored for safe and effective tapering, and to reduce the risk and intensity of unpleasant withdrawal symptoms."
            )}
          </Text>
          <Text
            fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
            fontWeight={400}
          >
            {t(
              "The medications contain the same medically active substance as the one you are currently taking, and the only difference is that the dose is completely tailored for a safe and secure tapering process."
            )}
          </Text>
          <Text
            fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
            fontWeight={400}
          >
            {t(
              "Your tapering dose will be packaged in medication strips that make it easy to know which dose to take on a given day."
            )}
          </Text> */}
        </Flex>
      </Flex>

      <Flex
        align="center"
        bg="rgba(88, 154, 175, 1)"
        direction="column"
        p="20px"
        borderRadius="24px"
        position="relative"
      >
        <Flex w="full" justify="flex-end" position="absolute">
          <Image src={iMac} w="50%" />
        </Flex>
        <Flex mt="200px">
          <Flex direction="column" gap="20px">
            <Heading color="white" fontSize="36px" fontWeight={500}>
              {t("Get your de-escalation plan")}
            </Heading>
            <Text
              fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
              fontWeight={400}
            >
              {t(
                "When you want to get a prescription from Lamina Clinic, you need to log in using Bank ID and answer a number of questions. The whole process take no more than about 5 minutes, and our doctors will assess immidiatly if we can help you.You will then recive a proposal for a tapering period in number of weeks from our tapering experts."
              )}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        align="center"
        bg="rgba(88, 154, 175, 1)"
        direction="column"
        p="20px"
        borderRadius="24px"
      >
        <Image src={two_phones} w="50%" />
        <Flex direction="column" gap="20px">
          <Heading color="white" fontSize="36px" fontWeight={500}>
            {t("Support throughout the process")}
          </Heading>
          <Text
            fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
            fontWeight={400}
          >
            {t(
              "Tell us how you feel every day with a simple click in the app. You have regular follow-ups with your care team every month and can quickly talk to your doctor if, against the odds, you experience problems. Digital, flexible and on your terms."
            )}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default OurSentencesMoblie;
