import { useFetchProfileInfo } from "features/dashboard/api/client";
import { APIClient } from "services/api/client";
import { convertDateToISODate } from "services/helpers/dateHelpers";
import useSWR, { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";
import {
  CarepersonalResponse,
  DayOffItemRequestType,
  DayOffItemType,
  DayOffResponseItemType,
  DayOffResponseType,
  MeetingLogItemResponseType,
  MeetingLogsResponseType,
  UserMeetingRequest,
  UsersForMeeting,
} from "./types";

export const useFetchBookedDaysOff = (id?: string) => {
  return useSWR<DayOffResponseItemType[]>(
    `/working-days/?profile=${id}`,
    (url: string) =>
      APIClient.get(url, {
        transformResponse: (response) => {
          return JSON.parse(response).map(
            (responseItem: DayOffResponseType): DayOffResponseItemType => {
              return {
                created_at: new Date(responseItem.created_at),
                date_from: new Date(responseItem.date_from),
                date_to: new Date(responseItem.date_to),
                id: responseItem.id,
                profile: responseItem.profile,
                status: responseItem.status,
                updated_at: new Date(responseItem.updated_at),
              };
            }
          );
        },
      }),
    {
      shouldRetryOnError: false,
    }
  );
};

export const useBookDayOffRequest = (id?: string) => {
  const { data: profileData } = useFetchProfileInfo();
  const { mutate } = useFetchBookedDaysOff(id ?? profileData?.id ?? "");

  return useSWRMutation(
    "working-days/",
    (url: string, { arg }: { arg: DayOffItemType }) => {
      return APIClient.post<any, DayOffItemRequestType>(url, {
        date_from: convertDateToISODate(arg.date_from).slice(0, 10),
        date_to: convertDateToISODate(arg.date_to).slice(0, 10),
        profile: arg.profile,
      }).then(() => {
        mutate();
      });
    }
  );
};

export const useDeleteRequestedDayoff = (id: string) => {
  const { data: profileData } = useFetchProfileInfo();
  const { mutate } = useFetchBookedDaysOff(profileData?.id ?? "");

  return useSWRMutation("working-days/" + id + "/", (url: string) => {
    return APIClient.delete<any, any>(url).then(() => {
      mutate();
    });
  });
};

export const useFetchMeetingsLogs = (id?: string, page = 1, status = "") => {
  return useSWR<MeetingLogsResponseType>(
    `/meeting/filter/user/${id}/?page=${page}&status=${status}`,
    (url: string) => APIClient.get(url)
  );
};

export const useFetchSingleMeeting = (meetingId: string) => {
  return useSWR<MeetingLogItemResponseType>(
    `/meeting/${meetingId}/`,
    (url: string) => APIClient.get(url)
  );
};

export const useStaffUpdateSingleMeeting = (meetingId: string) => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(
    `/meeting/${meetingId}/`,
    (
      url,
      {
        arg,
      }: {
        arg: Partial<Pick<MeetingLogItemResponseType, "comment" | "status">> & {
          type?: string;
        };
      }
    ) => {
      return APIClient.patch(url, arg);
    },
    {
      onSuccess: () => {
        mutate((key: string) => {
          return !!key?.includes("/meeting/filter/user/");
        });
      },
    }
  );
};

export const useFetchCarePersonel = (userId: string) => {
  return useSWR<CarepersonalResponse>(
    `/user/care-personal/?${userId}`,
    (url: string) => APIClient.get(url)
  );
};

export const useFetchUsersForMeeting = () => {
  return useSWR<UsersForMeeting>(`admin/users/meeting`, (url: string) =>
    APIClient.get(url)
  );
};

export const useSendPatientMeeting = (serializedParams: string) => {
  const shouldEnable = !!serializedParams;

  return useSWR<any, Error>(
    shouldEnable ? `admin/calendly/create-call?${serializedParams}` : null,
    (url: string) => (shouldEnable ? APIClient.get(url) : null),
    {
      onSuccess: (data) => {
        if (data) {
          window.open(data, "_blank");
        }
      },
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (retryCount >= 1) return;
        setTimeout(() => revalidate({ retryCount }), 1000);
      },
    }
  );
};

export const useCreateMeeting = () => {
  return useSWRMutation(
    `admin/calendly/create-call/`,
    (
      url: string,
      {
        arg,
      }: {
        arg: { user_id: string; doctor_id: string; service_id: string };
      }
    ) => {
      return APIClient.get(
        url + arg.user_id + "/" + arg.doctor_id + "/" + arg.service_id
      );
    }
  );
};
