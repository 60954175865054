import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "services/firebase/config";

export const requestPermission = () => {
  console.log("Requesting User Permission......");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.");
      return getToken(messaging, {
        vapidKey:
          process.env.REACT_APP_ENV === "DEV"
            ? `BGcjnNxAsXVbiz2dE1YHQATGAHNyPUgfc0dgiitDnFy6EMoRI6JlKpBaA7rXb0Z4cL9zi4ADlDh-1iLELAXyEy8`
            : "BDwYq3t561gPU3K-kn9cnkTZXLxNQg91EbGHdZKijBmZz5L-yHbqu8Qn-7-kJVcpbJmXLsX8V6Lpimk9CP1-Jso",
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log("Client Token: ", currentToken);
          } else {
            console.log("Failed to generate the app registration token.");
          }
        })
        .catch((err) => {
          console.log(
            "An error occurred when requesting to receive the token.",
            err
          );
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BISWByT0bJ6TnhvzWuYUyJ8tpu-cmzl5r6IqKQitjlAzmU1fb8Gk-0LQMvZuXbrB2fxoVsLd-hbzW9dIDnPC2iQ",
  });
};
