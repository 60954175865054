import { Circle, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";

interface MoodItemComponentProps {
  mood: number;
}

const MoodItemComponent: FunctionComponent<MoodItemComponentProps> = ({
  mood,
}) => {
  const getCircleColor = (mood: number) => {
    switch (mood) {
      case 1:
        return "#43B3BA";
      case 2:
        return "#589AAF";
      case 3:
        return "#568CB2";
      case 4:
        return "#647CAD";
      case 5:
        return "#796A9F";
      case 6:
        return "#8B5687";
      case 7:
        return "#944366";
      default:
        return "gray.500";
    }
  };
  return (
    <Circle
      bg={getCircleColor(mood)}
      size={{ base: "12px", sm: "24px", lg: "32px" }}
    >
      <Text color="white" fontSize="16px" fontWeight={700}>
        {mood}
      </Text>
    </Circle>
  );
};

export default MoodItemComponent;
