import { Checkbox } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface DeclinedCheckProps {
  value?: string;
  onChange(newValue: string): void;
}

const DeclinedCheck: FunctionComponent<DeclinedCheckProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Checkbox
        isChecked={value === "declined" || value === "all"}
        variant={"filter"}
        onChange={(e) => {
          if (value === "declined" && !e.target.checked) {
            onChange("");
          } else if (value === "not_declined" && e.target.checked) {
            onChange("all");
          } else if (value === "all" && !e.target.checked) {
            onChange("not_declined");
          } else if (value === "") {
            onChange("declined");
          } else {
            onChange("");
          }
          //onChange(e.target.checked ? "declined" : "not_declined");
        }}
      >
        {t("Show Declined Patients")}
      </Checkbox>
      <Checkbox
        isChecked={value === "not_declined" || value === "all"}
        variant={"filter"}
        onChange={(e) => {
          if (value === "not_declined" && !e.target.checked) {
            onChange("");
          } else if (value === "declined" && e.target.checked) {
            onChange("all");
          } else if (value === "all" && !e.target.checked) {
            onChange("declined");
          } else if (value === "") {
            onChange("not_declined");
          } else {
            onChange("");
          }
          //onChange(e.target.checked ? "declined" : "not_declined");
        }}
      >
        {t("Show Approved Patients")}
      </Checkbox>
    </>
  );
};

export default DeclinedCheck;
