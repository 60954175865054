import {
  Flex,
  FormControl,
  FormLabel,
  Tag,
  TagCloseButton,
  Wrap,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useFetchAllDoctors } from "features/doctors/api/client";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface DoctorSelectorProps {
  setSelectedDoctors(doctors: string): void;
  selectedDoctors: string;
}

const DoctorSelector: FunctionComponent<DoctorSelectorProps> = ({
  setSelectedDoctors,
  selectedDoctors,
}) => {
  const { t } = useTranslation();

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const selectedValuesRef = useRef<string[]>([]);

  const { data: doctorsData } = useFetchAllDoctors();

  const doctorOptions = doctorsData?.doctors.map((doctor) => {
    return {
      label: doctor.first_name + " " + doctor.last_name,
      value: doctor.id,
    };
  });

  useEffect(() => {
    if (!selectedDoctors && selectedValuesRef.current.length > 0) {
      selectedValuesRef.current = [];
      setSelectedValues([]);
    }
  }, [selectedDoctors]);

  const handleTagClose = (indexToRemove: number) => {
    const newSelectedValues = [...selectedValues];
    newSelectedValues.splice(indexToRemove, 1);
    selectedValuesRef.current = newSelectedValues;
    setSelectedValues(newSelectedValues);
    setSelectedDoctors(newSelectedValues.join(","));
  };

  return (
    <Flex w="20rem" direction="column" gap="12px">
      <FormControl>
        <FormLabel
          fontSize="14px"
          fontWeight={600}
          color="rgba(255, 255, 255, 1)"
          mb="0.9rem"
        >
          {t("TREATING DOCTOR")}
        </FormLabel>
        <Select
          options={doctorOptions}
          placeholder="Välj…"
          onChange={(selectedOption) => {
            const selectedValue = selectedOption?.value ?? "";
            if (!selectedValuesRef.current.includes(selectedValue)) {
              selectedValuesRef.current = [
                ...selectedValuesRef.current,
                selectedValue,
              ];
              setSelectedValues(selectedValuesRef.current);
            }
            setSelectedDoctors(selectedValuesRef.current.join(","));
          }}
          menuPlacement="bottom"
          menuPortalTarget={document.body}
          chakraStyles={{
            dropdownIndicator: (provided, state) => ({
              ...provided,
              display: "none",
            }),
            control: () => ({
              p: "0px",
            }),
            valueContainer: (provided) => ({
              ...provided,
              border: "1px solid rgba(255, 255, 255, 1)",
              bg: "rgba(255, 255, 255, 0.1)",
              borderRadius: "8px",
              color: "white",
              h: "49px",
            }),
            placeholder: (provided) => ({
              ...provided,
              fontWeight: 400,
              color: "white",
              opacity: 0.8,
              fontSize: { base: "1rem", lg: "md" },
            }),
            indicatorSeparator: () => ({ display: "none" }),
            indicatorsContainer: (provided) => ({
              ...provided,
              cursor: "pointer",
            }),
            option: (provided) => ({
              ...provided,
              color: "rgba(0, 38, 55, 1)",
              fontSize: "16px",
              fontWeight: 400,
              width: "100%",
            }),
            menu: (provided) => ({
              ...provided,
              width: "100%",
            }),
          }}
        />
      </FormControl>
      <Wrap w="100%" gap="12px">
        {selectedValues.map((value, index) => (
          <Tag
            key={value}
            size="md"
            fontWeight={400}
            color="rgba(0, 38, 55, 1)"
            bg="rgba(238, 232, 169, 1)"
            p={{ base: "2px 10px", lg: "4px 12px" }}
            fontSize={{
              base: "8px",
              sm: "8px",
              md: "12px",
              lg: "16px",
            }}
          >
            {doctorOptions?.find((e) => value === e.value)?.label}
            <TagCloseButton onClick={() => handleTagClose(index)} />
          </Tag>
        ))}
      </Wrap>
    </Flex>
  );
};

export default DoctorSelector;
