import { InlineWidget, useCalendlyEventListener } from "react-calendly";

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type CalendlyEventModalProps = {
  isOpen: boolean;
  onClose: () => void;
  calendlyUrl?: string;
};

const CalendlyEventModal: React.FC<CalendlyEventModalProps> = ({
  isOpen,
  onClose,
  calendlyUrl,
}) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} size="2xl" isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent maxH="82vh" overflowY="auto" position="relative">
        <ModalBody>
          <Box>{calendlyUrl && <InlineWidget url={calendlyUrl} />}</Box>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="dark"
            fontWeight={300}
            fontFamily="main"
            onClick={onClose}
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              transition:
                "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
            }}
          >
            {t("cancel").charAt(0).toUpperCase() + t("cancel").slice(1)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CalendlyEventModal;
