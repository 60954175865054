import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Flex,
} from "@chakra-ui/react";
import {
  b64toBlob,
  dataURItoBlob,
} from "features/admin/helper/helper_functions";
import {
  useFetchProfileInfo,
  usePatchProfile,
} from "features/dashboard/api/client";
import { useUploadFile } from "features/fileUploads/api/client";
import { FunctionComponent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BsPencil, BsPencilFill } from "react-icons/bs";
import SignatureCanvas from "react-signature-canvas";

interface SignPopupProps {
  onChangeSignUrl(signature: string): void;
  isOpen: boolean;
  onClose(): void;
}

const SignPopup: FunctionComponent<SignPopupProps> = ({
  onChangeSignUrl,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const canvasRef = useRef<any>(null);

  const { data: userData } = useFetchProfileInfo();

  const { trigger: uploadFileRequest } = useUploadFile();
  const { trigger: patchProfile } = usePatchProfile();

  const extractSignatureUrl = (): string => {
    return canvasRef.current.toDataURL();
  };

  const handleAddSignatureClick = () => {
    if (!userData?.signature) {
    }

    onChangeSignUrl(extractSignatureUrl());
    onClose();
  };

  const onSaveSignature = () => {
    const data = dataURItoBlob(canvasRef.current.toDataURL());
    debugger;
    let formData = new FormData();
    formData.append("file", data, new Date().toISOString() + ".png");
    formData.append("folder", "saved_signatures");

    uploadFileRequest(formData, {
      onSuccess: (data) => {
        patchProfile({ signature: data.url });
      },
    });

    onChangeSignUrl(extractSignatureUrl());
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={"calc(500px + 3.5rem)"}>
        <ModalHeader fontWeight={400}>{t("Add signature")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            borderRadius={"0.5rem"}
            border={"1px solid #589AAF32"}
            position={"relative"}
            width={"fit-content"}
          >
            <Box position={"absolute"} right={"1rem"} top={"1rem"}>
              <BsPencilFill />
            </Box>
            <SignatureCanvas
              ref={canvasRef}
              canvasProps={{
                height: "200px",
                width: "500x",
              }}
              penColor={"blue"}
            />
          </Box>
        </ModalBody>

        <ModalFooter justifyContent={"flex-end"}>
          {/* <Button
            variant={"transparent"}
            color={"blueish.900"}
            fontWeight={"400"}
            onClick={onSaveSignature}
            opacity={0.5}
            transition={"all 0.3s ease-in"}
            _hover={{
              opacity: 1,
            }}
          >
            {t("Save signature")}
          </Button> */}
          <Flex>
            <Button
              variant={"transparent"}
              color={"blueish.900"}
              fontWeight={"400"}
              onClick={onClose}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="ghost"
              border={"1px solid #589AAF80"}
              color={"#589AAF"}
              h={"3rem"}
              px={"1.5rem"}
              fontWeight={"500"}
              onClick={onSaveSignature}
            >
              {t("Add signature")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SignPopup;
