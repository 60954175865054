import { Flex, Text, Image, Box } from "@chakra-ui/react";
import PopupChange from "features/prescriptionForm/components/prescriptionPopupChange";

import notification_accept_green from "assets/icons/notification_accept_green.svg";
import notification_decline from "assets/icons/notification_decline.svg";
import { useTranslation } from "react-i18next";

interface UserHistoryCardProps {
  expected_duration: number;
  status: string;
  created_at: string;
  updated_at: string;
  id: string;
}

const UserHistoryCard: React.FC<UserHistoryCardProps> = ({
  status,
  created_at,
  updated_at,
  expected_duration,
  id,
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      py="24px"
      w="100%"
      direction="column"
      gap="12px"
      borderBottom="1px solid rgba(88, 154, 175, 0.2)"
    >
      <Flex w="100%" align="flex-start" direction="column" gap="8px">
        <Text color="black" fontSize="16px">
          {new Date(updated_at || "").toLocaleDateString(
            t("locale") ?? "en-GB",
            {
              month: "short",
              day: "numeric",
              year: "numeric",
            }
          )}
        </Text>
        <Flex w="100%" justify="space-between">
          <Text color="blueish.500" fontSize="16px" fontWeight={500}>
            {t("TREATMENT PLAN CHANGE REQUEST")}
          </Text>

          <Text color="black" fontSize="16px" fontWeight={700}>
            {expected_duration ? expected_duration * 4 : 0} {t("weeks")}
          </Text>
        </Flex>
      </Flex>

      <PopupChange
        isAdjustable={false}
        selectedPlan={(expected_duration ?? 0) * 4}
        setSelectedPlan={() => {}}
        dashboardDisplay={true}
        adminDisplayId={id}
      />

      <Flex
        w="max-content"
        py="8px"
        px="20px"
        bg={
          status === "Declined"
            ? "rgba(213, 115, 138, 0.1)"
            : "rgba(44, 132, 117, 0.1)"
        }
        borderRadius="8px"
        align="center"
        gap="8px"
      >
        {status === "Declined" ? (
          <Image src={notification_decline} />
        ) : (
          <Image src={notification_accept_green} />
        )}
        {status === "Declined" ? (
          <Text
            color="rgba(213, 115, 138, 0.5)"
            fontSize="16px"
            fontWeight={700}
          >
            {t("Request Declined")}
          </Text>
        ) : (
          <Text
            color="rgba(44, 132, 117, 0.5)"
            fontSize="16px"
            fontWeight={700}
          >
            {t("Request Accepted")}
          </Text>
        )}
        <Box color="rgba(0, 38, 55, 0.2)" w="2px">
          |
        </Box>
        <Text
          color={
            status === "Declined"
              ? "rgba(213, 115, 138, 0.5)"
              : "rgba(44, 132, 117, 0.5)"
          }
          fontSize="16px"
        >
          {new Date(updated_at || "").toLocaleDateString(
            t("locale") ?? "en-GB",
            {
              month: "short",
              day: "numeric",
              year: "numeric",
            }
          )}
        </Text>
      </Flex>
    </Flex>
  );
};

export default UserHistoryCard;
