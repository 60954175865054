import { VStack } from "@chakra-ui/react";
import MeetingLogWidget from "features/staffCommons/components/MeetingLogWidget";
import DoctorsTable from "features/superadmin/components/DoctorsTable";
import NotificationWiget from "features/superadmin/components/NotificationWidget";
import NursesTable from "features/superadmin/components/NursesTable";
import PatientsTable from "features/superadmin/components/PatientsTable";
import RoleSelect from "features/superadmin/components/RoleSelect";
import React, { useState } from "react";

const SuperAdmin = () => {
  const [selectedRoleType, setSelectedRoleType] = useState<string>("patient");

  return (
    <VStack w="100%" alignItems={"stretch !important"} gap={3}>
      <RoleSelect setSelectedRoleType={setSelectedRoleType} />

      <MeetingLogWidget />

      <NotificationWiget />

      {selectedRoleType === "patient" && <PatientsTable />}
      {selectedRoleType === "doctor" && <DoctorsTable />}
      {selectedRoleType === "nurse" && <NursesTable />}
    </VStack>
  );
};

export default SuperAdmin;
