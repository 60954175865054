import { Flex, Hide, Show } from "@chakra-ui/react";
import { useRef } from "react";

import HomeContactUs from "../../features/homeSections/components/homeContactUs";
import HeroContext from "../../features/homeSections/components/homeContext";
import HomeFooter from "../../features/homeSections/components/homeFooter";
import Hero from "../../features/homeSections/components/homeHero";
import HomeMotto from "../../features/homeSections/components/homeMotto";
import HomeOurSentences from "../../features/homeSections/components/homeOurSentences";
import HomeServices from "../../features/homeSections/components/homeServices";
import HomeMottoMobile from "../../features/homeSections/components/homeMottoMobile";
import HomeContactUsMobile from "../../features/homeSections/components/homeContactUsMobile";
import FooterMobile from "features/homeSections/components/homeFooterMobile";
import OurSentencesMoblie from "features/homeSections/components/homeOurSentencesMobile";

const Home = () => {
  const ref = useRef<any>(null);
  const mobileRef = useRef<any>(null);

  const handleClick = () => {
    if (ref.current) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClickMobile = () => {
    if (mobileRef.current) {
      mobileRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <Flex
      minW={"100%"}
      maxW={"100%"}
      gap={"0rem !important"}
      direction={"column"}
      overflow={"visible"}
    >
      <Hero handleClick={handleClick} handleClickMobile={handleClickMobile} />
      <div id="context">
        <HeroContext />
      </div>

      <Show above="sm">
        <HomeMotto />
      </Show>
      <Hide above="sm">
        <HomeMottoMobile />
      </Hide>

      {/* <Hide above="sm">
        <div id="services">
          <HomeServicesMobile ref={mobileRef} />
        </div>
      </Hide> */}

      <div id="services">
        <HomeServices ref={ref} />
      </div>

      <div id="donwloadApp">
        <Show above="sm">
          <HomeContactUs />
        </Show>
        <Hide above="sm">
          <HomeContactUsMobile />
        </Hide>
      </div>

      <Show above="sm">
        <HomeOurSentences />
      </Show>
      <Hide above="sm">
        <div id="sentences">
          <OurSentencesMoblie />
        </div>
      </Hide>
      <Show above="sm">
        <HomeFooter />
      </Show>
      <Hide above="sm">
        <FooterMobile />
      </Hide>
    </Flex>
  );
};

export default Home;
