import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { IoIosNotifications } from "react-icons/io";
import { toast } from "react-toastify";

import {
  useDeletePushToken,
  useSendPushToken,
} from "features/pushNotifications/api/client";
import {
  requestForToken,
  requestPermission,
} from "features/pushNotifications/services/helpers";
import { usePushTokenStore } from "features/pushNotifications/services/store";
import { onMessage } from "firebase/messaging";
import { onMessageListener } from "services/firebase/config";
import { useFetchProfileInfo } from "features/dashboard/api/client";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PushNotificationFacadeProps {}

const PushNotificationFacade: FunctionComponent<
  PushNotificationFacadeProps
> = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const { token, setToken } = usePushTokenStore();

  const { trigger: sendToken } = useSendPushToken();
  const { data: profileData } = useFetchProfileInfo();

  const { trigger: deleteTOken } = useDeletePushToken();

  const memoizedNotificationUpdate = useCallback(
    (payload: any) => {
      //refetchNotifications();

      toast.info(
        (payload?.notification?.title ?? "") +
          ": " +
          (payload.notification.body ?? ""),
        {
          icon: <IoIosNotifications />,

          progressStyle: {
            color: "#002637",
            backgroundColor: "#002637",
          },
          style: {
            color: "#002637",
            border: "1px solid #002637",
          },
        }
      );
      setNotification({
        title: payload?.notification?.title ?? "",
        body: payload?.notification?.body ?? "",
      });
    },
    [profileData?.id, setNotification, toast]
  );

  useEffect(() => {
    const callback = async () => {
      const token = await requestForToken();
      console.log("🚀 ~ file: index.tsx:50 ~ callback ~ token:", token);

      setToken(token);

      onMessageListener().then((payload: any) => {
        console.log("FIRED");
        console.log(payload);

        memoizedNotificationUpdate(payload);
      });
    };

    if (profileData?.id) {
      callback();
    }

    return () => {
      if (token) {
        deleteTOken(token);
        setToken(undefined);
      }
    };
  }, [profileData]);

  useEffect(() => {
    if (token) {
      sendToken({token: token});
    }

    return () => {
      setToken(undefined);
    };
  }, [token]);

  onMessageListener().then((payload: any) => {
    console.log("FIRED");
    console.log(payload);

    memoizedNotificationUpdate(payload);
  });

  return <></>;
};

export default PushNotificationFacade;
