import SvgIconConstituentValues from "./types/svgPropsType";

const RequestDeclinedIcon = (props: SvgIconConstituentValues) => (
  <svg
    width={44}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 44C26.3512 44 30.6047 42.7097 34.2225 40.2923C37.8404 37.875 40.6602 34.439 42.3253 30.419C43.9905 26.3991 44.4261 21.9756 43.5773 17.708C42.7284 13.4404 40.6331 9.52042 37.5564 6.44366C34.4796 3.36691 30.5596 1.27161 26.292 0.422734C22.0244 -0.426141 17.6009 0.00953233 13.581 1.67466C9.56099 3.33979 6.12506 6.15958 3.70767 9.77746C1.29028 13.3953 0 17.6488 0 22C0.00688094 27.8327 2.32694 33.4244 6.45125 37.5488C10.5756 41.6731 16.1673 43.9931 22 44ZM22 4.00001C25.5601 4.00001 29.0402 5.05569 32.0003 7.03356C34.9603 9.01142 37.2675 11.8226 38.6298 15.1117C39.9922 18.4008 40.3487 22.02 39.6541 25.5116C38.9596 29.0033 37.2453 32.2106 34.7279 34.7279C32.2106 37.2453 29.0033 38.9596 25.5116 39.6541C22.02 40.3487 18.4008 39.9922 15.1117 38.6298C11.8226 37.2675 9.01141 34.9604 7.03355 32.0003C5.05568 29.0402 4 25.5601 4 22C4.00529 17.2277 5.90342 12.6524 9.27792 9.27793C12.6524 5.90343 17.2277 4.0053 22 4.00001ZM14.586 26.586L19.172 22L14.586 17.414C14.2217 17.0368 14.0201 16.5316 14.0247 16.0072C14.0292 15.4828 14.2395 14.9812 14.6104 14.6104C14.9812 14.2396 15.4828 14.0292 16.0072 14.0247C16.5316 14.0201 17.0368 14.2217 17.414 14.586L22 19.172L26.586 14.586C26.9632 14.2217 27.4684 14.0201 27.9928 14.0247C28.5172 14.0292 29.0188 14.2396 29.3896 14.6104C29.7605 14.9812 29.9708 15.4828 29.9753 16.0072C29.9799 16.5316 29.7783 17.0368 29.414 17.414L24.828 22L29.414 26.586C29.605 26.7705 29.7574 26.9912 29.8622 27.2352C29.967 27.4792 30.0222 27.7416 30.0245 28.0072C30.0268 28.2728 29.9762 28.5361 29.8756 28.7819C29.7751 29.0277 29.6266 29.251 29.4388 29.4388C29.251 29.6266 29.0277 29.7751 28.7819 29.8757C28.5361 29.9762 28.2728 30.0268 28.0072 30.0245C27.7416 30.0222 27.4792 29.967 27.2352 29.8622C26.9912 29.7574 26.7705 29.605 26.586 29.414L22 24.828L17.414 29.414C17.0368 29.7783 16.5316 29.9799 16.0072 29.9754C15.4828 29.9708 14.9812 29.7605 14.6104 29.3896C14.2395 29.0188 14.0292 28.5172 14.0247 27.9928C14.0201 27.4684 14.2217 26.9632 14.586 26.586Z"
      fill="#D5738A"
    />
  </svg>
);
export default RequestDeclinedIcon;
