import useSWR from "swr";

import { APIClient } from "services/api/client";
import { StaffUser, UserById } from "features/admin/api/types";
import { TreatmentPlan } from "features/prescriptionForm/api/type";

export const useAdminUserInfo = (id: string) => {
  return useSWR<UserById | StaffUser, Error>(
    `admin/users/${id}/`,
    (url: string) => APIClient.get(url),
    {
      isPaused: () => !id || id.trim() === "",
      shouldRetryOnError: false,
    }
  );
};

export const useFetchTreatmentDurationOptions = (id: string) => {
  return useSWR<TreatmentPlan>(
    () => (id ? `admin/user_treatment_duration/${id}` : null),
    (url: string) => APIClient.get(url)
  );
};
