import { useAdminUserInfo, useUserChart } from "features/admin/api/client";
import { useFetchPatientJournal } from "features/staffCommons/components/JournalRecords/api/client";
import { APIClient } from "services/api/client";
import useSWRMutation from "swr/mutation";

export const useCancelTreatment = (patientId: string) => {
  const { mutate } = useAdminUserInfo(patientId);
  const { mutate: mutateChart } = useUserChart(patientId);
  const { mutate: mutateJournal } = useFetchPatientJournal(patientId);

  return useSWRMutation(
    `/treatment/${patientId}/cancel`,
    (url, { arg }: { arg: { comment: string } }) => {
      return APIClient.patch(url, arg);
    },
    {
      onSuccess: () => {
        mutate();
        mutateChart();
        mutateJournal();
      },
    }
  );
};
