import {
  VStack,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Text,
} from "@chakra-ui/react";
import DoubleDoseInput from "features/admin/components/DoubleDoseInput";
import { title } from "process";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface StartingDoseInputProps {
  from?: number;
  to?: number;
  setFrom(newFrom: number): void;
  setTo(newTo: number): void;
}

const StartingDoseInput: FunctionComponent<StartingDoseInputProps> = ({
  from,
  to,
  setFrom,
  setTo,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <FormLabel
        fontSize="14px"
        fontWeight={600}
        color="rgba(255, 255, 255, 1)"
        lineHeight="20px"
        textTransform={"uppercase"}
      >
        {t("STARTING DOSE")}
      </FormLabel>

      <Flex gap={"1rem"}>
        <Flex
          border="1px solid rgba(255, 255, 255, 0.5)"
          bg="rgba(255, 255, 255, 0.1)"
          borderRadius="8px"
          align="center"
          p="15px"
          pl={"0rem"}
          h="49px"
          w={"50%"}
        >
          <Input
            bg={"transparent !important"}
            color="white"
            type={"number"}
            fontWeight={500}
            opacity={0.8}
            fontSize={"1rem"}
            placeholder={"Från"}
            _placeholder={{ color: "white", opacity: 0.5 }}
            value={from ?? ""}
            min={0}
            onChange={(e) => {
              setFrom(e.target.valueAsNumber);
            }}
          />
          <Text fontSize={"stnd"}>mg</Text>
        </Flex>
        <Flex
          border="1px solid rgba(255, 255, 255, 0.5)"
          bg="rgba(255, 255, 255, 0.1)"
          borderRadius="8px"
          align="center"
          p="15px"
          pl={0}
          h="49px"
          w={"50%"}
        >
          <Input
            fontSize={"1rem"}
            bg={"transparent !important"}
            type={"number"}
            color="white"
            fontWeight={500}
            opacity={0.8}
            value={to ?? ""}
            min={0}
            onChange={(e) => {
              setTo(e.target.valueAsNumber);
            }}
            placeholder={"Till"}
            _placeholder={{ color: "white", opacity: 0.5 }}
          />
          <Text fontSize={"stnd"}>mg</Text>
        </Flex>
      </Flex>
    </FormControl>
  );
};

export default StartingDoseInput;
