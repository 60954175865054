import order_1 from "assets/icons/order_1.svg";
import order_2 from "assets/icons/order_2.svg";
import order_3 from "assets/icons/order_3.svg";
import order_4 from "assets/icons/order_4.svg";
import order_5 from "assets/icons/order_5.svg";
import order_6 from "assets/icons/order_6.svg";

import { Image, Text, Flex, Button, Box } from "@chakra-ui/react";
import { OrderStepType } from "features/dashboard/api/type";
import { useOrderStore } from "services/state/orderStore";
import {
  useDashboardInfo,
  useFetchProfileInfo,
} from "features/dashboard/api/client";
import { useTranslation } from "react-i18next";
import { AiOutlineCheckCircle, AiOutlineClockCircle } from "react-icons/ai";

const UserOrder = () => {
  const { t } = useTranslation();
  const { data: dashboardData } = useDashboardInfo();
  const { data: profileData } = useFetchProfileInfo();

  const { currentStep, currentStepState, setCurrentStep } = useOrderStore();

  const stepHelper = (stepNumber: number) => {
    if (stepNumber < currentStep) {
      return "completed";
    }

    if (stepNumber === currentStep) {
      return currentStepState;
    }
    return "";
  };

  const steps: OrderStepType[] = [
    {
      step: t("Fill out the questionnaire"),
      image: order_1,
      status: stepHelper(0),
      date: new Date(),
    },
    {
      step: t("Book free consultation"),
      image: order_2,
      status: stepHelper(1),
      date: new Date(profileData?.call_with_nurse ?? new Date()),
    },
    {
      step: t("Meet doctor and confirm your plan"),
      image: order_3,
      status: stepHelper(2),
      date: new Date(profileData?.consented_date ?? new Date()),
    },
    {
      step: t("Order and pay"),
      image: order_4,
      status: stepHelper(3),
      date: new Date(dashboardData?.order?.shipping_date ?? Date.now()),
    },
    {
      step: t("Receive your medication and start tapering"),
      image: order_5,
      status: stepHelper(4),
      date: new Date(dashboardData?.order?.delivery_date ?? Date.now()),
    },
    {
      step: t("Start tapering"),
      image: order_6,
      status: stepHelper(5),
      date: new Date(profileData?.start_treatment_date ?? Date.now()),
    },
  ];

  const steps1: OrderStepType[] = [
    {
      step: t("Fill out the questionnaire"),
      image: order_1,
      status: stepHelper(0),
      date: new Date(),
    },
    {
      step: t("Place an order"),
      image: order_2,
      status: stepHelper(1),
      date: new Date(dashboardData?.order?.order_date ?? Date.now()),
    },
    {
      step: t("Make a payment"),
      image: order_3,
      status: stepHelper(2),
      date: new Date(dashboardData?.order?.payment_date ?? Date.now()),
    },
    {
      step: t("Delivering"),
      image: order_4,
      status: stepHelper(3),
      date: new Date(dashboardData?.order?.shipping_date ?? Date.now()),
    },
    {
      step: t("Delivered"),
      image: order_5,
      status: stepHelper(4),
      date: new Date(dashboardData?.order?.delivery_date ?? Date.now()),
    },
  ];

  return (
    <Flex
      w="100%"
      justifyContent={"space-between"}
      alignItems={"flex-start"}
      px={{ base: "0rem", sm: "1rem" }}
      direction={{ base: "column", sm: "row" }}
      gap={{ base: "0.5rem", sm: "0rem" }}
    >
      {steps.map((step, index) => {
        const isActive =
          step.status === "completed" || step.status === "processing";

        return (
          <Flex
            direction={{ base: "row", sm: "column" }}
            w={{
              base: index !== steps.length - 1 ? "100%" : "100%",
              sm: index !== steps.length - 1 ? "100%" : "auto",
            }}
            justify="center"
            gap={2}
            alignItems="flex-start"
            key={step.step}
          >
            <Flex
              gap="0.5rem"
              pr={{ base: "0rem", sm: "0.5rem" }}
              mb={{ base: "0rem", sm: "1rem" }}
              alignItems="center"
              w="100%"
              direction={{ base: "column", sm: "row" }}
              justifyContent="flex-start"
            >
              <Image
                src={step.image}
                mx="0"
                width="60px"
                opacity={isActive ? 1 : 0.2}
                alt={step.step + " icon"}
              />
              {index !== steps.length - 1 && (
                <Box
                  w="0px"
                  minH={{ base: "90px", sm: "0px" }}
                  color={"black"}
                  flex={{ base: 0, sm: 1 }}
                  borderBottom={{
                    base: "none",
                    sm:
                      index < currentStep
                        ? "2px solid #589AAF !important"
                        : "2px dotted rgba(88, 154, 175, 0.2) !important",
                  }}
                  border={{
                    base:
                      index < currentStep
                        ? "1px solid #589AAF"
                        : "1px dashed rgba(88, 154, 175, 0.2)",
                    sm: "none",
                  }}
                />
              )}
            </Flex>

            <Flex direction="column" w="100%" justify="flex-end">
              <Text
                color={step.status ? "blueish.900" : "rgba(0, 38, 55, 0.1)"}
                fontSize="1rem"
                fontWeight="bold"
                maxW={"10rem"}
              >
                {step.step}
              </Text>
              {step.status && (
                <Text
                  color="blueish.900"
                  fontSize="16px"
                  opacity={0.5}
                  mb={"0.75rem"}
                >
                  {step.date.toLocaleDateString(t("locale") ?? "en-GB")}
                </Text>
              )}
              {step.status === "processing" ? (
                <Flex color={"#F3C477"} alignItems={"center"} gap={"0.25rem"}>
                  <AiOutlineClockCircle />
                  <Text
                    variant="dashboard"
                    fontSize={"1rem"}
                    cursor="auto"
                    fontWeight={"700"}
                    lineHeight={"1rem"}
                    pt={"0.125rem"}
                  >
                    {t("Processing")}
                  </Text>
                </Flex>
              ) : step.status ? (
                <Flex color={"#2C8475"} alignItems={"center"} gap={"0.25rem"}>
                  <AiOutlineCheckCircle />
                  <Text
                    variant="dashboard"
                    fontSize={"1rem"}
                    cursor="auto"
                    fontWeight={"700"}
                    lineHeight={"1rem"}
                    pt={"0.125rem"}
                  >
                    {t("Completed")}
                  </Text>
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default UserOrder;
