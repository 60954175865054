import { Select } from "chakra-react-select";
import { options } from "features/admin/helper/columns";
import { useGetMedicationsType } from "features/superadmin/api/client";

import { FunctionComponent } from "react";

interface MedicationFilterProps {
  value?: string;
  onChange(newValue: string): void;
}

const MedicationFilter: FunctionComponent<MedicationFilterProps> = ({
  value,
  onChange,
}) => {
  const { data: medicationType } = useGetMedicationsType();

  const medicationOptions = medicationType
    ? medicationType.map((medication) => ({
        value: medication.name,
        label: medication.name,
      }))
    : [];

  return (
    <Select
      className="chakra-react-select"
      classNamePrefix="chakra-react-select"
      selectedOptionStyle="check"
      variant={"outlined"}
      options={medicationOptions}
      value={medicationOptions.find((option) => option.value === value)}
      menuPlacement="bottom"
      menuPortalTarget={document.body}
      onChange={(e) => {
        if (e) {
          onChange(e?.label);
        }
      }}
      chakraStyles={{
        menu: (prev) => ({
          ...prev,
          zIndex: "1000000",
          overflowY: "visible",
        }),
        control: (prev) => ({
          ...prev,
          bg: "transparent !important",
          border: "1px solid rgba(0, 0, 0, 0.1) !important",
          minH: "3rem !important",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          bg: "transparent",
          p: 0,
          w: 6,
          mx: 2,
          cursor: "inherit",
        }),
        valueContainer: (provided) => ({
          ...provided,
          fontSize: "1rem",
          px: 0,
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: "none",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          cursor: "pointer",
        }),
      }}
    />
  );
};

export default MedicationFilter;
