import { APIClient } from "services/api/client";
import useSWRMutation from "swr/mutation";

import useSWR from "swr";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const useFetchAuthData = () => {
  return useSWR<string>("totp/create/", (url: string) => APIClient.get(url));
};

export const useAuthenticateDoctor = () => {
  const { t } = useTranslation();

  return useSWRMutation<any, any>(
    "totp/login/",
    (url: string, { arg }: { arg: string }) => {
      return APIClient.post(url + arg + "/");
    },
    {
      onError: (error) => {
        if (error.response.status === 400) {
          toast.error(t("Wrong authentication code, please try again"));
        } else {
          toast.error("Something went wrong, please try again later");
        }
      },
    }
  );
};
