import {
  Center,
  Flex,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  useDisclosure,
} from "@chakra-ui/react";

import logo from "assets/icons/blue-logo.svg";
import burger from "assets/images/hamburger-menu.svg";
import DrawerView from "../DrawerView";

import background from "assets/images/background.svg";
import { useHistory } from "react-router-dom";
import WebinarDrawer from "../WebinarDrawer";

const WebinarNavbarMobile = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const history = useHistory();

  return (
    <Flex
      justify="space-between"
      alignItems="center"
      w={"100%"}
      p={"5%"}
      pt={0}
    >
      <Image
        src={logo}
        alt="Lamina clinic logo"
        w={"calc(120px + 9%)"}
        minW={"100px"}
        cursor={"pointer"}
        onClick={() => history.push("/")}
        mb="5px"
      />

      <Center
        backgroundColor={"yellowish.400"}
        borderRadius={"full"}
        h={"2rem"}
        w={"2rem"}
      >
        <Image src={burger} alt="down" onClick={onOpen} />
      </Center>

      <Drawer isOpen={isOpen} onClose={onClose} size={"full"}>
        <DrawerOverlay />
        <DrawerContent bg="rgba(255, 255, 255, 1)" py={"3%"}>
          <DrawerBody>
            <WebinarDrawer onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default WebinarNavbarMobile;
