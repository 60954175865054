import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  ModalCloseButton,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { GroupBase, Select } from "chakra-react-select";
import { useFormik } from "formik";
import countryList from "react-select-country-list";

import { FunctionComponent, useEffect, useMemo, useState } from "react";
import DefaultAddress from "../DefaultAddress";
import { UserAddressType } from "features/addressPopup/api/types";
import { AddressSchema } from "../medicationSidebar/validationSchemas";
import {
  useCreateDefaultAddress,
  useEditAddress,
  useFetchAddressById,
  useFetchDefaultAddress,
  usePostNewAddress,
} from "features/addressPopup/api/client";
import {
  useCreateOrder,
  useDashboardInfo,
  useFetchProfileInfo,
} from "features/dashboard/api/client";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useCheckLogin, useGeneralLoginData } from "features/login/api/client";
import { StatusResponseType } from "features/login/api/types";

interface AddressFormProps {
  editMode?: boolean;
  onClose(): void;
}

type CountryData = {
  label: string;
  value: string;
};

const AddressForm: FunctionComponent<AddressFormProps> = ({
  editMode,
  onClose,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { data: defaultAddressData } = useFetchDefaultAddress();
  const { trigger: createDefaultAddress } = useCreateDefaultAddress();
  const { data: dashboardData } = useDashboardInfo();
  const userData = useGeneralLoginData();
  const { data: profileData } = useFetchProfileInfo();
  const { data: currentOrderAddress } = useFetchAddressById(
    dashboardData?.order?.delivery_address
  );

  const { trigger: dispatchEditForm, isMutating: isEditAddressLoading } =
    useEditAddress(dashboardData?.order?.delivery_address);
  const { trigger: createOrder, isMutating: isCreateOrderLoading } =
    useCreateOrder();
  const { trigger: saveNewAddress, isMutating: isCreateAddressLoading } =
    usePostNewAddress();

  const formik = useFormik<UserAddressType>({
    initialValues: {
      first_name: profileData?.first_name ?? "",
      last_name: profileData?.last_name ?? "",
      phone: "",
      email: "",
      zip_code: "",
      country: "Sweden",
      city: "",
      address: "",
    },
    onSubmit: async (values) => {
      if (editMode) {
        await dispatchEditForm({ address: values });
      } else {
        try {
          const newAddress = await saveNewAddress({ address: values });
          if (!defaultAddressData) {
            createDefaultAddress({ address: values });
          }
          await createOrder({
            order: {
              delivery_address: newAddress.id,
            },
          });
        } catch (error) {
          console.error("ERROR CREATING ORDER FROM NEW ADDRESS");
          onClose();
        }
      }
      onClose();
    },
    validationSchema: AddressSchema,
    validateOnChange: false,
  });

  const countryOptions = useMemo(
    () =>
      countryList()
        .getData()
        .map((country) => ({
          label: country.label,
          value: country.label,
        })),
    []
  );

  const [isDefaultAddressSelected, setIsDefaultAddressSelected] =
    useState<boolean>(false);

  const selectDefaultAddress = () => {
    if (defaultAddressData) {
      formik.setValues({
        ...defaultAddressData,
        first_name:
          defaultAddressData.first_name !== ""
            ? defaultAddressData.first_name
            : formik.values.first_name,
        last_name:
          defaultAddressData.last_name !== ""
            ? defaultAddressData.last_name
            : formik.values.last_name,
        zip_code: defaultAddressData.zip_code ?? "",
      });
    }
  };

  useEffect(() => {
    if (editMode && currentOrderAddress) {
      formik.setValues(currentOrderAddress);
    }
  }, [editMode, currentOrderAddress]);

  useEffect(() => {
    if (defaultAddressData) {
      const values = formik.values;
      setIsDefaultAddressSelected(
        (values.address === defaultAddressData.address ||
          defaultAddressData.address === "") &&
          (values.city === defaultAddressData.city ||
            defaultAddressData.city === "") &&
          (values.country === defaultAddressData.country ||
            defaultAddressData.country === "") &&
          (values.email === defaultAddressData.email ||
            defaultAddressData.email === "") &&
          (values.first_name === defaultAddressData.first_name ||
            defaultAddressData.first_name === "") &&
          (values.last_name === defaultAddressData.last_name ||
            defaultAddressData.last_name === "") &&
          (values.phone === defaultAddressData.phone ||
            defaultAddressData.phone === "") &&
          (values.zip_code.toString() === defaultAddressData.zip_code ||
            defaultAddressData.zip_code === "")
      );
    }
  }, [formik.values, defaultAddressData]);

  const isCollectiveLoading =
    isEditAddressLoading || isCreateOrderLoading || isCreateAddressLoading;

  useEffect(() => {
    console.log("🚀 ~ file: index.tsx:153 ~ userData:", userData);
  }, [userData]);

  return (
    <Flex
      p={{
        base: "1.5rem",
        md: "1.5rem 2.5rem 2.5rem 2.5rem",
        lg: "2.5rem 2.5rem 3.5rem 2.5rem",
      }}
      w={"100%"}
      direction={"column"}
      justifyContent={"space-between"}
    >
      <Box>
        <Flex justifyContent={"flex-end"} w={"100%"}>
          <ModalCloseButton
            position={"relative"}
            h={"1rem"}
            fontSize={"1rem"}
            justifyContent="flex-end"
            mb={{ base: 3, sm: 0 }}
          />
        </Flex>
        <Heading fontSize={{ base: "1.5rem", lg: "xl" }} mb={"2.5rem"}>
          {t("Please fill in your contact information for delivery.")}
        </Heading>
        <SimpleGrid
          templateColumns={{ base: "none", sm: "850fr 320fr" }}
          columnGap={{ base: "1.5rem", lg: "2.5rem" }}
          rowGap={"1rem"}
        >
          <form onSubmit={formik.handleSubmit}>
            <SimpleGrid
              columns={{ base: 1, sm: 2 }}
              gap={{ base: "1rem", lg: "2rem" }}
              rowGap={{ base: "1.5rem", lg: "2rem" }}
            >
              <FormControl isInvalid={!!formik.errors.first_name}>
                <FormLabel
                  htmlFor={"last_name"}
                  fontWeight={700}
                  fontSize={"stnd"}
                >
                  {t("First name")}
                </FormLabel>
                <Input
                  disabled={profileData?.first_name !== ""}
                  id={"first_name"}
                  name={"first_name"}
                  placeholder={`${t("Enter your first name")}`}
                  onChange={formik.handleChange}
                  value={formik.values.first_name}
                  _disabled={{
                    opacity: 0.9,
                    cursor: "not-allowed",
                    filter: "grayscale(0.5)",
                  }}
                />

                <FormErrorMessage color={"red"}>
                  {formik.errors.first_name}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!formik.errors.last_name}>
                <FormLabel
                  htmlFor={"last_name"}
                  fontWeight={700}
                  fontSize={"stnd"}
                >
                  {t("Last name")}
                </FormLabel>
                <Input
                  disabled={profileData?.last_name !== ""}
                  id={"last_name"}
                  name={"last_name"}
                  placeholder={`${t("Enter your last name")}`}
                  onChange={formik.handleChange}
                  value={formik.values.last_name}
                  _disabled={{
                    opacity: 0.9,
                    cursor: "not-allowed",
                    filter: "grayscale(0.5)",
                  }}
                />
                <FormErrorMessage color={"red"}>
                  {formik.errors.last_name}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!formik.errors.phone}>
                <FormLabel htmlFor={"phone"} fontWeight={700} fontSize={"stnd"}>
                  {t("Phone number")}
                </FormLabel>
                <Input
                  id={"phone"}
                  name={"phone"}
                  placeholder={`${t("Enter your phone number")}`}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
                <FormErrorMessage color={"red"}>
                  {formik.errors.phone}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                color="blackish.200"
                isInvalid={!!formik.errors.email}
              >
                <FormLabel
                  htmlFor="email"
                  color="blackish.200"
                  fontWeight={700}
                  fontSize="16px"
                >
                  {t("Email")}
                </FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  placeholder={`${t("Enter your email address")}`}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <FormErrorMessage color="red">
                  {formik.errors.email}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!formik.errors.zip_code}>
                <FormLabel
                  htmlFor={"zip_code"}
                  fontWeight={700}
                  fontSize={"stnd"}
                >
                  {t("ZIP-Code")}
                </FormLabel>
                <Input
                  type="text"
                  id={"zip_code"}
                  name={"zip_code"}
                  placeholder={`${t("Enter your ZIP-Code")}`}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "zip_code",
                      e.target.value.replace(/^[^0-9\s]+$/, "")
                    );
                  }}
                  value={formik.values.zip_code}
                />
                <FormErrorMessage color={"red"}>
                  {formik.errors.zip_code}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!formik.errors.country}>
                <FormLabel
                  htmlFor={"Country"}
                  fontWeight={700}
                  fontSize={"stnd"}
                >
                  {t("Country")}
                </FormLabel>
                <Select<CountryData, true, GroupBase<CountryData>>
                  placeholder={`${t("Select country")}`}
                  options={countryOptions}
                  chakraStyles={{
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      p: 0,
                      w: "40px",
                      borderLeft: "none",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      paddingLeft: "0px",
                    }),

                    placeholder: (provided) => ({
                      ...provided,
                      fontWeight: 500,
                      opacity: 0.8,
                      fontSize: { base: "1rem", lg: "md" },
                    }),
                    input: (provided) => ({
                      ...provided,
                      p: {
                        base: "0.3rem 0rem 0.3rem 0rem",
                        lg: "0.85rem 0rem 0.85rem 0rem !important",
                      },
                    }),
                    indicatorSeparator: () => ({ display: "none" }),
                    indicatorsContainer: (provided) => ({
                      ...provided,
                      cursor: "pointer",
                      bg: "rgba(0, 0, 0, 0)",
                    }),
                  }}
                  value={
                    formik.values.country !== ""
                      ? {
                          value: formik.values.country,
                          label: formik.values.country,
                        }
                      : undefined
                  }
                  onChange={(newValue) => {
                    formik.setFieldValue("country", (newValue as any).value);
                  }}
                />
                <FormErrorMessage color={"red"}>
                  {formik.errors.country}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!formik.errors.city}>
                <FormLabel htmlFor={"city"} fontWeight={700} fontSize={"stnd"}>
                  {t("City/Town")}
                </FormLabel>
                <Input
                  id={"city"}
                  name={"city"}
                  placeholder={`${t("Enter your city")}`}
                  onChange={formik.handleChange}
                  value={formik.values.city}
                />

                <FormErrorMessage color={"red"}>
                  {formik.errors.city}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!formik.errors.address}>
                <FormLabel htmlFor={"city"} fontWeight={700} fontSize={"stnd"}>
                  {t("Address")}
                </FormLabel>
                <Input
                  id={"address"}
                  name={"address"}
                  placeholder={`${t(
                    "Enter the street, house number, apartment number"
                  )}`}
                  onChange={formik.handleChange}
                  value={formik.values.address}
                />
                <FormErrorMessage color={"red"}>
                  {formik.errors.address}
                </FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </form>
          <DefaultAddress
            isSelected={isDefaultAddressSelected}
            setIsSelected={selectDefaultAddress}
          />

          <Box
            fontSize={"0.75rem"}
            gridColumn={{ base: "", sm: "1 / 3" }}
            opacity={0.6}
          >
            Du har ingen ångerrätt på det här köpet. Det beror på att
            medicinerna specialtillverkas för dig och vi har vårdansvaret för
            dig under den tid du äter dem. Däremot betalar du alltid bara för en
            månad i taget, och det finns ingen bindningstid eller automatisk
            förnyelse. Om du inte vill fortsätta med Lamina Clinic kan du
            avsluta när som helst. Ta dock alltid kontakt med oss innan du
            avslutar för att prata om hur du kan fortsätta din medicinering
            eller nedtrappning på ett säkert sätt.
          </Box>
        </SimpleGrid>
      </Box>
      <Flex justifyContent={"space-betwen"}>
        <Flex gap={"1rem"} w="100%" mt={"2rem"}>
          <Button
            variant="transparent"
            border={"1px solid #589AAF"}
            color={"#589AAF"}
            onClick={() => {
              formik.submitForm();
            }}
            isLoading={isCollectiveLoading}
          >
            <Text
              color="blueish.500"
              fontSize={{ base: "xs", md: "md" }}
              p="2px"
              fontWeight={700}
              zIndex="inherit"
            >
              {editMode ? t("Update address") : t("Save Address")}
            </Text>
          </Button>
          {!editMode && (
            <Button
              variant={"dark"}
              bg={"#589AAF"}
              minH={"none"}
              onClick={async () => {
                try {
                  const validate = await formik.validateForm();
                  if (Object.keys(validate).length === 0) {
                    await formik.submitForm();
                    history.push("/payment");
                  }
                } catch (error) {
                  console.error(
                    "🚀 ~ file: index.tsx:388 ~ onClick={ ~ error:",
                    error
                  );
                }
              }}
              isLoading={isCollectiveLoading}
            >
              <Text
                color="white"
                fontSize={{ base: "xs", md: "md" }}
                p="2px"
                fontWeight={700}
                zIndex="inherit"
              >
                {t("Save & Go to Payment")}
              </Text>
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AddressForm;
