import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import {
  useCancelCalendarMeeting,
  useResheduleCalendarMeeting,
} from "features/superadmin/api/client";

import { FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";

import MeetingLogsEditPopover from "../MeetingLogsEditPopover";

type MeetingLogsItemProps = {
  timestamp: string;
  callFromFirstName: string;
  callFromLastName: string;
  payment_status: string;
  meetingId: string;
};

const statusStyles: Record<string, { color: string; backgroundColor: string }> =
  {
    DECLINED: {
      backgroundColor: "rgba(213, 115, 138, 0.2)",
      color: "rgba(213, 115, 138, 1)",
    },
    NOT_PAID: {
      backgroundColor: "rgba(213, 115, 138, 0.2)",
      color: "rgba(213, 115, 138, 1)",
    },
    PENDING: {
      backgroundColor: "rgba(232, 155, 38, 0.2)",
      color: "rgba(232, 155, 38, 1)",
    },
    PAID: {
      backgroundColor: "rgba(44, 132, 117, 1)",
      color: "rgba(255, 255, 255, 1)",
    },
    DEBITED: {
      backgroundColor: "rgba(97, 150, 189, 1)",
      color: "rgba(255, 255, 255, 1)",
    },
    CANCELLED: {
      backgroundColor: "gray.300",
      color: "gray.500",
    },
    ERROR: {
      backgroundColor: "rgba(213, 115, 138, 1)",
      color: "rgba(255, 255, 255, 1)",
    },
  };

const MeetingLogsItem: FunctionComponent<MeetingLogsItemProps> = ({
  timestamp,
  callFromFirstName,
  callFromLastName,
  payment_status,
  meetingId,
}) => {
  const { t } = useTranslation();

  const [calendlyURL, setCalendlyURL] = useState<string | undefined>("");

  const { trigger: cancelEvent, isMutating: isCancelMutating } =
    useCancelCalendarMeeting(meetingId || "");
  const { trigger: resheduleEvent, isMutating: isRescheduleMutating } =
    useResheduleCalendarMeeting(meetingId || "");

  const cancelEventAction = () => {
    cancelEvent().then((response) => {
      if (response && response.link) {
        setCalendlyURL(response.link);
      }
    });
  };

  const resheduleEventAction = () => {
    resheduleEvent().then((response: any) => {
      if (response && response.link) {
        setCalendlyURL(response.link);
      }
    });
  };

  const timestampsDate = new Date(timestamp);

  const styles = statusStyles[payment_status] || {
    backgroundColor: "#FFFFFF",
    color: "#000000",
  };

  const toTitleCase = (str: string) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  return (
    <Flex
      border={"1px solid rgba(0, 38, 55, 0.10)"}
      w="full"
      borderRadius="12px"
      bgColor="rgba(44, 132, 117, 0.05)"
      opacity={payment_status === "CANCELLED" ? 0.5 : 1}
    >
      <VStack
        justifyContent={"center"}
        alignItems={"center"}
        gap={0}
        spacing={0}
        minW={"3.75rem"}
        minH={"100%"}
        borderRight={"1px solid rgba(0, 38, 55, 0.10)"}
      >
        <Text
          fontFamily={"secondary"}
          fontSize={"1.5rem"}
          fontWeight={500}
          color={"blueish.900"}
        >
          {timestampsDate.getDate()}
        </Text>
        <Text
          color={"blueish.900"}
          opacity={0.5}
          textTransform={"uppercase"}
          fontSize={"0.75rem"}
        >
          {timestampsDate
            .toLocaleDateString(t("locale") ?? "en-GB", {
              month: "short",
            })
            .replaceAll(".", "")}
        </Text>
      </VStack>

      <Flex
        p={"0.75rem 0.75rem 0.75rem 1.25rem"}
        justifyContent={"space-between"}
        flexGrow={"1"}
        alignItems={"center"}
      >
        <Box>
          <Text
            color={"blueish.900"}
            fontSize={"1rem"}
            fontWeight={700}
            textTransform={"capitalize"}
          >
            {timestampsDate.toLocaleString(t("locale") ?? "en-GB", {
              hour: "2-digit",
              minute: "2-digit",
            })}
            ,
            {" " +
              timestampsDate.toLocaleString(t("locale") ?? "en-GB", {
                weekday: "short",
              })}
          </Text>

          <Text color={"blueish.900"} fontSize={"1rem"}>
            Behandlare: <b>{callFromFirstName + " " + callFromLastName}</b>
          </Text>
        </Box>

        <Box
          bg={styles.backgroundColor}
          py=".25rem"
          px=".75rem"
          borderRadius=".5rem"
        >
          <Text color={styles.color} fontSize="1rem" fontWeight={700}>
            {payment_status === "ERROR"
              ? "Not Paid"
              : toTitleCase(payment_status)}
          </Text>
        </Box>
      </Flex>

      <VStack
        justifyContent={"center"}
        alignItems={"center"}
        gap={0}
        spacing={0}
        minW={"3.75rem"}
        minH={"100%"}
        borderLeft={"1px solid rgba(0, 38, 55, 0.10)"}
      >
        {payment_status !== "CANCELLED" && (
          <MeetingLogsEditPopover
            calendlyURL={calendlyURL || ""}
            cancelEventAction={cancelEventAction}
            resheduleEventAction={resheduleEventAction}
            isCancelMutating={isCancelMutating}
            isRescheduleMutating={isRescheduleMutating}
            onCloseModal={() => {
              setCalendlyURL(undefined);
            }}
          />
        )}
      </VStack>
    </Flex>
  );
};

export default MeetingLogsItem;
