import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useGetCalendlyCallURL } from "./api/client";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CalendlyEventHandlers } from "react-calendly/typings/components/hooks/useCalendlyEventListener";

type CalendlyModalWidgetProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CalendlyModalWidget: React.FC<CalendlyModalWidgetProps> = ({
  isOpen,
  onClose,
}) => {
  const { data } = useGetCalendlyCallURL();
  const [calendlyUrl, setCalendlyUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (data && data.link) {
      setCalendlyUrl(data.link);
    }
  }, [data]);

  return (
    <Modal onClose={onClose} size="3xl" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          {calendlyUrl && <InlineWidget url={calendlyUrl} />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CalendlyModalWidget;
