import { Flex, Image, List, ListIcon, ListItem, Text } from "@chakra-ui/react";
import logo from "assets/images/logo.svg";
import { Link, useHistory, useLocation } from "react-router-dom";

import HomeIcon from "ui/iconComponents/HomeIcon";
import DashboardIcon from "ui/iconComponents/DashboardIcon";

import SettingsIcon from "ui/iconComponents/SettingsIcon";
import LogoutIcon from "ui/iconComponents/Logout";
import { useLogOut } from "features/login/api/client";
import { useTranslation } from "react-i18next";
import FaqIcon from "ui/iconComponents/FaqIcon";

const DashboardSidebar = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const history = useHistory();
  const { trigger } = useLogOut();

  const logOutFunction = () => {
    trigger();
    history.push("/");
  };

  return (
    <Flex w={{ base: "auto", sm: "50%", md: "100%" }} justify="center">
      <Flex
        w="100%"
        direction="column"
        align={{ base: "center", sm: "flex-start" }}
        gap={{ base: 3, sm: 5 }}
        h={"95vh"}
        flex={1}
      >
        {/* Logo */}

        <Flex>
          <Link to="/">
            <Image
              src={logo}
              alt={"Lamina clinic logo"}
              w={{
                base: "160px",
                sm: "162px",
                md: "192px",
                lg: "256px",
              }}
            />
          </Link>
        </Flex>
        {/* Links */}

        <List spacing={8} ml={"2rem"} mt="6rem">
          <ListItem>
            <Flex align="center" gap={1}>
              <ListIcon>
                <HomeIcon />
              </ListIcon>
              <Link to="/">
                <Text
                  fontSize={"18px"}
                  fontWeight={location.pathname === "/" ? "bold" : "normal"}
                  mt="5px"
                >
                  {t("Home")}
                </Text>
              </Link>
            </Flex>
          </ListItem>
          <ListItem>
            <Flex align="center" gap={1}>
              <ListIcon>
                <DashboardIcon />
              </ListIcon>
              <Link to="/dashboard">
                <Text
                  fontSize={"18px"}
                  fontWeight={
                    location.pathname === "/dashboard" ? "bold" : "normal"
                  }
                  mt="5px"
                >
                  {t("Dashboard")}
                </Text>
              </Link>
            </Flex>
          </ListItem>
          {/*  <ListItem>
            <Flex align="center" gap={1}>
              <ListIcon>
                <SettingsIcon />
              </ListIcon>
              <Link to="/settings">
                <Text
                  fontSize={"18px"}
                  fontWeight={
                    location.pathname === "/settings" ? "bold" : "normal"
                  }
                  mt="5px"
                >
                  {t("Settings")}
                </Text>
              </Link>
            </Flex>
          </ListItem> */}
          <ListItem>
            <Link to="/faq">
              <Flex align="center" gap={1}>
                <ListIcon>
                  <FaqIcon />
                </ListIcon>

                <Text
                  fontSize={"18px"}
                  fontWeight={
                    location.pathname === "/dashboard/faq" ? "bold" : "normal"
                  }
                  mt="5px"
                  cursor="pointer"
                >
                  FAQ
                </Text>
              </Flex>
            </Link>
          </ListItem>
          <ListItem>
            <Flex align="center" gap={1}>
              <ListIcon>
                <LogoutIcon />
              </ListIcon>

              <Text
                fontSize={"18px"}
                fontWeight={location.pathname === "/logout" ? "bold" : "normal"}
                onClick={logOutFunction}
                mt="5px"
                cursor="pointer"
              >
                {t("Logout")}
              </Text>
            </Flex>
          </ListItem>
        </List>
      </Flex>
      <Flex
        height="90vh"
        width={"1px"}
        bg="blueish.500"
        display={{ base: "none", md: "block" }}
        mx={{ base: 1, sm: 2, md: 10 }}
      ></Flex>
    </Flex>
  );
};

export default DashboardSidebar;
