import {
  Box,
  Button,
  Flex,
  Heading,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { t } from "i18next";
import { FunctionComponent, useState } from "react";
import { DateRange } from "react-date-range";
import { useParams } from "react-router-dom";
import ManualSelect from "ui/components/ManualSelect";
import { useCancelTreatment } from "./api/client";
import {
  invokeBasicInfoModal,
  invokeConfirmationModal,
} from "features/genericModal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface PrescriptionCancelllerProps {
  onTreatmentCancelled?(): void;
}

const PrescriptionCancelller: FunctionComponent<
  PrescriptionCancelllerProps
> = ({ onTreatmentCancelled }) => {
  const { id } = useParams<{ id: string }>();
  const [cancelationReason, setCancelationReason] = useState<string>();
  const { t } = useTranslation();

  const { trigger: sendCancelRequest, isMutating: isLoading } =
    useCancelTreatment(id);

  const handleCancelTreatmentClick = () => {
    invokeConfirmationModal(
      t(
        "Are you sure you want to cancel this patient's treatment. This cannot be undone"
      ),
      () => {
        sendCancelRequest({ comment: cancelationReason! })
          .then(() => {
            onTreatmentCancelled?.();
            invokeBasicInfoModal(
              t("Treatment has been successfully cancelled")
            );
          })
          .catch(() => {
            toast.error(t("Something went wrong, please try again later"));
          });
      }
    );
  };

  return (
    <>
      <Flex
        p="1.25rem"
        pb={0}
        gap={"5rem"}
        w={"100%"}
        direction={{ base: "column", sm: "row" }}
      >
        <Box w={"40rem"}>
          <Flex direction="column" gap="1">
            <Heading
              color="#002637"
              opacity="0.5"
              fontSize=".875rem"
              fontFamily="secondary"
              fontWeight={400}
              textTransform="uppercase"
            >
              {t("Please specify a reason for cancelling treatment")}:
            </Heading>

            <Flex mt="1" w="full">
              <Textarea
                _focusVisible={{ borderColor: "rgba(88, 154, 175, 0.5)" }}
                placeholder={`${t(
                  "Briefly write the reason for the cancellation decision"
                )}`}
                size="sm"
                minH={"7rem"}
                value={cancelationReason}
                onChange={(e) => {
                  setCancelationReason(e.target.value);
                }}
              />
            </Flex>
          </Flex>

          <Flex
            w="full"
            gap={"12px"}
            justifyContent={"flex-start"}
            mb="10"
            mt={"1.5rem"}
          >
            <Button
              variant={"filledBlue"}
              bg={"red.700"}
              fontSize={"1rem"}
              fontWeight={700}
              maxH="3rem"
              isDisabled={cancelationReason === "" || !cancelationReason}
              onClick={handleCancelTreatmentClick}
              isLoading={isLoading}
            >
              {t("Cancel treatment")}
            </Button>

            <Button
              variant={"blue"}
              border={"1px solid"}
              borderColor={"blueish.900"}
              fontSize={"1rem"}
              px={"1.5rem"}
              fontWeight={700}
              color="#002637"
              h={"3rem"}
              onClick={() => {}}
            >
              {t("Cancel")}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default PrescriptionCancelller;
