import React, { useRef } from "react";
import {
  Button,
  Center,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import CalendlyEventModal from "features/superadmin/components/WorkingCalendar/components/CalendlyEventModal";
import { GoPencil } from "react-icons/go";
import { t } from "i18next";

type MeetingLogsEditPopoverProps = {
  cancelEventAction: () => void;
  resheduleEventAction: () => void;
  isCancelMutating: boolean;
  isRescheduleMutating: boolean;
  calendlyURL: string;
  onCloseModal: () => void;
};

const MeetingLogsEditPopover: React.FC<MeetingLogsEditPopoverProps> = ({
  cancelEventAction,
  resheduleEventAction,
  isCancelMutating,
  isRescheduleMutating,
  calendlyURL,
  onCloseModal,
}) => {
  const popoverContentRef = useRef<HTMLButtonElement>(null);
  const { isOpen, onClose: closePopover, onOpen } = useDisclosure();

  return (
    <>
      <Popover
        initialFocusRef={popoverContentRef}
        isOpen={isOpen}
        onClose={closePopover}
      >
        <PopoverTrigger>
          <Center onClick={onOpen} flexDir="column">
            <IconButton
              aria-label="Edit"
              icon={<GoPencil />}
              minWidth={0}
              variant="transparent"
              p="0 !important"
              color="#0026377F"
              fontSize="1.5rem"
            />
            <Text color="#0026377F" fontSize=".6rem">
              {t("Edit")}
            </Text>
          </Center>
        </PopoverTrigger>
        <PopoverContent w="max-content">
          <PopoverArrow />
          <PopoverBody>
            <HStack spacing={2} py="1rem">
              <Button
                variant="edit"
                bg="black"
                fontWeight={300}
                fontFamily="main"
                onClick={cancelEventAction}
                borderColor="none"
                color="white"
                _hover={{
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                  backgroundColor: "#589AAF",
                  borderColor: "#589AAF",
                  color: "white",
                }}
                isLoading={isCancelMutating}
              >
                {isCancelMutating ? t("Loading") : t("Cancel")}
              </Button>
              <Button
                variant="edit"
                fontWeight={300}
                fontFamily="main"
                onClick={resheduleEventAction}
                borderColor="#589AAF"
                color="#589AAF"
                _hover={{
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                  backgroundColor: "#589AAF",
                  color: "white",
                }}
                isLoading={isRescheduleMutating}
              >
                {isRescheduleMutating ? t("Loading") : t("Reschedule")}
              </Button>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <CalendlyEventModal
        isOpen={!!calendlyURL}
        calendlyUrl={calendlyURL}
        onClose={() => {
          onCloseModal();
          closePopover();
        }}
      />
    </>
  );
};

export default MeetingLogsEditPopover;
