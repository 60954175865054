import { useRef } from "react";

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";

import { useHistory, useLocation } from "react-router-dom";
import logo_group from "assets/icons/Logo.svg";
import { useTranslation } from "react-i18next";

import { invokeBasicInfoModal } from "features/genericModal";

import burger from "assets/images/hamburger-menu.svg";
import SuperAdminDrawer from "../SuperAdminDrawerView";

const SuperAdminNavbarMobile = () => {
  const ref = useRef<HTMLButtonElement>(null);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const handleModalOpen = () => {
    if (location.pathname === "/prescription") {
      invokeBasicInfoModal(
        `${t(
          "If you switch the language, unfortunately, the entire prescription process will not be saved. Therefore, the prescription needs to be completed in one language."
        )}`,
        `${t("Sorry")}`
      );
    }
  };

  return (
    <Flex w="full" direction="column" gap="3" align="center" px="2">
      <Flex w="full" justify="space-between">
        <Image
          src={logo_group}
          alt="logo_group"
          h="2rem"
          cursor={"pointer"}
          onClick={() => {
            history.push("/");
          }}
        />

        <Flex gap="3">
          <Popover
            arrowShadowColor="transparent"
            returnFocusOnClose={true}
            initialFocusRef={ref}
            closeOnBlur={true}
          >
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button
                    variant="ghost"
                    color={"black"}
                    onClick={handleModalOpen}
                    ref={ref}
                  >
                    {i18n.language.toUpperCase()}
                  </Button>
                </PopoverTrigger>
                <PopoverContent zIndex={4} bg={"#FFF"} width={"80px"}>
                  <PopoverArrow
                    backgroundColor={"white"}
                    border="1px"
                    borderBottomColor={"transparent"}
                    borderRightColor={"transparent"}
                    borderLeftColor={"#e4dada "}
                    borderTopColor={"#e4dada "}
                  />

                  <PopoverBody
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <Button
                      onClick={() => handleLanguageChange("en")}
                      variant="ghost"
                      color={"black"}
                    >
                      EN
                    </Button>
                    <Button
                      onClick={() => handleLanguageChange("sv")}
                      variant="ghost"
                      color={"black"}
                    >
                      SV
                    </Button>
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>

          <Image src={burger} alt="down" onClick={onOpen} />
        </Flex>

        <Drawer isOpen={isOpen} onClose={onClose} size={"full"}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody bg="rgba(155, 194, 207, 0.1)">
              <SuperAdminDrawer onClose={onClose} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Flex>
  );
};

export default SuperAdminNavbarMobile;
