import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Avatar,
  Heading,
  VStack,
  Center,
  IconButton,
} from "@chakra-ui/react";
import { FunctionComponent, useContext, useEffect } from "react";
import { SocketContext } from "../VideocallContext";
import PropagateLoader from "react-spinners/PropagateLoader";
import { MdOutlineCall, MdOutlineCallEnd } from "react-icons/md";
import ringtone from "../../assets/sounds/default_ring.wav";
import { useAudio } from "services/hooks/useAudio";
import { useAdminUserInfo } from "features/admin/api/client";

interface IncomingCallModalProps {
  callerName?: string;
  acceptCall?(): void;
  declineCall?(): void;
  isOpen: boolean;
  onClose(): void;
}

const IncomingCallModal: FunctionComponent<IncomingCallModalProps> = ({
  isOpen,
  onClose,
  acceptCall,
  declineCall,
  callerName,
}) => {
  const { play, stop } = useAudio(ringtone, true);

  const {
    answerCall,
    endCall,
    declineCall: declineContextCall,
    call,
  } = useContext(SocketContext);

  const acceptCallCallback = acceptCall ?? answerCall;

  const declineCallCallback = declineCall ?? declineContextCall;

  const { data: callerInfo } = useAdminUserInfo(call?.from ?? "");

  const finalCallerName =
    callerName ?? callerInfo
      ? callerInfo?.first_name + " " + callerInfo?.last_name
      : call?.from;

  //TODO: uncomment this because we were listening to Paranoid
  // useEffect(() => {
  //   if (isOpen) {
  //     play();
  //     console.log("START RINGTONE");
  //   } else {
  //     stop();
  //     console.log("STOP RINGTONE");
  //   }
  // }, [isOpen]);

  const overlayClickHandler = () => {
    declineCallCallback();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={overlayClickHandler} isCentered>
      <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
      <ModalOverlay />
      <ModalContent maxW={"26rem"}>
        <ModalHeader textAlign={"center"} fontWeight={400} fontSize="1.5rem">
          Incoming call
        </ModalHeader>

        <ModalBody pb={"2rem"}>
          <VStack>
            <Avatar h={"20vh"} w={"20vh"} />
            <Heading fontSize={"2rem"} fontWeight={"500"}>
              {finalCallerName}
            </Heading>
            <Center w="100%" bg={"red !important"} pr={"0.5rem"}>
              <PropagateLoader
                color="rgba(88, 154, 175, .3)"
                speedMultiplier={0.3}
              />
            </Center>
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent={"center"} gap={"3rem"} pb={"2rem"}>
          <IconButton
            icon={<MdOutlineCall />}
            fontSize={"1.75rem"}
            aria-label={""}
            minWidth={0}
            bg={"#1ad67b"}
            height={"4rem"}
            width={"4rem"}
            borderRadius={"50%"}
            padding={"0 !important"}
            color={"white"}
            border={"none"}
            transition={"all 0.3s ease-out"}
            _hover={{
              transform: "translateY(-0.25rem) rotate(-15deg)",
              boxShadow: "0px 0px 1rem #1ad67b",
            }}
            onClick={() => {
              acceptCallCallback();
              onClose();
            }}
          />
          <IconButton
            icon={<MdOutlineCallEnd />}
            fontSize={"1.75rem"}
            aria-label={""}
            minWidth={0}
            bg={"#e65a50"}
            border={"none"}
            height={"4rem"}
            width={"4rem"}
            padding={"0 !important"}
            borderRadius={"50%"}
            color={"white"}
            _hover={{
              transform: "translateY(0.25rem)",
              boxShadow: "0px 0px 1rem #e65a50",
            }}
            onClick={declineCallCallback}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default IncomingCallModal;
