import { Center, Image, Box, Flex } from "@chakra-ui/react";

import logo_group from "assets/icons/logo_group.svg";

import { useState } from "react";

import EmailForResetForm from "features/adminLogin/components/EmailForResetForm";
import ResetFormSuccess from "features/adminLogin/components/ResetFormSuccess";

const AdminLoginReset = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  return (
    <Flex
      w="full"
      direction="column"
      align="center"
      justify="center"
      mt={{ base: "-3rem", sm: "0rem" }}
    >
      <Image src={logo_group} />

      <Center
        flexDirection="column"
        w="full"
        py={"5.5rem"}
        px={{ base: "0rem", md: "4rem" }}
      >
        <Box
          w="full"
          py={{ base: "1rem", sm: "2rem", md: "", lg: "2.5rem" }}
          px={{ base: "1rem", sm: "10rem", md: "9.5rem", lg: "16.9rem" }}
        >
          <>
            {showSuccessMessage ? (
              <ResetFormSuccess />
            ) : (
              <EmailForResetForm
                setShowSuccessMessage={setShowSuccessMessage}
              />
            )}
          </>
        </Box>
      </Center>
    </Flex>
  );
};

export default AdminLoginReset;
