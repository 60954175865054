import {
  Box,
  Divider,
  Flex,
  Heading,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import JournalTriage from "../JournalTriage";
import { JournalEntryType, PrescriptionEvent } from "../../api/types";
import { actionStyles, formatAction } from "../../utils";
import { useTranslation } from "react-i18next";

import PrescriptionDetails from "./components/PrescriptionDetails";

type JournalCardProps = {
  status: string;
  action_key?: string;
  title?: string;
  writer?: string;
  date?: string;
  allAnswers?: Record<string, string>;
  action: string;
  details: any;
  prescriptionEvent: PrescriptionEvent;
};

const JournalCard: FunctionComponent<JournalCardProps> = ({
  status,
  title,
  writer,
  date,
  allAnswers,
  action,
  details,
  prescriptionEvent,
}) => {
  const { t } = useTranslation();

  const actionStyle = actionStyles[action] || {
    backgroundColor: "#FFFFFF",
    color: "#000000",
  };

  const isFieldDifferent = (
    a: Record<string, any>,
    b: Record<string, any>,
    fieldName: string
  ) => {
    if (!a || !b) return false;
    return a?.[fieldName] !== b?.[fieldName];
  };

  const detailsParser = (details: any, action: string) => {
    if (action.toLowerCase() === "stabilize") {
      return (
        <>
          {t("User has been stabilized for the duration of")}{" "}
          <b>
            {details?.length_in_days} {t("days")}
          </b>{" "}
          {t("with dosage of")} <b>{details?.dose}mg</b>. {t("Reason")}:{" "}
          <b>{!title || title === "" ? "not specified" : title}</b>
        </>
      );
    }

    if (action.toLowerCase() === "update_plan") {
      const previousPlanDetails = details.previousPlan;

      return (
        <Box>
          <>{t("Patient has been assigned a new plan")}</>
          <Box
            p={"1rem"}
            border={"1px solid rgba(0, 0, 0, 0.2)"}
            borderRadius={"0.5rem"}
          >
            <Heading
              fontSize="1.25rem"
              fontWeight={700}
              color={
                !isFieldDifferent(previousPlanDetails, details, "medication")
                  ? "rgba(0, 38, 55, 1)"
                  : "green.500"
              }
              fontFamily="main"
            >
              {details.medication}
            </Heading>
            <Text
              color={
                !isFieldDifferent(previousPlanDetails, details, "start_dose")
                  ? "rgba(0, 38, 55, 1)"
                  : "green.500"
              }
              fontSize="1rem"
            >
              {t("Dose")} :{" "}
              <Box as="span" fontWeight={700}>
                {`${details.start_dose} mg > 0 mg`}
              </Box>
            </Text>
            <Text
              color={
                !isFieldDifferent(previousPlanDetails, details, "tapering_pace")
                  ? "rgba(0, 38, 55, 1)"
                  : "green.500"
              }
              fontSize="1rem"
            >
              {t("Pace")}:{" "}
              <Box as="span" fontWeight={700}>
                {details?.tapering_pace}%
              </Box>
            </Text>
            <Text
              color={
                !isFieldDifferent(
                  previousPlanDetails,
                  details,
                  "period_duration"
                )
                  ? "rgba(0, 38, 55, 1)"
                  : "green.500"
              }
              fontSize="1rem"
            >
              {t("Intervall")}:{" "}
              <Box as="span" fontWeight={700}>
                {details?.period_duration} {t("days")}
              </Box>
            </Text>
            <Text
              color={
                !isFieldDifferent(
                  previousPlanDetails,
                  details,
                  "length_in_weeks"
                )
                  ? "rgba(0, 38, 55, 1)"
                  : "green.500"
              }
              fontSize="1rem"
            >
              {t("Total")}:{" "}
              <Box as="span" fontWeight={700}>
                {details?.length_in_weeks} {t("weeks")}
              </Box>
            </Text>
          </Box>
        </Box>
      );
    }

    if (action.toLowerCase() === "assign_plan") {
      return (
        <Box>
          <>{t("Patient has been assigned the following plan")}:</>
          <Box
            p={"1rem"}
            border={"1px solid rgba(0, 0, 0, 0.2)"}
            borderRadius={"0.5rem"}
          >
            <Heading
              fontSize="1.25rem"
              fontWeight={700}
              color="rgba(0, 38, 55, 1)"
              fontFamily="main"
            >
              {details?.medication}
            </Heading>
            <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
              Dosage :{" "}
              <Box as="span" fontWeight={700}>
                {`${details?.start_dose} mg > 0 mg`}
              </Box>
            </Text>
            <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
              {t("Pace")}:{" "}
              <Box as="span" fontWeight={700}>
                {details?.tapering_pace}%
              </Box>
            </Text>
            <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
              {t("Intervall")}:{" "}
              <Box as="span" fontWeight={700}>
                {details?.period_duration} {t("days")}
              </Box>
            </Text>
            <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
              {t("Total")}:{" "}
              <Box as="span" fontWeight={700}>
                {details?.length_in_weeks} {t("weeks")}
              </Box>
            </Text>
          </Box>
        </Box>
      );
    }

    if (status === JournalEntryType.Prescription) {
      return <PrescriptionDetails prescriptionEvent={prescriptionEvent} />;
    }
  };

  return (
    <VStack align="flex-start" px="2rem">
      {status === JournalEntryType.Triage ? (
        <JournalTriage allAnswers={allAnswers} />
      ) : (
        <>
          <Flex gap="2">
            <Box
              w="max-content"
              py="1"
              px="2"
              borderRadius=".5rem"
              fontWeight={700}
              color={actionStyle.color}
              bg={actionStyle.backgroundColor}
            >
              {t(formatAction(action).toUpperCase())}
            </Box>
          </Flex>

          <Heading
            color="rgba(0, 38, 55, 1)"
            fontSize="1rem"
            fontWeight={400}
            fontFamily="main"
          >
            {detailsParser(details, action) ?? title}
          </Heading>

          <Flex w="full" align="center" justify="space-between">
            {status !== JournalEntryType.Prescription && (
              <Text
                color="rgba(0, 38, 55, 0.5)"
                fontSize="14px"
                fontWeight={700}
              >
                {writer}
              </Text>
            )}
            <Spacer />
            <Text color="rgba(0, 38, 55, 0.5)" fontSize="14px" fontWeight={700}>
              {date}
            </Text>
          </Flex>

          <Divider h="1" w="full" colorScheme="rgba(88, 154, 175, 0.2)" />
        </>
      )}
    </VStack>
  );
};

export default JournalCard;
