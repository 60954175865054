import {
  Flex,
  Heading,
  Text,
  Image,
  Box,
  UnorderedList,
  ListItem,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import left_arrow from "assets/icons/left_arrow.svg";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";

const TermOfPurchase = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { basicEvent } = useGoogleAnalytics();
  return (
    <Box pb={"5vh"} w="full">
      <Flex
        w="full"
        bg="rgba(255, 255, 255, 1)"
        borderRadius="1.5rem"
        p={{ base: "1.25rem", sm: "3.75rem" }}
        align="flex-start"
        direction="column"
        gap=".625rem"
      >
        <Flex
          align="center"
          gap=".875rem"
          onClick={() => history.push("/")}
          cursor="pointer"
        >
          <Image src={left_arrow} />
          <Text fontSize="1.125rem" color="black" fontWeight={500}>
            {t("Back")}
          </Text>
        </Flex>
        <Flex w="100%" align="center" justify="center" mb="1.875rem">
          <Heading fontSize="4xl" color="blueish.500">
            LAMINA CLINICS ANVÄNDARVILLKOR
          </Heading>
        </Flex>

        {/* 1 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Lamina Clinic Sweden AB, organisationsnummer 559411–0859 med
              adress Graningegränd 18, 122 42 Enskede, Sverige (i fortsättningen
              kallat “Lamina Clinic”, ”kliniken” eller ”vi”) tillhandahåller det
              digitala vårdkonceptet Lamina Clinic. Lamina Clinic tillhandahålls
              genom vår webbplats www.laminaclinic.com (”Webbplats”) och vår
              mobila applikation (”App”).
            </Text>

            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Dessa avtalsvillkor (”Användarvillkor”) gäller när du registrerar
              dig som användare/kund/patient hos Lamina Clinic (i fortsättningen
              kallad ”Användaren”, ”du” eller ”dig”) genom att skapa ett konto
              (”Konto”) på vår Webbplats.
            </Text>

            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Registrering av Konto sker via vår Webbplats genom verifiering via
              BankID. Ditt Konto är personligt och du får inte ge någon annan
              tillgång till ditt BankID eller på annat sätt ge en annan person
              åtkomst till ditt Konto hos Lamina Clinic. Om du har anledning att
              tro att någon annan har fått tillgång till ditt Konto ska du
              omedelbart kontakta oss.
            </Text>

            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Om du inte accepterar dessa Användarvillkor ber vi dig att inte
              skapa ett Konto och/eller använda våra Tjänster.
            </Text>

            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Personer under 18 år får inte skapa ett Konto och/eller använda
              våra Tjänster.
            </Text>
          </Flex>
        </Flex>

        {/* 2 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              {t("DEFINITIONS")}
            </Heading>
            <UnorderedList>
              <ListItem>
                “Konto”: avser en Användares personliga digitala användarkonto,
                som skapats med BankID på Webbplats eller App
              </ListItem>
              <ListItem>“Användare”: avser en person med ett Konto</ListItem>
              <ListItem>
                ”Behandling”: avser det förlopp som påbörjas när en Användare
                initialt bokar ett möte via vår Webbplats eller App. En
                Behandling ger dig tillgång till Tjänsten och hela Appen, samt
                rätt att använda dessa på det sätt som framgår av
                Användarvillkoren.
              </ListItem>
              <ListItem>
                ”Tjänster” eller ”Tjänst”: avser de vårdtjänster som
                tillhandahålls av Lamina Clinic.
              </ListItem>
              <ListItem>
                “Avgift”, “Avgiften” eller “Avgifter”: avser kostnaden som för
                patienten är förknippade med att nyttja de vårdtjänster som
                tillhandahålls av Lamina Clinic.
              </ListItem>
              <ListItem>
                ”Lamina Clinic”: avser den gemensamma benämningen för
                Webbplatsen och Appen genom vilka Tjänsterna tillhandahålls.
              </ListItem>
              <ListItem>
                ”Medicineringsplan”: avser din individualiserade plan och
                dosering för nedtrappning av din pågående behandling med
                antidepressiva läkemedel. En Medicineringsplan består av en
                individanpassad dosering och längd på nedtrappningen.
              </ListItem>
              <ListItem>
                ”Mediciner”: avser dina förskrivna läkemedel som tillverkas av
                ett partnerapotek i en specialanpassad dosering
                (extempore-tillverkning) baserat på din Medicineringsplan.
              </ListItem>
            </UnorderedList>
          </Flex>
        </Flex>

        {/* 3 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              {t("WHAT IS THE LAMINA CLINIC?")}
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Lamina Clinic är ett digitalt vårdkoncept som riktar sig till dig
              som har en pågående behandling med antidepressiva läkemedel och
              vill trappa ned eller sluta med din medicinering.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Inom ramen för vår Tjänst tillhandahåller vi rekommendationer för
              nedtrappning av antidepressiva läkemedel genom en individanpassad
              Medicineringsplan och kontinuerlig uppföljning via vår App. Du
              ansvarar själv för att godkänna den Medicineringsplan vi föreslår
              till dig och har även möjlighet att justera din Medicineringsplan
              om du exempelvis vill genomföra din nedtrappning under en längre
              period eller pausa din nedtrappning. Förändringar verkställs av
              din Medicineringsplan i samband med att våra läkare förskriver ett
              förnyat recept till dig.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Under förutsättning att våra läkare förskriver ett recept till dig
              tillverkas dina Mediciner i en specialanpassad dosering baserat på
              din Medicineringsplan. Dina Mediciner förskrivs av våra läkare och
              tillverkas av ett av våra partnerapotek. Vilket beror på vilken
              medicin och vilka doser du behöver och levereras därefter av oss
              till din angivna leveransadress i Sverige, eller direkt till ditt
              apotek.
            </Text>
          </Flex>
        </Flex>

        {/* 4 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              TJÄNSTEKOSTNAD OCH BETALNING
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Tjänstekostnaden är det eller de pris som anges på vår Webbplats
              vid tiden för ditt köp. Priset anges i svenska kronor (SEK) och
              visas inklusive moms. De priser som anges på Webbplatsen och i
              Appen varierar efter vilket sorts besök eller tjänst du gör eller
              mottar, till exempel om det är ett möte hos en sjuksköterska eller
              en läkare. Priserna kan ändras när som helst och utan meddelande.
              Sådana ändringar påverkar inte betalningar för besök eller
              tjänster som gjorts eller mottagits före prisändringen.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              För att kunna använda Lamina Clinics Tjänster så måste du i
              samband med mötesbokning ange betalningsuppgifter hos en
              tredjepartsbetalleverantör. Efter att du har haft ett möte eller
              mottagit en tjänst genom din användning av Tjänsten, kommer Lamina
              Clinic att verkställa din betalning av tillämpliga avgifter på
              uppdrag av tredjepartbetalleverantören. Betalning av avgifterna på
              detta sätt ska anses vara detsamma som en betalning som gjorts
              direkt av dig till tredjepartsbetalleverantören.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Avgifter kan också komma inkludera andra avgifter där det är
              tillämpligt, såsom bokningsavgifter, förseningsavgifter eller
              delbetalningsavgifter, och kommer att inkludera tillämpliga
              skatter där det krävs enligt lag. Avgifter som betalats av dig är
              slutgiltiga och återbetalas inte, såvida inte annat bestäms av
              Lamina Clinic.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Alla Avgifter förfaller omedelbart och betalning kommer att
              verkställas av Lamina Clinic med hjälp av den föredragna
              betalningsmetod som anges i ditt Konto, varefter Lamina Clinic
              skickar dig ett kvitto via e-post. Om din primära betalningsmetod
              bedöms vara utgången, ogiltig eller på annat sätt inte kan
              debiteras, godkänner du att Lamina Clinic, som
              tredjepartsbetalleverantörens begränsade betalningsinkassoagent,
              kan använda en sekundär betalningsmetod i ditt Konto, om sådan
              finns tillgänglig. Om en betalning inte är möjlig att verkställa
              efter flera försök, kommer din tillgång till Tjänsten begränsas
              tills dess att betalningen och eventuell förseningsavgift är
              verkställd.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              I förhållandet mellan dig och Lamina Clinic förbehåller sig Lamina
              Clinic rätten att fastställa, ta bort och/eller ändra Avgifter för
              alla eller vissa tjänster eller varor som erhållits genom
              användning av Tjänsterna när som helst enligt Lamina Clinics
              beslut. Lamina Clinic kommer att göra rimliga ansträngningar för
              att informera dig om Avgifter som kan tillkomma via Lamina Clinics
              Tjänst, och du har i din tur en skyldighet att hålla dig
              informerad om vilka Avgifter som uppstår genom ditt användande av
              Tjänsten. Du ansvarar dock alltid för de Avgifter som uppstår,
              oavsett om du är medveten om sådana Avgifter eller deras belopp
              eller ej.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Lamina Clinic kan från tid till annan tillhandahålla vissa
              användare med kampanjerbjudanden och rabatter som kan resultera i
              olika belopp som debiteras för samma eller liknande tjänster eller
              varor som erhållits genom användning av Tjänsterna, och du
              godkänner att sådana kampanjerbjudanden och rabatter, såvida de
              inte också görs tillgängliga för dig, inte ska ha någon inverkan
              på din användning av Tjänsterna eller Avgifterna som tillämpas på
              dig.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Du kan när som helst avboka din mötesbokning eller begäran om
              Tjänster innan tredjepartsbetalleverantörens debitering, men om
              avbokningen sker inom 24 timmar från Tjänstens planerade utförande
              kan du debiteras en avbokningsavgift.
            </Text>
          </Flex>
        </Flex>

        {/* 5 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              ATT PÅBÖRJA BEHANDLING
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Du kan skapa ett Konto hos Lamina Clinic utan kostnad, men för att
              få tillgång till våra Tjänster och Appen behöver du gå vidare och
              bli godkänd för och påbörja en Behandling hos oss. Du behöver
              också ha minst en sparad betalmetod hos
              tredjepartsbetalleverantören.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Du påbörjar en Behandling genom att boka ett första besök och
              klicka i ”Jag bekräftar att jag läst och godkänt
              Användarvillkoren” i vår digitala kassa i Appen eller på
              Webbplatsen när du betalar för besöket. Du godkänner då att dessa
              Användarvillkor gäller.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              För att Behandlingen ska fortsätta löpa efter den första
              interaktionen med Lamina Clinics läkare måste denne ha bedömt att
              du är lämplig för Tjänsten och godkänna fortsatt Behandling. Om
              lämplighet inte föreligger kan Lamina Clinic när som helst avbryta
              eller avsluta din Behandling.
            </Text>
          </Flex>
        </Flex>

        {/* 6 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              BEHANDLINGENS LÖPTID OCH AVBRYTANDE
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Behandlingen pågår som regel tills dess att du Medicinerat
              färdigt, antingen genom att du trappat ned helt eller att du
              hittat en dos du är nöjd med och vill fortsätta stå på. Notera att
              i vissa fall kan det finnas behov av att du som patient är kvar på
              uppföljning i upp til sex (6) månader efter att du avslutat din
              medicinering. Detta då vissa utsättningssymtom kan ha en
              eftersläpande effekt.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Du kan dock när som helst meddela att du vill avsluta Behandlingen
              hos Lamina Clinic, men när våra läkare förskrivit Mediciner till
              dig kommer vi ändå behöva följa upp med dig tills dess att
              Medicinen är slut. Detta eftersom våra läkare har
              behandlingsansvar för dig under tiden du står på den Medicin de
              skrivit ut. Från och med att vi har mottagit ditt meddelande av
              uppsägning kommer vi inte att skriva ut någon förnyad dos av
              Mediciner, och uppföljningen kommer att upphöra så snart du slutat
              ta den föreskrivna Medicinerna eller när de tagit slut, beroende
              på vilket av detta som inträffar först.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Om du vill avbryta din Behandling innan din Medicineringsplan är
              slut behöver du meddela oss skriftligen via kundtjanst
              info@laminaclinic.com. Notera att ditt fullföljande av en
              Medicineringsplan förutsätter att du är i Behandling hos oss, och
              så länge som du står på din Medicineringsplan måste du vara i
              Behandling. Både Behandling och Medicineringsplan måste alltså
              sägas upp samtidigt. Väljer du att avbryta din Behandling under
              pågående Medicineringsplan ansvarar du för att, vid behov, söka
              vårdkontakt vid annan vårdgivare.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Du är också medveten om antalet veckor för nedtrappning i din
              Medicineringsplan kan komma att förlängas under din användning av
              Tjänsten, om du godkänner en sådan förändring av din
              Medicineringsplan. Att din Medicineringsplan initialt satts till
              exempelvis 24 veckor ska därför inte på något sätt ses som en
              utfästelse om att ditt behov av Tjänsten kommer att motsvara dessa
              24 veckor. En rekommendation från oss om att förlänga din
              Medicineringsplan är alltid baserad på vår medicinska bedömning av
              dig och får inte föranledas av några andra skäl.
            </Text>
          </Flex>
        </Flex>

        {/* 7 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              REKLAMATION
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Om du anser att våra Tjänster är felaktiga i enlighet med
              bestämmelserna i gällande konsumentskyddslagstiftning har du rätt
              att reklamera felet. Reklamation ska ske till oss på de
              kontaktuppgifter som framgår nedan och inom skälig tid efter det
              att du har märkt eller borde ha märkt felet.
            </Text>
          </Flex>
        </Flex>

        {/* 8 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              ÅNGERRÄTT
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              I vissa fall, till exempel vid behov av mycket små doser eller mer
              ovanliga läkemedel, så kan dina Mediciner komma att levereras
              direkt till dig istället för att du själv hämtar ut dem på ditt
              apoteket. Då Mediciner utgör receptförskrivna varor omfattas ditt
              köp av dessa inte av ångerrätten i Lag (2005:59) om distansavtal
              och avtal utanför affärslokaler (”Distansavtalslagen”).
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Mediciner och läkemedel som kommer i retur till oss måste enligt
              lag destrueras. Om du ändå skulle returnera Mediciner till oss
              kommer dessa destrueras utan någon rätt till återbetalning. Det
              gäller även om dina Mediciner inte kan levereras och går i retur
              till oss.
            </Text>
          </Flex>
        </Flex>

        {/* 9 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              ANVÄNDARENS ANSVAR
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              För att vi ska kunna tillhandahålla Tjänsterna till dig och ge dig
              adekvat vård och rådgivning ska du helst dagligen men minst tre
              gånger per vecka besvara de självskattningsformulär som
              tillhandahålls via Appen. Du åtar dig även att besvara de frågor
              som ställs via vår Webbplats och i App om din hälsa, dina
              sjukdomar, nuvarande symptom och läkemedel sanningsenligt, på
              samma sätt som vid ett vanligt läkarbesök.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Du är också medveten om att nedtrappning av antidepressiva
              läkemedel kan leda till olika former av utsättningssymtom. Om vi,
              via dina rapporterade symptom i Appen, får en indikation på att
              dina symptom inte utgör utsättningsymptom kan vi inte bedöma eller
              ordinera medicin eller behandling för dessa, och du kommer då
              uppmanas att ta kontakt med din ordinarie behandlande läkare.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Skulle du under tiden som registrerad Användare hos Lamina Clinic
              drabbas av akut eller icke-akut sjukdom måste en annan vårdgivare
              kontaktas och vård sökas. Du som Användare ska även kontakta oss
              om detta sker.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Du som Användare accepterar och förbinder dig vidare att:
            </Text>

            <UnorderedList>
              <ListItem>
                Helst dagligen men minst tre gånger per kalendervecka besvara de
                självskattningsformulär som tillhandahålls via Appen
              </ListItem>
              <ListItem>
                hålla dina lämnade uppgifter uppdaterade och meddela oss om
                ändringar av tidigare lämnade uppgifter
              </ListItem>
              <ListItem>
                inte sprida eller på annat sätt överföra data eller information
                som gör intrång eller bidrar till intrång i immateriella
                rättigheter eller företagshemligheter, du inte, enligt lag eller
                avtal har rätt att sprida, innehåller virus, trojaner eller
                annan skadlig mjukvara/kod eller på annat sätt kan ha skada
                eller ha negativ effekt på Lamina Clinic
              </ListItem>
            </UnorderedList>

            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Du är själv ansvarig för att säkerställa din åtkomst till det
              nätverk som behövs för att kunna använda Tjänsten. Det kan
              tillkomma kostnader för till exempel överföring av data och
              meddelandetjänster. Dessa kostnader står inte vi för. Vidare är du
              själv ansvarig för att skaffa och uppdatera den hård- eller
              mjukvara som behövs för att få tillgång till Tjänsten.
            </Text>
          </Flex>
        </Flex>

        {/* 10 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              ÅNGERRÄTT
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Vår verksamhet är inriktad på att tillhandahålla rådgivning för
              nedtrappning av antidepressiva läkemedel. Vi har inte möjlighet
              att diagnostisera eller behandla eventuella andra sjukdomar som du
              har eller som uppkommer under tiden du har ett Konto hos oss.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Lamina Clinic är en registrerad vårdgivare som lyder under hälso-
              och sjukvårdslagen, dataskyddsförordningen, patientdatalagen samt
              patientsäkerhetslagen. De Tjänster som tillhandahålls genom Lamina
              Clinic utförs med beaktande av dessa regelverk.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Vi ansvarar för att rådgivande och förskrivande läkare vid Lamina
              Clinic innehar svensk läkarlegitimation eller annan nödvändig
              kompetens för utförandet av våra Tjänster vid Lamina Clinic.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Vi baserar våra råd och Medicineringsplan på de uppgifter som du
              lämnat till oss. Vårt ansvar för korrekt och adekvat rådgivning
              förutsätter således att de uppgifter du lämnat till oss
              överensstämmer med verkligheten.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Vi ansvarar inte för tillgängligheten i vår App eller Webbplats,
              tekniska störningar eller förlust av data, om inte annat gäller
              enligt tillämplig lagstiftning.
            </Text>
          </Flex>
        </Flex>

        {/* 11 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              IMMATERIELLA RÄTTIGHETER
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Under förutsättning att du innehar ett Abonnemang och accepterar
              och följer dessa Användarvillkor, erhåller du en icke-exklusiv,
              icke överlåtbar, återkallelig licens att ladda ned, installera och
              använda Appen i objektkodform på en mobil enhet som du äger eller
              har tillgång till, i syfte att nyttja Appen för dess avsedda
              syfte. Du erhåller ingen annan licens att använda ovan nämnda
              immateriella rättigheter utom vad som uttryckligen anges i dessa
              Användarvillkor.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Du förstår och accepterar att innehåll som publiceras inom
              Tjänsten kan tillhöra tredje part, samt att vi inte har kontroll
              över sådant innehåll. Därför förstår och accepterar du att vi inte
              kan hållas ansvariga för sådant innehåll som tillhandahålls av
              tredje part och visas upp eller finns tillgängligt inom ramen för
              Tjänsten.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Vi ger varken dig eller någon annan tillstånd att kopiera eller
              förändra Appen i sin helhet eller delar av den. Detta innebär att
              ingen utan vårt samtycke får utveckla, göra tillägg till,
              dekompilera eller göra reverse engineering på Appen eller dess
              komponenter. Det är inte tillåtet att återskapa källkoden eller
              dess funktionalitet, eller att göra kopior av mjukvaran, utöver
              vad tvingande lag ger rätt till. Samtliga rättigheter,
              innefattande men inte uteslutande alla immateriella rättigheter,
              till Lamina Clinic inklusive den tekniska lösningen och eventuellt
              innehåll däri, tillhör eller Lamina Clinic eller förfogas över med
              licens av oss.
            </Text>
          </Flex>
        </Flex>

        {/* 12 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              PERSONUPPGIFTER
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Vi är personuppgiftsansvarig de personuppgifter som behandlas inom
              ramen för Lamina Clinic. Du kan läsa om vår behandling av
              personuppgifter i vår Integritetspolicy.
            </Text>
          </Flex>
        </Flex>

        {/* 13 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              TREDJEPARTSINTEGRATIONER
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Lamina Clinic är integrerad med applikationer och tjänster från
              tredje part (”tredjepartsapplikationer”), i syfte att göra Lamina
              Clinic tillgänglig för dig. Dessa tredjepartsapplikationer kan ha
              egna användarvillkor och integritetspolicyer. Vi ansvarar inte för
              beteenden, funktioner eller innehåll från någon
              tredjepartsapplikation.
            </Text>
          </Flex>
        </Flex>

        {/* 14 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              FORCE MAJEURE
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Vi är befriade från att fullgöra våra förpliktelser i
              Användarvillkoren och påföljder därav om befriande omständigheter
              föreligger, inklusive men ej begränsat till, myndighetsåtgärd,
              nytillkommen eller ändrad lagstiftning, konflikt på
              arbetsmarknaden, pandemi/epidemi, blockad, strejk, brand eller
              översvämning, sabotage eller olyckshändelse, och om sådan
              omständighet försvårar, förhindrar eller försenar Lamina Clinics
              fullgörande.
            </Text>
          </Flex>
        </Flex>

        {/* 15 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              ÖVERLÅTELSE
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Du får inte överlåta eller överföra några rättigheter,
              skyldigheter eller licenser som framgår av dessa Användarvillkor.
              Vi kan överlåta och överföra våra rättigheter enligt dessa
              Användarvillkor utan ditt samtycke och utan meddelande till dig.
            </Text>
          </Flex>
        </Flex>

        {/* 16 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              ÄNDRINGAR
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Vi har rätt att ensidigt ändra dessa Användarvillkor och kommer
              att informera dig senast trettio (30) dagar innan en ändringar av
              dessa Användarvillkor implementeras. En kortare tidsfrist kan
              tillämpas där så krävs för att vi ska kunna följa tillämplig lag
              eller för att undvika att vi eller tredje man lider en mer än
              oväsentlig skada och som vi inte skäligen kunnat förutse eller
              förhindra. Om du inte accepterar ändringen har du rätt att säga
              upp Användarvillkoren i enlighet med vad som anges om avbrott av
              Behandling i punkt Behandling: Löptid och avbrytande.
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Vi kommer att informera dig om ändringar genom att mejla den
              e-postadress som du har angett vid skapandet av ditt Konto eller
              som du senare har registrerat inom ramen för Tjänsten och som har
              ersatt sådan ursprunglig mejladress.
            </Text>
          </Flex>
        </Flex>

        {/* 17 */}
        <Flex
          w="full"
          direction="column"
          gap="1rem"
          px={{ base: ".313rem", sm: "1.25rem" }}
          mt="1.25rem"
        >
          <Flex w="full" direction="column" gap=".5rem">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              TILLÄMPLIG LAG OCH TVISTER
            </Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Dessa Användarvillkor ska styras av och tolkas i enlighet med
              materiell svensk rätt. Tvist i anledning av dessa Användarvillkor
              ska avgöras av allmän svensk domstol med Stockholms tingsrätt som
              första instans.
            </Text>
          </Flex>
        </Flex>

        <Flex w="full" align="center" justify="center" mt="2rem">
          <Flex
            borderTop="2px solid black"
            direction="column"
            align="center"
            justify="center"
            py="1rem"
            gap="1rem"
          >
            <Heading>{t("CONTACT DETAILS")}</Heading>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Lamina Clinic Sweden AB
            </Text>
            <Text color="black" fontSize="1.125rem" fontWeight={500}>
              Graningegränd 18, 122 42 Enskede
            </Text>
            <ChakraLink
              color={"white !important"}
              href="mailto:info@laminaclinic.com"
              onClick={() => {
                basicEvent("Contact", "User clicked footer link");
              }}
            >
              <Text
                color="black"
                fontSize="1.125rem"
                fontWeight={500}
                _hover={{
                  cursor: "pointer",
                  color: "blueish.500",
                }}
              >
                info@laminaclinic.com
              </Text>
            </ChakraLink>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TermOfPurchase;
