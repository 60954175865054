import SvgIconConstituentValues from "./types/svgPropsType";

const NotUseAppIcon = (props: SvgIconConstituentValues) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 22C13.1756 22 15.3023 21.3549 17.1113 20.1462C18.9202 18.9375 20.3301 17.2195 21.1627 15.2095C21.9952 13.1995 22.2131 10.9878 21.7886 8.85401C21.3642 6.72022 20.3166 4.76021 18.7782 3.22183C17.2398 1.68345 15.2798 0.635804 13.146 0.211367C11.0122 -0.213071 8.80047 0.00476617 6.79048 0.83733C4.78049 1.66989 3.06253 3.07979 1.85383 4.88873C0.645139 6.69767 0 8.82441 0 11C0.00344047 13.9163 1.16347 16.7122 3.22563 18.7744C5.28778 20.8365 8.08367 21.9966 11 22ZM11 2.00001C12.78 2.00001 14.5201 2.52785 16.0001 3.51678C17.4802 4.50571 18.6337 5.91132 19.3149 7.55585C19.9961 9.20039 20.1743 11.01 19.8271 12.7558C19.4798 14.5016 18.6226 16.1053 17.364 17.364C16.1053 18.6226 14.5016 19.4798 12.7558 19.8271C11.01 20.1743 9.20038 19.9961 7.55585 19.3149C5.91131 18.6337 4.50571 17.4802 3.51677 16.0001C2.52784 14.5201 2 12.78 2 11C2.00265 8.61387 2.95171 6.32622 4.63896 4.63897C6.32622 2.95171 8.61386 2.00265 11 2.00001ZM7.293 13.293L9.586 11L7.293 8.707C7.11084 8.5184 7.01005 8.2658 7.01233 8.0036C7.0146 7.74141 7.11977 7.49059 7.30518 7.30519C7.49059 7.11978 7.7414 7.01461 8.0036 7.01233C8.2658 7.01005 8.5184 7.11085 8.707 7.293L11 9.58601L13.293 7.293C13.4816 7.11085 13.7342 7.01005 13.9964 7.01233C14.2586 7.01461 14.5094 7.11978 14.6948 7.30519C14.8802 7.49059 14.9854 7.74141 14.9877 8.0036C14.99 8.2658 14.8892 8.5184 14.707 8.707L12.414 11L14.707 13.293C14.8025 13.3853 14.8787 13.4956 14.9311 13.6176C14.9835 13.7396 15.0111 13.8708 15.0123 14.0036C15.0134 14.1364 14.9881 14.2681 14.9378 14.391C14.8875 14.5139 14.8133 14.6255 14.7194 14.7194C14.6255 14.8133 14.5139 14.8875 14.391 14.9378C14.2681 14.9881 14.1364 15.0134 14.0036 15.0123C13.8708 15.0111 13.7396 14.9835 13.6176 14.9311C13.4956 14.8787 13.3852 14.8025 13.293 14.707L11 12.414L8.707 14.707C8.5184 14.8892 8.2658 14.99 8.0036 14.9877C7.7414 14.9854 7.49059 14.8802 7.30518 14.6948C7.11977 14.5094 7.0146 14.2586 7.01233 13.9964C7.01005 13.7342 7.11084 13.4816 7.293 13.293Z"
      fill="#DE2453"
    />
  </svg>
);

export default NotUseAppIcon;
