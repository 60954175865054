import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

import { useGetChangeLog } from "features/superadmin/api/client";
import { ChangeLog } from "features/superadmin/api/types";
import { useTranslation } from "react-i18next";

import { useState } from "react";
import Pagination from "features/admin/components/Pagination";

type ChangeLogModalProps = {
  id: string;
};

const ChangeLogModal: React.FC<ChangeLogModalProps> = ({ id }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data: viewLogs } = useGetChangeLog(id, currentPage);

  const ellipsisStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as const,
  };

  return (
    <Flex flexDirection="column" p="4" minH={"90vh"} h="max-content">
      <Table mt="2.5rem" mb="2rem" boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)">
        <Thead bg="rgba(155, 194, 207, 0.1)">
          <Tr>
            {["Date", "Patient", "Action"].map((header, index) => (
              <Th key={index}>
                <Box style={ellipsisStyle}>{t(header)}</Box>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {viewLogs?.results && viewLogs.results.length > 0 ? (
            viewLogs?.results.map((column: ChangeLog, index) => (
              <Tr key={index}>
                <Td>
                  {new Date(column.created_at)
                    .toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })
                    .replace(/\//g, ".")}
                </Td>
                <Td>{column.user?.name}</Td>
                <Td>{column.action}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={3}>{t("No data")}</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Flex
        justifyContent="flex-end"
        alignContent="flex-end"
        position="absolute"
        bottom="0"
        right="5"
        p="4"
      >
        {!!viewLogs?.results.length &&
          Math.ceil((viewLogs?.count ?? 0) / 10) > 1 && (
            <Pagination
              currentPage={currentPage}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              pageCount={Math.ceil((viewLogs?.count ?? 0) / 10)}
            />
          )}
      </Flex>
    </Flex>
  );
};

export default ChangeLogModal;
