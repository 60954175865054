export const serviceColors: { [key: string]: string } = {
  DOCTOR_MEETING: "rgba(67, 179, 186, 0.2)",
  NURSE_MEETING: "rgba(213, 115, 138, 0.3)",
  PRESCRIPTION_RENEWAL: "rgba(86, 140, 178, 0.2)",
};

export const textColor: { [key: string]: string } = {
  DOCTOR_MEETING: "rgba(67, 179, 186, 1)",
  NURSE_MEETING: "rgba(213, 115, 138, 1)",
  PRESCRIPTION_RENEWAL: "rgba(86, 140, 178, 1)",
};

export const serviceNames: { [key: string]: string } = {
  DOCTOR_MEETING: "Doctor meeting",
  NURSE_MEETING: "Nurse meeting",
  PRESCRIPTION_RENEWAL: "Prescription renewal",
};

export const roleNames: { [key: string]: string } = {
  doctor: "doctor",
  nurse: "nurse",
};
