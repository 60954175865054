import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { PrescriptionTestForm } from "../../api/types";

import { TestSchema } from "../../api/vaildationSchemas";
import { useState, useEffect } from "react";

import TestPageTreatment from "../testPageTreatment";
import { usePostTreatmentTest } from "features/testPage/api/client";
import { useTranslation } from "react-i18next";

const TestPageForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [weeks, setWeeks] = useState<number>(0);

  const { t } = useTranslation();

  const { trigger } = usePostTreatmentTest();

  const formik = useFormik<PrescriptionTestForm>({
    initialValues: {
      years: "",
      medication: "",
      dose: "",
      stop_using: false,
      anxiety: "",
    },
    validationSchema: TestSchema,

    onSubmit: async (values) => {
      try {
        const response = await trigger(values);
        const weeks = response.weeks;
        setWeeks(weeks);
        setFormSubmitted(true);
      } catch (error) {
        console.error("Not send", error);
      }
    },
  });

  useEffect(() => {
    if (formSubmitted) {
      console.log("Form submitted!");
    }
  }, [formSubmitted]);

  return (
    <Flex w="full" direction="column">
      {!formSubmitted && (
        <>
          <Heading fontSize="36px" fontWeight={500}>
            {t("Tapering schedual - Test")}
          </Heading>
          <Text fontSize="20px" color="blueish.500" fontWeight={400}>
            {t("5 questions")}
          </Text>
        </>
      )}
      {!formSubmitted && (
        <form onSubmit={formik.handleSubmit}>
          <FormControl isInvalid={!!formik.errors.years} py="10px" mb="24px">
            <FormLabel
              htmlFor="years"
              fontWeight={700}
              fontSize="18px"
              py="10px"
            >
              <Flex align="center" gap="12px">
                <Box
                  borderRadius="50%"
                  bg="blueish.500"
                  w="28px"
                  textAlign="center"
                  color="white"
                >
                  1
                </Box>

                <Text
                  color="black"
                  fontSize={{ base: "18px", sm: "20", lg: "24px" }}
                  fontWeight={500}
                >
                  {t("How long have you been taking this medication?")}
                </Text>
              </Flex>
            </FormLabel>
            <RadioGroup
              id="years"
              name="years"
              onChange={(value) => formik.setFieldValue("years", value)}
              value={formik.values.years}
            >
              <Flex w="100%" direction="column" gap="20px">
                <Flex
                  gap="40px"
                  justify="space-between"
                  direction={{ base: "column", sm: "row" }}
                >
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Less than 6 months"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Less than 6 months")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio value="6-12 months" size={{ base: "sm", lg: "md" }}>
                      {t("6-12 months")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio value="2-5 years" size={{ base: "sm", lg: "md" }}>
                      {t("2-5 years")}
                    </Radio>
                  </Box>
                </Flex>
                <Flex
                  gap="40px"
                  justify="space-between"
                  direction={{ base: "column", sm: "row" }}
                >
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio value="1-2 years" size={{ base: "sm", lg: "md" }}>
                      {t("1-2 years")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio value="5-10 years" size={{ base: "sm", lg: "md" }}>
                      {t("5-10 years")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Longer than 10 years"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Longer than 10 years")}
                    </Radio>
                  </Box>
                </Flex>
              </Flex>
            </RadioGroup>
            <FormErrorMessage color="red">
              {formik.errors.years}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!formik.errors.medication}
            py="10px"
            mb="24px"
          >
            <FormLabel
              htmlFor="medication"
              fontWeight={700}
              fontSize="18px"
              py="10px"
            >
              <Flex align="center" gap="12px">
                <Box
                  borderRadius="50%"
                  bg="blueish.500"
                  w="28px"
                  textAlign="center"
                  color="white"
                >
                  2
                </Box>
                <Text
                  color="black"
                  fontSize={{ base: "18px", sm: "20", lg: "24px" }}
                  fontWeight={500}
                >
                  {t(
                    "What antidepressant medication are you taking, your primary? (Choose ONE)"
                  )}
                </Text>
              </Flex>
            </FormLabel>
            <RadioGroup
              id="medication"
              name="medication"
              onChange={(value) => formik.setFieldValue("medication", value)}
              value={formik.values.medication}
            >
              <Flex direction="column" gap="20px">
                <Flex
                  gap="40px"
                  justify="space-between"
                  direction={{ base: "column", sm: "row" }}
                >
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Agomelatine (Valdoxan)"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Agomelatine (Valdoxan)")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Amitriptyline (Sarotex, Tryptizol)"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Amitriptyline (Sarotex, Tryptizol)")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Bupropion (Wellbutrin,Zuban)"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Bupropion (Wellbutrin,Zuban)")}
                    </Radio>
                  </Box>
                </Flex>
                <Flex
                  gap="40px"
                  justify="space-between"
                  direction={{ base: "column", sm: "row" }}
                >
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio value="Citalopram" size={{ base: "sm", lg: "md" }}>
                      {t("Citalopram")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Clomipramine (Anafranil)"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Clomipramine (Anafranil)")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio value="Escitalopram" size={{ base: "sm", lg: "md" }}>
                      {t("Escitalopram")}
                    </Radio>
                  </Box>
                </Flex>
                <Flex
                  gap="40px"
                  justify="space-between"
                  direction={{ base: "column", sm: "row" }}
                >
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Fluoxetine (Prozac)"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Fluoxetine (Prozac)")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Fluvoxamine (Fevarin)"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Fluvoxamine (Fevarin)")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Mirtazapine (Remeron)"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Mirtazapine (Remeron)")}
                    </Radio>
                  </Box>
                </Flex>
                <Flex
                  gap="40px"
                  justify="space-between"
                  direction={{ base: "column", sm: "row" }}
                >
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Moclobemide (Aurorix)"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Moclobemide (Aurorix)")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Nortriptyline (Nortrilen, Pamelor)"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Nortriptyline (Nortrilen, Pamelor)")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Paroxetine (Seroxat, Paxil)"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Paroxetine (Seroxat, Paxil)")}
                    </Radio>
                  </Box>
                </Flex>
                <Flex
                  gap="40px"
                  justify="space-between"
                  direction={{ base: "column", sm: "row" }}
                >
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Sertraline (Zoloft)"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Sertraline (Zoloft)")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Tranylcypromine"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Tranylcypromine")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio value="Venlafaxine" size={{ base: "sm", lg: "md" }}>
                      {t("Venlafaxine")}
                    </Radio>
                  </Box>
                </Flex>

                <Flex
                  gap="40px"
                  justify="space-between"
                  direction={{ base: "column", sm: "row" }}
                >
                  <Box
                    w={{ base: "auto", sm: "30.5%", lg: "31%" }}
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio
                      value="Trimipramine (Surmontil)"
                      size={{ base: "sm", lg: "md" }}
                    >
                      {t("Trimipramine (Surmontil)")}
                    </Radio>
                  </Box>
                </Flex>
                <Flex></Flex>
              </Flex>
            </RadioGroup>
            <FormErrorMessage color="red">
              {formik.errors.medication}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!formik.errors.dose}
            py="10px"
            mb="24px"
            w={{ base: "100%", sm: "66%" }}
          >
            <FormLabel
              htmlFor="dose"
              fontWeight={700}
              fontSize="18px"
              py="10px"
            >
              <Flex align="center" gap="12px">
                <Box
                  borderRadius="50%"
                  bg="blueish.500"
                  w="28px"
                  textAlign="center"
                  color="white"
                >
                  3
                </Box>
                <Text
                  color="black"
                  fontSize={{ base: "18px", sm: "20", lg: "24px" }}
                  fontWeight={500}
                >
                  {t("What dose are you taking today? (in milligram)")}
                </Text>
              </Flex>
            </FormLabel>
            <Input
              id="dose"
              name="dose"
              placeholder="Dose"
              onChange={formik.handleChange}
              value={formik.values.dose}
            />
            <FormErrorMessage color="red">
              {formik.errors.dose}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!formik.errors.stop_using}
            py="10px"
            mb="24px"
            w={{ base: "100%", sm: "66%" }}
          >
            <FormLabel htmlFor="stop_using" fontWeight={700} fontSize="18px">
              <Flex align="center" gap="12px">
                <Box
                  borderRadius="50%"
                  bg="blueish.500"
                  w="28px"
                  textAlign="center"
                  color="white"
                >
                  4
                </Box>
                <Text
                  color="black"
                  fontSize={{ base: "18px", sm: "20", lg: "24px" }}
                  fontWeight={500}
                >
                  {t(
                    "Have you previously tried to stop using your medication?"
                  )}
                </Text>
              </Flex>
            </FormLabel>
            <RadioGroup
              id="stop_using"
              name="stop_using"
              onChange={(value) => formik.setFieldValue("stop_using", value)}
              value={formik.values.stop_using.toString()}
            >
              <Flex gap="40px">
                <Box
                  w="100%"
                  bg="rgba(88, 154, 175, 0.1)"
                  p="20px"
                  borderRadius="8px"
                >
                  <Radio value="true" size={{ base: "sm", lg: "md" }}>
                    {t("Yes")}
                  </Radio>
                </Box>
                <Box
                  w="100%"
                  bg="rgba(88, 154, 175, 0.1)"
                  p="20px"
                  borderRadius="8px"
                >
                  <Radio value="false" size={{ base: "sm", lg: "md" }}>
                    {t("No")}
                  </Radio>
                </Box>
              </Flex>
            </RadioGroup>
            <FormErrorMessage color="red">
              {formik.errors.stop_using}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!formik.errors.anxiety}
            py="10px"
            mb="24px"
            w={{ base: "100%", sm: "66%" }}
          >
            <FormLabel htmlFor="anxiety" fontWeight={700} fontSize="18px">
              <Flex align="center" gap="12px">
                <Box
                  borderRadius="50%"
                  bg="blueish.500"
                  w="28px"
                  textAlign="center"
                  color="white"
                >
                  5
                </Box>
                <Text
                  color="black"
                  fontSize={{ base: "18px", sm: "20", lg: "24px" }}
                  fontWeight={500}
                >
                  {t("Are you feeling any anxiety about starting a taper?")}
                </Text>
              </Flex>
            </FormLabel>
            <RadioGroup
              id="anxiety"
              name="anxiety"
              onChange={(value) => formik.setFieldValue("anxiety", value)}
              value={formik.values.anxiety}
            >
              <Flex gap="20px" direction="column">
                <Flex gap="40px">
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio value={"Yes,a lot"} size={{ base: "sm", lg: "md" }}>
                      {t("Yes,a lot")}
                    </Radio>
                  </Box>
                  <Box
                    w="100%"
                    bg="rgba(88, 154, 175, 0.1)"
                    p="20px"
                    borderRadius="8px"
                  >
                    <Radio value={"Some"} size={{ base: "sm", lg: "md" }}>
                      {t("Some")}
                    </Radio>
                  </Box>
                </Flex>
                <Box
                  w="100%"
                  bg="rgba(88, 154, 175, 0.1)"
                  p="20px"
                  borderRadius="8px"
                >
                  <Radio
                    value={
                      "No worries at all, I just want to taper off / stop the medication"
                    }
                    size={{ base: "sm", lg: "md" }}
                  >
                    {t(
                      "No worries at all, I just want to taper off / stop the medication"
                    )}
                  </Radio>
                </Box>
              </Flex>
            </RadioGroup>
            <FormErrorMessage color="red">
              {formik.errors.anxiety}
            </FormErrorMessage>
          </FormControl>

          <Button
            w={{ base: "100%", sm: "40%" }}
            type="submit"
            minH={{ base: "56px" }}
            mt={{ base: "0px", sm: "70px" }}
            bg="rgba(88, 154, 175, 1)"
            color="white"
            fontSize={{ base: "16px" }}
            onClick={() => {
              formik.submitForm();
              console.log("hi");
            }}
          >
            {t("Get Results")}
          </Button>
        </form>
      )}
      {formSubmitted && <TestPageTreatment weeks={weeks} />}
    </Flex>
  );
};

export default TestPageForm;
