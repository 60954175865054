import { useState } from "react";

import SetNewPasswordSuccess from "../SetNewPasswordSuccess";

import PasswordUpdateForm from "../PasswordUpdateForm";

const SetNewPasswordForm = () => {
  const [showForm, setShowForm] = useState<boolean>(false);

  return (
    <>
      {showForm ? (
        <SetNewPasswordSuccess />
      ) : (
        <PasswordUpdateForm setShowForm={setShowForm} />
      )}
    </>
  );
};

export default SetNewPasswordForm;
