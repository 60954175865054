import { Button, Flex, Heading, Image, Text, VStack } from "@chakra-ui/react";

import clock from "assets/icons/clock.svg";
import webinar_vector from "assets/icons/webinar_vector.svg";
import wood from "assets/images/wood_with_shape.png";

const WebinarHero = () => {
  return (
    <Flex w="full" direction="column" position="relative" overflow="inherit">
      <Image
        position="absolute"
        src={wood}
        top={{ base: "-2rem", sm: "-10rem" }}
        left={{
          base: "-4vw",
          sm: "-10vw",
          md: "-13vw",
          lg: "-6vw",
        }}
        w={"110vw"}
        h="100%"
        overflow={"hidden"}
        maxW={"none"}
        minW={"200px"}
        maxH={"none"}
        objectFit={{ base: "cover", sm: "none" }}
      />

      <VStack
        w="full"
        align="center"
        justify="center"
        zIndex={1}
        position="relative"
        mt={{ base: "10rem", sm: "25rem", lg: "27rem" }}
        top={{ base: 220, sm: 10 }}
      >
        <Flex align="center" gap="13px">
          <Image src={clock} mb="3px" />
          <Text
            color="blueish.500"
            fontSize={{ base: "16px", sm: "20px" }}
            fontWeight={400}
            fontFamily="secondary"
          >
            TILLGÄNGLIGT FRÅN
          </Text>
          <Text
            color="blueish.500"
            fontSize={{ base: "16px", sm: "20px" }}
            fontWeight={600}
            fontFamily="secondary"
          >
            27 JUNI 19:00
          </Text>
        </Flex>

        <Flex
          w="full"
          align="center"
          justify="center"
          direction="column"
          gap="48px"
        >
          <Heading
            fontSize={{ base: "40px", sm: "56px", md: "65px", lg: "84px" }}
            px={{ base: "20px", sm: "130px", lg: "300px" }}
            textAlign="center"
            lineHeight="96px"
            fontFamily="secondary"
          >
            Webbinarium om nedtrappning av antidepressiva
          </Heading>
          {/* <Button
            as="a"
            href="https://zoom.us"
            target="_blank"
            minWidth="300px"
            minHeight="56px"
            color="rgba(255, 255, 255, 1)"
            bg="rgba(88, 154, 175, 1)"
            border="none"
            _hover={{ bg: "rgba(0, 38, 55, 1)" }}
            fontSize={{ base: "16px", sm: "20px" }}
          >
            Klicka för att starta webbinariet
            <Image src={webinar_vector} ml="1rem" />
          </Button> */}
        </Flex>
      </VStack>
    </Flex>
  );
};

export default WebinarHero;
