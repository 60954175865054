import { Button, Flex } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const GoBackButton = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <Flex
      w="full"
      alignItems="center"
      justifyContent="space-between"
      gap=".75rem"
      pt={"2rem"}
    >
      <Button
        variant="transparent"
        mr={"auto"}
        color={"blueish.900"}
        fontSize={"1rem"}
        opacity={"0.5"}
        leftIcon={<BsArrowLeft fontSize={"1.5rem"} />}
        iconSpacing={"0.5rem"}
        onClick={handleGoBack}
        px={0}
      >
        {t("Back")}
      </Button>
    </Flex>
  );
};

export default GoBackButton;
