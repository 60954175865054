import { Heading, VStack } from "@chakra-ui/react";
import UserFilterSlider from "features/admin/components/UserFilterSlider";
import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";

interface TreatmentDurationSliderProps {
  durations: { from?: number; to?: number };
  onChange(newValues: { from?: number; to?: number }): void;
}

const TreatmentDurationSlider: FunctionComponent<
  TreatmentDurationSliderProps
> = ({ durations, onChange }) => {
  return (
    <VStack w={"100%"} alignItems={"stretch"} pb={"3rem"}>
      <Heading
        fontSize="14px"
        fontWeight={600}
        color="rgba(255, 255, 255, 1)"
        textTransform={"uppercase"}
      >
        {t("TAPERING PLAN")}
      </Heading>
      <UserFilterSlider
        taperingPlanDurationFrom={durations.from}
        taperingPlanDurationTo={durations.to}
        setTaperingPlanDurationFrom={(newFrom) => {
          onChange({ ...durations, from: newFrom });
        }}
        setTaperingPlanDurationTo={(newTo) => {
          onChange({ ...durations, to: newTo });
        }}
      />
    </VStack>
  );
};

export default TreatmentDurationSlider;
