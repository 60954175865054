import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
} from "@chakra-ui/react";

import { FunctionComponent } from "react";
import PDFForm from "../PDFForm";

type PDFModalProps = {
  isOpen: boolean;
  onClose(): void;
  onReceivePdf(pdfBytes: any): void;
};

const PDFModal: FunctionComponent<PDFModalProps> = ({
  isOpen,
  onClose,
  onReceivePdf,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minW={"40vw"} minH={"70vh"} position="relative">
        <ModalCloseButton size={"3rem"} top={"1rem"} right={"1rem"} />
        <ModalBody pt={"1.75rem"}>
          <PDFForm onClose={onClose} onReceivePdf={onReceivePdf} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PDFModal;
