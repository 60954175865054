import { useState, useEffect } from "react";
import { Text } from "@chakra-ui/react";

interface IAnimatedText {
  text: string;
  isVisible: boolean;
  color: string;
}

const AnimatedText: React.FC<IAnimatedText> = ({ text, isVisible, color }) => {
  const [isTextVisible, setIsTextVisible] = useState(false);

  useEffect(() => {
    setIsTextVisible(true);
  }, []);

  const transitionStyles = {
    transition: "transform 0.4s ease-in-out",
    transform: `translateY(${isTextVisible && isVisible ? "0px" : "70px"})`,
    opacity: isTextVisible && isVisible ? 1 : 0,
    color: "black",
  };

  return (
    <Text style={transitionStyles} fontSize="15px" fontWeight={500}>
      {text}
    </Text>
  );
};

export default AnimatedText;
