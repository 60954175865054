import { Flex, Box, useEditable } from "@chakra-ui/react";

import UserSchedule from "features/dashboard/components/dashboardUserSchedule";
import PreviosOrder from "features/dashboard/components/dashboardPreviosOrder";
import UserMedication from "features/dashboard/components/dashboardUserMedication";
import OrderCard from "features/dashboard/components/dashboardOrderCard";
import Order from "features/dashboard/components/dashboardOrder";
import { useOrderStore } from "services/state/orderStore";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDashboardInfo } from "features/dashboard/api/client";

const Dashboard = () => {
  const { currentStep } = useOrderStore();
  const history = useHistory();

  useEffect(() => {
    if (currentStep !== 4 && (currentStep as number) !== 5) {
      history.push("/dashboard");
    }
  }, [currentStep]);
  return (
    <Flex direction="column" align="center" gap={"1.5rem"} w="100%">
      <Flex direction={{ base: "column", sm: "row" }} gap="24px" w="100%">
        <Flex direction="column" gap="24px" w={{ base: "100%", md: "38%" }}>
          {/* BOX 1 */}
          <OrderCard />
          {/* BOX 2 */}
          <UserMedication />
        </Flex>
        {/* BOX 3 */}
        <UserSchedule />
      </Flex>
      {/* BOX 4 */}
      <Box bg="white" borderRadius="0.75rem" w="100%">
        <Order />
      </Box>
    </Flex>
  );
};

export default Dashboard;
