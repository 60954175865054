import { useEffect, useState } from "react";

export const tableHeaders = [
  {
    id: "1",
    KEY: "NAME",
  },
  {
    id: "2",
    KEY: "MEDICATION TYPE",
  },
  {
    id: "3",
    KEY: "MOOD",
  },
  {
    id: "4",
    KEY: "APP USAGE",
  },
  {
    id: "5",
    KEY: "STATUS",
  },
  {
    id: "6",
    KEY: "STATUS CHANGE",
  },
];

export const statusColor = [
  { value: "treatment_completed", color: "rgba(67, 179, 186, 0.2)" },
  { value: "on_treatment", color: "rgba(243, 196, 119, 0.5)" },
  { value: "prescription_completed", color: "rgba(86, 140, 178, 0.2)" },
  { value: "registered", color: "rgba(88, 154, 175, 0.2)" },
  { value: "order_placed", color: "rgba(100, 124, 173, 0.2)" },
  { value: "payment_processing", color: "rgba(243, 196, 119, 0.2)" },
  { value: "payment_made", color: "rgba(67, 179, 186, 0.2)" },
  { value: "shipped", color: "rgba(67, 179, 186, 0.4)" },
  { value: "shipping", color: "rgba(243, 196, 119, 0.3)" },
  { value: "delivered", color: "rgba(243, 196, 119, 0.5)" },
  { value: "triage_completed", color: "rgba(67, 186, 140, 0.5)" },
  { value: "prescription_approved", color: "rgba(255, 177, 33, 0.3)" },
];

export const statusTextColor = [
  { value: "treatment_completed", color: "rgba(67, 179, 186, 1)" },
  { value: "on_treatment", color: "rgba(243, 196, 119, 1)" },
  { value: "prescription_completed", color: "rgba(86, 140, 178, 1)" },
  { value: "registered", color: "rgba(88, 154, 175, 1)" },
  { value: "order_placed", color: "rgba(100, 124, 173, 1)" },
  { value: "payment_processing", color: "rgba(243, 196, 119, 1)" },
  { value: "prescription_approved", color: "rgba(255, 177, 33, 1)" },
  { value: "payment_made", color: "rgba(67, 179, 186, 1)" },
  { value: "shipped", color: "rgba(67, 179, 186, 1)" },
  { value: "shipping", color: "rgba(243, 196, 119, 1)" },
  { value: "delivered", color: "rgba(243, 196, 119, 1)" },
  { value: "triage_completed", color: "rgba(67, 186, 140, 1)" },
];

export const options = [
  { value: "", label: "Please select" },
  { value: "treatment_completed", label: "Treatment Completed" },
  { value: "on_treatment", label: "On Treatment" },
  { value: "prescription_completed", label: "Prescription Completed" },
  { value: "registered", label: "Registered" },
  { value: "order_placed", label: "Order Placed" },
  { value: "payment_processing", label: "Peyment Processing" },
  { value: "payment_made", label: "Payment Made" },
  { value: "shipped", label: "Shipped" },
];

const useDebounce = (value: any, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const id = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(id);
    };
  }, [value, delay]);

  return debouncedValue;
};
export default useDebounce;

export const useDebug = (value: unknown, dependencies = []) => {
  useEffect(() => {
    console.log(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, value]);
};
