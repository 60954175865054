import { Flex, Text } from "@chakra-ui/react";
import { formatTime } from "../../services/dateFormater";
import { BsCheck, BsCheck2All } from "react-icons/bs";
import { useTranslation } from "react-i18next";

type ChatPersonelMessageProps = {
  message: string;
  publishedAt: string;
  unRead: string[];
  myId: string;
};

const ChatPersonelMessage: React.FC<ChatPersonelMessageProps> = ({
  message,
  publishedAt,
  unRead,
  myId,
}) => {
  const { t } = useTranslation();
  return (
    <Flex justify="flex-end">
      <Flex
        p="12px"
        bg="rgba(88, 154, 175, 1)"
        direction="column"
        w="max-content"
        borderRadius="8px"
        borderTopRightRadius="none"
      >
        <Text color="white" fontSize="16px" fontWeight={400} maxW={"35rem"}>
          {message}
        </Text>
        <Flex alignSelf="flex-end" gap=".2rem">
          <Text
            color="rgba(255, 255, 255, 0.5)"
            fontSize="12px"
            fontWeight={400}
            alignSelf="flex-end"
          >
            {new Date(
              new Date(publishedAt).getTime() -
                new Date().getTimezoneOffset() * 60 * 1000
            ).toLocaleTimeString(t("locale") ?? "en-GB", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Text>

          {unRead.length ? (
            unRead.map((userId) => {
              if (userId !== myId) {
                return <BsCheck color="rgba(255, 255, 255, 0.5)" />;
              }
              return <></>;
            })
          ) : (
            <BsCheck2All color="rgba(255, 255, 255, 0.5)" />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChatPersonelMessage;
