import { Box, Center, Spinner, Text } from "@chakra-ui/react";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";
import { useDashboardInfo } from "features/dashboard/api/client";
import useScript from "features/klarnaPayments/hooks/useScript";
import { useFetchPaymentID } from "features/netsEasyPayments/api/client";
import { useFetchStripePaymentURL } from "features/stripePayments/api/client";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const PaymentPage: FunctionComponent = () => {
  const { data: stripeData } = useFetchStripePaymentURL();
  const { purchaseEvent } = useGoogleAnalytics();
  const history = useHistory();

  useEffect(() => {
    console.log("🚀 ~ file: index.tsx:20 ~ stripeData?.url:", stripeData?.url);
    if (stripeData?.url) {
      console.log("FIRE");
      window.open(stripeData.url);
      setTimeout(() => {
        history.push("/dashboard");
      }, 3000);
    }
  }, [stripeData?.url]);

  return (
    <Center
      bg={"white"}
      width={"100%"}
      mt={"0 !important"}
      borderRadius={"lg"}
      height={"80vh !important"}
      flexDirection={"column"}
      p={"2vh"}
      position={"relative"}
      //id={"klarna-payments-container"}
    >
      Please proceed to payment page
    </Center>
  );
};

export default PaymentPage;
