import { StatusResponseType } from "features/login/api/types";
import LoadingComponent from "features/login/components/LoadingComponent";
import { FunctionComponent, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { useAdminStore } from "services/state/adminStore";
import { useTokenStore } from "services/state/tokenStore";
import { useUserStore } from "services/state/userStore";
import { useSWRConfig } from "swr";
import { useCheckLogin, useLogin } from "../../features/login/api/client";
import ExpiredWaitingPeriod from "../../features/login/components/ExpiredWaitingPeriod/ExpiredWaitingPeriod";
import LogInViaBankId from "../../features/login/components/LogInViaBankId/LogInViaBankId";

interface LoginPageProps {}

const LoginPage: FunctionComponent<LoginPageProps> = () => {
  const { access, refresh } = useTokenStore();
  const { mutate: forceFetchRefs } = useLogin();
  const loginData: StatusResponseType | undefined = useCheckLogin().data;
  const { isAdmin } = useAdminStore();
  const { isLoading, data } = useCheckLogin();
  const [status, setStatus] = useState<number>(0);
  const history = useHistory();
  const { state } = useLocation();
  const { mutate } = useSWRConfig();

  useEffect(() => {
    if (
      loginData?.code === 202 ||
      loginData?.code === 201 ||
      loginData?.code === 205
    ) {
      setStatus(loginData.code);
    }
  }, [loginData]);

  useEffect(() => {
    if (access !== "" && refresh !== "" && loginData && !isAdmin) {
      let dateJoined = new Date(loginData.data?.date_joined ?? "");
      let currentDate = new Date();

      dateJoined.setSeconds(0, 0);
      currentDate.setSeconds(0, 0);

      if ((state as any)?.from) {
        history.push((state as any)?.from);
        return;
      }

      if (dateJoined.getTime() === currentDate.getTime()) {
        history.push("/login-complete");
      } else {
        history.push("/prescription");
      }
    }
  }, [access, refresh, loginData]);

  useEffect(() => {
    forceFetchRefs();
  }, []);

  return (
    <>
      {status === 0 && <LoadingComponent />}
      {status === 201 && <LogInViaBankId />}
      {status === 205 && <ExpiredWaitingPeriod />}
    </>
  );
};

export default LoginPage;
