import {
  VStack,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
} from "@chakra-ui/react";
import { t } from "i18next";
import { FunctionComponent, useState } from "react";
import { DateRange } from "react-date-range";
import { AiOutlineCalendar } from "react-icons/ai";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import classes from "./styles.module.scss";
import { default as svlocale } from "date-fns/locale/sv";

interface LogFilterProps {
  value: { from?: Date; to?: Date };
  onChange(newValue: { from: Date; to: Date }): void;
}

const LogPeriodFilter: FunctionComponent<LogFilterProps> = ({
  value,
  onChange,
}) => {
  const [localDateRange, setLocalDateRange] = useState<{
    from?: Date;
    to?: Date;
  }>({});

  return (
    <>
      <Popover placement="bottom-end">
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                variant={"outline"}
                borderColor={"rgba(0, 0, 0, 0.1)"}
                border={"1px solid"}
                color={isOpen ? "white" : "blueish.900"}
                leftIcon={<AiOutlineCalendar fontSize={"1.5rem"} />}
                px={"1.5rem"}
                lineHeight={"1rem"}
                bg={isOpen ? "blueish.900" : "transparent"}
                transition={"all 0.3s"}
                _hover={{ bg: "" }}
                fontWeight={"400"}
                fontSize={"1rem"}
                maxH={"3rem"}
              >
                {value.from &&
                  value.from.toLocaleDateString(t("locale") ?? "en-GB", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}

                {value.to &&
                  "-" +
                    value.to.toLocaleDateString(t("locale") ?? "en-GB", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}

                {!value.from && !value.to && t("Specify date")}
              </Button>
            </PopoverTrigger>
            <PopoverContent
              boxShadow={"0px 4px 8px rgba(88, 154, 175, 0.5)"}
              border={"1px solid"}
              borderColor={"#589AAF"}
              overflow={"hidden"}
              pb={"2rem"}
              px={"1rem"}
              width={"fit-content"}
            >
              <VStack spacing={0}>
                <DateRange
                  classNames={{
                    dateDisplayWrapper: classes.hider,
                    dateDisplay: classes.hider,
                  }}
                  rangeColors={["#589AAF"]}
                  locale={svlocale}
                  ranges={[
                    {
                      startDate: localDateRange.from,
                      endDate: localDateRange.to,
                    },
                  ]}
                  onChange={(values) => {
                    setLocalDateRange({
                      from: values.range1.startDate ?? new Date(),
                      to: values.range1.endDate ?? new Date(),
                    });
                  }}
                />

                <Button
                  variant={"outline"}
                  border={"1px solid"}
                  borderColor={"#589AAF"}
                  color={"#589AAF"}
                  maxH={"2.5em !important"}
                  w={"auto"}
                  px={"1.1em"}
                  fontSize={"1.125rem !important"}
                  onClick={() => {
                    onChange({
                      from: localDateRange.from || new Date(),
                      to: localDateRange.to || new Date(),
                    });
                    onClose();
                  }}
                >
                  {t("Set")}
                </Button>
              </VStack>
            </PopoverContent>
          </>
        )}
      </Popover>
    </>
  );
};

export default LogPeriodFilter;
