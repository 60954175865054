import { Box, Flex, Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import { pricingData, tableColors } from "features/faqInfo/utils";
import { useTranslation } from "react-i18next";

const DiscoverTable = () => {
  const { t } = useTranslation();
  return (
    <Box
      w="full"
      border="1px solid rgba(88, 154, 175, 1)"
      borderRadius="8px"
      position="relative"
    >
      {pricingData.map((item, index) => (
        <Grid
          key={index}
          templateColumns="repeat(12, 1fr)"
          borderBottom="1px solid"
          borderColor="rgba(88, 154, 175, 1)"
          bg="rgba(88, 154, 175, 0.05)"
        >
          <GridItem
            colSpan={2}
            bg={tableColors[index % tableColors.length]}
            color="white"
            fontWeight="bold"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderTopLeftRadius={index === 0 ? "8px" : ""}
            borderBottomLeftRadius={
              index === pricingData.length - 1 ? "8px" : ""
            }
          >
            {t(item.month)}
          </GridItem>
          <GridItem
            colSpan={7}
            w="full"
            borderRight="1px solid"
            borderColor="rgba(88, 154, 175, 0.2)"
          >
            {item.details.map((detail, detailIndex) => {
              const shouldHaveBorderBottom = !(
                detail.activity === "Läkemedel" ||
                (item.month === "Månad 2" &&
                  detail.activity === "Uppföljningsmöte") ||
                (item.month === "Månad 4" &&
                  detail.activity === "Uppföljningsmöte") ||
                (item.month === "Månad 5" &&
                  detail.activity === "Uppföljningsmöte")
              );

              return (
                <Flex
                  px="2"
                  pb="1"
                  w="full"
                  key={detailIndex}
                  justifyContent="space-between"
                  alignItems="center"
                  mt="1rem"
                  borderBottom={
                    shouldHaveBorderBottom
                      ? "1px solid rgba(88, 154, 175, 0.2)"
                      : ""
                  }
                >
                  <Text
                    color="rgba(0, 38, 55, 1)"
                    fontWeight={400}
                    fontSize="1rem"
                  >
                    {t(detail.activity)}
                  </Text>
                  <Flex
                    w="50%"
                    alignSelf="flex-start"
                    mr={{ md: "-3rem", lg: "-5rem" }}
                  >
                    <Text
                      color="rgba(0, 38, 55, 1)"
                      fontWeight={700}
                      fontSize="1rem"
                    >
                      {t(detail.cost)}
                    </Text>
                  </Flex>
                </Flex>
              );
            })}
          </GridItem>

          <GridItem colSpan={3} justifySelf="flex-end" textAlign="right" p={2}>
            <VStack alignItems="flex-end" p="2">
              <Text color="rgba(0, 38, 55, 1)" fontWeight={400} fontSize="1rem">
                {t("Total")}:
              </Text>
              <Text
                color="rgba(88, 154, 175, 1)"
                fontWeight={700}
                fontSize="20px"
              >
                {item.total}
              </Text>
              <Text color="rgba(0, 38, 55, 1)" fontWeight={400} fontSize="1rem">
                {t("plus medication")}
              </Text>
            </VStack>
          </GridItem>
          <Box
            display={{ base: "none", md: "block" }}
            position="absolute"
            h="8.5rem"
            w="1px"
            bg="rgba(88, 154, 175, 0.2)"
            right="50%"
          />
        </Grid>
      ))}
    </Box>
  );
};

export default DiscoverTable;
