import { Select } from "chakra-react-select";
import { FunctionComponent } from "react";

interface PercentageSelectorProps {
  value: string;
  onChange(newValue: string): void;
}

const PercentageSelector: FunctionComponent<PercentageSelectorProps> = ({
  value,
  onChange,
}) => {
  const options = [
    { value: "5", label: "5%" },
    { value: "10", label: "10%" },
    { value: "15", label: "15%" },
  ];

  return (
    <Select
      options={options}
      onChange={(newVal) => {
        onChange(newVal?.value ?? "");
      }}
      defaultValue={{ value: "5", label: "5%" }}
    />
  );
};

export default PercentageSelector;
