import React, { useEffect, useRef, useState } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Flex,
  InputGroup,
  Input,
  InputRightElement,
  Image,
  Text,
  Textarea,
} from "@chakra-ui/react";
import ResizeTextarea from "react-textarea-autosize";

import ChatHeader from "./components/ChatHeader";
import ChatMessage from "./components/ChatMessage";
import ChatPersonelMessage from "./components/ChatPersonelMessage/insex";

import send from "assets/icons/send.svg";

import { useChat } from "./hooks/useChat";
import { type MessageItem } from "./api/types";
import {
  useAddChat,
  useChatReadMessages,
  useChatSendMessages,
} from "./api/client";

import { useFetchProfileInfo } from "features/dashboard/api/client";
import { useAdminUserInfo } from "features/admin/api/client";
import { PropagateLoader } from "react-spinners";
import { formatDateForDays } from "./services/dateFormater";

type ChatDrawerProps = {
  userId: string;
  chatId?: string;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
};

const ChatDrawer: React.FC<ChatDrawerProps> = ({
  userId,
  chatId,
  isOpen,
  onClose,
  onOpen,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const { data: personelData } = useFetchProfileInfo();

  const [newMessage, setNewMessage] = useState<string>("");

  const { data } = useAdminUserInfo(userId);

  const { data: currentChat } = useAddChat([
    personelData?.id || "",
    userId || "",
  ]);

  const chat_id = currentChat?.chat_id || chatId;

  const { trigger: sendMessage } = useChatSendMessages(chat_id || "");
  const { trigger: readMessage } = useChatReadMessages(chat_id || "");

  const { messages, isLoading } = useChat(chat_id || "");

  const handleMessages = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    sendMessage({ message: newMessage });

    setNewMessage("");
  };

  useEffect(() => {
    if (isOpen && messages) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
      readMessage();
    }
  }, [isOpen, messages]);

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <ChatHeader
              onClose={onClose}
              members={[
                ...(personelData ? [personelData] : []),
                ...(data ? [data] : []),
              ]}
            />
          </DrawerHeader>

          <DrawerBody bg="rgba(155, 194, 207, 0.1)">
            <Flex direction="column" gap=".5rem">
              {isLoading ? (
                <Flex align="center" justify="center">
                  <PropagateLoader
                    color="rgba(88, 154, 175, .3)"
                    speedMultiplier={0.3}
                  />
                </Flex>
              ) : (
                <>
                  {Object.keys(messages).map((date) => (
                    <React.Fragment key={date}>
                      <Flex align="center" justify="center">
                        <Text color="black" fontSize="14px">
                          {formatDateForDays(date)}
                        </Text>
                      </Flex>

                      {messages[date].map((chat, index) => (
                        <React.Fragment key={index}>
                          {chat.publishedBy === personelData?.id ? (
                            <ChatPersonelMessage
                              key={index}
                              message={chat.message}
                              publishedAt={chat.publishedAt}
                              unRead={chat.unRead}
                              myId={personelData.id}
                            />
                          ) : (
                            <ChatMessage
                              key={index}
                              message={chat.message}
                              publishedAt={chat.publishedAt}
                              firstName={data?.first_name || ""}
                              lastName={data?.last_name || ""}
                              role={data?.role || "patient"}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </>
              )}
              <div ref={ref} />
            </Flex>
          </DrawerBody>

          <form onSubmit={handleMessages}>
            <Flex alignItems={"center"} pr={"1rem"} bg={"white"} py={"0.5rem"}>
              <Textarea
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                _placeholder={{
                  color: "rgba(0, 0, 0, 0.4)",
                }}
                placeholder="Start typing..."
                bg="white !important"
                mr={"1rem"}
                border={"none"}
                minH="unset"
                overflow="hidden"
                w="100%"
                resize="none"
                minRows={1}
                as={ResizeTextarea}
                flexGrow={1}
              />
              <button type="submit">
                <Image src={send} cursor="pointer" />
              </button>
            </Flex>
          </form>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ChatDrawer;
