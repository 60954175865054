import { Flex, Heading, Text, Image } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import lamina_box from "assets/icons/lamina_box.svg";
import two_phones from "assets/icons/two_phones.svg";
import iMac from "assets/icons/iMac.svg";
import AnimatedBox from "ui/components/AnimatedBox";

const HomeOurSentences = () => {
  const { t } = useTranslation();

  return (
    <Flex
      w="full"
      gap="40px"
      direction="column"
      mt={{ base: "-5rem", lg: "0rem" }}
    >
      {/* BOX 1 */}
      <AnimatedBox>
        <Flex
          w="full"
          borderRadius="24px"
          bg="blueish.500"
          py="80px"
          px="116px"
        >
          <Flex align="center" gap="116px">
            <Image src={lamina_box} />
            <Flex direction="column" gap="20px">
              <Heading color="white" fontSize="36px" fontWeight={500}>
                {t("Medication strips")}
              </Heading>
              <Text
                fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
                fontWeight={400}
              >
                {t(
                  "Lamina Clinic provides access to completely individualized doses of most antidepressant medications (SSRI, SNRI). 57% of those who taper off or try to stop taking their antidepressant medications experience withdrawal symptoms. This occurs because the tapering is done in too large steps."
                )}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </AnimatedBox>

      {/* BOX 2 */}
      <AnimatedBox fromLeft>
        <Flex
          w="full"
          borderRadius="24px"
          bg="blueish.500"
          p="80px 0px 104px 104px"
          align="center"
        >
          <Flex align="center" gap="116px" position="relative">
            <Flex direction="column" gap="20px">
              <Heading color="white" fontSize="36px" fontWeight={500}>
                {t("Get your de-escalation plan")}
              </Heading>
              <Text
                fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
                fontWeight={400}
              >
                {t(
                  "When you want to get a prescription from Lamina Clinic, you need to log in using Bank ID and answer a number of questions. The whole process take no more than about 5 minutes, and our doctors will assess immidiatly if we can help you.You will then recive a proposal for a tapering period in number of weeks from our tapering experts."
                )}
              </Text>
            </Flex>

            <Image src={iMac} />
          </Flex>
        </Flex>
      </AnimatedBox>
      {/* BOX 3 */}
      <AnimatedBox>
        <Flex
          w="full"
          borderRadius="24px"
          bg="blueish.500"
          py="127px"
          px="116px"
        >
          <Flex align="center" gap="116px">
            <Image src={two_phones} />
            <Flex direction="column" gap="20px">
              <Heading color="white" fontSize="36px" fontWeight={500}>
                {t("Support throughout the process")}
              </Heading>
              <Text
                fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
                fontWeight={400}
              >
                {t(
                  "Tell us how you feel every day with a simple click in the app. You have regular follow-ups with your care team every month and can quickly talk to your doctor if, against the odds, you experience problems. Digital, flexible and on your terms."
                )}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </AnimatedBox>
    </Flex>
  );
};

export default HomeOurSentences;
