import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import { useFormik } from "formik";

import { Link } from "react-router-dom";

import { useResetPassword } from "features/adminLogin/api/client";
import { ResetPasswordSchema } from "features/adminLogin/api/validation";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface EmailResetFormProps {
  setShowSuccessMessage(v: boolean): void;
}

const EmailForResetForm: React.FC<EmailResetFormProps> = ({
  setShowSuccessMessage,
}) => {
  const { t } = useTranslation();

  const { trigger } = useResetPassword();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (value) => {
      trigger(
        { email: value.email },
        {
          onSuccess: () => {
            setShowSuccessMessage(true);
          },
        }
      );
    },
    validationSchema: ResetPasswordSchema,
  });

  return (
    <>
      <Heading
        color="rgba(0, 38, 55, 1)"
        fontWeight={600}
        fontSize="32px"
        letterSpacing={1.6}
        textAlign="center"
      >
        {t("Reset Password")}
      </Heading>

      <Text
        fontFamily="secondary"
        color="rgba(0, 38, 55, 0.4)"
        fontWeight={400}
        fontSize="16px"
        textAlign="center"
        px={{ base: "1rem", sm: "2rem", md: "0rem", lg: "0rem" }}
      >
        {t(
          "Enter your email address and you will receive a link to create a new password"
        )}
        .
      </Text>

      <form onSubmit={formik.handleSubmit}>
        <FormControl
          isInvalid={!!formik.errors.email}
          mt="24px"
          h={{ base: "", sm: "", md: "80px", lg: "90px" }}
        >
          <FormLabel
            fontWeight={600}
            fontSize={{ base: "12px", lg: "14px" }}
            color="rgba(0, 38, 55, 1)"
            fontFamily="secondary"
          >
            EMAIL
          </FormLabel>
          <Input
            id="email"
            name="email"
            type="email"
            placeholder="Enter your email"
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "16px",
            }}
            onChange={formik.handleChange}
            value={formik.values.email}
            minH="3.5rem !important"
          />
          <FormErrorMessage color="red" fontSize="14px">
            {formik.errors.email}
          </FormErrorMessage>
        </FormControl>

        <Center py="2.5rem">
          <Button
            w="full"
            type="submit"
            bg="rgba(88, 154, 175, 1)"
            borderColor="white"
            fontFamily="secondary"
            fontWeight={700}
            fontSize="16px"
            letterSpacing={1}
            color={"white"}
            _hover={{
              bg: "blueish.700",
            }}
            maxW="235px"
            onClick={() => {
              formik.submitForm();
              console.log("submited");
            }}
          >
            {t("Request Reset Link")}
          </Button>
        </Center>
      </form>
      <Center>
        <Text
          color="rgba(0, 38, 55, 0.5)"
          fontSize="16px"
          fontWeight={700}
          fontFamily="secondary"
          textDecor="underline"
          cursor="pointer"
        >
          <Link to="/admin">{t("Back to Login")}</Link>
        </Text>
      </Center>
    </>
  );
};

export default EmailForResetForm;
