import { Button, CircularProgress, Flex } from "@chakra-ui/react";

import { BsCalendar2Check } from "react-icons/bs";
import { useGetDayOffCSV } from "./api/client";
import { useTranslation } from "react-i18next";

const DayOffCSV = () => {
  const { t } = useTranslation();

  const { mutate, isValidating } = useGetDayOffCSV();

  const handleDownloadCSV = async () => {
    try {
      if (isValidating) {
        setTimeout(() => handleDownloadCSV(), 100);
        return;
      }

      const data = await mutate();

      if (data) {
        const blob = new Blob([data], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "workdays_schedule.csv";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Can not download:", error);
    }
  };

  return (
    <Flex
      flex={1}
      alignSelf="flex-start"
      justify="flex-end"
      py={{ base: "2", sm: "0" }}
    >
      <Button
        py={{ base: "3", sm: "auto" }}
        px={{ base: "30", sm: "auto" }}
        variant="transparent"
        bg="white"
        color="blueish.500"
        fontWeight={500}
        border="1px solid rgba(88, 154, 175, 0.7)"
        _hover={{
          boxShadow: "1px 1px 3px rgba(88, 154, 175, 0.7)",
        }}
        leftIcon={<BsCalendar2Check />}
        onClick={handleDownloadCSV}
      >
        {isValidating ? (
          <Flex align="center">
            <CircularProgress
              size="20px"
              color="rgba(88, 154, 175, 1)"
              marginRight="2"
            />
            {t("Loading")}...
          </Flex>
        ) : (
          `${t("Working days CSV")}`
        )}
      </Button>
    </Flex>
  );
};

export default DayOffCSV;
