import { Flex, Text, Box } from "@chakra-ui/react";

import PopupChange from "features/prescriptionForm/components/prescriptionPopupChange";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
interface RequestedTreatmentPlanProps {
  expected_treatment?: number;
  date?: string;
}

const RequestedTreatmentPlan: React.FC<RequestedTreatmentPlanProps> = ({
  expected_treatment,
  date,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  return (
    <Flex w="100%" direction="column" gap="12px">
      <Flex w="100%" align="flex-start" direction="column">
        <Text color="blueish.500" fontSize="16px" fontWeight={500}>
          {t("REQUESTED TREATMENT PLAN")}
        </Text>
        <Flex w="100%" gap="12px">
          <Text color="black" fontSize="16px" fontWeight={700}>
            {expected_treatment ? expected_treatment * 4 : 0} {t("weeks")}
          </Text>
          <Text color="black" fontSize="16px">
            {new Date(date || "").toLocaleDateString(t("locale") ?? "en-GB", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </Text>
        </Flex>
      </Flex>
      <PopupChange
        isAdjustable={false}
        selectedPlan={(expected_treatment ?? 0) * 4}
        setSelectedPlan={() => {}}
        dashboardDisplay={true}
        adminDisplayId={id}
      />
    </Flex>
  );
};

export default RequestedTreatmentPlan;
