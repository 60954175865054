import { Center, Heading, Image, Text, Box, Flex } from "@chakra-ui/react";

import { useState } from "react";

import logo_group from "assets/icons/logo_group.svg";

import SignUpForm from "features/adminLogin/components/SingUpForm";
import SignUpFormStep from "features/adminLogin/components/SignUpFormStep";
import { useTranslation } from "react-i18next";

const AdminSignUp = () => {
  const [showSignUpForm, setShowSignUpForm] = useState<boolean>(true);

  const { t } = useTranslation();

  return (
    <Flex
      w="full"
      direction="column"
      align="center"
      justify="center"
      mt={{ base: "-3rem", sm: "0rem" }}
    >
      <Image src={logo_group} />

      <Center
        flexDirection="column"
        w="full"
        py={{ base: "1.5rem", sm: "3rem", md: "5rem", lg: "6rem" }}
        px={{ base: ".5rem", md: "4rem" }}
        mt={showSignUpForm ? "0rem" : "3rem"}
      >
        <Heading
          color="rgba(0, 38, 55, 1)"
          fontWeight={600}
          fontSize={showSignUpForm ? "48px" : "32px"}
          letterSpacing={1.6}
          fontFamily="secondary"
        >
          {showSignUpForm ? `${t("Welcome")}!` : `${t("One more step")}...`}
        </Heading>
        <Text
          fontFamily="secondary"
          color="rgba(0, 38, 55, 0.4)"
          fontWeight={400}
          fontSize="16px"
        >
          {showSignUpForm
            ? `${t("Please login")}.`
            : `${t("Please fill the fields below")}.`}
        </Text>

        <Box
          w="full"
          py={{ base: "1rem", sm: "2rem", md: "", lg: "2.5rem" }}
          px={{ base: "1rem", sm: "10rem", md: "9.5rem", lg: "16.9rem" }}
        >
          {showSignUpForm ? (
            <SignUpForm setShowSignUpForm={setShowSignUpForm} />
          ) : (
            <SignUpFormStep />
          )}
        </Box>
      </Center>
    </Flex>
  );
};

export default AdminSignUp;
