import {
  Flex,
  Image,
  VStack,
  Text,
  Heading,
  Button,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Link,
  Link as ChakraLink,
} from "@chakra-ui/react";
import logo from "assets/images/logo.svg";
import PersonIcon from "assets/icons/Person.svg";

import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

import closeIcon from "assets/icons/closeIcon.svg";
import facebook_white from "assets/icons/facebook_white.svg";
import instagram_white from "assets/icons/instagram_white.svg";
import linkedIn_white from "assets/icons/linkedIn_white.svg";
import twitter_white from "assets/icons/twitter_white.svg";
import vimeo_white from "assets/icons/vimeo_white.svg";
import youtube_white from "assets/icons/youtube_white.svg";
import { triggerAnnouncementModal } from "features/genericModal";
import { useHistory } from "react-router-dom";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";

type IDrawerViewProps = {
  onClose: () => void;
};

const DrawerView = ({ onClose }: IDrawerViewProps) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { basicEvent } = useGoogleAnalytics();

  return (
    <>
      {/* NAVBAR */}
      <Flex
        justify={"space-between"}
        align={"center"}
        w={"100%"}
        p={"2%"}
        pt={0}
      >
        <Image src={logo} alt="logo" w={"calc(150px + 9%)"} minW={"100px"} />

        {/*TODO: uncomment language switch */}
        {/* <Flex ml={"4rem"} mt={"0.5rem"}>
          <Popover arrowShadowColor="transparent">
            <PopoverTrigger>
              <Button variant="ghost" color={"white"}>
                {i18n.language.toUpperCase()}
              </Button>
            </PopoverTrigger>
            <PopoverContent
              zIndex={4}
              bg={"#589AAF"}
              width={"80px"}
              border={"none"}
            >
              <PopoverArrow
                backgroundColor={"#589AAF"}
                border="none !important"
              />
              <PopoverBody
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Button
                  onClick={() => i18n.changeLanguage("en")}
                  variant="ghost"
                  color={"white"}
                >
                  EN
                </Button>
                <Button
                  onClick={() => i18n.changeLanguage("sv")}
                  variant="ghost"
                  color={"white"}
                >
                  SV
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex> */}

        <Image src={closeIcon} onClick={onClose} alt={"Close icon"} />
      </Flex>
      {/* CONTENT */}
      <VStack w={"100%"} py={"10rem"} gap={"1.5rem"}>
        <HashLink to="/#context" onClick={onClose}>
          <Heading variant={"light"}>
            {/* Om Oss */}
            {t("drawerViewHeader1")}
          </Heading>
        </HashLink>
        <HashLink to="/#services" onClick={onClose}>
          <Heading variant={"light"} cursor={"pointer"}>
            {/* Hur Lamina Fungerar */}
            {t("drawerViewHeader2")}
          </Heading>
        </HashLink>
        <HashLink to="/#sentences" onClick={onClose}>
          <Heading variant={"light"}>
            {/* Vad Vi Erbjuder */}
            {t("drawerViewHeader3")}
          </Heading>
        </HashLink>

        <Box
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          gap={3}
        >
          <HashLink to="/#donwloadApp" onClick={onClose}>
            <Button
              variant={"filled"}
              w={"20.75rem"}
              minH={"3.25rem"}
              fontSize={"1rem"}
              /* onClick={() => {
                console.log("H");
                history.push("/login");
              }} */
            >
              {t("drawerViewButton1")}
            </Button>
          </HashLink>
          {/*  <Button
            variant={"transparent"}
            onClick={() => history.push("/login")}
            leftIcon={<Image src={PersonIcon} />}
            fontSize={'stnd'}
          >
            {t("Log in")}
          </Button> */}
        </Box>
      </VStack>

      {/* FOOTER */}
      <Flex
        direction={"column"}
        gap={"2rem"}
        justify={"center"}
        align={"center"}
      >
        <Link href="Info@laminaclinic.com">
          <Text fontSize={"1rem"}>info@laminaclinic.com</Text>
        </Link>
        <Flex justify={"space-between"} align={"center"} gap={"2.5rem"}>
          <ChakraLink
            color={"white !important"}
            href="https://www.linkedin.com/company/lamina-clinic/"
            target="blank"
            onClick={() => {
              basicEvent("Contact", "User clicked footer link");
            }}
          >
            <Image src={linkedIn_white} alt={"linkedin link icon"} />
          </ChakraLink>
          <ChakraLink
            color={"white !important"}
            href="https://www.facebook.com/laminaclinicsverige"
            target="blank"
            onClick={() => {
              basicEvent("Contact", "User clicked footer link");
            }}
          >
            <Image src={facebook_white} alt={"facebook link icon"} />
          </ChakraLink>
          <ChakraLink
            color={"white !important"}
            href="https://www.instagram.com/laminaclinic.se/"
            target="blank"
            onClick={() => {
              basicEvent("Contact", "User clicked footer link");
            }}
          >
            <Image
              src={instagram_white}
              sizes={"1rem"}
              alt={"instagram link icon"}
            />
          </ChakraLink>
        </Flex>
      </Flex>
    </>
  );
};

export default DrawerView;
