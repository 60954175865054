import { APIClient } from "services/api/client";
import { useSWRConfig } from "swr";
import useSWRImmutable from "swr/immutable";

import { StripePaymentResponseType } from "./types";

export const useFetchStripePaymentURL = () => {
  return useSWRImmutable<StripePaymentResponseType>(
    `create-checkout`,
    (url: string) => APIClient.get(url)
  );
};
