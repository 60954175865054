import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useSendContactRequest } from "features/contactForm/api/client";
import { ContactFormType } from "features/contactForm/api/types";
import { ContactValidationSchema } from "features/contactForm/services/validators";
import { invokeBasicInfoModal } from "features/genericModal";
import { useFormik } from "formik";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface ContactFormProps {}

const ContactForm: FunctionComponent<ContactFormProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { trigger: contactUsRequest, isMutating: isSending } =
    useSendContactRequest();

  const contactFormik = useFormik<ContactFormType>({
    initialValues: {
      /*  email: "",
      phone_number: "", */
      name: "",
    },
    onSubmit: (data) => {
      contactUsRequest(data, {
        onSuccess: () => {
          invokeBasicInfoModal(
            t(
              "Request has been successfully submitted. Please wait for our team to get in touch with you"
            ),
            undefined,
            () => {
              history.push("/");
            }
          );
        },
      });
    },
    validationSchema: ContactValidationSchema,
    validateOnChange: false,
  });

  return (
    <Flex
      w="max-content"
      borderRadius="24px"
      m="0 auto"
      direction="column"
      gap="10px"
      py={{ base: ".5rem", sm: "3rem" }}
      px={{ base: "1.5rem", sm: "3.75rem", md: "5rem", lg: "3.5rem" }}
      pb={{ base: "0rem", sm: "3rem" }}
      fontSize={{ md: "12px", lg: "1rem" }}
      bg={"white"}
      boxShadow={"0px 4px 6px rgba(0, 0, 0, 0.1)"}
    >
      <Heading fontSize={{ base: "2rem", sm: "2em", md: "3.5em" }}>
        {t("Can't find the answer to your question?")}
      </Heading>
      <Text color={"blueish.900"} fontSize="clamp(1rem,1vw,1.25rem)">
        {t("If you have any questions feel free to contact us")}
      </Text>
      <VStack
        gap={"1em"}
        alignItems={{ base: "flex-start", sm: "stretch" }}
        minW={{ base: "50%", sm: "60%", md: "75%", lg: "100%" }}
      >
        {/* <Box>
          <Text color={"blueish.900"} fontSize={"1em"} opacity={0.5}>
            Email
          </Text>
          <Input
            type={"email"}
            name={"email"}
            isInvalid={!!contactFormik.errors.email}
            onChange={contactFormik.handleChange}
            variant={"outline"}
            bg={"transparent !important"}
            border={"1px solid rgba(88, 154, 175, 0.3) !important"}
            placeholder={"your@mail.here"}
            fontSize={"1.25em"}
          />
          <Text color={"#D5738A"} h={"0"} fontSize={"xs"}>
            {contactFormik.errors.email}
          </Text>
        </Box>
        <Box>
          <Text color={"blueish.900"} fontSize={"1em"} opacity={0.5}>
            {t("Phone number")}
          </Text>
          <Input
            onChange={contactFormik.handleChange}
            isInvalid={!!contactFormik.errors.phone_number}
            name={"phone_number"}
            variant={"outline"}
            bg={"transparent !important"}
            border={"1px solid rgba(88, 154, 175, 0.3) !important"}
            placeholder={"+46123456789"}
            fontSize={"1.25em"}
          />
          <Text color={"#D5738A"} h={"0"} fontSize={"xs"}>
            {contactFormik.errors.phone_number}
          </Text>
        </Box> */}
        <Box>
          <Text color={"blueish.900"} fontSize={"1em"} opacity={0.5}>
            {t("Name")}
          </Text>
          <Input
            onChange={contactFormik.handleChange}
            isInvalid={!!contactFormik.errors.name}
            name={"name"}
            variant={"outline"}
            bg={"transparent !important"}
            border={"1px solid rgba(88, 154, 175, 0.3) !important"}
            fontSize={"1.25em"}
          />
          <Text color={"#D5738A"} h={"0"} fontSize={"xs"}>
            {contactFormik.errors.name}
          </Text>
        </Box>
        <Box>
          <Text color={"blueish.900"} fontSize={"1em"} opacity={0.5}>
            {t("Message")}
          </Text>
          <Textarea
            onChange={contactFormik.handleChange}
            fontSize={"1.25em"}
            name={"message"}
            variant={"outline"}
            bg={"transparent !important"}
            border={"1px solid rgba(88, 154, 175, 0.3) !important"}
            minH={"8em"}
          />
        </Box>
        <Flex justifyContent={"flex-end"}>
          <Button
            variant={"filledBlue"}
            isLoading={isSending}
            onClick={() => {
              contactFormik.submitForm();
            }}
            fontSize={"1rem"}
            w={"10rem"}
            boxShadow={"0px 4px 6px rgba(0, 0, 0, 0.1)"}
            _hover={{ transform: "scale(1.05)" }}
          >
            {t("Send")}
          </Button>
        </Flex>
      </VStack>
    </Flex>
  );
};

export default ContactForm;
