import { Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const ServiceTableHeader: React.FC = () => {
  const { t } = useTranslation();
  const headers = [
    { title: "service name" },
    { title: "service type" },
    { title: "role" },
    { title: "price", textAlign: "right" as "right" },
  ];

  return (
    <Thead bg="rgba(88, 154, 175, 0.4)">
      <Tr>
        {headers.map((header, index) => (
          <Th key={index} fontSize="1rem" textAlign={header.textAlign}>
            {t(`${header.title}`)}
          </Th>
        ))}
      </Tr>
    </Thead>
  );
};

export default ServiceTableHeader;
