import RequestCompleted from "features/login/components/RequestCompleted/RequestCompleted";
import { FunctionComponent } from "react";

interface LoginCompletedProps {}

const LoginCompleted: FunctionComponent<LoginCompletedProps> = () => {
  return <RequestCompleted />;
};

export default LoginCompleted;
