import { Flex, Image, Heading, Text, Button, HStack } from "@chakra-ui/react";

import notification_user_icon from "assets/icons/notification_user_icon.svg";
import { useTranslation } from "react-i18next";
import NotificationAccept from "ui/iconComponents/NotificationAcceptIcon";
import NotificationDecline from "ui/iconComponents/NotificationDeclineIcon";
import ConsentedTreatmentPlan from "../notificationConsentedTreatmentPlan";
import RequestedTreatmentPlan from "../notificationRequestedTreatmentPlan";

import {
  useApproveTreatment,
  useDeclineTreatment,
  useUserActiveRequest,
} from "../api/client";
import { useParams } from "react-router-dom";
import { useAdminUserInfo } from "features/userInfo/api/client";

interface NotificationForUserProps {
  onClose(): void;
}

const NotificationForUser: React.FC<NotificationForUserProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { data: userInfo } = useAdminUserInfo(id);

  const { data: userData } = useUserActiveRequest(id);

  const { trigger: approveTreatment } = useApproveTreatment(
    userData?.request.id || "",
    onClose
  );

  const { trigger: declineTreatment } = useDeclineTreatment(
    userData?.request.id || "",
    onClose
  );

  const handleDeclinedRequest = () => {
    declineTreatment().catch(() => {});
  };

  const handleAcceptRequest = () => {
    approveTreatment().catch(() => {});
  };

  return (
    <>
      {userData?.request.status === "Requested" && (
        <Flex
          w="100%"
          borderRadius="12px"
          bg="#f1efe3"
          direction="column"
          p="40px"
          gap="24px"
        >
          <HStack w="100%" justify="space-between">
            <Flex gap="20px">
              <Image src={notification_user_icon} />
              <Flex direction="column" gap="4px">
                <Text
                  color="rgba(0, 38, 55, 1)"
                  fontSize="16px"
                  fontWeight={400}
                >
                  {new Date(userData?.request.date || "").toLocaleDateString(
                    t("locale") ?? "en-GB",
                    {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    }
                  )}
                </Text>
                <Heading fontSize="20px" fontWeight={700}>
                  {t("User requested to change their treatment plan.")}
                </Heading>
              </Flex>
            </Flex>

            <Flex gap="12px">
              <Button
                variant="filter"
                bg="rgba(44, 132, 117, 1)"
                border="none"
                color="white"
                maxH="40px"
                gap="8px"
                onClick={handleAcceptRequest}
              >
                <NotificationAccept />
                {t("Accept request")}
              </Button>
              <Button
                variant="adjust"
                border="1px solid rgba(213, 115, 138, 1)"
                color="rgba(213, 115, 138, 1)"
                maxH="40px"
                gap="8px"
                onClick={handleDeclinedRequest}
              >
                <NotificationDecline />
                {t("Decline")}
              </Button>
            </Flex>
          </HStack>

          <Flex w="75%" gap="116px" align="flex-start">
            <ConsentedTreatmentPlan
              date={userData?.request.date}
              current_treatment={userData?.request.expected_duration}
              duration={userInfo?.treatment_duration}
              status_change_date={userInfo?.status_changed_date}
            />
            <RequestedTreatmentPlan
              expected_treatment={userData?.request.expected_duration}
              date={userData?.request.date}
            />
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default NotificationForUser;
