import { useCallback, useState } from "react";
import {
  CallInvitationEndReason,
  ZegoUIKitPrebuilt,
} from "@zegocloud/zego-uikit-prebuilt";
import {
  addDoc,
  collection,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { ZIM } from "zego-zim-web";
import { db } from "services/firebase/config";
import { useCallUserAPI, useEndCallAPI } from "features/Videocalls/api/client";
import { useGetProdToken } from "../api/client";
import { toast } from "react-toastify";
import { callback } from "chart.js/dist/helpers/helpers.core";

export const useLocalZegoCalls = (
  renderRef: any,
  listeners?: {
    onCallUser?: () => void;
    onJoinRoom?: () => void;
    onEndCall?: () => void;
    onError?: (error?: any) => void;
  }
) => {
  const [dbEntryId, setDbEntryId] = useState<string>();
  const [kitToken, setKitToken] = useState<string>();
  const [roomId, setRoomId] = useState<string>();
  const { trigger: fetchTokenRequest } = useGetProdToken();

  const { trigger: logInitCallRequest } = useCallUserAPI();
  const { trigger: logEndCallRequest } = useEndCallAPI(dbEntryId ?? "");

  //SERVICE STATES
  const [isRequestingCall, setIsRequestingCall] = useState<boolean>();

  const firebaseEndCall = async (roomID: string) => {
    const querySnapshot = await getDocs(
      query(
        collection(db, "zegoRooms"),
        where("roomID", "==", roomID),
        limit(1)
      )
    );

    const docRef = querySnapshot.docs.at(0)?.ref;

    if (docRef) {
      updateDoc(docRef, { status: "ended" });
    }
  };

  const firebasePatientCallJoined = async (roomID: string) => {
    const querySnapshot = await getDocs(
      query(
        collection(db, "zegoRooms"),
        where("roomID", "==", roomID),
        limit(1)
      )
    );

    const docRef = querySnapshot.docs.at(0)?.ref;

    if (docRef) {
      updateDoc(docRef, { status: "active" });
    }
  };

  const encodeUUIDToBase64 = (uuidString: string) => {
    const binaryUUID =
      uuidString
        ?.replace(/-/g, "")
        ?.match(/.{1,2}/g)
        ?.map((byte) => parseInt(byte, 16)) ?? [];

    const base64UUID = btoa(String.fromCharCode.apply(null, binaryUUID));

    return base64UUID;
  };

  const callUser = async (
    from: { id: string; name: string },
    to: { id: string; name: string },
    callbacks?: {
      onCallEnd?(): void;
    }
  ) => {
    const roomID = Math.floor(Math.random() * 10000) + "";

    const userID = from.id.replaceAll("-", "");

    const patientId = to.id.replaceAll("-", "");
    //const patientId = "123123";

    const responseData = await fetchTokenRequest({
      roomId: roomID,
      user_id: userID,
    });

    const userName = from.name;
    const appID = parseInt(process.env.REACT_APP_ZEGO_APP_ID!);

    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForProduction(
      appID,
      responseData?.token ?? "",
      "",
      userID,
      userName
    );

    setKitToken(kitToken);

    const zp = ZegoUIKitPrebuilt.create(kitToken);
    zp.addPlugins({ ZIM });

    const zim = ZIM.getInstance();

    try {
      await zim.login(userID, {
        token: responseData?.token ?? "",
        userName: userName,
        isOfflineLogin: false,
      });
    } catch (error: any) {
      debugger;
      console.log("🚀 ~ error:", error);
      listeners?.onError?.(error);

      if (error?.code === 6000104) {
        toast.error("Network connection issue, please try again later");
      }
    }

    try {
      zp.setCallInvitationConfig({
        //enableCustomCallInvitationDialog: true,
        enableNotifyWhenAppRunningInBackgroundOrQuit: true,

        // The following is the callback for showing the call invitation pop-up dialog when receiving a call invitation, this callback returns [accept] and [refuse] for you to set the corresponding UI.
        onSetRoomConfigBeforeJoining: () => {
          // Add your custom logic here.
          // The following shows an example, the confirmDialogDom is the DOM dialog object that you used to represent the dialog element. here the dialog indicates your custom call invitation dialog.
          return {
            container: renderRef.current,
            layout: "Auto",
            showRoomTimer: true,
            showTextChat: false,
          };
          // The method used to set refuse and accept operations.
        },

        onCallInvitationEnded: (reason, data) => {
          logEndCallRequest({
            call_from: from.id,
            call_to: to.id,
          });

          listeners?.onEndCall?.();

          //zim.logout();

          if ((reason as string) === "LeaveRoom") {
            callbacks?.onCallEnd?.();
          } else {
            listeners?.onError?.();
          }
        },

        // The callee will receive the notification through this callback when the caller accepts the call invitation.
        onOutgoingCallAccepted: (callID, callee) => {
          debugger;
          logInitCallRequest(
            {
              call_from: from.id,
              call_to: to.id,
            },
            {
              onSuccess: (response) => {
                setDbEntryId(response.id);
              },
            }
          );
          console.log("CALL ACCEPTED");
        },
      });

      const dashedInvitation = await zp.sendCallInvitation({
        callees: [{ userID: patientId, userName: to.name }],
        callType: 1,
        timeout: 60,
        notificationConfig: {
          resourcesID: "zego_call",
          title: "Call invitation",
          message: "Please join your call with our care personnel",
        },
      });
    } catch (error) {
      try {
        const base64Invitation = await zp.sendCallInvitation({
          callees: [{ userID: encodeUUIDToBase64(to.id), userName: to.name }],
          callType: 1,
          timeout: 60,
          notificationConfig: {
            resourcesID: "zego_call",
            title: "Call invitation",
            message: "Please join your call with our care personnel",
          },
        });
      } catch (error2) {
        listeners?.onError?.();
        try {
          const errorObject = JSON.parse(error2 as string);

          listeners?.onError?.(error2);

          if (errorObject.code === 6000281) {
            toast.error("User is not online, please try again later");
          }

          console.log(error2);
        } catch (error) {
          console.error(error);
        }

        listeners?.onError?.();
      }
    }

    // zp.joinRoom({
    //   container: renderRef.current,
    //   scenario: {
    //     mode: ZegoUIKitPrebuilt.OneONoneCall,
    //     config: {
    //       role: ZegoUIKitPrebuilt.Host,
    //     },
    //   },

    //   onLeaveRoom: () => {
    //     firebaseEndCall(roomID);
    //     logEndCallRequest({
    //       call_from: from.id,
    //       call_to: to.id,
    //     });
    //     endCall();
    //   },

    //   onUserJoin: () => {
    //     firebasePatientCallJoined(roomID);
    //   },

    //   onJoinRoom() {
    //     logInitCallRequest(
    //       {
    //         call_from: from.id,
    //         call_to: to.id,
    //       },
    //       {
    //         onSuccess: (response) => {
    //           setDbEntryId(response.id);
    //         },
    //       }
    //     );
    //     addDoc(collection(db, "zegoRooms"), {
    //       from: from,
    //       to: to,
    //       userID: to.id,
    //       roomID: roomID,
    //       status: "requesting",
    //     });
    //     listeners?.onJoinRoom?.();
    //   },

    //   turnOnMicrophoneWhenJoining: true,
    //   turnOnCameraWhenJoining: true,
    //   showMyCameraToggleButton: true,
    //   showMyMicrophoneToggleButton: true,
    //   showAudioVideoSettingsButton: true,
    //   showScreenSharingButton: true,
    //   showTextChat: true,
    //   showUserList: true,
    //   maxUsers: 2,
    //   layout: "Auto",
    //   showLayoutButton: false,
    // });

    // setRoomId(roomID);

    listeners?.onCallUser?.();
  };

  const endCall = () => {
    const zp = ZegoUIKitPrebuilt.create(kitToken ?? "");

    zp?.hangUp();

    zp?.destroy();
    setKitToken("");

    listeners?.onEndCall?.();
  };

  return {
    kitToken,
    callUser,
    endCall,
  };
};
