import React, { useEffect } from "react";
import { useFormik } from "formik";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Center,
  FormErrorMessage,
} from "@chakra-ui/react";

import { useUserFirstLogin } from "features/adminLogin/api/client";

import PasswordInput from "ui/components/PasswordInput";
import { UserFirstLoginSchema } from "features/adminLogin/api/validation";
import { useTokenStore } from "services/state/tokenStore";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface SignUpFormProps {
  setShowSignUpForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ setShowSignUpForm }) => {
  const { t } = useTranslation();

  const { trigger, isMutating } = useUserFirstLogin();

  const { access } = useTokenStore();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      new_password: "",
      confirm_password: "",
    },

    onSubmit: (values) => {
      try {
        const response = trigger(
          {
            email: values.email,
            password: values.password,
            new_password: values.new_password,
            confirm_password: values.confirm_password,
          },
          {
            onError: (message) => {
              toast.error(message.response.data);
            },
          }
        );

        console.log("Success!", response);
      } catch (error) {
        console.error("Error:", error);
      }
    },

    validationSchema: UserFirstLoginSchema,
  });

  useEffect(() => {
    if (access && access !== "") {
      setShowSignUpForm(false);
    }
  }, [access]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl isInvalid={!!formik.errors.email} h="75px">
        <FormLabel
          fontWeight={600}
          fontSize={{ base: "12px", lg: "14px" }}
          color="rgba(0, 38, 55, 1)"
          fontFamily="secondary"
          letterSpacing={0.7}
        >
          EMAIL
        </FormLabel>
        <Input
          id="email"
          name="email"
          type="email"
          placeholder="Enter your email"
          _placeholder={{
            color: "rgba(0, 38, 55, 0.5)",
            fontSize: "16px",
          }}
          onChange={formik.handleChange}
          value={formik.values.email}
          minH={{ base: "3rem", lg: "3.5rem !important" }}
        />
        <FormErrorMessage color="red" fontSize="14px">
          {formik.errors.email}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={!!formik.errors.password}
        mt={{ base: "20px", lg: "36px" }}
        h="75px"
      >
        <FormLabel
          fontWeight={600}
          fontSize={{ base: "12px", lg: "14px" }}
          color="rgba(0, 38, 55, 1)"
          fontFamily="secondary"
          letterSpacing={0.7}
          textTransform="uppercase"
        >
          {t("temporary password (provided to you by super admin)")}
        </FormLabel>

        <PasswordInput
          id="password"
          name="password"
          placeholder="Enter temporary password"
          onChange={formik.handleChange}
          value={formik.values.password}
        />

        <FormErrorMessage color="red" fontSize="14px">
          {formik.errors.password}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={!!formik.errors.new_password}
        mt={{ base: "40px", sm: "20px", md: "20px", lg: "36px" }}
        h="75px"
      >
        <FormLabel
          fontWeight={600}
          fontSize={{ base: "12px", lg: "14px" }}
          color="rgba(0, 38, 55, 1)"
          fontFamily="secondary"
          letterSpacing={0.7}
          textTransform="uppercase"
        >
          {t("new password")}
        </FormLabel>

        <PasswordInput
          id="new_password"
          name="new_password"
          placeholder="Create new password"
          onChange={formik.handleChange}
          value={formik.values.new_password}
        />

        <FormErrorMessage color="red" fontSize="14px">
          {formik.errors.new_password}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={!!formik.errors.confirm_password}
        mt={{ base: "20px", lg: "36px" }}
        h="75px"
      >
        <FormLabel
          fontWeight={600}
          fontSize={{ base: "12px", lg: "14px" }}
          color="rgba(0, 38, 55, 1)"
          fontFamily="secondary"
          letterSpacing={0.7}
          textTransform="uppercase"
        >
          {t("confirm password")}
        </FormLabel>

        <PasswordInput
          id="confirm_password"
          name="confirm_password"
          placeholder="Confirm new password"
          onChange={formik.handleChange}
          value={formik.values.confirm_password}
        />

        <FormErrorMessage color="red" fontSize="14px">
          {formik.errors.confirm_password}
        </FormErrorMessage>
      </FormControl>

      <Center py="2.8rem">
        <Button
          w="full"
          type="submit"
          bg="rgba(88, 154, 175, 1)"
          borderColor="white"
          fontFamily="secondary"
          fontWeight={700}
          fontSize="16px"
          letterSpacing={1}
          color={"white"}
          _hover={{
            bg: "blueish.700",
          }}
          maxW="235px"
          onClick={() => {
            formik.submitForm();
            console.log("submited");
          }}
          isLoading={isMutating}
          isDisabled={!formik.isValid}
        >
          {t("Login")}
        </Button>
      </Center>
    </form>
  );
};

export default SignUpForm;
