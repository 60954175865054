import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { IoCloseOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";

import graph from "assets/images/graph.svg";
import { discoverFirstPageBlocks } from "features/faqInfo/utils";

import { FaqLinks } from "../faqDiscoverTwo/components/FaqLinks";
import { useTranslation } from "react-i18next";

const FaqDiscoverOne = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleContactUsClick = () => {
    history.push("/faq#contact-form");
  };
  return (
    <VStack
      w="full"
      align="flex-start"
      py="3rem"
      px={{ base: "1rem", sm: "5rem", md: "10rem" }}
      justify="center"
    >
      <Button
        onClick={() => history.goBack()}
        variant="unstyled"
        alignSelf="flex-end"
      >
        <Flex align="center">
          <IoCloseOutline size={25} />
          <Box as="span" mt={1}>
            {t("Close")}
          </Box>
        </Flex>
      </Button>

      <VStack
        w="full"
        align="flex-start"
        justify="center"
        px={{ base: ".5rem", sm: "2rem", md: "10rem" }}
        py="2rem"
        spacing={"20px"}
      >
        <Image src={graph} />
        <Heading
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1.5rem", sm: "2rem" }}
          fontWeight={500}
        >
          {t("How do I know it is time to downsize?")}
        </Heading>
        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "Before you start tapering off with Lamina, you and one of our knowledgeable doctors go through your situation, medication and history to make an assessment together as to whether it might be time to taper off your antidepressants."
          )}
        </Text>
        <Heading
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1.25rem", sm: "1.5rem" }}
          fontWeight={500}
        >
          {t("But how do you know if it is time to book an appointment?")}
        </Heading>
        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "Each person is unique and your care team must always make an assessment of what your particular situation looks like. However, we see that there are certain factors that indicate that you may be ready to try to taper off, and that also make it easier to feel good during the taper."
          )}
        </Text>

        <Flex w="full" justify="center" align="center">
          <Grid
            templateColumns={{
              base: "1fr",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
            }}
            gap={2}
            w="full"
          >
            {discoverFirstPageBlocks.map(({ id, image, text }, index) => (
              <GridItem key={id} colSpan={index < 3 ? 1 : 1.5}>
                <Box
                  py="17px"
                  px="20px"
                  bgColor="rgba(238, 245, 247, 1)"
                  borderRadius="12px"
                  h="full"
                >
                  <Image src={image} w="24px" />
                  <Text
                    mt="12px"
                    color="rgba(0, 38, 55, 1)"
                    fontSize="clamp(16px, 1vw, 20px)"
                    fontWeight={400}
                  >
                    {t(text)}
                  </Text>
                </Box>
              </GridItem>
            ))}
          </Grid>
        </Flex>

        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "If you are not sure if you are ready, remember that you can make an initial appointment with one of our experienced nurses without committing to anything. In the meeting, you talk through your situation and you get an initial assessment of whether a downsizing would be right for you, and you then decide for yourself whether you want to continue or not."
          )}
        </Text>

        <FaqLinks onContactUsClick={handleContactUsClick} />

        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "Get started and book your first appointment by downloading the app."
          )}
        </Text>
      </VStack>
    </VStack>
  );
};

export default FaqDiscoverOne;
