import PrescriptionForm from "features/prescriptionForm";
import { Center, Flex } from "@chakra-ui/react";

const Prescription = () => {
  return (
    <Center
      minW={"100%"}
      bg={"white"}
      borderRadius={"1.5rem"}
      flexGrow={"1"}
      alignItems={"stretch"}
    >
      <PrescriptionForm />
    </Center>
  );
};

export default Prescription;
