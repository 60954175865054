import { Center, Heading, HStack, SimpleGrid, VStack } from "@chakra-ui/react";
import { useFetchCSVTableByMedicineName } from "features/taperingCSV/api/client";
import { FunctionComponent } from "react";
import PillIcon from "ui/iconComponents/Pillicon";
import TablePillsComponent from "../TablePillsComponent";
import { useTranslation } from "react-i18next";

interface TaperingTableProps {
  medicineType: string;
  taperingRate: number;
  pharmacy?: string;
}

const TaperingTable: FunctionComponent<TaperingTableProps> = ({
  medicineType,
  taperingRate,
  pharmacy,
}) => {
  const { t } = useTranslation();

  const { data: medicineData } = useFetchCSVTableByMedicineName(
    medicineType,
    taperingRate,
    pharmacy
  );

  return (
    <VStack alignItems={"flex-start"}>
      <VStack
        w={"100%"}
        border={"1px solid #589AAF50"}
        borderRadius={"1.5rem"}
        alignItems={"stertch"}
        gap={"0"}
        spacing={0}
      >
        <SimpleGrid
          columns={5}
          fontSize={"1.5rem"}
          fontWeight={"600"}
          color={"blueish.500"}
          borderBottom={"1px solid #589AAF50"}
        >
          <Center borderRight={"1px solid #589AAF50"}></Center>
          <Center borderRight={"1px solid #589AAF50"} p={"0.5rem"}>
            Dose
          </Center>
          <Center borderRight={"1px solid #589AAF50"} p={"0.5rem"}>
            Regular
          </Center>
          <Center borderRight={"1px solid #589AAF50"} p={"0.5rem"}>
            APL:
          </Center>
          <Center pb={"0.5rem"}>RegenBoogen</Center>
        </SimpleGrid>

        {medicineData?.tapering.map((e, index) => {
          return (
            <SimpleGrid
              columns={5}
              fontSize={"1rem"}
              fontWeight={"400"}
              borderBottom={"1px solid #589AAF50"}
            >
              <Center
                fontWeight={"600"}
                borderRight={"1px solid #589AAF50"}
                p={"1rem"}
                fontSize={"1.1rem"}
                opacity={"0.75"}
              >
                {t("Step")} {index + 1}
              </Center>
              <Center borderRight={"1px solid #589AAF50"}>{e.dose} mg</Center>
              <Center borderRight={"1px solid #589AAF50"}>
                <TablePillsComponent pills={e.regular} />
              </Center>
              <Center borderRight={"1px solid #589AAF50"}>
                <TablePillsComponent pills={e.apl} />
              </Center>
              <Center>
                <TablePillsComponent pills={e.regenboogen} />
              </Center>
            </SimpleGrid>
          );
        })}
      </VStack>
    </VStack>
  );
};

export default TaperingTable;
