import React, { CSSProperties, useMemo, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { Box } from "@chakra-ui/react";

import moment from "moment";

import {
  WorkingDaysObject,
  Rule,
  Interval,
  BusyWorkingHours,
} from "features/superadmin/api/types";

import "./index.css";

import CalendarEventModal from "./CalendarEventModal";

moment.locale("en");
const localizer = momentLocalizer(moment);

export interface CalendarEvent {
  title: string;
  start: Date;
  end: Date;
  type: "availability" | "external";
  meetingId?: string;
  userId?: string;
}

interface WorkingCalendarWidgetProps {
  workingDays?: WorkingDaysObject;
  busyHours?: BusyWorkingHours;
  personelRole: string | undefined;
  step?: number;
}

const WorkingCalendarWidget: React.FC<WorkingCalendarWidgetProps> = ({
  workingDays,
  busyHours,
  personelRole,
  step = 1,
}) => {
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(
    null
  );
  const ref = useRef<HTMLDivElement | null>(null);

  const convertWorkingDaysToEvents = (
    workingDays?: WorkingDaysObject,
    busyHours?: BusyWorkingHours
  ): CalendarEvent[] => {
    const events: CalendarEvent[] = [];

    workingDays?.data.rules.forEach((rule: Rule) => {
      if (rule.type === "wday") {
        rule.intervals.forEach((interval: Interval) => {
          const start = moment()
            .day(rule.wday.toLowerCase())
            .set({
              hour: +interval.from.split(":")[0],
              minute: +interval.from.split(":")[1],
            })
            .toDate();

          const end = moment()
            .day(rule.wday.toLowerCase())
            .set({
              hour: +interval.to.split(":")[0],
              minute: +interval.to.split(":")[1],
            })
            .toDate();

          events.push({
            title: "",
            start,
            end,
            type: "availability" as const,
          });
        });
      }
    });

    busyHours?.data.forEach((busyHour) => {
      const start = moment(busyHour.start_time).toDate();
      const end = moment(busyHour.end_time).toDate();

      const personel = `${busyHour.meeting?.staff_member?.first_name ?? ""} ${
        busyHour.meeting?.staff_member?.last_name ?? ""
      }`;

      const patient = `${busyHour.meeting?.patient?.first_name ?? ""} ${
        busyHour.meeting?.patient?.last_name ?? ""
      }`;

      const title =
        busyHour.meeting?.type?.role && personel && patient
          ? busyHour.meeting.type.type === "Prescription renewal"
            ? `${busyHour.meeting.type.type} for patient ${patient} by ${busyHour.meeting.type.role} ${personel}`
            : `${busyHour.meeting.type.type} call from ${busyHour.meeting.type.role} ${personel} to patient ${patient}`
          : "External call";

      events.push({
        title: title,
        start,
        end,
        type: "external" as const,
        meetingId: busyHour.meeting?.id,
        userId: busyHour.meeting?.patient.id,
      });
    });

    return events;
  };

  const events: CalendarEvent[] = useMemo(
    () => convertWorkingDaysToEvents(workingDays, busyHours),
    [workingDays, busyHours]
  );

  const eventStyleGetter = (event: CalendarEvent) => {
    let style: CSSProperties = {};

    if (event.type === "external") {
      style = {
        alignItems: "stretch",
        whiteSpace: "nowrap",
        paddingInline: "10px",
        //maxWidth: "max-content",
        backgroundColor: "rgba(88, 154, 175, 1)",
        color: "white",
        fontWeight: 500,
        border: "none",
        maxWidth: "600px",
        minWidth: "100%",
        boxShadow: "0 2px 14px rgba(0, 0, 0, 0.1)",
        zIndex: 1,
      };
    }
    if (event.title === "External call") {
      style = {
        paddingInline: "10px",
        minWidth: "100%",
        maxWidth: '100%',
        backgroundColor: "rgba(200, 200, 175, 0.5)",
        color: "white",
        fontWeight: 500,
        border: "none",
        boxShadow: "0 2px 14px rgba(0, 0, 0, 0.1)",
      };
    }
    if (event.type === "availability") {
      style = {
        backgroundColor: "rgba(88, 154, 175, 0.5)",
        color: "rgba(0, 38, 55, 1)",
        fontWeight: 500,
        minWidth: "100%",
        border: "none",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        whiteSpace: "nowrap",
        paddingInline: "10px",
      };
    }

    if (event.end < new Date()) {
      style.opacity = 0.5;
      style.backgroundColor = "rgba(169, 169, 169, 0.5)";
    }

    return {
      style,
    };
  };

  const dayPropGetter = () => ({
    style: {
      backgroundColor: "transparent",
    },
  });

  const handleEventEdit = (event: CalendarEvent, e: any) => {
    setSelectedEvent(event);
  };

  const startHours = new Date(new Date().setHours(6, 0, 0));
  const endHours = new Date(new Date().setHours(21, 0, 0));

  return (
    <Box>
      <Calendar
        localizer={localizer}
        events={events}
        step={step}
        views={["day", "week", "agenda"]}
        doShowMoreDrillDown={true}
        defaultView="day"
        startAccessor="start"
        endAccessor="end"
        min={startHours}
        max={endHours}
        eventPropGetter={eventStyleGetter}
        dayPropGetter={dayPropGetter}
        onSelectEvent={(event, e) => handleEventEdit(event, e)}
      />
      <CalendarEventModal
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        meetingId={selectedEvent?.meetingId}
        personelRole={personelRole || ""}
        ref={ref}
      />
    </Box>
  );
};

export default WorkingCalendarWidget;
