import { useFetchProfileInfo } from "features/dashboard/api/client";
import {
  collection,
  collectionGroup,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "services/firebase/config";
import { useFetchAllUsersByIds } from "../api/client";

const chatCollectionName =
  process.env.REACT_APP_ENV === "prod" ? "chats" : "chats_dev";

export const useChatsList = () => {
  const [chatsList, setChatsList] = useState<any[]>([]);
  const [usersId, setUsersId] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: myData } = useFetchProfileInfo();
  const { data: users } = useFetchAllUsersByIds(usersId, setIsLoading);

  const hanldeClearUnread = (chatId: string) => {
    if (chatsList) {
      setChatsList(
        chatsList.map((chat) => {
          if (chat.id === chatId) {
            return {
              ...chat,
              unread: 0,
            };
          }
          return chat;
        })
      );
    }
  };

  useEffect(() => {
    if (myData) {
      //debugger;
      const chatsQuery = query(
        collection(db, chatCollectionName),
        where("users", "array-contains", myData.id)
      );

      setIsLoading(true);
      const unsubscribe = onSnapshot(
        chatsQuery,
        async (querySnapshot) => {
          const chats: any[] = [];

          querySnapshot.forEach(async (doc) => {
            const unreadMessagesCollectionQuery = query(
              collection(db, chatCollectionName, doc.id, "messages"),
              where("unRead", "array-contains", myData.id)
            );

            const lastMessageQuery = query(
              collection(db, chatCollectionName, doc.id, "messages"),
              orderBy("publishedAt", "desc"),
              limit(1)
            );

            const unreadMessages = await getDocs(unreadMessagesCollectionQuery);
            const lastMessage = await getDocs(lastMessageQuery);
            const lastMessageData = lastMessage.docs.at(0)?.data();
            chats.push({
              ...doc.data(),
              id: doc.id,
              unread: unreadMessages.docs.filter((e) => {
                return e.data().publishedBy !== myData.id;
              }).length,
              patientId: doc
                .data()
                .users.filter((userId: string) => userId !== myData.id)[0],
              lastMessage: lastMessageData?.message
                ? (lastMessageData?.publishedBy === myData.id ? "you: " : "") +
                  lastMessageData?.message
                : undefined,
              lastMessageTime: lastMessageData?.publishedAt
                ? new Date(
                    new Date(lastMessageData?.publishedAt ?? "").getTime() -
                      new Date().getTimezoneOffset() * 60 * 1000
                  )
                : undefined,

              createdAt: new Date(
                new Date(doc.data().createdAt ?? "").getTime()
              ),
            });

            setChatsList([...chats]);

            setUsersId(
              chats
                .map((chat) =>
                  chat.users.filter((userId: string) => userId !== myData.id)
                )
                .flat()
            );
          });
        },
        () => {
          setIsLoading(false);
          toast.error(
            "Something went wrong with fetching messagaes, please contact the administrator"
          );
        }
      );
      return () => {
        unsubscribe();
      };
    }
  }, [myData?.id]);

  return { users, chatsList, isLoading, hanldeClearUnread };
};
