import graph from "assets/images/graph.svg";
import calculate from "assets/icons/calculate.svg";
import initials from "assets/images/initials.svg";
import smile from "assets/icons/smile.svg";
import chart from "assets/icons/chart.svg";
import nature from "assets/icons/nature.svg";
import accessibility from "assets/icons/accessibility.svg";
import drug from "assets/icons/drug.svg";

export const discoverBlocks = [
  {
    id: "discover1",
    image: graph,
    title: "How do I know it is time to downsize?",
  },
  {
    id: "discover2",
    image: calculate,
    title: "How much does it cost to step down with Lamina?",
  },
  {
    id: "discover3",
    image: initials,
    title: "Extemporaneous medicine, what is it?",
  },
];

export const discoverFirstPageBlocks = [
  {
    id: 1,
    image: smile,
    text: "You feel better now than you did when you were first prescribed your medications",
  },
  {
    id: 2,
    image: chart,
    text: "You want to get off or lower the dose yourself",
  },
  {
    id: 3,
    image: nature,
    text: "You have a stable life situation, without strong stress or big changes around the corner",
  },
  {
    id: 4,
    image: accessibility,
    text: "You have people around you who support you",
  },
  {
    id: 5,
    image: drug,
    text: "You are not taking any other medications that interact with the antidepressants",
  },
];

export const faqQestionsGroup = [
  {
    category: "treatment",
    questions: [
      {
        question: "Hur vet jag att det är dags att trappa ned?",
        answer: "Description for FAQ 1",
      },
      {
        question: "Vad kostar det att trappa ned med Lamina?",
        answer: "Description for FAQ 2",
      },
      {
        question: "Extemporeläkemedel, vad är det?",
        answer: "Description for FAQ 3",
      },
    ],
  },
  {
    category: "frequently",
    questions: [
      {
        question: "Vad är Lamina Clinic?",
        answer:
          "Vi är en klinik specialiserad på nedtrappning och utsättning av antidepressiva läkemedel. Vi hjälper patienter trappa ner i ett lugnt tempo med hjälp av individuella nedtrappningsplaner och specialtillverkade läkemedelsdoser. Vi följer upp ofta och regelbundet och hjälper på så sätt våra patienter till en så smärtfri nedtrappning som möjligt med maximalt stöd.",
      },
      {
        question:
          "Vad är det för skillnad på Lamina Clinic och en vårdcentral?",
        answer:
          "Lamina Clinic är specialiserade på nedtrappning av antidepressiva och stöttar dig under din nedtrappning. Om du blir sjuk eller har besvär som inte har med nedtrappningen att göra så ska du vända dig till din vanliga vårdcentral eller vårdgivare. Om det är akut ska du alltid ringa 112 eller ta dig till närmaste akutmottagning.",
      },
      {
        question: "Varför ska jag trappa ned med Lamina?",
        answer:
          "Med Lamina får du en individuell nedtrappningsplan och dessutom recept på specialtillverkad medicin i mycket små nedtrappningsdoser. Det gör nedtrappningen skonsam och minskar risken för att du ska må dåligt när du trappar ned. Dessutom håller ditt vårdteam tät kontakt med dig så att du får snabb hjälp om du mot förmodan skulle känna av tuffa utsättningssymptom.",
      },
      {
        question: "Vilken är Laminas metod?",
        answer: "",
      },
    ],
  },
];

export const tableColors = [
  "rgba(88, 154, 175, 1)",
  "rgba(88, 154, 175, 0.9)",
  "rgba(88, 154, 175, 0.8)",
  "rgba(88, 154, 175, 0.7)",
  "rgba(88, 154, 175, 0.6)",
  "rgba(88, 154, 175, 0.5)",
];

export const pricingData = [
  {
    month: "Month 1",
    details: [
      { activity: "Start-up visit with a nurse", cost: "275 kr" },
      { activity: "Doctor's visit with prescription", cost: "499 kr" },
      { activity: "Medication Name", cost: "depends on medication" },
    ],
    total: "774 kr",
  },
  {
    month: "Month 2",
    details: [{ activity: "Follow-up meeting", cost: "275 kr" }],
    total: "275 kr",
  },
  {
    month: "Month 3",
    details: [
      { activity: "Follow-up meeting", cost: "275 kr" },
      { activity: "Prescription renewal", cost: "275 kr" },
      { activity: "Medication Name", cost: "depends on medication" },
    ],
    total: "550 kr",
  },
  {
    month: "Month 4",
    details: [{ activity: "Follow-up meeting", cost: "275 kr" }],
    total: "275 kr",
  },
  {
    month: "Month 5",
    details: [{ activity: "Follow-up meeting", cost: "275 kr" }],
    total: "275 kr",
  },
  {
    month: "Month 6",
    details: [
      { activity: "Follow-up meeting", cost: "275 kr" },
      { activity: "Prescription renewal", cost: "275 kr" },
      { activity: "Medication Name", cost: "depends on medication" },
    ],
    total: "550 kr",
  },
];
