import {
  Flex,
  Heading,
  IconButton,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import MeetingLoggerModal from "features/doctors/components/MeetingLoggerModal";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useParams } from "react-router-dom";
import MeetingLogsItem from "./components/MeetingLogsItem";
import { useFetchMeetingsLogs } from "features/staffCommons/api/client";

const MeetingLogsWidget = () => {
  const { t } = useTranslation();
  const { id: userID } = useParams<{ id: string }>();

  const { data } = useFetchMeetingsLogs(userID, 1);

  const filterFutureMeetings = (meetings: any[]) => {
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    return meetings.filter(
      (meeting) => new Date(meeting.timestamp) >= yesterday
    );
  };

  const futureMeetings = data ? filterFutureMeetings(data.results) : [];

  const {
    isOpen: isMeetingLoggerOpen,
    onOpen: onMeetingLoggerOpen,
    onClose: onMeetingLoggerClose,
  } = useDisclosure();

  const handleCreateANewMeeting = () => {
    onMeetingLoggerOpen();
  };
  return (
    <VStack
      w="100%"
      p="2rem"
      borderRadius="12px"
      bg="white"
      id={"meeting-logs"}
      align="flex-start"
    >
      <Flex
        w="full"
        align="center"
        justify="space-between"
        borderBottom="1px solid rgba(88, 154, 175, 0.2)"
        pb="1.25rem"
      >
        <Heading
          color="rgba(88, 154, 175, 0.8)"
          fontSize={"1.25rem"}
          fontWeight={600}
          textTransform={"uppercase"}
        >
          {t("Meetings logs")}
        </Heading>

        <Flex alignItems={"center"} gap={"1rem"}>
          <Text color={"blueish.500"} fontWeight={700} fontSize={"1rem"}>
            {t("Schedule New Meeting")}
          </Text>

          <IconButton
            aria-label={""}
            bg={"blueish.500"}
            border={"none"}
            minWidth={0}
            minH={"3rem"}
            w={"3rem"}
            icon={<FiPlus />}
            fontSize={"1.5rem"}
            p={"0 !important"}
            color={"white"}
            onClick={handleCreateANewMeeting}
          />
        </Flex>
      </Flex>

      <VStack w="full" py="20px">
        {futureMeetings.length === 0 ? (
          <Text color="rgba(0, 38, 55, 0.5)" fontSize="20px">
            {t("No appointments scheduled")}
          </Text>
        ) : (
          futureMeetings.map((item) => (
            <MeetingLogsItem
              key={item.id}
              timestamp={item.timestamp}
              callFromFirstName={item.staff_member.first_name}
              callFromLastName={item.staff_member.last_name}
              payment_status={
                item.status === "CANCELED"
                  ? "CANCELLED"
                  : item.payment?.status || "NOT_PAID"
              }
              meetingId={item.id}
            />
          ))
        )}
      </VStack>

      <MeetingLoggerModal
        isOpen={isMeetingLoggerOpen}
        onClose={onMeetingLoggerClose}
      />
    </VStack>
  );
};

export default MeetingLogsWidget;
