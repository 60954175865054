import { Flex, Text, Spinner } from "@chakra-ui/react";

const Loader = () => {
  return (
    <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
      <Flex alignItems="center" p="5rem" justify="center" w="100%">
        <Text mt="3" fontWeight="bold" color="white">
          Loading...
        </Text>
        <Spinner color="white" size="md" mt=".5rem" />
      </Flex>
    </Flex>
  );
};

export default Loader;
