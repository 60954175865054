import {
  Box,
  Button,
  DeepPartial,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useUserChart } from "features/admin/api/client";
import { useParams } from "react-router-dom";
import { useAdminUserInfo } from "features/userInfo/api/client";
import {
  ChartDayDetailsType,
  CustomTooltipStateType,
  StabilizationDetailsType,
  TreatmentDetailsType,
} from "features/admin/api/types";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface UserChartProps {
  dateFrom?: Date;
  dateTo?: Date;
  resetFilters?(): void;
}

type ColorNamesType = Record<string, string>;

const UserChart: FunctionComponent<UserChartProps> = ({
  dateFrom,
  dateTo,
  resetFilters,
}) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const { data } = useUserChart(id);
  const { data: patientData } = useAdminUserInfo(id ?? "");

  const dms = 24 * 60 * 60 * 1000; //dms = day in miliseconds

  const treatmentDurationStartDate: Date = useMemo(() => {
    const firstTreatment = patientData?.treatment?.days[0];
    return new Date(firstTreatment?.date ?? Date.now());
  }, [patientData?.treatment]);

  const treatmentDurationEndDate = useMemo(() => {
    const treatmentLength = patientData?.treatment?.days.length ?? 0;
    return new Date(
      treatmentDurationStartDate.getTime() +
        treatmentLength * 24 * 60 * 60 * 1000
    );
  }, [patientData?.treatment, treatmentDurationStartDate]);

  const datedData = useMemo(() => {
    return (
      data?.chart_data.map((e, index) => ({
        ...e,
        weekStartDate: new Date(
          treatmentDurationStartDate.getTime() + index * 24 * 60 * 60 * 1000
        ),
      })) ?? []
    );
  }, [data?.chart_data, treatmentDurationStartDate]);

  const paddingArray = useMemo(() => {
    return dateFrom
      ? new Array(
          Math.floor(
            (treatmentDurationStartDate?.getTime() -
              (dateFrom?.getTime() ?? 0)) /
              dms
          ) > 0
            ? Math.floor(
                (treatmentDurationStartDate?.getTime() -
                  (dateFrom?.getTime() ?? 0)) /
                  dms
              )
            : 0
        ).fill(undefined)
      : [];
  }, [dateFrom, dms, treatmentDurationStartDate]);

  const trailingPaddingArray = useMemo(() => {
    return dateTo
      ? new Array(
          Math.floor(
            ((dateTo?.getTime() ?? 0) - treatmentDurationEndDate?.getTime()) /
              dms
          ) > 0
            ? Math.floor(
                ((dateTo?.getTime() ?? 0) -
                  treatmentDurationEndDate?.getTime()) /
                  dms
              )
            : 0
        ).fill(undefined)
      : [];
  }, [dateTo, treatmentDurationEndDate]);

  const labels = useMemo(() => {
    if (!datedData.length) return [];

    if (dateTo && dateFrom) {
      const filteredData = datedData.filter(
        (e) =>
          e.weekStartDate.getTime() <= dateTo?.getTime() + 5 * 60 * 60 * 1000 &&
          e.weekStartDate.getTime() >= dateFrom?.getTime()
      );

      return [
        ...paddingArray,
        ...filteredData.map((item, index) => ({
          ...item,
          label: `Dag ${index + 1}`,
        })),
        ...trailingPaddingArray,
      ];
    }

    return datedData.map((_, index) => `Dag ${index + 1}`);
  }, [datedData, dateFrom, dateTo, paddingArray, trailingPaddingArray]);

  // add stabilization period
  const chartStabilization = [
    ...paddingArray,
    ...(data?.chart_data.map((item) => item.stabilization) ?? []),
  ];

  const chartDosage = [
    ...paddingArray,
    ...(data?.chart_data.map((item) => item.dosage) ?? []),
  ];
  const chartMood = [
    ...paddingArray,
    ...(data?.chart_data.map((item) => item.mood) ?? []),
  ];

  // change colors for each part
  const getChartColors = (
    chartData: number[],
    stabilization: boolean[],
    data: any
  ) => {
    const colors = ["#9260BA", "#35a2eb", "#46AF53", "#D5CD63"];
    const colorNames: ColorNamesType = {
      "#9260BA": t("Treatment"),
      "#35a2eb": t("Initial treatment"),
      "#46AF53": t("Current treatment"),
      "#D5CD63": t("Treatment (future plan)"),
      "#FFA500": t("Stabilization"),
    };

    return chartData.map((_, i) => {
      const isStabilized = stabilization[i] === true;
      const color = isStabilized
        ? "#FFA500"
        : colors[(data?.chart_data[i]?.part ?? 0) % colors.length];
      return { color, name: colorNames[color] };
    });
  };

  const chartDosageColors = getChartColors(
    chartDosage,
    chartStabilization,
    data
  );

  const chartDosageBorderColors = chartDosageColors.map((c) => c.color);
  const chartDosageBackgroundColors = chartDosageColors.map((c) => c.color);
  const chartDosageColorNames = chartDosageColors.map((c) => c.name);

  // change PointRadius for display
  const calculatePointRadius = (
    ctx: { dataIndex: number },
    data: any,
    chartStabilization: boolean[]
  ): number => {
    const index = ctx.dataIndex;
    if (data && data.chart_data) {
      const part = data.chart_data[index]?.part;
      const isStartOrEnd =
        index === 0 ||
        index === data.chart_data.length - 1 ||
        (index > 0 && data.chart_data[index - 1]?.part !== part);
      const isStabilizationStart =
        chartStabilization[index] &&
        (index === 0 || !chartStabilization[index - 1]);

      return isStartOrEnd || isStabilizationStart ? 6 : 2;
    }
    return 2;
  };

  const chartData = {
    labels,
    datasets: [
      {
        label: "Nedtrappningsdos",
        data: chartDosage,
        borderColor: chartDosageBorderColors,
        backgroundColor: chartDosageBackgroundColors,
        pointBackgroundColor: chartDosageBorderColors,
        pointBorderColor: chartDosageBorderColors,
        yAxisID: "y",
        pointRadius: (ctx: { dataIndex: number }) =>
          calculatePointRadius(ctx, data, chartStabilization),
        segment: {
          borderColor: (ctx: { p0DataIndex: number; p1DataIndex: number }) => {
            const isSwitchingToNewPlan =
              chartStabilization[ctx.p0DataIndex] === true &&
              chartStabilization[ctx.p1DataIndex] === false;
            if (isSwitchingToNewPlan) {
              return chartDosageBorderColors[ctx.p1DataIndex];
            }
            return chartDosageBorderColors[ctx.p0DataIndex];
          },
          backgroundColor: (ctx: {
            p0DataIndex: number;
            p1DataIndex: number;
          }) => chartDosageBackgroundColors[ctx.p0DataIndex],
        },
      },
      {
        label: "Mående",
        data: chartMood,
        borderColor: "#F1775A",
        backgroundColor: "#F1775A",
        yAxisID: "y1",
        pointRadius: 2,
      },
    ],
  };

  const [tooltipData, setTooltipData] = useState<CustomTooltipStateType>();

  const tooltipDetails = useMemo<(ChartDayDetailsType | undefined)[]>(() => {
    if (!data?.chart_data) return [];

    return data?.chart_data.map((e) => {
      return e.details;
    });
  }, [data]);
  const [pointDetails, setPointDetails] = useState<any[]>();

  const options: ChartOptions<"line"> = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },

    plugins: {
      tooltip: {
        enabled: (ctx) => {
          return !tooltipDetails[ctx.tooltip?.dataPoints[0]?.dataIndex];
        },
        external: ({ tooltip, chart }) => {
          const newData = {
            dataIndex: tooltip.dataPoints[0].dataIndex,
            top: tooltip.caretY,
            left: tooltip.caretX, // use caretX value instead of x
          };

          if (tooltipDetails[newData.dataIndex]) {
            setTooltipData({
              ...newData,
              detailsData: tooltipDetails[newData.dataIndex],
            });
          } else if (!!tooltipData) {
            setTooltipData(undefined);
          }
        },
        callbacks: {
          label: (tooltipItem: any) => {
            const datasetLabel = tooltipItem.dataset.label || "";
            const dataPoint = tooltipItem.parsed.y;
            const dayLabel =
              tooltipItem.dataset.label === "Nedtrappningsdos"
                ? `${dataPoint} mg`
                : `${dataPoint}`;
            const colorName = chartDosageColorNames[tooltipItem.dataIndex];
            return `${datasetLabel}: ${dayLabel} (${colorName})`;
          },
        },
        backgroundColor: "rgba(88, 154, 175, 1)",
      },
      legend: {
        position: "top",
        align: "end",
      },
      title: {
        display: true,
      },
    },
    scales: {
      y: {
        type: "linear" as const,
        display: true,
        position: "right" as const,
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: "Dos, mg",
        },
      },
      y1: {
        type: "linear" as const,
        display: true,
        reverse: true,
        position: "left" as const,
        min: 1,
        max: 7,
        ticks: {
          callback: (value) => {
            return (
              (value === 1 ? "Bra " : value === 7 ? "Riktigt dåligt " : "") +
              value
            );
          },
        },
      },
    },
  };

  const isTreatmentDetailType = (
    detail: ChartDayDetailsType
  ): detail is TreatmentDetailsType => {
    return (detail as TreatmentDetailsType).period_duration !== undefined;
  };

  return (
    <Flex
      w="full"
      py="2rem"
      borderRadius="12px"
      bg="white"
      direction="column"
      alignItems={"flex-start"}
      position={"relative"}
    >
      {/* <Button
        alignSelf="flex-end"
        variant={"ghost"}
        fontWeight={"400"}
        border={"1px solid rgba(0, 0, 0, 0.1)"}
        onClick={resetFilters}
      >
        {t("See full period")}
      </Button> */}
      <Line options={options} data={chartData} style={{ maxWidth: "100%" }} />

      {tooltipData && tooltipData.detailsData && (
        <Box
          position={"absolute"}
          top={tooltipData.top + 40}
          left={tooltipData.left}
          bg={"white"}
          borderRadius={"0.5rem"}
          boxShadow={"0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.04)"}
          p="1rem"
        >
          {isTreatmentDetailType(tooltipData.detailsData) && (
            <>
              <Box>
                <>{t("Patient has been assigned a new plan")}</>
                <Box
                  p={"1rem"}
                  border={"1px solid rgba(0, 0, 0, 0.2)"}
                  borderRadius={"0.5rem"}
                >
                  <Heading
                    fontSize="1.25rem"
                    fontWeight={700}
                    color="rgba(0, 38, 55, 1)"
                    fontFamily="main"
                  >
                    {tooltipData.detailsData.medication}
                  </Heading>
                  <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
                    {t("Dosage")} :{" "}
                    <Box as="span" fontWeight={700}>
                      {`${tooltipData.detailsData.start_dose} mg > 0 mg`}
                    </Box>
                  </Text>
                  <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
                    {t("Pace")}:{" "}
                    <Box as="span" fontWeight={700}>
                      {tooltipData.detailsData?.tapering_pace}%
                    </Box>
                  </Text>
                  <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
                    {t("Days between doses")}:{" "}
                    <Box as="span" fontWeight={700}>
                      {tooltipData.detailsData?.period_duration} {t("days")}
                    </Box>
                  </Text>
                  <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
                    {t("Total")}:{" "}
                    <Box as="span" fontWeight={700}>
                      {tooltipData.detailsData?.length_in_weeks} {t("weeks")}
                    </Box>
                  </Text>
                </Box>
              </Box>
            </>
          )}

          {!isTreatmentDetailType(tooltipData.detailsData) && (
            <Box>
              <>{t("Patient has been stabilized")}</>
              <Box
                p={"1rem"}
                border={"1px solid rgba(0, 0, 0, 0.2)"}
                borderRadius={"0.5rem"}
              >
                <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
                  {t("Dose")} :{" "}
                  <Box as="span" fontWeight={700}>
                    {`${tooltipData.detailsData.dose} mg > 0 mg`}
                  </Box>
                </Text>
                <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
                  {t("Stabilization period duration")}:{" "}
                  <Box as="span" fontWeight={700}>
                    {tooltipData.detailsData?.length_in_days} {t("days")}
                  </Box>
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default UserChart;
