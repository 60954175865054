import { Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useRef } from "react";

import NotUseAppIcon from "ui/iconComponents/NotUseAppIcon";
import UseAppIcon from "ui/iconComponents/UseAppIcon";

interface AppUsageProps {
  appUsageFilter: string;
  onAppUsageFilterChange: (value: string) => void;
}

const AppUsage: React.FC<AppUsageProps> = ({
  appUsageFilter,
  onAppUsageFilterChange,
}) => {
  const filtersRef = useRef<string[]>([]);
  const handleFilterChange = (value: string) => {
    if (!filtersRef.current.includes(value)) {
      filtersRef.current.push(value);
    }

    if (value === appUsageFilter) {
      onAppUsageFilterChange("");
    } else {
      onAppUsageFilterChange(value);
    }
  };
  return (
    <Flex direction="column" gap="12px">
      <Text
        fontSize="14px"
        fontWeight={600}
        color="rgba(255, 255, 255, 1)"
        lineHeight="20px"
      >
        {t("APP USAGE").toUpperCase()}
      </Text>
      <Flex gap={1}>
        <Flex
          bg={
            appUsageFilter === "treatment_started"
              ? "rgba(255, 255, 255, 1)"
              : "rgba(255, 255, 255, 0.5)"
          }
          p="15px"
          borderRadius="8px"
          cursor="pointer"
          onClick={() => {
            handleFilterChange("treatment_started");
          }}
        >
          <UseAppIcon />
        </Flex>
        <Flex
          bg={
            appUsageFilter === "not_used"
              ? "rgba(255, 255, 255, 1)"
              : "rgba(255, 255, 255, 0.5)"
          }
          p="15px"
          borderRadius="8px"
          cursor="pointer"
          onClick={() => handleFilterChange("not_used")}
        >
          <NotUseAppIcon />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AppUsage;
