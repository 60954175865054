import { Flex, Heading, Text, Image } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import left_arrow_white from "assets/icons/left_arrow_white.svg";

import { useTranslation } from "react-i18next";

const FaqHeader = () => {
  const history = useHistory();

  const { t } = useTranslation();

  const handleBack = () => {
    history.goBack();
  };
  return (
    <Flex
      w="100%"
      direction="column"
      align="flex-start"
      py={{ base: "0px", sm: "1.25rem", md: "6.25rem", lg: "5rem" }}
      px={{ base: "1.5rem", sm: "3.75rem", md: "5rem", lg: "1.5rem" }}
      pb={{ base: ".75rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
    >
      <Flex align="center" gap="14px" onClick={handleBack} cursor="pointer">
        <Image src={left_arrow_white} />
        <Text fontSize="18px">{t("Back")}</Text>
      </Flex>

      <Heading
        color="rgba(255, 255, 255, 1)"
        fontSize={{ base: "3rem", sm: "3.5rem", lg: "4rem" }}
        fontWeight={500}
      >
        {t("Help Center")}
      </Heading>
      <Text
        fontSize={{ base: "16px", sm: "18px", md: "20px", lg: "24px" }}
        w={{ base: "100%", sm: "70%", md: "60%", lg: "50%" }}
      >
        {t(
          "Here you will find answers to the most commonly asked questions about Lamina Clinic."
        )}
      </Text>
    </Flex>
  );
};

export default FaqHeader;
