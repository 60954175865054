import { Center } from "@chakra-ui/react";

import { useHistory } from "react-router-dom";

import { useEffect } from "react";
import { useGeneralLoginData } from "features/login/api/client";
import { UserResponseType } from "features/login/api/types";
import {
  useDashboardInfo,
  useFetchProfileInfo,
} from "features/dashboard/api/client";
import MemoizedWidget from "./components/memoizedWidget";
import { profile } from "console";

const PrescriptionForm = () => {
  const history = useHistory();

  const { data: userData, isLoading } = useFetchProfileInfo();

  const { data: dashboardData } = useDashboardInfo();
  useEffect(() => {
    if (!userData?.status) {
      return;
    }

    if (isLoading) {
      return;
    }

    if (userData?.status === "registered") {
      //this condition used to be a redirect for back when we had a waiting list
      //too scared it will break the fragile logic now so just replaced it with a return
      return;
    } else if (userData?.status === "premium") {
      return;
    } else {
      history.push("/dashboard");
    }
  }, [userData?.status, isLoading]);

  return (
    <Center w={"100%"}>
      <MemoizedWidget />
    </Center>
  );
};

export default PrescriptionForm;
