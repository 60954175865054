import { Divider, Flex, Image, Text } from "@chakra-ui/react";

import logout_black from "assets/icons/logout_black.svg";
import logo_group from "assets/icons/Logo.svg";

import { useTranslation } from "react-i18next";
import { useFetchProfileInfo } from "features/dashboard/api/client";

import ChatList from "features/doctors/components/ChatDrawer/components/ChatList/ChatList";
import Notifications from "features/notifications/notificationMain";
import { MdOutlineQueryStats } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useLogOut } from "features/login/api/client";

import { MdClose } from "react-icons/md";

type SuperAdminDrawerProps = {
  onClose: () => void;
};

const SuperAdminDrawer: React.FC<SuperAdminDrawerProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { trigger } = useLogOut();
  const { data: userData } = useFetchProfileInfo();

  const logOutFunction = () => {
    trigger();
    history.push("/");
  };

  const roleTranslations = {
    doctor: t("Doctor"),
    nurse: t("Nurses"),
    superadmin: t("superadmin"),
  };

  return (
    <Flex w="full" direction="column" py="5">
      <Flex w="full" align="center" justify="space-between">
        <Image
          src={logo_group}
          alt="logo_group"
          h="2rem"
          cursor={"pointer"}
          onClick={() => {
            history.push("/");
          }}
        />
        <MdClose onClick={onClose} size={25} />
      </Flex>

      <Divider
        borderColor="rgba(88, 154, 175, 0.5)"
        w="100vw"
        py="1rem"
        ml="-6"
      />

      <Flex align="center" justify="center" py="5">
        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", md: "1.25rem", lg: "1.5rem" }}
        >
          {t("Role")}:
        </Text>
        <Text
          color="rgba(0, 38, 55, 0.8)"
          fontSize={{ base: "1rem", md: "1.25rem", lg: "1.5rem" }}
          fontWeight={700}
          textTransform={"capitalize"}
        >
          {userData
            ? roleTranslations[
                userData.role as keyof typeof roleTranslations
              ] || t("superadmin")
            : t("superadmin")}
        </Text>
      </Flex>

      <Flex
        w="full"
        align="flex-start"
        justify="center"
        direction="column"
        gap="5"
        fontSize="1.5rem"
        px="4rem"
      >
        <ChatList />

        <Notifications />

        {(userData?.role === "admin" || userData?.role === "superadmin") && (
          <Flex
            gap={2}
            align="center"
            onClick={() => {
              history.push("/superadmin/reports");
            }}
            cursor="pointer"
          >
            <MdOutlineQueryStats />
            <Text
              color="rgba(0, 38, 55, 0.9)"
              fontWeight={700}
              fontSize="1.5rem"
              whiteSpace="nowrap"
            >
              {t("Statistics")}
            </Text>
          </Flex>
        )}

        <Flex gap={2} align="center">
          <Image src={logout_black} alt="Log out icon" />
          <Text
            color="rgba(0, 38, 55, 0.9)"
            fontWeight={700}
            fontSize="1.5rem"
            onClick={logOutFunction}
            cursor="pointer"
            whiteSpace="nowrap"
          >
            {t("Logout")}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SuperAdminDrawer;
