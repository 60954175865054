import {
  Box,
  Divider,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useAdminUserInfo } from "features/admin/api/client";
import { useFetchAllDoctors } from "features/doctors/api/client";
import { useFetchAllNurses } from "features/superadmin/api/client";
import ApproveTreatment from "features/superadmin/components/ApproveTreatmentCheckbox";

import { useTranslation } from "react-i18next";
import { GoPencil } from "react-icons/go";
import { useParams } from "react-router-dom";
import BoxWithStatusColor from "./components/BoxWithStatusColor.tsx";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import AssignPersonnelModal from "features/superadmin/components/PatientsTable/components/AssignPersonnelModal/index";

import { useState } from "react";

const PatientMedicalInformation = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { data: userData, mutate: mutateUserData } = useAdminUserInfo(id);
  const { data: doctorsData, mutate: mutateDoctors } = useFetchAllDoctors();
  const { data: allNursesList, mutate: mutateNurses } = useFetchAllNurses();
  const { data: profileData } = useFetchProfileInfo();

  const [assigned, setAssigned] = useState<boolean>(false);

  const {
    isOpen: isAssignModalOpen,
    onClose: onAssignModalClose,
    onOpen: onAssignModalOpen,
  } = useDisclosure();

  const mainDoctorId = userData?.doctors?.[0] || "";
  const secondaryDoctorId = userData?.doctors?.[1] || "";
  const nurseId = userData?.nurses?.[0] || "";
  const secondaryNurseId = userData?.nurses?.[1] || "";

  const mainDoctor = doctorsData?.doctors.find(
    (doctor) => doctor.id === mainDoctorId
  );
  const secondaryDoctor = doctorsData?.doctors.find(
    (doctor) => doctor.id === secondaryDoctorId
  );
  const nurse = allNursesList?.nurses.find((nurse) => nurse.id === nurseId);
  const secondaryNurse = allNursesList?.nurses.find(
    (nurse) => nurse.id === secondaryNurseId
  );

  const formatStatus = (status: string): string => {
    if (!status) {
      return "";
    }

    const formattedStatus = status
      .replace(/_/g, " ")
      .toLowerCase()
      .split(" ")
      .map((word, index) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedStatus;
  };

  return (
    <Stack
      bg="white"
      borderRadius="0.75rem"
      px="2rem"
      height="fit-content"
      maxW="100%"
      minW="23rem"
      spacing="1.5rem"
      position="relative"
      py={profileData?.role === "superadmin" ? "" : "1.5rem"}
      pb="2rem"
    >
      {profileData?.role === "superadmin" && (
        <Box position="absolute" top="1rem" right="1rem">
          <IconButton
            aria-label=""
            icon={<GoPencil />}
            minWidth={0}
            variant="transparent"
            p="0 !important"
            color="#0026377F"
            fontSize="1.5rem"
            onClick={onAssignModalOpen}
          />
          <Text color="#0026377F" fontSize=".6rem">
            {t("Edit")}
          </Text>
        </Box>
      )}

      <Stack py="2">
        {/* <Heading
          fontSize="1rem"
          fontWeight={600}
          fontFamily="secondary"
          color="#589AAF"
          textTransform="uppercase"
        >
          {t("medical information")}
        </Heading> */}

        <Box py=".5rem">
          <Text
            w="max-content"
            py="1"
            px="2"
            borderRadius=".5rem"
            color={!userData?.is_treatment_declined ? "#43B3BA" : "#D5738A"}
            fontSize="1rem"
            fontWeight={700}
            bgGradient={
              !userData?.is_treatment_declined
                ? "linear-gradient(0deg, rgba(67, 179, 186, 0.20) 0%, rgba(67, 179, 186, 0.20) 100%), #FFF"
                : "linear-gradient(0deg, rgba(213, 115, 138, 0.20) 0%, rgba(213, 115, 138, 0.20) 100%), #FFF;"
            }
          >
            {t(
              !userData?.is_treatment_declined
                ? t("Approved for treatment")
                : t("Declined for treatment")
            )}
          </Text>
        </Box>

        <Heading
          py=".5rem"
          pb="-1"
          fontSize=".875rem"
          textTransform="uppercase"
          color="#002637"
          fontWeight={600}
          opacity={0.5}
        >
          {t("treatment stage")}
        </Heading>
        <BoxWithStatusColor status={userData?.status || ""}>
          {t(formatStatus(userData?.status || ""))}
        </BoxWithStatusColor>
      </Stack>

      <Divider />

      <Stack direction="column" mt="5" flexDirection="column">
        <Heading
          fontSize=".875rem"
          textTransform="uppercase"
          color="#002637"
          fontWeight={600}
          opacity={0.5}
        >
          {t("Doctor")}
        </Heading>
        {mainDoctor && (
          <Flex
            w="full"
            align="center"
            justify="space-between"
            whiteSpace="nowrap"
          >
            <Text color="#000000" fontSize="1rem" fontWeight={400}>
              {mainDoctor.first_name} {mainDoctor.last_name} ({t("main")})
            </Text>
          </Flex>
        )}

        {secondaryDoctor && (
          <Text color="#000000" fontSize="1rem" fontWeight={400}>
            {secondaryDoctor.first_name} {secondaryDoctor.last_name} (
            {t("secondary")})
          </Text>
        )}
      </Stack>

      <Stack direction="column" mt="5" flexDirection="column">
        <Heading
          fontSize=".875rem"
          textTransform="uppercase"
          color="#002637"
          fontWeight={600}
          opacity={0.5}
        >
          {t("Nurse")}
        </Heading>
        {nurse && (
          <Flex
            w="full"
            align="center"
            justify="space-between"
            whiteSpace="nowrap"
          >
            <Text color="#000000" fontSize="1rem" fontWeight={400}>
              {nurse.first_name} {nurse.last_name} ({t("main")})
            </Text>
          </Flex>
        )}

        {secondaryNurse && (
          <Text color="#000000" fontSize="1rem" fontWeight={400}>
            {secondaryNurse.first_name} {secondaryNurse.last_name} (
            {t("secondary")})
          </Text>
        )}
      </Stack>

      <AssignPersonnelModal
        patientsList={[]}
        patientId={[userData?.id || ""]}
        patient={
          userData?.first_name && userData?.last_name
            ? `${userData.first_name} ${userData.last_name}`
            : ""
        }
        isOpen={isAssignModalOpen}
        onClose={() => {
          onAssignModalClose();
          setAssigned(true);
          mutateDoctors();
          mutateNurses();
          mutateUserData();
        }}
      />
    </Stack>
  );
};

export default PatientMedicalInformation;
