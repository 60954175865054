import { Box, Divider, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useAdminUserInfo } from "features/admin/api/client";
import {
  useFetchProfileInfo,
  usePatchAdminUserProfile,
} from "features/dashboard/api/client";

import { useGetMedicationsType } from "features/superadmin/api/client";

import EditMedicationPopover from "./components/EditMedicationPopover";

const MedicalInformation = () => {
  const { t } = useTranslation();
  const { id: userID } = useParams<{ id: string }>();
  const { data: patientData } = useAdminUserInfo(userID);
  const { data: profile } = useFetchProfileInfo();

  const canEditMedications =
    profile?.role === "superadmin" || profile?.role === "doctor";

  const { data: medicationType } = useGetMedicationsType();

  const { trigger: patchUserProfile, isMutating } =
    usePatchAdminUserProfile(userID);

  const medicationOptions = medicationType
    ? medicationType.map((medication) => ({
        value: medication.id.toString(),
        label: medication.name,
      }))
    : [];

  const initialValues = {
    medication_id: patientData?.medication?.id?.toString() || "",
    user_dose: patientData?.dose !== undefined ? patientData.dose : 0,
  };

  const onSubmit = (values: any) => {
    patchUserProfile(values);
  };

  const treatmentDurationInWeeks = Math.ceil(
    (patientData?.treatment?.days?.length ?? 0) / 7
  );

  const treatmentStatusColors: Record<string, { color: string }> = {
    NO_PLAN: {
      color: "#3b3738",
    },
    NOT_STARTED: {
      color: "#8c230e",
    },
    STARTED: {
      color: "#1c8436",
    },
    STABILIZED: {
      color: "#c9790f",
    },
    FINISHED: {
      color: "#100f0f",
    },
  };

  const treatmentStatus = patientData?.treatment_status || "NO_PLAN";

  return (
    <VStack
      w="full"
      h="fit-content"
      maxW="20rem"
      borderRadius={"0.75rem"}
      bg={"#589AAF1A"}
      p={"2rem"}
      alignItems={"flex-start"}
      position={"relative"}
    >
      <Heading
        fontSize="1.25rem"
        fontWeight={700}
        color="rgba(0, 38, 55, 1)"
        fontFamily="main"
      >
        {patientData?.medication?.name}
      </Heading>
      <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
        {t("Plan")} :{" "}
        <Box as="span" fontWeight={700}>
          {patientData?.treatment?.start_dose !== null &&
          patientData?.treatment?.start_dose !== undefined
            ? `${patientData?.treatment?.start_dose} mg > 0 mg`
            : `${patientData?.current_dose} mg`}
        </Box>
      </Text>

      {patientData?.has_treatment && (
        <>
          <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
            {t("Pace")}:{" "}
            <Box as="span" fontWeight={700}>
              {patientData.tapering_pace}%
            </Box>
          </Text>
          <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
            {t("Intervall")}:{" "}
            <Box as="span" fontWeight={700}>
              {patientData.period_duration} {t("days")}
            </Box>
          </Text>
          <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
            {t("Total")}:{" "}
            <Box as="span" fontWeight={700}>
              {treatmentDurationInWeeks} {t("weeks")}
            </Box>
          </Text>
        </>
      )}

      <Divider py=".5rem" borderColor="rgba(0, 38, 55, 0.4)" />

      <Flex py="1rem" direction="column" gap=".5rem">
        {patientData?.has_treatment ? (
          patientData?.is_active_treatment ? (
            <>
              <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
                {t("Status")}:{" "}
                <Box
                  as="span"
                  fontWeight={700}
                  color={treatmentStatusColors[treatmentStatus].color}
                >
                  {t(treatmentStatus)}
                </Box>
              </Text>
              <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
                {t("Current week")}:{" "}
                <Box as="span" fontWeight={700}>
                  {patientData.current_week} {t("of")}{" "}
                  {treatmentDurationInWeeks}
                </Box>
              </Text>
              <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
                {t("Today’s dose")}:{" "}
                <Box as="span" fontWeight={700}>
                  {patientData?.current_dose} mg
                </Box>
              </Text>
            </>
          ) : (
            <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
              {t("Status")}:{" "}
              <Box
                as="span"
                fontWeight={700}
                color={treatmentStatusColors[treatmentStatus].color}
              >
                {t(treatmentStatus)}
              </Box>
            </Text>
          )
        ) : (
          <Text color="rgba(0, 38, 55, 1)" fontSize="1rem">
            {t("Status")}:{" "}
            <Box
              as="span"
              fontWeight={700}
              color={treatmentStatusColors[treatmentStatus].color}
            >
              {t(treatmentStatus)}
            </Box>
          </Text>
        )}
      </Flex>

      {canEditMedications && (
        <EditMedicationPopover
          medicationOptions={medicationOptions}
          initialValues={initialValues}
          onSubmit={onSubmit}
          isMutating={isMutating}
        />
      )}
    </VStack>
  );
};

export default MedicalInformation;
