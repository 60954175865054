import {
  Box,
  TableContainer,
  VStack,
  Table as ChakraTable,
  Tbody,
  Center,
  Spinner,
  Tr,
  Td,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { TableHeaderItemType, TableRowItemType } from "../../api/types";
import TableHeader from "../TableHeader";
import TableRow from "../TableRow";

interface TableProps {
  columns?: TableHeaderItemType[];
  rows?: TableRowItemType[];
  isLoading?: boolean;
}

const Table: FunctionComponent<TableProps> = ({
  columns = [],
  rows = [],
  isLoading = false,
}) => {
  return (
    <TableContainer borderRadius={"0.75rem"}>
      {isLoading && (
        <Center w={"100%"} minH={"20rem"} bg={"white"} borderRadius={"0.75rem"}>
          <Spinner height={"3rem"} width="3rem" />
        </Center>
      )}

      {!isLoading && (
        <ChakraTable
          variant="unstyled"
          style={{ borderCollapse: "separate", borderSpacing: "0 1px" }}
        >
          <TableHeader columns={columns} />
          {rows.length === 0 && (
            <Tr>
              <Td colSpan={columns.length} bg={"white"}>
                <Center h={"10rem"} fontSize={"1.5rem"} opacity={"0.4"}>
                  No data here yet
                </Center>
              </Td>
            </Tr>
          )}

          <Tbody>
            {rows.map((rowData) => {
              return <TableRow rowData={rowData} columns={columns} />;
            })}
          </Tbody>
        </ChakraTable>
      )}
    </TableContainer>
  );
};

export default Table;
