import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";
import { useCreateNewStaffUser } from "features/superadmin/api/client";
import { newUserValidationSchema } from "features/superadmin/services/validationSchemas";
import { useFormik } from "formik";
import { FunctionComponent, useEffect, useState } from "react";
import StaffForm from "../StaffForm";
import SuccessForm from "../SuccessMessage";
import { useTranslation } from "react-i18next";

interface AddUserModalProps {
  isOpen: boolean;
  onClose(): void;
  role: string;
}

const AddUserModal: FunctionComponent<AddUserModalProps> = ({
  isOpen,
  onClose,
  role,
}) => {
  const { t } = useTranslation();

  const [password, setPassword] = useState<string>("");

  const { trigger } = useCreateNewStaffUser();

  useEffect(() => {
    setPassword("");
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"3xl"}>
      <ModalOverlay />
      <ModalContent pt={"1.5rem"} px={"1rem"}>
        {password === "" && (
          <ModalHeader
            fontSize={"2rem"}
            color={"blueish.900"}
            fontFamily={"secondary"}
          >
            {t("Add New User")}
          </ModalHeader>
        )}

        <ModalCloseButton top={"1.5rem"} right={"1.5rem"} fontSize={"1rem"} />
        <ModalBody mb={"2rem"}>
          <VStack alignItems={"flex-start"} gap={"2rem"}>
            <Box
              bg={"blueish.500"}
              color={"white"}
              px={"1rem"}
              py={"0.5rem"}
              fontWeight={"700"}
              textTransform={"capitalize"}
              borderRadius={"0.5rem"}
            >
              {role}
            </Box>
            {password !== "" && (
              <SuccessForm password={password} onClose={onClose} />
            )}

            {password === "" && (
              <StaffForm
                role={role}
                onGetPartialUserData={(data) => {
                  trigger(
                    { ...data, role: role },
                    {
                      onSuccess: (response) => {
                        debugger;
                        setPassword(response.password);
                      },
                    }
                  );
                }}
              />
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddUserModal;
