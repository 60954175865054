import { APIClient } from "services/api/client";
import useSWR, { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";
import {
  DoctorsListResponseType,
  LogAMeetingRequestType,
  MeetingTypeByUserResponseType,
  MeetingsTypesResponseType,
} from "./types";
import { useFetchUserNotes } from "features/admin/api/client";

export const useFetchAllDoctors = () => {
  return useSWR<DoctorsListResponseType>(`admin/doctors`, (url: string) =>
    APIClient.get(url)
  );
};

export const useFetchAvailableMeetingTypes = () => {
  return useSWR<MeetingsTypesResponseType>(`meeting-type/`, (url: string) =>
    APIClient.get(url)
  );
};

export const useFetchAvailableMeetingTypesByPersonnelId = (id?: string) => {
  return useSWR<MeetingTypeByUserResponseType>(
    id ? `meeting-type/for-user/${id}` : undefined,
    (url: string) => APIClient.get(url)
  );
};

export const useLogAMeeting = (patientId: string, doctorId: string) => {
  const { mutate } = useFetchUserNotes(patientId);

  return useSWRMutation(
    "meeting/",
    (url, { arg }: { arg: LogAMeetingRequestType }) => {
      const requestData: LogAMeetingRequestType = {
        type: arg.type,
        staff_member: doctorId, //call_from
        patient: patientId, //call_to
        timestamp: arg.timestamp,
      };

      if (arg.comment !== undefined && arg.comment.trim() !== "") {
        requestData.comment = arg.comment;
      }

      return APIClient.post(url, requestData);
    },
    {
      onSuccess: () => {
        mutate();
      },
    }
  );
};
