import { VStack, Text, ChakraComponent, StyleProps } from "@chakra-ui/react";
import { UserInfo } from "features/admin/api/types";
import AdminStatus from "features/admin/components/adminStatus";
import { t } from "i18next";
import { FunctionComponent } from "react";

interface StatusComponentProps {
  userInfo: UserInfo;
  style?: StyleProps;
}

const StatusComponent: FunctionComponent<StatusComponentProps> = ({
  userInfo,
  style,
}) => {
  return (
    <VStack alignItems={"flex-start"} {...style}>
      <AdminStatus
        status={userInfo.status.toString()}
        isDoctorChecked={
          userInfo.is_doctor_approve || userInfo.is_doctor_rejected
        }
      />
      <Text color={"#002637"} fontSize={"0.875rem"} opacity={0.5}>
        <b>{t("Changed")}: </b>
        {new Date(userInfo.status_changed_date).toLocaleString(
          t("locale") ?? "en-GB",
          {
            day: "numeric",
            month: "short",
            year: "numeric",
          }
        )}
      </Text>
    </VStack>
  );
};

export default StatusComponent;
