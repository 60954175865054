import { create } from "zustand";
import { persist } from "zustand/middleware";
import { AdminServiceStore } from "./types";

export const useAdminServiceStore = create(
  persist<AdminServiceStore>(
    (set) => ({
      userListSize: 10,
      setUserListSize: (newSize) => {
        set((state) => ({ ...state, userListSize: newSize }));
      },
    }),
    {
      name: "admin-service-store", // name of the item in the storage (must be unique)
    }
  )
);
