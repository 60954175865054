import { Box, Divider, Flex, Heading, HStack, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import PillIcon from "ui/iconComponents/Pillicon";
import { BsCapsule } from "react-icons/bs";

interface CurrentDayMedicationProps {
  dayNumber: number;
  tablets: number[];
  is_active_treatment: boolean;
}

const CurrentDayMedication: FunctionComponent<CurrentDayMedicationProps> = ({
  dayNumber,
  tablets,
  is_active_treatment,
}) => {
  const { t } = useTranslation();
  const occurrences = tablets.reduce((acc, curr) => {
    return (
      acc.has(curr) ? acc.set(curr, acc.get(curr) + 1) : acc.set(curr, 1), acc
    );
  }, new Map());

  return (
    <Flex w={"100%"} align="center" gap=".75rem">
      <Text color="rgba(0, 38, 55, 1)" fontSize="1rem" fontWeight={700}>
        {is_active_treatment ? t("Day") + " " + dayNumber : "Ej startad"}
      </Text>

      {is_active_treatment && (
        <>
          <Divider
            orientation="vertical"
            h="24px"
            borderColor="rgba(88, 154, 175, 0.4)"
          />
          <Flex gap='0.25rem'>
            <Text color="rgba(0, 38, 55, 0.5)" fontSize="1rem" fontWeight={500}>
              Dos:
            </Text>
            <Text color="rgba(0, 38, 55, 1)" fontSize="1rem" fontWeight={700}>
              {tablets.reduce((a, b) => a + b, 0).toFixed(2)} mg
              {is_active_treatment
                ? ", " + tablets.length + " " + t("capsules")
                : ""}
            </Text>
          </Flex>

          <Divider
            orientation="vertical"
            h="1.5rem"
            borderColor="rgba(88, 154, 175, 0.4)"
          />
          <HStack gap={"1rem"}>
            {Array.from(occurrences.keys()).map((dose) => {
              return (
                <Flex alignItems={"center"} gap={"0.5rem"}>
                  <Flex>
                    {Array.from(Array(occurrences.get(dose))).map((pill) => {
                      return (
                        <BsCapsule
                          color="rgba(88, 154, 175, 0.8)"
                          size="25px"
                        />
                      );
                    })}
                  </Flex>

                  <Text fontSize="1rem" color="blueish.500" fontWeight={500}>
                    {dose.toFixed(2)} mg
                  </Text>
                </Flex>
              );
            })}
          </HStack>
        </>
      )}
    </Flex>
  );
};

export default CurrentDayMedication;
