import { Hide, Show, VStack } from "@chakra-ui/react";
import ContactBubble from "features/contactForm/components/ContactBubble";
import React, { FunctionComponent } from "react";
import WebinarNavbar from "../Navbar/WebinarNavbar/WebinarNavbar";
import WebinarNavbarMobile from "../Navbar/WebnarNavbarMobile/WebinarNavbarMobile";

interface AdminLayoutProps {
  children?: React.ReactNode[] | React.ReactNode;
  fullWidth?: boolean;
}

const WebinarLayout: FunctionComponent<AdminLayoutProps> = ({
  children,
  fullWidth,
}) => {
  return (
    <VStack
      alignItems={"center"}
      justifyContent={"flex-start"}
      w={"100% !important"}
      minH={"100vh !important"}
      pt={"2rem"}
      position={"relative"}
      bg="white"
      overflow={"hidden"}
    >
      <Show above="sm">
        <WebinarNavbar />
      </Show>
      <Hide above="sm">
        <WebinarNavbarMobile />
      </Hide>

      <VStack
        width={"100%"}
        justify="flex-start"
        align="center"
        maxWidth={{
          base: "100%",
          sm: "1250px",
          lg: "1980px",
        }}
        px={{ base: "3%", md: "1.5%", sm: "5%" }}
        flexGrow={"1"}
      >
        <VStack
          width={fullWidth ? "100%" : { base: "98%", sm: "100%" }}
          flexGrow={"1"}
          mb={"5vh"}
        >
          {children}
        </VStack>
      </VStack>
      <ContactBubble />
    </VStack>
  );
};

export default WebinarLayout;
