import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";

const NoResults = () => {
  return (
    <Flex
      w="100%"
      p="1rem"
      justifyContent="center"
      alignItems="center"
      bg="white"
      mt="2rem"
    >
      <Flex alignItems="center" h="auto">
        <Text fontWeight="bold" color="blueish.500">
          {t("Sorry, no matches found")}
        </Text>
      </Flex>
    </Flex>
  );
};

export default NoResults;
