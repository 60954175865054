import { Box, Flex, Spinner } from "@chakra-ui/react";
import { useGetTreatmentPlan } from "features/prescriptionForm/api/client";
import InvalidPrescription from "features/prescriptionForm/components/InvalidPrescription";
import PrescriptionPopup from "features/prescriptionForm/components/prescriptionPopup";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Treatment = () => {
  const {
    data: treatmentDurationData,
    error,
    isLoading,
  } = useGetTreatmentPlan();

  const history = useHistory();

  if (treatmentDurationData && !error) {
    history.push("/wait-list");
    return null;
  }

  return (
    <Flex w="100%" bg="white" borderRadius="20px">
      {isLoading && <Spinner />}
      {!isLoading && (
        <>{error && error.response.status === 403 && <InvalidPrescription />}</>
      )}
    </Flex>
  );
};

export default Treatment;
