import Home from "pages/Home/Home";
import { RouteType } from "./services/types/types";
import DefaultLayout from "ui/layout/DefaultLayout/DefaultLayout";
import DashboardLayout from "ui/layout/DashboardLayout/DashboardLayout";
import Prescription from "pages/Prescription/Prescription";
import LoginPage from "pages/LoginPage/LoginPage";
import LoginCompleted from "pages/LoginCompleted";
import Dashboard from "pages/Dashboard/Dashboard";
import UserDashboard from "pages/Dashboard/UserDashboard";
import Settings from "pages/Dashboard/Settings";
import PaymentPage from "pages/PaymentPage";
import Treatment from "pages/Treatment";
import AdminPanel from "pages/AdminPanel/AdminPanel";
import AdminPanelLogin from "pages/AdminPanelLogin/AdminPanelLogin";
import AdminUserInfo from "features/admin/components/adminUserInfo";
import AdminLayout from "ui/layout/AdminLayout/AdminLayout";
import AdminLoginLayout from "ui/layout/AdminLoginLayout/AdminLoginLayout";
import FAQ from "pages/FAQ/FAQ";
import FaqDashboard from "features/faqInfo/components/faqDashboard";
import TestPaymentPage from "pages/TestPaymentPage";
import Taper from "pages/Taper";
import TermOfPurchase from "pages/TermsOfPurchase";
import PrivacyPolicy from "pages/PrivacyPolicy";
import CookiePolicy from "pages/CookiePolicy";
import Medicines from "pages/Medicines";
import TestPage from "pages/TestPage/TestPage";
import TestPageLayout from "ui/layout/TestPageLayout/TestPageLayout";
import PaymentSuccessful from "pages/PaymentSuccessful";
import PaymentFailed from "pages/PaymentFailed";
import AboutLaminaClinic from "pages/AboutLaminaClinic/AboutLaminaClinic";
import PrescriptionAndMedication from "pages/Prescription&Medication/PrescriptionAndMedication";
import BuyAtLaminaClinic from "pages/BuyAtLaminaClinic/BuyAtLaminaClinic";
import DoctorContact from "pages/DoctorContact";
import DoctorRejected from "pages/DoctorRejected";
import Webinar from "pages/Webinar/Webinar";
import WebinarLayout from "ui/layout/WebinarLayout/WebinarLayout";
import ContactForm from "pages/ContactForm";
import ArticleTaperingAntidepressants from "pages/ArticleTaperingAntidepressants";

import ArticleDifficultToStopOrTaperAntidepressants from "pages/ArticleDifficultToStopOrTaperAntidepressants";
import CalendlyBookPage from "pages/CalendlyBookPage";
import PaymentAborted from "pages/PaymentAborted";

import GeneralLayout from "ui/layout/GeneralLayout/GeneralLayout";
import GeneralPage from "pages/General/General";

import AdminLoginReset from "pages/AdminLoginReset/AdminLoginReset";

import AdminSignUp from "pages/AdminSignUp/AdminSignUp";
import SuperAdmin from "pages/SuperAdmin/SuperAdmin";
import SuperAdminLayout from "ui/layout/SuperAdminLayout/SuperAdminLayout";
import AdminLoginResetConfirm from "pages/AdminLoginResetConfirm/AdminLoginResetConfirm";
import SuperAdminPatientView from "pages/SuperAdminPatientView/SuperAdminPatientView";
import NursePage from "pages/Nurse";
import NursePatientPage from "pages/NursePatientPage";
import DoctorDashboard from "pages/DoctorDashboard/DoctorDashboard";
import DoctorPatientPage from "pages/DoctorPatientPage/DoctorPatientPage";
import TaperingCSVPage from "pages/TaperingCSV";
import WaitList from "pages/WaitList/WaitList";
import ReportsPage from "pages/ReportsPage/ReportsPage";
import StaffLogin from "pages/StaffLogin";
import EditProfile from "pages/EditProfile";
import TypeformMobilePasser from "pages/TypeformMobilePasser";
import EditProfileByPersonel from "pages/EditProfileByPersonel";

import FaqDiscover from "pages/FaqDiscover/FaqDiscover";
import ServicesPage from "pages/ServicesPage";
import OrdersPage from "pages/OrderPage";

export const routes: RouteType[] = [
  {
    path: "/",
    component: (
      <DefaultLayout>
        <Home />
      </DefaultLayout>
    ),
    exact: true,
  },
  {
    path: "/login-complete",
    component: (
      <DefaultLayout>
        <LoginCompleted />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: true,
  },
  {
    path: "/prescription",
    component: (
      <DefaultLayout fullWidth>
        <Prescription />
      </DefaultLayout>
    ),
    exact: false,
    isProtected: true,
  },
  {
    path: "/typeform-passer",
    component: <TypeformMobilePasser />,
    exact: false,
    isProtected: false,
  },
  {
    path: "/login",
    component: (
      <DefaultLayout fullWidth>
        <LoginPage />
      </DefaultLayout>
    ),
    exact: false,
  },
  {
    path: "/dashboard",
    component: (
      <DashboardLayout fullWidth={true}>
        <UserDashboard />
      </DashboardLayout>
    ),
    exact: true,
    isProtected: true,
  },
  {
    path: "/dashboard/order",
    component: (
      <DashboardLayout fullWidth={true}>
        <Dashboard />
      </DashboardLayout>
    ),
    exact: true,
    isProtected: true,
  },
  {
    path: "/dashboard/faq",
    component: (
      <DashboardLayout fullWidth={true}>
        <FaqDashboard />
      </DashboardLayout>
    ),
    exact: true,
    isProtected: true,
  },
  {
    path: "/dashboard/book-meeting",
    component: (
      <DashboardLayout>
        <CalendlyBookPage />
      </DashboardLayout>
    ),
    exact: true,
    isProtected: true,
  },
  {
    path: "/settings",
    component: (
      <DashboardLayout fullWidth={true}>
        <Settings />
      </DashboardLayout>
    ),
    exact: true,
    isProtected: true,
  },
  {
    path: "/admin",
    component: (
      <AdminLoginLayout fullWidth={true}>
        <AdminPanelLogin />
      </AdminLoginLayout>
    ),
    exact: true,
    isProtected: false,
    role: "admin",
  },
  {
    path: "/admin-signup",
    component: (
      <AdminLoginLayout fullWidth={true}>
        <AdminSignUp />
      </AdminLoginLayout>
    ),
    exact: true,
    isProtected: false,
    role: "admin",
  },
  {
    path: "/admin-login-reset",
    component: (
      <AdminLoginLayout fullWidth={true}>
        <AdminLoginReset />
      </AdminLoginLayout>
    ),
    exact: true,
    isProtected: false,
    role: "admin",
  },

  {
    path: "/admin-login-reset-confirmed/:token/:id/",
    component: (
      <AdminLoginLayout fullWidth={true}>
        <AdminLoginResetConfirm />
      </AdminLoginLayout>
    ),
    exact: true,
    isProtected: false,
    role: "admin",
  },

  {
    path: "/admin/users",
    component: (
      <AdminLayout fullWidth={true}>
        <AdminPanel />
      </AdminLayout>
    ),
    exact: true,
    isProtected: true,
    role: "admin",
  },
  {
    path: "/doctor/tapering",
    component: (
      <SuperAdminLayout fullWidth={true}>
        <TaperingCSVPage />
      </SuperAdminLayout>
    ),
    exact: true,
    isProtected: true,
    role: "doctor",
  },
  {
    path: "/admin/:id",
    component: (
      <AdminLayout fullWidth={true}>
        <AdminUserInfo />
      </AdminLayout>
    ),
    exact: true,
    isProtected: true,
    role: "admin",
  },
  {
    path: "/payment",
    component: (
      <DashboardLayout>
        <PaymentPage />
      </DashboardLayout>
    ),
    exact: true,
    isProtected: true,
  },
  {
    path: "/payment-test",
    component: (
      <DashboardLayout>
        <TestPaymentPage />
      </DashboardLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/choose-treatment-duration",
    component: (
      <DefaultLayout>
        <Treatment />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: true,
  },
  {
    path: "/faq",
    component: (
      <DefaultLayout fullWidth={true}>
        <FAQ />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/faq/:id",
    component: <FaqDiscover />,
    exact: true,
    isProtected: false,
  },
  {
    path: "/taper",
    component: (
      <DefaultLayout fullWidth={true}>
        <Taper />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/terms-of-purchase",
    component: (
      <DefaultLayout fullWidth={true}>
        <TermOfPurchase />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/privacy-policy",
    component: (
      <DefaultLayout fullWidth={true}>
        <PrivacyPolicy />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/cookie-policy",
    component: (
      <DefaultLayout fullWidth={true}>
        <CookiePolicy />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/medicines",
    component: (
      <DefaultLayout fullWidth={true}>
        <Medicines />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/social-ask-test",
    component: (
      <TestPageLayout fullWidth={true}>
        <TestPage />
      </TestPageLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/payment-successful",
    component: (
      <DefaultLayout fullWidth={true} minimalistic>
        <PaymentSuccessful />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/payment-failed",
    component: (
      <DefaultLayout fullWidth={true} minimalistic>
        <PaymentFailed />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/payment-aborted",
    component: (
      <DefaultLayout fullWidth={true} minimalistic>
        <PaymentAborted />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/about-lamina-clinic",
    component: (
      <DefaultLayout fullWidth={true}>
        <AboutLaminaClinic />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/prescription-medication",
    component: (
      <DefaultLayout fullWidth={true}>
        <PrescriptionAndMedication />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/buy-at-lamina-clinic",
    component: (
      <DefaultLayout fullWidth={true}>
        <BuyAtLaminaClinic />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/prescription-compelete",
    component: (
      <DefaultLayout>
        <DoctorContact />
      </DefaultLayout>
    ),
    isProtected: true,
  },
  {
    path: "/doctor-rejected",
    component: (
      <DefaultLayout>
        <DoctorRejected />
      </DefaultLayout>
    ),
    isProtected: true,
  },
  {
    path: "/webinar",
    component: (
      <WebinarLayout fullWidth={true}>
        <Webinar />
      </WebinarLayout>
    ),
    isProtected: false,
  },
  {
    path: "/contact-form",
    component: (
      <DefaultLayout>
        <ContactForm />
      </DefaultLayout>
    ),
  },
  {
    path: "/tapering-and-antidepressants",
    component: (
      <DefaultLayout fullWidth={true}>
        <ArticleTaperingAntidepressants />
      </DefaultLayout>
    ),
  },

  {
    path: "/why-is-it-difficult-to-stop-or-taper-antidepressants",
    component: (
      <DefaultLayout fullWidth={true}>
        <ArticleDifficultToStopOrTaperAntidepressants />
      </DefaultLayout>
    ),
  },

  {
    path: "/general",
    component: (
      <GeneralLayout fullWidth={true}>
        <GeneralPage />
      </GeneralLayout>
    ),
  },
  {
    path: "/superadmin",
    component: (
      <SuperAdminLayout fullWidth={true}>
        <SuperAdmin />
      </SuperAdminLayout>
    ),
    exact: true,
    isProtected: true,
    role: "superadmin",
  },
  {
    path: "/superadmin/reports",
    component: (
      <SuperAdminLayout>
        <ReportsPage />
      </SuperAdminLayout>
    ),
  },
  {
    path: "/superadmin/:id",
    component: (
      <SuperAdminLayout fullWidth={true}>
        <SuperAdminPatientView />
      </SuperAdminLayout>
    ),
    exact: true,
    isProtected: true,
    role: "superadmin",
  },
  {
    path: "/staff-login",
    component: (
      <AdminLoginLayout fullWidth={true}>
        <StaffLogin />
      </AdminLoginLayout>
    ),
    exact: true,
    isProtected: false,
  },
  {
    path: "/doctor",
    component: (
      <SuperAdminLayout fullWidth={true}>
        <DoctorDashboard />
      </SuperAdminLayout>
    ),
    exact: true,
    isProtected: true,
    role: "doctor",
  },
  {
    path: "/doctor/edit-profile",
    component: (
      <SuperAdminLayout fullWidth={true}>
        <EditProfile />
      </SuperAdminLayout>
    ),
    exact: true,
    isProtected: true,
    role: "doctor",
  },
  {
    path: "/edit-profile/:id",
    component: (
      <SuperAdminLayout fullWidth={true}>
        <EditProfileByPersonel />
      </SuperAdminLayout>
    ),
    exact: false,
    isProtected: true,
    role: "superadmin",
  },
  {
    path: "/nurse/edit-profile",
    component: (
      <SuperAdminLayout fullWidth={true}>
        <EditProfile />
      </SuperAdminLayout>
    ),
    exact: true,
    isProtected: true,
    role: "nurse",
  },
  {
    path: "/doctor/:id",
    component: (
      <SuperAdminLayout fullWidth={true}>
        <DoctorPatientPage />
      </SuperAdminLayout>
    ),
    exact: true,
    isProtected: true,
    role: "doctor",
  },

  {
    path: "/nurse",
    component: (
      <SuperAdminLayout fullWidth={true}>
        <NursePage />
      </SuperAdminLayout>
    ),
    exact: true,
    isProtected: true,
    role: "nurse",
  },

  {
    path: "/nurse/patients/:id",
    component: (
      <SuperAdminLayout fullWidth={true}>
        <NursePatientPage />
      </SuperAdminLayout>
    ),
    exact: true,
    isProtected: true,
    role: "nurse",
  },
  {
    path: "/wait-list",
    component: (
      <DefaultLayout>
        <WaitList />
      </DefaultLayout>
    ),
    exact: true,
    isProtected: true,
  },
  {
    path: "/services",
    component: (
      <SuperAdminLayout fullWidth={true}>
        <ServicesPage />
      </SuperAdminLayout>
    ),
    exact: true,
    isProtected: true,
    role: "superadmin",
  },
  {
    path: "/orders",
    component: (
      <SuperAdminLayout fullWidth={true}>
        <OrdersPage />
      </SuperAdminLayout>
    ),
    exact: true,
    isProtected: true,
    role: "superadmin",
  },
];
