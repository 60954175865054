import {
  Flex,
  Heading,
  VStack,
  Text,
  Button,
  Center,
  useHighlight,
} from "@chakra-ui/react";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import LeaveCalendarSection from "features/staffCommons/components/LeaveCalendarSection";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCalendar } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import { useHistory } from "react-router-dom";

interface AdminHeaderProps {}

const AdminHeader: FunctionComponent<AdminHeaderProps> = () => {
  const { data: profileData } = useFetchProfileInfo();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"space-between"}
      pt={"3rem"}
      pb={"2rem"}
    >
      <VStack alignItems={"flex-start"} gap={"0"} spacing={0}>
        <Flex alignItems={"baseline"} gap={"1rem"}>
          <Heading
            fontSize={{ base: "28px", md: "30px", lg: "32px" }}
            fontWeight={600}
            letterSpacing={0.8}
          >
            {t("Welcome")}, {profileData?.first_name}
          </Heading>
          <Button
            opacity={"0.5"}
            variant={"transparent"}
            color={"blueish.900"}
            fontFamily={"secondary"}
            fontWeight={400}
            fontSize={"0.875rem"}
            rightIcon={<CiEdit />}
            onClick={() => {
              history.push("/nurse/edit-profile");
            }}
          >
            {t("Edit profile")}
          </Button>
        </Flex>

        <Text
          fontSize={"1rem"}
          color={"blueish.900"}
          opacity={0.5}
          textTransform={"capitalize"}
        >
          {new Date().toLocaleDateString(t("locale") ?? "en-GB", {
            weekday: "long",
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
        </Text>
      </VStack>
    </Flex>
  );
};

export default AdminHeader;
