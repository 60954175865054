import SvgIconConstituentValues from "./types/svgPropsType";

const FaqIcon = (props: SvgIconConstituentValues) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 0.5C8.82441 0.5 6.69767 1.14514 4.88873 2.35383C3.07979 3.56253 1.66989 5.28049 0.83733 7.29048C0.00476617 9.30047 -0.213071 11.5122 0.211367 13.646C0.635804 15.7798 1.68345 17.7398 3.22183 19.2782C4.76021 20.8166 6.72022 21.8642 8.85401 22.2886C10.9878 22.7131 13.1995 22.4952 15.2095 21.6627C17.2195 20.8301 18.9375 19.4202 20.1462 17.6113C21.3549 15.8023 22 13.6756 22 11.5C21.9966 8.58367 20.8365 5.78778 18.7744 3.72563C16.7122 1.66347 13.9163 0.50344 11 0.5ZM11 20.5C9.21997 20.5 7.47992 19.9722 5.99987 18.9832C4.51983 17.9943 3.36628 16.5887 2.68509 14.9442C2.0039 13.2996 1.82567 11.49 2.17294 9.74419C2.5202 7.99836 3.37737 6.39471 4.63604 5.13604C5.89472 3.87737 7.49836 3.0202 9.24419 2.67293C10.99 2.32567 12.7996 2.5039 14.4442 3.18508C16.0887 3.86627 17.4943 5.01983 18.4832 6.49987C19.4722 7.97991 20 9.71997 20 11.5C19.9971 13.8861 19.0479 16.1735 17.3608 17.8607C15.6736 19.5479 13.3861 20.4971 11 20.5ZM12 16V18H10V16H12ZM15 9C15.0011 9.59973 14.8668 10.192 14.607 10.7325C14.3473 11.2731 13.9689 11.7481 13.5 12.122C12.7426 12.7089 12.2304 13.5565 12.063 14.5H10.031C10.1194 13.729 10.3613 12.9836 10.7424 12.3076C11.1235 11.6316 11.6361 11.0387 12.25 10.564C12.4964 10.3668 12.6926 10.1142 12.8226 9.82668C12.9526 9.53918 13.0128 9.22499 12.9981 8.9098C12.9835 8.5946 12.8945 8.28734 12.7384 8.01312C12.5823 7.7389 12.3636 7.5055 12.1 7.332C11.8063 7.14 11.4683 7.02635 11.1182 7.00184C10.7681 6.97734 10.4176 7.0428 10.1 7.192C9.7612 7.35439 9.47687 7.61172 9.28159 7.93269C9.08631 8.25367 8.98848 8.62446 9 9C9 9.26522 8.89465 9.51957 8.70711 9.70711C8.51958 9.89464 8.26522 10 8 10C7.73479 10 7.48043 9.89464 7.2929 9.70711C7.10536 9.51957 7 9.26522 7 9C6.98513 8.23144 7.19719 7.47555 7.60966 6.82687C8.02213 6.17819 8.6167 5.66553 9.31901 5.353C9.94156 5.07478 10.6241 4.9578 11.3038 5.01285C11.9835 5.0679 12.6383 5.29321 13.208 5.668C13.7585 6.03239 14.2102 6.52736 14.5228 7.10874C14.8355 7.69013 14.9994 8.33987 15 9Z"
      fill="white"
    />
  </svg>
);
export default FaqIcon;
