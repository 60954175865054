import SvgIconConstituentValues from "./types/svgPropsType";

const RequestAcceptedIcon = (props: SvgIconConstituentValues) => (
  <svg
    width={44}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 0C17.6488 0 13.3953 1.29028 9.77746 3.70767C6.15958 6.12506 3.33979 9.56099 1.67466 13.581C0.00953233 17.6009 -0.426141 22.0244 0.422734 26.292C1.27161 30.5596 3.36691 34.4796 6.44366 37.5564C9.52042 40.6331 13.4404 42.7284 17.708 43.5773C21.9756 44.4261 26.3991 43.9905 30.419 42.3253C34.439 40.6602 37.875 37.8404 40.2923 34.2225C42.7097 30.6047 44 26.3512 44 22C43.9931 16.1673 41.6731 10.5756 37.5488 6.45125C33.4244 2.32694 27.8327 0.00688094 22 0ZM22 40C18.4399 40 14.9598 38.9443 11.9997 36.9665C9.03966 34.9886 6.73256 32.1774 5.37018 28.8883C4.0078 25.5992 3.65134 21.98 4.34587 18.4884C5.04041 14.9967 6.75474 11.7894 9.27209 9.27208C11.7894 6.75473 14.9967 5.0404 18.4884 4.34586C21.98 3.65133 25.5992 4.00779 28.8883 5.37017C32.1774 6.73255 34.9886 9.03965 36.9665 11.9997C38.9443 14.9598 40 18.4399 40 22C39.9942 26.7721 38.0959 31.3471 34.7215 34.7215C31.3471 38.0959 26.7721 39.9942 22 40ZM33.474 15.648C33.6516 15.8416 33.7893 16.0682 33.8792 16.315C33.9692 16.5618 34.0097 16.8238 33.9984 17.0863C33.987 17.3487 33.9241 17.6063 33.8132 17.8444C33.7023 18.0825 33.5456 18.2965 33.352 18.474L21.352 29.474C20.9721 29.8224 20.4723 30.0106 19.957 29.9994C19.4416 29.9882 18.9505 29.7785 18.586 29.414L12.586 23.414C12.211 23.0387 12.0004 22.5298 12.0006 21.9993C12.0008 21.4688 12.2117 20.96 12.587 20.585C12.9623 20.21 13.4712 19.9994 14.0017 19.9996C14.5323 19.9998 15.041 20.2107 15.416 20.586L20.062 25.232L30.65 15.526C31.0408 15.168 31.5578 14.9798 32.0873 15.0027C32.6168 15.0255 33.1155 15.2576 33.474 15.648Z"
      fill="#2C8475"
    />
  </svg>
);
export default RequestAcceptedIcon;
