import {
  Flex,
  Heading,
  Box,
  Text,
  Link,
  Image,
  Hide,
  Show,
  Button,
} from "@chakra-ui/react";

import { t } from "i18next";
import { FunctionComponent } from "react";

import image1 from "assets/images/article1_1.png";
import HomeFooter from "features/homeSections/components/homeFooter";
import FooterMobile from "features/homeSections/components/homeFooterMobile";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";

interface ArticleTaperingAntidepressantsProps {}

const ArticleTaperingAntidepressants: FunctionComponent<
  ArticleTaperingAntidepressantsProps
> = () => {
  const { basicEvent } = useGoogleAnalytics();
  return (
    <>
      <Box
        mt={"2rem"}
        mb={"5vh"}
        w={"100%"}
        bg="rgba(255, 255, 255, 1)"
        borderRadius="24px"
        overflow={"hidden"}
      >
        <Flex w="full" justify="flex-end" py="2.5rem" px="4rem">
          <Button
            py="1rem"
            px="2rem"
            variant="filledBlue"
            as="a"
            fontSize={{ base: "16px", md: "18px" }}
            href="https://calendly.com/lamina-clinic/gratis-15-min-konsultation"
            target="_blank"
            onClick={() => basicEvent("Contact", "User clicked Get help today")}
          >
            Få hjälp idag
          </Button>
        </Flex>
        <Flex
          w="100%"
          p="60px"
          align="flex-start"
          direction="column"
          gap="10px"
          pt={"1rem"}
          fontSize={"1rem"}
        >
          <Flex w="100%" align="center" justify="center" mb="30px">
            <Heading fontSize="4xl" color="blueish.500">
              Rubrik: Individanpassad nedtrappning av antidepressiva läkemedel
            </Heading>
          </Flex>

          <Heading fontSize={"1.25em"}>
            Över en miljon svenskar behandlas idag med antidepressiva läkemedel.
            Fler än hälften av dem har använt medicinen i över nio år – trots
            att biverkningar som minskad sexlust, viktuppgång och emotionell
            avstängdhet är vanliga. Ett skäl till detta är de svåra
            utsättningssymtom som kan uppstå vid för snabb nedtrappning. Mikael
            Bäckström, grundare av Lamina Clinic, vill göra processen enklare
            för dem som vill sluta eller trappa ner med antidepressiva.
          </Heading>

          <Text color={"blueish.900"} fontSize={"1.25em"}>
            Idag använder över en miljon svenskar antidepressiva läkemedel.{" "}
            <Link
              color={"blueish.500"}
              href={"https://www.svd.se/a/M351EB/massmedicineringen"}
            >
              Statistik visar att 600 000 av dem har använt medicinen i över nio
              år
            </Link>
            , trots att långvarig användning kan leda till biverkningar som
            minskad sexlust, viktökning och känsla av emotionell avstängdhet.
            Det är ofta de svåra utsättningssymtomen som gör att många väljer
            att fortsätta medicinera. Dessvärre är uppföljning och stöd från
            vården vid utsättning ofta bristfällig och vi ser gång på gång att
            vården misstolkar utsättningssymptom som medicinen skapar till att
            patienterna har återfallit i sjukdom.
          </Text>

          <Text fontSize={"1.25em"} color={"blueish.900"}>
            ”Jag har träffat läkare som anser att man både kan trappa in och ut
            på 14 dagar. De menar alltså att kroppen vänjer sig vid att börja
            eller sluta med antidepressiva inom två veckor. Men processen är
            ofta mycket mer komplex än så”, säger Mikael.
          </Text>

          <Text fontSize={"1.25em"} color={"blueish.900"}>
            Forskning och stora patientgrupper vittnar om att nedtrappning av
            antidepressiva läkemedel kan vara en komplicerad process. Studier
            visar att över hälften av de som försöker sluta ta medicinen
            upplever utsättningssymtom. De vanligaste symptomen är fysiska
            symtom som skakningar, diarré, "brain zaps" (en elektrisk känsla i
            hjärnan), svindel och synrubbningar. De psykologiska symptomen kan
            vara problematiska eftersom de ofta innefattar nedstämdhet, ångest
            och självmordstankar – samma symptom som kännetecknar depression.
          </Text>

          <Text fontSize={"1.25em"} color={"blueish.900"}>
            En viktig faktor att beakta vid nedtrappning av antidepressiva
            läkemedel är relationen mellan dos och påverkan på hjärnans
            receptorer. Redan vid en ganska låg dos medicin får man en betydande
            effekt på hjärnan som inte ökar proportionerligt med högre dos, det
            vill säga: en dubbelt så hög dos ger inte en dubbelt så stark
            effekt. Detta innebär att nedtrappningen kan vara relativt enkel i
            början, men när dosen blir mycket låg kan skillnaden mellan olika
            doser vara betydande. Många upplever därför att de först några
            månader in i uttrappningen får utsättningssymtom. Eftersom dessa
            symtom ofta liknar depressionssymtom blir det vanligt att de
            rekommenderas att börja medicinera igen.
          </Text>

          <Text fontSize={"1.25em"} color={"blueish.900"}>
            För att hjälpa människor att gradvis minska eller sluta helt med
            antidepressiva läkemedel erbjuder Lamina Clinic en individanpassad
            nedtrappning. Varje person som registrerar ett konto hos Lamina
            Clinic får börja med att svara på ett antal frågor om nuvarande
            medicinering, hälsotillstånd och livssituation. Kort därefter blir
            man kontaktad av en av Laminas specialistläkare, som lägger upp en
            individuell nedtrappningsplan. Den antidepressiva medicinen
            levereras hem i specialanpassade doser för att nedtrappningen ska
            göras i så små steg som möjligt. Under hela processen finns
            vårdpersonal tillgänglig digitalt för stöd och rådgivning.{" "}
          </Text>

          <Text fontSize={"1.25em"} color={"blueish.900"}>
            Med hjälp av Lamina Clinic kan varje individs specifika behov
            tillgodoses för att minimera utsättningssymtom och underlätta
            övergången till ett liv utan eller med lägre dos antidepressiva
            läkemedel.{" "}
          </Text>

          <Text fontSize={"1.25em"} color={"blueish.900"}>
            Läs mer om hur{" "}
            <Link color={"blueish.500"} href={"https://www.laminaclinic.com/"}>
              Lamina Clinic fungerar här.
            </Link>
          </Text>
        </Flex>
        <Flex
          w="full"
          align={{ base: "flex-start", sm: "center" }}
          justify="space-between"
          pb="2rem"
          px="4rem"
          flexDirection={{ base: "column", md: "row" }}
          gap={{ base: "1rem", sm: "0px" }}
        >
          <Heading fontSize={{ base: "24px", md: "2xl" }} color="blueish.500">
            Vill du veta mer? Svara på några enkla frågor och boka en
            kostnadsfri konsultation, så berättar vi mer.
          </Heading>
          <Button
            py="1rem"
            px="2rem"
            variant="filledBlue"
            as="a"
            fontSize={{ base: "16px", md: "18px" }}
            href="https://calendly.com/lamina-clinic/gratis-15-min-konsultation"
            target="_blank"
            onClick={() =>
              basicEvent("Contact", "User clicked Book a consultation")
            }
          >
            Boka konsultation
          </Button>
        </Flex>
        <Image src={image1} />
      </Box>
      <Box minW={"100%"}>
        <Show above="sm">
          <HomeFooter />
        </Show>
        <Hide above="sm">
          <FooterMobile />
        </Hide>
      </Box>
    </>
  );
};

export default ArticleTaperingAntidepressants;
