import SvgIconConstituentValues from "./types/svgPropsType";

const UseAppIcon = (props: SvgIconConstituentValues) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476617 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3166 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C21.9966 8.08367 20.8365 5.28778 18.7744 3.22563C16.7122 1.16347 13.9163 0.00344047 11 0ZM11 20C9.21997 20 7.47992 19.4722 5.99987 18.4832C4.51983 17.4943 3.36628 16.0887 2.68509 14.4442C2.0039 12.7996 1.82567 10.99 2.17294 9.24419C2.5202 7.49836 3.37737 5.89471 4.63604 4.63604C5.89472 3.37737 7.49836 2.5202 9.24419 2.17293C10.99 1.82567 12.7996 2.0039 14.4442 2.68508C16.0887 3.36627 17.4943 4.51983 18.4832 5.99987C19.4722 7.47991 20 9.21997 20 11C19.9971 13.3861 19.0479 15.6735 17.3608 17.3607C15.6736 19.0479 13.3861 19.9971 11 20ZM16.737 7.824C16.8258 7.92078 16.8946 8.0341 16.9396 8.15749C16.9846 8.28088 17.0048 8.41192 16.9992 8.54313C16.9935 8.67435 16.9621 8.80316 16.9066 8.92221C16.8512 9.04127 16.7728 9.14823 16.676 9.237L10.676 14.737C10.486 14.9112 10.2362 15.0053 9.97848 14.9997C9.7208 14.9941 9.47524 14.8893 9.293 14.707L6.293 11.707C6.1055 11.5194 6.00021 11.2649 6.0003 10.9996C6.0004 10.7344 6.10586 10.48 6.2935 10.2925C6.48115 10.105 6.73559 9.9997 7.00086 9.9998C7.26613 9.99989 7.5205 10.1054 7.708 10.293L10.031 12.616L15.325 7.763C15.5204 7.584 15.7789 7.48989 16.0436 7.50133C16.3084 7.51276 16.5578 7.62882 16.737 7.824Z"
      fill="#2C8475"
    />
  </svg>
);

export default UseAppIcon;
