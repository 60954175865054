import {
  Box,
  Divider,
  Flex,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

import { pricingData, tableColors } from "features/faqInfo/utils";
import { useTranslation } from "react-i18next";

const DiscoverTableMobile = () => {
  const { t } = useTranslation();
  return (
    <Box
      w="full"
      border="1px solid rgba(88, 154, 175, 1)"
      borderRadius=".5rem"
      position="relative"
    >
      <UnorderedList
        listStyleType="none"
        m="0"
        bg="rgba(88, 154, 175, 0.1)"
        padding="0"
      >
        {pricingData.map((item, index) => (
          <ListItem key={item.month}>
            <Stack spacing={2}>
              <Text
                color="rgba(255, 255, 255, 1)"
                textAlign="center"
                bg={tableColors[index % tableColors.length]}
                fontSize="1rem"
                fontWeight={700}
                py=".5rem"
              >
                {t(item.month)}
              </Text>
              {item.details.map((datail, detailIndex) => (
                <>
                  <Flex
                    align="center"
                    justify="space-between"
                    px="12px"
                    key={detailIndex}
                  >
                    <Text
                      color="rgba(0, 38, 55, 1)"
                      fontSize=".875rem"
                      fontWeight={400}
                    >
                      {t(datail.activity)}
                    </Text>
                    <Text
                      color="rgba(0, 38, 55, 1)"
                      fontSize=".875rem"
                      fontWeight={700}
                    >
                      {t(datail.cost)}
                    </Text>
                  </Flex>
                  <Divider colorScheme="rgba(88, 154, 175, 0.2)" />
                </>
              ))}
              <Flex align="center" justify="space-between" px="12px">
                <Text
                  color="rgba(0, 38, 55, 1)"
                  fontWeight={400}
                  fontSize=".875rem"
                >
                  {t("Total")}:
                </Text>
                <Box>
                  <Text
                    color="rgba(88, 154, 175, 1)"
                    fontSize="1.125rem"
                    textAlign="end"
                    fontWeight={700}
                  >
                    {item.total}
                  </Text>
                  <Text
                    color="rgba(0, 38, 55, 1)"
                    fontWeight={400}
                    fontSize=".875rem"
                    pb=".625rem"
                  >
                    {t("plus medication")}
                  </Text>
                </Box>
              </Flex>
            </Stack>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};

export default DiscoverTableMobile;
