import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Box,
  RangeSliderMark,
  VStack,
  Flex,
  Text,
} from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface UserFilterSliderProps {
  taperingPlanDurationFrom?: number;
  taperingPlanDurationTo?: number;
  setTaperingPlanDurationFrom(from: number): void;
  setTaperingPlanDurationTo(to: number): void;
}

const UserFilterSlider: FunctionComponent<UserFilterSliderProps> = ({
  taperingPlanDurationFrom,
  taperingPlanDurationTo,
  setTaperingPlanDurationFrom,
  setTaperingPlanDurationTo,
}) => {
  const max = 56;
  const min = 12;
  const { t } = useTranslation();

  const [values, setValues] = useState<number[]>([
    taperingPlanDurationFrom ?? 16,
    taperingPlanDurationTo ?? 32,
  ]);

  //ISSUEPRONE
  useEffect(() => {
    setValues([taperingPlanDurationFrom ?? 16, taperingPlanDurationTo ?? 32]);
  }, [taperingPlanDurationFrom, taperingPlanDurationTo]);

  return (
    <VStack gap={0} spacing={0}>
      <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
        <Text color={"white"} opacity={0.5} fontSize={"14px"}>
          {min} {t("weeks")}(min)
        </Text>
        <Text color={"white"} opacity={0.5} fontSize={"14px"}>
          {max} {t("weeks")}(max)
        </Text>
      </Flex>
      <RangeSlider
        transition={"all 0.3s ease-out"}
        opacity={!taperingPlanDurationFrom && !taperingPlanDurationTo ? 0.5 : 1}
        defaultValue={[12, 48]}
        min={12}
        max={56}
        step={4}
        value={values}
        onClick={() => {
          setTaperingPlanDurationFrom(values[0]);
          setTaperingPlanDurationTo(values[1]);
        }}
        onChange={(values) => {
          setValues(values);
          setTaperingPlanDurationFrom(values[0]);
          setTaperingPlanDurationTo(values[1]);
        }}
        width={"100%"}
        minStepsBetweenThumbs={1}
      >
        <RangeSliderTrack
          bg="rgba(255, 255, 255, 0.1)"
          h={"12px"}
          borderRadius={"50px"}
        >
          <RangeSliderFilledTrack bg="#EEE8A9" />
        </RangeSliderTrack>

        {new Array((values[0] - min) / 4).fill(0).map((e, index) => {
          return (
            <RangeSliderMark
              value={min + index * 4}
              h={"0px"}
              w={"6px"}
              verticalAlign={"center"}
              display={"flex"}
              alignItems={"center"}
              overflow={"visible !important"}
            >
              {index > 0 && (
                <Box
                  bg={"rgba(255, 255, 255, 0.4)"}
                  h={"6px"}
                  w={"6px"}
                  borderRadius={"50%"}
                ></Box>
              )}
            </RangeSliderMark>
          );
        })}

        {new Array((values[1] - values[0]) / 4).fill(0).map((e, index) => {
          return (
            <RangeSliderMark
              value={values[0] + index * 4}
              borderRight={"2px solid #589AAF"}
              w={"1px"}
              top={"8px"}
              h={"12px"}
              verticalAlign={"center"}
            ></RangeSliderMark>
          );
        })}

        {new Array((max - values[1]) / 4).fill(0).map((e, index) => {
          return (
            <RangeSliderMark
              value={values[1] + index * 4}
              h={"0px"}
              w={"6px"}
              verticalAlign={"center"}
              display={"flex"}
              alignItems={"center"}
              overflow={"visible !important"}
            >
              {index > 0 && (
                <Box
                  bg={"rgba(255, 255, 255, 0.4)"}
                  h={"6px"}
                  w={"6px"}
                  borderRadius={"50%"}
                ></Box>
              )}
            </RangeSliderMark>
          );
        })}

        <RangeSliderMark
          value={values[0]}
          textAlign="center"
          bg="rgba(255, 255, 255, 0.1)"
          borderRadius={"0.5rem"}
          color="white"
          fontWeight={700}
          w={"5rem"}
          fontSize={"14px"}
          mt="1.5rem"
          ml="-2.5rem"
          px={"0.5rem"}
          py={"0.25rem"}
        >
          Från: {values[0]}
        </RangeSliderMark>

        <RangeSliderMark
          value={values[1]}
          textAlign="center"
          bg="rgba(255, 255, 255, 0.1)"
          borderRadius={"0.5rem"}
          color="white"
          fontWeight={700}
          w={"5rem"}
          fontSize={"14px"}
          mt="1.5rem"
          ml="-2.5rem"
          px={"0.5rem"}
          py={"0.25rem"}
        >
          Till: {values[1]}
        </RangeSliderMark>

        <RangeSliderThumb
          boxSize={6}
          index={0}
          minH={"1.75rem"}
          minW={"1.75rem"}
          border={"6px solid #9BC2CF !important"}
        />
        <RangeSliderThumb
          boxSize={6}
          index={1}
          minH={"1.75rem"}
          minW={"1.75rem"}
          border={"6px solid #9BC2CF !important"}
        />
      </RangeSlider>
    </VStack>
  );
};

export default UserFilterSlider;
