import {
  Flex,
  Heading,
  Box,
  Text,
  Link,
  Image,
  Hide,
  Show,
  Button,
} from "@chakra-ui/react";

import { t } from "i18next";
import { FunctionComponent } from "react";

import marketing_jpg from "assets/images/marketing_jpg.jpg";

import HomeFooter from "features/homeSections/components/homeFooter";
import FooterMobile from "features/homeSections/components/homeFooterMobile";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";

const ArticleDifficultToStopOrTaperAntidepressants: FunctionComponent = () => {
  const { basicEvent } = useGoogleAnalytics();
  return (
    <>
      <Box
        mt={"2rem"}
        mb={"5vh"}
        w={"100%"}
        bg="rgba(255, 255, 255, 1)"
        borderRadius="24px"
        overflow={"hidden"}
      >
        <Flex w="full" justify="flex-end" py="2.5rem" px="4rem">
          <Button
            py="1rem"
            px="2rem"
            variant="filledBlue"
            as="a"
            fontSize={{ base: "16px", md: "18px" }}
            href="https://calendly.com/lamina-clinic/gratis-15-min-konsultation"
            onClick={() => basicEvent("Contact", "User clicked Get help today")}
            target="_blank"
          >
            Få hjälp idag
          </Button>
        </Flex>
        <Flex
          w="100%"
          p="60px"
          align="flex-start"
          direction="column"
          gap="10px"
          pt={"1rem"}
          fontSize={"1rem"}
        >
          <Flex w="100%" align="center" justify="center" mb="30px">
            <Heading fontSize="4xl" color="blueish.500">
              Varför det är svårt att sluta med antidepressiva
            </Heading>
          </Flex>

          <Heading>
            Antidepressiva läkemedel hjälper många att hantera ångest och
            nedstämdhet. Men biverkningarna som kan uppstå vid nedtrappning av
            medicinen gör att många fortsätter att ta den även när de egentligen
            inte behöver. Lamina Clinic är en ny vårdtjänst som möjliggör mindre
            onödig långvarig användning av antidepressiva läkemedel, och som
            hjälper patienter trappa ner gradvis enligt den hyperbola metoden.
          </Heading>

          <Text color={"blueish.900"} fontSize={"1.25em"}>
            Idag beräknas över en miljon svenskar ta någon form av antidepressiv
            medicin.
            <Link
              color={"blueish.500"}
              href={"https://www.svd.se/a/M351EB/massmedicineringen"}
              target="_blank"
            >
              En sammanställning i Svenska Dagbladet visar att över 600 000
              personer har medicinerat i över nio års tid.
            </Link>
            En hel del människor har upplevt att antidepressiva hjälpt dem, men
            många av patienterna har tagit medicin år ut och år in, trots att
            rekommendationen normalt sett är att äta den bara mellan 6 månader
            och 2 år.
          </Text>

          <Text fontSize={"1.25em"} color={"blueish.900"}>
            Ofta har patienten försökt sluta, men har börjat igen eftersom deras
            depressionssymptom återkommit. Det kan stämma i vissa fall, men
            depressionssymptom kan lätt förväxlas med vad som kallar
            utsättningssymptom - alltså vad som händer fysiskt kroppen när man
            slutar ta medicinerna. Symptomen kan ta sig i uttryck både fysiskt
            och psykiskt och vara mycket påfrestande, rent av omöjliga att ta
            sig igenom.
          </Text>

          <Text fontSize={"1.25em"} color={"blueish.900"}>
            Föreställningarna om hur depression, och preparat som ska verka mot
            depression, fungerar har ändrats över åren. Det är fortfarande många
            läkare som går efter devisen att både in- och utsättning av
            antidepressiva kan göras under två veckors tid.
            <Link
              color={"blueish.500"}
              href={"https://pubmed.ncbi.nlm.nih.gov/30292574/"}
              target="_blank"
            >
              Men nyare studier visar att processen är mer komplex än så.
            </Link>
          </Text>

          <Text fontSize={"1.25em"} color={"blueish.900"}>
            Man kan till exempel lätt tro att relationen mellan medicindosen och
            effekten är linjär: det vill säga att dubbel dos ger dubbel effekt.
            Det stämmer inte. Skillnaden mellan två doser är större i de lägre
            doserna, och minskar sedan ju högre dosen blir. Därför behöver man
            minska dosen i olika stora steg, och minska väldigt lite i taget i
            slutet av en nedtrappning för att undvika de tuffa
            utsättningssymptomen. Det för med sig nedtrappning av antidepressiva
            läkemedel kan kännas lätt i början. Svåra utsättningssymptom kan
            uppstå först efter några månader när man redan trappat ned till en
            låg dos och tror man snart är klar med utsättningen.
          </Text>

          <Text fontSize={"1.25em"} color={"blueish.900"}>
            Antidepressiva preparat tillverkas dessutom bara i ett par stycken
            fasta, relativt höga doser. Men en optimal nedtrappning kräver
            doseringar i mycket mindre steg, något som är svårt att göra med de
            doser som finns på marknaden. Det ökar risken för att patienterna
            trappar ned för fort och får svåra utsättningssymptom.
          </Text>

          <Text fontSize={"1.25em"} color={"blueish.900"}>
            Många patienter försöker komma runt dosproblematiken på egen hand
            genom att dela, krossa eller lösa upp tabletterna. Det är ingen
            pålitlig metod eftersom antidepressiva oftast inte är delbara, då de
            aktiva substanserna fördelas ojämnt i tabletterna och ofta har
            speciella beläggningar för att tas upp på rätt sätt i kroppen.
            Därför erbjuder Lamina Clinic ett nedtrappningsprogram med
            förskrivning av mycket mindre, specialtillverkade doser anpassade
            efter patienten. Det gör att medicinen minskas successivt i små
            steg, något som minskar risken för svåra utsättningar avsevärt.
          </Text>

          <Text fontSize={"1.25em"} color={"blueish.900"}>
            För att komma igång hos Lamina Clinic fyller patienten i information
            om sin nuvarande medicinering och hälsotillstånd och får därefter
            prata med en nedtrappningsexpert för att diskutera en skräddarsydd
            nedtrappningsplan. Planen sträcker sig ofta över två till sex
            månader, ibland längre, beroende på läkemedel och patientens
            historia. Under hela processen finns Laminas Clinics vårdteam
            tillgängliga för stöd och uppföljning.
          </Text>

          <Text fontSize={"1.25em"} color={"blueish.900"}>
            Lamina Clinic är ett privat vårdinitiativ som fungerar som ett
            komplement till primärvården och är till för den som vill sluta med
            eller trappa ner på sina antidepressiva på ett tryggt och mer
            skonsamt sätt.
          </Text>
        </Flex>
        <Flex
          w="full"
          align={{ base: "flex-start", sm: "center" }}
          justify="space-between"
          pb="2rem"
          px="4rem"
          flexDirection={{ base: "column", md: "row" }}
          gap={{ base: "1rem", sm: "0px" }}
        >
          <Heading fontSize={{ base: "24px", md: "2xl" }} color="blueish.500">
            Vill du veta mer? Svara på några korta frågor och boka en
            kostnadsfri konsultation, så berättar vi mer.
          </Heading>
          <Button
            py="1rem"
            px="2rem"
            variant="filledBlue"
            as="a"
            fontSize={{ base: "16px", md: "18px" }}
            href="https://calendly.com/lamina-clinic/gratis-15-min-konsultation"
            onClick={() =>
              basicEvent("Contact", "User clicked Book a consultation")
            }
            target="_blank"
          >
            Boka konsultation
          </Button>
        </Flex>
        <Image src={marketing_jpg} w="full" />
      </Box>

      <Box minW={"100%"}>
        <Show above="sm">
          <HomeFooter />
        </Show>
        <Hide above="sm">
          <FooterMobile />
        </Hide>
      </Box>
    </>
  );
};

export default ArticleDifficultToStopOrTaperAntidepressants;
