import GeneralContext from "features/general/components/generalContext/GeneralContext";
import GeneralHero from "features/general/components/generalHero/GeneralHero";

const GeneralPage = () => {
  return (
    <>
      <GeneralHero />
      <GeneralContext />
    </>
  );
};

export default GeneralPage;
