import { APIClient } from "services/api/client";
import useSWRMutation from "swr/mutation";
import {
  SaveRequestPrescription,
  TreatmentPlan,
  UserTreatmentPlan,
} from "./type";
import useSWR, { useSWRConfig, mutate } from "swr";
import { useFetchProfileInfo } from "features/dashboard/api/client";

export const useSendTypeformData = () =>
  useSWRMutation(
    "save-user-prescription",
    (url: string, { arg }: { arg: SaveRequestPrescription }) =>
      APIClient.get(
        `${url}?form_id=${arg.form_id}&response_id=${arg.response_id}`
      )
  );

export const useSendUserTreatmentPlan = () => {
  return useSWRMutation<any, UserTreatmentPlan>(
    "treatment-durations-save",
    (url: string, { arg }: { arg: any }) => {
      return APIClient.post(url, arg);
    },
    {
      onSuccess: () => {
        mutate("profile/");
      },
      onError: () => {
        mutate("profile/");
      },
    }
  );
};

export const useGetTreatmentPlan = () => {
  const { mutate: localMutate } = useFetchProfileInfo();
  return useSWR<TreatmentPlan>(
    "treatment-durations",
    (url) => APIClient.get(url),
    {
      onSuccess: () => {
        localMutate();
      },
      onError: () => {
        localMutate();
      },
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
};
