import { APIClient } from "services/api/client";
import useSWRMutation from "swr/mutation";
import { FileUploadType } from "./types";

export const useUploadFile = () => {
  return useSWRMutation<any, FileUploadType>(
    "media/",
    (url: string, { arg }: any) => {
      return APIClient.post(url, arg);
    }
  );
};
