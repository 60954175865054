import { Flex, VStack, Text, Tag } from "@chakra-ui/react";
import CalendarMessageGreen from "ui/iconComponents/CalendarMessageGreen";

interface CalenderMoodNoteProps {
  tolerableMoodComments: string[];
  intolerableMoodComments: string[];
}

const CalenderMoodNote: React.FC<CalenderMoodNoteProps> = ({
  tolerableMoodComments,
  intolerableMoodComments,
}) => {
  return (
    <VStack>
      {tolerableMoodComments.length > 0 && (
        <>
          <Flex w="full" align="center" justify="space-between">
            <Text
              color="rgba(88, 154, 175, 1)"
              fontSize="16px"
              fontWeight={700}
            >
              User can tolerate this:
            </Text>
            <CalendarMessageGreen />
          </Flex>

          {tolerableMoodComments?.length > 0 && (
            <Flex
              w="full"
              align="flex-start"
              position="relative"
              zIndex={tolerableMoodComments.length}
              direction="column"
              gap=".5rem"
            >
              {tolerableMoodComments.map((comment, index) => (
                <Tag
                  key={index}
                  bg="rgba(238, 232, 169, 0.4)"
                  py="5px"
                  fontSize="14px"
                  fontWeight={400}
                >
                  {comment}
                </Tag>
              ))}
            </Flex>
          )}
        </>
      )}

      {intolerableMoodComments.length > 0 && (
        <>
          <Flex w="full" align="center" justify="space-between">
            <Text
              color="rgba(213, 115, 138, 1)"
              fontSize="16px"
              fontWeight={700}
            >
              User can’t tolerate this:
            </Text>
          </Flex>

          {intolerableMoodComments.length > 0 && (
            <Flex
              w="full"
              align="flex-start"
              position="relative"
              zIndex={intolerableMoodComments.length}
              direction="column"
              gap=".5rem"
            >
              {intolerableMoodComments.map((comment, index) => (
                <Tag
                  key={index}
                  bg="rgba(238, 232, 169, 0.4)"
                  py="5px"
                  fontSize="14px"
                  fontWeight={400}
                >
                  {comment}
                </Tag>
              ))}
            </Flex>
          )}
        </>
      )}
    </VStack>
  );
};

export default CalenderMoodNote;
