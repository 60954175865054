import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";

import { useFormik } from "formik";

import {
  useSignUpPrescription,
  useUserFirstLogin,
} from "features/adminLogin/api/client";
import PasswordInput from "ui/components/PasswordInput";
import { SignUpFormStepSchema } from "features/adminLogin/api/validation";
import { useEffect } from "react";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import { options } from "features/admin/helper/columns";
import { useHistory } from "react-router-dom";
import { useAdminStore } from "services/state/adminStore";
import { useTranslation } from "react-i18next";

const SignUpFormStep = () => {
  const { t } = useTranslation();

  const { trigger } = useSignUpPrescription();
  const { data: initialLoginData } = useFetchProfileInfo();
  const history = useHistory();
  const { isAdmin, setAdmin } = useAdminStore();

  const formik = useFormik({
    initialValues: {
      username:
        initialLoginData?.first_name + " " + initialLoginData?.last_name,
      pharmacy_code: "",
    },
    onSubmit: (values) => {
      try {
        const response = trigger(
          {
            pharmacy_code: values.pharmacy_code,
          },
          {
            onSuccess: () => {
              setAdmin(true);
            },
          }
        );
        console.log("Success!", response);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    validationSchema: SignUpFormStepSchema,
  });

  useEffect(() => {
    formik.setFieldValue(
      "username",
      initialLoginData?.first_name + " " + initialLoginData?.last_name
    );
  }, [initialLoginData]);

  useEffect(() => {
    if (isAdmin) {
      history.push("/admin/users/");
    }
  }, [isAdmin]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl isInvalid={!!formik.errors.username} mt="-2.5rem">
        <Flex
          w="full"
          align="flex-start"
          py={{ base: "2rem", sm: "", md: "", lg: "3rem" }}
          pb={{ base: "2.5rem", sm: "", md: "2.5rem", lg: "2.5rem" }}
          direction="column"
          gap=".5rem"
        >
          <Heading
            fontSize="14px"
            fontWeight={600}
            fontFamily="secondary"
            color="rgba(0, 38, 55, 1)"
            letterSpacing={0.7}
            textTransform="uppercase"
          >
            {t("your role")}
          </Heading>
          <Box
            py=".5rem"
            px="1rem"
            bg="blueish.500"
            color="#FFF"
            borderRadius=".5rem"
            fontWeight={700}
            letterSpacing={1}
            textTransform={"capitalize"}
          >
            {initialLoginData?.role}
          </Box>
        </Flex>
        <FormLabel
          fontWeight={600}
          fontSize={{ base: "12px", lg: "14px" }}
          color="rgba(0, 38, 55, 1)"
          fontFamily="secondary"
          letterSpacing={0.7}
        >
          {t("NAME")}
        </FormLabel>
        <Input
          id="username"
          name="username"
          placeholder="Enter your name"
          _placeholder={{
            color: "rgba(0, 38, 55, 0.5)",
            fontSize: "16px",
          }}
          onChange={formik.handleChange}
          value={formik.values.username}
          minH={{ base: "3rem", lg: "3.5rem !important" }}
          readOnly={true}
        />
        <FormErrorMessage
          color="red"
          fontSize="14px"
          h={"0 !important"}
          pt={"0.5rem"}
        >
          {formik.errors.username}
        </FormErrorMessage>
      </FormControl>

      {initialLoginData?.role !== "nurse" && (
        <FormControl
          isInvalid={!!formik.errors.pharmacy_code}
          mt={{ base: "40px", sm: "20px", md: "20px", lg: "36px" }}
          h="75px"
        >
          <FormLabel
            fontWeight={600}
            fontSize={{ base: "12px", lg: "14px" }}
            color="rgba(0, 38, 55, 1)"
            fontFamily="secondary"
            letterSpacing={0.7}
          >
            {t("PRESCRIBE CODE")}
          </FormLabel>

          <PasswordInput
            id="pharmacy_code"
            name="pharmacy_code"
            placeholder="Enter your 7-digit prescription code"
            onChange={formik.handleChange}
            value={formik.values.pharmacy_code}
          />

          <FormErrorMessage color="red" fontSize="14px">
            {formik.errors.pharmacy_code}
          </FormErrorMessage>
        </FormControl>
      )}

      <Center py="3rem">
        <Button
          w="full"
          type="submit"
          bg="rgba(88, 154, 175, 1)"
          borderColor="white"
          fontFamily="secondary"
          fontWeight={700}
          fontSize="16px"
          letterSpacing={1}
          color={"white"}
          _hover={{
            bg: "rgba(88, 154, 175, 1)",
          }}
          maxW="235px"
          onClick={() => {
            formik.submitForm();
            console.log("submited");
          }}
        >
          {t("Finish")}
        </Button>
      </Center>
    </form>
  );
};

export default SignUpFormStep;
