import { Flex, HStack, Image, Text } from "@chakra-ui/react";

import logo from "assets/icons/blue-logo.svg";
import taper from "assets/icons/taper_black.svg";
import faq from "assets/icons/faq_black.svg";
import home from "assets/icons/home_icon.svg";
import { t } from "i18next";
import { Link, useHistory } from "react-router-dom";

const WebinarNavbar = () => {
  const history = useHistory();
  return (
    <Flex
      zIndex={1000}
      alignItems={"center"}
      justifyContent={"space-between"}
      w="full"
      px={{ base: "0", sm: "160px", lg: "260px" }}
    >
      <Image
        src={logo}
        alt="Lamina Clinic logo"
        w={{
          base: "160px",
          sm: "162px",
          md: "192px",
          lg: "256px",
        }}
        cursor={"pointer"}
        onClick={() => history.push("/")}
      />

      <HStack gap={{ base: "0em", sm: "62px" }}>
        <Flex align="center" gap="11px" cursor="pointer">
          <Image src={home} alt={"taper"} mb="3px" />
          <Link to="/">
            <Text
              color="black"
              fontSize="18px"
              fontWeight={700}
              _hover={{ color: "rgba(88, 154, 175, 1)" }}
            >
              Startsida
            </Text>
          </Link>
        </Flex>
        {/* <Flex align="center" gap="11px" cursor="pointer">
          <Image src={taper} alt={"taper"} mb="3px" />
          <Link to="/taper">
            <Text
              color="black"
              fontSize="18px"
              fontWeight={700}
              _hover={{ color: "rgba(88, 154, 175, 1)" }}
            >
              {t("How the Taper Works")}
            </Text>
          </Link>
        </Flex> */}
        <Flex align="center" gap="9px" cursor={"pointer !important"} zIndex={1}>
          <Image src={faq} mb="5px" />
          <Link to="/faq">
            <Text
              fontSize="18px"
              fontWeight={700}
              cursor="pointer"
              color="black"
              _hover={{ color: "rgba(88, 154, 175, 1)" }}
            >
              Vanliga frågor
            </Text>
          </Link>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default WebinarNavbar;
