import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { useAdminUserInfo } from "features/admin/api/client";
import { FunctionComponent, useEffect } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useTranslation } from "react-i18next";
import { useSWRConfig } from "swr";

interface CalendlyPopupProps {
  isOpen: boolean;
  onClose(): void;
  link?: string;
  patientId?: string;
  meetingInfo?: {
    type_name?: string;
    patientId?: string;
  };
  callbacks?: {
    onSuccess?(): void;
  };
}

const CalendlyPopup: FunctionComponent<CalendlyPopupProps> = ({
  isOpen,
  onClose,
  link,
  meetingInfo,
  patientId,
  callbacks,
}) => {
  const config = useSWRConfig();
  const { t } = useTranslation();
  const { data: userData } = useAdminUserInfo(
    meetingInfo?.patientId ?? patientId ?? ""
  );

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      config.mutate((key: string) => key?.includes("get/user/busy/"));
      callbacks?.onSuccess?.();
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Schedule a meeting")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InlineWidget
            url={link ?? ""}
            prefill={{
              name: userData?.first_name + " " + userData?.last_name,
              firstName: userData?.first_name,
              lastName: userData?.last_name,
              email: userData?.email,
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            variant={"transparent"}
            color={"blueish.900"}
            mr={3}
            onClick={onClose}
          >
            Stäng bokningsfönstret
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CalendlyPopup;
