import { APIClient } from "services/api/client";
import useSWR from "swr";
import {
  IndividualFiltersType,
  LogsFilterTypes,
  ReportsFilterType,
  ReportsResponseType,
} from "./type";
import { PrimitivePaginationType } from "features/admin/api/types";
import { ChangeLog } from "features/superadmin/api/types";
import { useLocation } from "react-router-dom";

export const useFetchAdminReport = (filters?: ReportsFilterType) => {
  //i can't use useURLParametrize here because our api accepst ISO date string
  //besides, URLSearchParams has a weird behaviour with dates
  let paramsString = "?ballast=0";

  Object.entries(filters ?? {}).map(([key, value]) => {
    if ((key === "timeEnd" || key === "timeStart") && value) {
      paramsString +=
        "&" + key + "=" + new Date(value).toISOString().slice(0, 10);
    } else if (value) {
      paramsString += "&" + key + "=" + value + "";
    }
  });

  return useSWR<ReportsResponseType>(`admin/report` + paramsString, (url) =>
    APIClient.get(url)
  );
};

export const useFetchAdminReportByDoctorId = (
  id: string,
  filters?: IndividualFiltersType
) => {
  return useSWR<ReportsResponseType>(
    `admin/report?doctor=${encodeURI(id)}${
      filters?.date?.from
        ? "&timeStart=" + filters.date.from.toISOString().slice(0, 10)
        : ""
    }${
      filters?.date?.to
        ? "&timeEnd=" + filters.date.to.toISOString().slice(0, 10)
        : ""
    }`,
    (url) => APIClient.get(url)
  );
};

export const useFetchAdminReportByNurse = (
  id: string,
  filters?: IndividualFiltersType
) => {
  return useSWR<ReportsResponseType>(
    `admin/report?nurse=${encodeURI(id)}${
      filters?.date?.from
        ? "&timeStart=" + filters.date.from.toISOString().slice(0, 10)
        : ""
    }${
      filters?.date?.to
        ? "&timeEnd=" + filters.date.to.toISOString().slice(0, 10)
        : ""
    }`,
    (url) => APIClient.get(url)
  );
};

export const useGetAllLogs = (page: number, filters?: LogsFilterTypes) => {
  const queryParams: Record<string, string> = {
    page: page.toString(),
    size: "10",
    ...(filters?.doctor__role && { doctor__role: filters.doctor__role }),
    ...(filters?.created_at__gte && {
      created_at__gte: new Date(filters.created_at__gte).toISOString(),
    }),
    ...(filters?.created_at__lte && {
      created_at__lte: new Date(filters.created_at__lte).toISOString(),
    }),
    ...(filters?.action_type && { action_type: filters.action_type }),
  };

  const paramsString = Object.keys(queryParams)
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `personal-user-action/?${paramsString}`;

  return useSWR<PrimitivePaginationType<ChangeLog>>(url, (url: string) =>
    APIClient.get(url)
  );
};

export const useExportCSVFile = () => {
  const location = useLocation();
  const { data, error } = useSWR<Blob, Error>(
    `/personal-user-action/download/${location.search}`,
    (url: string) => APIClient.get(url)
  );

  const exportCSV = () => {
    if (data) {
      const blob = new Blob([data], { type: "text/csv" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "logs_exported_data.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return { exportCSV, error };
};
