import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useSendOrderInvoice } from "features/superadmin/api/client";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type OrderInvoiceButtonProps = {
  orderId: string;
  userEmail: string;
  currentPage: number;
  sortField: string;
  sortOrder: "asc" | "desc";
};

const OrderInvoiceButton: React.FC<OrderInvoiceButtonProps> = ({
  orderId,
  currentPage,
  sortField,
  sortOrder,
  userEmail,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const { trigger, isMutating } = useSendOrderInvoice(
    currentPage,
    sortField,
    sortOrder
  );

  const handleSendInvoice = async () => {
    try {
      const payload = { order_id: orderId };

      await trigger(payload);
      toast.success(t("The invoice was successfully sent."), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      onClose();
    } catch (error) {
      console.error("Error sending invoice:", error);
      toast.error(t("There was an error sending the invoice."), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Button
        variant="black"
        bg="rgba(121, 106, 159, 1) !important"
        border="none"
        transition="all 0.3s"
        _hover={{
          transform: "scale(1.1)",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
        fontWeight={500}
        onClick={onOpen}
      >
        {t("invoice")}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="1.5rem" color="blueish.500">
            Send Invoice
          </ModalHeader>

          <ModalBody
            w="full"
            pb="1rem"
            display="flex"
            flexDirection="column"
            gap={4}
          >
            <HStack>
              <Text color="black" fontSize="1rem" opacity={0.7}>
                Send invoice to:
              </Text>
              <Text color="black" fontSize="1rem" fontWeight="bold">
                {userEmail}
              </Text>
            </HStack>
            <Flex justify="flex-end" gap="2">
              <Button
                variant="black"
                onClick={onClose}
                transition="all 0.3s"
                _hover={{
                  transform: "scale(1.05)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
                textTransform="capitalize"
              >
                {t("cancel")}
              </Button>
              <Button
                variant="black"
                bg="white"
                color="black"
                transition="all 0.3s"
                _hover={{
                  transform: "scale(1.05)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
                onClick={handleSendInvoice}
              >
                {isMutating ? "Sending..." : "Send Invoice"}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OrderInvoiceButton;
