import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  VStack,
  Avatar,
  Heading,
  Center,
  ModalFooter,
  IconButton,
  Text,
  Box,
} from "@chakra-ui/react";
import { useAdminUserInfo } from "features/userInfo/api/client";
import { FunctionComponent, useContext, useEffect } from "react";
import { MdOutlineCall, MdOutlineCallEnd } from "react-icons/md";
import { PropagateLoader } from "react-spinners";
import { SocketContext } from "../VideocallContext";
import { useTranslation } from "react-i18next";

interface OutgoingCallModalProps {
  isOpen: boolean;
  onClose(): void;
  toId: string;
}

const OutgoingCallModal: FunctionComponent<OutgoingCallModalProps> = ({
  isOpen,
  onClose,
  toId,
}) => {
  const { t } = useTranslation();

  const {
    endCall: contextEndCall,
    call,
    endCallWithCustomStatus,
  } = useContext(SocketContext);

  const { data: userData } = useAdminUserInfo(toId);

  const overlayClickHandler = () => {
    contextEndCall();
    onClose();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (call?.status !== "declined") {
        onClose();
        endCallWithCustomStatus("missed");
      }
    }, 60000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [call?.status]);

  useEffect(() => {
    if (call?.status === "declined") {
      setTimeout(() => {
        onClose();
        contextEndCall(true);
      }, 3000);
    }
  }, [call]);

  return (
    <Modal isOpen={isOpen} onClose={overlayClickHandler} isCentered>
      <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
      <ModalOverlay />
      <ModalContent maxW={"26rem"}>
        <ModalHeader textAlign={"center"} fontWeight={400} fontSize="1.5rem">
          {t("Connecting")}...
        </ModalHeader>

        <ModalBody>
          <VStack>
            <Avatar h={"20vh"} w={"20vh"} />

            <Heading fontSize={"2rem"} fontWeight={"500"}>
              {userData?.first_name + " " + userData?.last_name}
            </Heading>
            <Center w="100%" h={"4rem"}>
              {call?.status === "requesting" && (
                <Box pr={"1rem"}>
                  <PropagateLoader
                    color="rgba(88, 154, 175, .3)"
                    speedMultiplier={0.3}
                  />
                </Box>
              )}

              {call?.status === "declined" && (
                <Text color={"blueish.900"} opacity={0.5} fontSize={"1rem"}>
                  {t("Busy")}...
                </Text>
              )}
            </Center>
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent={"center"} gap={"3rem"} pb={"2rem"}>
          <IconButton
            icon={<MdOutlineCallEnd />}
            fontSize={"1.75rem"}
            aria-label={""}
            minWidth={0}
            bg={"#e65a50"}
            border={"none"}
            height={"4rem"}
            width={"4rem"}
            padding={"0 !important"}
            borderRadius={"50%"}
            color={"white"}
            _hover={{
              transform: "translateY(0.25rem)",
              boxShadow: "0px 0px 1rem #e65a50",
            }}
            onClick={() => {
              contextEndCall();
              onClose();
            }}
            disabled={call?.status === "declined"}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OutgoingCallModal;
