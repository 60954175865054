import { Center, Spinner } from "@chakra-ui/react";
import { FunctionComponent } from "react";

interface LoadingComponentProps {}

const LoadingComponent: FunctionComponent<LoadingComponentProps> = () => {
  return (
    <Center
      bg={"white"}
      width={"100%"}
      mt={"0 !important"}
      borderRadius={"lg"}
      height={"80vh !important"}
      alignItems={"center"}
      flexDirection={"column"}
      p={"2vh"}
    >
      <Spinner />
    </Center>
  );
};

export default LoadingComponent;
