import { Box, ChakraProvider } from "@chakra-ui/react";
import GenericModal from "features/genericModal";
import i18next from "i18next";
import { useEffect } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { theme } from "./ui/theme/mainTheme";
import { routes } from "./routes";
import DefaultLayout from "ui/layout/DefaultLayout/DefaultLayout";
import Error404 from "pages/Error404";
import ProtectedRoute from "services/routing/protectedRoute";
import { useIntercom } from "react-use-intercom";
import CookiesPopup from "features/cookiesPopup/components/CookiePopup";
import { CookiesProvider } from "react-cookie";
import { AnimatePresence } from "framer-motion";
import usePageTracking from "features/analytics/hooks/usePageTracking";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminProtectedRoute from "services/routing/adminProtectedRoute";
import { useVerify } from "features/login/api/client";
import axios from "axios";
import { useDebug } from "features/admin/helper/columns";
import { useIpInfo } from "features/localization/api/client";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import "./index.css";
import { ContextProvider } from "features/Videocalls/components/VideocallContext";
import CallContextProvider from "features/Videocalls/components/CallContext";
import ErrorLayout from "ui/layout/ErrorLayout/ErrorLayout";
import StaffProtectedRoute from "services/routing/staffProtectedRoute";
import { ZegoCallsOmnipresentContextProvider } from "features/ZegoCallsOmnipresentContext/components/ZegoCallsOmnipresentContext";
import PushNotificationFacade from "features/pushNotifications/components/PushNotificationFacade";
import { ChatContextProvider } from "features/chats/components/ChatContext";

function App() {
  const { boot, shutdown } = useIntercom();
  const { t, i18n } = useTranslation();

  usePageTracking();
  useVerify();
  const { data: ipData } = useIpInfo();
  useEffect(() => {
    const hostname = window.location.hostname;

    const loaderElement = document.querySelector(".loader-container");
    if (loaderElement) {
      loaderElement.remove();
    }
    // let userLocale;
    // if (hostname.endsWith(".se")) {
    //   userLocale = "sv";
    // } else {
    //   userLocale = "en";
    // }
    // i18next.changeLanguage(userLocale);
  }, []);

  //INTERCOM BOOT SCRIPT, to reenable intercom uncomment this section below
  /* --------------------------------------------------- */
  // useEffect(() => {
  //   boot({ languageOverride: i18n.language });

  //   return () => {
  //     shutdown();
  //   };
  // }, [i18n.language]);
  /* --------------------------------------------------- */

  useEffect(() => {
    if (
      ipData &&
      !localStorage.getItem("i18nextLng") &&
      ipData.country_code_iso3 === "SWE"
    ) {
      i18n.changeLanguage("sv");
    }
  }, [ipData]);

  localStorage.setItem("chakra-ui-color-mode", "light");

  return (
    <CookiesProvider>
      <ChakraProvider theme={theme}>
        <ContextProvider>
          <CallContextProvider>
            <ZegoCallsOmnipresentContextProvider>
              <ChatContextProvider>
                <AnimatePresence mode={"wait"}>
                  <Switch>
                    {routes.map((route, index) => {
                      if (route.isProtected) {
                        if (
                          route.role === "doctor" ||
                          route.role === "nurse" ||
                          route.role === "superadmin"
                        ) {
                          return (
                            <StaffProtectedRoute
                              key={index}
                              path={route.path}
                              exact={route.exact}
                              role={route.role}
                            >
                              {route.component}
                            </StaffProtectedRoute>
                          );
                        } else {
                          return (
                            <ProtectedRoute
                              key={index}
                              path={route.path}
                              exact={route.exact}
                            >
                              {route.component}
                            </ProtectedRoute>
                          );
                        }
                      } else {
                        return (
                          <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                          >
                            {route.component}
                          </Route>
                        );
                      }
                    })}
                    <Route path="*">
                      <ErrorLayout>
                        <Error404 />
                      </ErrorLayout>
                    </Route>
                  </Switch>
                  <PushNotificationFacade />
                  <CookiesPopup />
                </AnimatePresence>
                <ToastContainer />
                <GenericModal />
              </ChatContextProvider>
            </ZegoCallsOmnipresentContextProvider>
          </CallContextProvider>
        </ContextProvider>
      </ChakraProvider>
    </CookiesProvider>
  );
}

export default App;
