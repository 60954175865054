import { APIClient } from "services/api/client";
import useSWRMutation from "swr/mutation";
import { ContactFormType } from "./types";

export const useSendContactRequest = () => {
  return useSWRMutation<any, ContactFormType>(
    "contact-us/",
    (url: string, { arg }: { arg: ContactFormType }) => {
      return APIClient.post(url, arg);
    }
  );
};
