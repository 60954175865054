import {
  Flex,
  useDisclosure,
  Image,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Box,
} from "@chakra-ui/react";
import NotificationCardList from "../notificationCardList";
import notification_icon from "assets/icons/notification_icon.svg";
import notification_icon_red from "assets/icons/notification_icon_red.svg";
import notification_black from "assets/icons/notifications_black.svg";
import { useSWRConfig } from "swr";
import { useNewNotifications, useReadNotifications } from "../api/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Notifications = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  const { t } = useTranslation();

  const { mutate } = useSWRConfig();
  const { data: notifications } = useNewNotifications();
  const { trigger: readNotifications } = useReadNotifications();

  const [unreadIds, setUnreadIds] = useState<string[]>([]);

  useEffect(() => {
    const newUnreadIds =
      (notifications ?? [])
        .filter((notification) => !notification.is_read)
        .map((notification) => notification.id) || [];

    setUnreadIds(newUnreadIds);
  }, []);

  const handleRefreshNotifications = () => {
    if (unreadIds.length > 0) {
      readNotifications({ ids: unreadIds });
      mutate("admin/notifications");
    }
  };

  return (
    <>
      <Flex
        gap={2}
        cursor="pointer"
        onClick={onOpen}
        align="center"
        position={"relative"}
      >
        <Image
          src={notification_black}
          alt="Notification Icon"
          position="relative"
        />
        {unreadIds.length > 0 && (
          <Box
            w="5px"
            h="5px"
            bg="red"
            borderRadius="100%"
            position="absolute"
            top={"0.5rem"}
            right={"-0.5rem"}
          />
        )}

        <Text
          color="rgba(0, 38, 55, 0.9)"
          fontSize={{ base: "", md: "16px", lg: "18px" }}
          fontWeight={700}
        >
          {t("Notifications")}
        </Text>
      </Flex>

      <Drawer
        onClose={() => {
          onClose();
          handleRefreshNotifications();
        }}
        isOpen={isOpen}
        size={{ base: "lg", lg: "xl" }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p="40px">
            <NotificationCardList unreadId={unreadIds} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Notifications;
