import { ChakraStylesConfig } from "chakra-react-select";

type Option = {
  label: string;
  value: string;
};

const controlStyles: ChakraStylesConfig<Option, false>["control"] = (
  provided
) => ({
  ...provided,
  bg: "transparent !important",
  border: "1px solid rgba(0, 0, 0, 0.1) !important",
  cursor: "pointer",
  fontSize: "1.2rem",
  fontWeight: "500",
});

const dropdownIndicatorStyles: ChakraStylesConfig<
  Option,
  false
>["dropdownIndicator"] = (provided) => ({
  ...provided,
  bg: "transparent",
  p: 0,
  w: 6,
  mx: 2,
  cursor: "inherit",
});

const indicatorSeparatorStyles: ChakraStylesConfig<
  Option,
  false
>["indicatorSeparator"] = () => ({
  display: "none",
});

const optionStyles: ChakraStylesConfig<Option, false>["option"] = (
  styles,
  { data }
) => ({
  ...styles,
  fontSize: "1rem",
  bgColor:
    data.label === "DOCTOR MEETING"
      ? "#589AAF"
      : data.label === "NURSE MEETING"
      ? "pink.300"
      : "gray.500",
  color: "white",
  fontWeight: "500",
  maxW: "max-content",
  borderRadius: "8px",
  ml: "2rem",
});

const singleValueStyles: ChakraStylesConfig<Option, false>["singleValue"] = (
  provided,
  { data }
) => ({
  ...provided,
  fontSize: "1rem",
  bgColor:
    data.label === "DOCTOR MEETING"
      ? "#589AAF"
      : data.label === "NURSE MEETING"
      ? "pink.300"
      : "gray.500",
  color: "white",
  fontWeight: "500",
  maxW: "max-content",
  borderRadius: "8px",
  paddingY: "5px",
  paddingX: "9px",
});

export const selectStaffStyles: ChakraStylesConfig<Option, false> = {
  control: controlStyles,
  dropdownIndicator: dropdownIndicatorStyles,
  indicatorSeparator: indicatorSeparatorStyles,
};

export const selectServiceStyles: ChakraStylesConfig<Option, false> = {
  control: (provided) => ({
    ...provided,
    bg: "transparent !important",
    border: "1px solid rgba(0, 0, 0, 0.1) !important",
    cursor: "pointer",
    fontSize: "1.2rem",
    fontWeight: "500",
  }),
  dropdownIndicator: dropdownIndicatorStyles,
  indicatorSeparator: indicatorSeparatorStyles,
  option: optionStyles,
  singleValue: singleValueStyles,
};
