export function paraget(
  params: URLSearchParams,
  name: string,
  type: "array"
): string[] | undefined;

export function paraget(
  params: URLSearchParams,
  name: string,
  type: "array_numeric"
): number[] | undefined;

export function paraget(
  params: URLSearchParams,
  name: string,
  type: "number"
): number | undefined;

export function paraget(
  params: URLSearchParams,
  name: string,
  type?: "string"
): string | undefined;

export function paraget(
  params: URLSearchParams,
  name: string,
  type?: "date"
): Date | undefined;

export function paraget(
  params: URLSearchParams,
  name: string,
  type?: "array" | "array_numeric" | "number" | "string" | "date"
) {
  const retreivedValue = params.get(name);
  if (
    !retreivedValue ||
    retreivedValue === "" ||
    retreivedValue === "undefined"
  ) {
    return undefined;
  }

  if (type === "array") {
    return retreivedValue.split(",");
  }

  if (type === "array_numeric") {
    return retreivedValue.split(",").map((e) => parseInt(e));
  }

  if (type === "number") {
    return parseInt(retreivedValue);
  }

  if (type === "date") {
    return retreivedValue ? new Date(retreivedValue) : undefined;
  }

  return retreivedValue;
}
