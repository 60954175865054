import { FunctionComponent, useEffect } from "react";
import { Button, Center, Flex, Link, Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { useHistory } from "react-router-dom";
import { useSWRConfig } from "swr";
import { useTranslation } from "react-i18next";

interface InvalidPrescriptionProps {}

const InvalidPrescription: FunctionComponent<InvalidPrescriptionProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Center
      w="100%"
      justifyContent={"space-between"}
      py={{ base: "1rem", lg: "4rem" }}
      h={{ base: "75vh", lg: "80vh" }}
      px={"10rem"}
    >
      <Flex
        flexDir="column"
        justifyContent={"space-between"}
        h={"100%"}
        w={"100%"}
      >
        <VStack
          alignItems={"flex-start"}
          px={"5rem"}
          maxW={"60rem"}
          gap={"1rem"}
          mt={"15%"}
        >
          <Text color={"blueish.900"} fontWeight={"600"} fontSize={"2rem"}>
            {t("Thank you for compleating our questionnaire.")}
          </Text>
          <Text
            color={"blueish.900"}
            fontSize={{ base: "1.2rem", lg: "1.5rem" }}
          >
            {t(
              "Based on the provided information, we are sorry to inform you that at this point you are not eligible to enrol into Lamina Clinic’s tapering program. We understand that this might come as a disappointment and we encourage you to reach out to us on"
            )}
            <Link href={"mailto:info@laminaclinic.com"} color={"blueish.500"}>
              info@laminaclinic.com
            </Link>
          </Text>
          <Text
            color={"blueish.900"}
            fontSize={{ base: "1.2rem", lg: "1.5rem" }}
          >
            {t("Stay strong and keep up your amazing work!")}
          </Text>
          <Text
            color={"blueish.900"}
            fontSize={{ base: "1.2rem", lg: "1.5rem" }}
          >
            {t("Lamina clinic team")}
          </Text>
        </VStack>
        <Button
          variant={"transparent"}
          color={"#002637"}
          opacity={0.5}
          onClick={() => history.push("/")}
          fontSize={{ base: "sm", sm: "sm", md: "md" }}
        >
          {t("Back")}
        </Button>
      </Flex>
    </Center>
  );
};

export default InvalidPrescription;
