import {
  Button,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  useGetDefaultAddressById,
  useUpdateDefaultAddress,
  useCreateDefaultAddress,
} from "features/addressPopup/api/client";
import { useAdminUserInfo } from "features/admin/api/client";
import {
  useFetchProfileInfo,
  usePatchAdminUserProfile,
} from "features/dashboard/api/client";
import { useFormik } from "formik";
import { FunctionComponent, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface AddressModalProps {
  isOpen: boolean;
  onClose(): void;
  patientId: string;
}

const AddressModal: FunctionComponent<AddressModalProps> = ({
  isOpen,
  onClose,
  patientId,
}) => {
  const { data: userData } = useAdminUserInfo(patientId);

  const { data: profileData } = useFetchProfileInfo();

  const { t } = useTranslation();

  const { trigger: updateAddress, isMutating: isEditAddressLoading } =
    useUpdateDefaultAddress(userData?.address?.id ?? "", userData?.id);

  const { trigger: createAddress, isMutating: isCreatingAddressLoading } =
    useCreateDefaultAddress();

  const { trigger: updateUserData } = usePatchAdminUserProfile(patientId);

  const isSuperAdmin = profileData?.role === "superadmin";
  const isLoading = isEditAddressLoading || isCreatingAddressLoading;

  const formik = useFormik<any>({
    initialValues: {
      ...userData,
      ...userData?.address,
      phone: userData?.address?.phone ?? "",
    },
    onSubmit: async (value) => {
      if (userData?.address) {
        await updateAddress({ address: value });
        toast.info("Address successfully updated");
        onClose();
      } else {
        const newAddressId = await createAddress({
          address: { ...value, user: patientId },
        });

        updateUserData({
          address: newAddressId.id,
        });
        toast.info("Address successfully updated");
        onClose();
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      first_name: userData?.address?.first_name ?? userData?.first_name ?? "",
      last_name: userData?.address?.last_name ?? userData?.last_name ?? "",
      phone: userData?.address?.phone,
      email: userData?.address?.email ?? userData?.email,
      username: userData?.username,
      address: userData?.address?.address,
      zip_code: userData?.address?.zip_code,
      city: userData?.address?.city,
      country: userData?.address?.country,
    });
  }, [userData?.address, formik.status]);

  const isChanged = useMemo(() => {
    return (
      JSON.stringify(formik.values) ===
      JSON.stringify({
        first_name: userData?.address?.first_name ?? userData?.first_name ?? "",
        last_name: userData?.address?.last_name ?? userData?.last_name ?? "",
        phone: userData?.address?.phone,
        email: userData?.address?.email ?? userData?.email,
        username: userData?.username,
        address: userData?.address?.address,
        zip_code: userData?.address?.zip_code,
        city: userData?.address?.city,
        country: userData?.address?.country,
      })
    );
  }, [userData, formik.values]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            w="100%"
            p="44px 32px"
            borderRadius="12px"
            bg="white"
            direction="column"
            gap="2rem"
          >
            <Heading
              color="rgba(88, 154, 175, 0.8)"
              fontSize={"20px"}
              fontWeight={600}
              letterSpacing={0.7}
            >
              {t("USER DETAILS")}
            </Heading>

            <Flex w="100%" direction="column" gap="2rem">
              <Flex w="100%" gap={4}>
                <Flex direction="column" w="100%">
                  <Text
                    color="rgba(0, 38, 55, 0.5)"
                    fontSize="0.875rem"
                    fontWeight={500}
                  >
                    {t("First Name")}
                  </Text>
                  <Input
                    bg="rgba(88, 154, 175, 0.1)"
                    borderRadius=".5rem"
                    py="0.875rem"
                    px="1.25rem"
                    fontSize="1rem"
                    fontWeight={500}
                    minH="3.25rem"
                    name="first_name"
                    id="first_name"
                    value={formik.values?.first_name}
                    onChange={formik.handleChange}
                    //isReadOnly={!isSuperAdmin}
                  />
                </Flex>
                <Flex direction="column" w="100%">
                  <Text
                    color="rgba(0, 38, 55, 0.5)"
                    fontSize="0.875rem"
                    fontWeight={500}
                  >
                    {t("Last Name")}
                  </Text>
                  <Input
                    bg="rgba(88, 154, 175, 0.1)"
                    borderRadius=".5rem"
                    py="0.875rem"
                    px="1.25rem"
                    fontSize="1rem"
                    fontWeight={500}
                    minH="3.25rem"
                    name="last_name"
                    id="last_name"
                    value={formik.values?.last_name}
                    onChange={formik.handleChange}
                    //isReadOnly={!isSuperAdmin}
                  />
                </Flex>

                <Flex direction="column" w="100%">
                  <Text
                    color="rgba(0, 38, 55, 0.5)"
                    fontSize="0.875rem"
                    fontWeight={500}
                  >
                    {t("Personal number")}
                  </Text>
                  <Input
                    bg="rgba(88, 154, 175, 0.1)"
                    borderRadius=".5rem"
                    py="0.875rem"
                    px="1.25rem"
                    fontSize="1rem"
                    fontWeight={500}
                    minH="3.25rem"
                    name="username"
                    id="username"
                    value={formik.values?.username}
                    onChange={formik.handleChange}
                    isReadOnly={true}
                  />
                </Flex>
              </Flex>

              {/* First Set */}
              <Flex w="100%" gap={4}>
                <Flex direction="column" w="100%">
                  <Text
                    color="rgba(0, 38, 55, 0.5)"
                    fontSize="0.875rem"
                    fontWeight={500}
                  >
                    {t("Phone Number")}
                  </Text>
                  <Input
                    bg="rgba(88, 154, 175, 0.1)"
                    borderRadius=".5rem"
                    py="0.875rem"
                    px="1.25rem"
                    fontSize="1rem"
                    fontWeight={500}
                    minH="3.25rem"
                    name="phone"
                    id="phone"
                    value={formik.values?.phone}
                    onChange={formik.handleChange}
                    //isReadOnly={!isSuperAdmin}
                  />
                </Flex>
                <Flex direction="column" w="100%">
                  <Text
                    color="rgba(0, 38, 55, 0.5)"
                    fontSize="0.875rem"
                    fontWeight={500}
                  >
                    Email
                  </Text>
                  <Input
                    bg="rgba(88, 154, 175, 0.1)"
                    borderRadius=".5rem"
                    py="0.875rem"
                    px="1.25rem"
                    fontSize="1rem"
                    fontWeight={500}
                    minH="3.25rem"
                    name="email"
                    id="email"
                    value={formik.values?.email}
                    onChange={formik.handleChange}
                    //isReadOnly={!isSuperAdmin}
                  />
                </Flex>
                <Flex direction="column" w="100%">
                  <Text
                    color="rgba(0, 38, 55, 0.5)"
                    fontSize="0.875rem"
                    fontWeight={500}
                  >
                    {t("Address")}
                  </Text>
                  <Input
                    bg="rgba(88, 154, 175, 0.1)"
                    borderRadius=".5rem"
                    py="0.875rem"
                    px="1.25rem"
                    fontSize="1rem"
                    fontWeight={500}
                    minH="3.25rem"
                    name="address"
                    id="address"
                    value={formik.values?.address}
                    onChange={formik.handleChange}
                    //isReadOnly={!isSuperAdmin}
                  />
                </Flex>
              </Flex>

              {/* Second Set */}
              <Flex w="100%" gap={4}>
                <Flex direction="column" w="100%">
                  <Text
                    color="rgba(0, 38, 55, 0.5)"
                    fontSize="0.875rem"
                    fontWeight={500}
                  >
                    {t("ZIP-Code")}
                  </Text>
                  <Input
                    bg="rgba(88, 154, 175, 0.1)"
                    borderRadius=".5rem"
                    py="0.875rem"
                    px="1.25rem"
                    fontSize="1rem"
                    fontWeight={500}
                    minH="3.25rem"
                    name="zip_code"
                    id="zip_code"
                    value={formik.values?.zip_code}
                    onChange={formik.handleChange}
                    //isReadOnly={!isSuperAdmin}
                  />
                </Flex>
                <Flex direction="column" w="100%">
                  <Text
                    color="rgba(0, 38, 55, 0.5)"
                    fontSize="0.875rem"
                    fontWeight={500}
                  >
                    {t("City/Town")}
                  </Text>
                  <Input
                    bg="rgba(88, 154, 175, 0.1)"
                    borderRadius=".5rem"
                    py="0.875rem"
                    px="1.25rem"
                    fontSize="1rem"
                    fontWeight={500}
                    minH="3.25rem"
                    name="city"
                    id="city"
                    value={formik.values?.city}
                    onChange={formik.handleChange}
                    //isReadOnly={!isSuperAdmin}
                  />
                </Flex>
                <Flex direction="column" w="100%">
                  <Text
                    color="rgba(0, 38, 55, 0.5)"
                    fontSize="0.875rem"
                    fontWeight={500}
                  >
                    {t("Country")}
                  </Text>
                  <Input
                    bg="rgba(88, 154, 175, 0.1)"
                    borderRadius=".5rem"
                    py="0.875rem"
                    px="1.25rem"
                    fontSize="1rem"
                    fontWeight={500}
                    minH="3.25rem"
                    name="country"
                    id="country"
                    value={formik.values?.country}
                    onChange={formik.handleChange}
                    //isReadOnly={!isSuperAdmin}
                  />
                </Flex>
              </Flex>
            </Flex>

            {true && (
              <Flex justifyContent={"flex-end"}>
                <Button
                  onClick={() => {
                    formik.submitForm();
                  }}
                  isLoading={isLoading}
                  isDisabled={isChanged}
                >
                  {t("Save changes")}
                </Button>
              </Flex>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddressModal;
