import { Flex, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";

interface SortableColumnHeaderProps {
  name: string;
  label: string;
  onChange(order?: string): void;
  order: string;
}

const SortableColumnHeader: FunctionComponent<SortableColumnHeaderProps> = ({
  label,
  name,
  onChange,
  order,
}) => {
  return (
    <Flex
      cursor={"pointer"}
      alignItems={"center"}
      gap={"0.5rem"}
      onClick={() => {
        if (order === "" || !order) {
          onChange("desc");
        } else if (order === "desc") {
          onChange("asc");
        } else if (order === "asc") {
          onChange("");
        }
      }}
    >
      <Text fontSize={"inherit"} color={"inherit"}>
        {label}
      </Text>

      {order === "asc" && <GoTriangleUp />}
      {order === "desc" && <GoTriangleDown />}
    </Flex>
  );
};

export default SortableColumnHeader;
