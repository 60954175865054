import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Slide,
  Box,
  SlideFade,
  useDisclosure,
  Heading,
  VStack,
  Text,
  HStack,
  Show,
  Flex,
} from "@chakra-ui/react";
import { FunctionComponent, useEffect } from "react";
import { useCookies } from "react-cookie";
import { MdOutlineCookie } from "react-icons/md";

import { useTranslation } from "react-i18next";

interface CookiesPopupProps {}

const CookiesPopup: FunctionComponent<CookiesPopupProps> = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [cookies, setCookie] = useCookies(["accepted-cookies"]);

  const { t } = useTranslation();

  useEffect(() => {
    const timeOutID = setTimeout(() => {
      if (!cookies["accepted-cookies"]) {
        onOpen();
      }
    }, 1000);

    return () => {
      clearTimeout(timeOutID);
    };
  }, []);

  const acceptCookies = () => {
    setCookie("accepted-cookies", true, {
      expires: new Date("2038-01-01"),
    });
    onClose();
  };

  const deleteCookies = () => {
    var theCookies = document.cookie.split(";");
    for (var i = 0; i < theCookies.length; i++) {
      document.cookie =
        theCookies[i].split("=")[0] +
        "=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
  };

  return (
    <>
      {isOpen && (
        <Box
          position={"fixed"}
          bottom={{ base: "1rem", md: "1rem" }}
          left={{ base: "2.5vw", md: "1rem" }}
          zIndex={100000}
          height={isOpen ? "auto" : "0px"}
        >
          <SlideFade in={isOpen}>
            <HStack
              background={"white"}
              width={"auto"}
              pr={{ base: "2rem", md: "1rem" }}
              pl={{ base: "2rem", md: "0.5rem" }}
              py={"1.5rem"}
              borderRadius={"1rem"}
              boxShadow={"0px 0px 16px rgba(0, 0, 0, 0.2)"}
              maxW={{ base: "95vw", sm: "35rem" }}
              alignItems={"flex-start"}
              gap={"0.5rem"}
            >
              <Show above={"sm"}>
                <MdOutlineCookie
                  fontSize={"5rem"}
                  color={"#589AAF"}
                  style={{ height: "auto" }}
                />
              </Show>

              <VStack alignItems={"flex-start"} gap={"0"}>
                <Heading fontSize={"1.75rem"}>Vi använder cookies</Heading>
                <Text
                  variant={"dark"}
                  fontSize={"1rem"}
                  mb={"0.75rem !important"}
                  textAlign={"justify"}
                >
                  Godkänner du våra cookies? Nödvändiga cookies är alltid
                  aktiva.
                </Text>

                <Flex gap={"0.5rem"}>
                  <Button
                    variant={"filledBlue"}
                    py={"0.5rem"}
                    px={"1.5rem"}
                    minWidth={"0"}
                    onClick={acceptCookies}
                    fontSize={"1rem"}
                  >
                    Ja
                  </Button>

                  <Button
                    variant={"ghost"}
                    color={"blueish.900"}
                    opacity={0.75}
                    py={"0.5rem"}
                    px={"1.5rem"}
                    minWidth={"0"}
                    onClick={acceptCookies}
                    fontSize={"1rem"}
                  >
                    Nej
                  </Button>
                </Flex>
              </VStack>
            </HStack>
          </SlideFade>
        </Box>
      )}
    </>
  );
};

export default CookiesPopup;
