import StateManagedSelect from 'react-select/dist/declarations/src/stateManager';

import { create, useStore } from 'zustand';

import { PushTokenState } from '../api/types';

export const usePushTokenStore = create<PushTokenState>((set) => ({
  token: '',
  setToken: (token: string) => set(() => ({ token: token })),
}));
