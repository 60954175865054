import React, { useState } from "react";
import { Flex, Heading, Box } from "@chakra-ui/react";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import {
  usePerrsonelBusyHours,
  usePersonelAvailabilityHours,
} from "features/superadmin/api/client";
import WorkingCalendarWidget from "./components/WorkingCalendarWidget";

import { FaSortAmountDownAlt } from "react-icons/fa";
import { FaSortAmountUp } from "react-icons/fa";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { BsChevronDown } from "react-icons/bs";

const WorkingCalendar = () => {
  const { t } = useTranslation();

  const [step, setStep] = useState<number>(30);

  const initialStep = 30;

  const { data: profileData } = useFetchProfileInfo();

  let startOfWeek = moment().startOf("week").toDate().toISOString();
  let endOfWeek = moment().endOf("week").toDate().toISOString();

  const personelId = profileData?.id || "";
  const { data: availabilityData } = usePersonelAvailabilityHours(personelId);

  const { data: busyData } = usePerrsonelBusyHours(
    personelId,
    startOfWeek,
    endOfWeek
  );

  const handleStepToggle = () => {
    if (step === initialStep) {
      setStep(7.5);
    } else {
      setStep(initialStep);
    }
  };

  return (
    <Flex
      w="full"
      bg="white"
      borderRadius="0.75rem"
      p="2rem"
      direction="column"
      gap="2rem"
    >
      <Flex w="full" align="center" justify="flex-end" gap={2}>
        <Box onClick={handleStepToggle} cursor="pointer">
          <BsChevronDown
            style={{
              transform: step === initialStep ? "" : "rotate(180deg)",
              transition: "all 0.5s ease-out",
            }}
          />
        </Box>

        <Heading lineHeight={1} letterSpacing={0.5}>
          {t("Working Days Calendar")}
        </Heading>
      </Flex>

      <WorkingCalendarWidget
        workingDays={availabilityData}
        busyHours={busyData}
        personelRole={profileData?.role}
        step={step}
      />
    </Flex>
  );
};

export default WorkingCalendar;
