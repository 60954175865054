import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Link as ChakraLink,
  Link,
  Box,
} from "@chakra-ui/react";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";
import i18n from "i18n";
import React from "react";
import { FunctionComponent } from "react";
import { useModalStore } from "services/state/modalStore";
import ConfirmCTA from "./components/CTAs/ConfirmCTA";

export const triggerAnnouncementModal = () => {
  const { onOpen, setModalContent, setTitle, setCta } =
    useModalStore.getState();

  setTitle("");
  setCta(i18n.t("close"));
  setModalContent(
    <>
      Vi öppnar snart upp nya platser! Om du vill få ett meddelande när det är
      dags,{" "}
      <ChakraLink
        href="https://form.typeform.com/to/bkJc66lo"
        target="blank"
        color="blueish.500"
      >
        skriv upp dig på vår väntelista
      </ChakraLink>
      . Om du har frågor når du oss på{" "}
      <ChakraLink
        href="mailto:kundtjanst@laminaclinic.com"
        target="blank"
        color="blueish.500"
      >
        kundtjanst@laminaclinic.com
      </ChakraLink>
    </>
  );
  (window as any).gtag("event", "Popups", {
    action: "User triggered announcement popup",
  });
  onOpen();
};

export const showNoBankId = () => {
  const { onOpen, setModalContent, setTitle, setCta } =
    useModalStore.getState();

  setTitle("");
  setCta(i18n.t("close"));
  setModalContent(
    i18n.t("Seems like BankID application is not intalled on your device")
  );

  onOpen();
};

export const invokeConfirmationModal = (
  text: string,
  callback: () => void,
  title?: string,
  confirmText?: string,
  cancelText?: string
) => {
  const { onOpen, onClose, setModalContent, setTitle, setCta, setIsLoading } =
    useModalStore.getState();

  const transformedCallback = async (callbackParam: any) => {
    try {
      setIsLoading(true);
      await callbackParam();
      onClose();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  setCta(
    <ConfirmCTA
      callback={() => {
        transformedCallback(callback);
      }}
      texts={{ confirmation: confirmText, cancelation: cancelText }}
    />
  );
  if (title) {
    setTitle(title);
  }

  setModalContent(text);

  onOpen();
};

export const invokeBasicInfoModal = (
  text: string | React.ReactNode,
  title?: string,
  ctaCallback?: () => void,
  ctaConfig?: {
    text?: string | React.ReactNode;
  }
) => {
  const { onOpen, setModalContent, setTitle, setCta, setCtaCallback } =
    useModalStore.getState();

  if (title) {
    setTitle(title);
  }

  if (ctaCallback) {
    setCtaCallback(ctaCallback);
  }
  setModalContent(text);
  setCta(ctaConfig?.text ?? "Okay");

  onOpen();
};

const GenericModal: FunctionComponent = () => {
  const { isOpen, onClose, content, title, cta, ctaCallback, setCtaCallback } =
    useModalStore();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"lg"}>
      <ModalOverlay />
      <ModalContent bg={"white"} p={"2em"}>
        <ModalHeader textAlign={"start"} color={"#589AAF"} fontWeight={"400"}>
          {title}
        </ModalHeader>
        <ModalBody fontSize={"md"} textColor={"black"} textAlign={"justify"}>
          {content}
        </ModalBody>

        <ModalFooter justifyContent={"center"}>
          <div>
            {typeof cta === "string" && (
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  if (ctaCallback) {
                    ctaCallback();
                    setCtaCallback(undefined);
                  }
                  onClose();
                }}
                variant={"transparent"}
                color={"#589AAF"}
                fontSize={"1.2em"}
                fontWeight={"300"}
                textTransform={"capitalize"}
              >
                {cta}
              </Button>
            )}
            {typeof cta !== "string" && <Box>{cta}</Box>}
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GenericModal;
