import { Button, ButtonGroup } from "@chakra-ui/react";
import React from "react";
import FaqPillIcon from "ui/iconComponents/FaqPillIcon";
import QuestionMarkIcon from "ui/iconComponents/QuestionMarkIcon";
import EnvelopeIcon from "ui/iconComponents/EnvelopeIcon";
import { useTranslation } from "react-i18next";

type FaqNavigationButtonsProps = {
  scrollToSection: (id: string) => void;
};

const FaqNavigationButtons: React.FC<FaqNavigationButtonsProps> = ({
  scrollToSection,
}) => {
  const { t } = useTranslation();
  return (
    <ButtonGroup
      ml={{ base: "2rem", sm: "7rem", md: "-2rem", lg: "-3rem" }}
      flexDirection={{ base: "column", sm: "row" }}
      alignSelf={{ base: "flex-start", md: "auto" }}
      gap="1rem"
    >
      <Button
        className="button-with-icon"
        variant="faq_tabs"
        leftIcon={<FaqPillIcon />}
        fontSize="1rem"
        onClick={() => scrollToSection("treatment-questions")}
      >
        {t("Treatment info for patients")}
      </Button>
      <Button
        className="button-with-icon"
        variant="faq_tabs"
        leftIcon={<QuestionMarkIcon />}
        fontSize="1rem"
        onClick={() => scrollToSection("frequently-questions")}
      >
        {t("Frequently asked questions")}
      </Button>
      <Button
        className="button-with-icon"
        variant="faq_tabs"
        leftIcon={<EnvelopeIcon />}
        fontSize="1rem"
        onClick={() => scrollToSection("contact-form")}
      >
        {t("Contact us")}
      </Button>
    </ButtonGroup>
  );
};

export default FaqNavigationButtons;
