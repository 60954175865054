import { Spinner } from "@chakra-ui/react";
import { Widget } from "@typeform/embed-react";
import { useSendTypeformData } from "features/prescriptionForm/api/client";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useFetchProfileInfo } from "features/dashboard/api/client";
import { useCookies } from "react-cookie";

interface MemoizedMobileTypeformProps {}

const MemoizedMobileTypeform: React.FunctionComponent<
  MemoizedMobileTypeformProps
> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [cookies, setCookie] = useCookies(["accepted-cookies"]);

  const handleSubmit = ({ responseId }: { responseId: string }) => {
    console.log("form submitted, response has id", responseId);
    history.push("?responseId=" + responseId);
  };

  useEffect(() => {
    setCookie("accepted-cookies", true, {
      expires: new Date(Date.now() + 2 * 60 * 60 * 1000),
    });
  }, []);

  return (
    <>
      {!isLoading && (
        <Widget
          id={t("prescription_form_id")}
          style={{
            width: "100%",
            height: "100%",
            fontFamily: "secondary",
          }}
          keepSession={true}
          /* onEndingButtonClick={() => {
          history.push("/dashboard");
        }} */
          onSubmit={handleSubmit}
        />
      )}
      {isLoading && <Spinner />}
    </>
  );
};

export default React.memo(MemoizedMobileTypeform);
