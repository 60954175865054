import {
  Flex,
  Image,
  Heading,
  Text,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import parse from "html-react-parser";

import change_treatment_icon from "assets/icons/change_treatment_icon.svg";

import visit_arrow from "assets/icons/visit_arrow.svg";
import notification_accept_green from "assets/icons/notification_accept_green.svg";

import NotificationAccept from "ui/iconComponents/NotificationAcceptIcon";
import NotificationDecline from "ui/iconComponents/NotificationDeclineIcon";
import NotificationPopupContainer from "../notificationPopupContainer";
import { useState, useEffect } from "react";
import { useFetchProfileInfo } from "features/dashboard/api/client";

interface NotificationCardProps {
  text: string;
  title: string;
  status: string;
  is_read: boolean;
  created_at: string;
  user_id: string;
  id: string;
  working_id: string;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  text,
  title,
  status,
  is_read,
  created_at,
  user_id,
  id,
  working_id,
}) => {
  const [editMode, setEditMode] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: userData } = useFetchProfileInfo();

  const date = new Date(created_at);
  const formattedDate = date
    .toLocaleString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    })
    .replace("at", "");

  const formattedText = parse(text);

  return (
    <Flex
      w="100%"
      p="20px"
      direction="column"
      bg={!is_read ? "rgba(88, 154, 175, 0.08)" : "none"}
      borderBottom={!is_read ? "none" : "1px solid rgba(88, 154, 175, 0.2)"}
      borderRadius="8px"
      gap="20px"
    >
      <Flex
        w="100%"
        align="flex-start"
        justify="space-between"
        gap="20px"
        position="relative"
        direction={{ base: "column", md: "row" }}
      >
        {!is_read && (
          <Flex
            w="8px"
            h="8px"
            bg="rgba(222, 36, 83, 1)"
            position="absolute"
            top={-4}
            left={-4}
            borderRadius="50%"
          />
        )}
        <Image src={change_treatment_icon} />

        <Flex direction="column" gap="2px" flexGrow={1}>
          <Heading fontSize="20px" fontWeight={700}>
            {title}
          </Heading>
          <Text color="black" fontSize="16px">
            {formattedText}
          </Text>
        </Flex>
        <Text color="rgba(0, 38, 55, 1)" fontSize="12px" whiteSpace="nowrap">
          {formattedDate}
        </Text>
      </Flex>

      <Flex w="100%" align="center" justify="space-between">
        {status === "request_changes_requested" && (
          <>
            <Flex gap="12px" px={{ base: "60px", lg: "70px" }}>
              <Button
                variant="filter"
                bg="rgba(44, 132, 117, 1)"
                color="white"
                onClick={() => {
                  setEditMode(true);
                  onOpen();
                }}
                _hover={{ bg: "rgba(44, 132, 117, 0.8)" }}
                maxH="40px"
                gap="8px"
              >
                <NotificationAccept />
                Accept
              </Button>
              <Button
                variant="adjust"
                border="1px solid rgba(213, 115, 138, 1)"
                color="rgba(213, 115, 138, 1)"
                _hover={{
                  bg: "rgba(213, 115, 138, 1)",
                  color: "white",
                  stroke: "#FFFFFF",
                }}
                maxH="40px"
                gap="8px"
                onClick={() => {
                  setEditMode(false);
                  onOpen();
                }}
              >
                <NotificationDecline />
                Decline
              </Button>
            </Flex>
          </>
        )}
        {status === "request_changes_declined" && (
          <Flex w="100%" align="center" justify="space-between">
            <Flex
              bg="rgba(213, 115, 138, 0.1)"
              py="8px"
              px="18px"
              borderRadius="8px"
              ml={{ base: "60px", lg: "60px" }}
              gap="8px"
            >
              <NotificationDecline />
              <Text
                color="rgba(213, 115, 138, 1)"
                fontSize="16px"
                fontWeight={700}
              >
                Request Declined
              </Text>
            </Flex>
          </Flex>
        )}
        {status === "request_changes_approved" && (
          <Flex w="100%" align="center" justify="space-between">
            <Flex
              bg="rgba(44, 132, 117, 0.185)"
              py="8px"
              px="18px"
              borderRadius="8px"
              ml={{ base: "60px", lg: "60px" }}
              gap="8px"
            >
              <Image src={notification_accept_green} mb="2px" />
              <Text
                color="rgba(44, 132, 117, 0.5)"
                fontSize="16px"
                fontWeight={700}
              >
                Request Accepted
              </Text>
            </Flex>
          </Flex>
        )}
        {user_id && (
          <Flex w={"100%"} justifyContent={"flex-end"}>
            <Link
              to={`/${
                userData?.role === "superadmin"
                  ? "superadmin"
                  : userData?.role === "doctor"
                  ? "doctor"
                  : "nurse/patients"
              }/${working_id}`}
              onClick={() => onClose()}
            >
              <Flex gap="12.8px">
                <Text
                  color="rgba(88, 154, 175, 1)"
                  fontSize="16px"
                  fontWeight={700}
                >
                  Visit Profile
                </Text>
                <Image src={visit_arrow} />
              </Flex>
            </Link>
          </Flex>
        )}
      </Flex>

      <NotificationPopupContainer
        onClose={onClose}
        isOpen={isOpen}
        editMode={editMode}
        id={id}
        user_id={user_id}
      />
    </Flex>
  );
};

export default NotificationCard;
