import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
  useDisclosure,
  Link as ChakraLink,
} from "@chakra-ui/react";

import logo from "assets/images/logo.svg";
import burger from "assets/images/hamburger-menu.svg";
import DrawerView from "../DrawerView";

import faq_yellow from "assets/icons/faq_yellow.svg";
import faq from "assets/icons/faq.svg";
import general_navbar_mobile from "assets/images/general_navbar_mobile.png";

import background from "assets/images/background.svg";
import { useHistory } from "react-router-dom";

const GeneralNavbarMobile = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const history = useHistory();
  return (
    <Flex
      position="relative"
      justify="space-between"
      alignItems="flex-start"
      w={"100%"}
      bgImage={general_navbar_mobile}
      backgroundPosition="center"
      backgroundSize="cover"
      h={{ base: "80vh", sm: "90vh", md: "80vh", lg: "80vh" }}
      p="2rem"
    >
      <Box
        position="absolute"
        left={-10}
        top={0}
        w="full"
        h={{ base: "80vh", sm: "90vh", md: "80vh", lg: "80vh" }}
        bgGradient="linear(to-l, rgba(0, 38, 55, 0), rgba(36, 98, 118, 0.5))"
      />
      <Flex w="full" direction="column" zIndex={1000}>
        <Flex
          w="full"
          align="center"
          justify="space-between"
          pb="2rem"
          borderBottom="1px solid rgba(255, 255, 255, 0.3)"
        >
          <Image
            src={logo}
            alt="Lamina clinic logo"
            w={"calc(120px + 9%)"}
            minW={"100px"}
            cursor={"pointer"}
            onClick={() => history.push("/")}
            mb="5px"
            zIndex={1000}
          />

          <HStack alignItems="center">
            {history.location.pathname === "/faq" ? (
              <Image src={faq_yellow} mb="5px" />
            ) : (
              <Image src={faq} mb="5px" />
            )}
            <Text
              fontSize="0.75rem"
              fontWeight={700}
              cursor="pointer"
              color={
                history.location.pathname === "/faq"
                  ? "rgba(238, 232, 169, 1)"
                  : "white"
              }
              onClick={() => history.push("/faq")}
              pr={"2rem"}
            >
              Vanliga frågor
            </Text>

            <Center
              backgroundColor={"yellowish.400"}
              borderRadius={"full"}
              h={"2rem"}
              w={"2rem"}
            >
              <Image src={burger} alt="down" onClick={onOpen} />
            </Center>
          </HStack>

          <Drawer isOpen={isOpen} onClose={onClose} size={"full"}>
            <DrawerOverlay />
            <DrawerContent bgImage={background} py={"3%"}>
              <DrawerBody>
                <DrawerView onClose={onClose} />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Flex>

        <Flex
          position="absolute"
          w="100%"
          align="flex-start"
          direction="column"
          px="3rem"
          gap="12px"
          zIndex={1000}
          left={-5}
          top={140}
        >
          <Heading
            color="rgba(255, 255, 255, 1)"
            fontSize={{ base: "48px", sm: "56px", md: "64px", lg: "72px" }}
            fontWeight={500}
            w={{ base: "100%", md: "55%" }}
            lineHeight={1.2}
          >
            Vill du trappa ner på dina antidepressiva?
          </Heading>
          <Text
            color="rgba(255, 255, 255, 1)"
            fontSize={{ base: "16px", sm: "18px", md: "20px" }}
            fontWeight={400}
            w="90%"
          >
            HAR DU KANSKE FÖRSÖKT FÖRUT, MEN INTE LYCKATS?
          </Text>
          <Button
            maxW="340px"
            color="rgba(0, 38, 55, 1)"
            minH={{ base: "50px", sm: "auto" }}
            mt="1rem"
            fontSize={{ base: "14px", sm: "18px" }}
            px="3rem"
          >
            <ChakraLink
              href="https://calendly.com/lamina-clinic/15-min-konsultation-kostnadsfritt"
              target="blank"
              textDecoration="none !important"
            >
              Boka gratis konsultation
            </ChakraLink>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default GeneralNavbarMobile;
