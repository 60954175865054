import general_steg1 from "assets/icons/general_steg1.svg";
import general_steg2 from "assets/icons/general_steg2.svg";
import general_steg3 from "assets/icons/general_steg3.svg";
import general_steg4 from "assets/icons/general_steg4.svg";
import general_steg5 from "assets/icons/general_steg5.svg";
import general_steg6 from "assets/icons/general_steg6.svg";

export const general_data = [
  {
    id: "1",
    steg: "STEG 1",
    text: "Fyll i gratis formulär",
    image: `${general_steg1}`,
  },
  {
    id: "2",
    steg: "STEG 2",
    text: "Boka kostnadsfri konsultation",
    image: `${general_steg2}`,
  },
  {
    id: "3",
    steg: "STEG 3",
    text: "Få din personliga nedtrappningsplan",
    image: `${general_steg3}`,
  },
  {
    id: "4",
    steg: "STEG 4",
    text: "Få hem mediciner i nedtrappningsdoser",
    image: `${general_steg4}`,
  },
  {
    id: "5",
    steg: "STEG 5",
    text: "Starta nedtrappningen i appen",
    image: `${general_steg5}`,
  },
  {
    id: "6",
    steg: "STEG 6",
    text: "Förnya för nästa månad",
    image: `${general_steg6}`,
  },
];
