import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFetchPrescriptionHelper } from "features/prescriptionHelper/api/client";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface PrescriptionHelperProps {
  isOpen: boolean;
  onClose(): void;
  userId?: string;
}

const BlurryOverlay = () => (
  <ModalOverlay
    bg="rgba(0, 0, 0, 0.2)"
    backdropFilter="auto"
    backdropBlur="4px"
  />
);

const PrescriptionHelper: FunctionComponent<PrescriptionHelperProps> = ({
  isOpen,
  onClose,
  userId,
}) => {
  const { id: urlId } = useParams<{ id: string }>();
  const id = userId ?? urlId;
  const { t } = useTranslation();
  const { data: helperData } = useFetchPrescriptionHelper(id);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <BlurryOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading
            fontFamily={"secondary"}
            fontSize={"2rem"}
            fontWeight={"400"}
          >
            {t("Prescription Helper")}
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          pb={"4rem !important"}
          maxH={"70vh"}
          overflow={"hidden"}
          overflowY={"auto"}
        >
          <VStack alignItems={"flex-start"} gap={"2rem"}>
            {helperData?.helper.data.map((helperItem) => {
              const regular = {
                name: "Standard",
                data: Object.entries(helperItem.regular),
              };
              const apl = {
                name: "APL",
                data: Object.entries(helperItem.apl_box),
              };
              const regenboogen = {
                name: "RegenBoogen",
                data: Object.entries(helperItem.regenboogen),
              };

              const colorScheme: { [key: string]: string[] } = {
                standard: ["#d9a82e", "#ffd875"],
                apl: ["#c76e26", "#ffb67a"],
                regenboogen: ["#9c3016", "#f5ae9d"],
              };

              return (
                <Box w={"100%"}>
                  <Heading
                    fontSize={"1.2rem"}
                    mb={"0.25rem"}
                    textTransform={"capitalize"}
                  >
                    {t("month")} {helperItem.month_from} - {helperItem.month_to}
                  </Heading>
                  <VStack>
                    {[regular, apl, regenboogen]
                      .filter((pharmacy) => pharmacy.data.length > 0)
                      .map((pharmacyData) => {
                        const pharmacy = pharmacyData.name;
                        const selectedData = pharmacyData.data;
                        return (
                          <Flex gap={"1rem"} w={"100%"}>
                            <Center
                              px={"0.75rem"}
                              py={"0.15rem"}
                              pb={0}
                              fontSize={"0.8rem"}
                              fontWeight={600}
                              borderRadius={"0.25rem"}
                              bg={
                                colorScheme[pharmacy.toLocaleLowerCase()]?.[1]
                              }
                              border={
                                "1px solid " +
                                colorScheme[pharmacy.toLocaleLowerCase()]?.[0]
                              }
                            >
                              {pharmacy}
                            </Center>
                            <Flex gap={"0.5rem"} flexWrap="wrap">
                              {selectedData
                                .filter(([dose, count]) => parseFloat(dose) > 0)
                                .map((pillsData) => {
                                  return (
                                    <Box
                                      borderRadius={"4px"}
                                      px={"0.75rem"}
                                      py={"0.15rem"}
                                      minW={"8rem"}
                                      bg={"#EEF5F7"}
                                      border={"1px solid #589AAF"}
                                    >
                                      {pillsData[0]} mg, {pillsData[1]}{" "}
                                      {pharmacy === "APL" ? "box" : "st"}
                                    </Box>
                                  );
                                })}
                            </Flex>
                          </Flex>
                        );
                      })}
                  </VStack>
                </Box>
              );
            })}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PrescriptionHelper;
