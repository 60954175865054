import { Center, VStack, Heading, Button, Image } from "@chakra-ui/react";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useTokenStore } from "services/state/tokenStore";
import CrossFailedLargeC from "assets/images/CrossLargeC.svg";

interface PaymentAbortedProps {}

const PaymentAborted: FunctionComponent<PaymentAbortedProps> = ({}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { setAccess, setRefresh } = useTokenStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const access = params.get("access");
    const refresh = params.get("refresh");

    if (access && refresh) {
      setAccess(access);
      setRefresh(refresh);
      history.push("/payment-aborted");
    }
  }, []);

  return (
    <Center bg={"white"} w={"100vw"} flexGrow={1} mb={"-6vh"} pb={"5rem"}>
      <VStack spacing={"0.25rem"} gap={0}>
        <Image src={CrossFailedLargeC} mb={"2rem"} />
        <Heading fontSize={"40px"} fontWeight={500}>
          Betalning avbruten
        </Heading>
        <Button
          variant={"transparent"}
          color={"#589AAF"}
          border={"1px solid #589AAF"}
          fontSize={"stnd"}
          fontWeight={"700"}
          minW={"12.5rem"}
          onClick={() => history.push("/dashboard")}
          _hover={{
            color: "white",
            bg: "blueish.500",
          }}
          mt={"2rem !important"}

        >
          Tillbaka till mina sidor
        </Button>
      </VStack>
    </Center>
  );
};

export default PaymentAborted;
