import { Image, Button, Flex, Heading, Text, Box } from "@chakra-ui/react";
import hero from "assets/images/hero.png";

import down from "assets/icons/down.svg";
import { useTranslation } from "react-i18next";
import { triggerAnnouncementModal } from "features/genericModal";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";

import { HashLink } from "react-router-hash-link";

interface IHome {
  handleClick: () => void;
  handleClickMobile: () => void;
}

const Hero = ({ handleClick, handleClickMobile }: IHome) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <motion.div
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -50 }}
      transition={{ duration: 1, ease: "easeInOut" }}
    >
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        direction={{
          base: "column",
          sm: "row",
        }}
        w={"100%"}
      >
        <Flex
          direction={"column"}
          gap={{ base: "0em", sm: "1em" }}
          p={{ base: "2%", sm: "0%" }}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <Heading
              variant={"light"}
              fontSize={{ base: "40px", md: "60px", lg: "3xl" }}
              lineHeight={"1.3em"}
              width={{ base: "85%", sm: "85%" }}
            >
              {t("heroHeader")}
            </Heading>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <Text
              fontSize={{ base: "16px", md: "20px", lg: "lg" }}
              lineHeight={"1.5em"}
              w={{ base: "100%", sm: "auto", md: "80%" }}
            >
              {t("heroDiscription")}
            </Text>
          </motion.div>
          <Flex
            mt={["1rem", "2.5rem", "2.5rem"]}
            direction={{ base: "column", sm: "row" }}
            gap={["1rem", "0rem", "0rem"]}
          >
            <HashLink to="/#donwloadApp">
              <Button
                display={{ base: "inline-block", sm: "none" }}
                w={"12rem"}
                variant={"filled"}
                minH={"3.25rem"}
                fontSize={"1rem"}
              >
                {t("heroButtonMobile1")}
              </Button>
            </HashLink>

            <Button
              variant={"outline"}
              onClick={() => {
                handleClick();
                handleClickMobile();
              }}
              maxW={"15rem"}
              h={"3.25rem"}
              py={"0.4rem"}
              justifyContent={"space-between"}
              mb={["2.5rem", "0rem", "0rem"]}
              color={"white"}
              fontSize={"16px"}
              paddingLeft={"1rem !important"}
              paddingRight={"0.5rem !important"}
              rightIcon={
                <Image src={down} alt="chevron down icon" maxWidth={"none"} />
              }
            >
              {t("heroButtonMobile2")}
            </Button>
          </Flex>
        </Flex>
        <Image
          src={hero}
          alt="Hero Image"
          mt={"-8rem "}
          mr={{ base: "-10%", sm: "5%" }}
          flex={{ base: "none", sm: "0 0 25%" }}
          w={{ base: "65%", sm: "25%" }}
          minW={{ base: "180px" }}
        />
      </Flex>
    </motion.div>
  );
};

export default Hero;
