import {
  Center,
  Flex,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  useDisclosure,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Text,
  HStack,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import logo from "assets/images/logo.svg";
import burger from "assets/images/hamburger-menu.svg";
import DrawerView from "../DrawerView";

import faq_yellow from "assets/icons/faq_yellow.svg";
import faq from "assets/icons/faq.svg";

import background from "assets/images/background.svg";
import { useHistory } from "react-router-dom";

const NavbarMobile = () => {
  const { i18n } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const history = useHistory();

  return (
    <Flex
      justify="space-between"
      alignItems="center"
      w={"100%"}
      p={"5%"}
      pt={0}
    >
      <Image
        src={logo}
        alt="Lamina clinic logo"
        w={"calc(120px + 9%)"}
        minW={"100px"}
        cursor={"pointer"}
        onClick={() => history.push("/")}
        mb="5px"
      />

      <HStack alignItems="center">
        {history.location.pathname === "/faq" ? (
          <Image src={faq_yellow} mb="5px" />
        ) : (
          <Image src={faq} mb="5px" />
        )}
        <Text
          fontSize="0.75rem"
          fontWeight={700}
          cursor="pointer"
          color={
            history.location.pathname === "/faq"
              ? "rgba(238, 232, 169, 1)"
              : "white"
          }
          onClick={() => history.push("/faq")}
          pr={"2rem"}
        >
          Vanliga frågor
        </Text>
        {/*TODO: uncomment language switch */}
        {/* <Popover arrowShadowColor="transparent">
          <PopoverTrigger>
            <Button variant="ghost" color={"white"} mr={"0.5em"} fontSize={'0.8rem'}>
              {i18n.language.toUpperCase()}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            zIndex={4}
            bg={"#589AAF"}
            width={"80px"}
            border={"none"}
          >
            <PopoverArrow
              backgroundColor={"#589AAF"}
              border="none !important"
            />
            <PopoverBody
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Button
                onClick={() => i18n.changeLanguage("en")}
                variant="ghost"
                color={"white"}
              >
                EN
              </Button>
              <Button
                onClick={() => i18n.changeLanguage("sv")}
                variant="ghost"
                color={"white"}
              >
                SV
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover> */}

        <Center
          backgroundColor={"yellowish.400"}
          borderRadius={"full"}
          h={"2rem"}
          w={"2rem"}
        >
          <Image src={burger} alt="down" onClick={onOpen} />
        </Center>
      </HStack>

      <Drawer isOpen={isOpen} onClose={onClose} size={"full"}>
        <DrawerOverlay />
        <DrawerContent bgImage={background} py={"3%"}>
          <DrawerBody>
            <DrawerView onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default NavbarMobile;
