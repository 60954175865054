import { VStack, Flex, Text, IconButton } from "@chakra-ui/react";
import { invokeConfirmationModal } from "features/genericModal";
import {
  useAssignPersonnel,
  useFetchAllNurses,
} from "features/superadmin/api/client";

import { FunctionComponent } from "react";
import { IoTrash } from "react-icons/io5";
import AssignPersonnelModal from "../AssignPersonnelModal";
import { useAdminUserInfo } from "features/admin/api/client";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface NurseComponentProps {
  ids: string[];
  userId?: string;
}

const NurseComponent: FunctionComponent<NurseComponentProps> = ({
  ids,
  userId,
}) => {
  const { t } = useTranslation();

  const { data: doctorsData } = useFetchAllNurses();
  const location = useLocation();

  const isDoctorPage = location.pathname.startsWith("/doctor");
  const isNursePage = location.pathname.startsWith("/nurse");

  const mainNurse = doctorsData?.nurses.find((e: any) => e.id === ids[0]);
  const secondaryNurse = doctorsData?.nurses.find((e: any) => e.id === ids[1]);

  const { trigger: assignPersonnel } = useAssignPersonnel();
  const { data: patientInfo } = useAdminUserInfo(userId || "");

  const removeNurse = (isMain: boolean) => {
    invokeConfirmationModal(
      t("Are you sure you want to remove this nurse from this patient?"),
      () => {
        const cleanedNurses = (patientInfo?.nurses || []).map((nurse) =>
          nurse === null ? "" : nurse
        );

        const cleanedDoctors = (patientInfo?.doctors || []).map((doctor) =>
          doctor === null ? "" : doctor
        );

        assignPersonnel({
          doctors: cleanedDoctors.filter(Boolean),
          nurses: isMain
            ? ["", cleanedNurses[1]].filter(Boolean)
            : [cleanedNurses[0], ""].filter(Boolean),
          users: [userId],
        });
      },
      `${t("Warning")}`
    );
  };

  return (
    <VStack alignItems={"flex-start"}>
      {mainNurse && (
        <Flex gap={"0.5rem"} alignItems={"baseline"}>
          <Text
            color={"#589AAF"}
            fontWeight={700}
            fontSize={"1rem"}
            textDecor={"underline"}
          >
            {mainNurse.first_name + " " + mainNurse.last_name}
          </Text>
          <Text color={"blueish.900"} opacity={0.5} fontSize={"0.75rem"}>
            {t("main")}
          </Text>

          {isDoctorPage || isNursePage ? (
            <></>
          ) : (
            <IconButton
              variant={"ghost"}
              icon={<IoTrash />}
              marginLeft={"auto"}
              aria-label={""}
              opacity={0.5}
              fontSize={"0.9rem"}
              onClick={() => removeNurse(true)}
            />
          )}
        </Flex>
      )}
      {secondaryNurse && (
        <Flex gap={"0.5rem"} alignItems={"baseline"}>
          <Text
            color={"#589AAF"}
            fontWeight={700}
            fontSize={"1rem"}
            textDecor={"underline"}
          >
            {secondaryNurse.first_name + " " + secondaryNurse.last_name}
          </Text>
          <Text color={"blueish.900"} opacity={0.5} fontSize={"0.75rem"}>
            {t("secondary")}
          </Text>

          {isDoctorPage || isNursePage ? (
            <></>
          ) : (
            <IconButton
              variant={"ghost"}
              icon={<IoTrash />}
              marginLeft={"auto"}
              aria-label={""}
              opacity={0.5}
              fontSize={"0.9rem"}
              onClick={() => removeNurse(false)}
            />
          )}
        </Flex>
      )}
    </VStack>
  );
};

export default NurseComponent;
