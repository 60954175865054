import { Flex, Image, VStack, Heading } from "@chakra-ui/react";
import logo from "assets/icons/blue-logo.svg";

import closeIcon from "assets/icons/closeIcon.svg";
import { Link, useHistory } from "react-router-dom";

type WebinarDrawerProps = {
  onClose: () => void;
};

const WebinarDrawer = ({ onClose }: WebinarDrawerProps) => {
  const history = useHistory();
  return (
    <>
      <Flex
        justify={"space-between"}
        align={"center"}
        w={"100%"}
        p={"2%"}
        pt={0}
      >
        <Image
          src={logo}
          alt="logo"
          w={"calc(150px + 9%)"}
          minW={"100px"}
          onClick={() => history.push("/")}
        />

        <Image src={closeIcon} onClick={onClose} alt={"Close icon"} />
      </Flex>

      <VStack w={"100%"} py={"10rem"} gap={"40px"} px="2rem">
        <Link to="/">
          <Heading color="rgba(0, 38, 55, 1)" fontSize="24px">
            Startsida
          </Heading>
        </Link>
        {/* <Link to="#">
          <Heading
            color="rgba(0, 38, 55, 1)"
            fontSize="24px"
            textAlign="center"
          >
            <Link to="/taper">Hur Nedtrappningen Fungerar</Link>
          </Heading>
        </Link> */}
        <Link to="/faq">
          <Heading color="rgba(0, 38, 55, 1)" fontSize="24px">
            Vanliga frågor
          </Heading>
        </Link>
      </VStack>
    </>
  );
};

export default WebinarDrawer;
