import SvgIconConstituentValues from "./types/svgPropsType";
import "./styles.css";

const QuestionMarkIcon = (props: SvgIconConstituentValues) => (
  <svg
    className="faq-icon"
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0.5C9.82441 0.5 7.69767 1.14514 5.88873 2.35383C4.07979 3.56253 2.66989 5.28049 1.83733 7.29048C1.00477 9.30047 0.786929 11.5122 1.21137 13.646C1.6358 15.7798 2.68345 17.7398 4.22183 19.2782C5.76021 20.8166 7.72022 21.8642 9.85401 22.2886C11.9878 22.7131 14.1995 22.4952 16.2095 21.6627C18.2195 20.8301 19.9375 19.4202 21.1462 17.6113C22.3549 15.8023 23 13.6756 23 11.5C22.9966 8.58367 21.8365 5.78778 19.7744 3.72563C17.7122 1.66347 14.9163 0.50344 12 0.5ZM12 20.5C10.22 20.5 8.47992 19.9722 6.99987 18.9832C5.51983 17.9943 4.36628 16.5887 3.68509 14.9442C3.0039 13.2996 2.82567 11.49 3.17294 9.74419C3.5202 7.99836 4.37737 6.39471 5.63604 5.13604C6.89472 3.87737 8.49836 3.0202 10.2442 2.67293C11.99 2.32567 13.7996 2.5039 15.4442 3.18508C17.0887 3.86627 18.4943 5.01983 19.4832 6.49987C20.4722 7.97991 21 9.71997 21 11.5C20.9971 13.8861 20.0479 16.1735 18.3608 17.8607C16.6736 19.5479 14.3861 20.4971 12 20.5ZM13 16V18H11V16H13ZM16 9C16.0011 9.59973 15.8668 10.192 15.607 10.7325C15.3473 11.2731 14.9689 11.7481 14.5 12.122C13.7426 12.7089 13.2304 13.5565 13.063 14.5H11.031C11.1194 13.729 11.3613 12.9836 11.7424 12.3076C12.1235 11.6316 12.6361 11.0387 13.25 10.564C13.4964 10.3668 13.6926 10.1142 13.8226 9.82668C13.9526 9.53918 14.0128 9.22499 13.9981 8.9098C13.9835 8.5946 13.8945 8.28734 13.7384 8.01312C13.5823 7.7389 13.3636 7.5055 13.1 7.332C12.8063 7.14 12.4683 7.02635 12.1182 7.00184C11.7681 6.97734 11.4176 7.0428 11.1 7.192C10.7612 7.35439 10.4769 7.61172 10.2816 7.93269C10.0863 8.25367 9.98848 8.62446 10 9C10 9.26522 9.89465 9.51957 9.70711 9.70711C9.51958 9.89464 9.26522 10 9 10C8.73479 10 8.48043 9.89464 8.2929 9.70711C8.10536 9.51957 8 9.26522 8 9C7.98513 8.23144 8.19719 7.47555 8.60966 6.82687C9.02213 6.17819 9.6167 5.66553 10.319 5.353C10.9416 5.07478 11.6241 4.9578 12.3038 5.01285C12.9835 5.0679 13.6383 5.29321 14.208 5.668C14.7585 6.03239 15.2102 6.52736 15.5228 7.10874C15.8355 7.69013 15.9994 8.33987 16 9Z"
      fill="#002637"
    />
  </svg>
);

export default QuestionMarkIcon;
