import {
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import logo from "assets/images/logo.svg";
const TestPageNavbar = () => {
  const { t, i18n } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"space-between"}
      w="full"
      mb={"2rem"}
    >
      <Image
        src={logo}
        alt="Lamina Clinic logo"
        w={{
          base: "160px",
          sm: "162px",
          md: "192px",
          lg: "256px",
        }}
        cursor={"pointer"}
      />
      {/*TODO: uncomment language switch */}
      {/* <Popover arrowShadowColor="transparent">
        <PopoverTrigger>
          <Button variant="ghost" color={"white"}>
            {i18n.language.toUpperCase()}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          zIndex={4}
          bg={"#589AAF"}
          width={"80px"}
          border={"none"}
        >
          <PopoverArrow backgroundColor={"#589AAF"} border="none !important" />
          <PopoverBody
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Button
              onClick={() => i18n.changeLanguage("en")}
              variant="ghost"
              color={"white"}
            >
              EN
            </Button>
            <Button
              onClick={() => i18n.changeLanguage("sv")}
              variant="ghost"
              color={"white"}
            >
              SV
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover> */}
    </Flex>
  );
};

export default TestPageNavbar;
