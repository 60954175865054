import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FiFilter } from "react-icons/fi";
import LogFiltersDropdown from "./components/LogFiltersDropdown";
import { BsFiletypeCsv } from "react-icons/bs";

import { useExportCSVFile, useGetAllLogs } from "features/reporting/api/client";
import { ChangeLog } from "features/superadmin/api/types";

import { useState } from "react";
import { LogsFilterTypes } from "features/reporting/api/type";
import TableHeaderRow from "./components/TableHeaderRow";
import { headers } from "./helper";
import Pagination from "features/admin/components/Pagination";

const ReportLogs = () => {
  const { t } = useTranslation();

  const { isOpen, onClose, onToggle } = useDisclosure();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<LogsFilterTypes>({});
  const { data, isLoading } = useGetAllLogs(currentPage, filter);
  const { exportCSV } = useExportCSVFile();

  return (
    <Flex
      bg="white"
      borderRadius=".5rem"
      p="2rem"
      mt="3rem !important"
      direction="column"
    >
      <Heading color="blueish.500" fontWeight={500} fontSize="2rem">
        {t("Reports Logs")}
      </Heading>

      <VStack w={"100%"} alignItems={"flex-end"} mt="5">
        <Button
          variant={isOpen ? "edit" : "black"}
          leftIcon={<FiFilter />}
          minWidth={"none"}
          onClick={onToggle}
          fontSize={"1.25rem"}
          px={"2rem"}
          py={"0.5rem"}
        >
          {t("Filter")}
        </Button>
        <Box w={"100%"}>
          <LogFiltersDropdown
            isOpen={isOpen}
            onClose={onClose}
            filter={filter}
            setFilter={setFilter}
            setCurrentPage={setCurrentPage}
          />
        </Box>
      </VStack>

      <Divider
        borderColor={"rgba(0, 0, 0, 0.2)"}
        mb={"1.5rem !important"}
        mt={"5 !important"}
      />

      <Flex>
        <Button
          p={{ base: "4", sm: "auto" }}
          fontSize={{ base: "1rem", sm: "auto" }}
          variant={"dark"}
          mb={"1rem"}
          leftIcon={<BsFiletypeCsv color={"white"} />}
          isLoading={isLoading}
          onClick={exportCSV}
        >
          {t("Export to CSV")}
        </Button>
      </Flex>

      <Flex direction="column" overflowX={"clip"}>
        <TableContainer>
          <Table
            mt="2.5rem"
            mb="2rem"
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
            style={{ tableLayout: "fixed", width: "100%" }}
          >
            <Thead bg="rgba(155, 194, 207, 0.1)">
              <TableHeaderRow headers={headers} />
            </Thead>

            <Tbody>
              {isLoading ? (
                <Tr>
                  <Td colSpan={5}>{t("Loading")}...</Td>
                </Tr>
              ) : data?.results && data.results.length > 0 ? (
                data?.results.map((column: ChangeLog, index) => (
                  <Tr key={index}>
                    <Td w="150px">
                      {new Date(column.created_at)
                        .toLocaleDateString("en-GB", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })
                        .replace(/\//g, ".")}
                    </Td>
                    <Td w="150px">{column.doctor?.role}</Td>
                    <Td w="200px">{column.doctor?.name}</Td>
                    <Td w="150px">{column.user?.name}</Td>
                    <Td w={{ base: "200px", sm: "150px" }}>
                      {column.action_type === "call" && column.call_duration
                        ? `call (duration ${column.call_duration.toString()})`
                        : column.action_type}
                    </Td>
                    <Td w={{ base: "200px", sm: "150px" }}>
                      {column.call_duration
                        ? column.call_duration.toString()
                        : ""}
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={5}>{t("No data")}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          <Flex
            justifyContent={{ base: "center", sm: "flex-end" }}
            alignContent="flex-end"
          >
            <Pagination
              pageCount={Math.ceil((data?.count ?? 0) / 10)}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          </Flex>
        </TableContainer>
      </Flex>
    </Flex>
  );
};

export default ReportLogs;
