import {
  Avatar,
  Box,
  Flex,
  Input,
  InputGroup,
  Stack,
  VStack,
  Text,
  Divider,
  SimpleGrid,
  Button,
  Image,
  Heading,
} from "@chakra-ui/react";
import {
  useFetchProfileInfo,
  usePatchAdminUserProfile,
} from "features/dashboard/api/client";
import { EditFormType } from "features/editProfile/api/types";
import { useFormik } from "formik";
import { FunctionComponent, useEffect } from "react";
import DoctorOutline from "assets/images/doctor_outline_colored.png";
import NurseOutline from "assets/images/nurse_outline_colored.png";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useUpdateStaffProfile } from "features/editProfile/api/client";
import { useHistory } from "react-router-dom";
import logo from "assets/images/logo.svg";
import { invokeBasicInfoModal } from "features/genericModal";
import { editValidationSchema } from "features/editProfile/api/form";

interface EditFormProps {}

const EditForm: FunctionComponent<EditFormProps> = () => {
  const { t } = useTranslation();
  const { data: profileData } = useFetchProfileInfo();
  const { trigger: updateDataRequest, isMutating: isLoading } =
    useUpdateStaffProfile();
  const history = useHistory();

  const formik = useFormik<EditFormType>({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      pharmacy_code: null,
      medical_code: null,
      hospital_name: null,
      hospital_address: null,
    },
    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      updateDataRequest(values, {
        onSuccess: () => {
          invokeBasicInfoModal("Profile updated");
        },
      });
    },
  });

  useEffect(() => {
    formik.setValues({
      email: profileData?.email ?? "",
      first_name: profileData?.first_name ?? "",
      hospital_address: profileData?.hospital_address ?? "",
      hospital_name: profileData?.hospital_name ?? "",
      last_name: profileData?.last_name ?? "",
      medical_code: profileData?.medical_code ?? "",
      pharmacy_code: profileData?.pharmacy_code ?? "",
    });
  }, [profileData]);

  return (
    <VStack
      alignItems={"stretch"}
      w={"100%"}
      pt={"3rem"}
      px={"4rem"}
      pb={"4rem"}
    >
      <Box
        bg={"white"}
        py={"2rem"}
        borderRadius={"1rem"}
        boxShadow={"0px 0px 8px 8px rgba(0, 0, 0, 0.02)"}
      >
        <Flex
          h={"5rem"}
          py={"1rem"}
          bg={"#589AAF"}
          w={"100%"}
          mb={"2rem"}
          px={"2rem"}
          alignItems={"center"}
          gap={"3rem"}
        >
          <Image src={logo} h={"100%"} />

          <Heading
            color={"white"}
            fontSize={"1.6rem"}
            fontWeight={800}
            textTransform={"capitalize"}
            fontFamily={"main"}
          >
            {t(profileData?.role ?? "")} {t("profile editor")}
          </Heading>

          <Flex
            alignItems={"center"}
            marginLeft={"auto"}
            justifySelf={"flex-end"}
            alignSelf={"flex-start"}
          >
            <Button
              onClick={() => {
                formik.submitForm();
              }}
              bg={"white"}
              border={"none"}
              minWidth={"0"}
              color={"#589AAF"}
              px={"1.5rem"}
              py={"0.65rem"}
              fontSize={"1em"}
              isLoading={isLoading}
            >
              {t("Save changes")}
            </Button>

            <Button
              onClick={() => {
                history.goBack();
              }}
              variant={"transparent"}
              color={"white"}
              fontSize={"1rem"}
            >
              {t("Cancel")}
            </Button>
          </Flex>
        </Flex>
        <Box px={"2rem"}>
          <Flex w={"100%"} gap={"3rem"}>
            <VStack>
              <Avatar
                h={"8rem"}
                w={"8rem"}
                bg={"#96BFCC"}
                src={
                  profileData?.role === "doctor" ? DoctorOutline : NurseOutline
                }
              />
              <Text
                color={"blueish.900"}
                fontFamily={"secondary"}
                opacity={0.5}
                fontWeight={400}
                fontSize={"1rem"}
              >
                {t("Role")}: <b>{t(profileData?.role ?? "")}</b>
              </Text>
            </VStack>

            <Stack gap={"1rem"}>
              <Box>
                <Text color={"blueish.900"} opacity={"0.7"} fontSize={"1rem"}>
                  {t("First name")}:
                </Text>
                <InputGroup>
                  <Input
                    fontSize={"1rem"}
                    h={"2rem"}
                    py={"0 !important"}
                    px={"0 !important"}
                    minHeight={"none"}
                    bg={"transparent !important"}
                    name={"first_name"}
                    borderRadius={"none"}
                    borderBottom={"1px solid #00263720"}
                    w={"20rem"}
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                  />
                </InputGroup>
                <Text color={"#D5738A"} h={"0"} fontSize={"xs"}>
                  {formik.errors.first_name}
                </Text>
              </Box>

              <Box>
                <Text color={"blueish.900"} opacity={"0.7"} fontSize={"1rem"}>
                  {t("Last name")}:
                </Text>
                <InputGroup>
                  <Input
                    fontSize={"1rem"}
                    h={"2rem"}
                    py={"0 !important"}
                    px={"0 !important"}
                    minHeight={"none"}
                    bg={"transparent !important"}
                    borderRadius={"none"}
                    borderBottom={"1px solid #00263720"}
                    w={"20rem"}
                    name={"last_name"}
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                  />
                </InputGroup>
                <Text color={"#D5738A"} h={"0"} fontSize={"xs"}>
                  {formik.errors.last_name}
                </Text>
              </Box>

              <Box>
                <Text color={"blueish.900"} opacity={"0.7"} fontSize={"1rem"}>
                  {t("Real last name")}:
                </Text>
                <InputGroup>
                  <Input
                    fontSize={"1rem"}
                    h={"2rem"}
                    py={"0 !important"}
                    px={"0 !important"}
                    minHeight={"none"}
                    bg={"transparent !important"}
                    borderRadius={"none"}
                    borderBottom={"1px solid #00263720"}
                    w={"20rem"}
                    isReadOnly={true}
                    value={profileData?.real_last_name}
                  />
                </InputGroup>
              </Box>
            </Stack>
          </Flex>

          <Divider my={"3rem"} borderColor={"#0026377F"} />

          <SimpleGrid columns={2} gap={"3rem"} rowGap={"2rem"}>
            <Box>
              <Text color={"blueish.900"} opacity={"0.7"} fontSize={"1rem"}>
                {t("Username")}:
              </Text>
              <InputGroup>
                <Input
                  fontSize={"1rem"}
                  h={"2rem"}
                  py={"0 !important"}
                  px={"0 !important"}
                  minHeight={"none"}
                  bg={"transparent !important"}
                  borderRadius={"none"}
                  borderBottom={"1px solid #00263720"}
                  w={"100%"}
                  value={profileData?.username}
                  isReadOnly={true}
                />
              </InputGroup>
            </Box>

            <Box>
              <Text color={"blueish.900"} opacity={"0.7"} fontSize={"1rem"}>
                {t("Email")}:
              </Text>
              <InputGroup>
                <Input
                  fontSize={"1rem"}
                  h={"2rem"}
                  py={"0 !important"}
                  px={"0 !important"}
                  minHeight={"none"}
                  bg={"transparent !important"}
                  borderRadius={"none"}
                  borderBottom={"1px solid #00263720"}
                  w={"100%"}
                  name={"email"}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </InputGroup>
              <Text color={"#D5738A"} h={"0"} fontSize={"xs"}>
                {formik.errors.email}
              </Text>
            </Box>

            <Box>
              <Text color={"blueish.900"} opacity={"0.7"} fontSize={"1rem"}>
                {t("Pharmacy code")}:
              </Text>
              <InputGroup>
                <Input
                  fontSize={"1rem"}
                  h={"2rem"}
                  py={"0 !important"}
                  px={"0 !important"}
                  minHeight={"none"}
                  bg={"transparent !important"}
                  borderRadius={"none"}
                  borderBottom={"1px solid #00263720"}
                  w={"100%"}
                  name={"pharmacy_code"}
                  onChange={formik.handleChange}
                  value={formik.values.pharmacy_code ?? ""}
                />
              </InputGroup>
              <Text color={"#D5738A"} h={"0"} fontSize={"xs"}>
                {formik.errors.pharmacy_code}
              </Text>
            </Box>

            <Box>
              <Text color={"blueish.900"} opacity={"0.7"} fontSize={"1rem"}>
                {t("Medical code")}:
              </Text>
              <InputGroup>
                <Input
                  fontSize={"1rem"}
                  h={"2rem"}
                  py={"0 !important"}
                  px={"0 !important"}
                  minHeight={"none"}
                  bg={"transparent !important"}
                  borderRadius={"none"}
                  borderBottom={"1px solid #00263720"}
                  w={"100%"}
                  name={"medical_code"}
                  onChange={formik.handleChange}
                  value={formik.values.medical_code ?? ""}
                />
              </InputGroup>
              <Text color={"#D5738A"} h={"0"} fontSize={"xs"}>
                {formik.errors.medical_code}
              </Text>
            </Box>

            <Divider
              borderColor={"#0026377F"}
              gridColumn={"1 / 3"}
              my={"1rem"}
            />

            <Box>
              <Text color={"blueish.900"} opacity={"0.7"} fontSize={"1rem"}>
                {t("Hospital name")}:
              </Text>
              <InputGroup>
                <Input
                  fontSize={"1rem"}
                  h={"2rem"}
                  py={"0 !important"}
                  px={"0 !important"}
                  minHeight={"none"}
                  bg={"transparent !important"}
                  borderRadius={"none"}
                  borderBottom={"1px solid #00263720"}
                  w={"100%"}
                  name={"hospital_name"}
                  onChange={formik.handleChange}
                  value={formik.values?.hospital_name ?? ""}
                />
              </InputGroup>
              <Text color={"#D5738A"} h={"0"} fontSize={"xs"}>
                {formik.errors.hospital_name}
              </Text>
            </Box>

            <Box gridColumn={"1 / 3"}>
              <Text color={"blueish.900"} opacity={"0.7"} fontSize={"1rem"}>
                {t("Hospital address")}:
              </Text>
              <InputGroup>
                <Input
                  fontSize={"1rem"}
                  h={"2rem"}
                  py={"0 !important"}
                  px={"0 !important"}
                  minHeight={"none"}
                  bg={"transparent !important"}
                  borderRadius={"none"}
                  borderBottom={"1px solid #00263720"}
                  w={"100%"}
                  name={"hospital_address"}
                  onChange={formik.handleChange}
                  value={formik.values?.hospital_address ?? ""}
                />
              </InputGroup>
              <Text color={"#D5738A"} h={"0"} fontSize={"xs"}>
                {formik.errors.hospital_address}
              </Text>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </VStack>
  );
};

export default EditForm;
