import { useFetchProfileInfo } from "features/dashboard/api/client";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  setDoc,
  getDoc,
  updateDoc,
  writeBatch,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { db } from "services/firebase/config";
import { useCallUserAPI, useEndCallAPI } from "../api/client";
import { FBCallType, FBUserRoomType, InternalCallType } from "../api/types";
import { useMyCalls } from "./useMyCalls";
import { invokeBasicInfoModal } from "features/genericModal";

const configuration: RTCConfiguration = {
  iceServers: [
    {
      urls: [
        "stun:stun1.l.google.com:19302",
        "stun:stun2.l.google.com:19302",
        "stun:stun.l.google.com:19302",
        "stun:stun3.l.google.com:19302",
        "stun:stun4.l.google.com:19302",
        "stun:stun.relay.metered.ca:80",
      ],
    },
    {
      urls: "turn:standard.relay.metered.ca:80",
      username: "70381747eaf90174134f7b3e",
      credential: "Olja2k7tEW5hzQX/",
    },
    {
      urls: "turn:standard.relay.metered.ca:80?transport=tcp",
      username: "70381747eaf90174134f7b3e",
      credential: "Olja2k7tEW5hzQX/",
    },
    {
      urls: "turn:standard.relay.metered.ca:443",
      username: "70381747eaf90174134f7b3e",
      credential: "Olja2k7tEW5hzQX/",
    },
    {
      urls: "turns:standard.relay.metered.ca:443?transport=tcp",
      username: "70381747eaf90174134f7b3e",
      credential: "Olja2k7tEW5hzQX/",
    },
  ],
};

export const useCalls = () => {
  const { data: profileData } = useFetchProfileInfo();
  const { incomingCalls } = useMyCalls();

  const [permissions, setPermissions] = useState<("audio" | "video")[]>([]);

  //API
  const [dbEntryId, setDbEntryId] = useState<string>();
  const { trigger: logInitCallRequest } = useCallUserAPI();
  const { trigger: logEndCallRequest } = useEndCallAPI(dbEntryId ?? "");

  const [currentCall, setCurrentCall] = useState<FBUserRoomType>();
  const [stream, setStream] = useState<MediaStream>();

  const myVideo = useRef<any>(null);
  const userVideo = useRef<any>(null);
  const connectionRef = useRef<any>(null);

  const [isVideoEnabled, setIsVideoEnabled] = useState<boolean>(true);
  const [isAudioEnabled, setIsAudioEnabled] = useState<boolean>(true);

  const registerPeerConnectionListeners = (peerConnection: any) => {
    peerConnection.addEventListener("icegatheringstatechange", () => {
      console.log(
        `ICE gathering state changed: ${peerConnection.iceGatheringState}`
      );
    });

    peerConnection.addEventListener("connectionstatechange", () => {
      console.log(`Connection state change: ${peerConnection.connectionState}`);
      debugger;

      //failed here
    });

    peerConnection.addEventListener("signalingstatechange", () => {
      console.log(`Signaling state change: ${peerConnection.signalingState}`);
    });

    peerConnection.addEventListener("iceconnectionstatechange ", () => {
      console.log(
        `ICE connection state change: ${peerConnection.iceConnectionState}`
      );
    });
  };

  const connectInputDevices = async () => {
    try {
      const currentStream = await navigator?.mediaDevices?.getUserMedia({
        video: true,
        audio: true, //TODO: return the audio, disabled because i couldn't listen to scooter fire
      });

      if (myVideo.current) {
        myVideo.current.srcObject = currentStream;
      }
      setPermissions(["video", "audio"]);
      return currentStream;
    } catch (error) {
      invokeBasicInfoModal(
        "In order to make a call you need to grant website necessary permissions"
      );
      console.error(
        "🚀 ~ file: useCalls.ts:44 ~ connectInputDevices ~ error:",
        error
      );
    }
  };

  const disconnectInputDevices = () => {
    myVideo.current?.srcObject?.getTracks().forEach((track: any) => {
      track.stop();
    });
    if (myVideo?.current?.srcObject) myVideo.current.srcObject = undefined;
    //setStream(undefined);
  };

  const createRoom = async () => {
    const localStream = await connectInputDevices();
    const roomRef = await addDoc(collection(db, "rooms"), {});
    userVideo.current.srcObject = new MediaStream();

    setStream(localStream);

    console.log("Create PeerConnection with configuration: ", configuration);
    const peerConnection = new RTCPeerConnection(configuration);

    registerPeerConnectionListeners(peerConnection);

    // Add code for creating a room here

    // Code for creating room above

    localStream?.getTracks().forEach((track) => {
      peerConnection.addTrack(track, localStream);
    });

    // Code for collecting ICE candidates below

    const callerCandidatesCollection = await collection(roomRef, "candidates");
    // Code for collecting ICE candidates above

    // Code for creating a room below
    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(offer);
    console.log("Created offer:", offer);

    const roomWithOffer = {
      offer: {
        type: offer.type,
        sdp: offer.sdp,
      },
    };

    updateDoc(roomRef, roomWithOffer);

    const roomId = roomRef.id;

    console.log(`New room created with SDP offer. Room ID: ${roomRef.id}`);

    // Code for creating a room above

    peerConnection.addEventListener("icecandidate", (event) => {
      if (!event.candidate) {
        console.log("Got final candidate!");
        return;
      }
      console.log("Got candidate: ", event.candidate);
      const testDoc = addDoc(
        callerCandidatesCollection,
        event.candidate.toJSON()
      );
    });

    peerConnection.addEventListener("track", (event) => {
      console.log("😏🎥 Got remote track:", event.streams[0]);
      if (userVideo.current) {
        userVideo.current.srcObject = event.streams[0];
      }
      // event.streams[0].getTracks().forEach((track) => {
      //   console.log("Add a track to the remoteStream:", track);
      //   userVideo.current.addTrack(track);
      // });
    });

    onSnapshot(roomRef, async (snapshot) => {
      const data = snapshot.data();
      if (!peerConnection.currentRemoteDescription && data && data.answer) {
        console.log("Got remote description: ", data.answer);
        const rtcSessionDescription = new RTCSessionDescription(data.answer);
        await peerConnection.setRemoteDescription(rtcSessionDescription);
      }
    });

    onSnapshot(callerCandidatesCollection, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === "added") {
          let data = change.doc.data();
          console.log(`Got new remote ICE candidate: ${JSON.stringify(data)}`);

          const candidate = new RTCIceCandidate(data);

          await peerConnection.addIceCandidate(candidate);
        }
      });
    });

    connectionRef.current = peerConnection;

    return roomId;
  };

  const callUser = async (id: string) => {
    const roomId = await createRoom();

    try {
      const callData = {
        from: profileData?.id ?? "",
        to: id,
        status: "requesting",
        roomId: roomId,
      };

      const res = await addDoc(collection(db, "userRoomPool"), callData);

      setCurrentCall({ id: res.id, ...callData });
    } catch (error) {
      console.log("🚀 ~ file: useCalls.ts:194 ~ callUser ~ error:", error);
    }
  };

  const declineCall = async () => {
    try {
      await updateDoc(doc(db, "userRoomPool", currentCall?.id ?? ""), {
        status: "declined",
      });
      setCurrentCall(undefined);
      disconnectInputDevices();
    } catch (error) {
      console.error("🚀 ~ file: useCalls.ts:93 ~ declineCall ~ error:", error);
    }
  };

  const joinRoom = async (roomId: string) => {
    const roomRef = doc(db, "rooms", roomId);
    const roomSnapshot = await getDoc(roomRef);
    console.log("Got room:", roomSnapshot?.exists);

    const localStream = await connectInputDevices();
    userVideo.current.srcObject = new MediaStream();

    if (roomSnapshot) {
      console.log("Create PeerConnection with configuration: ", configuration);
      let peerConnection = new RTCPeerConnection(configuration);
      registerPeerConnectionListeners(peerConnection);
      localStream?.getTracks().forEach((track) => {
        peerConnection.addTrack(track, localStream);
      });

      // Code for collecting ICE candidates below
      const calleeCandidatesCollection = collection(roomRef, "candidates");
      peerConnection.addEventListener("icecandidate", (event) => {
        if (!event.candidate) {
          console.log("Got final candidate!");
          return;
        }
        console.log("Got candidate: ", event.candidate);
        addDoc(calleeCandidatesCollection, event.candidate.toJSON());
      });
      // Code for collecting ICE candidates above

      peerConnection.addEventListener("track", (event) => {
        console.log("Got remote track:", event.streams[0]);

        userVideo.current.srcObject = event.streams[0];
      });

      // Code for creating SDP answer below
      const offer = roomSnapshot.data()?.offer;
      console.log("Got offer:", offer);
      await peerConnection.setRemoteDescription(
        new RTCSessionDescription(offer)
      );
      const answer = await peerConnection.createAnswer();
      console.log("Created answer:", answer);
      await peerConnection.setLocalDescription(answer);

      const roomWithAnswer = {
        answer: {
          type: answer.type,
          sdp: answer.sdp,
        },
      };

      await updateDoc(roomRef, roomWithAnswer);
      // Code for creating SDP answer above

      // Listening for remote ICE candidates below
      onSnapshot(collection(roomRef, "candidates"), (snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          if (change.type === "added") {
            let data = change.doc.data();
            console.log(
              `Got new remote ICE candidate: ${JSON.stringify(data)}`
            );

            const candidate = new RTCIceCandidate(data);

            await peerConnection.addIceCandidate(candidate);
          }
        });
      });
      // Listening for remote ICE candidates above
      connectionRef.current = peerConnection;
    }
  };

  const answerCall = async () => {
    if (currentCall) {
      joinRoom(currentCall?.roomId ?? "");
      await updateDoc(doc(db, "userRoomPool", currentCall?.id ?? ""), {
        status: "ongoing",
      });

      setCurrentCall({ ...currentCall, status: "ongoing" });
    }
  };

  const endCall = async (automaticEnd?: boolean) => {
    disconnectInputDevices();

    if (!automaticEnd) {
      await updateDoc(doc(db, "userRoomPool", currentCall?.id ?? ""), {
        status: "ended",
      });
      setCurrentCall(undefined);
    }

    if (currentCall?.roomId) {
      const roomRef = doc(db, "rooms", currentCall?.roomId);
      const calleeCandidates = await getDocs(collection(roomRef, "candidates"));
      calleeCandidates.forEach(async (candidate) => {
        deleteDoc(candidate.ref);
      });

      await deleteDoc(roomRef);
    }

    if (userVideo) {
      userVideo.current.srcObject
        .getTracks()
        .forEach((track: any) => track.stop());
    }

    if (connectionRef.current) {
      connectionRef.current.close();
    }

    if (!automaticEnd) {
      await updateDoc(doc(db, "userRoomPool", currentCall?.id ?? ""), {
        status: "ended",
      });
      setCurrentCall(undefined);
    }
    connectionRef.current.destroy();
    disconnectInputDevices();
  };

  const endCallWithCustomStatus = (customStatus: string) => {
    updateDoc(doc(db, "calls", currentCall?.id ?? ""), {
      status: customStatus,
    });

    connectionRef.current.destroy();
    disconnectInputDevices();
  };

  const enableMic = () => {
    const audioTrack = myVideo.current?.srcObject?.getAudioTracks()[0];

    if (audioTrack) audioTrack.enabled = true;

    setIsAudioEnabled(true);
  };

  const disableMic = () => {
    const audioTrack = myVideo.current?.srcObject?.getAudioTracks()[0];

    if (audioTrack) audioTrack.enabled = false;

    setIsAudioEnabled(false);
  };

  const enableVideo = () => {
    const videoTrack = myVideo.current.srcObject?.getVideoTracks()[0];

    if (videoTrack) videoTrack.enabled = true;

    setIsVideoEnabled(true);
  };

  const disableVideo = () => {
    const videoTrack = myVideo.current?.srcObject?.getVideoTracks()[0];

    if (videoTrack) videoTrack.enabled = false;

    setIsVideoEnabled(false);
  };

  const programaticInputDisable = () => {
    disableMic();
    disableVideo();
  };

  useEffect(() => {
    if (userVideo.current) {
      userVideo.current.srcObject = new MediaStream();
    }

    if (myVideo.current?.srcObject) {
      myVideo.current.srcObject = new MediaStream();
    }
  }, []);

  // useEffect(() => {
  //   if (currentCall) {
  //     //adding a subscription for changes on the current call
  //     const unsubFromCallInfo = onSnapshot(
  //       doc(db, "calls", currentCall.callId),
  //       (doc) => {
  //         const extractedData = doc.data() as FBCallType;

  //         //if the call is from me and its status changed to "accepted"
  //         //set signal from other user for my Peer object

  //         if (extractedData.status === "ongoing") {
  //           setCallAccepted(true);
  //           setCurrentCall({
  //             ...currentCall,
  //             ...extractedData,
  //             status: "ongoing",
  //           });
  //           connectionRef.current.signal(extractedData.theirSignal);
  //         }

  //         // if (extractedData.status === "ongoing") {
  //         //   setCurrentCall({ ...currentCall, status: "ongoing" });
  //         // }

  //         if (
  //           extractedData.status === "declined" &&
  //           extractedData.from === profileData?.id
  //         ) {
  //           setCurrentCall({ ...currentCall, status: "declined" });
  //         }

  //         if (extractedData.status === "ended") {
  //           //if a call was ended by other user or me
  //           //clear internal call object
  //           setCurrentCall(undefined);
  //         }

  //         if (extractedData.status === "missed") {
  //           //if a call was ended by other user or me
  //           //clear internal call object
  //           setCurrentCall(undefined);
  //         }
  //       }
  //     );

  //     return () => {
  //       unsubFromCallInfo();
  //     };
  //   }
  // }, [currentCall?.callId]);

  // //listener for incoming calls
  // useEffect(() => {
  //   //if there's an incoming or ongoing call already
  //   //set all new incoming calls as missed
  //   //otherwise make this call the incoming one

  //   if (currentCall) {
  //     const incomingBatch = writeBatch(db);
  //     incomingCalls
  //       .filter((incomingCall) => incomingCall.callId !== currentCall.callId)
  //       .forEach((incomingCall) => {
  //         incomingBatch.update(doc(db, "calls", incomingCall.callId), {
  //           status: "missed",
  //         });
  //       });
  //     incomingBatch.commit();
  //   } else {
  //     setCurrentCall(incomingCalls[0]);
  //   }
  // }, [incomingCalls]);

  useEffect(() => {
    let unsubscribe: any;
    if (currentCall) {
      unsubscribe = onSnapshot(
        doc(db, "userRoomPool", currentCall.id),
        (snapshot) => {
          const fbCurrentCallData = snapshot.data();
          if (fbCurrentCallData) {
            if (fbCurrentCallData?.status === "declined") {
              setCurrentCall({ ...currentCall, status: "declined" });
              setTimeout(() => {
                logEndCallRequest({
                  call_from: fbCurrentCallData.from ?? "",
                  call_to: fbCurrentCallData.to ?? "",
                });

                setCurrentCall(undefined);
                setDbEntryId(undefined);
                disconnectInputDevices();
              }, 5000);
            } else if (fbCurrentCallData?.status === "ended") {
              setCurrentCall(undefined);

              logEndCallRequest({
                call_from: fbCurrentCallData.from ?? "",
                call_to: fbCurrentCallData.to ?? "",
              });
              setDbEntryId(undefined);
              disconnectInputDevices();
            } else {
              setCurrentCall({ ...currentCall, ...fbCurrentCallData });
            }
          } else {
            setCurrentCall(undefined);
          }
        }
      );
    }

    // return () => {
    //   if (unsubscribe) {
    //     unsubscribe();
    //   }
    // };
  }, [currentCall?.id]);

  useEffect(() => {
    if (!currentCall) {
      //programaticInputDisable();
      disconnectInputDevices();
    }
  }, [currentCall]);

  useEffect(() => {
    if (currentCall) {
      debugger;
      logInitCallRequest(
        {
          call_from: currentCall?.from ?? "",
          call_to: currentCall?.to ?? "",
        },
        {
          onSuccess: (response) => {
            setDbEntryId(response.id);
          },
        }
      );
    }
  }, [currentCall?.id]);

  useEffect(() => {
    if (!currentCall) {
      //for reading one single calla and rejecting the rest
      const selectedCall = incomingCalls[0];

      setCurrentCall(selectedCall);

      const incomingBatch = writeBatch(db);

      incomingCalls
        .filter((incomingCall) => incomingCall.id !== selectedCall.id)
        .forEach((incomingCall) => {
          incomingBatch.update(doc(db, "userRoomPool", incomingCall.id), {
            status: "missed",
          });
        });
      incomingBatch.commit();
    }
  }, [incomingCalls]);

  return {
    currentCall,
    isCallOngoing: currentCall?.status === "ongoing",
    isCallIncoming:
      currentCall?.status === "requesting" &&
      currentCall.from !== profileData?.id,
    isCalling:
      currentCall !== undefined &&
      currentCall?.from === profileData?.id &&
      currentCall?.status === "requesting",
    stream,
    callUser,
    declineCall,
    endCall,
    endCallWithCustomStatus,
    answerCall,
    myVideo,
    userVideo,
    videoEnabled: isVideoEnabled,
    audioEnabled: isAudioEnabled,
    enableMic,
    enableVideo,
    disableMic,
    disableVideo,
    permissions,
  };
};
