import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import { MdOutlineClear } from "react-icons/md";
import { IoCalendarClearOutline } from "react-icons/io5";

import {
  useFetchAvailableMeetingTypes,
  useFetchAvailableMeetingTypesByPersonnelId,
} from "features/doctors/api/client";
import {
  useCreateMeeting,
  useFetchCarePersonel,
  useFetchUsersForMeeting,
  useSendPatientMeeting,
} from "features/staffCommons/api/client";
import { Select } from "chakra-react-select";
import { useFormik } from "formik";
import { UserMeetingRequest } from "features/staffCommons/api/types";
import { customSelectStyles } from "./selectStyles";
import { useEffect, useMemo, useState } from "react";
import CalendlyPopup from "../CalendlyPopup";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useGetMeetingServicesByPersonel } from "features/superadmin/api/client";

type Option = {
  label: string;
  value: string;
};

const MeetingLogWidget = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const formik = useFormik<UserMeetingRequest>({
    initialValues: {
      user_id: "",
      doctor_id: "",
      service_id: "",
    },
    onSubmit: (data) => {
      getCalendlyLink(data, {
        onSuccess: (response) => {
          setCalendlyLink((response as any).link);
          onCalendlyOpen();
        },
        onError: () => {
          toast.error(
            t(
              "Selected doctor/nurse hasn't registered their calendly account, please contact administrator and let them know about this issue"
            )
          );
        },
      });
    },
  });

  const { trigger: getCalendlyLink, isMutating: isGettingLink } =
    useCreateMeeting();

  const { data: usersForMeeting } = useFetchUsersForMeeting();
  const { data: meetingTypes, isLoading: isFetchingMeetingTypes } =
    useGetMeetingServicesByPersonel(formik.values.doctor_id);

  const {
    isOpen: isCalendlyOpen,
    onClose: onCalendlyClose,
    onOpen: onCalendlyOpen,
  } = useDisclosure();

  const [calendlyLink, setCalendlyLink] = useState<string>();

  const { data: carePersonel } = useFetchCarePersonel(
    formik.values.user_id ?? ""
  );

  const usersForMeetingOptions =
    (usersForMeeting?.users.map((usersOptions) => ({
      label: `${usersOptions.first_name} ${usersOptions.last_name}`,
      value: usersOptions.id,
    })) as Option[]) || [];

  const meetingOptions: Option[] =
    (meetingTypes?.service.map((meetingType) => ({
      label: meetingType.name.replaceAll("_", " "),
      value: meetingType.id,
    })) as Option[]) || [];

  const personelSelectOptions: Option[] = useMemo(() => {
    return (
      (carePersonel?.care_personal.map((personel) => ({
        label: `${personel.first_name} ${personel.last_name} (${t(
          personel.role
        )})`,
        value: personel.id,
      })) as Option[]) || []
    );
  }, [carePersonel?.care_personal, t]);

  return (
    <Flex
      w="full"
      bg={"white"}
      borderRadius={"0.75rem"}
      py={"1rem"}
      px="2rem"
      direction="column"
      gap={3}
    >
      <Heading color="blueish.500" fontWeight={600}>
        {t("SCHEDULE PATIENT MEETING")}
      </Heading>

      <form onSubmit={formik.handleSubmit}>
        <Flex direction="column" gap={7}>
          <Box mt="1rem">
            <Text
              color="black"
              fontSize="1rem"
              fontFamily="secondary"
              fontWeight={600}
              textTransform="uppercase"
            >
              patient
            </Text>
            <Select<Option>
              isSearchable={false}
              options={usersForMeetingOptions}
              placeholder={t("Please select")}
              size={{ base: "md", md: "lg", lg: "sm" }}
              name="user_id"
              value={
                typeof formik.values.user_id === "object"
                  ? formik.values.user_id
                  : undefined
              }
              onChange={(selectedOption) => {
                if (selectedOption && typeof selectedOption !== "string") {
                  formik.setFieldValue(
                    "user_id",
                    (selectedOption as Option).value
                  );
                }
              }}
              chakraStyles={customSelectStyles}
            />
          </Box>

          <Box>
            <Text
              color="black"
              fontSize="1rem"
              fontFamily="secondary"
              fontWeight={600}
              textTransform="uppercase"
            >
              Vårdpersonal
            </Text>
            <Select<Option>
              isSearchable={false}
              options={personelSelectOptions}
              placeholder={t("Please select")}
              size={{ base: "md", md: "lg", lg: "sm" }}
              name="doctor_id"
              value={
                typeof formik.values.doctor_id === "object"
                  ? formik.values.doctor_id
                  : undefined
              }
              onChange={(selectedOption) => {
                if (selectedOption && typeof selectedOption !== "string") {
                  formik.setFieldValue(
                    "doctor_id",
                    (selectedOption as Option).value
                  );
                }
              }}
              chakraStyles={customSelectStyles}
            />
          </Box>

          <Box>
            <Text
              color="black"
              fontSize="1rem"
              fontFamily="secondary"
              fontWeight={600}
              textTransform="uppercase"
            >
              Mötestyp
            </Text>
            <Select<Option>
              isSearchable={false}
              isLoading={isFetchingMeetingTypes}
              options={meetingOptions}
              placeholder={t("Please select")}
              size={{ base: "md", md: "lg", lg: "sm" }}
              name="service_id"
              value={
                typeof formik.values.service_id === "object"
                  ? formik.values.service_id
                  : undefined
              }
              onChange={(selectedOption) => {
                if (selectedOption && typeof selectedOption !== "string") {
                  formik.setFieldValue(
                    "service_id",
                    (selectedOption as Option).value
                  );
                }
              }}
              chakraStyles={customSelectStyles}
            />
          </Box>
          <Flex w="full" justify="flex-end" gap={3}>
            <Button
              variant="adjust"
              leftIcon={<MdOutlineClear size="1.5rem" />}
              fontWeight={600}
              onClick={() => {
                formik.setFieldValue("user_id", null);
                formik.setFieldValue("doctor_id", null);
                formik.setFieldValue("meeting_type_id", null);
              }}
            >
              Clear
            </Button>
            <Button
              type="submit"
              variant="filledBlue"
              leftIcon={<IoCalendarClearOutline />}
              fontWeight={500}
              isLoading={isGettingLink}
              disabled={
                !formik.values.user_id ||
                !formik.values.doctor_id ||
                !formik.values.service_id
              }
            >
              Set Date & Time
            </Button>
          </Flex>
        </Flex>
      </form>
      <CalendlyPopup
        isOpen={isCalendlyOpen}
        onClose={onCalendlyClose}
        link={calendlyLink}
        meetingInfo={{
          type_name: meetingOptions.find(
            (e) => e.value === formik.values.service_id
          )?.label,
          patientId: formik.values.user_id,
        }}
        patientId={id}
      />
    </Flex>
  );
};

export default MeetingLogWidget;
