import { Box, Flex } from "@chakra-ui/react";
import { DateRangeType } from "features/userInfo/api/type";
import { FunctionComponent, useState } from "react";

type FreeFormSelector = 0 | 1 | 3;

type ChartPeriodToggleProps = {
  dateRange: DateRangeType;
  setDateRange: (newDateRange: DateRangeType) => void;
};

const ChartPeriodToggle: FunctionComponent<ChartPeriodToggleProps> = ({
  dateRange,
  setDateRange,
}) => {
  const months1time = 30 * 24 * 60 * 60 * 1000;

  const months3time = 3 * months1time;
  const [activeButton, setActiveButton] = useState<FreeFormSelector>(0);

  const handleClick = (selectedButton: FreeFormSelector) => {
    if (selectedButton !== activeButton) {
      setActiveButton(selectedButton);

      switch (selectedButton) {
        case 0:
          setDateRange({
            dateFrom: undefined,
            dateTo: new Date(),
          });
          break;
        case 1:
          setDateRange({
            dateFrom: new Date(new Date().getTime() - months1time),
            dateTo: new Date(),
          });
          break;
        case 3:
          setDateRange({
            dateFrom: new Date(new Date().getTime() - months3time),
            dateTo: new Date(),
          });
          break;
        default:
          break;
      }
    }
  };
  return (
    <Flex w="full" justify="flex-end" alignSelf="flex-end">
      <Box
        py=".375rem"
        px="1.25rem"
        border="1px solid #E5E9EB"
        borderLeftRadius=".5rem"
        bg={activeButton === 0 ? "#589AAF1A" : "transparent"}
        color={activeButton === 0 ? "#589AAF" : "rgba(0,38,55,0.5)"}
        fontWeight={activeButton === 0 ? 700 : 400}
        cursor="pointer"
        fontSize=".875rem"
        onClick={() => handleClick(0)}
      >
        Hela perioden
      </Box>
      <Box
        py=".375rem"
        px="1.25rem"
        borderTop="1px solid #E5E9EB"
        borderBottom="1px solid #E5E9EB"
        borderLeft="none"
        borderRight="none"
        bg={activeButton === 1 ? "#589AAF1A" : "transparent"}
        color={activeButton === 1 ? "#589AAF" : "rgba(0,38,55,0.5)"}
        fontWeight={activeButton === 1 ? 700 : 400}
        cursor="pointer"
        fontSize=".875rem"
        onClick={() => handleClick(1)}
      >
        1 Månader
      </Box>
      <Box
        py=".375rem"
        px="1.25rem"
        border="1px solid #E5E9EB"
        borderRightRadius=".5rem"
        bg={activeButton === 3 ? "#589AAF1A" : "transparent"}
        color={activeButton === 3 ? "#589AAF" : "rgba(0,38,55,0.5)"}
        fontWeight={activeButton === 3 ? 700 : 400}
        cursor="pointer"
        fontSize=".875rem"
        onClick={() => handleClick(3)}
      >
        3 Månader
      </Box>
    </Flex>
  );
};

export default ChartPeriodToggle;
