import { Circle, Flex, Text } from "@chakra-ui/react";
import { Mood } from "features/admin/api/types";

interface MoodProps {
  mood: Mood[];
}

const PatientMood = ({ mood }: MoodProps) => {
  const getCircleColor = (mood: Mood) => {
    switch (mood.value) {
      case 1:
        return "#43B3BA";
      case 2:
        return "#589AAF";
      case 3:
        return "#568CB2";
      case 4:
        return "#647CAD";
      case 5:
        return "#796A9F";
      case 6:
        return "#8B5687";
      case 7:
        return "#944366";
      default:
        return "gray.500";
    }
  };

  const showAllMood =
    mood.length === 4 && mood.every((m) => m.value >= 1 && m.value <= 7);

  return (
    <Flex
      align="flex-start"
      gap={1}
      bg={showAllMood ? "rgba(148, 67, 102, 0.2)" : "none"}
      p={showAllMood ? "5px" : "none"}
      borderRadius={showAllMood ? "50px" : "none"}
      border={showAllMood ? "1px solid rgba(148, 67, 102, 1)" : "none"}
      w="max-content"
      overflow="hidden"
    >
      {mood.map((moodValue, index) => (
        <Circle
          key={index}
          bg={getCircleColor(moodValue)}
          size={{ base: "24px", lg: "32px" }}
        >
          <Text
            color="white"
            fontSize={{ base: "12px", lg: "16px" }}
            fontWeight={700}
          >
            {moodValue.value}
          </Text>
        </Circle>
      ))}
    </Flex>
  );
};

export default PatientMood;
