import {
  Button,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";

import logo from "assets/images/logo.svg";
import users from "assets/icons/users.svg";
import logout from "assets/icons/logout.svg";

import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useLogOut } from "features/login/api/client";
import Notifications from "features/notifications/notificationMain";

const AdminNavbar = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { trigger } = useLogOut();

  const logOutFunction = () => {
    trigger();
    history.push("/");
  };

  return (
    <Flex
      w="100%"
      direction="column"
      gap={2}
      p="32px 120px 32px 120px"
      overflow={"hidden"}
    >
      <Flex alignItems="center" justifyContent="space-between" w="100%">
        <Flex>
          <Image
            src={logo}
            alt="logo"
            w={{
              base: "160px",
              sm: "162px",
              md: "192px",
              lg: "256px",
            }}
            cursor={"pointer"}
            onClick={() => {
              history.push("/");
            }}
          />
        </Flex>
        <Flex w="100%" align="center" justify="flex-end" gap="4rem">
          <Flex gap={{ base: "0em", sm: "1em" }}>
            {/*TODO: UNCOMMENNT LANGUAGE SWITCH*/}
            {/* <Popover arrowShadowColor="transparent">
              <PopoverTrigger>
                <Button variant="ghost" color={"white"}>
                  {i18n.language.toUpperCase()}
                </Button>
              </PopoverTrigger>
              <PopoverContent
                zIndex={4}
                bg={"#589AAF"}
                width={"80px"}
                border={"none"}
              >
                <PopoverArrow
                  backgroundColor={"#589AAF"}
                  border="none !important"
                />
                <PopoverBody
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Button
                    onClick={() => i18n.changeLanguage("en")}
                    variant="ghost"
                    color={"white"}
                  >
                    EN
                  </Button>
                  <Button
                    onClick={() => i18n.changeLanguage("sv")}
                    variant="ghost"
                    color={"white"}
                  >
                    SV
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </Popover> */}
          </Flex>
          <Flex gap={2}>
            <Image src={users} alt={"users icon"} />
            <Text
              color="rgba(238, 232, 169, 1)"
              fontSize="18px"
              whiteSpace="nowrap"
            >
              {t("All Users")}
            </Text>
          </Flex>

          <Notifications />

          <Flex gap={2}>
            <Image src={logout} alt="Log out icon" />
            <Text
              color="white"
              fontSize="18px"
              onClick={logOutFunction}
              cursor="pointer"
            >
              {t("Logout")}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AdminNavbar;
