import {
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Flex,
  Heading,
  Input,
  Textarea,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useFetchAvailableStabilizingDoses,
  useStabiliseUserRequest,
} from "features/superadmin/api/client";
import { DateRangeType } from "features/userInfo/api/type";
import CalendarDateSelection from "features/userInfo/components/CalendarDateSelector";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { DateRange, DateRangePicker, RangeKeyDict } from "react-date-range";
import { useParams } from "react-router-dom";
import ManualSelect from "ui/components/ManualSelect";
import sv from "date-fns/locale/sv";
import { useTranslation } from "react-i18next";
import { profile } from "console";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import PrescriptionHelper from "features/prescriptionHelper/components/PrescriptionHelperModal";
import { toast } from "react-toastify";
import { invokeBasicInfoModal } from "features/genericModal";
import { convertDateToISODate } from "services/helpers/dateHelpers";

import "./styles.scss";
import { useAdminUserInfo } from "features/admin/api/client";

interface StabilizePatientProps {
  patientId?: string;
  expand?: boolean;
}

type ReasonOption = {
  label: string;
  value: string;
};

const StabilizePatient: FunctionComponent<StabilizePatientProps> = ({
  patientId,
  expand,
}) => {
  const { id: paramsId } = useParams<{ id: string }>();
  const [isStabilizationSet, setIsStabilisationSet] = useState<boolean>(false);
  const { t } = useTranslation();
  const id = patientId ?? paramsId;

  const reasonOptions: ReasonOption[] = [
    { label: `${t("Unwell")}`, value: "unwell" },
    {
      label: `${t("Patient's life situation")}`,
      value: "patient's life situation",
    },
    { label: `${t("Other")}`, value: "other" },
  ];

  const {
    isOpen: isSectionOpen,
    onOpen: onSectionOpen,
    onClose: onSectionClose,
  } = useDisclosure();

  const { data: availableDoses } = useFetchAvailableStabilizingDoses(id);
  const { data: profile } = useFetchProfileInfo();
  const { data: patientProfile } = useAdminUserInfo(id);

  const { trigger: sendStabilizeRequest, isMutating: isRequestPending } =
    useStabiliseUserRequest(id);

  const {
    isOpen: isPrescriptionHelperOpen,
    onClose: onPrescriptionHelperClose,
    onOpen: onPrescriptionHelperOpen,
  } = useDisclosure();

  const [reasonValue, setReasonValue] = useState<string>("");
  const [otherReasonValue, setOtherReasonValue] = useState<string>("");

  const [selectedDose, setSelectedDose] = useState<number>();
  const [calendarState, setCalendarState] = useState<any[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textareaValue = e.target.value;
    setOtherReasonValue(textareaValue);
  };

  const handleSaveClick = () => {
    if (!reasonValue || reasonValue === "") {
      toast.error(t("Please chouse the reason for stabilization"));
      return;
    }

    const reasonToSend =
      reasonValue === "other" ? otherReasonValue : reasonValue;

    sendStabilizeRequest(
      {
        dose: selectedDose ?? 0,
        end_date: convertDateToISODate(calendarState[0].endDate).slice(0, 10),
        start_date: convertDateToISODate(calendarState[0].startDate).slice(
          0,
          10
        ),
        reason: reasonToSend,
      },
      {
        onSuccess: () => {
          invokeBasicInfoModal(t("Patient successfully stabilized"));
          setReasonValue("");
          onSectionClose();
        },
        onError: (error) => {
          debugger;
          if (error.response.data.detail) {
            toast.error(error.response.data.detail);
          } else {
            toast.error(t("Something went wrong, please try again later"));
          }
        },
      }
    );
  };

  const handleCancelClick = () => {
    onSectionClose();
    setReasonValue("");
    setOtherReasonValue("");
    setSelectedDose(undefined);
    setCalendarState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };

  const planStartDose = patientProfile?.treatment?.days[0].dosage;

  useEffect(() => {
    setSelectedDose(planStartDose);
  }, [planStartDose]);

  const isEligibleRole =
    profile?.role === "doctor" ||
    profile?.role === "superadmin" ||
    profile?.role === "nurse";

  return (
    <Box>
      <Flex
        p="1.25rem"
        gap={"5rem"}
        w={"100%"}
        direction={{ base: "column", sm: "row" }}
      >
        <Box w={"20rem"}>
          <Flex direction="column" gap="1">
            <Heading
              color="#002637"
              opacity="0.5"
              fontSize=".875rem"
              fontFamily="secondary"
              fontWeight={400}
              textTransform="uppercase"
            >
              {t("Select stabilising dose")}:
            </Heading>
            <ManualSelect
              _dropdownStyles={{
                maxHeight: "6rem",
                overflowY: "auto",
              }}
              _containerStyles={{
                background: "rgba(0, 38, 55, 0.05)",
              }}
              _valueStyles={{
                color: "#002637",
                fontWeight: 500,
              }}
              options={
                availableDoses?.doses && availableDoses.doses.length > 0
                  ? availableDoses.doses.map((e) => ({
                      label: e + "mg",
                      value: e.toString(),
                    }))
                  : [{ label: `${t("No available doses")}`, value: "" }]
              }
              onChange={(newValue) => {
                setSelectedDose(parseFloat(newValue));
              }}
              value={selectedDose?.toString()}
            />
          </Flex>

          <Flex direction="column" gap="1">
            <Heading
              color="#002637"
              opacity="0.5"
              fontSize=".875rem"
              fontFamily="secondary"
              fontWeight={400}
              textTransform="uppercase"
              mt="1.5rem"
            >
              {t("Choose a reason")}:
            </Heading>
            <ManualSelect
              _dropdownStyles={{
                maxHeight: "9rem",
                overflowY: "auto",
              }}
              _containerStyles={{
                background: "rgba(0, 38, 55, 0.05)",
              }}
              _valueStyles={{
                color: "#002637",
                fontWeight: 500,
              }}
              options={reasonOptions}
              onChange={(newValue) => {
                setReasonValue(newValue);
              }}
              value={reasonValue?.toString()}
            />
            {reasonValue && reasonValue === "other" && (
              <Flex mt="1" w="full">
                <Textarea
                  _focusVisible={{ borderColor: "rgba(88, 154, 175, 0.5)" }}
                  placeholder={`${t(
                    "Briefly write the reason for the stabilization decision"
                  )}`}
                  size="sm"
                  value={otherReasonValue}
                  onChange={handleTextareaChange}
                />
              </Flex>
            )}
          </Flex>
        </Box>

        <Flex direction="column" maxW="25rem">
          <Heading
            color="#002637"
            opacity="0.5"
            fontSize=".875rem"
            fontFamily="secondary"
            fontWeight={400}
            textTransform="uppercase"
          >
            {t("Specify stabilizing period")}:
          </Heading>

          <DateRange
            locale={sv}
            editableDateInputs={true}
            onChange={(item) => {
              setCalendarState([item.selection]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={calendarState}
            color={"#000000"}
            rangeColors={["#589AAF"]}
            disabledDates={[]}
            minDate={new Date(Date.now() + 24 * 60 * 60 * 1000)}
          />
        </Flex>
      </Flex>

      <Flex
        w="full"
        px="1.5rem"
        gap={"12px"}
        justifyContent={"flex-end"}
        mb="10"
      >
        {isEligibleRole && (
          <Tooltip
            label={t("Stabilization period has been successfully set")}
            borderRadius={"4px"}
            fontSize={"0.875rem"}
            bg={"#002637"}
            fontWeight={600}
            hasArrow={true}
            placement="top"
            isDisabled={!isStabilizationSet}
          >
            <Button
              variant={"filledBlue"}
              fontSize={"1rem"}
              fontWeight={700}
              maxH="3rem"
              isLoading={isRequestPending}
              onClick={handleSaveClick}
              isDisabled={
                !calendarState[0].startDate ||
                !selectedDose ||
                isStabilizationSet
              }
            >
              {t("Save")}
            </Button>
          </Tooltip>
        )}

        <Button
          variant={"blue"}
          border={"1px solid"}
          borderColor={"blueish.900"}
          fontSize={"1rem"}
          px={"1.5rem"}
          fontWeight={700}
          color="#002637"
          h={"3rem"}
          onClick={handleCancelClick}
        >
          {t("Cancel")}
        </Button>
      </Flex>

      <PrescriptionHelper
        isOpen={isPrescriptionHelperOpen}
        onClose={onPrescriptionHelperClose}
      />
    </Box>
  );
};

export default StabilizePatient;
