import {
  Flex,
  VStack,
  Heading,
  Button,
  Image,
  HStack,
  Center,
  Link,
  Spinner,
  Divider,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { FunctionComponent, useEffect, useState } from "react";
import bankIdLogo from "../../../../assets/images/bankIdLogo.svg";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import {
  invokeBasicInfoModal,
  invokeConfirmationModal,
  showNoBankId,
} from "features/genericModal";
import { AnimatedQrCodeResponseType } from "features/login/api/types";
import { useHistory } from "react-router-dom";
import {
  useLogin,
  useCheckLogin,
  useTestLogin,
  useClearTestAccount,
} from "features/login/api/client";
/* import { useNavigate } from "react-router-dom"; */

interface LogInViaBankIdProps {}

const StaffBankIDAuth: FunctionComponent<LogInViaBankIdProps> = () => {
  const { data: tokens } = useLogin();
  const { t } = useTranslation();
  const data: AnimatedQrCodeResponseType | undefined = useCheckLogin().data;
  const { trigger: clearTestAccount } = useClearTestAccount();

  const [isOpeningBankId, setIsOpeningBankId] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (!isOpeningBankId) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setIsOpeningBankId(false);
      showNoBankId();
    }, 15000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpeningBankId]);

  const confirmClearAccount = () => {
    invokeConfirmationModal(
      "You are about to reset the testing account to it's initial state. Are you sure you want to proceed?",
      () => {
        return clearTestAccount().then(() => {
          invokeBasicInfoModal(
            <Text textAlign={"center"} color={"blue.900"}>
              Account successfully cleared
            </Text>
          );
        });
      },
      "Warning!"
    );
  };

  return (
    <Center
      bg={"rgba(88, 154, 175, 0.1)!important"}
      width={"100%"}
      mt={"0 !important"}
      borderRadius={"lg"}
      flexDirection={"column"}
      p={"2vh"}
      position={"relative"}
    >
      <Flex gap={"2em"} direction={{ base: "column-reverse", md: "row" }}>
        <VStack justifyContent={"center"}>
          <Image src={bankIdLogo} alt={"BankID logo"} />
          <Heading fontSize={{ base: "md", md: "lg" }} mb={"0.3em !important"}>
            {t("Log in via BankID")}
          </Heading>
          <VStack alignItems={"stretch"} minW={"100px"} maxW={"100%"}>
            <Flex alignItems={"center"} justifyContent={"center"}>
              {!isMobile && (
                <Link
                  variant={"filledBlue"}
                  mt={"0 !important"}
                  color="white"
                  height="auto"
                  fontSize={{ base: "10px", sm: "14px", md: "sm" }}
                  bg="blueish.500"
                  px={{ base: "1em", sm: "1.5em", md: "2em" }}
                  py="0.75em"
                  minWidth={{ base: "none", sm: "12em", md: "210px" }}
                  borderRadius={"md"}
                  fontWeight={"500"}
                  _hover={{ textDecor: "none" }}
                  onClick={(e) => {
                    if (!isMobile) {
                      setIsOpeningBankId(true);
                    }
                  }}
                  href={`bankid:///?autostarttoken=${tokens?.auto_start_token}&redirect=`}
                >
                  Starta BankID på denna enhet
                </Link>
              )}

              {isMobile && (
                <Link
                  variant={"filledBlue"}
                  mt={"0 !important"}
                  color="white"
                  height="auto"
                  fontSize={{ base: "1rem", sm: "14px", md: "sm" }}
                  bg="blueish.500"
                  px={{ base: "1em", sm: "1.5em", md: "2em" }}
                  py="0.75em"
                  minWidth={{ base: "none", sm: "12em", md: "210px" }}
                  borderRadius={"md"}
                  fontWeight={"500"}
                  _hover={{ textDecor: "none" }}
                  onClick={(e) => {
                    if (!isMobile) {
                      setIsOpeningBankId(true);
                    }
                  }}
                  href={`bankid:///?autostarttoken=${tokens?.auto_start_token}&redirect=`}
                >
                  Starta BankID på denna enhet
                </Link>
              )}

              {/* TODO: this is the link for the mobile devices login, investigate why deeplink works for both desktop and mobile */}
              {false && (
                <Link
                  variant={"filledBlue"}
                  mt={"0 !important"}
                  color="white"
                  height="auto"
                  fontSize={{ base: "1rem", sm: "14px", md: "sm" }}
                  bg="blueish.500"
                  px={{ base: "1em", sm: "1.5em", md: "2em" }}
                  py="0.75em"
                  minWidth={{ base: "none", sm: "12em", md: "210px" }}
                  borderRadius={"md"}
                  fontWeight={"500"}
                  _hover={{ textDecor: "none" }}
                  onClick={(e) => {
                    if (!isMobile) {
                      setIsOpeningBankId(true);
                    }
                  }}
                  href={`https://app.bankid.com/?autostarttoken=${tokens?.auto_start_token}&redirect=`}
                >
                  Starta BankID på denna enhet
                </Link>
              )}

              {isOpeningBankId && <Spinner ml={"1em"} />}
            </Flex>
            {/* <Flex alignItems={"center"}>
              <Button
                variant={"transparent"}
                onClick={() => testLogin()}
                opacity={0.5}
                fontSize={{ base: "xs", sm: "sm", md: "sm" }}
                color={"blueish.900"}
              >
                {t("Test account login")}
              </Button>
              <Divider
                orientation="vertical"
                borderWidth={"1px"}
                borderColor={"gray.400"}
                h={"2.5rem"}
              />
              <Button
                variant={"transparent"}
                onClick={confirmClearAccount}
                opacity={0.5}
                fontSize={{ base: "xs", sm: "sm", md: "sm" }}
                color={"#f5473b"}
              >
                {t("Clear test account")}
              </Button>
            </Flex> */}
          </VStack>
        </VStack>

        <Center display={{ base: "none", sm: "block" }}>
          <VStack>
            <Text
              color={"blueish.900"}
              fontSize={"0.875rem"}
              maxW={"20rem"}
              textAlign={"center"}
            >
              Eller scanna QR-koden för att logga in på annan enhet
            </Text>
            <QRCode value={data?.qrData?.qr_data ?? ""} />
          </VStack>
        </Center>
      </Flex>
    </Center>
  );
};

export default StaffBankIDAuth;
