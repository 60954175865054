import {
  SimpleGrid,
  Image,
  Box,
  Center,
  Heading,
  VStack,
  Text,
  Button,
  Flex,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import undepression from "../../../../assets/images/no_loner_depressed_girl.png";
import { FiClock } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface RequestCompletedProps {}

const RequestCompleted: FunctionComponent<RequestCompletedProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <SimpleGrid
      columns={2}
      gridAutoFlow={["column", "row", "row"]}
      gridTemplateColumns={["1fr", "744fr 936fr", "744fr 936fr"]}
      height={"80vh !important"}
      bg={"white"}
      width={"100%"}
      borderRadius={"lg"}
    >
      <Image
        objectFit="cover"
        height={"inherit"}
        src={undepression}
        borderRadius={"lg"}
        alt={"Decorative image"}
      />
      <Center p={["5%", "10%", "10%"]} pr={["7%", "7%", "12%"]}>
        <VStack alignItems={"flex-start"} gap={"20px"}>
          <Box>
            <Heading fontSize={"xl"} lineHeight={"44px"}>
              {t("Thank you for registration!")}
            </Heading>
            <Heading fontSize={"xl"} lineHeight={"44px"}>
              {t(
                "To receive an advice for a tapering-traject answer a number of questions."
              )}
            </Heading>
          </Box>

          <Text variant={"dark"} fontSize={"sm"}>
            {t(
              "Advice regarding the traject will be send within three working days."
            )}
          </Text>
          <Flex alignItems={"center"}>
            <Button
              variant="filledBlue"
              onClick={() => history.push("/prescription")}
            >
              {t("Get your medication")}
            </Button>
            <Flex alignItems={"center"} color={"blueish.500"} ml={"2em"}>
              <FiClock fontSize={"20px"} />
              <Text
                variant={"dark"}
                fontSize={"md"}
                color={"inherit"}
                height={"auto"}
                ml={"0.25em"}
              >
                ~10 min
              </Text>
            </Flex>
          </Flex>
        </VStack>
      </Center>
    </SimpleGrid>
  );
};

export default RequestCompleted;
