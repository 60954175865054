import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Tag,
  TagCloseButton,
  Wrap,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import SearchIcon from "ui/iconComponents/SearchIcon";

import { useTranslation } from "react-i18next";

interface SearchByNameProps {
  nameFilter: string;
  onNameFilterChange: (value: string) => void;
}

const SearchByName: React.FC<SearchByNameProps> = ({
  nameFilter,
  onNameFilterChange,
}) => {
  const { t } = useTranslation();
  const [selectedNames, setSelectedNames] = useState<string[]>([]);

  const filteredNames = nameFilter.split(",").filter((el) => el.trim() !== "");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && event.currentTarget.value.trim()) {
      const newNames = event.currentTarget.value
        .trim()
        .split(",")
        .filter((name) => name.trim());

      setSelectedNames([...selectedNames, ...newNames]);
      onNameFilterChange(selectedNames.concat(newNames).join(","));

      event.currentTarget.value = "";

      event.preventDefault();
    }
  };

  const handleTagClose = (name: string) => {
    const newSelectedNames = selectedNames.filter((n) => n !== name);
    setSelectedNames(newSelectedNames);
    onNameFilterChange(newSelectedNames.join(","));
  };

  /*  useEffect(() => {
    console.log(filteredNames);
  }, [filteredNames]); */

  useEffect(() => {
    if (!nameFilter) {
      setSelectedNames([]);
    }
  }, [nameFilter]);

  return (
    <Flex w="100%" direction="column" gap="12px">
      <FormControl>
        <FormLabel
          fontSize="14px"
          fontWeight={600}
          color="rgba(255, 255, 255, 1)"
          lineHeight="20px"
        >
          {t("SEARCH BY NAME")}
        </FormLabel>
        <Flex
          border="1px solid rgba(255, 255, 255, 0.5)"
          bg="rgba(255, 255, 255, 0.1)"
          borderRadius="8px"
          align="center"
          p="15px"
          h="49px"
          mt="1rem"
        >
          <SearchIcon />
          <Input
            color="white"
            fontWeight={500}
            opacity={0.8}
            fontSize={{ base: "1rem", lg: "md" }}
            autoFocus
            onKeyDown={handleKeyDown}
            placeholder={"Skriv…"}
            _placeholder={{
              color: "white",
              opacity: 1,
              fontWeight: 400,
            }}
          />
        </Flex>
      </FormControl>
      {filteredNames.length > 0 && (
        <Wrap gap="12px">
          {filteredNames.map((name) => (
            <Tag
              key={name}
              size="md"
              fontWeight={400}
              color="rgba(0, 38, 55, 1)"
              bg="rgba(238, 232, 169, 1)"
              p={{ base: "2px 10px", lg: "4px 12px" }}
              fontSize={{
                base: "8px",
                sm: "8px",
                md: "12px",
                lg: "16px",
              }}
            >
              {name}
              <TagCloseButton onClick={() => handleTagClose(name)} />
            </Tag>
          ))}
        </Wrap>
      )}
    </Flex>
  );
};

export default SearchByName;
