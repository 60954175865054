import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  Button,
  Heading,
  VStack,
  InputGroup,
  InputRightElement,
  Flex,
  Textarea,
  Text,
} from "@chakra-ui/react";
import {
  useAdminUserInfo,
  useCreateNote,
  useFetchUserNotes,
} from "features/admin/api/client";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import NoteListItem from "../NoteListItem";

interface NotesDrawerProps {
  isOpen: boolean;
  onClose(): void;
  userId?: string;
}

const NotesDrawer: FunctionComponent<NotesDrawerProps> = ({
  isOpen,
  onClose,
  userId,
}) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const { data: userData } = useAdminUserInfo(userId ?? id ?? "");

  const { data: notesData } = useFetchUserNotes(userId ?? id ?? "");
  const [noteText, setNoteText] = useState<string>("");
  const { trigger: createNote, isMutating: isCreating } = useCreateNote(
    userId ?? id ?? ""
  );

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={"md"}>
      <DrawerOverlay />
      <DrawerContent pt={"2rem"}>
        <DrawerCloseButton top={"2rem"} right={"2rem"} size={"3rem"} />
        <DrawerHeader py={"0.5rem"}>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            pt={"1rem"}
          >
            <Heading fontSize={"2rem"}>{t("See journal")}</Heading>
            <VStack gap={0} spacing={0} mt={"1rem"}>
              <Text color={"blueish.900"} fontSize={"1rem"}>
                {userData?.first_name} {userData?.last_name}
              </Text>
              <Text
                color={"blueish.900"}
                fontSize={"1rem"}
                opacity={0.4}
                fontWeight={500}
              >
                {userData?.username}
              </Text>
            </VStack>
          </Flex>
        </DrawerHeader>

        <DrawerBody overflowY={"scroll"}>
          <VStack
            minH={"100%"}
            h={"fit-content"}
            flexDirection={"column-reverse"}
            alignItems={"flex-end"}
            gap={"0.5rem"}
            spacing={0}
          >
            {notesData?.notes
              ?.sort((a, b) => {
                return new Date(b.date).getTime() - new Date(a.date).getTime();
              })
              .map((e) => (
                <NoteListItem
                  date={new Date(e.date)}
                  text={e.text}
                  written_by={e.written_by}
                />
              ))}
          </VStack>
        </DrawerBody>

        <DrawerFooter>
          <Flex
            w={"100%"}
            alignItems={"center"}
            border={"1px solid rgba(0, 0, 0, 0.1)"}
            borderRadius={"1.5rem"}
          >
            <Input
              value={noteText}
              fontSize={"1rem"}
              h={"3rem"}
              placeholder={t("Leave a note...") ?? ""}
              onChange={(e) => setNoteText(e.target.value)}
              flex={1}
              bg={"transparent !important"}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  createNote(
                    { text: noteText },
                    {
                      onSuccess: () => {
                        setNoteText("");
                      },
                    }
                  );
                }
              }}
            />

            <Button
              height={"3rem"}
              minHeight={0}
              minWidth={0}
              variant={"ghost"}
              bg={"transparent"}
              color={"blueish.900"}
              px={"2rem"}
              fontSize={"1rem"}
              opacity={0.7}
              isLoading={isCreating}
              borderRadius={"1.5rem"}
              onClick={() => {
                createNote(
                  { text: noteText },
                  {
                    onSuccess: () => {
                      setNoteText("");
                    },
                  }
                );
              }}
            >
              {t("Save")}
            </Button>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default NotesDrawer;
