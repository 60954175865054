import {
  CloseButton,
  Flex,
  HStack,
  Heading,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";

import user_icon from "assets/icons/user-circle.svg";
import { UserById } from "features/admin/api/types";
import { UserProfileType } from "features/dashboard/api/type";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

type ChatHeaderProps = {
  members: Partial<UserProfileType | UserById>[];
  onClose: () => void;
};

const ChatHeader: React.FC<ChatHeaderProps> = ({ members, onClose }) => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <HStack justify="space-between" bg="white">
      <CloseButton onClick={onClose} />
      <Flex direction="column" align="center">
        <Heading color="blueish.900" fontSize="16px" fontWeight={600}>
          {location.pathname === "/dashboard"
            ? `${t("Chat With Personel")}`
            : `${t("Chat With Patient")}`}
        </Heading>
        <Text
          color="blueish.900"
          fontSize="14px"
          fontWeight={400}
          fontFamily="secondary"
        >
          {members.length} {t("participants")}
        </Text>
      </Flex>

      <Popover>
        <PopoverTrigger>
          <Image src={user_icon} w="3%" cursor="pointer" />
        </PopoverTrigger>
        <PopoverContent
          maxW="260px"
          borderRadius="12px"
          borderTopRightRadius="none"
          mr="10px"
        >
          <PopoverArrow />
          <PopoverBody>
            <Flex direction="column" gap="8px" py="8px">
              <Text
                fontSize="14px"
                fontWeight={600}
                color="rgba(88, 154, 175, 1)"
              >
                {t("CHAT PARTICIPANTS")}
              </Text>
              <VStack align="flex-start" spacing={0.5}>
                {members.map((participant) => (
                  <>
                    <Text
                      fontSize="16px"
                      fontWeight={600}
                      color="rgba(0, 38, 55, 1)"
                    >
                      {participant.first_name} {participant.last_name}
                    </Text>
                    <Text
                      fontSize="14px"
                      fontWeight={400}
                      color="rgba(0, 38, 55, 0.5)"
                    >
                      {participant.role || "patient"}
                    </Text>
                  </>
                ))}
              </VStack>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  );
};

export default ChatHeader;
