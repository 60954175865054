import {
  Flex,
  Image,
  Text,
  Link as ChakraLink,
  Heading,
} from "@chakra-ui/react";

import arrow_footer from "assets/icons/arrow_footer.svg";
import footer_logo from "assets/icons/footer_logo.svg";

import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";

import bank_id from "assets/icons/bank_id.svg";
import mastercard from "assets/icons/mastercard.svg";
import visa from "assets/icons/visa.svg";
import postnord from "assets/icons/postnord.svg";
import LinkedIn from "assets/icons/LinkedIn.svg";
import Instagram from "assets/icons/Instagram.svg";

import Facebook from "assets/icons/Facebook.svg";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { HashLink } from "react-router-hash-link";

const HomeFooter = () => {
  const { basicEvent } = useGoogleAnalytics();

  const { t } = useTranslation();
  const history = useHistory();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Flex
      position={"relative"}
      pt="5rem"
      direction="column"
      width={"100vw"}
      left={"calc(-50vw + 50%)"}
      mb={"-6vh"}
    >
      <Flex
        w="full"
        bg="rgba(255, 255, 255, 1)"
        py="60px"
        justifyContent={"center"}
        px={{ base: "3%", sm: "9vw" }}
      >
        <Flex
          justify="center"
          width={{
            base: "100%",
            sm: "1250px",
            lg: "1680px",
          }}
          gap={{ base: "", sm: "5rem", lg: "" }}
        >
          {/* COLUMN 1 */}
          <Flex
            align="flex-start"
            px={{ base: "", md: "40px", lg: "90px" }}
            gap={{ base: "", md: "140px", lg: "150px" }}
          >
            <Flex direction="column" gap="8px">
              <Heading
                fontSize="16px"
                fontWeight={500}
                color="rgba(88, 154, 175, 1)"
                mb="15px"
              >
                {t("ABOUT LAMINA CLINIC")}
              </Heading>
              <HashLink
                to="/about-lamina-clinic#about-us"
                onClick={() => {
                  basicEvent(
                    "Contact",
                    "User clicked footer about-lamina-clinic"
                  );
                }}
              >
                <Text color="rgba(0, 38, 55, 1)" fontSize="16px">
                  {t("About us")}
                </Text>
              </HashLink>

              <HashLink
                to="/about-lamina-clinic#contact"
                onClick={() => {
                  basicEvent(
                    "Contact",
                    "User clicked footer about-lamina-clinic"
                  );
                }}
              >
                <Text
                  color="rgba(0, 38, 55, 1)"
                  fontSize="16px"
                  cursor="pointer"
                >
                  Press & kontakt
                </Text>
              </HashLink>
            </Flex>

            <Flex direction="column" gap="8px">
              <Heading
                fontSize="16px"
                fontWeight={500}
                color="rgba(88, 154, 175, 1)"
                whiteSpace="nowrap"
                mb="15px"
              >
                BEHANDLING
              </Heading>

              <HashLink
                color={"white !important"}
                to="/prescription-medication#medication-support"
                onClick={() => {
                  basicEvent("Contact", "User clicked footer email");
                }}
              >
                <Text
                  color="rgba(0, 38, 55, 1)"
                  fontSize="16px"
                  cursor="pointer"
                >
                  Läkemedel
                </Text>
              </HashLink>

              <HashLink
                color={"white !important"}
                to="/prescription-medication#medication-support"
                onClick={() => {
                  basicEvent("Contact", "User clicked footer email");
                }}
              >
                <Text
                  color="rgba(0, 38, 55, 1)"
                  fontSize="16px"
                  cursor="pointer"
                >
                  Forskning & evidens
                </Text>
              </HashLink>
            </Flex>
          </Flex>

          {/* COLUMN 2 */}
          <Flex align="flex-start" gap={{ base: "", md: "40px", lg: "90px" }}>
            <Flex direction="column" gap="8px">
              <Heading
                fontSize="16px"
                fontWeight={500}
                color="rgba(88, 154, 175, 1)"
                mb="15px"
              >
                {t("SUPPORT")}
              </Heading>

              <Text
                color="rgba(0, 38, 55, 1)"
                fontSize="16px"
                cursor="pointer"
                onClick={() => {
                  scrollToTop();
                  history.push("/faq");
                }}
              >
                Vanliga frågor
              </Text>

              <HashLink
                to="/contact-form"
                onClick={() => {
                  basicEvent(
                    "Contact",
                    "User clicked footer about-lamina-clinic"
                  );
                }}
              >
                <Text
                  color="rgba(0, 38, 55, 1)"
                  fontSize="16px"
                  cursor="pointer"
                >
                  Kundtjänst
                </Text>
              </HashLink>
            </Flex>
          </Flex>

          {/* COLUMN 3 */}
          {/*  <Flex direction="column" align="flex-start" px="60px" gap="40px">
            <Flex direction="column" gap="8px">
              <Heading
                fontSize="16px"
                fontWeight={500}
                color="rgba(88, 154, 175, 1)"
                mb="15px"
              >
                EMAIL
              </Heading>
              <ChakraLink
                color={"white !important"}
                href="mailto:info@laminaclinic.com"
                onClick={() => {
                  basicEvent("Contact", "User clicked footer link");
                }}
              >
                <Text color="rgba(0, 38, 55, 1)" fontSize="16px">
                  info@laminaclinic.com
                </Text>
              </ChakraLink>
            </Flex>
            <Flex gap="120px">
              <Flex direction="column">
                <Heading
                  fontSize="16px"
                  fontWeight={500}
                  color="rgba(88, 154, 175, 1)"
                  whiteSpace="nowrap"
                  mb="15px"
                >
                  {t("PAYMENT OPTIONS")}
                </Heading>
                <Flex justify="space-between" gap="50px">
                  <Image src={visa} />
                  <Image src={bank_id} />
                  <Image src={mastercard} />
                </Flex>
              </Flex>
              <Flex direction="column">
                <Heading
                  fontSize="16px"
                  fontWeight={500}
                  color="rgba(88, 154, 175, 1)"
                  whiteSpace="nowrap"
                  mb="15px"
                >
                  {t("WE SHIP WITH")}
                </Heading>
                <Flex>
                  <Image src={postnord} />
                </Flex>
              </Flex>
            </Flex>
            <Flex direction="column" gap="8px">
              <Heading
                fontSize="16px"
                fontWeight={500}
                color="rgba(88, 154, 175, 1)"
                mb="15px"
              >
                {t("SOCIAL MEDIA")}
              </Heading>
              <Flex gap="40px">
                <ChakraLink
                  color={"white !important"}
                  href="https://www.linkedin.com/company/lamina-clinic/"
                  target="blank"
                  onClick={() => {
                    basicEvent("Contact", "User clicked footer link");
                  }}
                >
                  <Image src={LinkedIn} />
                </ChakraLink>
                <ChakraLink
                  color={"white !important"}
                  href="https://www.facebook.com/laminaclinicsverige"
                  target="blank"
                  onClick={() => {
                    basicEvent("Contact", "User clicked footer link");
                  }}
                >
                  <Image src={Facebook} />
                </ChakraLink>
                <ChakraLink
                  color={"white !important"}
                  href="https://www.instagram.com/laminaclinic.se/"
                  target="blank"
                  onClick={() => {
                    basicEvent("Contact", "User clicked footer link");
                  }}
                >
                  <Image src={Instagram} />
                </ChakraLink>
              </Flex>
            </Flex>
          </Flex> */}
        </Flex>
      </Flex>
      {/* FOOTER BLACK */}
      <Flex
        w="full"
        p="40px"
        px={{ base: "3%", sm: "12vw" }}
        bg="rgba(0, 38, 55, 1)"
        justifyContent={"center"}
      >
        <Flex
          align="center"
          justify="space-between"
          width={{
            base: "100%",
            sm: "1250px",
            lg: "1680px",
          }}
        >
          <Image src={footer_logo} alt="Lamina clinic logo" h="58px" />
          <Flex gap="40px">
            <Text
              fontSize="16px"
              fontWeight={400}
              cursor="pointer"
              onClick={() => {
                scrollToTop();
                history.push("/terms-of-purchase");
              }}
            >
              {t("Terms of Purchase")}
            </Text>
            <Text
              fontSize="16px"
              fontWeight={400}
              cursor="pointer"
              onClick={() => {
                scrollToTop();
                history.push("/privacy-policy");
              }}
            >
              {t("Privacy Policy")}
            </Text>
            <Text
              fontSize="16px"
              fontWeight={400}
              cursor="pointer"
              onClick={() => history.push("/cookie-policy")}
            >
              {t("Cookie Policy")}
            </Text>
          </Flex>
          <Image
            src={arrow_footer}
            alt="arrow icon"
            onClick={() =>
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              })
            }
            cursor="pointer"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HomeFooter;
