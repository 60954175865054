import { Flex, Image, Text } from "@chakra-ui/react";

type RoleItemProps = {
  icon: string;
  role: string;
  count?: number;
  isActive: boolean;
  onClick?(): void;
};

const RoleItem: React.FC<RoleItemProps> = ({
  icon,
  role,
  count,
  isActive,
  onClick,
}) => {
  return (
    <Flex
      w={{ base: "100%", sm: "160px", md: "190px", lg: "248px" }}
      borderRadius="12px"
      boxShadow={isActive ? "0px 8px 12px 0px rgba(0, 0, 0, 0.10)" : "none"}
      direction="column"
      p={{ base: "20px", md: "22px", lg: "24px" }}
      background={isActive ? "rgba(88, 154, 175, 0.8)" : "white"}
      cursor="pointer"
      transition="box-shadow 0.3s, background-color 0.3s, border-color 0.3s"
      _hover={{
        borderRadius: "12px",
        borderWidth: "0.5px",
        borderColor: "rgba(88, 154, 175, 0.4)",
        boxShadow: "0px 8px 12px 0px rgba(0, 0, 0, 0.10)",
      }}
      gap="12px"
      alignSelf="flex-start"
      onClick={onClick}
    >
      <Image src={icon} w={{ base: "8%", sm: "20%", md: "22%", lg: "16%" }} />
      <Text
        fontFamily="secondary"
        fontWeight={500}
        fontSize={{ base: "14px", md: "16px", lg: "18px" }}
        color={isActive ? "white" : "black"}
      >
        {role.toUpperCase()}
      </Text>
      <Text
        fontFamily="secondary"
        fontWeight={700}
        fontSize={{ base: "28px", md: "30px", lg: "32px" }}
        color={isActive ? "white" : "black"}
        mt="-12px"
      >
        {count}
      </Text>
    </Flex>
  );
};

export default RoleItem;
