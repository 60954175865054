import {
  Box,
  Center,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
  useDisclosure,
  VStack,
  Image,
  Button,
} from "@chakra-ui/react";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlinePlus } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";
import SignPopup from "../SignPopup";

interface SignatureAddButtonProps {
  setSignatureUrl(signature: string): void;
}

const SignatureAddButton: FunctionComponent<SignatureAddButtonProps> = ({
  setSignatureUrl,
}) => {
  const { data: adminData } = useFetchProfileInfo();
  const { t } = useTranslation();
  const {
    isOpen: isSignModalOpen,
    onOpen: onSignModalOpen,
    onClose: onSignModalClose,
  } = useDisclosure();

  const {
    isOpen: isSavedSignaturesPopoverOpen,
    onClose: onSavedSignaturesPopoverClose,
    onOpen: onSavedSignaturesPopoverOpen,
  } = useDisclosure();

  const existingSignatureUrl: string = adminData?.signature ?? "";

  return (
    <>
      <Popover isOpen={isSavedSignaturesPopoverOpen} placement={"top"}>
        <PopoverTrigger>
          <Box position={"absolute"} right={"4rem"} bottom={"7rem"}>
            {existingSignatureUrl && existingSignatureUrl !== "" && (
              <Center
                borderRadius={"50%"}
                height={"3rem"}
                bg={"#589AAF"}
                w={"3rem"}
                boxShadow={"0px 0px 8px 8px #00000010"}
                zIndex={999999}
                color={"white"}
                cursor={"pointer"}
                transition={"all 0.3s ease-out"}
                _hover={{
                  transform: "scale(1.1)",
                }}
                onClick={onSavedSignaturesPopoverOpen}
              >
                <IoMdAdd fontSize={"2rem"} />
              </Center>
            )}
            {(!existingSignatureUrl || existingSignatureUrl === "") && (
              <Tooltip
                label="Click here to add your signature"
                fontSize={"1rem"}
                bg={"white"}
                color={"blueish.900"}
                placement={"top"}
                borderRadius={"0.5rem"}
                fontWeight={"500"}
                padding={"0.5rem"}
                boxShadow={"0px 0px 4px 4px #00000008"}
              >
                <Center
                  borderRadius={"50%"}
                  height={"3rem"}
                  bg={"#589AAF"}
                  w={"3rem"}
                  boxShadow={"0px 0px 8px 8px #00000010"}
                  right={"4rem"}
                  zIndex={999999}
                  color={"white"}
                  cursor={"pointer"}
                  transition={"all 0.3s ease-out"}
                  _hover={{
                    transform: "scale(1.1)",
                  }}
                  onClick={onSignModalOpen}
                >
                  <IoMdAdd fontSize={"2rem"} />
                </Center>
              </Tooltip>
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent maxW={"14rem"}>
          <PopoverArrow />
          <PopoverCloseButton onClick={onSavedSignaturesPopoverClose} />
          <PopoverBody>
            <VStack maxW={"12rem"}>
              <Image
                src={existingSignatureUrl}
                cursor={"pointer"}
                transition={"all 0.3s ease-in"}
                _hover={{
                  borderRadius: "0.5rem",
                  bg: "#589AAF15",
                }}
                onClick={() => {
                  setSignatureUrl(existingSignatureUrl);
                  onSavedSignaturesPopoverClose();
                }}
              />
              <Button
                variant={"ghost"}
                w={"100%"}
                fontWeight={"400"}
                fontSize={"stnd"}
                _hover={{
                  bg: "#589AAF15",
                }}
                leftIcon={<BsPlusLg />}
                onClick={() => {
                  onSavedSignaturesPopoverClose();
                  onSignModalOpen();
                }}
              >
                <Box pt={"0.25rem"}>{t("Add new")}</Box>
              </Button>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <SignPopup
        isOpen={isSignModalOpen}
        onClose={onSignModalClose}
        onChangeSignUrl={setSignatureUrl}
      />
    </>
  );
};

export default SignatureAddButton;
