import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Image,
  useDisclosure,
  Center,
  SimpleGrid,
} from "@chakra-ui/react";

import pattern_dash_new from "assets/icons/pattern_dash_new.svg";
import app_store_1 from "assets/icons/app_store_1.svg";
import google_play from "assets/images/google-play-badge.svg";

import AddressPopup from "features/addressPopup/components/addressPopupContainer";
import {
  useDashboardInfo,
  useFetchProfileInfo,
} from "features/dashboard/api/client";
import Order from "features/dashboard/components/dashboardOrder";
import TreantemtPlan from "features/dashboard/components/dashboardTreatmentPlan";
import UserMedication from "features/dashboard/components/dashboardUserMedication";

import ChatDrawer from "features/doctors/components/ChatDrawer/ChatDrawer";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

import { useHistory, useLocation } from "react-router-dom";
import { useOrderStore } from "services/state/orderStore";
import CalendarMessageGreen from "ui/iconComponents/CalendarMessageGreen";

import CalendlyModalWidget from "features/CalendlyWidget";
import BookMeetingPrompt from "features/dashboard/components/BookMeetingPrompt";

const UserDashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentStep, setCurrentStep, setCurrentStepState, currentStepState } =
    useOrderStore();
  const { data: profileData } = useFetchProfileInfo();
  const { data: dashboardData } = useDashboardInfo();

  const {
    isOpen: isAddressPopupOpen,
    onOpen: onAddressPopupOpen,
    onClose: onAddressPopupClose,
  } = useDisclosure();

  const location = useLocation();

  useEffect(() => {
    if (currentStep === 5) {
      history.push("/dashboard/order");
    }
  }, [currentStep, history]);

  useEffect(() => {
    if (profileData?.status === "registered") {
      history.push("/prescription");
    }
  }, [history, profileData]);

  useEffect(() => {
    if (profileData && dashboardData) {
      const orderStatuses = [
        "",
        "order_placed",
        "payment_made",
        "shipped",
        "delivered",
      ];

      if (
        dashboardData.order?.order_status === "delivered" &&
        profileData.start_treatment_date &&
        new Date(profileData.start_treatment_date).getTime() >
          new Date().getTime()
      ) {
        setCurrentStep(5);
        setCurrentStepState("processing");
      } else if (
        dashboardData.order?.order_status === "delivered" &&
        profileData.start_treatment_date &&
        new Date(profileData.start_treatment_date).getTime() <=
          new Date().getTime()
      ) {
        setCurrentStep(5);
        setCurrentStepState("completed");
      } else if (
        dashboardData.order?.order_status === "shipping" ||
        dashboardData.order?.order_status === "shipped"
      ) {
        setCurrentStep(4);
        setCurrentStepState("processing");
      } else if (dashboardData.order?.order_status === "order_placed") {
        setCurrentStep(3);
        setCurrentStepState("processing");
      } else if (dashboardData.order?.order_status === "payment_made") {
        setCurrentStep(3);
        setCurrentStepState("completed");
      } else if (dashboardData.order?.order_status === "payment_processing") {
        setCurrentStep(3);
        setCurrentStepState("processing");
      } else if (dashboardData.order?.order_status === "shipped") {
        setCurrentStep(4);
        setCurrentStepState("processing");
      } else if (dashboardData.order?.order_status === "delivered") {
        setCurrentStep(4);
        setCurrentStepState("completed");
      } else if (
        profileData.is_doctor_approve ||
        profileData.is_doctor_rejected
      ) {
        setCurrentStep(2);
        setCurrentStepState("completed");
      } else if (
        profileData.call_with_nurse &&
        new Date(profileData.call_with_nurse).getTime() < new Date().getTime()
      ) {
        setCurrentStep(2);
        setCurrentStepState("processing");
      } else if (
        profileData.call_with_nurse &&
        new Date(profileData.call_with_nurse).getTime() > new Date().getTime()
      ) {
        setCurrentStep(1);
        setCurrentStepState("processing");
      } else {
        setCurrentStep(0);
        setCurrentStepState("completed");
      }
    }
  }, [profileData, dashboardData]);

  return (
    <Flex w="100%" direction="column" gap={3}>
      <Flex direction="column" gap={3}>
        <SimpleGrid
          w="100%"
          gap={3}
          gridTemplateColumns={{ base: "1fr", sm: "1fr 2fr" }}
        >
          <UserMedication />
          <BookMeetingPrompt />
        </SimpleGrid>
      </Flex>

      {/* Box 3 */}

      {/*  <Box bg="white" borderRadius="0.75rem" w="100%">
         <Order /> 
      </Box> */}

      <Flex
        w={{ base: "auto", md: "full" }}
        borderRadius=".75rem"
        p="2.5rem"
        bg="#589AAF"
        mb={{ base: "1rem", sm: "3rem" }}
        justify="space-between"
        direction={{ base: "column", md: "row" }}
      >
        <Flex
          direction="column"
          align="flex-start"
          justify="center"
          gap={3}
          py={2}
        >
          <Heading color="#FFFFFF" fontSize="1.75rem">
            {t("servicesSixthBlockHeader")}
          </Heading>
          <Text fontSize="1.25rem" color="#FFFFFF">
            Appen är nyckeln till en nedtrappning med Lamina Clinic. Det är här
            du har dina vårdmöten, ser din nedtrappningsplan och loggar ditt
            mående.
          </Text>
        </Flex>
        <Flex direction="column" gap={2}>
          <Image
            alt={"Link to app store app"}
            src={app_store_1}
            onClick={() => {
              window.open(
                "https://apps.apple.com/us/app/lamina-clinic/id6448011545",
                "_blank"
              );
            }}
            minW={"12.5rem"}
            maxH="4.563rem"
            cursor={"pointer"}
          />
          <Image
            alt={"Link to Play Market app"}
            src={google_play}
            minW={"12.5rem"}
            maxH="4.063rem"
            cursor={"pointer"}
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=com.laminaclinic.laminaclinic",
                "_blank"
              );
            }}
          />
        </Flex>
      </Flex>

      <AddressPopup
        isOpen={isAddressPopupOpen}
        onClose={onAddressPopupClose}
        editMode={!!dashboardData?.order}
      />
    </Flex>
  );
};

export default UserDashboard;
