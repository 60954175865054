import {
  Flex,
  Text,
  Image,
  Heading,
  Link as ChakraLink,
} from "@chakra-ui/react";

import arrow_footer from "assets/icons/arrow_footer.svg";
import footer_logo from "assets/icons/footer_logo.svg";
import bank_id from "assets/icons/bank_id.svg";
import mastercard from "assets/icons/mastercard.svg";
import visa from "assets/icons/visa.svg";
import postnord from "assets/icons/postnord.svg";
import LinkedIn from "assets/icons/LinkedIn.svg";
import Instagram from "assets/icons/Instagram.svg";
import Facebook from "assets/icons/Facebook.svg";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import { Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const FooterMobile = () => {
  const { basicEvent } = useGoogleAnalytics();
  const { isOpen, show, hide } = useIntercom();
  const { t } = useTranslation();
  const history = useHistory();
  const handleToggleIntercom = () => {
    if (isOpen) {
      hide();
    } else {
      show();
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <Flex
      w="full"
      direction="column"
      position={"relative"}
      pt="5rem"
      width={"100vw"}
      left={"calc(-50vw + 50%)"}
      mb="-3.5rem"
    >
      <Flex
        w="full"
        bg="rgba(255, 255, 255, 1)"
        px={"2rem"}
        py={"2rem"}
        justify="space-between"
        direction="column"
        gap="3rem"
        pt={"4rem"}
      >
        <Flex w="full" justify="space-between">
          <Flex direction="column" gap="8px">
            <Heading
              fontSize="14px"
              fontWeight={500}
              color="rgba(88, 154, 175, 1)"
              mb="10px"
            >
              {t("ABOUT LAMINA CLINIC")}
            </Heading>
            <HashLink
              to="/about-lamina-clinic#about-us"
              onClick={() => {
                basicEvent(
                  "Contact",
                  "User clicked footer about-lamina-clinic"
                );
              }}
            >
              <Text color="rgba(0, 38, 55, 1)" fontSize="16px">
                {t("About us")}
              </Text>
            </HashLink>

            <HashLink
              to="/about-lamina-clinic#contact"
              onClick={() => {
                basicEvent(
                  "Contact",
                  "User clicked footer about-lamina-clinic"
                );
              }}
            >
              <Text color="rgba(0, 38, 55, 1)" fontSize="16px" cursor="pointer">
                Press & kontakt
              </Text>
            </HashLink>
          </Flex>

          <Flex direction="column" gap="8px">
            <Heading
              fontSize="14px"
              fontWeight={500}
              color="rgba(88, 154, 175, 1)"
              mb="10px"
            >
              BEHANDLING
            </Heading>
            <HashLink
              color={"white !important"}
              to="/prescription-medication#prescription"
              onClick={() => {
                basicEvent("Contact", "User clicked footer email");
              }}
            >
              <Text color="rgba(0, 38, 55, 1)" fontSize="16px" cursor="pointer">
                Läkemedel
              </Text>
            </HashLink>

            <HashLink
              color={"white !important"}
              to="/prescription-medication#medication-support"
              onClick={() => {
                basicEvent("Contact", "User clicked footer email");
              }}
            >
              <Text color="rgba(0, 38, 55, 1)" fontSize="16px" cursor="pointer">
                Forskning & evidens
              </Text>
            </HashLink>
          </Flex>
          {/*  <Flex direction="column" gap="8px">
            <Heading
              fontSize="14px"
              fontWeight={500}
              color="rgba(88, 154, 175, 1)"
              whiteSpace="nowrap"
              mb="10px"
            >
              {t("BUY AT LAMINA CLINIC")}
            </Heading>
            <HashLink
              color={"white !important"}
              to="/buy-at-lamina-clinic#order"
              onClick={() => {
                basicEvent("Contact", "User clicked footer email");
              }}
            >
              <Text color="rgba(0, 38, 55, 1)" fontSize="16px">
                {t("Ordering")}
              </Text>
            </HashLink>

            <HashLink
              color={"white !important"}
              to="/buy-at-lamina-clinic#delivery"
              onClick={() => {
                basicEvent("Contact", "User clicked footer email");
              }}
            >
              <Text color="rgba(0, 38, 55, 1)" fontSize="16px">
                {t("Delivery")}
              </Text>
            </HashLink>

            <HashLink
              color={"white !important"}
              to="/buy-at-lamina-clinic#payment"
              onClick={() => {
                basicEvent("Contact", "User clicked footer email");
              }}
            >
              <Text color="rgba(0, 38, 55, 1)" fontSize="16px">
                {t("Payment")}
              </Text>
            </HashLink>

            <HashLink
              color={"white !important"}
              to="/buy-at-lamina-clinic#safe-payments"
              onClick={() => {
                basicEvent("Contact", "User clicked footer email");
              }}
            >
              <Text color="rgba(0, 38, 55, 1)" fontSize="16px">
                {t("Safe payments")}
              </Text>
            </HashLink>

            <HashLink
              color={"white !important"}
              to="/buy-at-lamina-clinic#returns-complaints"
              onClick={() => {
                basicEvent("Contact", "User clicked footer email");
              }}
            >
              <Text color="rgba(0, 38, 55, 1)" fontSize="16px">
                {t("Returns & Complaints")}
              </Text>
            </HashLink>
          </Flex> */}
        </Flex>

        <Flex w="full" justify="space-between">
          <Flex direction="column" gap="8px">
            <Heading
              fontSize="14px"
              fontWeight={500}
              color="rgba(88, 154, 175, 1)"
              mb="10px"
            >
              {t("SUPPORT")}
            </Heading>

            <Text
              color="rgba(0, 38, 55, 1)"
              fontSize="16px"
              cursor="pointer"
              onClick={() => {
                scrollToTop();
                history.push("/faq");
              }}
            >
              Vanliga frågor
            </Text>

            <HashLink
              to="/contact-form"
              onClick={() => {
                basicEvent(
                  "Contact",
                  "User clicked footer about-lamina-clinic"
                );
              }}
            >
              <Text color="rgba(0, 38, 55, 1)" fontSize="16px" cursor="pointer">
                Kundtjänst
              </Text>
            </HashLink>
          </Flex>
        </Flex>

        {/*  <Flex w="full" justify="space-between">
          <Flex direction="column">
            <Heading
              fontSize="14px"
              fontWeight={500}
              color="rgba(88, 154, 175, 1)"
              whiteSpace="nowrap"
              mb="10px"
            >
              {t("PAYMENT OPTIONS")}
            </Heading>
            <Flex justify="space-between" gap="50px">
              <Image src={visa} />
              <Image src={bank_id} />
              <Image src={mastercard} />
            </Flex>
          </Flex>
          <Flex direction="column">
            <Heading
              fontSize="14px"
              fontWeight={500}
              color="rgba(88, 154, 175, 1)"
              whiteSpace="nowrap"
              mb="10px"
            >
              {t("WE SHIP WITH")}
            </Heading>
            <Flex>
              <Image src={postnord} />
            </Flex>
          </Flex>
        </Flex> */}

        {/*   <Flex w="full" justify="space-between">
            <Flex direction="column" gap="8px">
            <Heading
              fontSize="14px"
              fontWeight={500}
              color="rgba(88, 154, 175, 1)"
              mb="10px"
            >
              {t("SOCIAL MEDIA")}
            </Heading>
            <Flex gap="40px">
              <ChakraLink
                color={"white !important"}
                href="https://www.linkedin.com/company/lamina-clinic/"
                target="blank"
                onClick={() => {
                  basicEvent("Contact", "User clicked footer link");
                }}
              >
                <Image src={LinkedIn} />
              </ChakraLink>
              <ChakraLink
                color={"white !important"}
                href="https://www.facebook.com/laminaclinicsverige"
                target="blank"
                onClick={() => {
                  basicEvent("Contact", "User clicked footer link");
                }}
              >
                <Image src={Facebook} />
              </ChakraLink>
              <ChakraLink
                color={"white !important"}
                href="https://www.instagram.com/laminaclinic.se/"
                target="blank"
                onClick={() => {
                  basicEvent("Contact", "User clicked footer link");
                }}
              >
                <Image src={Instagram} />
              </ChakraLink>
            </Flex>
          </Flex> 
          <Flex direction="column" gap="8px">
            <Heading
              fontSize="14px"
              fontWeight={500}
              color="rgba(88, 154, 175, 1)"
              mb="10px"
            >
              EMAIL :
            </Heading>
            <ChakraLink
              color={"white !important"}
              href="mailto:Info@laminaclinic.com"
              onClick={() => {
                basicEvent("Contact", "User clicked footer email");
              }}
            >
              <Text color="rgba(0, 38, 55, 1)" fontSize="16px">
                info@laminaclinic.com
              </Text>
            </ChakraLink>
          </Flex>
        </Flex> */}
      </Flex>

      <Flex
        w="full"
        p="40px"
        bg="rgba(0, 38, 55, 1)"
        gap="1rem"
        align="center"
        justify="space-between"
        direction="column"
      >
        <Flex w="full" justify="space-between">
          <Image src={footer_logo} alt="Lamina clinic logo" h="58px" />
          <Image
            src={arrow_footer}
            alt="arrow icon"
            onClick={() =>
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              })
            }
            cursor="pointer"
          />
        </Flex>
        <Flex gap="40px">
          <Text
            fontSize="12px"
            fontWeight={400}
            cursor="pointer"
            onClick={() => {
              scrollToTop();
              history.push("/terms-of-purchase");
            }}
            whiteSpace="nowrap"
          >
            {t("Terms of Purchase")}
          </Text>
          <Text
            fontSize="12px"
            fontWeight={400}
            cursor="pointer"
            onClick={() => {
              scrollToTop();
              history.push("/privacy-policy");
            }}
            whiteSpace="nowrap"
          >
            {t("Privacy Policy")}
          </Text>
          <Text
            fontSize="12px"
            fontWeight={400}
            cursor="pointer"
            onClick={() => history.push("/cookie-policy")}
            whiteSpace="nowrap"
          >
            {t("Cookie Policy")}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FooterMobile;
