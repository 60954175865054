import { Button, Center, Heading, Image } from "@chakra-ui/react";
import check from "assets/icons/check.svg";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

const SetNewPasswordSuccess = () => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <Center
      flexDirection="column"
      w="full"
      py="6rem"
      px={{ base: "0rem", md: "4rem" }}
    >
      <Image src={check} />
      <Heading
        mt="20px"
        color="rgba(0, 38, 55, 1)"
        fontWeight={600}
        fontSize="32px"
        letterSpacing={1.6}
        textAlign="center"
      >
        {t("Your password has been updated")}
      </Heading>

      <Button
        w="full"
        mt="40px"
        bg="rgba(88, 154, 175, 1)"
        borderColor="white"
        fontFamily="secondary"
        fontWeight={700}
        fontSize="16px"
        letterSpacing={1}
        color={"white"}
        maxW="235px"
        onClick={() => {
          history.push("/admin");
        }}
      >
        {t("Login")}
      </Button>
    </Center>
  );
};

export default SetNewPasswordSuccess;
