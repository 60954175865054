import {
  Flex,
  Heading,
  Text,
  Image,
  Box,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import left_arrow from "assets/icons/left_arrow.svg";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { basicEvent } = useGoogleAnalytics();
  return (
    //<div id="privacy-policy">
    <Box pb={"5vh"} w="full">
      <Flex
        w="full"
        bg="rgba(255, 255, 255, 1)"
        borderRadius="24px"
        p={{ base: "20px", sm: "60px" }}
        align="flex-start"
        direction="column"
        gap="25px"
      >
        <Flex
          align="center"
          gap="14px"
          onClick={() => history.push("/")}
          cursor="pointer"
        >
          <Image src={left_arrow} />
          <Text fontSize="18px" color="black" fontWeight={500}>
            {t("Back")}
          </Text>
        </Flex>
        <Flex w="100%" align="center" justify="center" mb="30px">
          <Heading fontSize={{ base: "25px", sm: "30px" }} color="blueish.500">
            LAMINA CLINICS INTEGRITETSPOLICY
          </Heading>
        </Flex>

        <Flex
          w="full"
          direction="column"
          gap="16px"
          px={{ base: "5px", sm: "20px" }}
        >
          <Flex w="full" direction="column" gap="8px">
            <Text color="black" fontSize="18px" fontWeight={500}>
              Lamina Clinic Sweden AB, Reg. nr 559411-0859, c/o Mikael Bäckström
              Graningegränd 18, 122 42 Enskede (i fortsättningen kallat “Lamina
              Clinic”, ”kliniken” eller ”vi”) vidtar alla nödvändiga åtgärder
              för att säkerställa att personuppgifter rörande besökare på vår
              webbplats, våra aktieägare, kontaktpersoner för vår leverantörer
              och affärspartners samt andra berörda parter behandlas av oss på
              ett lagligt, rättvist och öppet sätt.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              På Lamina Clinic vill vi hjälpa människor att minska eller sluta
              använda antidepressiva mediciner på ett säkert och smärtfritt
              sätt. Därför utvecklar vi en säker plattform för kommunikation,
              kunskapsdelning och digitala verktyg som är enkel att implementera
              och använda.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Lamina Clinic är den personuppgiftsansvariga för dina
              personuppgifter och behandlar dem i enlighet med denna
              integritetspolicy och gällande dataskyddslagar.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Denna integritetspolicy förklarar och tydliggör dina rättigheter i
              förhållande till Lamina Clinic när det gäller behandlingen av dina
              personuppgifter och hur du kan utöva dessa rättigheter. Det är
              viktigt för oss att du känner dig trygg med vilka personuppgifter
              vi samlar in och hur vi kommer att behandla dem, därför täcker
              denna integritetspolicy all nödvändig information om vår
              behandling av dina personuppgifter.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Om du har ytterligare frågor, vänligen kontakta oss genom att
              använda kontaktinformationen nedan. För information om hur Lamina
              Clinic samlar in, hanterar och lagrar information som erhålls
              genom cookies, se vår separata cookie-policy.
            </Text>
          </Flex>

          <Flex w="full" direction="column" gap="8px">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              {t("COLLECTION OF YOUR PERSONAL DATA")}
            </Heading>

            <Text color="black" fontSize="18px" fontWeight={500}>
              Lamina Clinics webbplats, till exempel när du kontaktar oss via
              webbplatsen, ansöker om en av våra utannonserade tjänster eller
              skickar in en spontan jobbansökan.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Lamina Clinics programvaruprodukter och applikationer, till
              exempel när du loggar in, använder vår mobilapplikation och/eller
              sparar foton, text eller annat material på ditt privata konto.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              När du kontaktar oss via e-post, chatt eller telefon; och/eller
              genom avtal, fakturor eller annan affärskontakt, om du agerar som
              kontaktperson för någon av våra affärspartners.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Genom sociala medier, om du postar, kommenterar eller interagerar
              med oss.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              De typer av personuppgifter som vi kan samla in kan, beroende på
              sammanhanget, inkludera: namn och kontaktinformation, inklusive
              adress, mobilnummer och e-postadress; ditt personnummer; ditt
              användarnamn och lösenord som du kan behöva för att få åtkomst
              till vår plattform och/eller mobilapplikation; all information du
              delar genom att använda vår applikation, inklusive foton och
              information relaterad till din hälsa (om delad); statistik och
              analys av sociala medier/nätverkskommunikationer; samt
              jobbansökningsdokument såsom ditt CV, personligt brev och foto (om
              tillämpligt).
            </Text>
          </Flex>

          <Flex w="full" direction="column" gap="8px">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              {t("WHY DO WE PROCESS YOUR PERSONAL DATA?")}
            </Heading>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Lamina Clinic kan använda dina personuppgifter för följande
              ändamål, baserat på de lagliga grunderna som anges nedan.
              Genomförande av ett avtal: För genomförande av
              rekryteringsprocesser.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Uppfyllelse av en rättslig skyldighet: Vi sparar
              fakturadokumentation samt jobbansökningar och/eller
              rekryteringsdokumentation i enlighet med gällande bokföringslagar
              och bestämmelser.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Berättigat intresse: För att kunna kommunicera med dig via e-post
              och upprätthålla kontakt med våra affärspartners och andra
              avtalsparter.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Samtycke: Vi lagrar eventuella hälso-relaterade data som du
              tillhandahåller i appen och/eller i snabbmeddelande-applikationen
              baserat på ditt samtycke.
            </Text>
          </Flex>

          <Flex w="full" direction="column" gap="8px">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              {t("RETENTION OF PERSONAL DATA")}
            </Heading>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Lamina Clinic vidtar alla rimliga åtgärder för att säkerställa att
              dina personuppgifter behandlas och lagras på ett säkert sätt.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Dina personuppgifter kommer aldrig att lagras längre än tillåtet
              enligt gällande lag eller längre än nödvändigt för att uppfylla
              ovan angivna ändamål. Dina personuppgifter kommer att behandlas av
              oss under nedan angivna tidsperioder och därefter raderas.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Kontaktperson: Dina personuppgifter kommer att lagras så länge du
              är registrerad som kontaktperson för någon av våra affärspartners
              eller annan kontrakterad part till Lamina Clinic.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Kommunikation: Om du kontaktar Lamina Clinic, till exempel via
              e-post, kommer dina personuppgifter att lagras så länge som
              nödvändigt för att slutföra kontakten med dig.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Jobbsökande: Lamina Clinic kommer att lagra dina
              ansökningshandlingar, inklusive ditt CV, personligt brev och
              eventuell foto, under rekryteringsprocessen och under en
              efterföljande period på två år, för att vi ska kunna försvara oss
              mot potentiella rättsliga anspråk.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Lagstadgad skyldighet: Lamina Clinic sparar dokumentation som
              utgör redovisningsinformation i enlighet med gällande lagar och
              förordningar.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Samtycke: När vi behandlar dina personuppgifter baserat på ditt
              samtycke kommer vi endast att spara dina personuppgifter så länge
              vi har ditt samtycke att göra det.
            </Text>
          </Flex>

          <Flex w="full" direction="column" gap="8px">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              {t("TRANSFER OF PERSONAL DATA")}
            </Heading>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Lamina Clinic följer alltid stor försiktighet vid överföring av
              dina personuppgifter och dina personuppgifter överförs endast i
              enlighet med denna integritetspolicy och efter att ha vidtagit
              lämpliga säkerhetsåtgärder. Vi överför endast personuppgifter i
              enlighet med den information som anges nedan.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Affärstransaktioner: Om hela eller delar av Lamina Clinics
              verksamhet säljs eller integreras med annan verksamhet eller
              företag, kan dina personuppgifter lämnas ut till våra rådgivare,
              potentiella köpare och deras rådgivare, samt överföras till de nya
              ägarna av verksamheten.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Lagliga förpliktelser: Dina personuppgifter kan också lämnas ut i
              syfte att Lamina Clinic uppfyller vissa lagliga skyldigheter och
              de kan överföras till relevanta offentliga myndigheter när det är
              tillåtet och krävs enligt lag. Lamina Clinic överför inte
              personuppgifter till mottagare i länder utanför EU/EES och vi
              säljer inte dina personuppgifter.
            </Text>
          </Flex>

          <Flex w="full" direction="column" gap="8px">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              {t("WITHDRAWAL OF CONSENT")}
            </Heading>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Om vi behandlar dina personuppgifter baserat på ditt samtycke är
              det viktigt att du är medveten om din rätt att när som helst
              återkalla ditt samtycke. Du kan återkalla ditt samtycke genom att
              kontakta oss på de kontaktuppgifter som anges nedan.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              En sådan återkallelse kan göras helt eller delvis. Observera att
              om du återkallar ditt samtycke kan du eventuellt inte använda
              delar eller hela våra tjänster.
            </Text>
          </Flex>

          <Flex w="full" direction="column" gap="8px">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              {t("YOUR RIGHTS")}
            </Heading>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Du har rätt att begära information om vilka personuppgifter om dig
              som vi behandlar och hur de används genom att kontakta oss
              skriftligen på de kontaktuppgifter som anges nedan.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Du har också rätt att begära rättelse av felaktiga, ofullständiga
              eller oklara personuppgifter om dig genom att kontakta oss.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              För att skydda din integritet och dina personuppgifter kan vi
              kräva att du identifierar dig i samband med vår hjälp.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              I enlighet med gällande dataskyddslagar har du även rätt att
              begära att dina personuppgifter raderas eller att behandlingen av
              dina personuppgifter begränsas.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              I vissa situationer har du också rätt att invända mot behandlingen
              av dina personuppgifter och begära att dina personuppgifter ska
              överföras i elektroniskt format.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Du kan lämna in ett klagomål till Datainspektionen om du anser att
              Lamina Clinics behandling av dina personuppgifter inte sker i
              enlighet med gällande lagar.
            </Text>
          </Flex>

          <Flex w="full" direction="column" gap="8px">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              {t("CHANGES TO THE PRIVACY POLICY")}
            </Heading>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Observera att villkoren i integritetspolicyn kan komma att ändras
              eller kompletteras.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Vid ändringar kommer en ny version av villkoren att publiceras på
              Lamina Clinics webbplats. Följaktligen bör du regelbundet granska
              dessa villkor för att säkerställa att du är nöjd med eventuella
              ändringar. Vid väsentliga förändringar kommer vi dock att skicka
              ett e-postmeddelande till dig, om du har gett oss din
              e-postadress, för att informera dig om eventuella ändringar som
              har gjorts.
            </Text>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Om ändringarna gäller behandling av personuppgifter som vi utför
              baserat på ditt samtycke, kommer vi att ge dig möjlighet att ge
              ditt samtycke igen till behandlingen med hänsyn till de nya
              presenterade villkoren.
            </Text>
          </Flex>

          <Flex w="full" direction="column" gap="8px">
            <Heading textAlign={{ base: "start", sm: "center" }}>
              {t("CONTACT US")}
            </Heading>
            <Text color="black" fontSize="18px" fontWeight={500}>
              Om du har några frågor som rör integritetspolicyn, om du
              misstänker att en överträdelse av integritetspolicyn har skett
              eller om du vill kontakta oss av någon anledning som anges i
              integritetspolicyn, vänligen kontakta oss via mail på
            </Text>
            <ChakraLink
              color={"white !important"}
              href="mailto:info@laminaclinic.com"
              onClick={() => {
                basicEvent("Contact", "User clicked email");
              }}
            >
              <Text color="rgba(0, 38, 55, 1)" fontSize="18px">
                info@laminaclinic.com
              </Text>
            </ChakraLink>
          </Flex>
        </Flex>
      </Flex>
    </Box>
    //</div>
  );
};

export default PrivacyPolicy;
