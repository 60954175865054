import * as Yup from "yup";

export const editValidationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "First name is too short")
    .required("Försök igen"),
  last_name: Yup.string()
    .min(2, "Last name is too short")
    .required("Försök igen"),
  email: Yup.string().email("Invalid email address").required("Försök igen"),
});
