import {
  Box,
  Center,
  Heading,
  HStack,
  VStack,
  Text,
  Divider,
  Flex,
  Button,
} from "@chakra-ui/react";
import { useFetchAdminReport } from "features/reporting/api/client";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMessage } from "react-icons/ai";
import { BiPhoneCall } from "react-icons/bi";
import { BsFiletypeCsv, BsFillPeopleFill, BsPersonVideo } from "react-icons/bs";
import { GrDocumentCsv } from "react-icons/gr";
import { IoStatsChartSharp } from "react-icons/io5";
import { paraget } from "services/helpers/paraget";
import { useURLParameters } from "services/hooks/useURLParameters";

interface GeneralStatsBarProps {}

const GeneralStatsBar: FunctionComponent<GeneralStatsBarProps> = () => {
  const p = useURLParameters();

  const { data: reportsData } = useFetchAdminReport({
    age_end: paraget(p, "age_end", "number"),
    age_start: paraget(p, "age_start", "number"),
    dose_end: paraget(p, "dose_end", "number"),
    dose_start: paraget(p, "dose_start", "number"),
    medication: paraget(p, "medication", "string"),
    pace: paraget(p, "pace", "number"),
    profession: paraget(p, "profession", "string"),
    timeEnd: paraget(p, "timeEnd", "date"),
    timeStart: paraget(p, "timeStart", "date"),
  });

  const { t } = useTranslation();

  const generateCSV = () => {
    if (!reportsData) {
      return;
    }
    let CSVstring = "";

    //add csv header
    CSVstring += ",Total,Nurse,Doctor\n";

    //Total users
    CSVstring += "Total users," + reportsData?.data.users + "\n";

    //Initial meeting with nurse
    CSVstring +=
      "Initial meeting with nurse," +
      reportsData?.data.initial_meetings_nurse +
      "\n";

    //Initial meeting with doctor
    CSVstring +=
      "Initial meeting with doctor," +
      reportsData?.data.initial_meetings_doctor +
      "\n";

    CSVstring += "Triage started," + reportsData?.data.triage_started + "\n";

    //Initial meeting with nurse
    CSVstring += "Triage ended," + reportsData?.data.triage_ended + "\n";

    CSVstring += `Messages\n`;

    CSVstring += `Received,${
      reportsData?.data.messages.received.nurse! +
      reportsData?.data.messages.received.doctor!
    },${reportsData?.data.messages.received.nurse},${
      reportsData?.data.messages.received.doctor
    }\n`;

    CSVstring += `Sent,${
      reportsData?.data.messages.sent.nurse! +
      reportsData?.data.messages.sent.doctor!
    },${reportsData?.data.messages.sent.nurse},${
      reportsData?.data.messages.sent.doctor
    }\n`;

    CSVstring += `Video calls\n`;

    CSVstring += `Received,${
      reportsData?.data.calls.video_call.receive.nurse! +
      reportsData?.data.calls.video_call.receive.doctor!
    },${reportsData?.data.calls.video_call.receive.nurse},${
      reportsData?.data.calls.video_call.receive.doctor
    }\n`;

    CSVstring += `Made,${
      reportsData?.data.calls.video_call.receive.nurse! +
      reportsData?.data.calls.video_call.receive.doctor!
    },${reportsData?.data.calls.video_call.receive.nurse},${
      reportsData?.data.calls.video_call.receive.doctor
    }\n`;

    CSVstring += `Voice calls\n`;

    CSVstring += `Received,${
      reportsData?.data.calls.voice_call.receive.nurse! +
      reportsData?.data.calls.voice_call.receive.doctor!
    },${reportsData?.data.calls.voice_call.receive.nurse},${
      reportsData?.data.calls.voice_call.receive.doctor
    }\n`;

    CSVstring += `Made,${
      reportsData?.data.calls.voice_call.receive.nurse! +
      reportsData?.data.calls.voice_call.receive.doctor!
    },${reportsData?.data.calls.voice_call.receive.nurse},${
      reportsData?.data.calls.voice_call.receive.doctor
    }\n`;

    CSVstring += `Meetings\n`;

    Object.entries(reportsData.data.meetings).map(([key, value]) => {
      CSVstring += `${key},${value}\n`;
    });
    let csvContent = "data:text/csv;charset=utf-8," + CSVstring ?? "";

    var link = document.createElement("a");
    link.href = encodeURI(csvContent);

    link.download = "statistics.csv";
    link.click();
  };

  return (
    <>
      <Box overflowX={"auto"} py={"1rem"} px={"0.5rem"}>
        <Button
          variant={"dark"}
          mb={"1rem"}
          leftIcon={<BsFiletypeCsv color={"white"} />}
          onClick={() => {
            generateCSV();
          }}
        >
          {t("Export to CSV")}
        </Button>
        <HStack
          gap={"1rem"}
          w={"fit-content !important"}
          alignItems={"stretch"}
        >
          <VStack
            borderRadius={"0.5rem"}
            boxShadow={"0px 0px 4px 4px rgba(0, 0, 0, 0.05)"}
            p={"1rem"}
            minW={"18rem"}
            alignItems={"stretch"}
            bg={"white"}
            gap={0}
            px="1.5rem"
          >
            <Center
              bg={"blueish.500"}
              w={"2rem"}
              h={"2rem"}
              borderRadius={"50%"}
            >
              <IoStatsChartSharp color={"white"} fontSize={"1.25rem"} />
            </Center>
            <Heading fontWeight={"400"}>{t("General statistics")}</Heading>
            <Divider mt={"1rem !important"} mb={"0.5rem !important"} />
            <Text color={"blueish.900"} fontSize={"1rem"}>
              {t("Total users")}: <b>{reportsData?.data.users}</b>
            </Text>
            <Text color={"blueish.900"} fontSize={"1rem"}>
              {t("Initial meetings with nurse")}:{" "}
              <b>{reportsData?.data.initial_meetings_nurse}</b>
            </Text>
            <Text color={"blueish.900"} fontSize={"1rem"}>
              {t("Initial meetings with doctor")}:{" "}
              <b>{reportsData?.data.initial_meetings_doctor}</b>
            </Text>

            <Text color={"blueish.900"} fontSize={"1rem"}>
              {t("Triage started")}: <b>{reportsData?.data.triage_started}</b>
            </Text>

            <Text color={"blueish.900"} fontSize={"1rem"}>
              {t("Triage ended")}: <b>{reportsData?.data.triage_ended}</b>
            </Text>
          </VStack>

          <VStack
            borderRadius={"0.5rem"}
            boxShadow={"0px 0px 4px 4px rgba(0, 0, 0, 0.05)"}
            p={"1rem"}
            minW={"18rem"}
            alignItems={"stretch"}
            bg={"white"}
            gap={0}
            px="1.5rem"
          >
            <Center
              bg={"blueish.500"}
              w={"2rem"}
              h={"2rem"}
              borderRadius={"50%"}
            >
              <AiOutlineMessage color={"white"} fontSize={"1.25rem"} />
            </Center>
            <Heading fontWeight={"400"}>{t("Messages")}</Heading>
            <Divider mt={"1rem !important"} mb={"0.5rem !important"} />
            <Flex justifyContent={"space-between"}>
              <Box>
                <Heading fontSize={"1.1rem"} fontWeight={"300"}>
                  {t("Nurse")}
                </Heading>
                <Text color={"blueish.900"} fontSize={"1rem"}>
                  {t("Sent")}: <b>{reportsData?.data.messages.sent.nurse}</b>
                </Text>
                <Text color={"blueish.900"} fontSize={"1rem"}>
                  {t("Received")}:{" "}
                  <b>{reportsData?.data.messages.received.nurse}</b>
                </Text>
              </Box>
              <Divider orientation="vertical" />
              <Box>
                <Heading fontSize={"1.1rem"} fontWeight={"300"}>
                  {t("Doctor")}
                </Heading>
                <Text color={"blueish.900"} fontSize={"1rem"}>
                  {t("Sent")}: <b>{reportsData?.data.messages.sent.doctor}</b>
                </Text>
                <Text color={"blueish.900"} fontSize={"1rem"}>
                  {t("Received")}:{" "}
                  <b>{reportsData?.data.messages.received.doctor}</b>
                </Text>
              </Box>
            </Flex>
            <Divider />

            <Flex alignItems={"center"} gap={"1rem"}>
              <Heading fontSize={"1.1rem"} fontWeight={"300"}>
                {t("Total")}:
              </Heading>

              <Text color={"blueish.900"} fontSize={"1rem"}>
                {t("Sent")}:{" "}
                <b>
                  {(reportsData?.data.messages.sent.doctor ?? 0) +
                    (reportsData?.data.messages.sent.nurse ?? 0)}
                </b>
              </Text>
              <Text color={"blueish.900"} fontSize={"1rem"}>
                {t("Received")}:{" "}
                <b>
                  {(reportsData?.data.messages.received.doctor ?? 0) +
                    (reportsData?.data.messages.received.nurse ?? 0)}
                </b>
              </Text>
            </Flex>
          </VStack>
          <VStack
            borderRadius={"0.5rem"}
            boxShadow={"0px 0px 4px 4px rgba(0, 0, 0, 0.05)"}
            p={"1rem"}
            minW={"18rem"}
            alignItems={"stretch"}
            bg={"white"}
            gap={0}
            px="1.5rem"
          >
            <Center
              bg={"blueish.500"}
              w={"2rem"}
              h={"2rem"}
              borderRadius={"50%"}
            >
              <BsPersonVideo color={"white"} fontSize={"1.25rem"} />
            </Center>
            <Heading fontWeight={"400"}>{t("Video calls")}</Heading>
            <Divider mt={"1rem !important"} mb={"0.5rem !important"} />
            <Flex justifyContent={"space-between"}>
              <Box>
                <Heading fontSize={"1.1rem"} fontWeight={"300"}>
                  {t("Nurse")}
                </Heading>
                <Text
                  color={"blueish.900"}
                  fontSize={"1rem"}
                  textTransform="capitalize"
                >
                  {t("made")}:{" "}
                  <b>{reportsData?.data.calls.video_call.made.nurse}</b>
                </Text>
                <Text color={"blueish.900"} fontSize={"1rem"}>
                  {t("Received")}:{" "}
                  <b>{reportsData?.data.calls.video_call.receive.nurse}</b>
                </Text>
              </Box>
              <Divider orientation="vertical" />
              <Box>
                <Heading fontSize={"1.1rem"} fontWeight={"300"}>
                  {t("Doctor")}
                </Heading>
                <Text
                  color={"blueish.900"}
                  fontSize={"1rem"}
                  textTransform="capitalize"
                >
                  {t("made")}:{" "}
                  <b>{reportsData?.data.calls.video_call.made.doctor}</b>
                </Text>
                <Text color={"blueish.900"} fontSize={"1rem"}>
                  {t("Received")}:{" "}
                  <b>{reportsData?.data.calls.video_call.receive.doctor}</b>
                </Text>
              </Box>
            </Flex>
            <Divider />

            <Flex alignItems={"center"} gap={"1rem"}>
              <Heading fontSize={"1.1rem"} fontWeight={"300"}>
                {t("Total")}:
              </Heading>

              <Text
                color={"blueish.900"}
                fontSize={"1rem"}
                textTransform="capitalize"
              >
                {t("made")}:{" "}
                <b>
                  {(reportsData?.data.calls.video_call.made.doctor ?? 0) +
                    (reportsData?.data.calls.video_call.made.nurse ?? 0)}
                </b>
              </Text>
              <Text color={"blueish.900"} fontSize={"1rem"}>
                {t("Received")}:{" "}
                <b>
                  {(reportsData?.data.calls.video_call.receive.doctor ?? 0) +
                    (reportsData?.data.calls.video_call.receive.nurse ?? 0)}
                </b>
              </Text>
            </Flex>
          </VStack>

          <VStack
            borderRadius={"0.5rem"}
            boxShadow={"0px 0px 4px 4px rgba(0, 0, 0, 0.05)"}
            p={"1rem"}
            minW={"18rem"}
            alignItems={"stretch"}
            bg={"white"}
            gap={0}
            px="1.5rem"
          >
            <Center
              bg={"blueish.500"}
              w={"2rem"}
              h={"2rem"}
              borderRadius={"50%"}
            >
              <BiPhoneCall color={"white"} fontSize={"1.25rem"} />
            </Center>
            <Heading fontWeight={"400"}>{t("Audio calls")}</Heading>
            <Divider mt={"1rem !important"} mb={"0.5rem !important"} />
            <Flex justifyContent={"space-between"}>
              <Box>
                <Heading fontSize={"1.1rem"} fontWeight={"300"}>
                  {t("Nurse")}
                </Heading>
                <Text
                  color={"blueish.900"}
                  fontSize={"1rem"}
                  textTransform="capitalize"
                >
                  {t("made")}:{" "}
                  <b>{reportsData?.data.calls.voice_call.made.nurse}</b>
                </Text>
                <Text color={"blueish.900"} fontSize={"1rem"}>
                  {t("Received")}:{" "}
                  <b>{reportsData?.data.calls.voice_call.receive.nurse}</b>
                </Text>
              </Box>
              <Divider orientation="vertical" />
              <Box>
                <Heading fontSize={"1.1rem"} fontWeight={"300"}>
                  {t("Doctor")}
                </Heading>
                <Text
                  color={"blueish.900"}
                  fontSize={"1rem"}
                  textTransform="capitalize"
                >
                  {t("made")}:{" "}
                  <b>{reportsData?.data.calls.voice_call.made.doctor}</b>
                </Text>
                <Text color={"blueish.900"} fontSize={"1rem"}>
                  {t("Received")}:{" "}
                  <b>{reportsData?.data.calls.voice_call.receive.doctor}</b>
                </Text>
              </Box>
            </Flex>
            <Divider />

            <Flex alignItems={"center"} gap={"1rem"}>
              <Heading fontSize={"1.1rem"} fontWeight={"300"}>
                {t("Total")}:
              </Heading>

              <Text
                color={"blueish.900"}
                fontSize={"1rem"}
                textTransform="capitalize"
              >
                {t("made")}:{" "}
                <b>
                  {(reportsData?.data.calls.voice_call.made.doctor ?? 0) +
                    (reportsData?.data.calls.voice_call.made.nurse ?? 0)}
                </b>
              </Text>
              <Text color={"blueish.900"} fontSize={"1rem"}>
                {t("Received")}:{" "}
                <b>
                  {(reportsData?.data.calls.voice_call.receive.doctor ?? 0) +
                    (reportsData?.data.calls.voice_call.receive.nurse ?? 0)}
                </b>
              </Text>
            </Flex>
          </VStack>

          <VStack
            borderRadius={"0.5rem"}
            boxShadow={"0px 0px 4px 4px rgba(0, 0, 0, 0.05)"}
            p={"1rem"}
            minW={"25rem"}
            alignItems={"stretch"}
            gap={0}
            bg={"white"}
            px="1.5rem"
          >
            <Center
              bg={"blueish.500"}
              w={"2rem"}
              h={"2rem"}
              borderRadius={"50%"}
            >
              <BsFillPeopleFill color={"white"} fontSize={"1.25rem"} />
            </Center>
            <Heading fontWeight={"400"}>{t("Meetings")}</Heading>
            <Divider mt={"1rem !important"} mb={"0.5rem !important"} />

            {Object.entries(reportsData?.data.meetings ?? {}).length > 0 ? (
              Object.entries(reportsData?.data.meetings ?? {}).map(
                (meeting) => {
                  return (
                    <Text color={"blueish.900"} fontSize={"1rem"}>
                      {t(meeting[0])}: <b>{meeting[1]}</b>
                    </Text>
                  );
                }
              )
            ) : (
              <Text color={"blueish.900"} fontSize={"1rem"}>
                {t("No meetings data for these filters")}
              </Text>
            )}
          </VStack>
        </HStack>
      </Box>
    </>
  );
};

export default GeneralStatsBar;
