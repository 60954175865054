export function formatTime(dateString: string) {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit" as const,
    minute: "2-digit" as const,
    hour12: true,
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
}

export function formatDateForDays(dateString: string | number | Date) {
  const messageDate = new Date(dateString);
  const currentDate = new Date();

  if (
    messageDate.getDate() === currentDate.getDate() &&
    messageDate.getMonth() === currentDate.getMonth() &&
    messageDate.getFullYear() === currentDate.getFullYear()
  ) {
    return "Today";
  } else {
    const day = messageDate.getDate();
    const month =
      messageDate
        .toLocaleString("sv", { month: "short" })
        .toUpperCase()
        .charAt(0) +
      messageDate.toLocaleString("sv", { month: "short" }).slice(1);
    return `${day} ${month}`;
  }
}
