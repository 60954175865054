import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";
import { FunctionComponent, useEffect } from "react";
import NotificationRequestAccepted from "../notificationRequestAccepted";
import NotificationRequestDecline from "../notifacationRequestDecline";

interface NotificationPopupContainerProps {
  isOpen: boolean;
  onClose(): void;
  editMode?: boolean;
  user_id: string;
  id: string;
}

const NotificationPopupContainer: React.FC<NotificationPopupContainerProps> = ({
  isOpen,
  onClose,
  editMode,
  id,
  user_id,
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        mt={{ base: "85rem", sm: "0rem" }}
        maxW={{ base: "750px", lg: "860px" }}
        minH={{ base: "300px", lg: "500px" }}
        flexDir={{ base: "column", sm: "row" }}
        alignItems={"stretch"}
        borderRadius={"20px"}
        w="100%"
      >
        {editMode ? (
          <NotificationRequestAccepted onClose={onClose} id={id} />
        ) : (
          <NotificationRequestDecline onClose={onClose} id={id} />
        )}
      </ModalContent>
    </Modal>
  );
};

export default NotificationPopupContainer;
