import { VStack, Heading, Flex, Text, Box, Center } from "@chakra-ui/react";
import { Mood } from "features/admin/api/types";
import { t } from "i18next";
import { FunctionComponent } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { hexToRgb } from "services/helpers/colors";

interface MoodOverviewItemProps {
  mood: Mood;
}

const moodColors = [
  "#43B3BA",
  "#589AAF",
  "#568CB2",
  "#647CAD",
  "#796A9F",
  "#8B5687",
  "#944366",
];

const MoodOverviewItem: FunctionComponent<MoodOverviewItemProps> = ({
  mood,
}) => {
  const color = moodColors[mood.value - 1];

  return (
    <VStack
      bg={hexToRgb(color, 0.1)}
      minW={"15rem"}
      maxW={"15rem"}
      h={"15.5rem"}
      borderRadius={"0.5rem"}
      border={`1px solid ${color}`}
      spacing={0}
      position={"relative"}
      alignItems={"flex-start"}
    >
      <Center
        position={"absolute"}
        color={"white"}
        bg={color}
        borderRadius={"50%"}
        fontFamily={"secondary"}
        fontSize={"1.25rem"}
        fontWeight={600}
        h={"2.5rem"}
        w={"2.5rem"}
        top={"-1.25rem"}
        left={"1rem"}
      >
        {mood.value}
      </Center>

      {(!mood.is_tolerable ||
        (mood.comments_argent && mood.comments_argent?.length)) && (
        <Box position={"absolute"} right={"0.25rem"} top={"0.25rem"}>
          <RiErrorWarningFill fontSize={"1.5rem"} color={color} />
        </Box>
      )}

      <Box py={"1rem"} pt={"1.5rem"} px={"1rem"}>
        <Box mb={"0.75rem !important"}>
          <Heading
            fontSize={"1.25rem"}
            color={color}
            fontFamily={"secondary"}
            fontWeight={500}
          >
            {mood.is_tolerable ? t("Tolerable") : t("Not tolerable")}
          </Heading>
        </Box>

        <Box mb={"1rem !important"}>
          <Text
            color={color}
            fontSize={"1rem"}
            textTransform={"capitalize"}
            fontWeight={400}
            lineHeight={"1rem"}
          >
            {new Date(mood.date).toLocaleDateString(t("locale") ?? "en-GB", {
              weekday: "short",
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </Text>

          <Text
            color={color}
            fontSize={"1rem"}
            textTransform={"capitalize"}
            fontWeight={400}
          >
            {new Date(mood.date).toLocaleString(t("locale") ?? "en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })}
          </Text>
        </Box>
      </Box>

      <Box flex={1} overflow={"auto"} w={"100%"} px={"1rem"}>
        {mood.comments_argent && mood.comments_argent?.length > 0 && (
          <Box w={"100%"}>
            <Text
              color={hexToRgb(color, 1)}
              fontWeight={600}
              fontSize={"0.75em"}
              textTransform={"uppercase"}
              mb={"0.5rem"}
            >
              Akuta symptom:
            </Text>
            <Flex flexWrap={"wrap"} gap={"0.3rem"}>
              {mood.comments_argent?.map((comment, index) => (
                <Box
                  key={index}
                  fontSize={"0.875rem"}
                  bg={hexToRgb(color, 0.1)}
                  borderRadius={"0.5rem"}
                  color={color}
                  fontWeight={500}
                  px={"0.6rem"}
                  py={"0.35rem"}
                >
                  {comment}
                </Box>
              ))}
            </Flex>
          </Box>
        )}

        {mood.value > 3 && (
          <Box w={"100%"}>
            <Text
              color={hexToRgb(color, 0.5)}
              fontWeight={600}
              fontSize={"0.75em"}
              textTransform={"uppercase"}
              mb={"0.5rem"}
            >
              Symptom:
            </Text>
            <Flex flexWrap={"wrap"} gap={"0.3rem"}>
              {mood.comments.map((comment, index) => (
                <Box
                  key={index}
                  fontSize={"0.875rem"}
                  bg={hexToRgb(color, 0.1)}
                  borderRadius={"0.5rem"}
                  color={color}
                  fontWeight={500}
                  px={"0.6rem"}
                  py={"0.35rem"}
                >
                  {comment}
                </Box>
              ))}
            </Flex>
          </Box>
        )}
      </Box>
    </VStack>
  );
};

export default MoodOverviewItem;
