import React from "react";
import { Input } from "@chakra-ui/react";
import { motion } from "framer-motion";

interface DiscountInputProps {
  orderId: string;
  value: string;
  onChange: (orderId: string, value: string) => void;
  onSubmit: (orderId: string) => void;
}

const DiscountInput: React.FC<DiscountInputProps> = ({
  orderId,
  value,
  onChange,
  onSubmit,
}) => {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSubmit(orderId);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.2 }}
    >
      <Input
        p="0px"
        type="number"
        fontSize="1rem"
        minH="0px !important"
        placeholder="Enter..."
        value={value}
        min={0}
        max={100}
        onChange={(e) => onChange(orderId, e.target.value)}
        onKeyDown={handleKeyPress}
      />
    </motion.div>
  );
};

export default DiscountInput;
