export const paymentStatusTransform = (status?: string | null) => {
  const availableTransforms: Record<string, string> = {
    PENDING: "BOOKED, NOT PAID",
    PAID: "BOOKED AND PAID",
  };

  if (typeof status === "undefined") return "CALL NOT PAID";

  if (!status) return "CALL NOT BOOKED";

  return availableTransforms[status] ?? status;
};
