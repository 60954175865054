import { Box, Flex, Hide, Input, Show } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import FaqHeader from "features/faqInfo/components/faqHeader";

import { discoverBlocks } from "features/faqInfo/utils";

import HomeFooter from "features/homeSections/components/homeFooter";
import ContactForm from "pages/ContactForm";
import { useState } from "react";

import FaqFrequentlyQuestions from "features/faqInfo/components/faqFrequentlyQuestions";
import FaqDiscoverBlock from "features/faqInfo/components/faqDiscoverBlock";
import FaqNavigationButtons from "features/faqInfo/components/faqNavigationButtons";
import ScrollToContactForm from "features/faqInfo/components/faqDiscoverTwo/components/ScrollToContactForm";
import FooterMobile from "features/homeSections/components/homeFooterMobile";
import { useTranslation } from "react-i18next";
import FaqTreatmentQuestionsBlock from "features/faqInfo/components/faqTreatmentQuestionBlock";

const FAQ = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleDiscoverClick = (id: string) => {
    history.push(`/faq/${id}`);
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  return (
    <Flex w="100%" direction="column" maxW="1352px">
      <div id="faq-header">
        <FaqHeader />

        <Input
          value={searchQuery}
          onChange={handleChange}
          placeholder={`${t("Search questions")}...`}
          bg="white !important"
          mb="2rem"
          w={{ base: "90%", sm: "90%", md: "87%", lg: "96%" }}
          ml={{
            base: "1rem",
            sm: "3.5rem",
            md: "5rem",
            lg: "1.5rem",
          }}
        />
      </div>

      <Box bg="white" w="100vw" position="relative" left="calc(-50vw + 50%)">
        <Flex
          w="full"
          mx="auto"
          maxW={{ base: "auto", md: "1352px", lg: "1652px" }}
          align="flex-start"
          justify="center"
          direction="column"
          py="2rem"
          px={{ base: "", sm: "", md: "15rem", lg: "14rem" }}
        >
          {/* Navigate Buttons */}
          <FaqNavigationButtons scrollToSection={scrollToSection} />
        </Flex>

        <Flex
          mx="auto"
          w="100%"
          maxW={{ base: "auto", sm: "1278px", md: "1352px", lg: "1652px" }}
          gap={{ base: ".5rem", sm: "1rem", md: "2rem" }}
          py={{ base: ".5rem", sm: "2rem" }}
          direction={{ base: "column", sm: "row" }}
          px={{ base: "2rem", sm: "7rem", md: "12.5rem", lg: "11rem" }}
        >
          {discoverBlocks.map(({ id, image, title }) => (
            <FaqDiscoverBlock
              id={id}
              image={image}
              title={title}
              key={id}
              handleDiscoverClick={handleDiscoverClick}
            />
          ))}
        </Flex>

        {/* BLOCKS */}
        <Box w="full">
          <div id="treatment-questions">
            <FaqTreatmentQuestionsBlock searchQuery={searchQuery} />
          </div>
        </Box>
      </Box>

      <Box
        bg="rgba(238, 245, 247, 1)"
        w="100vw"
        position="relative"
        left="calc(-50vw + 50%)"
      >
        <Box w="full">
          <div id="frequently-questions">
            <FaqFrequentlyQuestions searchQuery={searchQuery} />
          </div>
        </Box>

        <>
          <ScrollToContactForm />
          <div id="contact-form">
            <ContactForm />
          </div>
        </>

        <Show above="sm">
          <HomeFooter />
        </Show>
        <Hide above="sm">
          <FooterMobile />
        </Hide>
      </Box>
    </Flex>
  );
};

export default FAQ;
