import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  VStack,
  Text,
} from "@chakra-ui/react";
import { dayNumber } from "features/dashboard/dateHelper";
import { useFetchAllDoctors } from "features/doctors/api/client";
import { useAdminUserInfo } from "features/userInfo/api/client";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IoAddSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { usePatchAdminUserProfile } from "../../../dashboard/api/client";
import { GrClose } from "react-icons/gr";
import { AiOutlineEdit } from "react-icons/ai";

interface PatientsDoctorProps {
  id?: string;
}

const PatientsDoctor: FunctionComponent<PatientsDoctorProps> = ({
  id: propId,
}) => {
  const { id: pathId } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const id = propId ?? pathId;

  const { data: userData } = useAdminUserInfo(id);
  const { data: doctorsList } = useFetchAllDoctors();

  const { trigger: patchProfile, isMutating: isPatchingProfile } =
    usePatchAdminUserProfile(id);

  const handleAddDoctor = (doctorId: string) => {
    patchProfile({ doctors: [...(userData?.doctors ?? []), doctorId] });
  };

  const handleAssignSingleDoctor = (doctorId: string) => {
    patchProfile({ doctors: [doctorId] });
  };

  const handleRemoveDoctor = (doctorId: string) => {
    patchProfile({
      doctors: userData?.doctors.filter((e) => e !== doctorId),
    });
  };

  const userHasDoctors = userData?.doctors && userData.doctors.length > 0;

  return (
    <>
      <Box
        px={"2.5rem"}
        py={"2rem"}
        w={"100%"}
        bg={"rgba(88, 154, 175, 0.1)"}
        border={"1px solid #589AAF"}
        borderRadius={"0.5rem"}
        h={"auto"}
      >
        <Flex justifyContent={"space-between"}>
          <Heading fontSize={"1.25rem"} color={"blueish.500"} mb={"0.25rem"}>
            {t("Patient's doctors")}
          </Heading>

          <Popover>
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <IconButton
                    icon={userHasDoctors ? <AiOutlineEdit /> : <IoAddSharp />}
                    aria-label={""}
                    w={"2rem"}
                    p={"0 !important"}
                    h={"2rem"}
                    borderRadius={"1rem"}
                    minWidth={"0"}
                    isLoading={isPatchingProfile}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton zIndex={10} />
                  <PopoverBody>
                    <VStack pt={"1.5rem"}>
                      <Heading
                        fontWeight={"400"}
                        fontSize={"1rem"}
                        w={"100%"}
                        pl={"1rem"}
                      >
                        {!userHasDoctors
                          ? t("Choose a doctor for this patient")
                          : t("Assign a new doctor for this patient")}
                        :
                      </Heading>

                      {userHasDoctors && <Heading></Heading>}

                      <VStack w={"100%"} alignItems={"flex-start"}>
                        {doctorsList?.doctors
                          .filter((e) => !userData?.doctors?.includes(e.id))
                          .map((doctor) => {
                            return (
                              <Flex
                                color={"blueish.900"}
                                fontWeight={400}
                                w={"100%"}
                                transition={"all 0.3s ease-out"}
                                _hover={{
                                  bg: "#589AAF10",
                                }}
                                cursor={"pointer"}
                                borderRadius={"0.5rem"}
                                h={"2rem"}
                                verticalAlign={"center"}
                                onClick={() => {
                                  handleAssignSingleDoctor(doctor.id);
                                  //handleAddDoctor(doctor.id);
                                  onClose();
                                }}
                                alignItems={"center"}
                                pl={"1rem"}
                              >
                                {doctor.first_name} {doctor.last_name}
                              </Flex>
                            );
                          })}
                      </VStack>
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </Flex>

        <Divider borderColor={"#589AAF"} opacity={0.4} my={"1.5rem"} />
        {(!userData?.doctors || userData.doctors.length === 0) && (
          <Center opacity={0.5}>
            {t("This patient does not have a doctor assigned to them")}
          </Center>
        )}
        <Flex gap={"1rem"}>
          {userData?.doctors?.map((doctorId) => {
            const doctor = doctorsList?.doctors.find((e) => e.id === doctorId);

            return (
              <Flex
                fontSize={"1rem"}
                alignItems={"center"}
                gap={"1rem"}
                borderBottom={"1px solid #589AAF5f"}
                width={"auto"}
              >
                <Text color={"blueish.900"} fontSize={"inherit"}>
                  {doctor?.first_name} {doctor?.last_name}
                </Text>
                {/* <IconButton
                  opacity={"0.3"}
                  fontSize={"inherit"}
                  icon={<GrClose fontSize={"0.8rem"} />}
                  aria-label={""}
                  minWidth={0}
                  px={0}
                  bg={"transparen"}
                  border={"none"}
                  transition={"all 0.3s ease-out"}
                  _hover={{
                    opacity: 0.5,
                  }}
                  onClick={() => {
                    handleRemoveDoctor(doctor?.id ?? "");
                  }}
                /> */}
              </Flex>
            );
          })}
        </Flex>
      </Box>
    </>
  );
};

export default PatientsDoctor;
