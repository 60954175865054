import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import { UserTrackingNumType } from "features/dashboard/api/type";

import copy from "assets/icons/copy.svg";
import { useTranslation } from "react-i18next";

const UserTrackingNumber = () => {
  const { t } = useTranslation();

  const tracking: UserTrackingNumType[] = [
    { userNumber: 13660056282065, delivery: "DPD" },
  ];

  return (
    <>
      {tracking.map((track, index) => (
        <Flex direction="column" gap={".5rem"} w="100%">
          <Flex direction="column" gap={".5rem"}>
            <Flex direction="column" gap={"0.25rem"}>
              <Heading fontSize="16px" color={"blueish.500"} fontWeight={500}>
                {t("TRACKING NUMBER")}
              </Heading>
              <Text color="blackish.200" fontSize="16px" fontWeight="bold">
                {track.userNumber}
              </Text>
            </Flex>
            <Flex
              borderRadius={"4px"}
              bg={"#EEE8A9"}
              maxW="5.9rem"
              p="4px 12px 4px 12px"
            >
              <Flex gap={1} align="center">
                <Image src={copy} alt={"icon to copy tracking number"} />
                <Text
                  color={"rgba(0, 38, 55, 1)"}
                  fontSize={"16px"}
                  fontWeight="bold"
                >
                  {t("Copy")}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex direction="column">
            <Heading fontSize="16px" color={"blueish.500"} fontWeight={500}>
              {t("DELIVERY SERVICE")}
            </Heading>
            <Text color="blackish.200" fontSize="16px" fontWeight={500}>
              {track.delivery}
            </Text>
          </Flex>
        </Flex>
      ))}
    </>
  );
};

export default UserTrackingNumber;
