import {
  Flex,
  Heading,
  Text,
  Image,
  Box,
  Divider,
  HStack,
  Button,
} from "@chakra-ui/react";

import circle_minus from "assets/icons/circle_minus.svg";

import { useAdminUserInfo } from "features/userInfo/api/client";
import { DateRangeType } from "features/userInfo/api/type";
import CalendarDateSelection from "features/userInfo/components/CalendarDateSelector";
import MoodCheckupCalendar from "features/userInfo/components/MoodCheckupCalendar";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import UserChart from "../adminUserChart";
import AdjustPlanButton from "features/doctors/components/AdjustPlanButton";

import { CiCalendar } from "react-icons/ci";
import { MdShowChart } from "react-icons/md";
import ChartPeriodToggle from "./components/ChartPeriodToggle";

const ActiveTaperingPlan = () => {
  const { id } = useParams<{ id: string }>();
  const { data: userData } = useAdminUserInfo(id);

  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState<DateRangeType>({
    dateTo: new Date(),
  });

  const [showChart, setShowChart] = useState(true);

  return (
    <>
      {!!userData?.has_treatment && (
        <Flex
          w="100%"
          py="2.625rem"
          px="2rem"
          borderRadius="12px"
          bg="white"
          direction="column"
        >
          <Flex
            w="full"
            mb={"1rem"}
            justifyContent={"space-between"}
            direction={{ base: "column", sm: "row" }}
          >
            <Heading
              color="#589AAF"
              fontSize="1.25rem"
              fontWeight={600}
              letterSpacing={0.7}
              textTransform="uppercase"
              mt="3.5"
            >
              {t("Active tapering plan")}
            </Heading>

            <Flex gap=".75rem" alignContent="center">
              <AdjustPlanButton title={t("Adjust Plan or Stabilize Patient")} />

              <CalendarDateSelection
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </Flex>
          </Flex>

          <Divider />

          <HStack w="full" justify="space-between">
            <Flex w="full" mt={{ base: "3", sm: "5" }} align="flex-start">
              <Box
                px="1.25rem"
                py=".438rem"
                pb=".438rem"
                display="flex"
                alignItems="center"
                gap=".5rem"
                border="1px solid #E5E9EB"
                borderLeftRadius=".5rem"
                onClick={() => setShowChart(false)}
                bg={showChart ? "transparent" : "#589AAF1A"}
                cursor="pointer"
              >
                <CiCalendar
                  color={
                    showChart ? "rgba(0,38,55,0.5)" : "rgba(88, 154, 175, 1)"
                  }
                  size="1.25rem"
                />
                <Heading
                  fontSize="1rem"
                  fontFamily="main"
                  fontWeight={showChart ? 400 : 700}
                  letterSpacing={0.7}
                  color={showChart ? "rgba(0,38,55,0.5)" : "blueish.500"}
                >
                  {t("CALENDAR").charAt(0).toUpperCase() +
                    t("CALENDAR").slice(1).toLowerCase()}
                </Heading>
                <Box />
              </Box>

              <Box
                px="1.25rem"
                py=".438rem"
                pb=".438rem"
                display="flex"
                alignItems="center"
                gap=".5rem"
                border="1px solid #E5E9EB"
                borderRightRadius=".5rem"
                bg={showChart ? "#589AAF1A" : "transparent"}
                onClick={() => setShowChart(true)}
                cursor="pointer"
                color={showChart ? "#002637" : "blueish.500"}
              >
                <MdShowChart
                  color={
                    showChart ? "rgba(88, 154, 175, 1)" : "rgba(0,38,55,0.5)"
                  }
                  size="1.25rem"
                />
                <Heading
                  fontSize="1rem"
                  fontFamily="main"
                  fontWeight={showChart ? 700 : 400}
                  letterSpacing={0.7}
                  color={showChart ? "blueish.500" : "rgba(0,38,55,0.5)"}
                  textTransform={"capitalize"}
                >
                  {t("CHART").toLowerCase()}
                </Heading>
                <Box />
              </Box>
            </Flex>

            <ChartPeriodToggle
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </HStack>

          {showChart ? (
            <UserChart
              dateTo={dateRange.dateTo}
              dateFrom={dateRange.dateFrom}
              resetFilters={() => {
                setDateRange({ dateTo: new Date() });
              }}
            />
          ) : (
            <>
              {userData?.mood && userData?.mood.length > 0 && (
                <MoodCheckupCalendar
                  dateTo={dateRange.dateTo}
                  dateFrom={dateRange.dateFrom}
                />
              )}

              {userData?.mood.length === 0 && (
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                  py="1rem"
                  gap={2}
                >
                  <Image src={circle_minus} />
                  <Heading fontSize="20px" fontWeight={400}>
                    {t("No data for mood checkup.")}
                  </Heading>
                  <Text color="black" fontSize="16px" fontWeight={400}>
                    {t("User doesn’t use the application.")}
                  </Text>
                </Flex>
              )}
            </>
          )}
        </Flex>
      )}
    </>
  );
};

export default ActiveTaperingPlan;
