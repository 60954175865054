import {
  Box,
  Divider,
  Heading,
  Table,
  TableContainer,
  Tbody,
  VStack,
} from "@chakra-ui/react";
import { useGetAllServices } from "features/superadmin/api/client";
import ServiceRow from "features/superadmin/components/ServiceRow";
import ServiceRowSkeleton from "features/superadmin/components/ServiceRow/components/ServiceRowSkeleton";
import ServiceTableHeader from "features/superadmin/components/ServiceRow/components/ServiceTableHeader";
import React from "react";
import { useTranslation } from "react-i18next";

import CurrentDate from "ui/components/CurrentDate";
import GoBackButton from "ui/components/GoBackButton";

const ServicesPage = () => {
  const { t } = useTranslation();

  const { data: services } = useGetAllServices();

  const skeletonCount = services ? services.length : 2;

  return (
    <VStack w="full" py="5rem">
      <GoBackButton />

      <Heading fontFamily="secondary" fontSize="2rem" alignSelf="flex-start">
        {t("Available Services")}
      </Heading>

      <CurrentDate />

      <Divider mb="5 !important" />

      <Box w="full" py=".5rem">
        <TableContainer
          w="full"
          overflow="hidden"
          bg="white"
          borderRadius=".75rem"
          boxShadow="md"
        >
          <Table variant="simple">
            <ServiceTableHeader />

            <Tbody>
              {services ? (
                services.map(({ id, type, role, name, price }) => (
                  <ServiceRow
                    key={id}
                    serviceID={id}
                    type={type}
                    role={role}
                    name={name}
                    price={price}
                  />
                ))
              ) : (
                <React.Fragment>
                  {Array.from({ length: skeletonCount }).map((_, index) => (
                    <ServiceRowSkeleton key={index} />
                  ))}
                </React.Fragment>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </VStack>
  );
};

export default ServicesPage;
