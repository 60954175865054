import { Th, Thead, Tr } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { TableHeaderItemType } from "../../api/types";

interface TableHeaderProps {
  columns?: TableHeaderItemType[];
}

const TableHeader: FunctionComponent<TableHeaderProps> = ({ columns }) => {
  return (
    <Thead>
      <Tr bg={"#96BFCC33"}>
        {columns?.map((e) => {
          return (
            <Th
              py={"1.75rem"}
              h={"auto"}
              fontSize={"0.875rem"}
              fontWeight={"600"}
              width={"auto"}
              {...e.style}
            >
              {e.title}
            </Th>
          );
        })}
      </Tr>
    </Thead>
  );
};

export default TableHeader;
