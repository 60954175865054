import { AspectRatio, Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";

import webinar_vector from "assets/icons/webinar_vector.svg";
import shape1 from "assets/images/shape_png.png";
import { useState } from "react";

const WebinarAnnouncement = () => {

  const [isVideoEnd, setIsVideoEnd] = useState(false);
  return (
    <Flex
      w="full"
      gap={{ base: "48px", sm: "60px", lg: "120px" }}
      px={{ base: "20px", sm: "120px", lg: "300px" }}
      py={{ base: "320px", sm: "310px", lg: "330px" }}
      position="relative"
      direction={{ base: "column", sm: "column", md: "row" }}
    >
      <Image
        src={shape1}
        position="absolute"
        top={{ base: 200, sm: 20 }}
        left={{ base: "-4vw", sm: "-10vw", md: "-13vw", lg: "-6vw" }}
        w="110vw"
        h="92%"
        overflow="hidden"
        maxW="none"
        minW="200px"
        objectFit="cover"
      />

      <Flex direction="column" align="flex-start" gap="10px" zIndex={1}>
        <Heading
          color="rgba(0, 38, 55, 1)"
          fontWeight={500}
          fontSize={{ base: "32px", sm: "48", lg: "56px" }}
          fontFamily="secondary"
        >
          Om webbinariet
        </Heading>
        <Text
          fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
          color="rgba(0, 38, 55, 1)"
          fontWeight={400}
        >
          Att trappa ner antidepressiva när man inte längre behöver dem kan vara
          mycket svårt. Varför är det så? Måste man må dåligt under tiden man
          trappar ned? Och vad kan man göra för att lyckas? 
        </Text>
        <Text
          fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
          color="rgba(0, 38, 55, 1)"
          fontWeight={400}
        >
          I det här samtalet förklarar läkarna på Lamina Clinic hur det kommer
          sig att så många får utsättningssymptom, och diskuterar hur man kan
          trappa ner på sina antidepressiva på ett mer skonsamt och säkert sätt.
        </Text>

        <Box
          as='video'
          controls
          src='https://lamina-files.s3.eu-north-1.amazonaws.com/video1810102413.mp4'
          poster={!isVideoEnd ? 'https://lamina-files.s3.eu-north-1.amazonaws.com/1.png' : 
          'https://lamina-files.s3.eu-north-1.amazonaws.com/2.png'}
          onEnded={() => {
            setIsVideoEnd(true);
            let video = document.querySelector('video');
            video?.load();
          }}
          // alt='webbinariet'
          id='videoAnnouncement'
          objectFit='contain'
          sx={{
            aspectRatio: '16/9',
            maxWidth: '100%'
          }}
        />
        
        {/* <Button
          as="a"
          href="https://zoom.us"
          target="_blank"
          mt="48px"
          minWidth="300px"
          minHeight="56px"
          color="rgba(255, 255, 255, 1)"
          bg="rgba(88, 154, 175, 1)"
          _hover={{ bg: "rgba(0, 38, 55, 1)" }}
          border="none"
          fontSize={{ base: "16px", sm: "20px" }}
        >
          Klicka för att starta webbinariet
          <Image src={webinar_vector} ml="1rem" />
        </Button> */}
      </Flex>

      <Flex
        direction="column"
        align="flex-start"
        borderRadius="20px"
        bg="rgba(255, 255, 255, 0.6)"
        py="37px"
        px="42px"
        gap={{ base: "32px", sm: "36px", lg: "40px" }}
        minWidth={"335px"}
        zIndex={1}
      >
        <Flex direction="column">
          <Text
            color="rgba(0, 38, 55, 1)"
            fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
            fontWeight={400}
            fontFamily="secondary"
          >
            TILLGÄNGLIGT FRÅN
          </Text>
          <Text
            color="rgba(0, 38, 55, 1)"
            fontSize={{ base: "32px", sm: "36px", lg: "40px" }}
            fontWeight={600}
            fontFamily="secondary"
          >
            27 JUNI 19:00
          </Text>
        </Flex>

        <Flex direction="column">
          <Text
            color="rgba(0, 38, 55, 1)"
            fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
            fontWeight={400}
            fontFamily="secondary"
          >
            TAR UNGEFÄR
          </Text>
          <Text
            color="rgba(0, 38, 55, 1)"
            fontSize={{ base: "32px", sm: "36px", lg: "40px" }}
            fontWeight={600}
            fontFamily="secondary"
          >
            30 minuter
          </Text>
        </Flex>

        <Flex direction="column">
          <Text
            color="rgba(0, 38, 55, 1)"
            fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
            fontWeight={400}
            fontFamily="secondary"
          >
            SVARA PÅ DINA FRÅGOR
          </Text>
          <Text
            color="rgba(0, 38, 55, 1)"
            fontSize={{ base: "32px", sm: "36px", lg: "40px" }}
            fontWeight={600}
            fontFamily="secondary"
          >
            15 minuterna
          </Text>
          <Text
            color="rgba(0, 38, 55, 1)"
            fontSize="16px"
            fontWeight={400}
            fontFamily="main"
          >
            Vi kommer att svara på så många som möjligt av de frågor som kommit
            in under anmälningsprocessen.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default WebinarAnnouncement;
