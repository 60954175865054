import { APIClient } from "services/api/client";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import useSWRInfinite from "swr/infinite";
import { toast } from "react-toastify";

import {
  NotificationResponse,
  PerformActionArgs,
  ReadNotificationsArgs,
  TreatmentChangeRequest,
  UserActiveRequest,
  UserHistoryRequest,
} from "./types";
import { useMemo } from "react";

export const useRequestChangesConfirmBlock = (notificationID: string) => {
  return useSWR<TreatmentChangeRequest>(
    `admin/notifications/request-changes-confirm-block/${notificationID}/`,
    (url: string) => APIClient.get(url)
  );
};

export const usePerformNotificationAction = (
  notificationID: string,
  action: string
) => {
  return useSWRMutation<any, PerformActionArgs>(
    `admin/notifications/perform-action/${notificationID}/`,
    // eslint-disable-next-line no-empty-pattern
    (url: string, {}: any) => APIClient.post(url, { action })
  );
};

export const useReadNotifications = () => {
  const { mutate } = useNewNotifications();
  return useSWRMutation<any, ReadNotificationsArgs>(
    `admin/read-notifications/`,
    (url: string, { arg }: { arg: ReadNotificationsArgs }) =>
      APIClient.post(url, { ids: arg.ids }),
    {
      onSuccess() {
        mutate();
      },
    }
  );
};

export const useUserActiveRequest = (user_id: string) => {
  return useSWR<UserActiveRequest>(
    `admin/user-active-request/${user_id}/`,
    (url: string) => APIClient.get(url)
  );
};

export const useUserHistory = (id: string) => {
  return useSWR<UserHistoryRequest>(
    `admin/treatment/request/history/${id}/`,
    (url: string) => APIClient.get(url)
  );
};

export const useApproveTreatment = (id: string, onClose: () => void) => {
  const { mutate } = useNewNotifications();
  return useSWRMutation<any, UserActiveRequest>(
    `/admin/treatment/approve/${id}/`,
    (url: string) => APIClient.put(url, {}),
    {
      onSuccess() {
        mutate();
        onClose();
      },
      onError: (error: unknown) => {
        toast.error(`Failed to accept the request`);
      },
    }
  );
};

export const useDeclineTreatment = (id: string, onClose: () => void) => {
  const { mutate } = useNewNotifications();
  return useSWRMutation<any, UserActiveRequest>(
    `/admin/treatment/decline/${id}/`,
    (url: string) => APIClient.put(url, {}),
    {
      onSuccess() {
        mutate();
        onClose();
      },
      onError: (error: unknown) => {
        toast.error(`Failed to decline the request`);
      },
    }
  );
};

export const useNewNotifications = () => {
  const getPageUrl = (pageIndex: number = 0, previousPageData: any) => {
    if (previousPageData && !previousPageData.next) return null;
    return `admin/notifications?page=${pageIndex + 1}`;
  };

  const { data: pages, ...rest } = useSWRInfinite<any, NotificationResponse>(
    (pageIndex, previousPageData) => getPageUrl(pageIndex, previousPageData),
    (url: string) => APIClient.get(url),
    {
      persistSize: false,
      revalidateFirstPage: false,
    }
  );

  const notifications = useMemo(() => {
    if (!pages) return [];
    return pages.flatMap((pageIndex) => pageIndex.results);
  }, [pages]);
  return { data: notifications, ...rest };
};

export const useGetNotificationsForWidget = (pageIndex: number) => {
  return useSWR<NotificationResponse, Error>(
    `admin/notifications?page=${pageIndex}`,
    (url: string) => APIClient.get(url)
  );
};
