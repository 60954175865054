import { Button, useDisclosure } from "@chakra-ui/react";

import { FunctionComponent } from "react";

import AdjustIcon from "ui/iconComponents/AdjustIcon";

import AdjustPlanDrawer from "../AdjustPlanDrawer";

type AdjustPlanButtonProps = {
  title: string;
};

const AdjustPlanButton: FunctionComponent<AdjustPlanButtonProps> = ({
  title,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        leftIcon={<AdjustIcon />}
        iconSpacing={3}
        variant="filledBlue"
        maxH="3.25rem"
        h={'100%'}
        fontSize={"1rem"}
        py="0.75rem"
        fontWeight={700}
        onClick={onOpen}
        _hover={{
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          transform: "scale(1.02)",
          transition: "0.3s",
        }}
      >
        {title}
      </Button>

      <AdjustPlanDrawer isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default AdjustPlanButton;
