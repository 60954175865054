import {
  Button,
  Center,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  IconButton,
  VStack,
  Text,
  Flex,
  Spacer,
  Box,
} from "@chakra-ui/react";
import { GoPencil } from "react-icons/go";
import ManualSelect from "ui/components/ManualSelect";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

type MedicationOption = {
  value: string;
  label: string;
};

type InitialValues = {
  medication_id: string;
  user_dose: number;
};

type EditMedicationPopoverProps = {
  medicationOptions: MedicationOption[];
  initialValues: InitialValues;
  onSubmit: (values: InitialValues) => void;
  isMutating: boolean;
};

const EditMedicationPopover: React.FC<EditMedicationPopoverProps> = ({
  medicationOptions,
  initialValues,
  onSubmit,
  isMutating,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    if (initialValues) {
      formik.setValues(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleCancel = () => {
    formik.resetForm();
  };

  return (
    <Popover isLazy>
      <PopoverTrigger>
        <Box position="absolute" top={1} right={3}>
          <IconButton
            aria-label="Edit"
            icon={<GoPencil size={20} />}
            minWidth={0}
            variant="transparent"
            p="0 !important"
            color="#0026377F"
            fontSize="1.5rem"
          />
          <Text color="#0026377F" fontSize=".6rem">
            {t("Edit")}
          </Text>
        </Box>
      </PopoverTrigger>
      <PopoverContent boxShadow="lg" border="1px solid" borderColor="gray.200">
        <PopoverArrow />
        <PopoverCloseButton />

        <PopoverBody>
          <VStack align="flex-start" spacing=".5rem" pb=".5rem">
            <Text color="rgba(0, 38, 55, 0.5)" fontSize="1rem">
              {t("Medication type")}:
            </Text>
            <ManualSelect
              options={medicationOptions.map(
                (e: { label: any; value: any }) => ({
                  label: e.label,
                  value: e.value,
                })
              )}
              value={formik.values.medication_id}
              onChange={(value) => {
                formik.setFieldValue("medication_id", value);
              }}
              _containerStyles={{
                minW: "17rem",
                bg: "#0026370D",
              }}
              _valueStyles={{
                color: "blueish.900",
              }}
              _dropdownStyles={{
                overflowY: "auto",
                maxH: "9rem",
                bg: "white",
              }}
            />

            <Text color="rgba(0, 38, 55, 0.5)" fontSize="1rem">
              {t("Start Dose")}:
            </Text>
            <InputGroup>
              <Input
                value={formik.values.user_dose}
                w="max-content"
                h="3rem"
                type="number"
                min={0}
                minHeight={0}
                step="0.001"
                bg="#0026370D !important"
                fontWeight={700}
                fontSize="1rem"
                onChange={(e) => {
                  formik.setFieldValue("user_dose", e.target.valueAsNumber);
                }}
              />

              <InputRightElement>
                <Center
                  fontWeight="700"
                  opacity={0.5}
                  fontSize="1rem"
                  h="3rem"
                  pt="0.25rem"
                  pr="0.5rem"
                >
                  mg
                </Center>
              </InputRightElement>
            </InputGroup>
            <Spacer height="1rem" />
            <Flex w="full" gap="1rem">
              <Button variant="edit" px="3rem" onClick={handleCancel}>
                {t("Cancel")}
              </Button>
              <Button
                onClick={formik.submitForm}
                variant="black"
                px="3rem"
                isDisabled={!formik.dirty || isMutating}
              >
                {t("Save")}
              </Button>
            </Flex>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default EditMedicationPopover;
