import { useContext } from "react";
import { ChatContext } from "../components/ChatContext";

export const useWrappedUseChatsList = () => {
  const hookify = useContext(ChatContext);

  return {
    ...hookify,
  };
};
