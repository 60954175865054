import { Flex, Skeleton, Tbody, Td, Tr } from "@chakra-ui/react";
import React from "react";

type OrdersRowSkeletonProps = {
  length: number;
};

const OrdersRowSkeleton: React.FC<OrdersRowSkeletonProps> = ({ length }) => {
  return (
    <Tbody>
      {Array(length)
        .fill("")
        .map((_, index) => (
          <Tr key={index} overflow="hidden">
            <Td>
              <Skeleton height="1.5rem" w="9.25rem" borderRadius=".5rem" />
            </Td>

            <Td>
              <Skeleton height="1.5rem" w="6.25rem" />
            </Td>
            <Td>
              <Flex w="full" justify="space-between">
                <Skeleton height="1.5rem" w="6.25rem" borderRadius=".5rem" />
              </Flex>
            </Td>

            <Td>
              <Skeleton height="1.5rem" width="6.25rem" />
            </Td>
            <Td>
              <Skeleton height="1.5rem" width="5rem" />
            </Td>
            <Td>
              <Flex w="full" justify="space-between">
                <Skeleton
                  height="1.5rem"
                  width="6.25rem"
                  borderRadius=".5rem"
                />
              </Flex>
            </Td>

            <Td>
              <Flex w="full" justify="space-between">
                <Skeleton
                  height="1.5rem"
                  width="6.25rem"
                  borderRadius=".5rem"
                />
              </Flex>
            </Td>

            <Td>
              <Skeleton height="1.5rem" width="6.25rem" />
            </Td>

            <Td>
              <Skeleton height="1.5rem" width="6.25rem" borderRadius=".5rem" />
            </Td>
          </Tr>
        ))}
    </Tbody>
  );
};

export default OrdersRowSkeleton;
