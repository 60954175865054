import { APIClient } from "services/api/client";
import useSWRMutation from "swr/mutation";
import { EditFormType } from "./types";
import { useFetchProfileInfo } from "features/dashboard/api/client";

export const useUpdateStaffProfile = () => {
  const { data: profileData } = useFetchProfileInfo();
  const { mutate: refetchProfileInfo } = useFetchProfileInfo();
  return useSWRMutation(
    `/profile/${profileData?.id}/`,
    (url: string, { arg }: { arg: EditFormType }) => {
      return APIClient.patch(url, arg);
    },
    {
      onSuccess: () => {
        refetchProfileInfo();
      },
    }
  );
};
