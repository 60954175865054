import { Divider, Show, VStack } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import SuperAdminNavbar from "../Navbar/SuperAdminNavbar/SuperAdminNavbar";
import SuperAdminNavbarMobile from "../Navbar/SuperAdminNavbarMobile/SuperAdminNavbarMobile";
import { useFetchProfileInfo } from "features/dashboard/api/client";

interface SuperAdminLayoutProps {
  children?: React.ReactNode[] | React.ReactNode;
  fullWidth?: boolean;
}

const SuperAdminLayout: FunctionComponent<SuperAdminLayoutProps> = ({
  children,
  fullWidth,
}) => {
  const { data: userData } = useFetchProfileInfo();
  return (
    <VStack
      alignItems={"center"}
      justifyContent={"flex-start"}
      maxWidth="100vw"
      minH={"100vh !important"}
      pt={"2rem"}
      position={"relative"}
      __css={{
        background: "#F5F9FA",
      }}
      overflow="hidden"
    >
      <VStack
        width={"100%"}
        justify="flex-start"
        align="center"
        maxWidth={{
          base: "100%",
          sm: "1370px",
          md: "1680px",
        }}
        px={{ base: "3%", sm: "5%" }}
        flexGrow={"1"}
      >
        <Show below="sm">
          <SuperAdminNavbarMobile />
        </Show>

        <Show above="sm">
          <SuperAdminNavbar userId={userData?.id ?? ""} />
        </Show>
        <Divider borderColor="rgba(88, 154, 175, 0.5)" w="100%" py="10px" />
        <VStack
          width={fullWidth ? "100%" : { base: "98%", sm: "100%" }}
          flexGrow={"1"}
          mb={"5vh"}
        >
          {children}
        </VStack>
      </VStack>
    </VStack>
  );
};

export default SuperAdminLayout;
