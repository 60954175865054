import { Flex, Heading, Text, Button } from "@chakra-ui/react";
import React, { useState } from "react";
import RequestAccepted from "../RequestAccepted";
import NotificationAccept from "ui/iconComponents/NotificationAcceptIcon";

import {
  useNewNotifications,
  usePerformNotificationAction,
  useRequestChangesConfirmBlock,
} from "../api/client";
import NotificationConsented from "../notificationConsented";
import NotificationRequested from "../notificationRequested";
import { useTranslation } from "react-i18next";

interface NotificationRequestAcceptedProps {
  onClose(): void;
  id: string;
}

const NotificationRequestAccepted: React.FC<
  NotificationRequestAcceptedProps
> = ({ onClose, id }) => {
  const [requestAccepted, setRequestAccepted] = useState<boolean>(false);

  const { t } = useTranslation();

  const { mutate } = useNewNotifications();

  const { data: userData } = useRequestChangesConfirmBlock(id);
  const { trigger: requestActionAccepted } = usePerformNotificationAction(
    id,
    "request_changes_approve"
  );

  const handleAcceptRequest = async () => {
    await requestActionAccepted();
    mutate();
    setRequestAccepted(true);
  };

  return (
    <>
      {requestAccepted ? (
        <RequestAccepted onClose={onClose} />
      ) : (
        <Flex
          w="100%"
          direction="column"
          align="flex-start"
          gap="24px"
          py="68px"
          px="60px"
        >
          <Heading fontSize={{ base: "32px", lg: "36px" }} fontWeight={500}>
            {t(
              "Are you sure you want to accept the users request to change the treatment plan?"
            )}
          </Heading>
          {userData && (
            <Text
              color="black"
              fontSize="20px"
              fontWeight={700}
              textDecoration="underline"
            >
              {userData.name}
            </Text>
          )}
          <Flex w="100%" gap="40px">
            <NotificationConsented
              current_treatment={userData?.current_treatment}
              start_treatment_date={userData?.start_treatment_date}
              id={id}
            />
            <NotificationRequested
              expected_treatment={userData?.expected_treatment}
              treatment_change_date={userData?.treatment_change_date}
              id={id}
            />
          </Flex>

          <Flex w="100%" justify="flex-end" align="center" gap="60px" mt="40px">
            <Text
              color="black"
              fontSize="18px"
              fontWeight={700}
              cursor="pointer"
              onClick={onClose}
            >
              {t("Cancel")}
            </Text>
            <Button
              variant="filter"
              bg="rgba(44, 132, 117, 1)"
              color="white"
              onClick={handleAcceptRequest}
              gap="8px"
            >
              <NotificationAccept />
              {t("Accept request")}
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default NotificationRequestAccepted;
