import {
  Box,
  useDisclosure,
  Text,
  Collapse,
  Flex,
  IconButton,
  VStack,
  HTMLChakraProps,
} from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronDown } from "react-icons/fi";

interface ManualSelectProps {
  onChange?(newValue: string): void;
  options: { label: string | React.ReactNode; value: string }[];
  value?: string;
  hideIcon?: boolean;
  customValueMapper?: () => any;
  closeOnSelect?: boolean;
  _containerStyles?: HTMLChakraProps<"div">;
  _dropdownStyles?: HTMLChakraProps<"div">;
  _placeholderStyles?: HTMLChakraProps<"p">;
  _valueStyles?: HTMLChakraProps<"p">;
}

const ManualSelect: FunctionComponent<ManualSelectProps> = ({
  onChange,
  options,
  value,
  hideIcon,
  customValueMapper,
  closeOnSelect = true,
  _containerStyles,
  _dropdownStyles,
  _placeholderStyles,
  _valueStyles,
}) => {
  const { t } = useTranslation();

  const { isOpen, onClose, onOpen, onToggle } = useDisclosure();

  useEffect(() => {
    console.log("🚀 ~ file: index.tsx:42 ~ value:", value);
  }, [value]);

  return (
    <Box
      bg={"rgba(88, 154, 175, 0.10)"}
      borderRadius={"0.5rem"}
      w={"100%"}
      border={isOpen ? "1px solid rgba(88, 154, 175, 0.50)" : ""}
      cursor={"pointer"}
      {..._containerStyles}
    >
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        px={"1.25rem"}
        py={"0.8rem"}
        onClick={onToggle}
      >
        {(!value || customValueMapper?.().length === 0) && (
          <Text
            fontSize={"1rem"}
            color={"blueish.900"}
            opacity={0.5}
            {..._placeholderStyles}
          >
            {t("Please select")}
          </Text>
        )}
        {value && !customValueMapper && (
          <Text
            fontSize={"1rem"}
            color={"blueish.500"}
            fontWeight={700}
            {..._valueStyles}
          >
            {options.find((e) => e.value === value)?.label}
          </Text>
        )}

        {value && customValueMapper && (
          <Text
            fontSize={"1rem"}
            color={"blueish.500"}
            fontWeight={700}
            {..._valueStyles}
          >
            {customValueMapper()}
          </Text>
        )}

        {!hideIcon && (
          <IconButton
            variant={"unstyled"}
            icon={<FiChevronDown />}
            aria-label={""}
            w={"auto"}
            transform={isOpen ? "rotate(180deg)" : ""}
            minWidth={"0"}
            minHeight={"0"}
            h={"auto"}
          />
        )}
      </Flex>

      <Collapse in={isOpen} unmountOnExit={false}>
        <VStack
          alignItems={"stretch"}
          borderTop={"1px solid rgba(88, 154, 175, 0.50)"}
          px={"1.25rem"}
          py={"0.8rem"}
          gap={"0.5rem"}
          {..._dropdownStyles}
        >
          {options.map((e) => {
            return (
              <Flex
                opacity={"0.5"}
                cursor={"pointer"}
                onClick={() => {
                  onChange?.(e.value);
                  if (closeOnSelect) {
                    onClose();
                  }
                }}
                h={"2rem"}
                alignItems={"center"}
              >
                {e.label}
              </Flex>
            );
          })}
        </VStack>
      </Collapse>
    </Box>
  );
};

export default ManualSelect;
