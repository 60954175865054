import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { FunctionComponent, useContext, useEffect } from "react";
import { SocketContext } from "../VideocallContext";

interface VideoPlayerProps {}

const VideoPlayer: FunctionComponent<VideoPlayerProps> = () => {
  const { myVideo, userVideo, stream, call, endCall, permissions } =
    useContext(SocketContext);

  return (
    <HStack gap={"1rem"} alignItems={"stretch"}>
      <Box>
        {!permissions.includes("video") && (
          <Center h={"50vh"} minW={"40rem"} flexDir={"column"}>
            <Heading>Insufficient permissions</Heading>
            <Text color={"black"} opacity={"0.5"} fontSize={"1rem"}>
              Please grant this website camera and microphone permissions and
              try again
            </Text>
          </Center>
        )}

        <video
          style={{ height: "50vh" }}
          playsInline
          muted
          key={"MYVIDEO"}
          ref={myVideo}
          autoPlay
        />
      </Box>

      {permissions.includes("video") && (
        <Box>
          <video
            style={{ height: "50vh" }}
            playsInline
            ref={userVideo}
            key={"USERVIDEO"}
            autoPlay
          />
        </Box>
      )}
    </HStack>
  );
};

export default VideoPlayer;
