import { Tr, Th, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type HeaderItem = {
  readonly text: string;
  readonly width: string | { readonly base: string; readonly sm: string };
};

type TableHeaderRowProps = {
  readonly headers: readonly HeaderItem[];
};

const TableHeaderRow: React.FC<TableHeaderRowProps> = ({ headers }) => {
  const { t } = useTranslation();

  const ellipsisStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as const,
  };

  return (
    <Tr>
      {headers.map((header: HeaderItem, index) => (
        <Th key={index} w={header.width}>
          <Box style={ellipsisStyle}>{t(header.text)}</Box>
        </Th>
      ))}
    </Tr>
  );
};

export default TableHeaderRow;
