import React from "react";
import { create } from "zustand";
import { ModalGlobalState } from "./types";

export const useModalStore = create<ModalGlobalState>((set) => ({
  isOpen: false,
  isLoading: false,
  content: "",
  title: "",
  cta: "",
  ctaCallback: undefined,
  setTitle: (title: string | React.ReactNode) => set(() => ({ title: title })),
  setModalContent: (content: string | React.ReactNode) =>
    set(() => ({ content: content })),
  setCta: (cta: string | React.ReactNode) => set(() => ({ cta: cta })),
  setCtaCallback: (ctaCallback: (() => void) | undefined) =>
    set(() => ({ ctaCallback: ctaCallback })),
  setIsOpen: (isOpen: boolean) =>
    set(() => ({ isOpen: isOpen, isLoading: false })),
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading: isLoading })),
  onOpen: () => set(() => ({ isOpen: true })),
  onClose: () => set(() => ({ isOpen: false })),
}));
