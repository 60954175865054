import { Center, Flex, VStack, Button, Text, Link } from "@chakra-ui/react";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface DoctorContactProps {}

const DoctorContact: FunctionComponent<DoctorContactProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { data: profileData } = useFetchProfileInfo();

  useEffect(() => {
    if (profileData?.status === "prescription_reject") {
      history.push("/doctor-rejected");
    } else if (profileData?.status === "registered") {
      history.push("/prescription");
    } else if (
      profileData &&
      profileData?.status !== "prescription_completed"
    ) {
      history.push("/dashboard");
    }
  }, [profileData]);

  return (
    <Flex w="100%" bg="white" borderRadius="20px">
      <Center
        w="100%"
        justifyContent={"space-between"}
        py={{ base: "1rem", lg: "4rem" }}
        h={{ base: "75vh", lg: "80vh" }}
        px={"10rem"}
      >
        <Flex
          flexDir="column"
          justifyContent={"space-between"}
          h={"100%"}
          w={"100%"}
        >
          <VStack
            alignItems={"flex-start"}
            px={"5rem"}
            maxW={"60rem"}
            gap={"1rem"}
            mt={"15%"}
          >
            <Text color={"blueish.900"} fontWeight={"600"} fontSize={"2rem"}>
              {t("Thank you for completing our questionnaire.")}
            </Text>
            <Text
              color={"blueish.900"}
              fontSize={{ base: "1.2rem", lg: "1.5rem" }}
            >
              Our doctors will contact you to further discuss your treatment
              plan
            </Text>
            <Text
              color={"blueish.900"}
              fontSize={{ base: "1.2rem", lg: "1.5rem" }}
            >
              {t("Stay strong and keep up your amazing work!")}
            </Text>
            <Text
              color={"blueish.900"}
              fontSize={{ base: "1.2rem", lg: "1.5rem" }}
            >
              {t("Lamina clinic team")}
            </Text>
          </VStack>
          <Button
            variant={"transparent"}
            color={"#002637"}
            opacity={0.5}
            onClick={() => history.push("/")}
            fontSize={{ base: "sm", sm: "sm", md: "md" }}
          >
            {t("Back")}
          </Button>
        </Flex>
      </Center>
    </Flex>
  );
};

export default DoctorContact;
