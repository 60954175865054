import {
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  IconButton,
  Slide,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { UserInfo } from "features/admin/api/types";
import { FunctionComponent } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { IoMdClose } from "react-icons/io";
import { useHistory } from "react-router-dom";
import AssignPersonnelModal from "../AssignPersonnelModal";

interface SelectedPatientsPopoverProps {
  patients: UserInfo[];
  onManualClose?(): void;
}

const SelectedPatientsPopover: FunctionComponent<
  SelectedPatientsPopoverProps
> = ({ patients, onManualClose }) => {
  const history = useHistory();
  const {
    isOpen: isAssignModalOpen,
    onClose: onAssignModalClose,
    onOpen: onAssignModalOpen,
  } = useDisclosure();
  return (
    <Slide direction="bottom" in={patients.length > 0} style={{ zIndex: 10 }}>
      <Center>
        <Box
          bg={"#002637"}
          borderRadius={"0.75rem 0.75rem 0rem 0rem"}
          p={"1.62rem"}
          minW={"40vw"}
        >
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Flex alignItems={"center"} gap={"1rem"}>
              <IconButton
                icon={<IoMdClose color={"white"} fontSize={"1.5rem"} />}
                aria-label={""}
                variant={"unstyled"}
                onClick={onManualClose}
                h={"1.4rem"}
                w={"auto"}
                minWidth={"0"}
              />
              <Text
                fontSize={"1rem"}
                lineHeight={"1rem"}
                mt={"0.4rem"}
                minW={"10rem"}
              >
                Selected:{" "}
                <b>
                  {patients.length} user{patients.length > 1 ? "s" : ""}
                </b>
              </Text>
            </Flex>

            <Flex gap={"0.75rem"}>
              <Button
                fontSize={"1rem"}
                w={"12rem"}
                bg={"transaprent"}
                color={"white"}
                border={"1px solid white"}
                borderColor={"white"}
                opacity={patients.length > 1 ? "0" : "1"}
                transition={"all 0.2s ease-out"}
                onClick={() => {
                  history.push("superadmin/" + patients[0].id);
                }}
              >
                Go to User Page
              </Button>
              <Button
                fontSize={"1rem"}
                bg={"#589AAF"}
                color={"white"}
                borderColor={"#589AAF"}
                onClick={onAssignModalOpen}
              >
                Assign Care Personnel
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Center>
      <AssignPersonnelModal
        patientsList={patients}
        isOpen={isAssignModalOpen}
        onClose={() => {
          onAssignModalClose();
          onManualClose?.();
        }}
      />
    </Slide>
  );
};

export default SelectedPatientsPopover;
