import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Tag,
  TagCloseButton,
  Wrap,
  Text,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useGetMedicationsType } from "features/superadmin/api/client";

import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { InputType } from "services/types/types";
import ManualSelect from "ui/components/ManualSelect";

interface MedicationTypeProps extends InputType<string[]> {}

const MedicationType: React.FC<MedicationTypeProps> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const { data: medicationType } = useGetMedicationsType();

  const medicationOptions = medicationType
    ? medicationType.map((medication) => ({
        value: medication.id,
        label: medication.name,
      }))
    : [];

  const customValueMapper = () => {
    return value
      .map((e) => {
        return medicationOptions.find((e1) => e1.value === e);
      })
      .filter((e) =>
        medicationOptions.map((e1) => e1.value).includes(e?.value ?? "")
      )
      .map((e) => e?.label)
      .join(", ");
  };

  return (
    <Flex w="100%" direction="column" gap="12px">
      <FormControl>
        <FormLabel
          fontSize="14px"
          fontWeight={600}
          color="rgba(255, 255, 255, 1)"
        >
          {t("MEDICATION TYPE")}
        </FormLabel>
        <ManualSelect
          value={customValueMapper()}
          closeOnSelect={false}
          options={medicationOptions.map((e) => {
            const isSelected = value.includes(e.value);

            return {
              value: e.value,
              label: (
                <Flex alignItems={"center"} gap={"1rem"}>
                  <Checkbox
                    variant={"admin"}
                    isChecked={isSelected}
                    onChange={() => {
                      if (isSelected) {
                        onChange(value.filter((e1) => e1 !== e.value));
                      } else {
                        onChange([...value, e.value]);
                      }
                    }}
                  />
                  <Text fontSize={"1rem"}>{e.label}</Text>
                </Flex>
              ),
            };
          })}
          _containerStyles={{
            border: "1px solid rgba(255, 255, 255, 0.5)",
          }}
          _dropdownStyles={{
            borderTop: "1px solid rgba(255, 255, 255, 0.5)",
            maxH: "15rem",
            overflowY: "auto",
            gap: "0.5rem",
          }}
          _placeholderStyles={{
            color: "white",
          }}
          _valueStyles={{
            color: "white",
          }}
          customValueMapper={customValueMapper}
        />
      </FormControl>

      {/*  <Wrap w="100%" gap="12px">
        {selectedValues.map((value, index) => (
          <Tag
            key={value}
            size="md"
            fontWeight={400}
            color="rgba(0, 38, 55, 1)"
            bg="rgba(238, 232, 169, 1)"
            p={{ base: "2px 10px", lg: "4px 12px" }}
            fontSize={{
              base: "8px",
              sm: "8px",
              md: "12px",
              lg: "16px",
            }}
          >
            {value.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())}
            <TagCloseButton onClick={() => handleTagClose(index)} />
          </Tag>
        ))}
      </Wrap>  */}
    </Flex>
  );
};

export default MedicationType;
