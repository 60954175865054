import { Box, Flex, Td, Text, Tr } from "@chakra-ui/react";
import { Order, OrderItem } from "features/superadmin/api/types";
import { serviceColors, serviceNames, textColor } from "../ServiceRow/utils";
import {
  formatDiscount,
  orderStatusColors,
  orderStatusNames,
  orderStatusTextColor,
} from "./components/utils";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DiscountInput from "./components/DiscountInput";
import { AnimatePresence, motion } from "framer-motion";
import { useSetOrderDiscount } from "features/superadmin/api/client";
import OrderInvoiceButton from "./components/OrderInvoiceButton";

interface OrdersRowProps {
  order: Order;
  item: OrderItem;
  currentPage: number;
  sortField: string;
  sortOrder: "asc" | "desc";
  onDiscountApply: (orderId: string, discountAmount: string) => void;
}

const OrdersRow: React.FC<OrdersRowProps> = ({
  order,
  item,
  currentPage,
  sortField,
  sortOrder,
  onDiscountApply,
}) => {
  const { t } = useTranslation();

  const [discount, setDiscount] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { trigger: setOrderDiscount } = useSetOrderDiscount(
    order.id,
    currentPage
  );

  const handleDiscountChange = (orderId: string, value: string) => {
    const numericValue = parseFloat(value);
    if (numericValue >= 0 && numericValue <= 100) {
      setDiscount(value);
    } else {
      setDiscount("");
    }
  };

  const handleEditStart = () => {
    setIsEditing(true);
  };

  const handleEditEnd = async (orderId: string) => {
    setIsEditing(false);
    await setOrderDiscount({ percentage: parseFloat(discount) });
    onDiscountApply(orderId, discount);
  };

  return (
    <Tr key={`${order.id}-${item.id}`}>
      <Td>
        <Box
          w="max-content"
          py="2"
          px="3"
          border="1px solid rgba(88, 154, 175, 0.2)"
          borderRadius=".5rem"
          fontWeight={500}
        >
          {order.id}
        </Box>
      </Td>

      <Td>
        <Box textAlign="center">
          {new Date(order.created_at).toLocaleDateString(
            t("locale") ?? "en-GB",
            {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }
          )}
        </Box>
      </Td>

      <Td>
        <Flex w="full" justify="space-between">
          <Box
            py="2"
            px="3"
            bg={serviceColors[item.service.name]}
            borderRadius=".5rem"
            minW="8rem"
          >
            <Text
              color={textColor[item.service.name]}
              fontWeight={700}
              fontSize="1rem"
              textAlign="center"
            >
              {t(serviceNames[item.service.name])}
            </Text>
          </Box>
        </Flex>
      </Td>

      <Td>
        {new Date(item.scheduled_date).toLocaleDateString(
          t("locale") ?? "en-GB",
          {
            day: "2-digit",
            month: "short",
            year: "numeric",
          }
        )}
      </Td>

      <Td>{item.service.price} SEK</Td>

      <Td onClick={handleEditStart} minW="6.25rem" cursor="pointer">
        {!isEditing ? (
          <Flex
            py="2"
            px="3"
            borderRadius=".5rem"
            fontWeight={500}
            alignSelf="center"
            justify="center"
            bg="rgba(88, 154, 175, 0.8)"
            color="white"
            transition="all 0.3s"
            _hover={{
              transform: "scale(1.1)",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            {order.discount?.amount
              ? formatDiscount(order.discount.amount)
              : `${t("No discount")}`}
          </Flex>
        ) : (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.2 }}
            >
              <DiscountInput
                orderId={order.id}
                value={discount}
                onChange={handleDiscountChange}
                onSubmit={handleEditEnd}
              />
            </motion.div>
          </AnimatePresence>
        )}
      </Td>

      <Td>
        <Flex w="full" justify="space-evenly">
          <Box
            py="2"
            px="3"
            bg={orderStatusColors[order.status]}
            borderRadius=".5rem"
            minW="6rem"
          >
            <Text
              color={orderStatusTextColor[order.status]}
              fontWeight={700}
              fontSize=".9rem"
              textAlign="center"
            >
              {orderStatusNames[order.status]}
            </Text>
          </Box>
        </Flex>
      </Td>

      <Td>{order.total} SEK</Td>

      <Td>
        {order.status === "UNPAID" && (
          <OrderInvoiceButton
            orderId={order.id}
            userEmail={order.user_email || ""}
            currentPage={currentPage}
            sortField={sortField}
            sortOrder={sortOrder}
          />
        )}
      </Td>
    </Tr>
  );
};

export default OrdersRow;
