import { Variants, motion, useAnimation, useInView } from "framer-motion";
import React, { ReactNode, useLayoutEffect, useRef } from "react";

type AnimatedTextProps = {
  children: ReactNode;
  zIndex?: number;
};

const AnimatedText: React.FC<AnimatedTextProps> = ({ children, zIndex }) => {
  const controls = useAnimation();
  const textRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(textRef);

  const variants: Variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.7, ease: "easeOut" },
    },
    hidden: { opacity: 0, y: 50 },
  };

  useLayoutEffect(() => {
    if (isInView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, isInView]);

  return (
    <motion.div
      ref={textRef}
      initial="hidden"
      animate={controls}
      variants={variants}
      transition={{ duration: 0.7 }}
      style={{ zIndex }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedText;
