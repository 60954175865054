import { Box, Button, Collapse, Flex, HStack, Text } from "@chakra-ui/react";

import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import EventFilter from "../EventFilter";
import { useHistory, useLocation } from "react-router-dom";
import PersonelFilter from "../PersonelFilter";

import { LogsFilterTypes } from "features/reporting/api/type";
import LogPeriodFilter from "../LogPeriodFilter";

type LogFiltersDropdownProps = {
  isOpen: boolean;
  onClose(): void;
  setFilter: (filter: LogsFilterTypes) => void;
  setCurrentPage: (arg: number) => void;
  filter: LogsFilterTypes;
};

const LogFiltersDropdown: FunctionComponent<LogFiltersDropdownProps> = ({
  isOpen,
  filter,
  setFilter,
  setCurrentPage,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [selectedDoctorRole, setSelectedDoctorRole] = useState<string>(
    filter.doctor__role ?? ""
  );
  const [selectedActionType, setSelectedActionType] = useState<string>(
    filter.action_type ?? ""
  );

  const applyFilters = () => {
    const updatedFilter = { ...filter };

    if (selectedDoctorRole) {
      updatedFilter.doctor__role = selectedDoctorRole;
    } else {
      delete updatedFilter.doctor__role;
    }

    if (selectedActionType) {
      updatedFilter.action_type = selectedActionType;
    } else {
      delete updatedFilter.action_type;
    }

    setFilter(updatedFilter);

    if (Object.keys(updatedFilter).length === 0) {
      history.push("/superadmin/reports");
      return;
    }

    setCurrentPage(1);

    const queryStringParams = Object.entries(updatedFilter).map(
      ([key, value]) =>
        `${key}=${encodeURIComponent(
          value instanceof Date ? value.toISOString() : value
        )}`
    );

    const parametersAppendix =
      queryStringParams.length > 0 ? `?${queryStringParams.join("&")}` : "";

    if (parametersAppendix) {
      history.push("/superadmin/reports" + parametersAppendix);
    } else {
      console.error("Failed to generate URL parameters");
    }
  };

  const clearFilters = () => {
    setFilter({});
    setSelectedDoctorRole("");
    setSelectedActionType("");
    setCurrentPage(1);
    history.push(location.pathname);
  };

  return (
    <>
      <Collapse in={isOpen} animateOpacity>
        <HStack
          w={"100%"}
          minH={"8rem"}
          alignItems={"center"}
          flexWrap={"wrap"}
          rowGap={"0.5rem"}
          borderTop={"1px solid rgba(0, 0, 0, 0.1)"}
          pt={"2rem"}
          pb={"1rem"}
          bg={"white"}
          px={"2rem"}
        >
          <Box
            minW={"15rem"}
            overflowY={"visible"}
            ml={{ base: ".5rem", sm: "0px" }}
          >
            <Text color={"blueish.900"} opacity={0.5} fontSize={"1rem"}>
              {t("Personal")}:
            </Text>
            <PersonelFilter
              value={filter.doctor__role ?? ""}
              onChange={(newValue) => setSelectedDoctorRole(newValue)}
            />
          </Box>

          <Box minW={"15rem"}>
            <Text color={"blueish.900"} opacity={0.5} fontSize={"1rem"}>
              {t("Action")}:
            </Text>
            <EventFilter
              value={filter.action_type ?? ""}
              onChange={(newValue) => setSelectedActionType(newValue)}
            />
          </Box>

          <Box mr={"2rem !important"}>
            <Text color={"blueish.900"} opacity={0.5} fontSize={"1rem"}>
              {t("Time period")}:
            </Text>
            <LogPeriodFilter
              value={{
                from:
                  typeof filter.created_at__gte === "string"
                    ? new Date(filter.created_at__gte)
                    : filter.created_at__gte,
                to:
                  typeof filter.created_at__lte === "string"
                    ? new Date(filter.created_at__lte)
                    : filter.created_at__lte,
              }}
              onChange={(newDates: { to: any; from: any }) => {
                setFilter({
                  ...filter,
                  created_at__gte: newDates.from,
                  created_at__lte: newDates.to,
                });
              }}
            />
          </Box>
        </HStack>
        <Flex
          w={"100%"}
          justifyContent={"flex-end"}
          bg={"white"}
          alignItems={"center"}
          gap={"1rem"}
          p={"1rem"}
        >
          <Button
            p={{ base: "4", sm: "auto" }}
            fontSize={{ base: "1rem", sm: "auto" }}
            variant={"ghost"}
            onClick={clearFilters}
            isDisabled={Object.entries(filter).length === 0}
          >
            {t("Clear")}
          </Button>

          <Button
            p={{ base: "3", sm: "auto" }}
            fontSize={{ base: "1rem", sm: "auto" }}
            variant={"dark"}
            minWidth={"none"}
            onClick={applyFilters}
          >
            {t("Apply Filters")}
          </Button>
        </Flex>
      </Collapse>
    </>
  );
};

export default LogFiltersDropdown;
