import {
  Flex,
  Heading,
  Text,
  Image,
  Box,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import left_arrow from "assets/icons/left_arrow.svg";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";
import { HashLink } from "react-router-hash-link";

const AboutLaminaClinic = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { basicEvent } = useGoogleAnalytics();

  return (
    <Box pb={"5vh"} w="full">
      <Flex
        w="full"
        bg="rgba(255, 255, 255, 1)"
        borderRadius="24px"
        p="60px"
        direction="column"
        gap="10px"
      >
        <Flex
          align="center"
          gap="14px"
          onClick={() => history.goBack()}
          cursor="pointer"
        >
          <Image src={left_arrow} />
          <Text fontSize="18px" color="black" fontWeight={500}>
            {t("Back")}
          </Text>
        </Flex>
        <Flex w="100%" align="center" justify="center" mb="30px">
          <Heading
            fontSize={{ base: "1.5rem", sm: "4xl" }}
            color="blueish.500"
            fontWeight={500}
            mr={{ base: "1rem", sm: "0px" }}
          >
            {t("ABOUT LAMINA CLINIC")}
          </Heading>
        </Flex>

        {/*  About Us */}
        <div id="about-us">
          <Flex w="full" direction="column">
            <Heading
              textAlign="start"
              fontSize={{ base: "1.5rem", sm: "2rem" }}
              fontWeight={500}
            >
              {t("About us")}
            </Heading>
            <Text
              color="black"
              fontSize={{ base: "16px", sm: "18px" }}
              textAlign="justify"
            >
              Lamina Clinic grundades 2022 och är idag den första digitala
              kliniken i världen som är specialiserade på nedtrappning och
              utsättning av antidepressiva läkemedel. Vi som arbetar på Lamina
              Clinic har lång erfarenhet av digital sjukvård och är specialister
              på nedtrappning och utsättning av antidepressiva. Vi arbetar tätt
              ihop med världsledande farmaceuter och apotek som är
              specialiserade på att bereda läkemedel i doser som är optimerade
              för nedtrappning.
            </Text>

            <Text
              color="black"
              fontSize={{ base: "16px", sm: "18px" }}
              textAlign="justify"
            >
              Med vår unika lösning får du tillgång till en personlig
              nedtrappningsplan, skräddarsydda läkemedelsdoser av just din
              medicin och stöd och hjälp från vår kunniga personal. Detta för
              att du ska få en så lyckad och smärtfri nedtrappning eller
              utsättning som möjligt.
            </Text>
          </Flex>
        </div>

        {/* Contact Us */}
        <div id="contact">
          <Flex w="full" direction="column" gap=".5rem">
            <Heading
              textAlign="start"
              fontSize={{ base: "1.5rem", sm: "2rem" }}
              fontWeight={500}
            >
              {t("Contact us")}
            </Heading>
            <Text
              color="black"
              display="flex"
              flexDirection={{ base: "column", md: "row" }}
              fontSize={{ base: "16", sm: "20px" }}
              gap=".5rem"
              alignItems={{ base: "start", sm: "center" }}
            >
              Om du har frågor eller funderingar, skicka oss ett meddelande via
              <HashLink
                to="/contact-form"
                onClick={() => {
                  basicEvent(
                    "Contact-form",
                    "User clicked footer about-lamina-clinic"
                  );
                }}
              >
                <Text
                  color="blueish.500"
                  fontSize={{ base: "16", sm: "18px" }}
                  textDecor="underline"
                >
                  vårt kontaktformulär
                </Text>
              </HashLink>
            </Text>
          </Flex>

          {/* Press & samarbeten */}

          <Flex w="full" direction="column" gap=".5rem">
            <Heading
              textAlign="start"
              fontSize={{ base: "1.5rem", sm: "2rem" }}
              fontWeight={500}
            >
              Press & samarbeten
            </Heading>
            <Text
              color="black"
              display="flex"
              flexDirection={{ base: "column", md: "row" }}
              fontSize={{ base: "16", sm: "20px" }}
              gap=".5rem"
              alignItems={{ base: "start", sm: "center" }}
            >
              För frågor kring samarbeten eller pressförfrågningar, kontakta VD
              Mikael Bäckström på
              <ChakraLink
                color={"white !important"}
                href="mailto:mb@laminaclinic.com"
                onClick={() => {
                  basicEvent("Contact", "User clicked footer link");
                }}
              >
                <Text
                  color="blueish.500"
                  fontSize={{ base: "16", sm: "18px" }}
                  textDecor="underline"
                >
                  mb@laminaclinic.com
                </Text>
              </ChakraLink>
            </Text>
          </Flex>
        </div>
      </Flex>
    </Box>
  );
};

export default AboutLaminaClinic;
