import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Tag,
  TagCloseButton,
  Wrap,
  Text,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useFetchAllDoctors } from "features/doctors/api/client";
import { useFetchAllNurses } from "features/superadmin/api/client";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputType } from "services/types/types";
import ManualSelect from "ui/components/ManualSelect";

interface NurseSelectorProps extends InputType<string[]> {}

const NurseSelector: FunctionComponent<NurseSelectorProps> = ({
  onChange,
  value,
}) => {
  const { t } = useTranslation();

  const { data: allNursesList } = useFetchAllNurses();

  const nurseOptions =
    allNursesList?.nurses.map((e) => {
      return {
        label: e.first_name + " " + e.last_name,
        value: e.id,
      };
    }) ?? [];

  const customValueMapper = () => {
    return value
      .map((e) => {
        return nurseOptions.find((e1) => e1.value === e);
      })
      .filter((e) =>
        nurseOptions.map((e1) => e1.value).includes(e?.value ?? "")
      )
      .map((e) => e?.label)
      .join(", ");
  };

  return (
    <FormControl>
      <FormLabel
        fontSize="14px"
        fontWeight={600}
        color="rgba(255, 255, 255, 1)"
        mb="0.9rem"
      >
        {t("NURSE")}
      </FormLabel>
      <ManualSelect
        value={customValueMapper()}
        closeOnSelect={false}
        options={nurseOptions.map((e) => {
          return {
            value: e.value,
            label: (
              <Flex alignItems={"center"} gap={"1rem"}>
                <Checkbox
                  variant={"admin"}
                  isChecked={value.includes(e.value)}
                  onChange={() => {
                    if (value.includes(e.value)) {
                      onChange(value.filter((e1) => e1 !== e.value));
                    } else {
                      onChange([...value, e.value]);
                    }
                  }}
                />
                <Text fontSize={"1rem"}>{e.label}</Text>
              </Flex>
            ),
          };
        })}
        _containerStyles={{
          border: "1px solid rgba(255, 255, 255, 0.5)",
        }}
        _dropdownStyles={{
          borderTop: "1px solid rgba(255, 255, 255, 0.5)",
          maxH: "15rem",
          overflowY: "auto",
          gap: "0.5rem",
        }}
        _placeholderStyles={{
          color: "white",
        }}
        _valueStyles={{
          color: "white",
        }}
        customValueMapper={customValueMapper}
      />
    </FormControl>
  );
};

export default NurseSelector;
