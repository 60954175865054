import {
  Button,
  Flex,
  Image,
  Text,
  useHighlight,
  VStack,
} from "@chakra-ui/react";
import ActiveTaperingPlan from "features/admin/components/adminMoodCheckup";
import MoodCheckup from "features/admin/components/adminMoodCheckup";
import AdminUserDetails from "features/admin/components/adminUserDetails";
import MedicalHistory from "features/admin/components/MedicalHistory";
import PatientLoggedEvents from "features/admin/components/PatientLoggedEvents";
import AdjustPlanButton from "features/doctors/components/AdjustPlanButton";
import DoctorPrescriptionButton from "features/doctors/components/DoctorPrescriptionButton";
import UserManagement from "features/doctors/components/UserManagement";
import PillsTracker from "features/pillsTracker";
import MeetingsLog from "features/staffCommons/components/MeetingsLog";
import MoreButton from "features/staffCommons/components/MoreButton";
import PatientInfoBlock from "features/staffCommons/components/PatientInfoBlock";
import PatientMedicalInformation from "features/staffCommons/components/PatientMedicalInformation";

import CurrentDose from "features/superadmin/components/PatientCurrentDose";
import PatientDosageSection from "features/superadmin/components/PatientDosageSection";
import PatientInfo from "features/superadmin/components/PatientInfo";
import PatientMedicationView from "features/superadmin/components/PatientMedicationView";
import PatientMoodOverview from "features/superadmin/components/PatientMoodOverview";
import PatientSchedule from "features/superadmin/components/PatientSchedule";
import StartingDose from "features/superadmin/components/PatientStartingDose";
import PatientTreatment from "features/superadmin/components/PatientTreatment";
import TaperingPlanner from "features/superadmin/components/TaperingPlanner";
import { SocketContext } from "features/Videocalls/components/VideocallContext";
import { t } from "i18next";
import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory, useLocation, useParams } from "react-router-dom";

import arrow_left from "assets/icons/left_arrow.svg";
import MeetingLogsWidget from "features/staffCommons/components/MeetingLogsWidget";
import MedicalInformation from "features/superadmin/components/PatientMedicalInformation";
import JournalRecords from "features/staffCommons/components/JournalRecords";
import { ZegoCallsOmnipresentContext } from "features/ZegoCallsOmnipresentContext/components/ZegoCallsOmnipresentContext";

const SuperAdminPatientView = () => {
  const { callUser } = useContext(SocketContext);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { isCallOngoing } = useContext(ZegoCallsOmnipresentContext);

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <VStack w="full" ref={scrollRef}>
      <Flex
        w="full"
        alignItems="center"
        justifyContent="flex-end"
        mt="40px"
        gap=".75rem"
      >
        <Button
          variant="transparent"
          mr={"auto"}
          color={"blueish.900"}
          fontSize={"1rem"}
          opacity={"0.5"}
          leftIcon={<BsArrowLeft fontSize={"1.5rem"} />}
          iconSpacing={"0.5rem"}
          px={0}
          onClick={handleGoBack}
        >
          {t("Back")}
        </Button>
        <AdjustPlanButton title={t("Adjust Plan")} key="" />
        <DoctorPrescriptionButton />
        <MoreButton patientId={id} />
      </Flex>

      <Flex
        py="1rem"
        gap="1.5rem"
        w="100%"
        direction={{
          base: "column",
          md: isCallOngoing ? "column" : "row",
          lg: "row",
        }}
        justify="center"
      >
        <Flex
          w={{ base: "100%", sm: "50%", md: "35%" }}
          direction={{
            base: "column",
            sm: "column",
            md: isCallOngoing ? "row" : "column",
            lg: "column",
          }}
          gap="1.5rem"
        >
          {/* <PatientInfo /> */}
          <PatientInfoBlock patientId={id} />

          <PatientMedicalInformation />

          {/* <PatientLoggedEvents /> */}
        </Flex>

        <Flex direction="column" gap="24px" w="100%">
          <Flex gap="24px">
            <MeetingLogsWidget />
            {/* <MeetingsLog /> */}
            {/* <PatientDosageSection patientId={id} /> */}
            <MedicalInformation />
          </Flex>

          <Flex direction="column" gap="24px">
            <PatientMoodOverview />
            <JournalRecords />
            <ActiveTaperingPlan />
            {/*  <TaperingPlanner /> */}
            {/* <PillsTracker /> */}
            <PatientSchedule />
            {/* <AdminUserDetails /> */}
            {/* <MedicalHistory /> */}
            {/* <UserManagement /> */}
          </Flex>
        </Flex>
      </Flex>
    </VStack>
  );
};

export default SuperAdminPatientView;
