import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Image,
  Text,
} from "@chakra-ui/react";
import { GroupBase, Select } from "chakra-react-select";

import countryList from "react-select-country-list";

import {
  useCreateDefaultAddress,
  useEditAddress,
  useFetchDefaultAddress,
  usePostNewAddress,
  useUpdateDefaultAddress,
} from "features/addressPopup/api/client";
import { DeliveryAddressSchema } from "features/addressPopup/components/medicationSidebar/validationSchemas";
import { useDashboardInfo } from "features/dashboard/api/client";
import { useEffect, useMemo, useState } from "react";
import { FormValuesDelivery } from "../api/types";
import { useFormik } from "formik";

import popup_bg from "assets/images/popup_bg.png";
import cross from "assets/images/cross.svg";
import { useTranslation } from "react-i18next";

type CountryData = {
  label: string;
  value: string;
};

const DeliveryAddress = () => {
  const { t } = useTranslation();

  const { data: defaultAddressData } = useFetchDefaultAddress();

  const { trigger: dispatchEditForm, isMutating: isEditAddressLoading } =
    useUpdateDefaultAddress(defaultAddressData?.id ?? "");
  const { trigger: saveNewAddress, isMutating: isCreateAddressLoading } =
    useCreateDefaultAddress();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  const formik = useFormik<FormValuesDelivery>({
    initialValues: {
      zip_code: "",
      country: "Sweden",
      city: "",
      address: "",
    },
    onSubmit: async (values) => {
      try {
        if (defaultAddressData) {
          await dispatchEditForm({ address: values });
        } else {
          await saveNewAddress({
            address: {
              first_name: "",
              last_name: "",
              phone: "",
              email: "",
              ...values,
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
      handleOpen();
    },
    validationSchema: DeliveryAddressSchema,
    validateOnChange: false,
    validateOnMount: false,
  });

  const countryOptions = useMemo(
    () =>
      countryList()
        .getData()
        .map((country) => ({
          label: country.label,
          value: country.label,
        })),
    []
  );

  useEffect(() => {
    if (defaultAddressData) {
      formik.setValues({
        zip_code: defaultAddressData?.zip_code || "",
        country: defaultAddressData?.country || "",
        city: defaultAddressData?.city || "",
        address: defaultAddressData?.address || "",
      });
    }
  }, [defaultAddressData]);

  const isFormValid = formik.isValid;
  return (
    <Flex direction="column" w="100%">
      <Heading fontSize="20px" fontWeight={500} color="blueish.500">
        {t("EDIT YOUR ADDRESS")}
      </Heading>
      <Text color="blackish.200" fontSize="16px" fontWeight={400}>
        {t("This information will not be published.")}
      </Text>

      <form onSubmit={formik.handleSubmit}>
        <Flex
          gap={5}
          mt="2rem"
          w="100%"
          direction={{ base: "column", sm: "row" }}
        >
          <FormControl color="blackish.200" isInvalid={!!formik.errors.country}>
            <FormLabel
              htmlFor="country"
              color="blackish.200"
              fontWeight={700}
              fontSize="16px"
            >
              {t("Country")}
            </FormLabel>
            <Select<CountryData, true, GroupBase<CountryData>>
              placeholder={`${t("Select country")}`}
              options={countryOptions}
              chakraStyles={{
                dropdownIndicator: (provided: any, state: any) => ({
                  ...provided,
                  p: 0,
                  w: "40px",
                  borderLeft: "none",
                }),
                valueContainer: (provided: any) => ({
                  ...provided,
                  paddingLeft: "0px",
                }),

                placeholder: (provided: any) => ({
                  ...provided,
                  fontWeight: 500,
                  opacity: "1 !important",
                  fontSize: { base: "1rem", lg: "md" },
                }),
                input: (provided: any) => ({
                  ...provided,
                  p: {
                    base: "0.3rem 0rem 0.3rem 0rem",
                    lg: "0.85rem 0rem 0.85rem 0rem !important",
                  },
                }),
                indicatorSeparator: () => ({ display: "none" }),
                indicatorsContainer: (provided: any) => ({
                  ...provided,
                  cursor: "pointer",
                }),
              }}
              value={
                formik.values.country !== ""
                  ? {
                      value: formik.values.country,
                      label: formik.values.country,
                    }
                  : undefined
              }
              onChange={(newValue: any) => {
                formik.setFieldValue("country", (newValue as any).value);
              }}
            />

            <FormErrorMessage color="red">
              {formik.errors.country}
            </FormErrorMessage>
          </FormControl>

          <FormControl color="blackish.200" isInvalid={!!formik.errors.city}>
            <FormLabel
              htmlFor="city"
              color="blackish.200"
              fontWeight={700}
              fontSize="16px"
            >
              {t("City/Town")}
            </FormLabel>
            <Input
              id="city"
              name="city"
              type="text"
              placeholder={`${t("Enter your city")}`}
              onChange={formik.handleChange}
              value={formik.values.city}
            />
            <FormErrorMessage color="red">
              {formik.errors.city}
            </FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex
          gap={5}
          mt="2rem"
          w="100%"
          direction={{ base: "column", sm: "row" }}
        >
          <FormControl
            color="blackish.200"
            isInvalid={!!formik.errors.zip_code}
          >
            <FormLabel
              htmlFor="zip_code"
              color="blackish.200"
              fontWeight={700}
              fontSize="16px"
            >
              {t("ZIP-Code")}
            </FormLabel>
            <Input
              id="zip_code"
              name="zip_code"
              type="text"
              placeholder={`${t("Enter your ZIP-Code")}`}
              onChange={formik.handleChange}
              value={formik.values.zip_code}
            />
            <FormErrorMessage color="red">
              {formik.errors.zip_code}
            </FormErrorMessage>
          </FormControl>

          <FormControl color="blackish.200" isInvalid={!!formik.errors.address}>
            <FormLabel
              htmlFor="address"
              color="blackish.200"
              fontWeight={700}
              fontSize="16px"
            >
              {t("Address")}
            </FormLabel>
            <Input
              id="address"
              name="address"
              type="text"
              placeholder={`${t(
                "Enter the street, house number, apartment number"
              )}`}
              onChange={formik.handleChange}
              value={formik.values.address}
            />
            <FormErrorMessage color="red">
              {formik.errors.address}
            </FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex
          mt="2rem"
          direction={{ base: "column", sm: "row" }}
          mx={{ base: "3rem", sm: "0rem" }}
          gap={'0.5rem'}
        >
          <Button
            variant="filledBlue"
            disabled={!isFormValid}
            onClick={() => {
              formik.submitForm();
              console.log("form submitted");
            }}
          >
            <Text fontSize={{ base: "18px", sm: "20px" }} zIndex="inherit">
              {t("Save Changes")}
            </Text>
          </Button>
          <Button
            variant="whited"
            onClick={() => {
              if (defaultAddressData) {
                formik.setValues({
                  zip_code: defaultAddressData.zip_code,
                  country: defaultAddressData.country,
                  city: defaultAddressData.city,
                  address: defaultAddressData.address ?? "",
                });
              } else {
                formik.resetForm();
              }
            }}
          >
            <Text fontSize={{ base: "16px", sm: "20px" }} color="blueish.500">
              {t("Cancel")}
            </Text>
          </Button>
        </Flex>
      </form>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        isCentered
        size={{ base: "sm", md: "md" }}
      >
        <ModalOverlay />
        <ModalContent backgroundImage={popup_bg} borderRadius={"20px"}>
          <ModalHeader>
            <Flex justify="flex-end">
              <Image
                onClick={handleClose}
                src={cross}
                cursor="pointer"
                alt={"close icon"}
              />
            </Flex>
          </ModalHeader>
          <ModalBody w="100%">
            <Flex
              direction="column"
              gap="40px"
              w="100%"
              p="40px"
              align="center"
            >
              <Heading fontSize="36px" color="white" textAlign="center">
                {t("Your data has been updated.")}
              </Heading>
              <Button
                variant="popup"
                minW={{ sm: "none", md: "240px" }}
                fontSize={{ base: "md" }}
                onClick={handleClose}
              >
                {t("Okay")}
              </Button>
            </Flex>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default DeliveryAddress;
