import { Flex, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BsChevronDown } from "react-icons/bs";

type OrdersTableHeaderProps = {
  sortField: string;
  sortOrder: "asc" | "desc";
  onSortChange: (field: string) => void;
};

type TableHeader = {
  title: string;
  field?: string;
};

const OrdersTableHeader: React.FC<OrdersTableHeaderProps> = ({
  sortField,
  sortOrder,
  onSortChange,
}) => {
  const { t } = useTranslation();
  const headers: TableHeader[] = [
    { title: "order ID" },
    { title: "order created", field: "created_at" },
    { title: "service name", field: "service_name" },
    { title: "scheduled date", field: "scheduled_date" },
    { title: "price" },
    { title: "discount %" },
    { title: "payment status" },
    { title: "order total" },
    { title: "invoice" },
  ];

  const handleSort = (field?: string) => {
    if (field) {
      onSortChange(field);
    }
  };

  const isSortableField = (field?: string) => {
    return (
      field === "created_at" ||
      field === "scheduled_date" ||
      field === "service_name"
    );
  };

  return (
    <Thead bg="rgba(88, 154, 175, 0.4)">
      <Tr>
        {headers.map((header, index) => (
          <Th
            key={index}
            fontSize="1rem"
            py={4}
            cursor={header.field ? "pointer" : "default"}
            onClick={() => header.field && handleSort(header.field)}
          >
            <Flex gap=".2rem" align="center">
              {t(header.title)}
              {isSortableField(header.field) && header.field && (
                <BsChevronDown
                  color={sortField === header.field ? "black" : "#589AAF"}
                  size="1.3rem"
                  style={{
                    transform:
                      sortField === header.field && sortOrder === "asc"
                        ? "rotate(180deg)"
                        : "",
                    transition: "all 0.5s ease-out",
                  }}
                  strokeWidth={sortField === header.field ? 1 : 0}
                />
              )}
            </Flex>
          </Th>
        ))}
      </Tr>
    </Thead>
  );
};

export default OrdersTableHeader;
