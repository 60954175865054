import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  useDisclosure,
  Flex,
  Heading,
  Text,
  Box,
} from "@chakra-ui/react";

import { BsChatText } from "react-icons/bs";

import { useChatsList } from "../../hooks/useChatList";
import ChatDrawer from "../../ChatDrawer";
import { Chat } from "../../api/types";
import ChatPreview from "../ChatPreview/ChatPreview";
import { PropagateLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { useWrappedUseChatsList } from "features/chats/hooks/useWrappedUseChatsList";

const ChatList = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isChatOpen,
    onOpen: onChatOpen,
    onClose: onChatClose,
  } = useDisclosure();

  const [currentChat, setCurrentChat] = useState<Chat>();

  const { users, chatsList, isLoading, hanldeClearUnread } = useWrappedUseChatsList();

  const sortedChatsList = [...chatsList].sort((chatA, chatB) => {
    const unreadA = chatA.unread || 0;
    const unreadB = chatB.unread || 0;
    return unreadB - unreadA;
  });

  const handleSelectChat = (chat: Chat) => {
    onClose();
    setCurrentChat(chat);
    onChatOpen();
  };

  useEffect(() => {
    if (currentChat) {
      hanldeClearUnread(currentChat.id);
    }
  }, [currentChat]);

  return (
    <>
      <Flex
        gap="8px"
        onClick={onOpen}
        align="center"
        cursor="pointer"
        color="black"
        fontSize={{ base: "", md: "16px", lg: "18px" }}
        fontWeight={600}
      >
        <BsChatText />
        {t("Chats")}
        {sortedChatsList.some((chat) => chat.unread > 0) && (
          <Box borderRadius={100} bg="red" w="10px" h="10px" ml="2px" />
        )}
      </Flex>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Flex w="full" align="center" justify="space-between">
              <Heading>{t("Chats")}</Heading>
              <DrawerCloseButton mt=".5rem" />
            </Flex>
          </DrawerHeader>

          <DrawerBody bg="rgba(155, 194, 207, 0.1)">
            <Flex direction="column" gap="1rem" py=".5rem">
              {isLoading ? (
                <Flex align="center" justify="center">
                  <PropagateLoader
                    color="rgba(88, 154, 175, .3)"
                    speedMultiplier={0.3}
                  />
                </Flex>
              ) : (
                <>
                  {users && sortedChatsList.length > 0 ? (
                    sortedChatsList.map((chat: Chat) => (
                      <Flex
                        onClick={() => handleSelectChat(chat)}
                        key={chat.id}
                      >
                        <ChatPreview
                          chat={chat}
                          patientName={
                            users[chat.patientId]?.first_name +
                            " " +
                            users[chat.patientId]?.last_name
                          }
                        />
                      </Flex>
                    ))
                  ) : (
                    <Text color="#589AAF" fontSize="18px" fontWeight={500}>
                      {t("No chats available")}
                    </Text>
                  )}
                </>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <ChatDrawer
        isOpen={isChatOpen}
        onOpen={onChatOpen}
        onClose={onChatClose}
        chatId={currentChat?.id}
        userId={currentChat?.patientId || ""}
      />
    </>
  );
};

export default ChatList;
