import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { Chat } from "../../api/types";
import { formatTime } from "../../services/dateFormater";

type ChatPreviewProps = {
  chat: Chat;
  patientName: string;
};

const ChatPreview: React.FC<ChatPreviewProps> = ({ chat, patientName }) => {
  return (
    <Flex
      w="full"
      py=".5rem"
      px="1rem"
      align="center"
      justify="space-between"
      borderBottomWidth={2}
    >
      <Flex direction="column">
        <Heading fontSize="20px" color="rgba(88, 154, 175, 1)">
          {patientName}
        </Heading>
        <Text
          color="black"
          fontSize="18px"
          opacity={chat?.lastMessage ? 1 : 0.3}
          overflow={"hidden"}
          whiteSpace={"nowrap"}
          textOverflow={"ellipsis"}
          maxW={'19rem'}
        >
          {chat?.lastMessage ?? "No messages here yet"}
        </Text>
      </Flex>
      <Flex direction="column">
        <Text color="black" fontSize="12px">
          {chat.lastMessageTime
            ? new Date(chat.lastMessageTime)
                .toLocaleString("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })
                .toLocaleUpperCase()
            : ""}
        </Text>
        <Box alignSelf="flex-end">
          {chat.unread > 0 && (
            <Box borderRadius={100} bg="red" w="12px" h="12px" />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default ChatPreview;
