import { Flex, Input } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type SearchPatientsProps = {
  value: string;
  onChange: (value: string) => void;
};

const SearchPatients: React.FC<SearchPatientsProps> = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <Flex>
      <Input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={`${t("Search patient")}...`}
        minH="3.2rem !important"
      />
    </Flex>
  );
};

export default SearchPatients;
