import { PrescriptionTestForm } from "./types";
import { APIClient } from "services/api/client";
import useSWRMutation from "swr/mutation";

export const usePostTreatmentTest = () => {
  return useSWRMutation<any, PrescriptionTestForm>(
    `/treatment/test`,
    (url: string, { arg }: { arg: any }) => {
      return APIClient.post(url, arg);
    }
  );
};
