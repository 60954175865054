import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  Flex,
  Divider,
  ModalFooter,
  Button,
  Text,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import {
  useAdminUserInfo,
  useRequestUserTaperingPDF,
  useSaveFileUrl,
} from "features/admin/api/client";
import { FunctionComponent, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { downloadByUrl } from "features/admin/helper/helper_functions";
import { VscFilePdf } from "react-icons/vsc";
import SignPdfComponent from "features/signPDF/components/SignPdfComponent";
import { FaFileSignature } from "react-icons/fa";
import { useUploadFile } from "features/fileUploads/api/client";
import { usePrescriptionToPharmacyStore } from "services/state/prescriptionToPharmacyStore";
import { invokeBasicInfoModal } from "features/genericModal";
import { useTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IHRPDFModalProps {}

const IHRPDFModal: FunctionComponent<IHRPDFModalProps> = ({}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams<{ id: string }>();
  const [pdfBlob, setPdfBlob] = useState<string>("");
  const [localUpdatedPdfObjectUrl, setLocalUpdatedPdfObjectUrl] =
    useState<string>("");

  const { data: userData } = useAdminUserInfo(id ?? "");
  const [numPages, setNumPages] = useState<number>(0);
  const { position, setPosition } = usePrescriptionToPharmacyStore();
  const { t } = useTranslation();

  const prescriptionRef = useRef<any>(null);

  const { trigger: taperingPDF, isMutating: taperingMutate } =
    useRequestUserTaperingPDF(id ?? "");

  const { trigger: uploadFileRequest } = useUploadFile();
  const { trigger: sendUploadedFileUrl } = useSaveFileUrl(id ?? "", "tapering");

  const [isSignatureProcessing, setIsSignatureProcessing] =
    useState<boolean>(false);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const handleSaveTaperingPDF = () => {
    downloadByUrl(
      pdfBlob,
      `${userData?.name
        .toLocaleLowerCase()
        .replaceAll(" ", "_")}_prescription_ihr.pdf`
    );
    onClose();
  };

  const handleTaperingPDF = () => {
    taperingPDF(undefined, {
      onSuccess: (data) => {
        const pdfBlob = URL.createObjectURL(data);
        setPdfBlob(pdfBlob);
        onOpen();
      },
    });
  };

  const handleSignAndUpload = async () => {
    try {
      setIsSignatureProcessing(true);
      const data = await fetch(localUpdatedPdfObjectUrl).then((res) =>
        res.blob()
      );
      //setPosition(prescriptionRef.current.signaturePosition);
      let formData = new FormData();

      formData.append("file", data, new Date().toISOString() + ".pdf");
      formData.append("folder", "signed_prescription_to_pharmacy");

      uploadFileRequest(formData, {
        onSuccess: (data) => {
          sendUploadedFileUrl({ file: data.url });
        },
      });
      invokeBasicInfoModal(t("Document successfully signed"), undefined, () => {
        onClose();
      });
      setIsSignatureProcessing(false);
    } catch (error) {
      setIsSignatureProcessing(false);
    }
  };

  return (
    <>
      <Button
        onClick={handleTaperingPDF}
        variant={"outline"}
        leftIcon={<VscFilePdf fontSize={"1.5rem"} />}
        px={"1.5rem"}
        minW={"18rem"}
        maxH={"3.5rem"}
        isLoading={taperingMutate}
        _loading={{ p: "0" }}
        disabled={
          !userData?.treatment_duration || userData.treatment?.days.length === 0
        }
      >
        <Text pt={"0.25em"} fontSize={"1rem"}>
          Download prescription IHR
        </Text>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading color="blueish.500" fontSize="32px" fontWeight={500}>
              {userData?.name} prescription IHR
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {pdfBlob ? (
              <Flex w="full" h="65vh" overflow="auto">
                <SignPdfComponent
                  pdfUrl={pdfBlob}
                  ref={prescriptionRef}
                  defaultPosition={position}
                  setNewPdfUrl={setLocalUpdatedPdfObjectUrl}
                  disableSignature
                />
                {/* <Document
                        file={pdfObjectURL}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={(error: any) => {
                          console.error("Error loading PDF:", error.message);
                          console.error("Error stack:", error.stack);
                        }}
                      >
                        {Array.from({ length: numPages }, (_, index) => (
                          <>
                            <Page
                              key={index + 1}
                              pageNumber={index + 1}
                              renderTextLayer={false}
                              renderAnnotationLayer={false}
                              scale={1.38}
                            />
                            {index !== numPages - 1 && (
                              <Flex justifyContent="flex-start">
                                <Divider
                                  width="100%"
                                  h="1px"
                                  background="blueish.500"
                                />
                              </Flex>
                            )}
                          </>
                        ))}
                      </Document> */}
              </Flex>
            ) : (
              <Text color="black">Loading PDF...</Text>
            )}
          </ModalBody>
          <ModalFooter justifyContent={"space-between"}>
            {/* <Button
              variant={"adjust"}
              leftIcon={<FaFileSignature />}
              isLoading={isSignatureProcessing}
              onClick={handleSignAndUpload}
              isDisabled={localUpdatedPdfObjectUrl === ""}
            >
              {t("Save signed document")}
            </Button> */}
            <Box></Box>
            <Button ml={2} variant="adjust" onClick={handleSaveTaperingPDF}>
              Save PDF
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default IHRPDFModal;
