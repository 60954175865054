import {
  Button,
  VStack,
  Text,
  Input,
  Flex,
  useDisclosure,
  InputGroup,
} from "@chakra-ui/react";
import { useRejectTreatmentPlan } from "features/admin/api/client";

import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModalStore } from "services/state/modalStore";

interface DoctorRejectReasonModalProps {
  userId: string;
}

const DoctorRejectReasonModal: FunctionComponent<
  DoctorRejectReasonModalProps
> = ({ userId }) => {
  const { onClose } = useModalStore();
  const { t } = useTranslation();

  const { trigger: rejectRequest } = useRejectTreatmentPlan(userId);

  const [reasonText, setReasonText] = useState<string>("");
  const {
    isOpen: isErrorMessageOpen,
    onOpen: onErrorMessageOpen,
    onClose: onErrorMessageClose,
  } = useDisclosure();

  const onReject = () => {
    if (reasonText === "") {
      onErrorMessageOpen();
    } else {
      rejectRequest(
        { reason: reasonText },
        {
          onSuccess: () => {
            onClose();
          },
        }
      );
    }
  };

  useEffect(() => {
    if (isErrorMessageOpen) {
      onErrorMessageClose();
    }
  }, [reasonText]);

  return (
    <VStack alignItems={"flex-start"} mb={"-3rem"}>
      <Text color={"blueish.900"} opacity={0.5} fontSize={"1rem"}>
        Please specify a reason for rejecting treatment plan:
      </Text>
      <InputGroup mb={"1.5rem !important"} flexDir={"column"}>
        <Input
          placeholder="Enter your reason"
          borderRadius={"none"}
          bg={"white !important"}
          fontSize={"1rem"}
          lineHeight={"1rem"}
          minH={"0 !important"}
          pl={"0 !important"}
          borderBottom={"1px solid rgba(0, 0, 0, 0.1) !important"}
          onChange={(e) => {
            setReasonText(e.target.value);
          }}
        />
        {isErrorMessageOpen && (
          <Text color={"red"} fontSize={"12px"} h={0}>
            Please fill in the reason
          </Text>
        )}
      </InputGroup>

      <Flex
        gap={2}
        direction={{ base: "column", sm: "row" }}
        mt="3rem"
        mx={{ base: "3rem", sm: "0rem" }}
      >
        <Button
          variant="filledBlue"
          minWidth={"0 !important"}
          onClick={onReject}
        >
          <Text fontSize={"1rem"}>{t("Send")}</Text>
        </Button>

        <Button variant="whited" onClick={onClose} minW={"0 !important"}>
          <Text fontSize={"1rem"} color="blueish.500">
            {t("Cancel")}
          </Text>
        </Button>
      </Flex>
    </VStack>
  );
};

export default DoctorRejectReasonModal;
