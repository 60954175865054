import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import hands_mobile from "assets/images/hands_mobile.png";
import line from "assets/images/line.svg";
import AnimatedText from "ui/components/AnimatedText";

const HomeMottoMobile = () => {
  const { t } = useTranslation();

  return (
    <Flex
      direction={"column"}
      w={"100%"}
      justify={"center"}
      align={"center"}
      py={"2rem"}
      position={"relative"}
    >
      <Box
        position={"relative"}
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Heading
          color={"white"}
          fontSize={"40px"}
          w={"60%"}
          mt={"5rem"}
          zIndex={1}
        >
          <AnimatedText>{t("mottoHeader")}</AnimatedText>
        </Heading>
        <Image
          src={hands_mobile}
          position={"absolute"}
          top={"0rem"}
          right={{ base: "-4vw", sm: "-7vw" }}
          left={{ base: "-4vw", sm: "-7vw" }}
          w={"100vw"}
          overflow={"hidden"}
          maxW={"none"}
          minW={"200px"}
          alt={"decorative image"}
        />
      </Box>
      <Text mt={"3%"} p={"5%"} fontSize={"16px"}>
        <AnimatedText zIndex={1}>{t("mottoDiscription")}</AnimatedText>
      </Text>
      <Image
        src={line}
        position={"absolute"}
        top={"-36vh"}
        left={"-1.5rem"}
        bottom={"0rem"}
        h={"100%"}
        alt={"decorative line fragments"}
      />
    </Flex>
  );
};

export default HomeMottoMobile;
