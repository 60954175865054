import { Box, Button, Heading, Image } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

type FaqDiscoverBlockProps = {
  id: string;
  image: string;
  title: string;
  handleDiscoverClick: (value: string) => void;
};

const FaqDiscoverBlock: FunctionComponent<FaqDiscoverBlockProps> = ({
  id,
  image,
  title,
  handleDiscoverClick,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      key={id}
      bg="rgba(238, 245, 247, 1)"
      p={{ base: "1rem", md: "2rem" }}
      borderRadius="1.5rem"
      transition="transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out"
      _hover={{
        transform: "scale(1.05)",
        boxShadow: "lg",
      }}
      onClick={() => handleDiscoverClick(id)}
      cursor="pointer"
      minW={{ base: "30%", sm: "32%", md: "30%" }}
    >
      <Image
        src={image}
        alt="pic4"
        w={{
          base: "3.125em",
          sm: "3.75em",
          md: "3.75em",
          lg: "5em",
        }}
      />
      <Heading
        color="rgba(0, 38, 55, 1)"
        fontSize={{ base: "1rem", sm: "1rem", md: "1.2rem", lg: "1.6rem" }}
        fontWeight={500}
        py="1rem"
      >
        {t(title)}
      </Heading>
      <Button
        mt={{ base: "1em", sm: "2em" }}
        w="full"
        maxW={{ base: "12.5em", sm: "11.25em", md: "12.5em" }}
        fontSize={{ base: "1em", sm: "0.8em", md: "1em", lg: "1em" }}
        onClick={() => {
          handleDiscoverClick(id);
        }}
      >
        {t("Discover")}
      </Button>
    </Box>
  );
};

export default FaqDiscoverBlock;
