import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Center,
  PinInput,
  PinInputField,
  Text,
  VStack,
  HStack,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import NotesDrawer from "features/admin/components/NotesDrawer";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { useTokenStore } from "services/state/tokenStore";
import { useAuthenticateDoctor, useFetchAuthData } from "../../api/client";

interface AuthModalProps {
  isOpen: boolean;
  onClose(): void;
}

const AuthModal: FunctionComponent<AuthModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { data: qrData } = useFetchAuthData();
  const { trigger: sendAuthRequest, isMutating: isAuthorizing } =
    useAuthenticateDoctor();
  const { setAccess, setRefresh, setAuth2fa } = useTokenStore();
  const {
    isOpen: isNotesDrawerOpen,
    onOpen: onNotesDrawerOpen,
    onClose: onNotesDrawerClose,
  } = useDisclosure();

  const [authCode, setAuthCode] = useState<string>("");

  const handleLogin = () => {
    sendAuthRequest(authCode, {
      onSuccess: (data) => {
        setAccess(data.access);
        setRefresh(data.refresh);
        setAuth2fa(true);
        onNotesDrawerOpen();
        onClose();
      },
    });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg={"rgba(0, 0, 0, 0.6)"} />
        <ModalContent>
          <ModalHeader fontWeight={400} lineHeight={"2.5rem"}>
            {t("Authorize to view notes")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody alignItems={"center"}>
            <VStack>
              <Text color={"blueish.900"} fontSize={"1rem"}>
                {t("Scan the QR code with your authentication app")}:
              </Text>
              <Center mb={"2rem !important"}>
                <QRCode value={qrData ?? ""} />
              </Center>
              <Text color={"blueish.900"} fontSize={"1rem"}>
                {t("Enter code from your authentication app")}:
              </Text>
              <HStack>
                <PinInput
                  otp
                  placeholder="_"
                  onChange={(value) => {
                    setAuthCode(value);
                  }}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <Box border={"none"} w={"0.5rem"}></Box>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={onClose}
              variant={"transparent"}
              color={"blueish.900"}
              fontWeight={"400"}
              opacity={0.5}
            >
              {t("Close")}
            </Button>
            <Button
              variant="ghost"
              disabled={authCode.length < 6}
              onClick={handleLogin}
              isLoading={isAuthorizing}
            >
              {t("Log in")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <NotesDrawer isOpen={isNotesDrawerOpen} onClose={onNotesDrawerClose} />
    </>
  );
};

export default AuthModal;
