import { Portal, useDisclosure } from "@chakra-ui/react";
import {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
} from "react";
import CallModal from "../CallModal";
import IncomingCallModal from "../IncomingCallModal";
import OutgoingCallModal from "../OutgoingCallModal";
import { SocketContext } from "../VideocallContext";
import VideoPlayer from "../VideoPlayer";

const CallContext = createContext<any>(undefined);

interface CallContextProviderProps {
  children?: React.ReactNode | React.ReactNode[];
}

const CallContextProvider: FunctionComponent<CallContextProviderProps> = ({
  children,
}) => {
  const { call, isCallIncoming, isCallOngoing, isCalling, endCall } =
    useContext(SocketContext);

  const {
    isOpen: isIncomingCallModalOpen,
    onOpen: onIncomingCallModalOpen,
    onClose: onIncomingCallModalClose,
  } = useDisclosure();

  const {
    isOpen: isOutgoingCallModalOpen,
    onOpen: onOutgoingCallModalOpen,
    onClose: onOutgoingCallModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (isCallIncoming) {
      onIncomingCallModalOpen();
    } else {
      onIncomingCallModalClose();
    }
  }, [isCallIncoming]);

  useEffect(() => {
    if (isCalling) {
      onOutgoingCallModalOpen();
    }
  }, [isCalling]);

  useEffect(() => {
    if (isCallOngoing) {
      onOutgoingCallModalClose();
      onIncomingCallModalClose();
    }
  }, [isCallOngoing]);

  return (
    <>
      <CallContext.Provider value={undefined}>{children}</CallContext.Provider>

      <OutgoingCallModal
        isOpen={isOutgoingCallModalOpen}
        onClose={onOutgoingCallModalClose}
        toId={call?.to ?? ""}
      />

      <IncomingCallModal
        isOpen={isIncomingCallModalOpen}
        onClose={onIncomingCallModalClose}
      />

      <CallModal
        isOpen={isCallOngoing}
        onClose={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
    </>
  );
};

export default CallContextProvider;
