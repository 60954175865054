import { Button, Flex, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import RequestDeclinedIcon from "ui/iconComponents/RequestDeclinedIcon";

interface RequestDeclinedProps {
  onClose(): void;
}

const RequestDeclined: React.FC<RequestDeclinedProps> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <Flex
      w="100%"
      h="540px"
      bg="rgba(213, 115, 138, 0.1)"
      align="center"
      justify="center"
      direction="column"
      gap="40px"
    >
      <Flex direction="column" align="center" gap="22px">
        <RequestDeclinedIcon />
        <Heading fontSize="36px" color="rgba(213, 115, 138, 1)">
          {t("Request Declined")}
        </Heading>
      </Flex>
      <Button
        variant="adjust"
        minW="140px"
        onClick={() => {
          onClose();
        }}
      >
        {t("Close")}
      </Button>
    </Flex>
  );
};

export default RequestDeclined;
