import { Flex, VStack } from "@chakra-ui/react";
import AdminHeader from "features/nurse/components/AdminHeader";
import NurseMonitorPatients from "features/nurse/components/NurseMonitorPatients";
import NursePatientsTable from "features/nurse/components/NursePatientsTable";
import LeaveCalendarSection from "features/staffCommons/components/LeaveCalendarSection";
import MeetingLogWidget from "features/staffCommons/components/MeetingLogWidget";
import NotificationWiget from "features/superadmin/components/NotificationWidget";
import WorkingCalendar from "features/superadmin/components/WorkingCalendar/WorkingCalendar";
import { FunctionComponent } from "react";

interface NursePageProps {}

const NursePage: FunctionComponent<NursePageProps> = () => {
  return (
    <VStack w="100%" alignItems={"stretch !important"} gap={2.5}>
      <AdminHeader />
      <Flex
        direction={{ base: "column", sm: "row" }}
        gap={{ base: "3", sm: "0" }}
      >
        <WorkingCalendar />
      </Flex>

      {/* <LeaveCalendarSection /> */}

      <MeetingLogWidget />

      <Flex gap={7}>
        {/*   <NurseMonitorPatients /> */}

        <NotificationWiget />
      </Flex>

      <NursePatientsTable />
    </VStack>
  );
};

export default NursePage;
