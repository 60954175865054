import SvgIconConstituentValues from './types/svgPropsType';

const LogoutIcon = (props: SvgIconConstituentValues) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M7.70679 8.707L5.41379 11H16.9998C17.265 11 17.5194 11.1054 17.7069 11.2929C17.8944 11.4804 17.9998 11.7348 17.9998 12C17.9998 12.2652 17.8944 12.5196 17.7069 12.7071C17.5194 12.8946 17.265 13 16.9998 13H5.41379L7.70679 15.293C7.8023 15.3852 7.87848 15.4956 7.93089 15.6176C7.9833 15.7396 8.01088 15.8708 8.01204 16.0036C8.01319 16.1364 7.98789 16.2681 7.93761 16.391C7.88733 16.5138 7.81307 16.6255 7.71918 16.7194C7.62529 16.8133 7.51364 16.8875 7.39074 16.9378C7.26784 16.9881 7.13616 17.0134 7.00339 17.0123C6.87061 17.0111 6.73939 16.9835 6.61738 16.9311C6.49538 16.8787 6.38503 16.8025 6.29279 16.707L2.29279 12.707C2.10532 12.5195 2 12.2652 2 12C2 11.7348 2.10532 11.4805 2.29279 11.293L6.29279 7.293C6.38503 7.19749 6.49538 7.12131 6.61738 7.0689C6.73939 7.01649 6.87061 6.9889 7.00339 6.98775C7.13616 6.9866 7.26784 7.0119 7.39074 7.06218C7.51364 7.11246 7.62529 7.18671 7.71918 7.2806C7.81307 7.3745 7.88733 7.48615 7.93761 7.60905C7.98789 7.73194 8.01319 7.86362 8.01204 7.9964C8.01088 8.12918 7.9833 8.2604 7.93089 8.3824C7.87848 8.50441 7.8023 8.61475 7.70679 8.707ZM20.9998 1H12.9998C12.7346 1 12.4802 1.10536 12.2927 1.29289C12.1051 1.48043 11.9998 1.73478 11.9998 2C11.9998 2.26522 12.1051 2.51957 12.2927 2.70711C12.4802 2.89464 12.7346 3 12.9998 3H19.9998V21H12.9998C12.7346 21 12.4802 21.1054 12.2927 21.2929C12.1051 21.4804 11.9998 21.7348 11.9998 22C11.9998 22.2652 12.1051 22.5196 12.2927 22.7071C12.4802 22.8946 12.7346 23 12.9998 23H20.9998C21.265 23 21.5194 22.8946 21.7069 22.7071C21.8944 22.5196 21.9998 22.2652 21.9998 22V2C21.9998 1.73478 21.8944 1.48043 21.7069 1.29289C21.5194 1.10536 21.265 1 20.9998 1Z"
      fill="white"
    />
  </svg>
);

export default LogoutIcon;
