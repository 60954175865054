import { Box, Center, Checkbox, Flex, Heading, VStack } from "@chakra-ui/react";
import AppUsage from "../adminAppUsage";
import FilterMood from "../adminFilterMood";
import MedicationTypeSearch from "../adminMedicationTypeSearch";
import SearchByName from "../adminSearchByName";
import StatusSelect from "../adminStatusSelect";
import DoubleDoseInput from "../DoubleDoseInput";
import DoctorSelector from "../filterComponents/DoctorSelector";
import UserFilterSlider from "../UserFilterSlider";

import { useTranslation } from "react-i18next";
import { string } from "yup";
import { useFetchProfileInfo } from "features/dashboard/api/client";

interface AdminTableFilterProps {
  statusFilter: string;
  nameFilter: string;
  medicineTypeFilter: string;
  appUsageFilter: string;
  moodFilter: string[];

  startingDosageFrom?: number;
  startingDosageTo?: number;
  setStargingDosageFrom(from: number): void;
  setStartingDosageTo(to: number): void;
  currentDoseFrom?: number;
  currentDoseTo?: number;
  setCurrentDoseFrom(from: number): void;
  setCurrentDoseTo(to: number): void;
  taperingPlanDurationFrom?: number;
  taperingPlanDurationTo?: number;
  setTaperingPlanDurationFrom(from: number): void;
  setTaperingPlanDurationTo(to: number): void;

  onStatusFilterChange: (value: string) => void;
  onNameFilterChange: (value: string) => void;
  onMedicineTypeFilterChange: (value: string) => void;
  onAppUsageFilterChange: (value: string) => void;
  onMoodFilterChange: (value: string[]) => void;

  excludeTestUsers: boolean;
  onSetExcludeTestUsers(newValue: boolean): void;

  selectedDoctors: string;
  setSelectedDoctors(newDoctors: string): void;
}

const AdminTableFilter: React.FC<AdminTableFilterProps> = ({
  statusFilter,
  nameFilter,
  medicineTypeFilter,
  appUsageFilter,
  moodFilter,
  onStatusFilterChange,
  onNameFilterChange,
  onMedicineTypeFilterChange,
  onAppUsageFilterChange,
  onMoodFilterChange,

  startingDosageFrom,
  startingDosageTo,
  setStargingDosageFrom,
  setStartingDosageTo,

  currentDoseFrom,
  currentDoseTo,
  setCurrentDoseFrom,
  setCurrentDoseTo,

  taperingPlanDurationFrom,
  taperingPlanDurationTo,
  setTaperingPlanDurationFrom,
  setTaperingPlanDurationTo,

  excludeTestUsers,
  onSetExcludeTestUsers,

  selectedDoctors,
  setSelectedDoctors,
}) => {
  const { t } = useTranslation();

  const { data: profileData } = useFetchProfileInfo();

  return (
    <VStack alignItems={"flex-start"} px="32px" gap={"2rem"} pb={"3rem"}>
      <Flex alignItems="flex-start" justify="space-between" gap={5} w="100%">
        {/* SEARCH BY NAME */}
        <SearchByName
          nameFilter={nameFilter}
          onNameFilterChange={onNameFilterChange}
        />

        {/*  MEDICATION TYPE */}
        <MedicationTypeSearch
          medicineTypeFilter={medicineTypeFilter}
          onMedicineTypeFilterChange={onMedicineTypeFilterChange}
        />

        {/* MOOD */}
        <FilterMood
          moodFilter={moodFilter}
          onMoodFilterChange={onMoodFilterChange}
        />

        {/*  STATUS */}
        <StatusSelect
          statusFilter={statusFilter}
          onStatusFilterChange={onStatusFilterChange}
        />

        {/* APP USAGE */}
        <AppUsage
          appUsageFilter={appUsageFilter}
          onAppUsageFilterChange={onAppUsageFilterChange}
        />
      </Flex>

      <Flex gap={"2rem"} w={"100%"}>
        <DoubleDoseInput
          title={t("STARTING DOSE")}
          from={startingDosageFrom}
          to={startingDosageTo}
          setFrom={setStargingDosageFrom}
          setTo={setStartingDosageTo}
        />
        <DoubleDoseInput
          title={t("CURRENT DOSE")}
          from={currentDoseFrom}
          to={currentDoseTo}
          setFrom={setCurrentDoseFrom}
          setTo={setCurrentDoseTo}
        />

        <Box flexGrow={1}>
          <Heading
            fontSize="14px"
            fontWeight={600}
            color="rgba(255, 255, 255, 1)"
            lineHeight="20px"
            textTransform={"uppercase"}
          >
            {t("TAPERING PLAN")}
          </Heading>
          <UserFilterSlider
            taperingPlanDurationFrom={taperingPlanDurationFrom}
            taperingPlanDurationTo={taperingPlanDurationTo}
            setTaperingPlanDurationFrom={setTaperingPlanDurationFrom}
            setTaperingPlanDurationTo={setTaperingPlanDurationTo}
          />
        </Box>
      </Flex>
      <Flex gap={"2rem"} w={"100%"} alignItems={"center"}>
        {profileData?.is_admin && (
          <DoctorSelector
            selectedDoctors={selectedDoctors}
            setSelectedDoctors={setSelectedDoctors}
          />
        )}

        <Center pt={"2rem"}>
          <Checkbox
            fontSize="14px"
            fontWeight={400}
            color="rgba(255, 255, 255, 1)"
            mb="0.9rem"
            defaultChecked
            variant={"filter"}
            isChecked={excludeTestUsers}
            onChange={(e) => {
              onSetExcludeTestUsers(e.target.checked);
            }}
          >
            Exkludera testanvändare
          </Checkbox>
        </Center>
      </Flex>
    </VStack>
  );
};

export default AdminTableFilter;
