import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Text,
} from "@chakra-ui/react";

import { useFormik } from "formik";

import { Link, useParams } from "react-router-dom";

import { useSetNewPassword } from "features/adminLogin/api/client";
import PasswordInput from "ui/components/PasswordInput/index";
import { NewPasswordSchema } from "features/adminLogin/api/validation";
import { string } from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface PasswordUpdateFormProps {
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const PasswordUpdateForm: React.FC<PasswordUpdateFormProps> = ({
  setShowForm,
}) => {
  const { id, token } = useParams<{ id: string; token: string }>();

  const { t } = useTranslation();

  const { trigger, isMutating } = useSetNewPassword(id, token);

  const formik = useFormik({
    initialValues: {
      newpassword: "",
      confirmpassword: "",
    },
    onSubmit: (value) => {
      trigger(
        {
          password: value.newpassword,
          confirm_password: value.confirmpassword,
        },
        {
          onSuccess: () => {
            setShowForm(true);
          },
          onError: () => {
            toast.error("Something went wrong, please try again later");
          },
        }
      );
    },
    validationSchema: NewPasswordSchema,
  });
  return (
    <>
      <Heading
        color="rgba(0, 38, 55, 1)"
        fontWeight={600}
        fontSize="32px"
        letterSpacing={1.6}
        pb="1rem"
        textAlign="center"
      >
        {t("Set New Password")}
      </Heading>
      <form onSubmit={formik.handleSubmit}>
        <FormControl isInvalid={!!formik.errors.newpassword} h="80px">
          <FormLabel
            fontWeight={600}
            fontSize={{ base: "12px", lg: "14px" }}
            color="rgba(0, 38, 55, 1)"
            fontFamily="secondary"
            textTransform="uppercase"
          >
            {t("new password")}
          </FormLabel>
          <PasswordInput
            id="newpassword"
            name="newpassword"
            placeholder="Enter new password"
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "16px",
            }}
            onChange={formik.handleChange}
            value={formik.values.newpassword}
          />

          <FormErrorMessage color="red" fontSize="14px">
            {formik.errors.newpassword}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={!!formik.errors.confirmpassword}
          mt="24px"
          h="80px"
        >
          <FormLabel
            fontWeight={600}
            fontSize={{ base: "12px", lg: "14px" }}
            color="rgba(0, 38, 55, 1)"
            fontFamily="secondary"
          >
            CONFIRM PASSWORD
          </FormLabel>
          <PasswordInput
            id="confirmpassword"
            name="confirmpassword"
            placeholder="Confirm new password"
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "16px",
            }}
            onChange={formik.handleChange}
            value={formik.values.confirmpassword}
          />

          <FormErrorMessage color="red" fontSize="14px">
            {formik.errors.confirmpassword}
          </FormErrorMessage>
        </FormControl>

        <Center py="3rem">
          <Button
            w="full"
            type="submit"
            bg="rgba(88, 154, 175, 1)"
            borderColor="white"
            fontFamily="secondary"
            fontWeight={700}
            fontSize="16px"
            letterSpacing={1}
            color={"white"}
            _hover={{
              bg: "blueish.700",
            }}
            maxW="235px"
            onClick={() => {
              formik.submitForm();
              console.log("submited");
            }}
            isDisabled={!formik.isValid}
            isLoading={isMutating}
          >
            {t("Set Password")}
          </Button>
        </Center>
      </form>
      <Center w="full" mt={{ base: "", sm: "8rem", md: "9rem", lg: "7.5rem" }}>
        <Text
          color="rgba(0, 38, 55, 0.5)"
          fontSize="16px"
          fontWeight={700}
          fontFamily="secondary"
          textDecor="underline"
          cursor="pointer"
        >
          <Link to="/admin">{t("Back to Login")}</Link>
        </Text>
      </Center>
    </>
  );
};

export default PasswordUpdateForm;
