import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  Flex,
  Text,
  Box,
  Center,
} from "@chakra-ui/react";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AnimatedText from "../prescriptionAnimatedText";

interface DurationSliderProps {
  min?: number;
  max?: number;
  defaultValue: number;
  selectedPlan: number;
  onChange: (newValue: number) => void;
  isAdjustable: boolean;
}

const DurationSlider: FunctionComponent<DurationSliderProps> = ({
  min = 12,
  max = 48,
  defaultValue,
  selectedPlan,
  onChange,
  isAdjustable,
}) => {
  const { t } = useTranslation();
  const sliderSelectedPlan =
    selectedPlan > max
      ? max
      : selectedPlan < min
      ? min
      : selectedPlan && !isNaN(selectedPlan)
      ? selectedPlan
      : min;

  useEffect(() => {
    console.log(
      "🚀 ~ file: index.tsx:45 ~ sliderSelectedPlan:",
      sliderSelectedPlan
    );
  }, [sliderSelectedPlan]);

  return (
    <>
      <Slider
        key={"duration-slider-editor"}
        aria-label="slider-ex-1"
        min={min}
        max={max}
        bgColor="rgba(88, 154, 175, 0.1)"
        borderRadius="12px"
        defaultValue={defaultValue}
        value={sliderSelectedPlan}
        onChange={(val) => {
          onChange(val);
        }}
        width={"100%"}
        step={4}
        isDisabled={!isAdjustable}
        _disabled={{ opacity: 1 }}
        transition={"all 0.3s"}
        focusThumbOnChange={false}
      >
        <SliderTrack bg="transparent" h="1.25rem" borderLeftRadius="12px">
          <SliderFilledTrack
            bg="rgba(88, 154, 175, 1)"
            borderRadius={max === sliderSelectedPlan ? "20px" : ""}
          />
          {new Array(
            sliderSelectedPlan - (min ?? 0) >= 0
              ? sliderSelectedPlan - (min ?? 0)
              : 1
          )
            .fill(0)
            .map((e, index) => {
              return (
                <SliderMark
                  value={(min ?? 0) + index + 1}
                  borderRight={"2px solid white"}
                  w={"1px"}
                  h={"2rem"}
                  verticalAlign={"center"}
                ></SliderMark>
              );
            })}
          {isAdjustable &&
            new Array((max ?? sliderSelectedPlan + 1) - sliderSelectedPlan)
              .fill(0)
              .map((e, index) => {
                return (
                  <SliderMark
                    value={(sliderSelectedPlan ?? 0) + index + 1}
                    h={"1.25rem"}
                    w={"6px"}
                    verticalAlign={"center"}
                    display={"flex"}
                    alignItems={"center"}
                    overflow={"visible !important"}
                  >
                    <Box
                      h={"6px"}
                      w={"6px"}
                      borderRadius={"50%"}
                      bg={"rgba(88, 154, 175, 0.4)"}
                    ></Box>
                  </SliderMark>
                );
              })}
        </SliderTrack>
        <SliderThumb
          boxSize={6}
          borderColor="#9BC2CF"
          w={"2rem"}
          h={"2rem"}
          opacity={isAdjustable ? 1 : 0}
          border={"3px solid #9BC2CF"}
        />
      </Slider>

      <Flex justify="space-between" align="center">
        <Text fontSize="15px" fontWeight={500} color={"#002637"} opacity={0.5}>
          {min} {t("weeks")} (min)
        </Text>
        {/* <Text fontSize="15px" fontWeight={500} color={"#002637"} opacity={0.5}>
          {max} {t("weeks")}(max)
        </Text> */}
      </Flex>

      {isAdjustable && (
        <Flex justify="space-between" align="center" mt={"-0.5rem"}>
          <AnimatedText text={t("Go faster")} color="black" isVisible={true} />
          <AnimatedText text={t("Go slower")} color="black" isVisible={true} />
        </Flex>
      )}
    </>
  );
};

export default DurationSlider;
