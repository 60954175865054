import * as Yup from "yup";

export const newUserValidationSchema = Yup.object().shape({
  email: Yup.string().email("Försök igen").required("Försök igen"),
  first_name: Yup.string().required("Försök igen"),
  last_name: Yup.string().required("Försök igen"),
  medical_code: Yup.string().required("Försök igen"),
  hospital_name: Yup.string().required("Försök igen"),
  hospital_address: Yup.string().required("Försök igen"),
  pnr: Yup.string().required("Personnal code required"),
});

export const PDFValidationSchema = Yup.object().shape({
  medicationName: Yup.string().required("Försök igen"),
  startDose: Yup.number().required("Försök igen"),
  endDose: Yup.number().required("Försök igen"),
  doctorName: Yup.string().required("Försök igen"),
  doctorEmail: Yup.string(),
  fullName: Yup.string().required("Försök igen"),
  birthday: Yup.date().required("Försök igen"),
  genre: Yup.string().required("Försök igen"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Försök igen"),
  treatment: Yup.number()
    .test(
      "divisibleBy4",
      "Number of weeks should be divisible by 4",
      (number) => {
        if (number) return number % 4 === 0;
        return false;
      }
    )
    .required("Försök igen"),
});
