import {
  Box,
  Flex,
  Image,
  Text,
  Heading,
  HStack,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";

import treatment from "assets/icons/treatment.svg";
import history from "assets/icons/history.svg";

import TreantemtPlan from "features/dashboard/components/dashboardTreatmentPlan";
import PopupChange from "features/prescriptionForm/components/prescriptionPopupChange";
import { useParams } from "react-router-dom";
import tick_circle from "assets/icons/tick_circle.svg";
import {
  useAdminUserInfo,
  useFetchTreatmentDurationOptions,
} from "features/userInfo/api/client";

import { useTranslation } from "react-i18next";
import { useUserHistory } from "features/notifications/api/client";

import UserHistory from "features/notifications/notificationUserHistory";
import { useDebug } from "features/admin/helper/columns";
import UserPlanStartDateChange from "../UserPlanStartDateChange";
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useAdminChangeTreatmentduration } from "features/admin/api/client";

const AdminUserTreatment = () => {
  const { id } = useParams<{ id: string }>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: userData } = useAdminUserInfo(id);

  const { data: userHistory } = useUserHistory(id);

  const { t } = useTranslation();

  useDebug(userHistory);

  //TREATMENT DURATION ADJUSTMENT STATE
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [newTreatmentDuration, setNewTreatmentDuration] = useState<number>(
    (userData?.treatment_duration ?? 0) * 4 ?? 0
  );

  const oldTreatmentDuration = (userData?.treatment_duration ?? 0) * 4 ?? 0;

  useEffect(() => {
    setNewTreatmentDuration((userData?.treatment_duration ?? 0) * 4 ?? 0);
  }, [userData?.treatment_duration]);

  const { trigger: sendChangeDurationRequest, isMutating: isUpdatingDuration } =
    useAdminChangeTreatmentduration(id);
  //END OF TREATMENT DURATION ADJUST STATE

  return (
    <Flex
      bg="white"
      p={"2.5rem 2rem 1.5rem 2.5rem"}
      borderRadius="12px"
      w="100%"
      direction="column"
      gap={3}
    >
      <Flex
        flexDirection={"row"}
        w={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Image src={treatment} mr={"0 !important"} />
        {userData?.treatment_duration && (
          <HStack spacing={"1.5rem"}>
            {userData?.treatment_duration &&
            userHistory?.request &&
            userHistory.request.length > 0 ? (
              <HStack gap="2rem">
                <Flex align="flex-start" direction="column">
                  <Flex align="center" gap={1} w="100%">
                    <Image src={tick_circle} />
                    <Text
                      color="rgba(44, 132, 117, 1)"
                      fontSize={{ md: "14px", lg: "1rem" }}
                      fontWeight={700}
                      mt="2px"
                    >
                      {t("Plan was changed by a request")}
                    </Text>
                  </Flex>
                  <Flex w="100%" justify="flex-end">
                    <Text
                      color="rgba(0, 38, 55, 1)"
                      fontSize={{ md: "14px", lg: "1rem" }}
                      fontWeight={400}
                    >
                      {new Date(
                        userHistory?.request[0].date
                      ).toLocaleDateString(t("locale") ?? "en-GB", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </Text>
                  </Flex>
                </Flex>
                <Button
                  variant="adjust"
                  gap="8px"
                  onClick={onOpen}
                  px={"1rem"}
                  fontSize={{ md: "14px", lg: "1rem" }}
                >
                  <Image src={history} alt="history" />
                  History
                </Button>
              </HStack>
            ) : (
              <Flex gap={1} align="flex-start">
                <Image src={tick_circle} />
                <Text
                  color="rgba(44, 132, 117, 1)"
                  fontSize="16px"
                  fontWeight={700}
                >
                  {t("User has consented this plan")}
                </Text>
              </Flex>
            )}

            <>
              <Divider
                orientation="vertical"
                h={"3rem"}
                opacity={0.5}
                color={"#002637"}
              />

              <UserPlanStartDateChange />
            </>
          </HStack>
        )}
      </Flex>
      <Box>
        <Flex gap={"1rem"} alignItems={"center"}>
          <Heading
            color="rgba(88, 154, 175, 0.8)"
            fontSize={"20px"}
            fontWeight={600}
            letterSpacing={0.7}
          >
            {t("SUGGESTED TREATMENT PLAN")}
          </Heading>
          <Divider orientation="vertical" h={"2rem"} />
          {userData?.treatment_duration && (
            <>
              {!isEditable && (
                <Button
                  variant={"transparent"}
                  color={"rgba(0, 0, 0, 0.3)"}
                  px={0}
                  fontWeight={"400"}
                  leftIcon={<AiOutlineEdit />}
                  onClick={() => setIsEditable(true)}
                >
                  {t("Adjust treatment duration")}
                </Button>
              )}

              {isEditable && (
                <Flex gap={"1rem"}>
                  <Button
                    variant={"transparent"}
                    disabled={newTreatmentDuration === oldTreatmentDuration}
                    px={0}
                    color={"rgba(0, 0, 0, 0.6)"}
                    fontWeight={"400"}
                    leftIcon={<AiOutlineCheck />}
                    onClick={() => {
                      sendChangeDurationRequest(
                        {
                          duration: newTreatmentDuration / 4,
                        },
                        {
                          onSuccess: () => {
                            setIsEditable(false);
                          },
                        }
                      );
                    }}
                    isLoading={isUpdatingDuration}
                    _disabled={{
                      color: "rgba(0, 0, 0, 0.2)",
                      cursor: "not-allowed",
                    }}
                  >
                    {t("Save")}
                  </Button>
                  <Button
                    variant={"transparent"}
                    color={"rgba(0, 0, 0, 0.6)"}
                    px={0}
                    fontWeight={"400"}
                    leftIcon={<AiOutlineClose />}
                    onClick={() => setIsEditable(false)}
                  >
                    {t("Cancel")}
                  </Button>
                </Flex>
              )}
            </>
          )}
        </Flex>

        {userData?.prescription_made && (
          <Flex w="full" align="center" justify="flex-end">
            <Heading color="blueish.500" fontSize="18px" fontWeight={500}>
              Medicin förskriven:
            </Heading>
            <Text
              color="rgba(0,0,0,0.3)"
              fontSize="18px"
              fontWeight={400}
              ml="1rem"
              mt=".2rem"
            >
              {userData?.prescription_made &&
                new Date(userData.prescription_made).toLocaleDateString(
                  t("locale") ?? "en-GB",
                  {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  }
                )}
            </Text>
          </Flex>
        )}

        {!userData?.treatment_duration && (
          <Text color={"blackish.200"} fontSize={"16px"}>
            {t("User have not got the prescription yet.")}
          </Text>
        )}
        {userData?.treatment_duration && (
          <Flex
            w="100%"
            bg="white"
            py={"0.5rem"}
            pb={"1rem"}
            borderRadius="12px"
            align="flex-start"
            direction="column"
            gap={"0.5rem"}
          >
            <Text color="rgba(0, 38, 55, 1)" fontSize="16px" fontWeight={400}>
              {(userData.treatment_duration ?? 0) * 4} {t("weeks")}
            </Text>
            <PopupChange
              isAdjustable={isEditable}
              selectedPlan={
                isEditable
                  ? newTreatmentDuration
                  : (userData?.treatment_duration ?? 0) * 4 ?? 0
              }
              setSelectedPlan={(newPlan) => {
                setNewTreatmentDuration(newPlan);
              }}
              dashboardDisplay={true}
              adminDisplayId={id}
            />
          </Flex>
        )}
      </Box>

      <Drawer isOpen={isOpen} onClose={onClose} size={{ base: "lg", lg: "xl" }}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p="40px">
            <UserHistory />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default AdminUserTreatment;
