import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";

import logo from "assets/images/logo.svg";
import taper from "assets/icons/taper.svg";
import faq from "assets/icons/faq.svg";

import general_bg_2 from "assets/images/general_bg_2.png";
import { t } from "i18next";
import { Link, useHistory } from "react-router-dom";

const GeneralNavbar = () => {
  const history = useHistory();
  return (
    <Flex
      alignItems="flex-start"
      w="full"
      h={{ base: "80vh", sm: "90vh", md: "80vh", lg: "80vh" }}
      px={{ base: "20px", sm: "80px", lg: "100px" }}
      bgImage={general_bg_2}
      backgroundPosition="center"
      backgroundSize="cover"
      direction="column"
      gap="12rem"
    >
      <Box
        position="absolute"
        left={-10}
        w="full"
        h={{ base: "80vh", sm: "90vh", md: "80vh", lg: "80vh" }}
        bgGradient="linear(to-l, rgba(0, 38, 55, 0), rgba(36, 98, 118, 0.5))"
      />
      <Flex
        w="full"
        align="center"
        justify="space-between"
        py="1.5rem"
        borderBottom="1px solid rgba(255, 255, 255, 0.3)"
        zIndex={1000}
      >
        <Image
          src={logo}
          alt="Lamina Clinic logo"
          w={{
            base: "120px",
            sm: "162px",
            md: "192px",
            lg: "256px",
          }}
          cursor={"pointer"}
          onClick={() => history.push("/")}
        />

        <HStack gap={{ base: "0em", sm: "62px" }}>
          <Flex align="center" gap="11px" cursor="pointer">
            <Image src={taper} alt={"taper"} mb="3px" />
            <Link to="/taper">
              <Text
                color="white"
                fontSize={{ base: "12px", sm: "18px" }}
                fontWeight={500}
              >
                {t("How the Taper Works")}
              </Text>
            </Link>
          </Flex>
          <Flex
            align="center"
            gap="9px"
            cursor={"pointer !important"}
            zIndex={1}
          >
            <Image src={faq} mb="5px" />
            <Link to="/faq">
              <Text
                fontSize={{ base: "12px", sm: "18px" }}
                fontWeight={500}
                cursor="pointer"
                color="white"
              >
                Vanliga frågor
              </Text>
            </Link>
          </Flex>
        </HStack>
      </Flex>

      <Flex
        w="100%"
        direction="column"
        px="3rem"
        gap="12px"
        zIndex={1000}
        mt="-3rem"
      >
        <Heading
          color="rgba(255, 255, 255, 1)"
          fontSize={{ base: "32px", sm: "56px", md: "64px", lg: "72px" }}
          fontWeight={500}
          w={{ base: "100%", md: "55%" }}
        >
          Vill du trappa ner på dina antidepressiva?
        </Heading>
        <Text
          color="rgba(255, 255, 255, 1)"
          fontSize={{ base: "16px", sm: "18px", md: "20px" }}
          fontWeight={400}
        >
          HAR DU KANSKE FÖRSÖKT FÖRUT, MEN INTE LYCKATS?
        </Text>
        <Button
          maxW="270px"
          color="rgba(0, 38, 55, 1)"
          minH={{ base: "50px", sm: "auto" }}
          mt="1rem"
          fontSize={{ base: "14px", sm: "18px" }}
        >
          <ChakraLink
            href="https://calendly.com/lamina-clinic/15-min-konsultation-kostnadsfritt"
            target="blank"
            textDecoration="none !important"
          >
            Boka gratis konsultation
          </ChakraLink>
        </Button>
      </Flex>
    </Flex>
  );
};

export default GeneralNavbar;
