import { Box, Divider, Flex, Heading } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import TabSelector from "ui/components/TabSelector";

import JournalCard from "./components/JournalCard";
import AddRecordButton from "./components/AddRecordButton";
import { useMemo, useState } from "react";

import { useParams } from "react-router-dom";
import { useFetchPatientJournal } from "./api/client";
import {
  JournalEntryDisplayType,
  JournalEntryType,
  PrescriptionEvent,
  TriageEventType,
} from "./api/types";

const JournalRecords = () => {
  const { id: userID } = useParams<{ id: string }>();

  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const { t } = useTranslation();
  const typeOptions = [
    {
      value: "",
      label: t("All records"),
    },
    {
      value: JournalEntryType.Treatment,
      label: t("Plan"), //Treatment
    },
    {
      value: JournalEntryType.Prescription,
      label: t("Prescription"),
    },
    {
      value: JournalEntryType.Meeting,
      label: t("Meeting"),
    },

    {
      value: JournalEntryType.Triage,
      label: t("Triage"),
    },
  ];

  const { data: journalData } = useFetchPatientJournal(userID);

  const transformedJournalData: JournalEntryDisplayType[] = useMemo(() => {
    return (
      journalData?.map((journalEntry) => {
        const singleDate = new Date(journalEntry.created_at);

        //used for highlighting differences between plans
        //this section finds previous plan journal entry and passes it down to
        //JournalCard component
        let previousPlan;
        if (journalEntry.tag === "UPDATE_PLAN") {
          previousPlan = journalData
            .filter(
              (e) =>
                (e.tag === "UPDATE_PLAN" || e.tag === "ASSIGN_PLAN") &&
                new Date(e.created_at).getTime() <
                  new Date(journalEntry.created_at).getTime()
            )
            .sort(
              (a, b) =>
                new Date(a.created_at).getTime() -
                new Date(b.created_at).getTime()
            )
            .at(0);
        }

        return {
          id: journalEntry.id,
          status: journalEntry.event_type,
          action_key: journalEntry.event_type,
          title: journalEntry.comment ?? "",
          details: {
            ...journalEntry.details,
            previousPlan: previousPlan?.details,
          },
          writer:
            "" +
            journalEntry.staff_member?.first_name +
            " " +
            journalEntry.staff_member?.last_name +
            " (" +
            journalEntry.staff_member?.role +
            ")",
          date: singleDate.toLocaleDateString(t("locale") ?? "en-GB"),
          allAnswers: (journalEntry.event as TriageEventType)?.answers?.reduce(
            (acc, item) => {
              acc[item.question] = item.answer;
              return acc;
            },
            {} as Record<string, string>
          ),
          action: journalEntry.tag,
          prescriptionEvent: journalEntry.event as PrescriptionEvent,
        };
      }) ?? []
    );
  }, [journalData]);

  const filteredJournalCards = useMemo(() => {
    if (!transformedJournalData) return [];

    if (selectedStatus === "") {
      return transformedJournalData;
    } else if (selectedStatus === JournalEntryType.Prescription) {
      return transformedJournalData.filter(
        (record) => record.status === selectedStatus
      );
    } else {
      return transformedJournalData.filter(
        (record) => record.status === selectedStatus
      );
    }
  }, [transformedJournalData, selectedStatus]);

  return (
    <Flex
      w="100%"
      py="2rem"
      borderRadius=".75rem"
      bg="white"
      direction="column"
      gap="2rem"
      id={"journal-records"}
      maxH="95dvh"
      overflowX="hidden"
    >
      <Flex w="full" align="center" justify="space-between" px="2rem">
        <Heading
          color="rgba(88, 154, 175, 0.8)"
          fontSize={"1.25rem"}
          fontWeight={600}
          textTransform={"uppercase"}
          mb={"0.75rem"}
        >
          {t("Journal Records")}
        </Heading>

        <AddRecordButton />
      </Flex>

      <Box w="max-content" px="2rem">
        <TabSelector<string>
          value={selectedStatus}
          onChange={setSelectedStatus}
          options={typeOptions}
        />
      </Box>

      <Divider w="full" colorScheme="rgba(88, 154, 175, 0.2)" />

      {filteredJournalCards.map((record) => (
        <JournalCard
          key={record.id}
          status={record.status}
          action_key={record.action_key}
          title={record.title}
          writer={record.writer}
          date={record.date}
          allAnswers={record?.allAnswers}
          action={record.action}
          details={record.details}
          prescriptionEvent={record.prescriptionEvent as PrescriptionEvent}
        />
      ))}
    </Flex>
  );
};

export default JournalRecords;
