import { Box, VStack } from "@chakra-ui/react";
import { useFetchAllDoctors } from "features/doctors/api/client";
import { IndividualFiltersType } from "features/reporting/api/type";
import { useFetchAllNurses } from "features/superadmin/api/client";
import { FunctionComponent } from "react";
import DoctorTableRowItem from "../DoctorTableRowItem";
import NurseTableRowItem from "../NurseTableRowItem";

interface NurseTableProps {
  filters?: IndividualFiltersType;
}

const NurseTable: FunctionComponent<NurseTableProps> = () => {
  const { data: nurseList } = useFetchAllNurses();

  return (
    <Box overflowX={"auto"}>
      <VStack w={"fit-content"} alignItems={"stretch"} p={"1rem"}>
        {nurseList?.nurses.map((nurse) => {
          return (
            <NurseTableRowItem
              id={nurse.id}
              name={nurse.first_name + " " + nurse.last_name}
            />
          );
        })}
      </VStack>
    </Box>
  );
};

export default NurseTable;
