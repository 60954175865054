import { Box, Flex, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { RiMedicineBottleLine } from "react-icons/ri";
import PrescriptionDetailsModal from "../PrescriptionDetailsModal";

type PrescriptionSectionProps = {
  title: "APL" | "Regular" | "Regenboogen";
  pillsInfo: Record<string, number>;
  eventID: string;
};

const PrescriptionSection: FunctionComponent<PrescriptionSectionProps> = ({
  title,
  pillsInfo,
  eventID,
}) => {
  const { t } = useTranslation();

  const {
    isOpen: modalIsOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const handleEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onModalOpen();
  };

  return (
    <VStack align="flex-start" spacing=".5rem">
      <Flex align="center" mt=".5rem">
        <RiMedicineBottleLine color="rgba(88, 154, 175, 0.7)" />
        <Text color="black" fontSize="1rem" ml="0.5rem">
          <Box as="span" fontWeight="bold">
            {t(title)}:
          </Box>
        </Text>
      </Flex>

      <Flex direction="column">
        <Flex gap={5}>
          <Text color="black" flex="1" fontSize="1rem" fontWeight="bold">
            {t("Dose")}
          </Text>
          <Text color="black" fontSize="1rem" fontWeight="bold">
            {t("Value")}
          </Text>
        </Flex>
        {Object.entries(pillsInfo).map(([key, value]) => (
          <Flex key={key} mt="0.3rem" gap={5}>
            <Text color="black" fontSize="1rem" flex="1">
              {key}
            </Text>
            <Text color="black" fontSize="1rem">
              {value} {t("pcs")}
            </Text>
          </Flex>
        ))}
        {/*   <PrescriptionDetailsModal
          handleEditClick={handleEditClick}
          modalIsOpen={modalIsOpen}
          onModalClose={onModalClose}
          eventID={eventID}
        /> */}
      </Flex>
    </VStack>
  );
};

export default PrescriptionSection;
