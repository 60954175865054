import { Flex, Heading, Text } from "@chakra-ui/react";
import { IoMdNotifications } from "react-icons/io";

import { useMemo, useState } from "react";
import { useGetNotificationsForWidget } from "features/notifications/api/client";

import { useFetchProfileInfo } from "features/dashboard/api/client";
import { useTranslation } from "react-i18next";
import { Notification } from "features/notifications/api/types";
import Pagination from "features/admin/components/Pagination";
import NotificationWidgetItem from "./components/NotificationWidgetItem";
import NotificationWidgetItemSkeleton from "./components/NotificationWidgetItemSkeleton";

const NotificationWiget = () => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data: userData } = useFetchProfileInfo();

  const { data: usersNotifications, isLoading } =
    useGetNotificationsForWidget(currentPage);

  const rolePaths: Record<string, string> = {
    doctor: "/doctor",
    nurse: "/nurse/patients",
    superadmin: "/superadmin",
  };

  const rolePath = useMemo(() => {
    if (
      userData &&
      typeof userData.role === "string" &&
      userData.role in rolePaths
    ) {
      return rolePaths[userData.role];
    } else {
      return "";
    }
  }, [userData]);

  return (
    <Flex
      w="full"
      bg="white"
      borderRadius="0.75rem"
      py="1.5rem"
      direction="column"
      minH="100%"
    >
      <Flex align="center" gap={3} px="2rem">
        <Heading
          fontWeight={600}
          color="blueish.500"
          fontSize="1.5rem"
          textTransform="uppercase"
        >
          {t("Patient events")}
        </Heading>
        <IoMdNotifications size={35} color="#589AAF" />
      </Flex>

      <Flex
        w="full"
        justify="space-between"
        px={{ base: "1.5rem", sm: "3rem" }}
        py="2rem"
        pb="0"
        align="start"
      >
        <Heading
          fontSize={{ base: "1rem", sm: "1.5rem" }}
          textTransform="uppercase"
          textAlign="left"
          w="28%"
          color="blueish.500"
          fontFamily="main"
        >
          {t("Typ")}
        </Heading>
        <Heading
          fontSize={{ base: "1rem", sm: "1.5rem" }}
          textTransform="uppercase"
          textAlign="left"
          w="24%"
          color="blueish.500"
          fontFamily="main"
        >
          {t("Patient")}
        </Heading>
        <Heading
          fontSize={{ base: "1rem", sm: "1.5rem" }}
          textTransform="uppercase"
          color="blueish.500"
          fontFamily="main"
          w="35%"
        >
          {t("MOOD")}
        </Heading>
        <Heading
          fontSize={{ base: "1rem", sm: "1.5rem" }}
          textTransform="uppercase"
          w="13%"
          color="blueish.500"
          fontFamily="main"
        >
          {t("Date")}
        </Heading>
      </Flex>

      <Flex
        w="full"
        direction="column"
        px={{ base: "1.5rem", sm: "3rem" }}
        py="1rem"
        align="start"
        minH="600px"
      >
        {isLoading ? (
          <NotificationWidgetItemSkeleton
            length={usersNotifications?.count || 10}
          />
        ) : (
          <>
            {!!usersNotifications?.results.length ? (
              <Flex w="full" direction="column" gap="2">
                {usersNotifications?.results.map(
                  (noticationInfo: Notification, index: number) => (
                    <NotificationWidgetItem
                      key={index}
                      noticationInfo={noticationInfo}
                      rolePath={rolePath}
                    />
                  )
                )}
              </Flex>
            ) : (
              <Text color="blueish.500">{t("No patients")}</Text>
            )}
          </>
        )}
      </Flex>

      <Flex w="full" align="center" justify="flex-end" px="3rem" mt="2">
        {!!usersNotifications?.results.length &&
          Math.ceil((usersNotifications?.count ?? 0) / 10) > 1 && (
            <Pagination
              currentPage={currentPage}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              pageCount={Math.ceil((usersNotifications?.count ?? 0) / 10)}
            />
          )}
      </Flex>
    </Flex>
  );
};

export default NotificationWiget;
