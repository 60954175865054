export const customSelectStyles = {
  dropdownIndicator: (provided: any) => ({
    ...provided,
    bg: "transparent",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: "1rem",
    fontWeight: 500,
    cursor: "pointer",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    cursor: "pointer",
  }),
  control: (provided: any) => ({
    ...provided,
    borderRadius: "0.375rem",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: "1rem",
    backgroundColor: state.isSelected
      ? "rgba(88, 154, 175, 0.50)"
      : "transparent",
    "&:hover": {
      backgroundColor: "rgba(88, 154, 175, 0.50)",
    },
  }),
};
