import { Button, Divider, Flex } from "@chakra-ui/react";

interface TabSelectorProps<ValueType> {
  value: ValueType;
  onChange(newValue: ValueType): void;
  options: { label: string; value: ValueType }[];
}

const TabSelector = <ValueType,>({
  value,
  onChange,
  options,
}: TabSelectorProps<ValueType>) => {
  return (
    <>
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        border={"1px solid #E5E9EB"}
        borderRadius={"0.5rem"}
        overflow={"hidden"}
        h={"2rem"}
      >
        {options.map((optionData, index) => {
          return (
            <>
              <Button
                variant={"ghost"}
                bg={optionData.value === value ? "#589AAF1A" : "transparent"}
                fontWeight={optionData.value === value ? 700 : 400}
                fontSize={"0.875rem"}
                color={optionData.value === value ? "#589AAF" : "#0026379f"}
                h={"100%"}
                _hover={{}}
                borderRadius={0}
                onClick={() => {
                  onChange(optionData.value);
                }}
                opacity={optionData.value === value ? 1 : 0.5}
                px={"1.25rem"}
              >
                {optionData.label}
              </Button>
              {index !== options.length - 1 && (
                <Divider
                  orientation="vertical"
                  borderColor={"#E5E9EB"}
                  opacity={1}
                  h={"2rem"}
                />
              )}
            </>
          );
        })}
      </Flex>
    </>
  );
};

export default TabSelector;
