import { defineStyleConfig } from "@chakra-ui/react";

export const Text = defineStyleConfig({
  baseStyle: {
    borderRadius: "2px",

  },

  variants: {
    light: {
      fontSize: "lg",
      color: "white",
      textAlign: "left",
      lineHeight: "tall",
      zIndex: 1,
    },
    dark: {
      fontSize: "lg",
      color: "#002637",
      textAlign: "left",
      lineHeight: "tall",
      zIndex: 1,
    },
    error: {
      color: '#D5738A',
      fontSize: 'xs',
      fontFamily: 'main'
    }
  },
  defaultProps: {
    variant: "light",
    
  },
});
