import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  /*  Select, */
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useAdminUserInfo } from "features/admin/api/client";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import {
  useCalculateTreatmentPlan,
  useGetMedicationsType,
} from "features/superadmin/api/client";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronDown } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ManualSelect from "ui/components/ManualSelect";
import ChangeTreatmentStartDate from "../ChangeTreatmentStartDate";
import GeneratedTaperingPlanSection from "../GeneratedTaperingPlanSection";
import StabilizePatient from "../StabilizePatient";

import { Select } from "chakra-react-select";
import TaperingSlider from "./components/TaperingSlider";

import TaperingDaysCheckboxes from "features/doctors/components/TyperingDaysChekboxes";

interface TaperingPlannerProps {
  id?: string;
  expand?: boolean;
  handleToggleExpand?: () => void;
}

const options = [
  { value: "5", label: "5%" },
  { value: "10", label: "10%" },
  { value: "15", label: "15%" },
];

const TaperingPlanner: FunctionComponent<TaperingPlannerProps> = ({
  id: propsId,
  expand,
  handleToggleExpand = () => {},
}) => {
  const { t } = useTranslation();

  const [selectedPharmacy, setSelectedPharmacy] =
    useState<string>("standard/apl");

  const { id: paramsId } = useParams<{ id: string }>();

  const id = propsId ?? paramsId ?? "";

  const { data: patientData } = useAdminUserInfo(id);

  const [selectedMedicationType, setSelectedMedicationType] =
    useState<string>();

  const { data: medicationType } = useGetMedicationsType();

  const medicationOptions = medicationType
    ? medicationType.map((medication) => ({
        value: medication.id,
        label: medication.name,
      }))
    : [];

  const [startDose, setStartDose] = useState<number>();
  const [endDose, setEndDose] = useState<number>(0);
  const [checkboxValue, setCheckboxValue] = useState<number>(7);

  const [pace, setPace] = useState<number>();

  const {
    data: calculatedPlanData,
    trigger: triggerCalculation,
    isMutating: isPlanLoading,
  } = useCalculateTreatmentPlan(
    selectedMedicationType,
    startDose,
    endDose,
    pace,
    checkboxValue
  );

  const startDate = useMemo(() => {
    if (patientData && patientData.treatment) {
      return new Date(patientData.treatment.start_date);
    }
    return null;
  }, [patientData]);

  useEffect(() => {
    if (patientData) {
      setStartDose(patientData.current_dose ?? patientData.dose);
      setSelectedMedicationType(patientData.medication?.id);
    }
  }, [patientData]);

  const [isMinimized, setIsMinimized] = useState<boolean>(false);

  const handleCalculateClick = () => {
    if (selectedPharmacy === "standard/apl") {
      triggerCalculation(undefined, {
        onError: (e: any) => {
          toast.error(e.response.data.detail);
        },
      });
    } else {
      triggerCalculation(
        { pharmacy: "regenboogen" },
        {
          onError: (e: any) => {
            toast.error(e.response.data.detail);
          },
        }
      );
    }
  };

  const medicationWarning = medicationType?.find(
    (medication) => medication.id === selectedMedicationType
  )?.warning;

  const handleCheckboxChange = (newValue: number) => {
    setCheckboxValue(newValue);
  };

  return (
    <>
      <Collapse in={!isMinimized}>
        <Flex
          px="1rem"
          w={"100%"}
          justifyContent={"space-between"}
          mb={"1rem"}
          direction={{ base: "column", sm: "row" }}
          h="fit-content"
        >
          <Flex
            align="center"
            border="1px solid rgba(0, 0, 0, 0.2)"
            borderRadius={8}
            maxH="2.5rem"
          >
            <Box
              fontSize={"1rem"}
              cursor={"pointer"}
              onClick={() => {
                setSelectedPharmacy("standard/apl");
              }}
              fontWeight={selectedPharmacy === "standard/apl" ? 700 : 400}
              color={
                selectedPharmacy === "standard/apl"
                  ? "rgba(88, 154, 175, 1)"
                  : "rgba(0, 0, 0, 0.5)"
              }
              flex="1"
              textAlign="center"
              py={2}
              px="5"
              bg={
                selectedPharmacy === "standard/apl"
                  ? "rgba(88, 154, 175, 0.10)"
                  : "transparent"
              }
            >
              {t("Standard/APL")}
            </Box>

            <Divider orientation="vertical" />

            <Box
              cursor={"pointer"}
              onClick={() => {
                setSelectedPharmacy("regenboogen");
              }}
              fontWeight={selectedPharmacy === "regenboogen" ? 700 : 400}
              color={
                selectedPharmacy === "regenboogen"
                  ? "rgba(88, 154, 175, 1)"
                  : "rgba(0, 0, 0, 0.5)"
              }
              flex="1"
              textAlign="center"
              bg={
                selectedPharmacy === "regenboogen"
                  ? "rgba(88, 154, 175, 0.10)"
                  : "transparent"
              }
              py={2}
              px="5"
            >
              {t("RegenBoogen")}
            </Box>
          </Flex>

          <Flex gap="1.25rem">
            {startDate && (
              <Flex alignItems={"center"} gap={"0.25rem"} direction="column">
                <Text
                  fontSize={{ md: "14px", lg: "stnd" }}
                  color="rgba(0, 38, 55, 0.5)"
                  fontWeight={400}
                >
                  {t("Plan start date")}
                </Text>
                <Text
                  color="rgba(0, 38, 55, 1)"
                  fontSize={{ md: "14px", lg: "1rem" }}
                  fontWeight={700}
                  alignSelf="flex-end"
                >
                  {startDate?.toLocaleDateString(t("locale") ?? "en-GB", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </Text>
              </Flex>
            )}

            {/* <ChangeTreatmentStartDate /> */}
          </Flex>
        </Flex>

        <Flex
          w="full"
          py="1.5rem"
          px="1rem"
          gap={"1rem"}
          direction={{ base: "column", md: "row" }}
        >
          <Box zIndex={2} w="30%">
            <Text
              color="#002637"
              opacity="0.5"
              fontSize=".875rem"
              fontFamily="secondary"
              fontWeight={400}
            >
              {t("Medication type").toUpperCase()}
            </Text>
            <Box>
              <Box bg={"white"}>
                <ManualSelect
                  hideIcon
                  options={medicationOptions}
                  value={selectedMedicationType}
                  onChange={(value) => {
                    setSelectedMedicationType(value);
                  }}
                  _containerStyles={{
                    minW: "18rem",
                    border: "none !important",
                    height: "48px",
                    cursor: "default",
                    background: "rgba(0, 38, 55, 0.05)",
                  }}
                  _dropdownStyles={{
                    display: "none",
                    minHeight: "10rem",
                    maxHeight: "10rem",
                    overflowY: "auto",
                  }}
                  _valueStyles={{
                    color: "#002637",
                    fontWeight: 500,
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box w="20%">
            <Text
              color="#002637"
              opacity="0.5"
              fontSize=".875rem"
              fontFamily="secondary"
              fontWeight={400}
            >
              {t("Start Dose").toUpperCase()}
            </Text>
            <InputGroup>
              <Input
                fontSize={"1rem"}
                bg="rgba(0, 38, 55, 0.05) !important"
                fontWeight={500}
                type={"number"}
                h={"3rem"}
                py={"0.5rem"}
                minHeight={"none"}
                value={startDose}
                onChange={(e) => {
                  setStartDose(e.target.valueAsNumber);
                }}
              />
            </InputGroup>
          </Box>

          <Box w="20%">
            <Text
              color="#002637"
              opacity="0.5"
              fontSize=".875rem"
              fontFamily="secondary"
              fontWeight={400}
            >
              {t("End Dose").toUpperCase()}
            </Text>
            <InputGroup>
              <Input
                fontSize={"1rem"}
                bg="rgba(0, 38, 55, 0.05) !important"
                fontWeight={500}
                type={"number"}
                h={"3rem"}
                py={"0.5rem"}
                minHeight={"none"}
                value={endDose}
                onChange={(e) => {
                  setEndDose(e.target.valueAsNumber);
                }}
              />
            </InputGroup>
          </Box>

          <Box w="30%">
            <Text
              color="#002637"
              opacity="0.5"
              fontSize=".875rem"
              fontFamily="secondary"
              fontWeight={400}
            >
              {t("Tapering Pace").toUpperCase()}
            </Text>
            <Select
              useBasicStyles
              options={options}
              value={
                options.find((option) => option.value === pace?.toString()) || {
                  value: "",
                  label: `${t("Please select")}`,
                }
              }
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setPace(parseInt(selectedOption.value));
                }
              }}
              chakraStyles={{
                control: (provided) => ({
                  ...provided,
                  background: "rgba(0, 38, 55, 0.05) !important",
                  minW: "10rem",
                  minH: "3rem",
                  px: "0px",
                  fontSize: "1rem",
                  fontWeight: 500,
                  color: "rgba(0,38,55,0.5)",
                  "@media screen and (min-width: 1200px)": {
                    minH: "2.9rem",
                  },
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 2,
                }),
                option: (provided) => ({
                  ...provided,
                  zIndex: 2,
                  fontSize: ".9rem",
                }),
              }}
            />
          </Box>
        </Flex>

        <Flex w="full" px="1rem" mb="7">
          <TaperingDaysCheckboxes
            value={checkboxValue}
            onChange={handleCheckboxChange}
          />

          <Button
            variant="filledBlue"
            minWidth={"none"}
            px={"1.5rem"}
            py={{ base: ".8rem", sm: "auto" }}
            alignSelf={"flex-end"}
            onClick={handleCalculateClick}
            isDisabled={!pace || !startDose || !selectedMedicationType}
            gap={"1rem"}
            fontSize="1rem"
          >
            {t("Calculate plan")}
          </Button>
        </Flex>
      </Collapse>

      {(calculatedPlanData || isPlanLoading) && (
        <>
          {isPlanLoading ? (
            <Flex w="full" align="center" justify="center">
              <Spinner emptyColor="gray.200" color="blueish.500" />
            </Flex>
          ) : (
            <>
              <Flex
                w="full"
                align="center"
                justify="center"
                direction="column"
                onClick={() => {
                  setIsMinimized(!isMinimized);
                }}
              >
                <Flex
                  align="center"
                  gap={2}
                  cursor="pointer"
                  pb={{ md: "1.25rem", lg: "1.25rem" }}
                >
                  <Text
                    color="#002637"
                    fontSize="1rem"
                    fontWeight={700}
                    opacity={0.5}
                  >
                    {!isMinimized ? t("Minimize") : t("Expand")}
                  </Text>

                  <BsChevronDown
                    style={{
                      transform: isMinimized ? "" : "rotate(180deg)",
                      transition: "all 0.5s ease-out",
                    }}
                  />
                </Flex>
                <Divider />
              </Flex>

              <GeneratedTaperingPlanSection
                plan={calculatedPlanData.plan}
                medicineName={selectedMedicationType || ""}
                pharmacy={selectedPharmacy}
                warning={medicationWarning}
                toggleTaperingPlanner={handleToggleExpand}
                duration={checkboxValue}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default TaperingPlanner;
