import { Box, Flex, Td, Tr } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { TableHeaderItemType, TableRowItemType } from "../../api/types";

interface TableRowProps {
  rowData: TableRowItemType;
  columns: TableHeaderItemType[];
}

const TableRow: FunctionComponent<TableRowProps> = ({ rowData, columns }) => {
  const isStabilized = rowData.is_stabilize === true;
  return (
    <Tr bg={isStabilized ? "azure" : "white"}>
      {columns.map((e) => {
        return (
          <Td key={`${rowData.id}-${e.name}`} sx={e.columnStyle}>
            <Flex minH={"3.75rem"} alignItems={"center"}>
              {rowData[e.name]}
            </Flex>
          </Td>
        );
      })}
    </Tr>
  );
};

export default TableRow;
