import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFetchAdminReportByDoctorId } from "features/reporting/api/client";
import { IndividualFiltersType } from "features/reporting/api/type";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMessage, AiOutlinePhone } from "react-icons/ai";
import { BiGroup } from "react-icons/bi";
import { BsCameraVideo } from "react-icons/bs";

interface DoctorTableRowItemProps {
  id: string;
  name: string;
  filters?: IndividualFiltersType;
}

const DoctorTableRowItem: FunctionComponent<DoctorTableRowItemProps> = ({
  id,
  name,
  filters,
}) => {
  const { data: callsData } = useFetchAdminReportByDoctorId(id, filters);
  const { t } = useTranslation();

  return (
    <HStack
      w={"fit-content"}
      minW={"100%"}
      boxShadow={"0px 0px 4px 4px rgba(0, 0, 0, 0.05)"}
      p={"1rem"}
      minH={"2rem"}
      borderRadius={"0.5rem"}
      gap={"2rem"}
    >
      <Text
        fontSize={"1.25rem"}
        color={"blueish.900"}
        fontWeight={"400"}
        minW={"20rem"}
      >
        {name}
      </Text>

      <Center
        minW={"13rem"}
        alignItems={"center"}
        gap={"1rem"}
        border={"1px solid rgba(0, 0, 0, 0.1)"}
        borderRadius={"4px"}
        p={"0.5rem"}
      >
        <Flex alignItems={"center"} gap={"0.25rem"}>
          <BsCameraVideo />

          <Text fontSize={"1rem"} color={"blueish.900"} h={"1.35em"}>
            {t("Video calls")}
          </Text>
        </Flex>

        <Flex gap={"0.5rem"}>
          <Text fontSize={"1rem"} color={"blueish.900"}>
            {t("made")}: <b>{callsData?.data.calls.video_call.made.doctor}</b>
          </Text>
        </Flex>
      </Center>

      <Center
        minW={"13rem"}
        alignItems={"center"}
        gap={"1rem"}
        border={"1px solid rgba(0, 0, 0, 0.1)"}
        borderRadius={"4px"}
        p={"0.5rem"}
      >
        <Flex alignItems={"center"} gap={"0.25rem"}>
          <AiOutlinePhone />

          <Text fontSize={"1rem"} color={"blueish.900"} h={"1.35em"}>
            {t("Voice calls")}
          </Text>
        </Flex>

        <Flex gap={"0.5rem"}>
          <Text fontSize={"1rem"} color={"blueish.900"}>
            {t("made")}: <b>{callsData?.data.calls.voice_call.made.doctor}</b>
          </Text>
        </Flex>
      </Center>

      <Center
        minW={"18rem"}
        alignItems={"center"}
        gap={"1rem"}
        border={"1px solid rgba(0, 0, 0, 0.1)"}
        borderRadius={"4px"}
        p={"0.5rem"}
      >
        <Flex alignItems={"center"} gap={"0.25rem"}>
          <AiOutlineMessage />

          <Text fontSize={"1rem"} color={"blueish.900"} h={"1.35em"}>
            {t("Messages")}
          </Text>
        </Flex>

        <Flex gap={"0.5rem"}>
          <Text fontSize={"1rem"} color={"blueish.900"}>
            {t("sent")}: <b>{callsData?.data.messages.sent.doctor}</b>
          </Text>
          <Divider orientation="vertical" h={"1.5rem"} />

          <Text fontSize={"1rem"} color={"blueish.900"}>
            {t("received")}: <b>{callsData?.data.messages.received.doctor}</b>
          </Text>
        </Flex>
      </Center>

      {Object.entries(callsData?.data.meetings ?? {}).length > 0 && (
        <Center
          minW={"18rem"}
          alignItems={"center"}
          gap={"1rem"}
          border={"1px solid rgba(0, 0, 0, 0.1)"}
          borderRadius={"4px"}
          p={"0.5rem"}
        >
          <Flex alignItems={"center"} gap={"0.25rem"}>
            <BiGroup />

            <Text fontSize={"1rem"} color={"blueish.900"} h={"1.35em"}>
              {t("Meetings")}
            </Text>
          </Flex>

          <VStack gap={"0.25rem"} alignItems={"flex-start"}>
            {Object.entries(callsData?.data.meetings ?? {}).map(
              ([key, value]) => {
                return (
                  <Text fontSize={"1rem"} color={"blueish.900"}>
                    {t(key)}: <b>{value}</b>
                  </Text>
                );
              }
            )}
          </VStack>
        </Center>
      )}
    </HStack>
  );
};

export default DoctorTableRowItem;
