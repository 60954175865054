import {
  Box,
  Divider,
  Flex,
  Heading,
  Text,
  Image,
  Button,
  Link as ChakraLink,
} from "@chakra-ui/react";

import UserDeliveryAddress from "../dashboardUserDeliveryAddress";
import UserTrackingNumber from "../dashboardUserTrackingNumber";
import UserTreatment from "../dashboardUserTreatment";

import archive from "assets/icons/archive.svg";
import { useOrderStore } from "services/state/orderStore";
import { useDashboardInfo } from "features/dashboard/api/client";
import { useFetchAddressById } from "features/addressPopup/api/client";
import { useTranslation } from "react-i18next";

const UserAddress = () => {
  const { t } = useTranslation();
  const { currentStep, currentStepState } = useOrderStore();

  const { data: dashboardData } = useDashboardInfo();

  return (
    <>
      <Box py="2rem">
        <Divider orientation="horizontal" h="1px" borderColor="gray.300" />
      </Box>
      <Flex
        py="1rem"
        align="flex-start"
        justify="space-between"
        w="100%"
        p={{ base: "2%", sm: "0%" }}
        direction={{ base: "column", sm: "row" }}
        gap={{ base: "1rem", sm: "4rem" }}
      >
        <UserDeliveryAddress />

        <UserTreatment />

        <Flex direction="column" gap={".25rem"} w="100%">
          <Heading fontSize="16px" color={"blueish.500"} fontWeight={500}>
            {t("PRICE")}
          </Heading>
          <Text color="blackish.200" fontSize="16px" fontWeight="bold">
            {dashboardData?.order?.price} {dashboardData?.order?.currency}
          </Text>
        </Flex>

        {currentStep >= 3 ? <UserTrackingNumber /> : <Box w={"100%"} />}

        <Flex direction="column" gap={"1.5rem"} w="100%">
          <Flex direction="column" gap={".25rem"}>
            <Text color="blackish.200" fontSize="16px" fontWeight={500}>
              {t("Have an issue with your order?")}
            </Text>
            <ChakraLink href="mailto:info@laminaclinic.com">
              <Text
                color="blackish.200"
                fontSize="16px"
                fontWeight={"bold"}
                cursor="pointer"
                textDecoration="underline"
              >
                {t("Contact Us")}
              </Text>
            </ChakraLink>
          </Flex>

          {currentStep === 4 && (
            <Button
              variant="archive"
              alignItems="center"
              justifyContent="center"
              gap={2}
              maxW="180px"
            >
              <Image src={archive} alt={"icon to archive order"} />
              <Text fontSize="16px" color="blueish.500">
                {t("Archive Order")}
              </Text>
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default UserAddress;
