import UserOrder from "../dashboardUserOrder";
import UserAddress from "../dashboardUserAddress";
import { Flex, Heading, Text } from "@chakra-ui/react";
import { useOrderStore } from "services/state/orderStore";
import { useTranslation } from "react-i18next";
import { useDashboardInfo } from "features/dashboard/api/client";

const Order = () => {
  const { t } = useTranslation();
  const { currentStep, currentStepState } = useOrderStore();
  const { data: dashboardData } = useDashboardInfo();

  return (
    <Flex direction="column" justify="space-between" p="2.5rem" w="100%">
      <Flex alignItems={"baseline"} mb={"2rem"}>
        <Heading
          color="blueish.500"
          textAlign={{ base: "center", sm: "start" }}
          fontWeight={500}
          fontSize={"md"}
          mr={"1.5rem"}
        >
          {t("YOUR ORDER")}
        </Heading>
        {dashboardData?.order && (
          <Text
            fontWeight={400}
            fontSize={"1rem"}
            color={"blueish.900"}
            lineHeight={"1rem"}
            opacity={0.5}
          >
            № {dashboardData?.order?.order_number.toString().padStart(5, "0")}
          </Text>
        )}
      </Flex>

      <UserOrder />
      {currentStep > 0 && dashboardData?.order && <UserAddress />}
    </Flex>
  );
};

export default Order;
