import { useEffect, useState } from "react";

export const useAudio = (audioUrl: string, looped?: boolean) => {
  const [audio] = useState(new Audio(audioUrl));
  const [playing, setPlaying] = useState(false);

  const play = () => {
    setPlaying(true);
  };

  const stop = () => {
    setPlaying(false);
  };

  const pause = () => {
    setPlaying(false);
  };

  useEffect(() => {
    playing ? audio.play() : audio.pause();

    if (looped) {
      audio.loop = true;
    } else {
      audio.loop = false;
    }
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return {
    play,
    stop,
    pause,
  };
};

// export const useAudio = (url: string) => {
//   const [audio] = useState(new Audio(url));
//   const [playing, setPlaying] = useState(false);

//   const toggle = () => {
//     setPlaying(!playing);
//   };

//   return { playing, toggle };
// };
