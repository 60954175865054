import { Text, Flex, Heading, VStack, Image } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import drugs from "assets/images/drugs.png";
import breaking from "assets/images/breaking.png";
import line_left from "assets/images/line_left.svg";
import AnimatedText from "ui/components/AnimatedText";

const HeroContext = () => {
  const { t } = useTranslation();

  return (
    <VStack
      w={"100%"}
      bg={"white"}
      mt={"0"}
      p={"5%"}
      borderRadius={"1.5rem"}
      justify={"center"}
      align={"center"}
      position={"relative"}
      gap={"3rem"}
    >
      {/* HEADER */}
      <Flex
        direction={"column"}
        justify={"center"}
        alignItems={"center"}
        w={"100%"}
        gap={5}
      >
        <Heading
          fontSize={{
            base: "lg",
            sm: "32px",
            md: "36px ",
            lg: "56px",
          }}
          lineHeight={{
            base: "auto",
            sm: "36px",
            md: "64px",
            lg: "64px",
          }}
          textAlign={"center"}
          w={{ base: "90%", sm: "40rem" }}
        >
          <AnimatedText>{t("contextHeader")}</AnimatedText>
        </Heading>

        <Text
          variant={"dark"}
          textAlign={"center"}
          lineHeight={{ base: "", sm: "35px" }}
          fontSize={{
            base: "16px",
            sm: "20px",
            md: "24px",
            lg: "24px",
          }}
          w={{ base: "90%", sm: "70%" }}
        >
          <AnimatedText>{t("contextHeaderDiscription")}</AnimatedText>
        </Text>
      </Flex>

      {/* CONTENT 1 */}
      <Flex
        w={{ base: "90%", md: "80%", lg: "70%" }}
        align="center"
        gap={"2.625rem"}
        zIndex={"1"}
        direction={{ base: "column", sm: "row" }}
        ml={{ base: "0px", sm: "5rem" }}
      >
        <Image
          src={drugs}
          alt="Pills Icon"
          width={{ base: "90%", sm: "52%", md: "32%" }}
        />

        <Text
          color={"black"}
          ml={["2rem", "0rem", "0rem"]}
          lineHeight={{ base: "auto", sm: "35px" }}
          fontSize={{
            base: "16px",
            sm: "20px",
            md: "22px",
            lg: "24px",
          }}
          maxW={{ base: "100%", sm: "55%", md: "45%" }}
        >
          <AnimatedText>{t("contextFirstBlock")}</AnimatedText>
        </Text>
      </Flex>

      {/* LINE */}
      <Image
        src={line_left}
        alt="Decorative line fragment"
        display={{ base: "inline-block", sm: "none" }}
        position={"absolute"}
        top={"46%"}
        left={{ base: "7%", sm: "0%" }}
        height={"25%"}
      />

      {/* CONTENT 2 */}
      <Flex
        w={{ base: "90%", md: "80%", lg: "70%" }}
        align="center"
        gap={"2.625rem"}
        zIndex={"1"}
        direction={{ base: "column-reverse", sm: "row" }}
        ml={{
          base: "0px",
          sm: "5rem !important",
          md: "17rem !important",
        }}
      >
        <Text
          variant={"dark"}
          ml={["2rem", "0rem", "0rem"]}
          lineHeight={{ base: "auto", sm: "35px" }}
          fontSize={{
            base: "16px",
            sm: "20px",
            md: "22px",
            lg: "24px",
          }}
          maxW={{ base: "100%", sm: "45%" }}
          fontWeight={"350"}
        >
          <AnimatedText>{t("contextSecondBlock")}</AnimatedText>
        </Text>
        <Image
          src={breaking}
          alt="Pills icon"
          width={{ base: "90%", sm: "52%", md: "32%" }}
        />
      </Flex>
    </VStack>
  );
};

export default HeroContext;
