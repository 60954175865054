import {
  Box,
  Button,
  Divider,
  Flex,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { FiFilter } from "react-icons/fi";
import FiltersDropDown from "../FiltersDropdown";
import { useTranslation } from "react-i18next";

interface FilterButtonProps {}

const FilterButton: FunctionComponent<FilterButtonProps> = () => {
  const { t } = useTranslation();

  const { isOpen, onClose, onOpen, onToggle } = useDisclosure();

  return (
    <>
      <VStack w={"100%"} alignItems={"flex-end"}>
        <Button
          variant={isOpen ? "edit" : "black"}
          leftIcon={<FiFilter />}
          minWidth={"none"}
          onClick={onToggle}
          fontSize={"1.25rem"}
          px={"2rem"}
          py={"0.5rem"}
        >
          {t("Filter")}
        </Button>
        <Box w={"100%"}>
          <FiltersDropDown isOpen={isOpen} onClose={onClose} />
        </Box>
      </VStack>
    </>
  );
};

export default FilterButton;
