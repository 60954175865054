import { Image, Heading, Text, Box, Flex } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import phone from "assets/images/phone.png";

import laminaQR from "assets/icons/laminaQR.svg";
import app_store_1 from "../../../../assets/icons/app_store_1.svg";
import google_play from "../../../../assets/images/google-play-badge.svg";
import { triggerAnnouncementModal } from "features/genericModal";
import QRCode from "react-qr-code";
import AnimatedText from "ui/components/AnimatedText";

const HomeContactUs = () => {
  const { t } = useTranslation();

  return (
    <Flex
      justify={"center"}
      align={"center"}
      w={{ base: "auto", sm: "100%" }}
      py={{ base: "200px", lg: "80px" }}
      direction={{
        base: "column",
        sm: "row",
      }}
      gap={{ base: 5, sm: 0 }}
      mb={{ base: "", md: "50px", lg: "210px" }}
      position="relative"
    >
      <Flex direction={"column"} gap={5} w={"auto"}>
        <Box display={"flex"} flexDirection={"column"} gap={5} w={"100%"}>
          <Heading
            variant={"light"}
            fontSize={{
              base: "24px",
              sm: "36px",
              md: "48px",
              lg: "xxl",
            }}
            lineHeight={{
              base: "2rem",
              sm: "2.5rem",
              md: "3.5rem",
              lg: "4rem",
            }}
            textAlign={{
              base: "center",
              sm: "center",
              md: "inherit",
              lg: "inherit",
            }}
            w={{ base: "100%", sm: "55%" }}
          >
            <AnimatedText>{t("contactUsHeader")}</AnimatedText>
          </Heading>
          <Text
            fontSize={["calc(16px + 0.1vw)", "calc(20px + 0.1vw)", "lg"]}
            w={"60%"}
          >
            {t("contactUsDiscription")}
          </Text>
        </Box>
        <Flex gap={5} w={"50%"} alignItems={"center"} h={"150px"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={5}
          >
            <Box width={"100%"}>
              <Image
                alt={"App store app link"}
                src={app_store_1}
                minW={"200px"}
                cursor={"pointer"}
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/us/app/lamina-clinic/id6448011545",
                    "_blank"
                  );
                }}
              />
            </Box>

            <Box width={"100%"}>
              <Image
                src={google_play}
                alt={"Play Market app link"}
                minW={"200px"}
                cursor={"pointer"}
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.laminaclinic.laminaclinic",
                    "_blank"
                  );
                }}
              />
            </Box>
          </Box>

          <Flex w={"auto"} justifyContent={"flex-start"}>
            <QRCode
              value="https://onelink.to/6up88c"
              style={{ height: "10rem", width: "10rem" }}
            />
          </Flex>
        </Flex>
      </Flex>
      <Image
        position="absolute"
        top={-20}
        right={{ base: 100, md: 150, lg: 250 }}
        src={phone}
        alt="Phone icon"
        minW={"200px"}
        flex={"0 0 25%"}
        zIndex={1}
      />
    </Flex>
  );
};

export default HomeContactUs;
