export const actionStyles: Record<
  string,
  { color: string; backgroundColor: string; bgWithOpacity: string }
> = {
  ASSIGN_PLAN: {
    backgroundColor: "rgba(72, 61, 139, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(72, 61, 139, 0.2)",
  },
  OTHER: {
    backgroundColor: "rgba(128, 40, 0, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(128, 40, 0, 0.2)",
  },
  START_PLAN: {
    backgroundColor: "rgba(34, 139, 34, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(34, 139, 34, 0.2)",
  },
  INITIAL_PRESCRIPTION: {
    backgroundColor: "rgba(34, 139, 194, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(34, 139, 194, 0.2)",
  },
  RENEWAL_PRESCRIPTION: {
    backgroundColor: "rgba(94, 139, 194, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(94, 139, 194, 0.2)",
  },

  MEETING_CANCELLED: {
    backgroundColor: "rgba(178, 34, 34, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(178, 34, 34, 0.1)",
  },
  MEETING_RESCHEDULED: {
    backgroundColor: "rgba(94, 139, 254, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(94, 139, 194, 0.2)",
  },
  UPDATE_PLAN: {
    backgroundColor: "rgba(220, 120, 0, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(200, 120, 0, 0.2)",
  },
  STABILIZE: {
    backgroundColor: "rgba(88, 154, 175, 0.8)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(218, 165, 32, 0.2)",
  },
  DOCTOR_MEETING: {
    backgroundColor: "rgba(88, 154, 175, 0.8)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(218, 165, 32, 0.2)",
  },
  NURSE_MEETING: {
    backgroundColor: "rgba(88, 154, 175, 0.5)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(218, 165, 32, 0.2)",
  },
  CANCEL: {
    backgroundColor: "rgba(178, 34, 34, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(178, 34, 34, 0.2)",
  },
  STOP_PLAN: {
    backgroundColor: "rgba(178, 34, 34, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(178, 34, 34, 0.2)",
  },
};

export const statusStyles: Record<
  string,
  { color: string; backgroundColor: string; bgWithOpacity: string }
> = {
  Triage: {
    backgroundColor: "rgba(44, 132, 117, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(44, 132, 117, 0.2)",
  },
  meeting: {
    backgroundColor: "rgba(88, 154, 175, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(88, 154, 175, 0.2)",
  },
  action: {
    backgroundColor: "rgba(100, 124, 173, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(100, 124, 173, 0.2)",
  },
  JSONTreatment: {
    backgroundColor: "rgba(232, 155, 38, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(232, 155, 38, 0.2)",
  },
  Treatment: {
    backgroundColor: "rgba(232, 155, 38, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(232, 155, 38, 0.2)",
  },
  other: {
    backgroundColor: "rgba(97, 150, 189, 1)",
    color: "rgba(255, 255, 255, 1)",
    bgWithOpacity: "rgba(97, 150, 189, 0.2)",
  },
};

export const formatAction = (action: string): string => {
  return action
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const nameCaster = (statusName: string) => {
  const mapper: Record<string, string> = {
    JSONTreatment: "Treatment",
  };

  return mapper[statusName] ?? statusName;
};
