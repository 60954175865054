import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useChangeServicePrice } from "features/superadmin/api/client";
import { useFormik } from "formik";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { GoPencil } from "react-icons/go";

type EditServicePriceProps = {
  serviceID: string;
};

type InitialValues = {
  price: number | undefined;
};

const EditServicePrice: FunctionComponent<EditServicePriceProps> = ({
  serviceID,
}) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { trigger: mutatePrice } = useChangeServicePrice(serviceID);

  const formik = useFormik<InitialValues>({
    initialValues: {
      price: undefined,
    },
    onSubmit: (values) => {
      if (values.price === undefined || isNaN(values.price)) return;

      mutatePrice({ price: values.price }).catch((error) => {
        console.error("Failed to update price:", error);
      });

      formik.resetForm();
      onClose();
    },
  });

  const handleCancel = () => {
    formik.resetForm();
  };
  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <IconButton
          aria-label="Edit"
          icon={<GoPencil size={20} />}
          minWidth={0}
          variant="transparent"
          p="0 !important"
          color="#0026377F"
          fontSize="1.5rem"
          _hover={{ color: "rgba(88, 154, 175, 1)" }}
          onClick={onOpen}
        />
      </PopoverTrigger>

      <PopoverContent
        boxShadow="lg"
        border="1px solid"
        borderColor="gray.200"
        maxW="13.75rem"
      >
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader
          fontWeight={700}
          color="rgba(88, 154, 175, 1)"
          fontSize="1.25rem"
        >
          {t("Change price")}
        </PopoverHeader>
        <PopoverBody>
          <VStack align="flex-start" spacing=".5rem" pb=".5rem">
            <Text color="rgba(0, 38, 55, 0.5)" fontSize="1rem">
              {t("New price")}:
            </Text>
            <InputGroup>
              <Input
                value={formik.values.price ?? ""}
                placeholder={`${t("add new price...")}`}
                _placeholder={{
                  fontSizeAdjust: ".5rem",
                  fontWeight: 400,
                }}
                w="full"
                h="3rem"
                type="number"
                min={0}
                minHeight={0}
                step="0.001"
                bg="#0026370D !important"
                fontWeight={700}
                fontSize="1rem"
                onChange={(e) => {
                  const value = e.target.value
                    ? parseFloat(e.target.value)
                    : undefined;
                  formik.setFieldValue("price", value);
                }}
              />
            </InputGroup>
            <Spacer height="1rem" />
            <Flex w="full" gap="1rem">
              <Button
                variant="edit"
                px="1rem"
                onClick={handleCancel}
                textTransform="capitalize"
              >
                {t("cancel")}
              </Button>
              <Button
                onClick={formik.submitForm}
                variant="black"
                px="2rem"
                isDisabled={!formik.dirty || formik.isSubmitting}
              >
                {t("Save")}
              </Button>
            </Flex>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default EditServicePrice;
