import EditForm from "features/editProfile/components/EditForm";
import { FunctionComponent } from "react";

interface EditProfileProps {}

const EditProfile: FunctionComponent<EditProfileProps> = () => {
  return (
    <>
      <EditForm />
    </>
  );
};

export default EditProfile;
