import { Button, Input, VStack } from "@chakra-ui/react";
import { useImportCSV } from "features/taperingCSV/api/client";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface ImportCSVButtonProps {
  medicineName: string;
  taperingRate: number;
}

const ImportCSVButton: FunctionComponent<ImportCSVButtonProps> = ({
  medicineName,
  taperingRate,
}) => {
  const { t } = useTranslation();
  const { trigger: importCSV, isMutating } = useImportCSV(
    medicineName,
    taperingRate
  );
  const [csvFile, setcsvFile] = useState<File>();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleImportCSVClick = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    inputRef?.current?.click();
  };

  return (
    <VStack>
      <Button
        variant={"dark"}
        onClick={() => {
          handleImportCSVClick();
        }}
        isLoading={isMutating}
        isDisabled={medicineName === ""}
      >
        {t("Import a CSV file")}
      </Button>
      <Input
        type={"file"}
        display={"none"}
        ref={inputRef}
        value={undefined}
        onChange={(e) => {
          if (e.target.files) {
            setcsvFile(e.target.files[0]);
            const formData = new FormData();

            formData.append("file", e.target.files[0]);
            formData.append("medication", medicineName);
            formData.append("type", taperingRate.toString());

            importCSV(formData);
          }
        }}
      />
    </VStack>
  );
};

export default ImportCSVButton;
