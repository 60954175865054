import { useTranslation } from "react-i18next";
import i18n from "i18next";

export const dayNumber: number[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

export const days: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const months: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function getFormatedDate(
  format: string = "standard",
  today: boolean = false
): string {
  if (today) {
    return "Today";
  } else {
    let date = new Date();
    let day = days[date.getUTCDay()];
    let month = months[date.getUTCMonth()];
    let dateNum = date.getUTCDate();
    let year = date.getUTCFullYear();
    if (format === "number-month-year") {
      return `${dateNum} ${month} ${year}`;
    } else {
      return `${i18n.t(day)}, ${i18n
        .t(month)
        .toLocaleLowerCase()} ${dateNum}, ${year}`;
    }
  }
}

export function getOrderDate(): {
  day: number;
  months: string;
  year: number;
} {
  let date = new Date();
  let day = dayNumber[date.getUTCDay()];
  let month = months[date.getUTCMonth()];
  let year = date.getUTCFullYear();
  return { day: day, months: month, year: year };
}
