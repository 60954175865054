import { Flex } from "@chakra-ui/react";
import DoctorHero from "features/doctors/components/DoctorHero";
import DoctorPatientsTable from "features/doctors/components/DoctorPatientsTable";
import LeaveCalendarSection from "features/staffCommons/components/LeaveCalendarSection";
import MeetingLogWidget from "features/staffCommons/components/MeetingLogWidget";
import NotificationWiget from "features/superadmin/components/NotificationWidget";
import WorkingCalendar from "features/superadmin/components/WorkingCalendar/WorkingCalendar";

const DoctorDashboard = () => {
  return (
    <Flex w="full" direction="column">
      <DoctorHero />
      <Flex direction="column" gap="2rem">
        <WorkingCalendar />

        {/*  <LeaveCalendarSection /> */}

        <MeetingLogWidget />

        <NotificationWiget />
      </Flex>
      <DoctorPatientsTable />
    </Flex>
  );
};

export default DoctorDashboard;
