import { Box, Flex, Heading, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";

import avatar_1 from "assets/icons/avatar_1.svg";
import avatar_2 from "assets/icons/avatar_2.svg";
import avatar_3 from "assets/icons/avatar_3.svg";
import sven_pic from "assets/images/Sven_Ternov.jpg";

import webinar_up from "assets/icons/webinar_up.svg";

const WebinarSpeakers = () => {
  return (
    <VStack
      w="full"
      px={{ base: "20px", sm: "20px", lg: "180px" }}
      gap="60px"
      py={{ base: "10px", sm: "40px" }}
    >
      <Flex w="full" align="flex-start">
        <Heading
          w="full"
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "32px", sm: "56px" }}
          fontWeight={500}
          fontFamily="secondary"
        >
          Talare
        </Heading>
      </Flex>

      <Flex
        gap={{ base: "52px", sm: "40px" }}
        direction={{ base: "column", sm: "column", md: "row" }}
      >
        <Flex
          w="full"
          bg="rgba(238, 245, 247, 1)"
          borderRadius="20px"
          direction="column"
          py="42px"
          px="32px"
          position="relative"
          gap={{ base: "42px", sm: "56px" }}
        >
          <Flex w="full" direction="column" align="center" justify="center">
            <Box ml={{ base: "5rem", sm: "10rem" }}>
              <Heading
                color="rgba(0, 38, 55, 1)"
                fontSize={{ base: "24px", sm: "32px" }}
                fontWeight={600}
              >
                Behroz
              </Heading>
              <Text
                color="rgba(0, 38, 55, 1)"
                fontSize={{ base: "24px", sm: "32px" }}
                fontWeight={400}
              >
                Dehdari
              </Text>
            </Box>
            <Box
              position="absolute"
              left={{ base: -3, sm: -5 }}
              top={{ base: -5, sm: -10 }}
            >
              <Image
                src={avatar_1}
                h={{ base: "10rem", sm: "12rem", lg: "13rem" }}
                w={{ base: "10rem", sm: "12rem", lg: "13rem" }}
                objectFit={"cover"}
              />
            </Box>
          </Flex>

          <Box>
            <Text
              color="rgba(0, 38, 55, 1)"
              fontSize={{ base: "16px", sm: "20px" }}
              fontWeight={400}
              textAlign={{ base: "justify", sm: "start" }}
              fontFamily="main"
            >
              Behroz Dehdari är psykiatriker och överläkare som är specialiserad
              på nedtrappning av antidepressiva.
            </Text>
          </Box>
        </Flex>

        <Flex
          w="full"
          bg="rgba(238, 245, 247, 1)"
          borderRadius="20px"
          direction="column"
          py="42px"
          px="32px"
          position="relative"
          gap={{ base: "42px", sm: "56px" }}
        >
          <Flex w="full" direction="column" align="center" justify="center">
            <Box ml={{ base: "5rem", sm: "10rem" }}>
              <Heading
                color="rgba(0, 38, 55, 1)"
                fontSize={{ base: "24px", sm: "32px" }}
                fontWeight={600}
              >
                Sven
              </Heading>
              <Text
                color="rgba(0, 38, 55, 1)"
                fontSize={{ base: "24px", sm: "32px" }}
                fontWeight={400}
              >
                Ternov
              </Text>
            </Box>
            <Box
              position="absolute"
              left={{ base: -3, sm: -5 }}
              top={{ base: -5, sm: -10 }}
              width={"fit-content"}
            >
              <Image
                src={sven_pic}
                h={{ base: "10rem", sm: "12rem", lg: "13rem" }}
                w={{ base: "10rem", sm: "12rem", lg: "13rem" }}
                objectFit={"cover"}
                borderRadius={"50%"}
              />
            </Box>
          </Flex>

          <Box>
            <Text
              color="rgba(0, 38, 55, 1)"
              fontSize={{ base: "16px", sm: "20px" }}
              fontWeight={400}
              textAlign={{ base: "justify", sm: "start" }}
              fontFamily="main"
            >
              Sven Ternov är allmänläkare med mångårig erfarenhet av att hjälpa
              patienter sluta med antidepressiva läkemedel.
            </Text>
          </Box>
        </Flex>

        <Flex
          w="full"
          bg="rgba(238, 245, 247, 1)"
          borderRadius="20px"
          direction="column"
          py="42px"
          px="32px"
          position="relative"
          gap={{ base: "42px", sm: "56px" }}
        >
          <Flex w="full" direction="column" align="center" justify="center">
            <Box ml={{ base: "5rem", sm: "10rem" }}>
              <Heading
                color="rgba(0, 38, 55, 1)"
                fontSize={{ base: "24px", sm: "32px" }}
                fontWeight={600}
              >
                Mikael
              </Heading>
              <Text
                color="rgba(0, 38, 55, 1)"
                fontSize={{ base: "24px", sm: "32px" }}
                fontWeight={400}
              >
                Bäckström
              </Text>
            </Box>
            <Box
              position="absolute"
              left={{ base: -3, sm: -5 }}
              top={{ base: -5, sm: -10 }}
            >
              <Image
                src={avatar_3}
                h={{ base: "10rem", sm: "12rem", lg: "13rem" }}
                w={{ base: "10rem", sm: "12rem", lg: "13rem" }}
                objectFit={"cover"}
              />
            </Box>
          </Flex>

          <Box>
            <Text
              color="rgba(0, 38, 55, 1)"
              fontSize={{ base: "16px", sm: "20px" }}
              fontWeight={400}
              textAlign={{ base: "justify", sm: "start" }}
              fontFamily="main"
            >
              Diskussionen modereras av Mikael Bäckström som står bakom Lamina
              Clinic, ett företag specialiserat på nedtrappning och utsättning
              av antidepressiva läkemedel.
            </Text>
          </Box>
        </Flex>
      </Flex>

      <Flex w="full" align="center" justify="center">
        <Image
          src={webinar_up}
          alt="arrow icon"
          onClick={() =>
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
          cursor="pointer"
        />
      </Flex>
    </VStack>
  );
};

export default WebinarSpeakers;
