import { Flex, Heading, Spacer, Text, Button, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import left_arrow from "assets/icons/left_arrow.svg";
import right_arrow from "assets/icons/right_arrow.svg";
import ScheduleItem from "../dashboardScheduleItem";
import { useTranslation } from "react-i18next";
import { useFetchUserSchedule } from "features/dashboard/api/client";

const UserSchedule: React.FC = () => {
  const { data: scheduleData } = useFetchUserSchedule();

  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const handlePreviousClick = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const spliceIntoChunks = (arr: any[], chunkSize: number) => {
    let res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  const [pagedScheduleData, setPagedScheduleData] = useState<any[]>([]);

  useEffect(() => {
    if (scheduleData?.treatments) {
      const treatmentsWithStrip = scheduleData.treatments.map(
        (treatment, index) => {
          return {
            ...treatment,
            strip: Math.floor(index / 28) + 1,
          };
        }
      );

      setPagedScheduleData(spliceIntoChunks(treatmentsWithStrip, 7));
    }
  }, [scheduleData]);

  useEffect(() => {
    console.log("🚀 ~ file: index.tsx:33 ~ Data:", pagedScheduleData);
  }, [pagedScheduleData]);

  return (
    <>
      <Flex
        bg="white"
        p="32px 40px 32px 40px"
        direction="column"
        borderRadius="12px"
        gap={3}
        w="100%"
      >
        <Flex
          align={{ base: "flex-start", md: "center" }}
          direction={{ base: "column", sm: "row" }}
          w="100%"
          justify="space-between"
        >
          <Heading color="blueish.500" fontSize="20px" fontWeight={500}>
            {t("YOUR SCHEDULE")}
          </Heading>

          <Flex
            gap="40px"
            w={{ base: "100%", sm: "auto" }}
            justifyContent={{ base: "space-between", sm: "flex-end" }}
          >
            {pagedScheduleData.length > 0 && (
              <Flex gap="22px">
                <Button
                  variant={"outline"}
                  style={{ padding: "0px" }}
                  border={"none"}
                  onClick={handlePreviousClick}
                  disabled={currentPage === 1}
                >
                  <Image src={left_arrow} alt={"arrow left"} />
                </Button>

                <Text color="blackish.200" fontSize="16px" fontWeight={500}>
                  {t("Week")} {currentPage} of {pagedScheduleData.length}
                </Text>

                <Button
                  variant={"outline"}
                  style={{ padding: "0px" }}
                  border={"none"}
                  onClick={handleNextClick}
                  disabled={currentPage === pagedScheduleData.length}
                >
                  <Image src={right_arrow} alt={"arrow right"} />
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>

        {pagedScheduleData.length === 0 ? (
          <Flex justify="center" alignItems="center" mt="18rem">
            <Text color="gray.500" fontWeight={400} opacity={0.6}>
              {t("Treatment is not available until the start of the course")}
            </Text>
          </Flex>
        ) : (
          <>
            {pagedScheduleData[currentPage - 1]?.map(
              (item: any, index: any) => (
                <ScheduleItem
                  totalDose={item.dosage}
                  key={index}
                  id={item.id}
                  day={item.day_of_tapering}
                  dayOfWeek={new Date(item.date).toLocaleDateString(
                    t("locale") ?? "en-GB",
                    {
                      weekday: "long",
                    }
                  )}
                  pillsDosage={item.tablets}
                  strip={item.strip}
                />
              )
            )}
          </>
        )}
      </Flex>
    </>
  );
};

export default UserSchedule;
