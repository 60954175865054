import {
  Button,
  Flex,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Show,
  Text,
} from "@chakra-ui/react";
import { FunctionComponent, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PersonIcon from "assets/icons/Person.svg";
import taper from "assets/icons/taper.svg";

import faq_yellow from "assets/icons/faq_yellow.svg";
import faq from "assets/icons/faq.svg";
import logo from "assets/images/logo.svg";
import {
  invokeBasicInfoModal,
  triggerAnnouncementModal,
} from "features/genericModal";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import { profile } from "console";

import { HashLink } from "react-router-hash-link";

interface NavbarProps {
  superminimalistic?: true;
}

const Navbar: FunctionComponent<NavbarProps> = ({ superminimalistic }) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { data: profileData } = useFetchProfileInfo();

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const minimalistic = location.pathname === "/prescription";

  const handleModalOpen = () => {
    if (location.pathname === "/prescription") {
      invokeBasicInfoModal(
        `${t(
          "If you switch the language, unfortunately, the entire prescription process will not be saved. Therefore, the prescription needs to be completed in one language."
        )}`,
        `${t("Sorry")}`
      );
    }
  };

  const handleNavigateToDashboard = () => {
    if (profileData && profileData.status === "prescription_completed") {
      history.push("/dashboard");
    } else if (profileData && profileData.status === "prescription_reject") {
      history.push("/doctor-rejected");
    } else {
      history.push("/prescription");
    }
  };

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"space-between"}
      width={{
        base: "95vw",
        sm: "min(1280px, 95vw)",
        lg: "min(1700px, 95vw)",
      }}
      mb={"2rem"}
    >
      <Image
        src={logo}
        alt="Lamina Clinic logo"
        w={{
          base: "160px",
          sm: "162px",
          md: "192px",
          lg: "256px",
        }}
        cursor={"pointer"}
        onClick={() => history.push("/")}
      />
      <HStack gap={{ base: "0em", sm: "1em" }}>
        {!superminimalistic && (
          <>
            {/*  <Flex
              align="center"
              gap="11px"
              cursor="pointer"
              onClick={() => history.push("/taper")}
            >
              <Image src={taper} alt={"taper"} mb="3px" />
              <Text
                color="rgba(255, 255, 255, 1)"
                fontSize="18px"
                fontWeight={700}
              >
                {t("How the Taper Works")}
              </Text>
            </Flex> */}
            <Flex
              align="center"
              gap="9px"
              cursor={"pointer !important"}
              onClick={() => history.push("/faq")}
              zIndex={1}
            >
              {history.location.pathname === "/faq" ? (
                <Image src={faq_yellow} mb="5px" />
              ) : (
                <Image src={faq} mb="5px" />
              )}
              <Text
                fontSize="18px"
                fontWeight={700}
                cursor="pointer"
                color={
                  history.location.pathname === "/faq"
                    ? "rgba(238, 232, 169, 1)"
                    : "white"
                }
              >
                Vanliga frågor
              </Text>
            </Flex>
          </>
        )}
        {/*TODO: uncomment this */}
        {false && (
          <Popover
            arrowShadowColor="transparent"
            returnFocusOnClose={true}
            initialFocusRef={ref}
            closeOnBlur={true}
          >
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button
                    variant="ghost"
                    color={"white"}
                    onClick={handleModalOpen}
                    ref={ref}
                  >
                    {i18n.language.toUpperCase()}
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  zIndex={4}
                  bg={"#589AAF"}
                  width={"80px"}
                  border={"none"}
                >
                  <PopoverArrow
                    backgroundColor={"#589AAF"}
                    border="none !important"
                  />
                  <PopoverBody
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <Button
                      onClick={() => handleLanguageChange("en")}
                      variant="ghost"
                      color={"white"}
                    >
                      EN
                    </Button>
                    <Button
                      onClick={() => handleLanguageChange("sv")}
                      variant="ghost"
                      color={"white"}
                    >
                      SV
                    </Button>
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        )}

        {!minimalistic && !superminimalistic && (
          <>
            {/* <Button
              variant={"transparent"}
              onClick={() => history.push("/login")}
              leftIcon={<Image src={PersonIcon} />}
            >
              {t("Log in")}
            </Button> */}
            <HashLink to="/#donwloadApp">
              <Button variant={"filled"}>{t("navB1")}</Button>
            </HashLink>
          </>
        )}

        {/* Hide!!! */}
        {/*  <Flex align="center" gap="9px">
          <Image src={PersonIcon} />
          <Text fontSize="18px" fontWeight={700}>
            Sign Up
          </Text>
        </Flex> */}
      </HStack>
    </Flex>
  );
};

export default Navbar;
