import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Flex,
  Text,
  Box,
  Divider,
} from "@chakra-ui/react";
import { useFetchUserTriageData } from "features/admin/api/client";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface TriageModalProps {
  isOpen: boolean;
  onClose(): void;
  userId?: string;
}

const TriageModal: FunctionComponent<TriageModalProps> = ({
  isOpen,
  onClose,
  userId,
}) => {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const trueId = userId ?? id ?? "";
  const { data: typeFormData } = useFetchUserTriageData(trueId);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"3xl"} isCentered>
      <ModalOverlay />
      <ModalContent maxH="80vh" overflowY="auto" position="relative">
        <ModalHeader>{t("Patient's answers")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack color={"blueish.900"} alignItems={"stretch"} spacing={2}>
            {typeFormData?.answers.map((e) => (
              <Box key={e.answer} w="100%">
                <Flex justifyContent={"space-between"} alignItems="center">
                  <Text color={"inherit"} fontSize={"inherit"} maxW={"50%"}>
                    {e.question}
                  </Text>
                  <Text
                    fontWeight={600}
                    color={"inherit"}
                    fontSize={"inherit"}
                    textAlign={"end"}
                    maxW="50%"
                    overflowY="auto"
                  >
                    {!(
                      !isNaN(Date.parse(e.answer)) &&
                      typeof e.answer === "string"
                    )
                      ? t(e.answer)
                      : new Date(e.answer).toLocaleString(
                          t("locale") ?? "en-GB",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                  </Text>
                </Flex>
                <Divider />
              </Box>
            ))}
          </VStack>
        </ModalBody>

        <ModalFooter position="sticky" bottom={0} zIndex={1} bg={"white"}>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Stäng
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TriageModal;
