import { Center, Circle, Flex, Text } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

interface FilterMoodProps {
  moodFilter: string[];

  onMoodFilterChange: (value: string[]) => void;
}

const filterOptions = [
  { id: 1, color: "#944366" },
  { id: 2, color: "#8B5687" },
  { id: 3, color: "#796A9F" },
  { id: 4, color: "#647CAD" },
  { id: 5, color: "#568CB2" },
  { id: 6, color: "#589AAF" },
  { id: 7, color: "#43B3BA" },
];

const FilterMood: React.FC<FilterMoodProps> = ({
  moodFilter,
  onMoodFilterChange,
}) => {
  const { t } = useTranslation();

  const activeMoodRef = useRef<string[]>(moodFilter);

  useEffect(() => {
    activeMoodRef.current = moodFilter;
  }, [moodFilter]);

  const handleMoodClick = (moodId: string) => {
    if (moodId == null) {
      activeMoodRef.current = [];
      onMoodFilterChange([]);

      return;
    }

    const index = activeMoodRef.current.indexOf(moodId);
    if (index > -1) {
      const newMoods = activeMoodRef.current.slice();
      newMoods.splice(index, 1);
      activeMoodRef.current = newMoods;
      onMoodFilterChange(newMoods);
    } else {
      const newMoods = [...activeMoodRef.current, moodId];
      activeMoodRef.current = newMoods;
      onMoodFilterChange(newMoods);
    }
  };

  return (
    <Flex direction="column" gap="12px" w="auto">
      <Text
        fontSize="14px"
        fontWeight={600}
        color="rgba(255, 255, 255, 1)"
        lineHeight="20px"
      >
        {t("MOOD ( 1-TERRIBLE, 7-GOOD )")}
      </Text>
      <Flex gap={0.5} cursor="pointer" w="100%">
        {filterOptions.map((option) => (
          <Flex
            key={option.id}
            bg={
              activeMoodRef.current.includes(option.id.toString())
                ? "rgba(255, 255, 255, 1)"
                : "rgba(255, 255, 255, 0.5)"
            }
            p="10px"
            borderRadius="8px"
            onClick={() => handleMoodClick(option.id.toString())}
          >
            <Circle size="32px" bg={option.color}>
              <Center
                color="white"
                fontSize="1rem"
                pt={"0.125rem"}
                fontWeight={700}
              >
                {option.id}
              </Center>
            </Circle>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default FilterMood;
