import SvgIconConstituentValues from './types/svgPropsType';

const PillIcon = (props: SvgIconConstituentValues) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M2.92554 2.92564C-0.975181 6.82652 -0.975181 13.1736 2.92554 17.0744C6.82627 20.9751 13.1733 20.9754 17.0744 17.0744C20.9752 13.1735 20.9752 6.82644 17.0744 2.92564C13.1734 -0.975252 6.82643 -0.975171 2.92554 2.92564ZM4.9697 15.0303C2.19603 12.2565 2.19603 7.74346 4.9697 4.96979C7.74336 2.1962 12.2564 2.19612 15.0302 4.96979L4.9697 15.0303Z"
      fill="#96BFCC"
    />
  </svg>
);

export default PillIcon;
