import { create } from "zustand";
import { CurrentOrderState } from "./types";

export const useOrderStore = create<CurrentOrderState>((set) => ({
  orderNumber: "",
  currentStep: 0,
  currentStepState: "completed",
  setOrderNumber: (orderNumber: string) =>
    set((prevState) => ({ ...prevState, orderNumber })),
  setCurrentStep: (currentStep: number) =>
    set((prevState) => ({ ...prevState, currentStep })),
  setCurrentStepState: (currentStepState: string) =>
    set((prevState) => ({ ...prevState, currentStepState })),
}));
