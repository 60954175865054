import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useGoogleAnalytics from "./useGoogleAnalytics";

const usePageTracking = () => {
  const location = useLocation();
  const { pageView } = useGoogleAnalytics();

  useEffect(() => {
    if (location) {
      pageView(location.pathname + location.search);
    }
  }, [location]);
};

export default usePageTracking;
