import { Button } from "@chakra-ui/react";
import {
  invokeBasicInfoModal,
  invokeConfirmationModal,
} from "features/genericModal";
import { useDeletePersonnelById } from "features/superadmin/api/client";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface DeletePersonnelButtonProps {
  id: string;
}

const DeletePersonnelButton: FunctionComponent<DeletePersonnelButtonProps> = ({
  id,
}) => {
  const { t } = useTranslation();

  const { trigger: deleteRequest, isMutating } = useDeletePersonnelById(id);

  const handleDeletePersonnel = () => {
    invokeConfirmationModal(
      "Are you sure you want to delete this user?",
      () => {
        deleteRequest(undefined, {
          onSuccess: () => {
            invokeBasicInfoModal("User successfully deleted");
          },
        });
      }
    );
  };
  return (
    <Button
      bg={"red.300"}
      color={"white"}
      px={"1rem"}
      minWidth={"none"}
      fontSize={"1rem"}
      fontWeight={"800"}
      onClick={handleDeletePersonnel}
      isLoading={isMutating}
    >
      {t("DELETE")}
    </Button>
  );
};

export default DeletePersonnelButton;
