import { defineStyleConfig } from "@chakra-ui/react";

export const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: "base",
    lineHeight: "tall",
    fontSize: ["10px", "sm", "sm"],
  },

  variants: {
    filled: {
      border: "1px solid black",
      borderColor: "yellowish.400",
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "sm" },
      bg: "yellowish.400",
      color: "#002637",
      px: { base: "1em", sm: "1.5em", md: "1.5em" },
      py: "0.75em",
      minWidth: { base: "none", sm: "12em", md: "210px" },
    },
    filledBlue: {
      color: "white",
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "sm" },
      bg: "blueish.500",
      px: { base: "1em", sm: "1.5em", md: "2em" },
      py: "0.75em",
      minWidth: { base: "none", sm: "12em", md: "210px" },
      _loading: {
        _hover: {
          color: "black",
        },
      },
    },
    outline: {
      boxSizing: "border-box !important",
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "sm" },
      bg: "rgba(255, 255, 255, 0.1)",
      color: "white",
      border: "1px solid white",
      px: { base: "1em", sm: "1.5em", md: "2em" },
      py: "0.75em",
    },
    dark: {
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "sm" },
      bg: "blueish.900",
      color: "white",
      px: { base: "1em", sm: "1.5em", md: "1.5em" },
      py: "0.75em",
      minWidth: { base: "none", sm: "12em", md: "210px" },
    },
    transparent: {
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "sm" },
      bg: "transparent",
      color: "white",
      px: { base: "1em", sm: "1.5em", md: "1.5em" },
      py: "0.75em",
    },
    //! add button design
    whited: {
      boxSizing: "border-box !important",
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "sm" },
      bg: "rgba(255, 255, 255, 0.1)",
      color: "#589AAF",
      border: "1px solid #589AAF",
      px: { base: "1em", sm: "1.5em", md: "2em" },
      py: "0.75em",
    },
    //! add button with yellow border
    yellow: {
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "sm" },
      bg: "transparent",
      color: "#EEE8A9",
      border: "1px solid #EEE8A9",
      px: { base: "1em", sm: "1.5em", md: "1.5em" },
      py: "0.75em",
    },
    //! add dashboard button
    dashboard: {
      height: "32px",
      fontSize: { base: "10px", sm: "14px", md: "sm" },
      bg: "rgba(44, 132, 117, 0.1)",
      color: "#2C8475",
      px: { base: "0.5em", sm: "0.85em", md: "0.85em" },
      py: "0.75em",
    },
    //! add dashboard button
    archive: {
      height: "52px",
      fontSize: { base: "10px", sm: "14px", md: "sm" },
      bg: "transparent",
      color: "rgba(88, 154, 175, 0.5)",
      border: "1px solid rgba(88, 154, 175, 0.5)",
      px: { base: "0.5em", sm: "0.85em", md: "0.85em" },
      py: "0.75em",
    },
    processing: {
      height: "32px",
      fontSize: { base: "10px", sm: "14px", md: "sm" },
      bg: "rgba(243, 196, 119, 0.3)",
      color: "#F3C477",
      px: { base: "0.5em", sm: "0.85em", md: "0.85em" },
      py: "0.75em",
    },
    popup: {
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "sm" },
      bg: "transparent",
      color: "rgba(255, 255, 255, 1)",
      border: "1px solid rgba(255, 255, 255, 0.3)",
      px: { base: "1em", sm: "1.5em", md: "1.5em" },
      py: "0.75em",
    },
    adjust: {
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "sm" },
      bg: "transparent",
      color: "rgba(0, 38, 55, 1)",
      border: "1px solid rgba(0, 38, 55, 1)",
      px: { base: "1em", sm: "1.5em", md: "1.5em" },
      py: "0.75em",
    },
    plan: {
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "sm" },
      bg: "rgba(44, 132, 117, 1)",
      color: "rgba(255, 255, 255, 1)",
      px: { base: "1em", sm: "1.5em", md: "1.5em" },
      py: "0.75em",
    },
    filter: {
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "sm" },
      bg: "rgba(238, 232, 169, 1)",
      color: "rgba(0, 38, 55, 1)",
      border: "1px solid #EEE8A9",
      px: { base: "1em", sm: "1.5em", md: "1.5em" },
      py: "0.75em",
    },
    white: {
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "16px" },
      bg: "transparent",
      color: "rgba(0, 38, 55, 1)",
      border: "1px solid rgba(0, 38, 55, 1)",
      px: { base: "1em", sm: "1rem", md: "1rem" },
      py: "0.5rem",
    },
    black: {
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "16px" },
      bg: "rgba(0, 38, 55, 1)",
      color: "rgba(255, 255, 255, 1)",
      border: "1px solid rgba(0, 38, 55, 1)",
      px: { base: "1em", sm: "1rem", md: "1rem" },
      py: "0.5rem",
    },
    edit: {
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "16px" },
      bg: "transparent",
      color: "rgba(0, 38, 55, 1)",
      border: "1px solid rgba(0, 38, 55, 1)",
      px: { base: "1em", sm: "1rem", md: "1rem" },
      py: ".5rem",
    },
    faq_tabs: {
      height: "auto",
      fontSize: { base: "10px", sm: "14px", md: "1rem" },
      bg: "transparent",
      color: "rgba(0, 38, 55, 1)",
      border: "1px solid rgba(0, 38, 55, 1)",
      borderRadius: "50px",
      px: { base: "1em", sm: "1rem", md: "1.5rem" },
      py: ".875rem",
      transition: "all 0.3s ease-in-out",
      _hover: {
        bgColor: "rgba(88, 154, 175, 1)",
        color: "rgba(255, 255, 255, 1)",
        border: "none",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
      },
    },
  },

  defaultProps: {
    variant: "filled",
  },
});
