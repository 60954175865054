import { Button, Wrap } from "@chakra-ui/react";

import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { InputType } from "services/types/types";

interface UnassignedFilterProps extends InputType<string> {}

const UnassignedFilter: FunctionComponent<UnassignedFilterProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const options = [
    {
      value: "showAll",
      label: t("Show All"),
    },
    {
      value: "unassigned_doctor",
      label: t("Unassigned Doctor"),
    },
    {
      value: "unassigned_nurse",
      label: t("Unassigned Nurse"),
    },
  ];
  return (
    <Wrap>
      {options.map((option) => {
        return (
          <Button
            variant={"unstyled"}
            fontWeight={"700"}
            fontSize={"1rem"}
            borderRadius={"0.5rem"}
            border={"1px solid rgba(255, 255, 255, 0.50)"}
            color={value === option.value ? "blueish.900" : "white"}
            bg={value === option.value ? "white" : "transparent"}
            px={"1rem !important"}
            transition={"all 0.3s"}
            onClick={() => {
              onChange(option.value);
            }}
          >
            {option.label}
          </Button>
        );
      })}
    </Wrap>
  );
};

export default UnassignedFilter;
