import { Button } from "@chakra-ui/react";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";
import { useTranslation } from "react-i18next";

const DoctorPrescriptionButton = () => {
  const { t } = useTranslation();
  const { basicEvent } = useGoogleAnalytics();

  return (
    <Button
      as="a"
      href="https://app.muntra.com/"
      variant="outline"
      borderColor="rgb(88,154,175)"
      color="#589AAF"
      fontSize="1rem"
      py="2.5"
      minH={'3rem'}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        basicEvent("Contact", "Doctor clicked Start prescription");
      }}
      _hover={{
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        transform: "scale(1.02)",
        transition: "0.3s",
      }}
    >
      {t("Start prescription")}
    </Button>
  );
};

export default DoctorPrescriptionButton;
