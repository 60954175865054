import { Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
import { FunctionComponent } from "react";

import addRecord from "assets/icons/add_record.svg";
import AddRecordDrawer from "../AddRecordDrawer";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type AddRecordButtonProps = {};

const AddRecordButton: FunctionComponent<AddRecordButtonProps> = () => {
  const { t } = useTranslation();
  const { id: userID } = useParams<{ id: string }>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex
        align="center"
        justify="center"
        gap="1rem"
        cursor="pointer"
        onClick={onOpen}
      >
        <Text fontSize="1rem" fontWeight={700} color="rgba(88, 154, 175, 1)">
          {t("Create new record")}
        </Text>
        <Image
          src={addRecord}
          py=".75rem"
          px="1.5rem"
          bgColor="rgba(88, 154, 175, 1)"
          borderRadius=".5rem"
        />
      </Flex>

      <AddRecordDrawer isOpen={isOpen} onClose={onClose} userID={userID} />
    </>
  );
};

export default AddRecordButton;
