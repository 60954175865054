import { Box, VStack, Text, Divider, Flex, Collapse } from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronDown } from "react-icons/bs";

type JournalTriageProps = {
  allAnswers?: Record<string, string>;
};

const JournalTriage: FunctionComponent<JournalTriageProps> = ({
  allAnswers,
}) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <VStack w="full" align="flex-start">
      <Flex w="full" py="1" px="2" fontWeight={700} align="center" gap="3">
        <Box
          w="max-content"
          py="1"
          px="2"
          borderRadius=".5rem"
          fontWeight={700}
          backgroundColor="rgba(44, 132, 117, 1)"
          color="rgba(255, 255, 255, 1)"
          cursor="pointer"
          onClick={toggleShowAll}
        >
          Triage
        </Box>
        <Text fontSize="1rem" color="black">
          Anamnes och tidigare medicinering
        </Text>
      </Flex>

      <Collapse in={showAll} animateOpacity>
        {allAnswers &&
          Object.entries(allAnswers).map(([question, answer], index) => (
            <Box
              key={index}
              w="100%"
              py="1"
              fontWeight={700}
              color="rgba(0, 0, 0, 1)"
              bg="rgba(255, 255, 255, 1)"
              mt="1"
            >
              <Text
                fontWeight="normal"
                color="rgba(0, 38, 55, 1)"
                fontSize="1rem"
              >
                {question}:
              </Text>
              <Text color="rgba(0, 38, 55, 1)" fontSize="1.125rem">
                {answer}
              </Text>

              <Divider
                w="full"
                colorScheme="rgba(88, 154, 175, 0.2)"
                pb=".5rem"
              />
            </Box>
          ))}
      </Collapse>

      {allAnswers && Object.entries(allAnswers).length > 0 && (
        <Flex
          onClick={toggleShowAll}
          w="full"
          align="center"
          justify="flex-end"
          cursor="pointer"
          pb="1rem"
          borderBottom="1px solid rgba(88, 154, 175, 0.2)"
          fontWeight={700}
          fontSize="1rem"
          gap=".5rem"
          mt={"0rem !important"}
          bg={'white'}
          zIndex={100}
        >
          {showAll ? t("Show less") : t("More")}
          <BsChevronDown
            style={{
              transform: showAll ? "rotate(180deg)" : "",
              transition: "all 0.5s ease-out",
            }}
          />
        </Flex>
      )}
    </VStack>
  );
};

export default JournalTriage;
