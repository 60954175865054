import { Button, Flex } from "@chakra-ui/react";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useModalStore } from "services/state/modalStore";

interface ConfirmCTAProps {
  callback(): void;
  texts?: {
    confirmation?: string;
    cancelation?: string;
  };
}

const ConfirmCTA: FunctionComponent<ConfirmCTAProps> = ({
  callback,
  texts,
}) => {
  const { t } = useTranslation();
  const { onClose, isLoading } = useModalStore();

  return (
    <Flex>
      <Button onClick={onClose} variant={"transparent"} color={"#589AAF"}>
        {texts?.cancelation ?? t("Cancel")}
      </Button>
      <Button
        onClick={() => {
          callback();
        }}
        isLoading={isLoading}
        variant={"filledBlue"}
      >
        {texts?.confirmation ?? t("Confirm")}
      </Button>
    </Flex>
  );
};

export default ConfirmCTA;
