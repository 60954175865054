import { Box, Flex, HStack, Tab, TabPanel, Tabs, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import PillIcon from "ui/iconComponents/Pillicon";
import EditDosageComponent from "../EditDosageComponent";
import { BsCapsule } from "react-icons/bs";
import { PiWarningCircleFill } from "react-icons/pi";

interface ScheduleItemProps {
  id: string;
  day: number;
  dayOfWeek: string;
  pillsDosage: number[];
  strip?: number;
  isEditable?: boolean;
  totalDose: number;
  isFaded?: boolean;
  isCurrent?: boolean;
  isSkipped?: boolean;
  isWrongDose?: boolean;
  wrongDose?: number[];
}

const ScheduleItem: React.FC<ScheduleItemProps> = ({
  id,
  day,
  dayOfWeek,
  pillsDosage,
  strip,
  isEditable,
  totalDose,
  isFaded = false,
  isCurrent = false,
  isSkipped,
  wrongDose = [],
  isWrongDose,
}) => {
  const { t } = useTranslation();

  /* const dayInCurrentStrip = day % 28 === 0 ? 28 : day % 28;
  const stripNumber = Math.ceil(dayInCurrentStrip / 28);
  console.log(`Day: ${day}, Strip: ${stripNumber}`); */

  const occurrences = pillsDosage.reduce((acc, curr) => {
    return (
      acc.has(curr) ? acc.set(curr, acc.get(curr) + 1) : acc.set(curr, 1), acc
    );
  }, new Map());

  const tabletsCount = pillsDosage.length;

  return (
    <Box w={"100%"} borderRadius="8px" overflow={"hidden"}>
      <Flex
        w="100%"
        bg={isSkipped || isWrongDose ? "#DE24531A" : "rgba(88,154,175,0.1)"}
        py={{ base: ".5rem", sm: "1.125rem" }}
        px={{ base: ".5rem", sm: "1.5rem" }}
        borderRadius="8px"
        align="center"
        justify="space-between"
        direction={{ base: "column", sm: "row" }}
        gap={{ base: 3, sm: 0 }}
        opacity={isFaded ? 0.5 : 1}
        border={isCurrent ? "2px solid #589AAF" : ""}
      >
        <Flex justify="space-between" w={{ base: "100%", sm: "55%" }}>
          <Flex direction="column" alignItems={"flex-start"}>
            <Text
              fontSize="14px"
              color={"blueish.500"}
              textAlign={{ base: "center", sm: "start" }}
              fontWeight={700}
            >
              {t("Day")} {day}
            </Text>
            <Text
              fontSize="16px"
              color="blackish.200"
              fontWeight={500}
              textTransform={"capitalize"}
            >
              {dayOfWeek}
            </Text>
          </Flex>

          <Flex
            direction="column"
            w={{ base: "65%", sm: "60%" }}
            alignItems={"flex-start"}
            fontSize={{ base: "12px", sm: "14px" }}
          >
            <Text
              fontSize="14px"
              color={"blueish.500"}
              fontWeight={700}
              textAlign={{ base: "center", sm: "start" }}
            >
              {t("Total")} mg
            </Text>

            <Flex
              gap={"1.25em"}
              alignItems={"center"}
              direction={{ base: "column", sm: "row" }}
            >
              <Flex w="150px" align="center" justify="space-between">
                <EditDosageComponent
                  id={id}
                  dose={totalDose}
                  day={day}
                  isEditable={isEditable}
                />
                <Flex gap={"0.5rem"} alignItems={"center"}>
                  <BsCapsule color="rgba(88, 154, 175, 0.8)" size="20px" />
                  <Text
                    fontSize="16px"
                    color="blackish.200"
                    fontWeight={"bold"}
                  >
                    {tabletsCount} {tabletsCount === 1 ? "kapslar" : "kapslar"}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <HStack gap={"2rem"}>
          {Array.from(occurrences.keys()).map((dose, index) => {
            return (
              <Flex
                key={index}
                direction="column"
                alignItems={"flex-end"}
                gap={"0.25rem"}
              >
                <Flex>
                  {Array.from(Array(occurrences.get(dose))).map(
                    (pill, subIndex) => {
                      return (
                        <BsCapsule
                          key={subIndex}
                          color="rgba(88, 154, 175, 0.8)"
                          size="20px"
                        />
                      );
                    }
                  )}
                </Flex>

                <Text fontSize="14px" color="blueish.500" fontWeight={700}>
                  {dose} mg
                </Text>
              </Flex>
            );
          })}
        </HStack>
      </Flex>

      {(isSkipped || isWrongDose) && (
        <Flex
          w={"100%"}
          p="0.5rem"
          bg={"#DE245330"}
          alignItems={"center"}
          gap={"1.25rem"}
        >
          <Flex
            bg={"#DE2453"}
            color={"white"}
            borderRadius={"0.25rem"}
            px={"0.5rem"}
            alignItems={"center"}
            gap={"0.25rem"}
            py={"0.25rem"}
          >
            <PiWarningCircleFill />
            {isSkipped && (
              <Text fontSize={"0.875rem"} fontWeight={700} lineHeight={"1em"}>
                {t("Skipped dose")}
              </Text>
            )}

            {isWrongDose && (
              <Text fontSize={"0.875rem"} fontWeight={700} lineHeight={"1em"}>
                {t("Wrong dose")}
              </Text>
            )}
          </Flex>

          {isWrongDose && (
            <Text fontSize={"1rem"} color={"#DE2453"} fontWeight={"400"}>
              {t("Patient took the wrong dose")} -{" "}
              <b>
                {wrongDose.length > 0
                  ? wrongDose.map((e) => e + " mg").join(", ")
                  : t("unknown dose")}
              </b>
            </Text>
          )}

          {isSkipped && (
            <Text fontSize={"1rem"} color={"#DE2453"} fontWeight={"400"}>
              {t("Patient skipped a dose")}
            </Text>
          )}
        </Flex>
      )}
    </Box>
  );
};

export default ScheduleItem;
