import { Flex, Heading, Text, Image, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import left_arrow from "assets/icons/left_arrow.svg";

const CookiePolicy = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Box pb={"5vh"} w="full">
      <Flex
        w="full"
        bg="rgba(255, 255, 255, 1)"
        borderRadius="24px"
        p={{ base: "20px", sm: "60px" }}
        align="flex-start"
        direction="column"
        gap="10px"
      >
        <Flex
          align="center"
          gap="14px"
          onClick={() => history.push("/")}
          cursor="pointer"
        >
          <Image src={left_arrow} />
          <Text fontSize="18px" color="black" fontWeight={500}>
            {t("Back")}
          </Text>
        </Flex>
        <Flex w="100%" align="center" justify="center" mb="30px">
          <Heading fontSize="4xl" color="blueish.500">
            {t("Cookie Policy")}
          </Heading>
        </Flex>

        <Flex
          w="full"
          direction="column"
          gap="8px"
          px={{ base: "5px", sm: "20px" }}
          mt="20px"
        >
          <Text color="black" fontSize="18px" fontWeight={500}>
            {t(
              "The website uses cookies. This means that information can will be stored in users' browsers when they visit the website. The purpose of this is for the website to recognize a user's computer, keep statistics and in turn improve the user experience. A cookie is a small text file that makes it possible to identify one users' means of communication without storing personal data. The There are two different types of cookies, time-limited and session cookies. Time-limited cookies are saved for a longer period of time and can e.g. be used to show the user such content that was previously missed. Session cookies are deleted when the website/browser is closed. This one type of cookies can store information such as selected language and means that users don't have to choose this again every time they click further on the website. There are also third-party cookies on the website, i.e. cookies which are placed by external parties. Cookies are used on the website for the following purpose: We use cookies for, among other things, Google Analytics and Amplitude. We use these to get a picture of how our visitors use website and customize our marketing. A person who wishes to avoid cookies or wants to be warned before they are stored can change this himself in the settings of his device."
            )}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CookiePolicy;
