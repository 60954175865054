import { Heading, Text, Button, Flex, Box } from "@chakra-ui/react";

import { useHistory, useLocation } from "react-router-dom";

const Error404 = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Flex
      direction={"column"}
      align="center"
      justify={"center"}
      gap={5}
      minHeight={"calc(100vh - 90px)"}
      /* mt={{ base: '-2.5rem', sm: '-4rem' }} */
    >
      <Box>
        <Heading color={"white"} fontSize={{ base: "12rem", sm: "14rem" }}>
          404
        </Heading>
      </Box>
      <Text w={"100%"} textAlign={"center"}>
        We can’t find the page you’re looking for.
      </Text>
      <Button variant={"outline"} w={"191px"} onClick={() => history.push("/")}>
        Go Back
      </Button>
    </Flex>
  );
};

export default Error404;
