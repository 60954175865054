import { Box, Flex, Hide, VStack } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import DashboardNavbar from "features/dashboard/components/dashboardNavbar";
import DashboardSidebar from "features/dashboard/components/dashboardSidebar";
import SidebarMobile from "features/dashboard/components/dashboardSidebarMobile";
import AnimatedDashboardLayoutNavbar from "features/routesAnimation/components/AnimateDashboardLayoutNavbar";
import classes from "./styles.module.scss";

import { useLocation } from "react-router-dom";
import FaqNavbar from "features/faqInfo/components/faqDashboardNavbar";

interface DashboardLayoutProps {
  children?: React.ReactNode[] | React.ReactNode;
  fullWidth?: boolean;
}

const DashboardLayout: FunctionComponent<DashboardLayoutProps> = ({
  children,
  fullWidth,
}) => {
  const location = useLocation();
  return (
    <Flex
      alignItems="flex-start"
      justifyContent="flex-start"
      direction={{ base: "column", sm: "row" }}
      w={"100% !important"}
      p="2%"
      gap={"1rem"}
    >
      <Flex
        w={{ base: "100%", sm: "25%", md: "300px", lg: "300px" }}
        flexWrap={"wrap"}
      >
        <Box
          position={{ base: "sticky", sm: "fixed" }}
          w={{ base: "100%", sm: "auto" }}
          mb={{ base: "1rem", sm: "0rem" }}
          className={classes.scroll}
        >
          <AnimatedDashboardLayoutNavbar>
            <Hide below="sm">
              <DashboardSidebar />
            </Hide>
            <Hide above="sm">
              <SidebarMobile />
            </Hide>
          </AnimatedDashboardLayoutNavbar>
        </Box>
      </Flex>

      <VStack
        width="100%"
        align="center"
        maxWidth={{
          base: "100%",
          sm: "1250px",
          lg: "1680px",
        }}
        px={"3%"}
        overflowY={"auto"}
        flex={1}
      >
        <Flex w={"100%"} mb={"2rem"}>
          {location.pathname === "/dashboard/faq" ? (
            <FaqNavbar />
          ) : (
            <DashboardNavbar />
          )}
        </Flex>
        <VStack width={fullWidth ? "100%" : { base: "98%", sm: "100%" }}>
          {children}
        </VStack>
      </VStack>
    </Flex>
  );
};

export default DashboardLayout;
