import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import ChangeLogModal from "../ChangeLogModalContent";
import { useTranslation } from "react-i18next";

type ChangeLogProps = {
  id: string;
};

const ChangeLog: FunctionComponent<ChangeLogProps> = ({ id }) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        color={"white"}
        px={"1rem"}
        minWidth={"none"}
        fontSize={"1rem"}
        fontWeight={"800"}
        onClick={onOpen}
        variant="dark"
        textTransform="uppercase"
      >
        {t("View Log")}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="5xl" isCentered>
        <ModalOverlay />
        <ModalContent overflow="hidden">
          <ModalCloseButton />
          <ModalBody>
            <ChangeLogModal id={id} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChangeLog;
