import { forwardRef } from "react";

import {
  Img,
  VStack,
  Flex,
  Text,
  Box,
  Heading,
  Button,
  Image,
  Center,
  HStack,
  SimpleGrid,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import pic1 from "assets/images/pic1.png";
import pic2 from "assets/images/pic2.png";
import pic3 from "assets/images/pic3.png";
import pic4 from "assets/images/pic4.png";
import pic5 from "assets/images/pic5.png";
import pic6 from "assets/images/pic6.png";
import pic7 from "assets/images/pic7.png";
import meetingIcon from "assets/icons/meetingIcon.svg";

/* import line1 from 'assets/images/lines/servicesDesktop/W1.svg';
import line2 from 'assets/images/lines/servicesDesktop/W2.svg'; */

import yellowLine from "assets/images/yellowLine.png";
import { triggerAnnouncementModal } from "features/genericModal";
import useAnalyticsEventTracker from "features/analytics/hooks/useAnalyticsEventTracker";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";

import AnimatedText from "ui/components/AnimatedText";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { TextEffect } from "ui/components/TextEffect";

const HomeServices = (props: any, ref: any) => {
  const { t } = useTranslation();

  const { downloadEvent } = useGoogleAnalytics();

  const history = useHistory();

  return (
    <VStack
      bg={"rgba(255, 255, 255, 0.9)"}
      width={"100vw"}
      position={"relative"}
      left={"calc(-50vw + 50%)"}
      px={{ base: "3vw", sm: "12vw" }}
      paddingTop={{ base: "5%", sm: "7%" }}
      ref={ref}
    >
      <Heading fontSize={{ base: "lg", sm: "xl", lg: "xxl" }} mb={"2rem"}>
        <AnimatedText>{t("servicesHeader")}</AnimatedText>
      </Heading>

      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        gap={"40px"}
        px={{ base: "1rem", sm: "1.5%", lg: "1.5%" }}
        paddingBottom={"7.625rem"}
      >
        <Flex
          w="full"
          align="flex-start"
          direction="column"
          gap="20px"
          p={{ base: "13px 30px", lg: "20px 40px" }}
          bg="rgba(238, 245, 247, 1)"
          borderRadius="24px"
          position="relative"
          transition="transform 0.3s ease, box-shadow 0.3s ease"
          _hover={{
            transform: "translateY(-6px)",
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Text
            w="full"
            color="rgba(88, 154, 175, 0.2)"
            fontSize={{ base: "40px", lg: "xl" }}
            textAlign="right"
            fontWeight={"bold"}
            lineHeight={"1.2em"}
            position="absolute"
            left={-5}
            top={2}
          >
            01
          </Text>

          <Image
            src={pic6}
            alt="pic6"
            w={{
              base: "30%",
              sm: "80px",
              md: "80px",
              lg: "100px",
            }}
          />
          <Flex direction="column">
            <Heading
              fontSize={{ base: "14px", sm: "16px", lg: "20px" }}
              fontWeight={500}
              textTransform="uppercase"
            >
              {t("Download the app")}
            </Heading>
            <TextEffect
              fontSize={{ base: "16px", sm: "16px", lg: "20px" }}
              color="rgba(0, 38, 55, 1)"
              fontWeight={350}
              per="char"
              preset="fade"
            >
              {t(
                "Step one is to download Laminas app. Log in with bankID to get started."
              )}
            </TextEffect>

            <HashLink
              to="#donwloadApp"
              onClick={() => {
                downloadEvent("User clicked app download on a landing page");
              }}
            >
              <Button
                cursor="pointer"
                mt="15px"
                fontSize={"18px"}
                w={"12em"}
                variant={"dark"}
              >
                {t("Download the app")}
              </Button>
            </HashLink>
          </Flex>
        </Flex>

        <Flex
          w="full"
          align="flex-start"
          direction="column"
          gap="20px"
          p={{ base: "13px 30px", lg: "20px 40px" }}
          bg="rgba(238, 245, 247, 1)"
          borderRadius="24px"
          position="relative"
          transition="transform 0.3s ease, box-shadow 0.3s ease"
          _hover={{
            transform: "translateY(-6px)",
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Text
            w="full"
            color="rgba(88, 154, 175, 0.2)"
            fontSize={{ base: "40px", lg: "xl" }}
            textAlign="right"
            fontWeight={"bold"}
            lineHeight={"1.2em"}
            position="absolute"
            left={-5}
            top={2}
          >
            02
          </Text>

          <Image
            src={pic3}
            alt="pic3"
            w={{
              base: "30%",
              sm: "80px",
              md: "80px",
              lg: "95px",
            }}
          />
          <Flex direction="column">
            <Heading
              fontSize={{ base: "14px", sm: "16px", lg: "20px" }}
              fontWeight={500}
            >
              {t("FILL OUT FREE QUESTIONNAIRE")}
            </Heading>
            <TextEffect
              fontSize={{ base: "16px", sm: "16px", lg: "20px" }}
              color="rgba(0, 38, 55, 1)"
              fontWeight={350}
              per="char"
              preset="fade"
            >
              {t(
                "Easy and quick questions about you and your current medication."
              )}
            </TextEffect>
          </Flex>
          {/*  <Button
            fontSize={"18px"}
            w={"12em"}
            onClick={() => history.push("/login")}
          >
            {t("heroButtonMobile1")}
          </Button> */}
        </Flex>

        <Flex
          w="full"
          align="flex-start"
          direction="column"
          gap="20px"
          p={{ base: "30px", lg: "40px" }}
          bg="rgba(238, 245, 247, 1)"
          borderRadius="24px"
          position="relative"
          transition="transform 0.3s ease, box-shadow 0.3s ease"
          _hover={{
            transform: "translateY(-6px)",
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Text
            w="full"
            color="rgba(88, 154, 175, 0.2)"
            fontSize={{ base: "40px", lg: "xl" }}
            textAlign="right"
            fontWeight={"bold"}
            lineHeight={"1.2em"}
            position="absolute"
            left={-5}
            top={2}
          >
            03
          </Text>

          <Image
            src={meetingIcon}
            alt="pic4"
            w={{
              base: "30%",
              sm: "80px",
              md: "80px",
              lg: "100px",
            }}
          />
          <Flex direction="column">
            <Heading
              fontSize={{ base: "14px", sm: "16px", lg: "20px" }}
              fontWeight={500}
              textTransform="uppercase"
            >
              {/* {t("BOOK A FREE CONSULTATION")} */}
              Träffa ditt vårdteam
            </Heading>
            <TextEffect
              fontSize={{ base: "16px", sm: "16px", lg: "20px" }}
              color="rgba(0, 38, 55, 1)"
              fontWeight={350}
              per="char"
              preset="fade"
            >
              {t(
                "Meet the nurse and doctor and find out if we are right for you."
              )}
            </TextEffect>
          </Flex>
        </Flex>

        <Flex
          w="full"
          align="flex-start"
          direction="column"
          gap="20px"
          p={{ base: "30px", lg: "40px" }}
          bg="rgba(238, 245, 247, 1)"
          borderRadius="24px"
          position="relative"
          transition="transform 0.3s ease, box-shadow 0.3s ease"
          _hover={{
            transform: "translateY(-6px)",
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Text
            w="full"
            color="rgba(88, 154, 175, 0.2)"
            fontSize={{ base: "40px", lg: "xl" }}
            textAlign="right"
            fontWeight={"bold"}
            lineHeight={"1.2em"}
            position="absolute"
            left={-5}
            top={2}
          >
            04
          </Text>

          <Image
            src={pic4}
            alt="pic4"
            w={{
              base: "30%",
              sm: "80px",
              md: "80px",
              lg: "100px",
            }}
            transform="rotate(180deg)"
          />
          <Flex direction="column">
            <Heading
              fontSize={{ base: "14px", sm: "16px", lg: "20px" }}
              fontWeight={500}
            >
              {t("GET YOUR PERSONALIZED TAPERING CARE PLAN")}
            </Heading>

            <TextEffect
              fontSize={{ base: "16px", sm: "16px", lg: "20px" }}
              color="rgba(0, 38, 55, 1)"
              fontWeight={350}
              per="char"
              preset="fade"
            >
              {t(
                "If everything feels good, you and the doctor will set up your personal tapering plan."
              )}
            </TextEffect>
          </Flex>
        </Flex>

        <Flex
          w="full"
          align="flex-start"
          direction="column"
          gap="20px"
          p={{ base: "13px 30px", lg: "20px 40px" }}
          bg="rgba(238, 245, 247, 1)"
          borderRadius="24px"
          position="relative"
          transition="transform 0.3s ease, box-shadow 0.3s ease"
          _hover={{
            transform: "translateY(-6px)",
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Text
            w="full"
            color="rgba(88, 154, 175, 0.2)"
            fontSize={{ base: "40px", lg: "xl" }}
            textAlign="right"
            fontWeight={"bold"}
            lineHeight={"1.2em"}
            position="absolute"
            left={-5}
            top={2}
          >
            05
          </Text>

          <Image
            src={pic5}
            alt="pic5"
            w={{
              base: "30%",
              sm: "80px",
              md: "80px",
              lg: "100px",
            }}
          />
          <Flex direction="column">
            <Heading
              fontSize={{ base: "14px", sm: "16px", lg: "20px" }}
              fontWeight={500}
            >
              {t("A PHARMACY SHIPS THE MEDICATION HOME TO YOU")}
            </Heading>
            <TextEffect
              fontSize={{ base: "16px", sm: "16px", lg: "20px" }}
              color="rgba(0, 38, 55, 1)"
              fontWeight={350}
              per="char"
              preset="fade"
            >
              {t(
                "Once our doctors have approved your prescription and payment have been done you will receive your individualized medication within 7 days from a pharmacy."
              )}
            </TextEffect>
          </Flex>
        </Flex>

        <VStack
          align="flex-start"
          direction="column"
          gap="20px"
          p={{ base: "13px 30px", lg: "20px 40px" }}
          bg="rgba(238, 245, 247, 1)"
          borderRadius="24px"
          position="relative"
          transition="transform 0.3s ease, box-shadow 0.3s ease"
          _hover={{
            transform: "translateY(-6px)",
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Text
            w="full"
            color="rgba(88, 154, 175, 0.2)"
            fontSize={{ base: "40px", lg: "xl" }}
            textAlign="right"
            fontWeight={"bold"}
            lineHeight={"1.2em"}
            position="absolute"
            left={-5}
            top={2}
          >
            06
          </Text>

          <Flex direction="column" gap="1.5rem">
            <Image
              src={pic7}
              alt="pic7"
              w={{
                base: "30%",
                sm: "80px",
                md: "80px",
                lg: "100px",
              }}
            />
            <Flex direction="column">
              <Heading
                fontSize={{ base: "14px", sm: "16px", lg: "20px" }}
                fontWeight={500}
              >
                {t("MEDICATION RENEWED")}
              </Heading>
              <TextEffect
                fontSize={{ base: "16px", sm: "16px", lg: "20px" }}
                color="rgba(0, 38, 55, 1)"
                fontWeight={350}
                per="char"
                preset="fade"
              >
                {t(
                  "Log how you feel every day and have follow-up calls every month."
                )}
              </TextEffect>
            </Flex>
          </Flex>
        </VStack>
      </SimpleGrid>
    </VStack>
  );
};

export default forwardRef(HomeServices);
