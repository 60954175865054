import {
  Flex,
  Heading,
  VStack,
  Wrap,
  WrapItem,
  Text,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";

import { FunctionComponent } from "react";
import PillIcon from "ui/iconComponents/Pillicon";
import MoodItemComponent from "../MoodItemComponent";
import CalendarMessageBlack from "ui/iconComponents/CalendarMessageBlack";
import CalenderMoodNote from "../CalendarMoodNote";

interface CalendarDayItemProps {
  isPadding?: true;
  dayNumber: number;
  moodData: number[];
  dosage?: number;
  tolerableMoodComments: string[];
  intolerableMoodComments: string[];
}

const CalendarDayItem: FunctionComponent<CalendarDayItemProps> = ({
  isPadding,
  dayNumber,
  moodData,
  dosage,
  tolerableMoodComments,
  intolerableMoodComments,
}) => {
  return (
    <VStack
      w={{ base: "43px", sm: "93px", lg: "120px" }}
      borderTop={
        isPadding
          ? "2px solid rgba(88, 154, 175, 0.1)"
          : "2px solid rgba(88, 154, 175, 0.3)"
      }
      h={"8rem"}
      pt={"0.75rem"}
      alignItems={"flex-start"}
    >
      {!isPadding && (
        <>
          <Flex justifyContent={"space-between"} w={"auto"} align="center">
            <Heading
              fontSize={{ base: ".5rem", sm: "1rem", lg: "1.5rem" }}
              fontWeight={400}
            >
              {dayNumber}
            </Heading>

            <Box
              ml={{ base: "0rem", sm: "0.9rem", lg: "5rem" }}
              position="relative"
            >
              {(tolerableMoodComments.length > 0 ||
                intolerableMoodComments.length > 0) && (
                <Popover trigger="hover">
                  <PopoverTrigger>
                    <Box
                      cursor="pointer"
                      position="relative"
                      ml={{ base: "0rem", lg: "-3rem" }}
                    >
                      <CalendarMessageBlack />
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent
                    position="absolute"
                    zIndex="popover"
                    top={-9}
                    left={-220}
                    maxW="230px"
                    borderColor={"rgba(238, 232, 169, 1)"}
                  >
                    <PopoverBody>
                      <CalenderMoodNote
                        tolerableMoodComments={tolerableMoodComments}
                        intolerableMoodComments={intolerableMoodComments}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </Box>

            {dosage && (
              <Flex
                alignItems={"center"}
                gap={"0.5rem"}
                ml={{ base: "0rem", lg: "-2rem" }}
              >
                <Box transform={"scale(0.65)"} transformOrigin={"right"}>
                  <PillIcon />
                </Box>

                <Text
                  fontSize={{ base: "13px", lg: "14px" }}
                  color={"#589AAF"}
                  fontWeight={700}
                  lineHeight={"1rem"}
                >
                  {dosage.toFixed(2)} mg
                </Text>
              </Flex>
            )}
          </Flex>

          <Wrap flexWrap={"wrap"} spacing={"0.25rem"}>
            {moodData.map((mood) => {
              return (
                <WrapItem>
                  <MoodItemComponent mood={mood} />
                </WrapItem>
              );
            })}
          </Wrap>
        </>
      )}
    </VStack>
  );
};

export default CalendarDayItem;
