import {
  Box,
  Button,
  Center,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useDisclosure,
  VStack,
  Image,
} from "@chakra-ui/react";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import { invokeBasicInfoModal } from "features/genericModal";
import SignPopup from "features/signPDF/components/SignPopup";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsPlusLg } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";

interface AddSignatureButtonProps {}

const AddSignatureButton: FunctionComponent<AddSignatureButtonProps> = () => {
  const { data: adminData } = useFetchProfileInfo();
  const { t } = useTranslation();
  const {
    isOpen: isSignModalOpen,
    onOpen: onSignModalOpen,
    onClose: onSignModalClose,
  } = useDisclosure();

  const {
    isOpen: isSavedSignaturesPopoverOpen,
    onClose: onSavedSignaturesPopoverClose,
    onOpen: onSavedSignaturesPopoverOpen,
  } = useDisclosure();

  const setSignatureUrl = (e: any) => {
    invokeBasicInfoModal("Signature successfully updated");
  };

  const existingSignatureUrl: string = adminData?.signature ?? "";
  return (
    <>
      <Popover isOpen={isSavedSignaturesPopoverOpen} placement={"top"}>
        <PopoverTrigger>
          <Button
            variant={"transparent"}
            fontSize={"1rem"}
            color={"blueish.900"}
            bg={"rgba(86, 140, 178, 0.20)"}
            px={"1rem"}
            ml={"1rem"}
            opacity={0.5}
            onClick={onSavedSignaturesPopoverOpen}
          >
            {t("Your signature")}
          </Button>
        </PopoverTrigger>
        <PopoverContent maxW={"14rem"}>
          <PopoverArrow />
          <PopoverCloseButton onClick={onSavedSignaturesPopoverClose} />
          <PopoverBody>
            <VStack maxW={"12rem"}>
              <Image
                src={existingSignatureUrl}
                cursor={"pointer"}
                transition={"all 0.3s ease-in"}
                _hover={{
                  borderRadius: "0.5rem",
                  bg: "#589AAF15",
                }}
                onClick={() => {
                  onSavedSignaturesPopoverClose();
                }}
              />
              <Button
                variant={"ghost"}
                w={"100%"}
                fontWeight={"400"}
                fontSize={"stnd"}
                _hover={{
                  bg: "#589AAF15",
                }}
                leftIcon={<BsPlusLg />}
                onClick={() => {
                  onSavedSignaturesPopoverClose();
                  onSignModalOpen();
                }}
              >
                <Box pt={"0.25rem"}>{t("Add new")}</Box>
              </Button>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <SignPopup
        isOpen={isSignModalOpen}
        onClose={onSignModalClose}
        onChangeSignUrl={setSignatureUrl}
      />
    </>
  );
};

export default AddSignatureButton;
