import {
  Center,
  Heading,
  Image,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Box,
  Flex,
  FormErrorMessage,
} from "@chakra-ui/react";
import jwt_decode from "jwt-decode";

import { useAdminLogin } from "features/admin/api/client";
import { Link, useHistory } from "react-router-dom";

import { useTokenStore } from "services/state/tokenStore";
import { useAdminStore } from "services/state/adminStore";

import logo_group from "assets/icons/logo_group.svg";
import { useState } from "react";

import { useFormik } from "formik";
import PasswordInput from "ui/components/PasswordInput";
import { AdminLoginSchema } from "features/adminLogin/api/validation";
import { useTranslation } from "react-i18next";

const AdminPanelLogin = () => {
  const history = useHistory();
  const toast = useToast();
  const { trigger, isMutating } = useAdminLogin();
  const { setAccess, setRefresh } = useTokenStore();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const { setAdmin } = useAdminStore();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      try {
        trigger(
          {
            username: values.email,
            password: values.password,
          },
          {
            onSuccess: (response) => {
              setAccess(response.access ?? "");
              setRefresh(response.refresh ?? "");
              setAdmin(true);

              const tokenData: { role: string } = jwt_decode(
                response.access ?? ""
              );

              if (tokenData.role === "superadmin") {
                history.push("/superadmin");
              } else if (tokenData.role === "doctor") {
                history.push("/doctor");
              } else if (tokenData.role === "nurse") {
                history.push("/nurse");
              } else {
                history.push("/admin/users");
              }
            },
            onError: (error) => {
              if (error.response.statusText === "Unauthorized") {
                toast({
                  position: "top",
                  title: "Email or password are incorrect",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              }
            },
          }
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        const errorMessage = navigator.onLine
          ? "Incorrect username or password."
          : "Unable to connect to the server. Please check your network connection and try again.";
        toast({
          position: "top",
          title: "Login error",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    validationSchema: AdminLoginSchema,
  });

  return (
    <Flex
      w="full"
      direction="column"
      align="center"
      justify="center"
      mt={{ base: "-3rem", sm: "0rem" }}
    >
      <Image src={logo_group} />

      <Center
        flexDirection="column"
        w="full"
        py={{ base: ".5rem", sm: "", md: "4rem", lg: "4rem" }}
        px={{ base: "0rem", md: "4rem" }}
      >
        <Heading
          color="rgba(0, 38, 55, 1)"
          fontWeight={600}
          fontSize="48px"
          letterSpacing={1.6}
        >
          {t("Welcome")}!
        </Heading>
        <Text
          fontFamily="secondary"
          color="rgba(0, 38, 55, 0.4)"
          fontWeight={400}
          fontSize="16px"
        >
          {t("Please login")}.
        </Text>

        <Box
          w="full"
          py={{ base: "1rem", sm: "2rem", md: "", lg: "2.5rem" }}
          px={{ base: "1rem", sm: "10rem", md: "9.5rem", lg: "16.9rem" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <FormControl isInvalid={!!formik.errors.email} h="75px">
              <FormLabel
                fontWeight={600}
                fontSize={{ base: "12px", lg: "14px" }}
                color="rgba(0, 38, 55, 1)"
                fontFamily="secondary"
                letterSpacing={0.7}
              >
                EMAIL
              </FormLabel>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Enter your email"
                _placeholder={{
                  color: "rgba(0, 38, 55, 0.5)",
                  fontSize: "16px",
                }}
                onChange={formik.handleChange}
                value={formik.values.email}
                minH={{ base: "3rem", lg: "3.5rem !important" }}
              />
              <FormErrorMessage color="red" fontSize="14px">
                {formik.errors.email}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              mt={{ base: "1.5rem", lg: "2rem" }}
              isInvalid={!!formik.errors.email}
              h="75px"
            >
              <FormLabel
                fontWeight={600}
                fontSize={{ base: "12px", lg: "14px" }}
                color="rgba(0, 38, 55, 1)"
                fontFamily="secondary"
                letterSpacing={0.7}
              >
                PASSWORD
              </FormLabel>

              <PasswordInput
                id="password"
                name="password"
                placeholder="Enter your password"
                _placeholder={{
                  color: "rgba(0, 38, 55, 0.5)",
                  fontSize: "16px",
                }}
                onChange={formik.handleChange}
                value={formik.values.password}
              />

              <FormErrorMessage color="red" fontSize="16px">
                {formik.errors.password}
              </FormErrorMessage>
            </FormControl>

            <Center py="2.5rem">
              <Button
                w="full"
                type="submit"
                bg="rgba(88, 154, 175, 1)"
                borderColor="white"
                fontFamily="secondary"
                fontWeight={700}
                fontSize="16px"
                letterSpacing={1}
                color={"white"}
                mb="-3rem"
                _hover={{
                  bg: "blueish.700",
                }}
                maxW="235px"
                onClick={() => {
                  formik.submitForm();
                  console.log("submited");
                }}
                isLoading={isMutating}
              >
                {t("Login")}
              </Button>
            </Center>
          </form>
        </Box>
      </Center>

      <Center position={"absolute"} bottom={"5vh"}>
        <Link to="/admin-login-reset">
          <Text
            color="rgba(0, 38, 55, 0.5)"
            fontSize="16px"
            fontWeight={700}
            fontFamily="secondary"
            textDecor="underline"
          >
            {t("I forgot my password")}
          </Text>
        </Link>
      </Center>
    </Flex>
  );
};

export default AdminPanelLogin;
