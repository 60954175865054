import { Box, Flex, Image, Text, Heading } from "@chakra-ui/react";

import pills_1 from "assets/icons/pills_1.svg";
import { useAdminUserInfo } from "features/userInfo/api/client";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const AdminUserMedication: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: userData } = useAdminUserInfo(id);

  const { t } = useTranslation();

  /*   const lastOrder = userData?.orders.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )[0]; */

  return (
    <Flex
      bg="white"
      p={"2.5rem 1rem 1.5rem 2.5rem"}
      borderRadius="12px"
      w="40%"
      direction="column"
      gap={3}
    >
      <Box display={{ base: "none", sm: "block" }}>
        <Image src={pills_1} />
      </Box>
      <Box>
        <Heading color={"blueish.500"} fontSize={"20px"} fontWeight={500}>
          {t("MEDICATION TYPE")}
        </Heading>
        <Text color={"blackish.200"} fontSize={"24px"} fontWeight={400}>
          {userData?.medication?.name}
        </Text>
        <Text color={"blackish.200"} fontSize={"16px"}>
          {userData?.dose} {t("milligram (mg) per day")}
        </Text>
      </Box>
    </Flex>
  );
};

export default AdminUserMedication;
