import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface DoubleDoseInputProps {
  title: string;
  from?: number;
  to?: number;
  setFrom(from: number): void;
  setTo(to: number): void;
}

const DoubleDoseInput: FunctionComponent<DoubleDoseInputProps> = ({
  title,
  from,
  to,
  setFrom,
  setTo,
}) => {
  const { t } = useTranslation();

  return (
    <VStack>
      <FormControl>
        <FormLabel
          fontSize="14px"
          fontWeight={600}
          color="rgba(255, 255, 255, 1)"
          lineHeight="20px"
          textTransform={"uppercase"}
        >
          {title}
        </FormLabel>

        <Flex gap={"1rem"}>
          <Flex
            border="1px solid rgba(255, 255, 255, 0.5)"
            bg="rgba(255, 255, 255, 0.1)"
            borderRadius="8px"
            align="center"
            p="15px"
            pl={"0rem"}
            h="49px"
            w={"10rem"}
          >
            <Input
              color="white"
              type={"number"}
              fontWeight={500}
              opacity={0.8}
              fontSize={{ base: "1rem", lg: "md" }}
              placeholder={"Från"}
              _placeholder={{ color: "white", opacity: 0.5 }}
              value={from ?? ""}
              min={0}
              onChange={(e) => {
                setFrom(e.target.valueAsNumber);
              }}
            />
            <Text fontSize={"stnd"}>mg</Text>
          </Flex>
          <Flex
            border="1px solid rgba(255, 255, 255, 0.5)"
            bg="rgba(255, 255, 255, 0.1)"
            borderRadius="8px"
            align="center"
            p="15px"
            pl={0}
            h="49px"
            w={"10rem"}
          >
            <Input
              type={"number"}
              color="white"
              fontWeight={500}
              opacity={0.8}
              value={to ?? ""}
              min={0}
              onChange={(e) => {
                setTo(e.target.valueAsNumber);
              }}
              fontSize={{ base: "1rem", lg: "md" }}
              placeholder={"Till"}
              _placeholder={{ color: "white", opacity: 0.5 }}
            />
            <Text fontSize={"stnd"}>mg</Text>
          </Flex>
        </Flex>
      </FormControl>
    </VStack>
  );
};

export default DoubleDoseInput;
