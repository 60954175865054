import { Box, VStack } from "@chakra-ui/react";
import { useFetchAllDoctors } from "features/doctors/api/client";
import { IndividualFiltersType } from "features/reporting/api/type";
import { FunctionComponent } from "react";
import DoctorTableRowItem from "../DoctorTableRowItem";

interface DoctorsTableProps {
  filters?: IndividualFiltersType;
}

const DoctorsTable: FunctionComponent<DoctorsTableProps> = ({ filters }) => {
  const { data: doctorsList } = useFetchAllDoctors();

  return (
    <Box overflowX={"auto"}>
      <VStack w={"fit-content"} alignItems={"stretch"} p={"1rem"}>
        {doctorsList?.doctors.map((doctor) => {
          return (
            <DoctorTableRowItem
              id={doctor.id}
              name={doctor.first_name + " " + doctor.last_name}
              filters={filters}
            />
          );
        })}
      </VStack>
    </Box>
  );
};

export default DoctorsTable;
