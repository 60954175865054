import { Button, Flex, Heading } from "@chakra-ui/react";
import { FunctionComponent } from "react";

import RequestAcceptedIcon from "ui/iconComponents/RequestAcceptedIcon";

import { useTranslation } from "react-i18next";

interface RequestAcceptedProps {
  onClose(): void;
}

const RequestAccepted: FunctionComponent<RequestAcceptedProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      w="100%"
      bg="rgba(44, 132, 117, 0.1)"
      align="center"
      justify="center"
      direction="column"
      gap="40px"
      h="540px"
    >
      <Flex direction="column" align="center" gap="22px">
        <RequestAcceptedIcon />
        <Heading fontSize="36px" color="rgba(44, 132, 117, 1)">
          {t("Request Accepted")}
        </Heading>
      </Flex>
      <Button
        variant="adjust"
        minW="140px"
        onClick={() => {
          onClose();
        }}
      >
        {t("Close")}
      </Button>
    </Flex>
  );
};

export default RequestAccepted;
