import { Flex, Heading, Box, Spinner, Text } from "@chakra-ui/react";
import NotificationCard from "../notificationCard";
import { useNewNotifications } from "../api/client";
import { useCallback, useMemo, useRef, useState } from "react";
import useDebounce from "features/admin/helper/columns";
import { useTranslation } from "react-i18next";

interface NotificationCardListProps {
  unreadId: string[];
}

const NotificationCardList: React.FC<NotificationCardListProps> = ({
  unreadId,
}) => {
  const { t } = useTranslation();
  const { data: notifications, size, setSize } = useNewNotifications();

  const [isFetching, setIsFetching] = useState(false);
  const [noMoreNotifications, setNoMoreNotifications] = useState(false);

  const debouncedIsFetching = useDebounce(isFetching, 500);

  const handleScroll = useCallback(
    async (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (
        target.isIntersecting &&
        !debouncedIsFetching &&
        !noMoreNotifications
      ) {
        setIsFetching(true);
        await setSize((currentSize: number) => currentSize + 1);
        setIsFetching(false);
      }
    },
    [debouncedIsFetching, setSize, noMoreNotifications]
  );

  const observer = useRef<IntersectionObserver>();
  const endRef = useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(handleScroll, {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      });

      if (node) observer.current.observe(node);
    },
    [handleScroll]
  );

  const allNotifications = useMemo(() => {
    if (!notifications) return [];
    return notifications.flatMap((page) => page);
  }, [notifications]);

  allNotifications.sort((a, b) => {
    const aIsRead = a.is_read;
    const bIsRead = b.is_read;
    const aDate = new Date(a.created_at).getTime();
    const bDate = new Date(b.created_at).getTime();

    if (aIsRead === bIsRead) {
      return bDate - aDate;
    } else if (aIsRead) {
      return 1;
    } else {
      return -1;
    }
  });

  return (
    <Flex w="100%" direction="column" gap={3}>
      <Flex w="100%" position="relative">
        <Heading fontSize="36px" fontWeight={500} px="20px" mb="30px">
          {t("Notifications")}
        </Heading>
        {unreadId.length > 0 && (
          <Box
            w="20px"
            h="20px"
            bg="rgba(222, 36, 83, 1)"
            borderRadius="50%"
            position="absolute"
            top={0}
            left={213}
            _after={{
              content: `"${unreadId.length}"`,
              position: "absolute",
              bottom: 0,
              right: 1.5,
              color: "white",
              fontSize: "13px",
              fontWeight: 700,
            }}
          />
        )}
      </Flex>
      {allNotifications.map((item) => (
        <NotificationCard
          key={item.id}
          text={item.text}
          title={item.title}
          status={item.status}
          is_read={item.is_read}
          created_at={item.created_at}
          user_id={item.user_id}
          id={item.id}
          working_id={item.working_id}
        />
      ))}
      <div ref={endRef}></div>
      {debouncedIsFetching && <Spinner />}
      {noMoreNotifications && (
        <Flex w="100%" align="center" justify="center">
          <Text color="rgba(0, 38, 55, 1)" fontSize="18px" fontWeight={500}>
            No more notifications
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default NotificationCardList;
