import { Flex, Heading, Text, Image, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import left_arrow from "assets/icons/left_arrow.svg";
const Taper = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Box pb={"5vh"}>
      <Flex
        w="full"
        bg="rgba(255, 255, 255, 1)"
        borderRadius="24px"
        p="60px"
        align="flex-start"
        direction="column"
        gap="10px"
      >
        <Flex
          align="center"
          gap="14px"
          onClick={() => history.goBack()}
          cursor="pointer"
        >
          <Image src={left_arrow} />
          <Text fontSize="18px" color="black" fontWeight={500}>
            {t("Back")}
          </Text>
        </Flex>
        <Flex w="100%" align="center" justify="center" mb="1rem">
          <Heading fontSize="4xl" color="blueish.500">
            {t("How the tapering works")}
          </Heading>
        </Flex>

        <Heading mt={"1rem"}>{t("The idea behind Lamina Clinic")}</Heading>
        <Text color="rgba(0, 38, 55, 1)" fontSize="18px" textAlign={"justify"}>
          {t(
            "We who are behind Lamina Clinic are specialists in tapering and discontinuing antidepressants. Our doctors have access to prescribing your medication in doses not provided by regular pharmacies and in this way we can help you taper off at a safer pace. Through our care team and with our app, we keep an eye on you feeling well throughout the period, and can adjust the rate and dose if, against the odds, you feel worse for a period. All this means that you get as painless a reduction as possible with maximum support."
          )}
        </Text>

        <Heading mt={"1rem"}>{t("A gentler method of tapering off")}</Heading>
        <Text color="rgba(0, 38, 55, 1)" fontSize="18px" textAlign={"justify"}>
          {t(
            "Based on our doctor's assessment of you and your situation, we make an initial calculation of what your tapering off might look like. Our doctors base the schedule on your answers to the assessment questions, your situation, what medication you are taking, and a host of other parameters that we know play a role in what rate you should taper off. We find a balance between tapering off to be as gentle and safe for you as possible, but at the same time not taking too long."
          )}
        </Text>

        <Text color="rgba(0, 38, 55, 1)" fontSize="18px" textAlign={"justify"}>
          {t(
            "The problem we want to avoid is that you will experience what are called withdrawal symptoms. About 56% of those who try to taper off in the usual way suffer from this, which is often very unpleasant feelings and thoughts, strong physical symptoms and sleep problems. Some are not affected at all, while others can have a very troublesome time, especially when you get down to small doses."
          )}
        </Text>

        <Text color="rgba(0, 38, 55, 1)" fontSize="18px" textAlign={"justify"}>
          {t(
            "In order for you to avoid having to experience severe withdrawal symptoms, Lamina Clinic uses a method called hyperbolic tapering. This means that you reduce slowly and with a smaller and smaller dose at a time. Stepping down hyperbole instead of reducing by the same amount each time significantly reduces the risk of severe and very severe withdrawal symptoms."
          )}
        </Text>

        <Heading mt={"1rem"}>{t("Close monitoring provides security")}</Heading>
        <Text color="rgba(0, 38, 55, 1)" fontSize="18px" textAlign={"justify"}>
          {t(
            "We follow up in two ways: firstly, we also book you in for regular follow-up calls with our care team once you've had your first appointment with your doctor. Then you go through how you feel and address any questions you have about your tapering. In addition, we also ask you to report how you feel in the app, preferably every day. It's easy and only takes a couple of clicks, just rate how well you feel on a scale of one to seven. Your reports enable us to catch you immediately if, contrary to expectations, you begin to feel worse and experience symptoms of withdrawal."
          )}
        </Text>

        <Heading mt={"1rem"}>{t("Your mood determines the pace")}</Heading>
        <Text color="rgba(0, 38, 55, 1)" fontSize="18px" textAlign={"justify"}>
          {t(
            "If we notice in the follow-up that the tapering is going too fast for your body, we will recommend that you adjust your plan and make the tapering slower, so that you don't feel bad during the process. If, against the odds, you should experience strong withdrawal symptoms, we will recommend you to back off one step and stabilize for a while, before continuing to step down again to the next step. Then you go up a small step to the dose you had before you started feeling worse, and wait a few days before starting the tapering again. That way, you'll feel better again in just a couple of days - you won't have to suffer through severe withdrawal symptoms."
          )}
        </Text>

        <Heading mt={"1rem"}>{t("A simple, stress-free process")}</Heading>
        <Text color="rgba(0, 38, 55, 1)" fontSize="18px" textAlign={"justify"}>
          {t("Does it seem like a lot to keep track of? No worries, we'll keep track of it for you. You receive medicines in ready-made sachets to your home, close follow-up with the care team and a slow and safe tapering rate. Depending on what you report in the app and what emerges in our follow-up meetings, we will contact you about adjusting the dose, if we see that there is a need for it. The only thing you need to think about is taking your medications as directed and telling us how you feel in the meantime.")}
        </Text>
      </Flex>
    </Box>
  );
};

export default Taper;
