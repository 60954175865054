import { Flex, IconButton, Text, VStack } from "@chakra-ui/react";
import { useFetchAllDoctors } from "features/doctors/api/client";
import { useAssignPersonnel } from "features/superadmin/api/client";

import { FunctionComponent } from "react";
import { IoTrash } from "react-icons/io5";
import { useLocation } from "react-router-dom";

import { useAdminUserInfo } from "features/admin/api/client";

import { invokeConfirmationModal } from "features/genericModal";
import { useTranslation } from "react-i18next";

interface DoctorsComponentProps {
  ids: string[];
  userId?: string;
}

const DoctorsComponent: FunctionComponent<DoctorsComponentProps> = ({
  ids = [],
  userId,
}) => {
  const { t } = useTranslation();

  const { data: doctorsData } = useFetchAllDoctors();
  const location = useLocation();

  const isDoctorPage = location.pathname.startsWith("/doctor");
  const isNursePage = location.pathname.startsWith("/nurse");
  const isAdmin =
    location.pathname.startsWith("/superadmin") ||
    location.pathname.startsWith("/admin");

  const mainDoctor = doctorsData?.doctors.find((e) => e.id === ids[0]);
  const secondaryDoctor = doctorsData?.doctors.find((e) => e.id === ids[1]);

  const { trigger: assignPersonnel } = useAssignPersonnel();
  const { data: patientInfo } = useAdminUserInfo(userId || "");

  const removeDoc = (isMain: boolean) => {
    invokeConfirmationModal(
      t("Are you sure you want to remove this doctor from this patient?"),
      () => {
        if (userId) {
          const cleanedDoctors = (patientInfo?.doctors || []).map((doctor) =>
            doctor === null ? "" : doctor
          );
          const cleanedNurses = (patientInfo?.nurses || []).map((nurse) =>
            nurse === null ? "" : nurse
          );

          assignPersonnel({
            nurses: cleanedNurses.filter(Boolean),
            doctors: isMain
              ? ["", cleanedDoctors[1]].filter(Boolean)
              : [cleanedDoctors[0], ""].filter(Boolean),
            users: [userId],
          });
        }
      },
      `${t("Warning")}`
    );
  };

  return (
    <VStack alignItems={"flex-start"}>
      {mainDoctor && (
        <Flex gap={"0.5rem"} alignItems={"baseline"}>
          <Text
            color={"#589AAF"}
            fontWeight={700}
            fontSize={"1rem"}
            textDecor={"underline"}
          >
            {mainDoctor.first_name + " " + mainDoctor.last_name}
          </Text>
          {isDoctorPage || isNursePage ? (
            <Text color={"blueish.900"} opacity={0.5} fontSize={"0.75rem"}>
              {t("main")}
            </Text>
          ) : (
            <>
              <Text color={"blueish.900"} opacity={0.5} fontSize={"0.75rem"}>
                {t("main")}
              </Text>

              <IconButton
                variant={"ghost"}
                icon={<IoTrash />}
                marginLeft={"auto"}
                aria-label={""}
                opacity={0.5}
                fontSize={"0.9rem"}
                onClick={() => {
                  removeDoc(true);
                }}
              />
            </>
          )}
        </Flex>
      )}
      {secondaryDoctor && (
        <Flex gap={"0.5rem"} alignItems={"baseline"}>
          <Text
            color={"#589AAF"}
            fontWeight={700}
            fontSize={"1rem"}
            textDecor={"underline"}
          >
            {secondaryDoctor.first_name + " " + secondaryDoctor.last_name}
          </Text>
          <Text color={"blueish.900"} opacity={0.5} fontSize={"0.75rem"}>
            {t("secondary")}
          </Text>

          {isAdmin && (
            <IconButton
              variant={"ghost"}
              icon={<IoTrash />}
              marginLeft={"auto"}
              aria-label={""}
              opacity={0.5}
              fontSize={"0.9rem"}
              onClick={() => removeDoc(false)}
            />
          )}
        </Flex>
      )}
    </VStack>
  );
};

export default DoctorsComponent;
