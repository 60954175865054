import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  Link,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ZegoCallsOmnipresentContext } from "features/ZegoCallsOmnipresentContext/components/ZegoCallsOmnipresentContext";
import { useFetchAddressById } from "features/addressPopup/api/client";
import { useAdminUserInfo } from "features/admin/api/client";
import NotesDrawer from "features/admin/components/NotesDrawer";
import ChatDrawer from "features/doctors/components/ChatDrawer/ChatDrawer";
import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsChatText } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { FiHome, FiPhone } from "react-icons/fi";
import { PiEnvelopeBold, PiNoteBold } from "react-icons/pi";
import AddressModal from "../AddressModal";
import { GoPencil } from "react-icons/go";
import { useFetchMeetingsLogs } from "features/staffCommons/api/client";
import { MeetingLogItemResponseType } from "features/staffCommons/api/types";

interface PatientInfoBlockProps {
  patientId: string;
}

const PatientInfoBlock: FunctionComponent<PatientInfoBlockProps> = ({
  patientId,
}) => {
  const { data: userData } = useAdminUserInfo(patientId);
  const { callUser, callUserByMeeting } = useContext(
    ZegoCallsOmnipresentContext
  );

  const { data: meetingsData } = useFetchMeetingsLogs(patientId, 1);

  const filterFutureMeetings = (meetings: any[]) => {
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    return meetings.filter(
      (meeting) => new Date(meeting.timestamp) >= yesterday
    );
  };

  const futureMeetings: MeetingLogItemResponseType[] = meetingsData
    ? filterFutureMeetings(meetingsData.results)
    : [];

  const {
    isOpen: isChatOpen,
    onOpen: onChatOpen,
    onClose: onChatClose,
  } = useDisclosure();

  const {
    isOpen: isJournalOpen,
    onOpen: onJournalOpen,
    onClose: onJournalClose,
  } = useDisclosure();

  const {
    isOpen: isAddressEditorOpen,
    onOpen: onAddressEditorOpen,
    onClose: onAddressEditorClose,
  } = useDisclosure();

  const { t } = useTranslation();

  const handleCallClick = () => {
    if (userData) {
      //callUser(userData, userData.id + Date.now());
      callUserByMeeting(userData, futureMeetings, userData.id + Date.now());
    }
  };

  const handleChatClick = () => {
    onChatOpen();
  };

  const handleJournalClick = () => {
    onJournalOpen();
  };

  return (
    <Stack
      bg={"white"}
      borderRadius={"0.75rem"}
      p={"2rem"}
      height={"fit-content"}
      maxW={"100%"}
      minW={"23rem"}
      spacing={"1.5rem"}
      position={"relative"}
    >
      <Box position={"absolute"} top={"1rem"} right={"1rem"}>
        <IconButton
          aria-label={""}
          icon={<GoPencil />}
          minWidth={0}
          variant={"transparent"}
          p={"0 !important"}
          color={"#0026377F"}
          fontSize={"1.5rem"}
          onClick={() => {
            onAddressEditorOpen();
          }}
        />
        <Text color="#0026377F" fontSize=".6rem">
          {t("Edit")}
        </Text>
      </Box>
      <Stack spacing={0} gap={"1.5rem"}>
        <Heading
          fontSize={"2rem"}
          fontWeight={500}
          fontFamily={"secondary"}
          lineHeight={"1em"}
        >
          {userData?.first_name} {userData?.last_name}
        </Heading>
        <Text color={"blueish.900"} fontSize={"1rem"} fontWeight={700}>
          {userData?.username}
        </Text>

        <Stack spacing={"0.5rem"}>
          <Flex alignItems={"center"} gap={"0.5rem"}>
            <PiEnvelopeBold color={"#002637"} opacity={0.5} />
            <Link
              href={"mailto:" + userData?.email}
              color={"#43B3BA"}
              fontWeight={"500"}
              lineHeight={"1rem"}
              fontSize={"1rem"}
            >
              {userData?.email ?? t("Not specified")}
            </Link>
          </Flex>
          <Flex alignItems={"center"} gap={"0.5rem"}>
            <FiPhone color={"#002637"} opacity={0.5} />
            <Text
              color={"blueish.900"}
              fontWeight={"500"}
              lineHeight={"1rem"}
              fontSize={"1rem"}
            >
              {userData?.address?.phone ?? t("Not specified")}
            </Text>
          </Flex>
        </Stack>

        <Flex alignItems={"flex-start"} gap={"0.5rem"}>
          <FiHome color={"#002637"} opacity={0.5} />

          <Box>
            <Text
              color={"blueish.900"}
              fontWeight={"500"}
              lineHeight={"1.5rem"}
              fontSize={"1rem"}
            >
              {userData?.address?.address ?? t("Not specified")}
            </Text>
            <Text
              color={"blueish.900"}
              fontWeight={"500"}
              lineHeight={"1.5rem"}
              fontSize={"1rem"}
            >
              {userData?.address?.zip_code} {userData?.address?.city}
            </Text>

            <Text
              color={"blueish.900"}
              fontWeight={"500"}
              lineHeight={"1.5rem"}
              fontSize={"1rem"}
              textTransform={"uppercase"}
            >
              {userData?.address?.country}
            </Text>
          </Box>
        </Flex>

        <Text color={"#0026377f"} fontSize={"1rem"}>
          {t("Registered")}:{" "}
          {new Date(userData?.date_joined ?? Date.now()).toLocaleDateString(
            t("locale") ?? "en-GB",
            {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }
          )}
        </Text>
      </Stack>

      <Divider />

      <Stack spacing={"0.5rem"}>
        <Button
          bg={"transparent"}
          variant={"unstyled"}
          border={"1px solid #002637"}
          w={"100%"}
          color={"#002637"}
          fontWeight={700}
          fontSize={"1rem"}
          display={"inline-flex"}
          alignItems={"flex-start"}
          leftIcon={<FaPhoneAlt />}
          iconSpacing={"0.5rem"}
          py={"0.75rem"}
          h={"fit-content"}
          onClick={handleCallClick}
        >
          {t("Direct Call to Patient")}
        </Button>

        <Button
          bg={"transparent"}
          variant={"unstyled"}
          border={"1px solid #002637"}
          w={"100%"}
          color={"#002637"}
          fontWeight={700}
          fontSize={"1rem"}
          display={"inline-flex"}
          alignItems={"flex-start"}
          leftIcon={
            <BsChatText
              style={{ transform: "scaleX(-1)" }}
              fontSize={"1.1rem"}
            />
          }
          iconSpacing={"0.5rem"}
          py={"0.75rem"}
          h={"fit-content"}
          onClick={handleChatClick}
        >
          {t("Chat with Patient")}
        </Button>

        {/*  <Button
          bg={"transparent"}
          variant={"unstyled"}
          border={"1px solid #002637"}
          w={"100%"}
          color={"#002637"}
          fontWeight={700}
          fontSize={"1rem"}
          display={"inline-flex"}
          alignItems={"flex-start"}
          leftIcon={<PiNoteBold fontSize={"1.1rem"} />}
          iconSpacing={"0.5rem"}
          py={"0.75rem"}
          h={"fit-content"}
          onClick={handleJournalClick}
        >
          {t("See Journal Notes")}
        </Button> */}
      </Stack>

      <ChatDrawer
        userId={patientId}
        isOpen={isChatOpen}
        onOpen={onChatOpen}
        onClose={onChatClose}
      />
      <NotesDrawer isOpen={isJournalOpen} onClose={onJournalClose} />
      <AddressModal
        isOpen={isAddressEditorOpen}
        onClose={onAddressEditorClose}
        patientId={patientId}
      />
    </Stack>
  );
};

export default PatientInfoBlock;
