import { Flex, Text } from "@chakra-ui/react";
import PopupChange from "features/prescriptionForm/components/prescriptionPopupChange";
import { t } from "i18next";
interface NotificationConsentedProps {
  current_treatment?: number;
  start_treatment_date?: string;
  id: string;
}

const NotificationConsented: React.FC<NotificationConsentedProps> = ({
  id,
  current_treatment,
  start_treatment_date,
}) => {
  return (
    <Flex w="100%" direction="column" gap="12px">
      <Flex w="100%" align="flex-start" direction="column">
        <Text color="blueish.500" fontSize="16px" fontWeight={500}>
          CONSENTED TREATMENT PLAN
        </Text>
        <Flex w="100%" gap="12px">
          <Text color="black" fontSize="16px" fontWeight={700}>
            {current_treatment ? current_treatment * 4 : 0} weeks
          </Text>
          <Text color="black" fontSize="16px">
            {new Date(start_treatment_date || "").toLocaleDateString(
              t("locale") ?? "en-GB",
              {
                month: "short",
                day: "numeric",
                year: "numeric",
              }
            )}
          </Text>
        </Flex>
      </Flex>

      <PopupChange
        isAdjustable={false}
        selectedPlan={(current_treatment ?? 0) * 4}
        setSelectedPlan={() => {}}
        dashboardDisplay={true}
        adminDisplayId={id}
      />
    </Flex>
  );
};

export default NotificationConsented;
