import { Flex, Skeleton, SkeletonCircle } from "@chakra-ui/react";

type NotificationWidgetItemSkeletonProps = {
  length: number;
};

const NotificationWidgetItemSkeleton: React.FC<
  NotificationWidgetItemSkeletonProps
> = ({ length }) => {
  return (
    <Flex w="full" justify="center" align="stretch" direction="column" gap="2">
      {Array.from({
        length: length || 10,
      }).map((_, index) => (
        <Flex w="full" gap=".5rem">
          <Skeleton w="28%" borderLeftRadius="0.5rem" h="2.5rem" />

          <Skeleton w="24%" h="2.5rem" />

          <SkeletonCircle />
          <Skeleton w="35%" h="2.5rem" />

          <Skeleton w="13%" borderRightRadius="0.5rem" h="2.5rem" />
        </Flex>
      ))}
    </Flex>
  );
};

export default NotificationWidgetItemSkeleton;
