import { Flex, Heading, Text, Button, Image } from "@chakra-ui/react";
import { useState } from "react";

import decline_white from "assets/icons/decline_white.svg";

import RequestDeclined from "../RequestDeclined";

import {
  useNewNotifications,
  usePerformNotificationAction,
  useRequestChangesConfirmBlock,
} from "../api/client";
import NotificationConsented from "../notificationConsented";
import NotificationRequested from "../notificationRequested";

interface NotificationRequestDeclineProps {
  onClose(): void;
  id: string;
}

const NotificationRequestDecline: React.FC<NotificationRequestDeclineProps> = ({
  onClose,
  id,
}) => {
  const [requestDeclined, setRequestDeclined] = useState<boolean>(false);

  const { mutate } = useNewNotifications();

  const { data: userData } = useRequestChangesConfirmBlock(id);
  const { trigger: requestActionDeclined } = usePerformNotificationAction(
    id,
    "request_changes_decline"
  );

  const handleDeclinedRequest = async () => {
    await requestActionDeclined();
    mutate();
    setRequestDeclined(true);
  };
  return (
    <>
      {requestDeclined ? (
        <RequestDeclined onClose={onClose} />
      ) : (
        <Flex
          w="100%"
          direction="column"
          align="flex-start"
          gap="24px"
          py="68px"
          px="60px"
        >
          <Heading fontSize={{ base: "32px", lg: "36px" }} fontWeight={500}>
            Are you sure you want to accept the user's request to change the
            treatment plan?
          </Heading>
          {userData && (
            <Text
              color="black"
              fontSize="20px"
              fontWeight={700}
              textDecoration="underline"
            >
              {userData.name}
            </Text>
          )}
          <Flex w="100%" gap="40px">
            <NotificationConsented
              current_treatment={userData?.current_treatment}
              start_treatment_date={userData?.start_treatment_date}
              id={id}
            />
            <NotificationRequested
              expected_treatment={userData?.expected_treatment}
              treatment_change_date={userData?.treatment_change_date}
              id={id}
            />
          </Flex>

          <Flex w="100%" justify="flex-end" align="center" gap="60px" mt="40px">
            <Text
              color="black"
              fontSize="18px"
              fontWeight={700}
              cursor="pointer"
              onClick={onClose}
            >
              Cancel
            </Text>
            <Button
              minW="200px"
              variant="adjust"
              border="none"
              bg="rgba(213, 115, 138, 1)"
              color="white"
              onClick={handleDeclinedRequest}
              gap="8px"
            >
              <Image src={decline_white} mb="2px" />
              Decline request
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default NotificationRequestDecline;
