import {
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";

import { general_data } from "features/general/helper/general_data";

const GeneralContext = () => {
  return (
    <Flex
      w="100vw"
      bgColor="rgba(88, 154, 175, 0.1)"
      align="center"
      justify="center"
      p={{ base: "0rem", sm: "2rem", md: "4rem" }}
      py={{ base: "3rem", sm: "0rem" }}
      mb={{ base: "-3.5rem !important", lg: "-5.5rem !important" }}
      direction="column"
      gap={7}
    >
      <Heading
        color="rgba(0, 38, 55, 1)"
        fontSize={{ base: "32px", sm: "48px" }}
        fontWeight={600}
      >
        Så fungerar det
      </Heading>

      <Flex
        w="100%"
        align="center"
        justify="center"
        p={{ base: ".5rem", sm: "2rem", md: "6rem" }}
        mt="2"
      >
        <SimpleGrid
          w="full"
          columns={{ base: 2, sm: 6 }}
          spacing={8}
          columnGap={2}
          justifyItems="center"
          placeItems="center"
        >
          {general_data.map((item) => (
            <Flex
              w="full"
              direction="column"
              key={item.id}
              gap={3}
              alignSelf="flex-start"
              align="center"
              justify="center"
              px="1.25rem"
            >
              <Image
                src={item.image}
                w={{ base: "35%", sm: "40%", md: "40%", lg: "25%" }}
              />

              <Text
                color="rgba(88, 154, 175, 1)"
                fontSize="14px"
                fontWeight={700}
                opacity={0.5}
              >
                {item.steg}
              </Text>
              <Text
                color="rgba(0, 38, 55, 1)"
                fontSize={{ base: "14px", sm: "16px" }}
                fontWeight={400}
                textAlign="center"
                fontFamily="secondary"
              >
                {item.text}
              </Text>
            </Flex>
          ))}
        </SimpleGrid>
      </Flex>
      <Divider
        w={{ base: "90%", sm: "80%" }}
        h="1px"
        bgColor="rgba(88, 154, 175, 0.3)"
      />

      <Button
        maxW="270px"
        color="rgba(0, 38, 55, 1)"
        minH={{ base: "50px", sm: "auto" }}
        mt="2rem"
        mb={"5"}
        fontSize={{ base: "14px", sm: "18px" }}
        px={{ base: "3rem", sm: "auto" }}
      >
        <ChakraLink
          href="https://calendly.com/lamina-clinic/15-min-konsultation-kostnadsfritt"
          target="blank"
          textDecoration="none !important"
        >
          Boka gratis konsultation
        </ChakraLink>
      </Button>
    </Flex>
  );
};

export default GeneralContext;
