import { APIClient } from "services/api/client";
import useSWR, { useSWRConfig, mutate } from "swr";
import { PrescriptionHelperResponseType } from "./type";

export const useFetchPrescriptionHelper = (id: string) => {
  return useSWR<PrescriptionHelperResponseType>(
    `/admin/users/${id}/prescription-helper`,
    (url) => APIClient.get(url)
  );
};
