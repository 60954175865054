import { Box, Button, Flex, HStack, Text } from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { number } from "yup";

interface PaginationProps {
  onPageChange(newPage: number): void;
  numberOfPages: number;
  currentPage: number;
}

const Pagination: FunctionComponent<PaginationProps> = ({
  numberOfPages,
  onPageChange,
  currentPage,
}) => {
  const { t } = useTranslation();

  return (
    <HStack w="full" gap={0} spacing={0} overflow="hidden">
      <Button
        key="prev"
        variant={"transparent"}
        mr={"2rem"}
        isDisabled={currentPage === 1}
        onClick={() => {
          onPageChange(currentPage - 1);
        }}
      >
        <Flex alignItems={"center"} gap={"0.5rem"}>
          <BsArrowLeft color={"#002637"} />
          <Text color={"#002637"} fontSize={"1rem"} lineHeight={"1rem"}>
            {t("Prev")}
          </Text>
        </Flex>
      </Button>

      {Array.from({ length: numberOfPages }, (_, i) => i + 1).map((e) => {
        return (
          <Button
            key={e}
            onClick={() => {
              onPageChange(e);
            }}
            variant={"transparent"}
            color={currentPage === e ? "white" : "blueish.900"}
            bg={currentPage === e ? "blueish.500" : "transparent"}
            w={"2.5rem"}
            h={"2.5rem"}
            fontSize={"1rem"}
            fontWeight={currentPage === e ? 700 : 400}
            transition={"all 0.3s ease-out"}
          >
            {e}
          </Button>
        );
      })}

      <Button
        key="next"
        variant={"transparent"}
        ml={"2rem"}
        isDisabled={currentPage === numberOfPages}
        onClick={() => {
          onPageChange(currentPage + 1);
        }}
      >
        <Flex alignItems={"center"} gap={"0.5rem"}>
          <Text color={"#002637"} fontSize={"1rem"} lineHeight={"1rem"}>
            {t("Next")}
          </Text>
          <BsArrowRight color={"#002637"} />
        </Flex>
      </Button>

      <Flex w="full" justify="flex-end" px="1">
        {numberOfPages >= 1 && (
          <Flex
            fontSize={"1rem"}
            color="rgb(0, 0, 0, 0.5)"
            fontWeight={600}
            align="center"
            gap={3}
          >
            {t("Page")}
            <Box
              px={"1.2rem !important"}
              py={"0.65rem !important"}
              bg="gray.300"
              border="1px solid rgb(0, 0, 0, 0.3)"
              borderRadius="8px"
              fontSize={"1rem"}
              textAlign="center"
              color="black"
              fontWeight={600}
            >
              {currentPage}
            </Box>
            {t("of")} {numberOfPages}
          </Flex>
        )}
      </Flex>
    </HStack>
  );
};

export default Pagination;
