import {
  Button,
  Flex,
  useDisclosure,
  Text,
  Box,
  HStack,
  VStack,
  Image,
} from "@chakra-ui/react";
import MoodCheckup from "features/admin/components/adminMoodCheckup";
import AdminUserDetails from "features/admin/components/adminUserDetails";
import MedicalHistory from "features/admin/components/MedicalHistory";
import NotesDrawer from "features/admin/components/NotesDrawer";
import TriageModal from "features/admin/components/TriageModal";
import AuthModal from "features/admin/components/notes2fa/components/AuthModal";
import DoctorPatientInfo from "features/doctors/components/DoctorPatientInfo";
import PillsTracker from "features/pillsTracker";

import CurrentDose from "features/superadmin/components/PatientCurrentDose";

import PatientMedicationView from "features/superadmin/components/PatientMedicationView";
import PatientMoodOverview from "features/superadmin/components/PatientMoodOverview";
import PatientSchedule from "features/superadmin/components/PatientSchedule";
import StartingDose from "features/superadmin/components/PatientStartingDose";
import PatientTreatment from "features/superadmin/components/PatientTreatment";
import TaperingPlanner from "features/superadmin/components/TaperingPlanner";
import { t } from "i18next";
import { FunctionComponent, useContext, useEffect, useRef } from "react";
import { BiTestTube } from "react-icons/bi";
import { BsArrowLeft, BsTrash } from "react-icons/bs";
import { CgNotes } from "react-icons/cg";
import { FiUsers } from "react-icons/fi";
import { GoTasklist } from "react-icons/go";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTokenStore } from "services/state/tokenStore";
import {
  useAdminUserInfo,
  useClearUserRequiest,
  useFetchUserTriageData,
} from "features/admin/api/client";
import { useModalStore } from "services/state/modalStore";
import { usePatchAdminUserProfile } from "features/dashboard/api/client";
import {
  invokeBasicInfoModal,
  invokeConfirmationModal,
} from "features/genericModal";
import UserManagement from "features/doctors/components/UserManagement";
import MeetingsLog from "features/staffCommons/components/MeetingsLog";
import DoctorPrescriptionButton from "features/doctors/components/DoctorPrescriptionButton";

import AdjustPlanButton from "features/doctors/components/AdjustPlanButton";
import PatientDosageSection from "features/superadmin/components/PatientDosageSection";
import PatientInfoBlock from "features/staffCommons/components/PatientInfoBlock";
import ActiveTaperingPlan from "features/admin/components/adminMoodCheckup";
import PatientMedicalInformation from "features/staffCommons/components/PatientMedicalInformation";
import PatientLoggedEvents from "features/admin/components/PatientLoggedEvents";

import arrow_left from "assets/icons/left_arrow.svg";
import MoreButton from "features/staffCommons/components/MoreButton";
import JournalRecords from "features/staffCommons/components/JournalRecords";
import MedicalInformation from "features/superadmin/components/PatientMedicalInformation";
import MeetingLogsWidget from "features/staffCommons/components/MeetingLogsWidget";
import { useLocalZegoCalls } from "features/zegoCalls/hooks/useLocalZegoCalls";
import { ZegoCallsOmnipresentContext } from "features/ZegoCallsOmnipresentContext/components/ZegoCallsOmnipresentContext";

interface DoctorPatientPageProps {}
const DoctorPatientPage: FunctionComponent<DoctorPatientPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const history = useHistory();

  const scrollRef = useRef<HTMLDivElement>(null);

  const { isCallOngoing } = useContext(ZegoCallsOmnipresentContext);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <VStack w="full" ref={scrollRef}>
      <Flex
        w="full"
        alignItems="center"
        justifyContent="space-between"
        gap=".75rem"
        pt={"2rem"}
      >
        <Button
          variant="transparent"
          mr={"auto"}
          color={"blueish.900"}
          fontSize={"1rem"}
          opacity={"0.5"}
          leftIcon={<BsArrowLeft fontSize={"1.5rem"} />}
          iconSpacing={"0.5rem"}
          onClick={handleGoBack}
          px={0}
        >
          {t("Back")}
        </Button>
        <AdjustPlanButton title={t("Adjust Plan")} key="" />
        <DoctorPrescriptionButton />
        <MoreButton patientId={id} />
      </Flex>

      <Flex
        w="full"
        py="3rem"
        pt={"1rem"}
        gap="24px"
        direction={{
          base: "column",
          md: isCallOngoing ? "column" : "row",
          lg: "row",
        }}
      >
        <Flex
          w={{ base: "100%", sm: "50%", md: "35%" }}
          direction={{
            base: "column",
            sm: "column",
            md: isCallOngoing ? "row" : "column",
            lg: "column",
          }}
          gap="1.5rem"
        >
          {/* <DoctorPatientInfo /> */}
          <PatientInfoBlock patientId={id} />

          <PatientMedicalInformation />

          {/* <PatientLoggedEvents /> */}
        </Flex>

        <Flex w="full" direction="column" gap="24px">
          <Flex gap="24px">
            <MeetingLogsWidget />
            {/* <MeetingsLog /> */}
            {/* <PatientDosageSection patientId={id} /> */}
            <MedicalInformation />
          </Flex>

          <Flex direction="column" gap="24px">
            <PatientMoodOverview />

            <JournalRecords />

            <ActiveTaperingPlan />
            {/* <TaperingPlanner /> */}
            {/* <PatientTreatment /> */}
            {/* <PillsTracker /> */}
            <PatientSchedule />
            {/* <AdminUserDetails /> */}

            {/* <MedicalHistory /> */}

            {/* <UserManagement /> */}
          </Flex>
        </Flex>
      </Flex>
    </VStack>
  );
};
export default DoctorPatientPage;
