import {
  Flex,
  Heading,
  Box,
  Text,
  Image,
  List,
  ListItem,
  useDisclosure,
} from "@chakra-ui/react";
import { UserAddressType } from "features/dashboard/api/type";

import pencil from "assets/icons/pencil.svg";
import AddressPopup from "features/addressPopup/components/addressPopupContainer";
import { useOrderStore } from "services/state/orderStore";
import { useFetchAddressById } from "features/addressPopup/api/client";
import { useDashboardInfo } from "features/dashboard/api/client";
import { formatPhoneNumber } from "features/addressPopup/helpers/helper";
import { useTranslation } from "react-i18next";

const UserDeliveryAddress = () => {
  const { t } = useTranslation();
  const { data: dashboardData } = useDashboardInfo();
  const { data: addressData } = useFetchAddressById(
    dashboardData?.order?.delivery_address
  );

  const {
    isOpen: isAddressModalOpen,
    onOpen: onAddressModalOpen,
    onClose: onAddressModalClose,
  } = useDisclosure();

  const { currentStep, currentStepState } = useOrderStore();

  return (
    <Flex direction="column" gap={3} w="100%">
      <Heading fontSize="16px" color={"blueish.500"} fontWeight={500}>
        {t("DELIVERY ADDRESS")}
      </Heading>
      {currentStep <= 1 && (
        <Flex
          gap={1}
          align="center"
          borderRadius={"4px"}
          bg={"#EEE8A9"}
          w="max-content"
          p="4px 12px 4px 12px"
          h={"2rem"}
          cursor={"pointer"}
          onClick={onAddressModalOpen}
        >
          <Image src={pencil} alt={"icon to edit address"} />
          <Text
            color={"rgba(0, 38, 55, 1)"}
            fontSize={"16px"}
            fontWeight="bold"
            lineHeight={"1em"}
            w="auto"
          >
            {t("Edit")}
          </Text>
        </Flex>
      )}

      <Text color="blackish.200" fontSize="16px" fontWeight={700}>
        {addressData?.first_name} {addressData?.last_name}
      </Text>
      <Box>
        <Text color="blackish.200" fontSize="16px" fontWeight={500}>
          {addressData?.email}
        </Text>
        <Text color="blackish.200" fontSize="16px" fontWeight={500}>
          {formatPhoneNumber(addressData?.phone ?? "")}
        </Text>
      </Box>
      <List color="blackish.200" fontSize="16px" fontWeight={500}>
        <ListItem>
          {addressData?.city}, {addressData?.country}
        </ListItem>
        <ListItem>{addressData?.address}</ListItem>
        <ListItem>{addressData?.zip_code}</ListItem>
      </List>

      <AddressPopup
        isOpen={isAddressModalOpen}
        onClose={onAddressModalClose}
        editMode={true}
      />
    </Flex>
  );
};

export default UserDeliveryAddress;
