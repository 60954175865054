import { Select } from "chakra-react-select";
import { useGetMedicationsType } from "features/superadmin/api/client";
import { FunctionComponent } from "react";

interface MedicineSelectorProps {
  value: string;
  onChange(newValue: string): void;
}

const MedicineSelector: FunctionComponent<MedicineSelectorProps> = ({
  value,
  onChange,
}) => {
  const { data: medicationType } = useGetMedicationsType();
  const medicationOptions = medicationType
    ? medicationType.map((medication) => ({
        value: medication.id,
        label: medication.name,
      }))
    : [];

  return (
    <Select
      options={medicationOptions}
      onChange={(newVal) => {
        onChange(newVal?.value ?? "");
      }}
    />
  );
};

export default MedicineSelector;
