import useSWRMutation from "swr/mutation";

import useSWR from "swr";
import { useSWRConfig } from "swr";
import { APIClient } from "services/api/client";

import {
  type SendMessageBody,
  type ChatMessageResponse,
  type AddChatResponse,
  AllUsersByIdsResponse,
} from "./types";

export const useAddChat = (users: string[]) => {
  if (!Array.isArray(users) || users.length < 2) {
    throw new Error("Invalid list of users for chat creation.");
  }

  return useSWR<AddChatResponse, Error>(
    [`/add-chat`, users],
    ([url, users]: [string, string[]]) => {
      return APIClient.post(url, {
        users: users,
      });
    },
    {
      isPaused: () => {
        return users.length !== 2 || users[1].trim() === "";
      },
      shouldRetryOnError: false,
    }
  );
};

export const useChatSendMessages = (chat_id: string) => {
  return useSWRMutation<any, ChatMessageResponse>(
    `/send-message/${chat_id}`,
    (url: string, { arg }: { arg: SendMessageBody }) => {
      return APIClient.post(url, {
        message: arg.message,
      });
    }
  );
};

export const useChatReadMessages = (chat_id: string) => {
  return useSWRMutation(
    `/read-all-message/${chat_id}`,
    (url: string, { arg }: { arg: any }) => APIClient.put(url, {})
  );
};

export const useFetchAllUsersByIds = (
  users: string[],
  setIsLoading: (isLoading: boolean) => void
) => {
  return useSWR<any, AllUsersByIdsResponse>(
    [`/users-by-ids`, users],
    ([url, users]: [string, string[]]) => {
      return APIClient.post(url, {
        users: users,
      });
    },
    {
      onSuccess: () => {
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      },
    }
  );
};

// MOCK
export const useChatDeleteMessage = (userId: string, messageId?: string) => {
  const { mutate } = useSWRConfig();

  return useSWRMutation<any, any>(
    `admin/chat/${userId}/delete/${messageId}`,
    (url: string) => {
      return APIClient.delete(url);
    },
    {
      onSuccess: (data) => {
        mutate(`admin/chat/${userId}/`, (cachedData: any) => {
          const updatedData = { ...cachedData };
          updatedData.messages = updatedData.messages.filter(
            (message: { messageId: string }) => message.messageId !== messageId
          );
          return updatedData;
        });
      },
    }
  );
};
