import React, {
  createContext,
  useState,
  useRef,
  useEffect,
  FunctionComponent,
} from "react";
import { io, Socket } from "socket.io-client";
import Peer from "simple-peer";
import { useMyCalls } from "features/Videocalls/hooks/useMyCalls";
import { useCalls } from "features/Videocalls/hooks/useCalls";
import { CallSocketContextType, CallType } from "features/Videocalls/api/types";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import VideoPlayer from "../VideoPlayer";

const SocketContext = createContext<CallSocketContextType>({
  answerCall: (roomId: string) => {},
  isCallIncoming: false,
  isCallOngoing: false,
  isCalling: false,
  callUser: () => {},
  endCallWithCustomStatus: (status: string) => {},
  endCall: () => {},
  declineCall: () => {},
  enableMic: () => {},
  disableMic: () => {},
  enableVideo: () => {},
  disableVideo: () => {},
  audioEnabled: false,
  videoEnabled: false,
  permissions: [],
});

//  const socket = io('https://warm-wildwood-81069.herokuapp.com');

interface ContextProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

const ContextProvider: FunctionComponent<ContextProviderProps> = ({
  children,
}) => {
  //   useEffect(() => {
  //     navigator.permissions
  //       .query({ name: "camera" })
  //       .then((permissionObj) => {
  //         alert("PERMISSION GRANTED");
  //       })
  //       .catch((error) => {
  //         alert("Got error :" + JSON.stringify(error));
  //       });

  //     navigator.permissions
  //       .query({ name: "audio" })
  //       .then((permissionObj) => {
  //         alert("PERMISSION GRANTED");
  //       })
  //       .catch((error) => {
  //         alert("Got error audio:" + JSON.stringify(error));
  //       });
  //   }, []);

  // const callUser = (id: string) => {
  //   connectInputDevices();
  //   const peer = new Peer({ initiator: true, trickle: false, stream });

  //   peer.on("signal", (data) => {
  //     socket?.emit("callUser", {
  //       userToCall: id,
  //       signalData: data,
  //       from: me,
  //       name,
  //     });
  //   });

  //   peer.on("stream", (currentStream) => {
  //     userVideo.current.srcObject = currentStream;
  //   });

  //   socket?.on("callAccepted", (signal) => {
  //     setCallAccepted(true);

  //     peer.signal(signal);
  //   });

  //   connectionRef.current = peer;
  // };

  const {
    currentCall,
    callUser,
    declineCall,
    answerCall,
    endCall,
    myVideo,
    userVideo,
    stream,
    isCallOngoing,
    isCalling,
    isCallIncoming,
    endCallWithCustomStatus,
    audioEnabled,
    videoEnabled,
    enableMic,
    enableVideo,
    disableMic,
    disableVideo,
    permissions,
  } = useCalls();

  return (
    <SocketContext.Provider
      value={{
        isCallOngoing,
        isCallIncoming,
        isCalling,
        call: currentCall,
        myVideo,
        userVideo,
        stream,
        callUser,
        endCall,
        endCallWithCustomStatus,
        answerCall,
        declineCall,
        audioEnabled,
        videoEnabled,
        enableMic,
        enableVideo,
        disableMic,
        disableVideo,
        permissions,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
