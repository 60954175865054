import { useLogin, useCheckLogin } from "features/login/api/client";
import { StatusResponseType } from "features/login/api/types";
import ExpiredWaitingPeriod from "features/login/components/ExpiredWaitingPeriod/ExpiredWaitingPeriod";
import LoadingComponent from "features/login/components/LoadingComponent";
import LogInViaBankId from "features/login/components/LogInViaBankId/LogInViaBankId";
import LoginPage from "pages/LoginPage/LoginPage";
import { FunctionComponent, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { useAdminStore } from "services/state/adminStore";
import { useTokenStore } from "services/state/tokenStore";
import { useUserStore } from "services/state/userStore";
import { useSWRConfig } from "swr";
import StaffBankIDAuth from "../StaffBankIDAuth";
import { Center, Heading, Spinner, VStack, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface LoginPageProps {}

const StaffBankidWrapped: FunctionComponent<LoginPageProps> = () => {
  const { access, refresh } = useTokenStore();
  const { mutate: forceFetchRefs } = useLogin();
  const loginData: StatusResponseType | undefined = useCheckLogin().data;
  const { isAdmin } = useAdminStore();
  const { isLoading, data } = useCheckLogin();
  const [status, setStatus] = useState<number>(0);
  const history = useHistory();
  const { state } = useLocation();
  const { mutate } = useSWRConfig();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      loginData?.code === 202 ||
      loginData?.code === 201 ||
      loginData?.code === 205
    ) {
      setStatus(loginData.code);
    }
  }, [loginData]);

  useEffect(() => {
    if (access !== "" && refresh !== "" && loginData) {
      if (loginData.data?.role === "doctor") {
        history.push("/doctor");
      }

      if (loginData.data?.role === "nurse") {
        history.push("/nurse");
      }

      if (loginData.data?.role === "superadmin") {
        history.push("/superadmin");
      }

      if (loginData.data && !loginData.data.role) {
        toast.error("You do not have permission to view these pages.");
        history.push("/");
      }
    }
  }, [access, refresh, loginData]);

  useEffect(() => {
    forceFetchRefs();
  }, []);

  return (
    <>
      {status === 0 && (
        <Center>
          <Spinner />
        </Center>
      )}
      {status === 201 && <StaffBankIDAuth />}
      {status === 205 && (
        <VStack>
          {" "}
          <Heading textAlign={{ base: "center" }}>
            {t("30 seconds waiting period has expired")}
          </Heading>
          <Text variant={"dark"}>{t("Please reload your page")}</Text>
        </VStack>
      )}
    </>
  );
};

export default StaffBankidWrapped;
