import {
  Flex,
  Text,
  Heading,
  Image,
  Box,
  Button,
  useDisclosure,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";

import arrow_left from "assets/icons/arrow_left.svg";
import MoodCheckup from "../adminMoodCheckup";

import AdminFullSchedule from "../adminFullSchedule";
import AdminUserDetails from "../adminUserDetails";
import AdminUserTreatment from "../adminUserTreatment";
import AdminUserMedication from "../adminUserMedication";
import { useAdminUserInfo } from "features/userInfo/api/client";
import { useTranslation } from "react-i18next";
import { statusColor, statusTextColor } from "features/admin/helper/columns";
import Loader from "features/admin/helper/Loader";
import {
  useApproveTreatmentPlan,
  useClearUserRequiest,
  useFetchUserTriageData,
} from "features/admin/api/client";

import { useContext, useState } from "react";
import NotificationForUser from "features/notifications/notificationForUser";
import { useUserActiveRequest } from "features/notifications/api/client";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { pdfjs } from "react-pdf";
import {
  invokeBasicInfoModal,
  invokeConfirmationModal,
} from "features/genericModal";
import { useModalStore } from "services/state/modalStore";
import DoctorRejectReasonModal from "../DoctorRejectResonModal";
import { CgNotes } from "react-icons/cg";
import { GoTasklist } from "react-icons/go";
import NotesDrawer from "../NotesDrawer";
import TriageModal from "../TriageModal";

import { BsTrash } from "react-icons/bs";

import IHRPDFModal from "../IHRPDFModal";
import PrescriptionToPharmacyPdfModal from "../PrescriptionToPharmacyPDFModal";
import { usePatchAdminUserProfile } from "features/dashboard/api/client";

import { BiPhoneCall, BiTestTube } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import AuthModal from "../notes2fa/components/AuthModal";
import { useTokenStore } from "services/state/tokenStore";
import CallModal from "features/Videocalls/components/CallModal";
import { SocketContext } from "features/Videocalls/components/VideocallContext";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface AdminUserInfoProps {}

const AdminUserInfo: React.FC<AdminUserInfoProps> = () => {
  const { callUser, answerCall } = useContext(SocketContext);

  const [close, setClose] = useState<boolean>(false);

  const {
    isOpen: isNotesDrawerOpen,
    onOpen: onNotesDrawerOpen,
    onClose: onNotesDrawerClose,
  } = useDisclosure();

  const {
    isOpen: isNotesAuthOpen,
    onOpen: onNotesAuthOpen,
    onClose: onNotesAuthClose,
  } = useDisclosure();

  const {
    isOpen: isTriageModalOpen,
    onOpen: onTriageModalOpen,
    onClose: onTriageModalClose,
  } = useDisclosure();

  const history = useHistory();
  const { auth2fa } = useTokenStore();

  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { data: userActive } = useUserActiveRequest(id ?? "");
  const { data: userData, error, isLoading } = useAdminUserInfo(id);
  const {
    onOpen: onModalOpen,
    setModalContent,
    setTitle: setModalTitle,
  } = useModalStore();

  const { data: typeFormData } = useFetchUserTriageData(id);

  const { trigger: patchUserProfile, isMutating: isPatchingProfile } =
    usePatchAdminUserProfile(id ?? "");

  const { trigger: approveTreatmentPlanRequest, isMutating: isApproving } =
    useApproveTreatmentPlan(id);

  const { trigger: clearUser, isMutating: isClearingUsser } =
    useClearUserRequiest(userData?.username ?? "", id ?? "");

  if (error) return <Text>Failed to load user info</Text>;

  const handleGoBack = () => {
    history.goBack();
  };

  const showRejectModal = () => {
    setModalTitle("Reject user treatment");
    setModalContent(<DoctorRejectReasonModal userId={id} />);

    onModalOpen();
  };

  const deleteUserData = () => {
    invokeConfirmationModal(
      t("Are you sure you want to delete this user's information"),
      () => {
        return clearUser().then(() => {
          invokeBasicInfoModal(
            <Text textAlign={"center"} color={"blue.900"}>
              {t("Account successfully cleared")}
            </Text>
          );
        });
      },
      "Warning!"
    );
  };

  const pendingDoctorsApproval =
    userData?.status === "prescription_completed" ||
    userData?.status === "meeting_with_doctor" ||
    userData?.status === "meeting_with_nurse";

  const trueStatus =
    userData?.status === "prescription_completed" &&
    !(userData.is_doctor_approve || userData.is_doctor_rejected)
      ? "triage_completed"
      : userData?.status;

  const handleUserTestMode = () => {
    patchUserProfile({ is_test_user: !userData?.is_test_user });
  };

  const handleOpenNotes = () => {
    if (auth2fa) {
      onNotesDrawerOpen();
    } else {
      onNotesAuthOpen();
    }
  };

  return (
    <>
      <Flex w="100%" direction="column" py="48px" gap={4}>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Flex gap={2} onClick={handleGoBack} cursor="pointer">
            <Image src={arrow_left} />
            <Text color="white" fontSize="20px">
              {t("Back")}
            </Text>
          </Flex>

          <Flex gap="24px">
            <IHRPDFModal />
            <PrescriptionToPharmacyPdfModal />
          </Flex>
        </Flex>

        {isLoading ? (
          <Loader />
        ) : (
          <Flex w="100%" align="center" justify="space-between">
            <Flex>
              <Heading
                color="white"
                fontSize={{ base: "2rem", lg: "2.5rem" }}
                fontWeight={500}
                maxW={"20rem"}
              >
                {userData?.name}
              </Heading>
              {/*TODO: REMOVE THIS ABOMINATION*/}
              <IconButton
                aria-label={""}
                icon={<BiPhoneCall />}
                minWidth={0}
                onClick={() => {
                  callUser(id);
                }}
              />
              {pendingDoctorsApproval && (
                <HStack ml={"2rem"}>
                  <Button
                    variant={"outline"}
                    px={"1rem"}
                    maxH={"3.5rem"}
                    _loading={{ p: "0" }}
                    fontSize={"14px"}
                    onClick={approveTreatmentPlanRequest}
                    isLoading={isApproving}
                  >
                    {t("Approve treatment plan")}
                  </Button>
                  <Button
                    variant={"outline"}
                    px={"1rem"}
                    maxH={"3.5rem"}
                    _loading={{ p: "0" }}
                    fontSize={"14px"}
                    onClick={showRejectModal}
                  >
                    {t("Reject treatment plan")}
                  </Button>
                </HStack>
              )}
            </Flex>
            <Flex align="center" gap="1rem">
              <Box bg={"white"} borderRadius={"0.5rem"} overflow={"hidden"}>
                <Box
                  bg={statusColor.find((e) => e.value === trueStatus)?.color}
                  color={
                    statusTextColor.find((e) => e.value === trueStatus)?.color
                  }
                  px={"1.25rem"}
                  py={"0.5rem"}
                  fontSize={{ base: "1rem", lg: "1.25rem" }}
                  fontWeight={700}
                  textTransform={"capitalize"}
                >
                  {t(userData?.status.replaceAll("_", " ") ?? "")}
                </Box>
              </Box>

              {userData && (
                <Flex gap={1}>
                  <Text
                    fontWeight={400}
                    fontSize={{ base: "1rem", lg: "1.25rem" }}
                  >
                    {t("Registered")}:
                  </Text>
                  <Text
                    display={"inline"}
                    fontSize={{ base: "1rem", lg: "1.25rem" }}
                    fontWeight={500}
                  >
                    {new Date(userData.status_changed_date).toLocaleDateString(
                      t("locale") ?? "en-GB",
                      { month: "short", day: "numeric", year: "numeric" }
                    )}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        )}

        {userActive && userActive.request && !close && (
          <NotificationForUser onClose={() => setClose(true)} />
        )}

        <MoodCheckup />

        <Flex w="100%" gap={4}>
          <AdminUserMedication />
          <AdminUserTreatment />
        </Flex>

        <AdminFullSchedule />

        <AdminUserDetails />

        <Flex justifyContent={"space-between"}>
          <Button
            variant={"outline"}
            leftIcon={userData?.is_test_user ? <FiUsers /> : <BiTestTube />}
            onClick={handleUserTestMode}
            isLoading={isPatchingProfile}
          >
            {userData?.is_test_user
              ? t("Set default user")
              : t("Set test account")}
          </Button>
          <Flex alignItems={"center"} justifyContent={"flex-end"} gap={"1rem"}>
            <Button
              leftIcon={<GoTasklist />}
              px={"2rem"}
              minWidth={"0"}
              onClick={onTriageModalOpen}
              disabled={typeFormData?.answers.length === 0}
            >
              {t("See triage")}
            </Button>
            <Button
              leftIcon={<CgNotes />}
              px={"2rem"}
              minWidth={"0"}
              onClick={handleOpenNotes}
            >
              {t("Open notes")}
            </Button>
            <Button
              leftIcon={<BsTrash />}
              variant={"outline"}
              px={"1.5rem"}
              maxH={"3.5rem"}
              isLoading={isClearingUsser}
              _loading={{ p: "0" }}
              onClick={() => {
                deleteUserData();
              }}
            >
              {t("Clear user")}
            </Button>
          </Flex>
        </Flex>

        <NotesDrawer isOpen={isNotesDrawerOpen} onClose={onNotesDrawerClose} />
        <TriageModal isOpen={isTriageModalOpen} onClose={onTriageModalClose} />
        {/* <NotesSection /> */}
        <AuthModal isOpen={isNotesAuthOpen} onClose={onNotesAuthClose} />
      </Flex>

      <CallModal
        isOpen={false}
        onClose={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
    </>
  );
};

export default AdminUserInfo;
