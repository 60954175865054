import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import { useAdminUserInfo } from "features/userInfo/api/client";
import { t } from "i18next";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FaExclamationCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { hexToRgb } from "services/helpers/colors";
import MoodOverviewItem from "../MoodOverviewItem";

interface PatientMoodOverviewProps {
  patientId?: string;
}

const PatientMoodOverview: FunctionComponent<PatientMoodOverviewProps> = ({
  patientId,
}) => {
  const { data: userProfile } = useFetchProfileInfo();
  const { id: paramId } = useParams<{ id: string }>();

  const [durationLimiter, setDurationLimiter] = useState<number>(
    userProfile?.role === "nurse" ? 2 : 4
  );
  const { t } = useTranslation();

  const id = patientId ?? paramId;

  const { data: patientData } = useAdminUserInfo(id);

  const filteredMoods = patientData?.mood.filter(
    (mood) =>
      Date.now() - new Date(mood.date).getTime() <
      durationLimiter * 7 * 24 * 60 * 60 * 1000
  );

  return (
    <Flex
      w="0"
      minW={"100%"}
      p="2rem"
      borderRadius="0.75rem"
      bg="white"
      direction="column"
    >
      <Flex
        w={"100%"}
        justifyContent={"space-between"}
        borderBottom={"1px solid rgba(88, 154, 175, 0.20)"}
        pb={"1rem"}
      >
        <Heading
          color="#589AAF"
          fontSize={"1.25rem"}
          fontWeight={600}
          textTransform={"uppercase"}
          fontFamily={"secondary"}
        >
          {t("Latest reported moods")}
        </Heading>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          border={"1px solid #E5E9EB"}
          borderRadius={"0.5rem"}
          overflow={"hidden"}
        >
          <Button
            variant={"ghost"}
            bg={durationLimiter === 2 ? "#589AAF1A" : "transparent"}
            fontWeight={durationLimiter === 2 ? 700 : 400}
            fontSize={"0.875rem"}
            color={durationLimiter === 2 ? "#589AAF" : "#0026377f"}
            h={"100%"}
            _hover={{}}
            borderRadius={0}
            onClick={() => {
              setDurationLimiter(2);
            }}
            opacity={durationLimiter === 2 ? 1 : 0.5}
          >
            2 {t("weeks")}
          </Button>
          <Divider
            orientation="vertical"
            borderColor={"#E5E9EB"}
            opacity={1}
            h={"2rem"}
          />
          <Button
            variant={"ghost"}
            bg={durationLimiter === 4 ? "#589AAF1A" : "transparent"}
            fontWeight={durationLimiter === 4 ? 700 : 400}
            fontSize={"0.875rem"}
            color={durationLimiter === 4 ? "#589AAF" : "#0026377f"}
            h={"100%"}
            borderRadius={0}
            _hover={{}}
            onClick={() => {
              setDurationLimiter(4);
            }}
            opacity={durationLimiter === 4 ? 1 : 0.5}
          >
            4 {t("weeks")}
          </Button>
        </Flex>
      </Flex>

      <HStack
        alignItems={"stretch"}
        w={"100%"}
        overflowX={"auto"}
        pt={"2.5rem"}
      >
        {filteredMoods?.map((moodReport) => (
          <MoodOverviewItem mood={moodReport} />
        ))}

        {filteredMoods?.length === 0 && (
          <Center w={"100%"} py={"4rem"} fontWeight={500} opacity={'0.3'}>
            {t("No reported moods here yet")}
          </Center>
        )}
      </HStack>
    </Flex>
  );
};

export default PatientMoodOverview;
