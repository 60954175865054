import axios, { AxiosError, AxiosResponse } from "axios";
import { useTokenStore } from "services/state/tokenStore";

export const APIClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const UninterceptedAPIClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

APIClient.interceptors.request.use((config) => {
  const { access } = useTokenStore.getState();
  return {
    ...config,
    headers: {
      authorization: access !== "" ? `Bearer ${access}` : undefined,
    },
  };
});

APIClient.interceptors.response.use(
  ({ data }) => data,
  (error: AxiosError) => Promise.reject(error)
);

export const OnlyResponseAPIClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

OnlyResponseAPIClient.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => Promise.reject(error)
);
