import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Center,
  Button,
} from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import DoctorsTable from "../DoctorsTable";
import NurseTable from "../NurseTable";
import PeriodFilter from "../PeriodFilter";

interface UserListsProps {}

const UserLists: FunctionComponent<UserListsProps> = () => {
  const { t } = useTranslation();

  const [dateFilter, setDateFilter] = useState<{ from?: Date; to?: Date }>({
    from: undefined,
    to: undefined,
  });

  return (
    <Box
      bg={"white"}
      borderRadius={"8px"}
      p={{ base: "1rem", sm: "2rem" }}
      mt={"3rem !important"}
    >
      <Tabs>
        <TabList>
          <Tab fontSize={{ base: ".8rem", sm: "1.25rem" }}>{t("Doctors")}</Tab>
          <Tab fontSize={{ base: ".8rem", sm: "1.25rem" }}>{t("Nurses")}</Tab>

          <Center marginLeft={"auto"} pb={"0.5rem"}>
            <PeriodFilter
              value={dateFilter}
              onChange={(newDates) => {
                setDateFilter({ ...newDates });
              }}
            />
            {(dateFilter.from || dateFilter.to) && (
              <Button
                variant={"ghost"}
                opacity={0.5}
                fontWeight={"400"}
                fontSize={"1rem"}
                px={"1.5rem"}
                onClick={() => {
                  setDateFilter({ from: undefined, to: undefined });
                }}
              >
                {t("Clear")}
              </Button>
            )}
          </Center>
        </TabList>

        <TabPanels>
          <TabPanel>
            <DoctorsTable
              filters={{
                date: dateFilter,
              }}
            />
          </TabPanel>
          <TabPanel>
            <NurseTable
              filters={{
                date: dateFilter,
              }}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default UserLists;
