import { Button, Flex, Heading, Text, Image, Spacer } from "@chakra-ui/react";
import order_pattern from "assets/images/order_pattern.png";

import QR from "assets/images/QR.svg";
import { useTranslation } from "react-i18next";

const OrderCard = () => {
  const { t } = useTranslation();
  return (
    <Flex
      direction="column"
      bg="blueish.500"
      p="40px"
      borderRadius="12px"
      align="flex-start"
      position={"relative"}
      w="100%"
      gap="52px"
    >
      <Image
        alt={"Decorative background patterns"}
        src={order_pattern}
        position={"absolute"}
        right={0}
        top={0}
        bottom={0}
        h={"100%"}
      />
      <Flex direction="column" justify="space-between" gap="4px">
        <Heading color="white" fontSize="28px" fontWeight={500}>
          {t("Your order has been delivered.")}
        </Heading>
        <Text fontSize="16px">
          {t(
            "Do not forget to Start your plan in the app to begin tracking the medication taking or use a schedule on your Dashboard."
          )}
        </Text>
      </Flex>

      <Flex direction="column" gap="16px">
        <Text fontSize="20px" fontWeight={500}>
          {t("Get the app:")}
        </Text>
        <Image src={QR} alt={"QR code to get the app"} />
      </Flex>
    </Flex>
  );
};

export default OrderCard;
