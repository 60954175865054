import {
  Checkbox,
  Flex,
  Heading,
  VStack,
  Text,
  Box,
  Button,
  useDisclosure,
  Center,
} from "@chakra-ui/react";
import { useUserListFiltered } from "features/admin/api/client";
import { UserInfo } from "features/admin/api/types";
import PatientMood from "features/admin/components/patientMood";
import { FunctionComponent, useEffect, useState } from "react";
import { TableHeaderItemType } from "ui/components/AdminTable/api/types";
import Table from "ui/components/AdminTable/components/Table";
import AppUsage from "./components/AppUsage";
import DoctorsComponent from "./components/DoctorsComponent";
import MoodComponent from "./components/MoodComponent";
import NurseComponent from "./components/NurseComponent";
import Pagination from "./components/Pagination";
import SelectedPatientsPopover from "./components/SelectedPatientsPopover";
import StatusComponent from "./components/StatusComponent";
import { RiFilterLine } from "react-icons/ri";
import FilterDrawer from "./components/FilterDrawer";
import { useURLParameters } from "services/hooks/useURLParameters";
import { m } from "framer-motion";
import { paraget } from "services/helpers/paraget";
import { useHistory, useLocation } from "react-router-dom";
import { useURLParametrize } from "services/hooks/useUrlParametrize";
import SortableColumnHeader from "./components/SortableColumnHeader";
import SearchPatients from "./components/SearchPatients";
import { useTranslation } from "react-i18next";
import { paymentStatusTransform } from "features/staffCommons/services/consts";

interface PatientsTableProps {}

const PatientsTable: FunctionComponent<PatientsTableProps> = () => {
  /* ------------DATA SECTION-------- */
  const p = useURLParameters();
  const initialPage = paraget(p, "page", "number");
  const initialOrder = paraget(p, "order", "string");

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showClearAll, setShowClearAll] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const urlFilters = {
    status: paraget(p, "status", "array"),
    appUsage: paraget(p, "appUsage"),
    currentDoseFrom: paraget(p, "currentDoseFrom", "number"),
    currentDoseTo: paraget(p, "currentDoseTo", "number"),
    medicineType: paraget(p, "medicineType", "array"),
    medication_id: paraget(p, "medication_id", "array"),
    mood: paraget(p, "mood", "array_numeric"),
    name: paraget(p, "name", "array"),
    selectedDoctors: paraget(p, "selectedDoctors", "array"),
    startingDosageFrom: paraget(p, "startingDosageFrom", "number"),
    taperingPlanDurationFrom: paraget(p, "taperingPlanDurationFrom", "number"),
    taperingPlanDurationTo: paraget(p, "taperingPlanDurationTo", "number"),
    startingDosageTo: paraget(p, "startingDosageTo", "number"),
    excludeTestUsers: false,
    nurses: paraget(p, "nurses", "array"),
    unassigned: paraget(p, "unassigned"),
    is_archived: paraget(p, "is_archived"),
    is_declined: paraget(p, "is_declined"),
  };

  const [sorting, setSorting] = useState<{ column: string; order: string }>({
    column: initialOrder?.split(",")[0] ?? "",
    order: initialOrder?.split(",")[1] ?? "",
  });

  const sortingTransform: { [key: string]: any } = {
    asc: "",
    desc: "-",
  };

  const { parametersAppendix } = useURLParametrize(urlFilters);

  const filtersApplied = Object.values(urlFilters).some((e) => e);

  const { data: patientsData, isLoading } = useUserListFiltered(
    currentPage,
    pageSize,
    {
      status: urlFilters.status?.join(","),
      appUsage: urlFilters.appUsage ?? "",
      currentDoseFrom: urlFilters.currentDoseFrom,
      currentDoseTo: urlFilters.currentDoseTo,
      medicineType: urlFilters.medicineType?.join(","),
      medication_id: urlFilters.medication_id?.join(","),
      mood: urlFilters.mood?.map((e) => e.toString()),
      name: urlFilters.name?.join(",") || searchQuery,
      selectedDoctors: urlFilters.selectedDoctors?.join(","),
      startingDosageFrom: urlFilters.startingDosageFrom,
      startingDosageTo: urlFilters.startingDosageTo,
      excludeTestUsers: false,
      nurses: urlFilters.nurses?.join(","),
      taperingPlanDurationFrom: urlFilters.taperingPlanDurationFrom
        ? urlFilters.taperingPlanDurationFrom / 4
        : undefined,
      taperingPlanDurationTo: urlFilters.taperingPlanDurationTo
        ? urlFilters.taperingPlanDurationTo / 4
        : 0,
      unassigned: urlFilters.unassigned,
      sorting: sorting.order
        ? sortingTransform[sorting.order] + sorting.column
        : undefined,
      is_archived: urlFilters.is_archived,
      is_declined: urlFilters.is_declined,
    }
  );

  const [selectedPatients, setSelectedPatients] = useState<UserInfo[]>([]);

  const selectedPatientsIds = selectedPatients.map((e) => e.id);

  const isAllPagePatientsSelected = patientsData?.results
    .map((e) => e.id)
    .every((e) => selectedPatientsIds.includes(e));
  /* ----------END OF DATA SECTION------- */

  /* ------------UI STATE SECTION-------- */
  const {
    isOpen: isFilterDrawerOpen,
    onClose: onFilterDrawerClose,
    onOpen: onFilterDrawerOpen,
  } = useDisclosure();
  /* -------END OF UI STATE SECTION------ */

  /* ---------UI SERVICES SECTION-------- */
  const toggleCheckEveryItemOnThePage = () => {
    if (isAllPagePatientsSelected) {
      setSelectedPatients(
        selectedPatients.filter(
          (e) => !patientsData?.results.map((e) => e.id).includes(e.id)
        )
      );
    } else {
      setSelectedPatients([
        ...selectedPatients,
        ...(patientsData?.results.filter(
          (e) => !selectedPatientsIds.includes(e.id)
        ) ?? []),
      ]);
    }
  };
  const columns: TableHeaderItemType[] = [
    {
      name: "checkbox",
      title: (
        <Checkbox
          variant={"admin"}
          isChecked={isAllPagePatientsSelected}
          onChange={toggleCheckEveryItemOnThePage}
        />
      ),
      type: "string",
    },
    {
      name: "name",
      title: (
        <SortableColumnHeader
          label={t("Name")}
          name="name"
          order={sorting.column === "first_name" ? sorting.order : ""}
          onChange={(order) => {
            setSorting({
              column: "first_name",
              order: order ?? "",
            });
          }}
        />
      ),
      type: "string",
      style: {
        borderRight: "1px solid #589AAF33",
        minW: "20rem",
      },
      columnStyle: {
        borderRight: "1px solid #589AAF33",
        minW: "20rem",
      },
    },

    /*  {
      name: "status",
      title: (
        <SortableColumnHeader
          label={t("Status")}
          name="status"
          order={sorting.column === "status" ? sorting.order : ""}
          onChange={(order) => {
            setSorting({
              column: "status",
              order: order ?? "",
            });
          }}
        />
      ),
      type: "component",
    }, */

    {
      name: "payment_status",
      title: (
        <SortableColumnHeader
          label={t("PAYMENT STATUS")}
          name="medicine_type"
          order={sorting.column === "payment_status" ? sorting.order : ""}
          onChange={(order: any) => {
            setSorting({
              column: "payment_status",
              order: order ?? "",
            });
          }}
        />
      ),
      type: "string",
    },
    {
      name: "doctor",
      title: (
        <SortableColumnHeader
          label={t("Doctor")}
          name="doctors"
          order={sorting.column === "doctors" ? sorting.order : ""}
          onChange={(order) => {
            setSorting({
              column: "doctors",
              order: order ?? "",
            });
          }}
        />
      ),
      type: "component",
      style: {
        minWidth: "12rem",
      },
      columnStyle: {
        minWidth: "12rem",
      },
    },
    {
      name: "nurse",
      title: (
        <SortableColumnHeader
          label={t("Nurses")}
          name="nurses"
          order={sorting.column === "nurses" ? sorting.order : ""}
          onChange={(order) => {
            setSorting({
              column: "nurses",
              order: order ?? "",
            });
          }}
        />
      ),
      type: "component",
      style: {
        minWidth: "12rem",
      },
      columnStyle: {
        minWidth: "12rem",
      },
    },
    {
      name: "mood",
      title: (
        <SortableColumnHeader
          label={t("MOOD")}
          name="mood"
          order={sorting.column === "mood" ? sorting.order : ""}
          onChange={(order) => {
            setSorting({
              column: "mood",
              order: order ?? "",
            });
          }}
        />
      ),
      type: "component",
      style: {
        minWidth: "10rem",
      },
      columnStyle: {
        minWidth: "10rem",
      },
    },
    {
      name: "medication_type",
      title: (
        <SortableColumnHeader
          label={t("MEDICATION TYPE")}
          name="medicine_type"
          order={sorting.column === "medicine_type" ? sorting.order : ""}
          onChange={(order) => {
            setSorting({
              column: "medicine_type",
              order: order ?? "",
            });
          }}
        />
      ),
      type: "string",
    },

    {
      name: "tapering_plan",
      title: (
        <SortableColumnHeader
          label={t("TAPERING PLAN")}
          name="treatment_duration"
          order={sorting.column === "treatment_duration" ? sorting.order : ""}
          onChange={(order) => {
            setSorting({
              column: "treatment_duration",
              order: order ?? "",
            });
          }}
        />
      ),
      type: "string",
    },
    {
      name: "starting_dose",
      title: (
        <SortableColumnHeader
          label={t("STARTING DOSE")}
          name="dose"
          order={sorting.column === "dose" ? sorting.order : ""}
          onChange={(order) => {
            setSorting({
              column: "dose",
              order: order ?? "",
            });
          }}
        />
      ),
      type: "string",
    },
    {
      name: "current_dose",
      title: (
        <SortableColumnHeader
          label={t("CURRENT DOSE")}
          name="current_dose"
          order={sorting.column === "current_dose" ? sorting.order : ""}
          onChange={(order) => {
            setSorting({
              column: "current_dose",
              order: order ?? "",
            });
          }}
        />
      ),
      type: "string",
    },

    {
      name: "app",
      title: (
        <SortableColumnHeader
          label="App"
          name="app_usage"
          order={sorting.column === "app_usage" ? sorting.order : ""}
          onChange={(order) => {
            setSorting({
              column: "app_usage",
              order: order ?? "",
            });
          }}
        />
      ),
      type: "component",
    },
  ];

  const toggleCheckSingleItem = (newPatient: UserInfo) => {
    if (selectedPatientsIds.includes(newPatient.id)) {
      setSelectedPatients(
        selectedPatients.filter((e) => e.id !== newPatient.id)
      );
    } else {
      setSelectedPatients([...selectedPatients, newPatient]);
    }
  };

  const transformFunction = (e: UserInfo) => {
    const currentPatientId = e.id;
    return {
      checkbox: (
        <Checkbox
          variant={"admin"}
          isChecked={selectedPatientsIds.includes(e.id)}
          onChange={() => {
            toggleCheckSingleItem(e);
          }}
        />
      ),
      payment_status: (
        <Box
          borderRadius={"4px"}
          p={"0.25rem"}
          bg={"#96BFCC33"}
          px={"0.5rem"}
          fontWeight={"600"}
          fontSize={"0.75rem"}
        >
          {t(paymentStatusTransform(e.payment_status))}
        </Box>
      ),
      name: (
        <Flex w="full" justifyContent="space-between">
          <Text
            color={"blueish.900"}
            fontWeight={700}
            fontSize={"1rem"}
            cursor={"pointer"}
            onClick={() => {
              history.push("/superadmin/" + e.id);
            }}
          >
            {e.first_name + " " + e.last_name}
          </Text>

          {e.is_stabilize === true && (
            <Text color="blueish.500" fontSize="0.8rem" fontWeight={600}>
              {t("Stabilized")}
            </Text>
          )}
          {e.is_test_user && (
            <Center
              fontSize={"0.5rem"}
              bg={"#00263710"}
              px={"0.5rem"}
              borderRadius={"0.5rem"}
            >
              TEST USER
            </Center>
          )}
        </Flex>
      ),
      medication_type: e.medication?.name,
      doctor: (
        <DoctorsComponent ids={e.doctors ?? []} userId={currentPatientId} />
      ),
      nurse: <NurseComponent ids={e.nurses ?? []} userId={currentPatientId} />,
      tapering_plan: e.treatment_duration
        ? `${e.treatment_duration * 4} ${t("weeks")}`
        : t("not set"),
      starting_dose: (e.dose ?? "0") + " mg",
      current_dose: (e.current_dose ?? "0") + " mg",
      mood: (
        <MoodComponent
          moodValues={e.mood.map((e) => {
            return e.value;
          })}
        />
      ),
      app: <AppUsage userInfo={e} />,
      status: <StatusComponent userInfo={e} />,
    };
  };

  useEffect(() => {
    if (!parametersAppendix || parametersAppendix === "") {
      return;
    }
    let orderAppendage = "";

    if (sorting.order !== "") {
      orderAppendage = "&order=" + sorting.column + "," + sorting.order;
    }

    history.push(
      location.pathname +
        "?page=" +
        currentPage +
        orderAppendage +
        parametersAppendix.replace("?", "&")
    );
  }, [currentPage, sorting, parametersAppendix]);

  useEffect(() => {
    setCurrentPage(initialPage ?? 1);
  }, [filtersApplied]);

  /* ------END OF UI SERVICES SECTION----- */

  /* ----------METHODS SECTION------------ */
  const clearAllFilters = () => {
    const queryParams = new URLSearchParams(location.search);

    setSearchQuery("");
    queryParams.delete("name");

    setShowClearAll(false);

    history.push(location.pathname);
  };

  useEffect(() => {
    const hasFilters = Object.values(urlFilters).some((e) => e);
    const hasSearchQuery = searchQuery.trim() !== "";

    if (hasSearchQuery) {
      setCurrentPage(1);
    }

    setShowClearAll(hasFilters || hasSearchQuery);
  }, [urlFilters, searchQuery]);

  /* ----------END OF METHODS SECTION----- */

  const handleSearchInputChange = (value: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("name", value);
    history.push({ search: queryParams.toString() });

    setSearchQuery(value);
  };

  return (
    <VStack w={"100%"} alignItems={"stretch"} pb={"5rem"}>
      <Flex
        justifyContent={"space-between"}
        pb="1rem"
        py="1rem"
        direction={{ base: "column", sm: "row" }}
      >
        <Heading
          textTransform={"uppercase"}
          fontWeight={"600"}
          fontSize={"1.5rem"}
          color={"blueish.900"}
        >
          {t("Number of patients")}
        </Heading>

        <Flex alignItems={"center"} gap="1rem">
          <SearchPatients
            value={searchQuery}
            onChange={(value) => handleSearchInputChange(value)}
          />

          {showClearAll && (
            <Button
              variant={"transparent"}
              textDecor={"underline"}
              color={"#D5738A"}
              fontWeight={"400"}
              onClick={clearAllFilters}
            >
              {t("Clear All")}
            </Button>
          )}

          <Button
            variant={"unstyled"}
            bg={"#002637"}
            color={"white"}
            py={"0.9rem"}
            px={"1.7rem !important"}
            h={"auto"}
            w={"fit-content"}
            onClick={onFilterDrawerOpen}
            position={"relative"}
          >
            <Flex alignItems={"center"} gap={"0.5rem"}>
              <RiFilterLine fontSize={"1.2rem"} />

              <Text
                fontSize={"1rem"}
                verticalAlign={"center"}
                lineHeight={"1.2rem"}
                pt={"4.5%"}
              >
                {t("Filter")}
              </Text>
            </Flex>
            {filtersApplied && (
              <Box
                position={"absolute"}
                h={"4px"}
                w={"4px"}
                borderRadius={"50%"}
                bg={"red"}
                top={"1rem"}
                right={"1rem"}
              ></Box>
            )}
          </Button>
        </Flex>
      </Flex>

      <Table
        columns={columns}
        rows={patientsData?.results.map(transformFunction)}
        isLoading={isLoading}
      />
      <Box pt={"2rem"}>
        <Pagination
          currentPage={currentPage}
          onPageChange={(newPage) => {
            setCurrentPage(newPage);
          }}
          numberOfPages={Math.ceil((patientsData?.count ?? 0) / pageSize) ?? 0}
        />
      </Box>
      <SelectedPatientsPopover
        patients={selectedPatients}
        onManualClose={() => {
          setSelectedPatients([]);
        }}
      />
      <FilterDrawer isOpen={isFilterDrawerOpen} onClose={onFilterDrawerClose} />
    </VStack>
  );
};

export default PatientsTable;
