import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  Button,
  Flex,
  Heading,
  IconButton,
  VStack,
  useHighlight,
  Text,
  useDisclosure,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { PatientsFilterType } from "features/superadmin/components/PatientsTable/components/FilterDrawer/api/types";
import ArchivedCheck from "features/superadmin/components/PatientsTable/components/FilterDrawer/components/ArchivedCheck";
import CurrentDoseInput from "features/superadmin/components/PatientsTable/components/FilterDrawer/components/CurrentDoseInput";
import DeclinedCheck from "features/superadmin/components/PatientsTable/components/FilterDrawer/components/DeclinedCheck";
import DoctorSelector from "features/superadmin/components/PatientsTable/components/FilterDrawer/components/DoctorSelector";
import MedicationType from "features/superadmin/components/PatientsTable/components/FilterDrawer/components/MedicationType";
import MoodFilter from "features/superadmin/components/PatientsTable/components/FilterDrawer/components/MoodFilter";
import NurseSelector from "features/superadmin/components/PatientsTable/components/FilterDrawer/components/NurseSelector";
import StartingDoseInput from "features/superadmin/components/PatientsTable/components/FilterDrawer/components/StartingDoseInput";
import StatusSelect from "features/superadmin/components/PatientsTable/components/FilterDrawer/components/StatusSelector";
import TreatmentDurationSlider from "features/superadmin/components/PatientsTable/components/FilterDrawer/components/TreatmentDurationSlider";
import UnassignedFilter from "features/superadmin/components/PatientsTable/components/FilterDrawer/components/UnassignedFilter";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrClose } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import { useHistory, useLocation } from "react-router-dom";
import { paraget } from "services/helpers/paraget";
import { useURLParameters } from "services/hooks/useURLParameters";
import { useURLParametrize } from "services/hooks/useUrlParametrize";
import { BaseDisclosureType } from "services/types/types";
import SearchIcon from "ui/iconComponents/SearchIcon";

interface NurseFilterProps extends BaseDisclosureType {}

const NurseFilter: FunctionComponent<NurseFilterProps> = ({
  isOpen,
  onClose = () => {},
}) => {
  /* ------------DATA SECTION-------- */
  const p = useURLParameters();
  const { t } = useTranslation();
  const {
    isOpen: isBackdropOpen,
    onClose: onBackdropClose,
    onOpen: onBackdropOpen,
  } = useDisclosure();

  const [urlFilters, setUrlFilters] = useState<any>();

  useEffect(() => {
    setUrlFilters({
      status: paraget(p, "status", "array"),
      appUsage: paraget(p, "appUsage"),
      currentDoseFrom: paraget(p, "currentDoseFrom", "number"),
      currentDoseTo: paraget(p, "currentDoseTo", "number"),
      medicineType: paraget(p, "medicineType", "array"),
      medication_id: paraget(p, "medication_id", "array"),
      mood: paraget(p, "mood", "array_numeric"),
      name: paraget(p, "name", "array"),
      selectedDoctors: paraget(p, "selectedDoctors", "array"),
      startingDosageFrom: paraget(p, "startingDosageFrom", "number"),
      startingDosageTo: paraget(p, "startingDosageTo", "number"),
      taperingPlanDurationFrom: paraget(
        p,
        "taperingPlanDurationFrom",
        "number"
      ),
      taperingPlanDurationTo: paraget(p, "taperingPlanDurationTo", "number"),
      excludeTestUsers: false,
      nurses: paraget(p, "nurses", "array"),
      is_declined: paraget(p, "is_declined"),
    });
  }, [isOpen]);

  const history = useHistory();
  const location = useLocation();
  const [filters, setFilters] = useState<PatientsFilterType>({
    unassigned: "showAll",
    excludeTestUsers: false,
    medicineType: [],
    medication_id: [],
    mood: [],
    name: [],
    status: [],
    nurses: [],
    selectedDoctors: [],
    appUsage: "",
    is_archived: "unarchived",
    is_declined: "",
  });

  useEffect(() => {
    setFilters({
      unassigned: "showAll",
      appUsage: urlFilters?.appUsage ?? "",
      excludeTestUsers: false,
      medicineType: urlFilters?.medicineType ?? [],
      medication_id: urlFilters?.medication_id ?? [],
      mood: urlFilters?.mood ?? [],
      name: urlFilters?.name ?? [],
      status: urlFilters?.status ?? [],
      nurses: urlFilters?.nurses ?? [],
      selectedDoctors: urlFilters?.selectedDoctors ?? [],
      currentDoseFrom: urlFilters?.currentDoseFrom,
      currentDoseTo: urlFilters?.currentDoseTo,
      startingDosageFrom: urlFilters?.currentDoseFrom,
      startingDosageTo: urlFilters?.currentDoseTo,
      taperingPlanDurationFrom: urlFilters?.taperingPlanDurationFrom,
      taperingPlanDurationTo: urlFilters?.taperingPlanDurationTo,
      is_declined:
        urlFilters?.is_declined === "" ? "all" : urlFilters?.is_declined ?? "",
      is_archived: "unarchived",
    });
  }, [urlFilters]);

  const filtersApplied = Object.values(filters).some((element) => {
    if (!element) {
      return false;
    }

    if (Array.isArray(element) && element.length === 0) {
      return false;
    }

    if (element === "") return false;

    return true;
  });

  const { parametersAppendix } = useURLParametrize(filters);

  /* ------END OF DATA SECTION-------- */

  const pushFiltersToUrl = () => {
    if (location && parametersAppendix) {
      history.push(
        location.pathname + "?page=1" + parametersAppendix.replace("?", "&")
      );
    }
  };

  const clearFilterUrl = () => {
    history.push(location.pathname);
  };

  const clearAll = () => {
    setFilters({
      unassigned: "showAll",
      excludeTestUsers: false,
      medicineType: [],
      mood: [],
      name: [],
      status: [],
      nurses: [],
      selectedDoctors: [],
      appUsage: "",
      is_archived: "unarchived",
      is_declined: "not_declined",
      medication_id: [],
    });

    clearFilterUrl();
  };

  useEffect(() => {
    onBackdropOpen();
  }, [filters]);

  const applyFilter = () => {
    pushFiltersToUrl();
    onBackdropClose();
  };

  useEffect(() => {
    const enterHandler = (event: KeyboardEvent) => {
      if (event.code === "Enter") {
        applyFilter();
      }
    };

    document.addEventListener("keypress", enterHandler);

    return () => {
      document.removeEventListener("keypress", enterHandler);
    };
  }, [filters]);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay
        transition={"all 0.5s ease-out"}
        opacity={!isBackdropOpen ? "0 !important" : "1 !important"}
      />
      <DrawerContent bg={"blueish.900"} minW={"26rem"} color={"white"}>
        <DrawerHeader pt={"2rem"} mb={"1rem"}>
          <Flex
            w={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Flex alignItems={"center"} gap={"1.5rem"}>
              <IconButton
                variant={"unstyled"}
                aria-label={""}
                icon={<IoClose color={"white"} fontSize={"1.8rem"} />}
                minWidth={"0"}
                minHeight={"0"}
                onClick={() => {
                  onClose();
                }}
              />
              <Heading color={"inherit"} fontSize={"2rem"}>
                {t("Filter")}
              </Heading>
            </Flex>

            <Button
              variant={"transparent"}
              textDecor={"underline"}
              fontSize={"1rem"}
              fontWeight={"500"}
              opacity={"0.5"}
              p={"0 !important"}
              onClick={() => {
                clearAll();
              }}
            >
              {t("Clear all")}
            </Button>
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          <VStack gap={"1rem"} alignItems={"flex-start"}>
            <DeclinedCheck
              value={filters.is_declined}
              onChange={(value) => {
                setFilters({ ...filters, is_declined: value });
              }}
            />
            <FormControl>
              <FormLabel
                fontSize="0.875rem"
                fontWeight={600}
                color="rgba(255, 255, 255, 1)"
              >
                {t("SEARCH BY NAME")}
              </FormLabel>

              <InputGroup
                border="1px solid rgba(255, 255, 255, 0.5)"
                bg="rgba(255, 255, 255, 0.1)"
                borderRadius="0.5rem"
                alignItems={"center"}
                px={"2rem"}
                py={"0.5rem"}
              >
                <InputLeftElement left={"0.5rem"} top={"0.5rem"}>
                  <SearchIcon />
                </InputLeftElement>
                <Input
                  bg={"transparent !important"}
                  color="white"
                  minH={"0 !important"}
                  fontWeight={500}
                  opacity={0.8}
                  fontSize={{ base: "1rem", lg: "md" }}
                  autoFocus
                  placeholder={"Skriv…"}
                  _placeholder={{
                    color: "white",
                    opacity: 1,
                    fontWeight: 400,
                  }}
                  value={filters.name[0]}
                  onChange={(e) => {
                    setFilters({ ...filters, name: [e.target.value] });
                  }}
                />
              </InputGroup>
            </FormControl>
            <MedicationType
              value={filters.medicineType}
              onChange={(newFilters) => {
                setFilters({ ...filters, medicineType: newFilters });
              }}
            />

            <MoodFilter
              value={filters.mood}
              onChange={(moods) => {
                setFilters({ ...filters, mood: moods });
              }}
            />
            <StatusSelect
              value={filters.status}
              onChange={(newStatus) => {
                setFilters({ ...filters, status: newStatus });
              }}
            />

            <TreatmentDurationSlider
              durations={{
                from: filters.taperingPlanDurationFrom,
                to: filters.taperingPlanDurationTo,
              }}
              onChange={(newValues) => {
                setFilters({
                  ...filters,
                  taperingPlanDurationFrom: newValues.from,
                  taperingPlanDurationTo: newValues.to,
                });
              }}
            />

            <StartingDoseInput
              from={filters.startingDosageFrom}
              to={filters.startingDosageTo}
              setFrom={(newFrom) => {
                setFilters({ ...filters, startingDosageFrom: newFrom });
              }}
              setTo={(newTo) => {
                setFilters({ ...filters, startingDosageTo: newTo });
              }}
            />

            <CurrentDoseInput
              from={filters.currentDoseFrom}
              to={filters.currentDoseTo}
              setFrom={(newFrom) => {
                setFilters({ ...filters, currentDoseFrom: newFrom });
              }}
              setTo={(newTo) => {
                setFilters({ ...filters, currentDoseTo: newTo });
              }}
            />
          </VStack>
        </DrawerBody>

        <DrawerFooter
          alignItems={"center"}
          justifyContent={"center"}
          bg={filtersApplied ? "#EEE8A9" : "#7e949e"}
          cursor={"pointer"}
          onClick={applyFilter}
        >
          <Text
            color={"blueish.900"}
            opacity={filtersApplied ? 1 : 0.5}
            fontWeight={"700"}
            fontSize={"1.125rem"}
          >
            {t("Apply Filters")}
          </Text>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default NurseFilter;
