import { useFetchProfileInfo } from "features/dashboard/api/client";
import { useHistory } from "react-router-dom";
import { APIClient } from "services/api/client";
import useSWRMutation from "swr/mutation";

export const useSendPushToken = () => {
  const { data } = useFetchProfileInfo();

  return useSWRMutation<any, any>(
    "/profile/" + data?.id + "/",
    (url: string, { arg }: { arg: { token: string } }) => {
      return APIClient.patch(url, {
        firebase_token: arg.token,
      });
    }
  );
};

export const useDeletePushToken = () => {
  const { data } = useFetchProfileInfo();

  return useSWRMutation<any, any>(
    "/profile/" + data?.id + "/",
    (url: string) => {
      return APIClient.patch(url, {
        firebase_token: null,
      });
    }
  );
};
