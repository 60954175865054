import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import { PartialNewUserType } from "features/superadmin/api/types";
import { newUserValidationSchema } from "features/superadmin/services/validationSchemas";
import { useFormik } from "formik";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface StaffFormProps {
  onGetPartialUserData(partialData: PartialNewUserType): void;
  role: string;
}

const StaffForm: FunctionComponent<StaffFormProps> = ({
  onGetPartialUserData,
  role,
}) => {
  const { t } = useTranslation();

  const formik = useFormik<PartialNewUserType>({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      pnr: "",
      medical_code: role === "doctor" ? "" : "NURSE_CODE",
      hospital_name: "LAMINACLINIC",
      hospital_address: "LAMINACLINIC_ADDRESS",
    },
    onSubmit: (data) => {
      onGetPartialUserData(data);
    },
    validationSchema: newUserValidationSchema,
  });

  return (
    <VStack gap={"2rem"} alignItems={"stretch"} w={"100%"}>
      <Flex gap={"1rem"} w={"100%"}>
        <FormControl isInvalid={!!formik.errors.first_name} h="80px">
          <FormLabel
            fontWeight={600}
            fontSize={{ base: "12px", lg: "14px" }}
            color="rgba(0, 38, 55, 1)"
            fontFamily="secondary"
          >
            {t("First name").toUpperCase()}
          </FormLabel>
          <Input
            id="first_name"
            name="first_name"
            placeholder="Enter user's last name"
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "16px",
            }}
            onChange={formik.handleChange}
            value={formik.values.first_name}
          />

          <FormErrorMessage color="red" fontSize="14px">
            {formik.errors.first_name}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formik.errors.last_name} h="80px">
          <FormLabel
            fontWeight={600}
            fontSize={{ base: "12px", lg: "14px" }}
            color="rgba(0, 38, 55, 1)"
            fontFamily="secondary"
          >
            {t("Last name").toUpperCase()}
          </FormLabel>
          <Input
            id="last_name"
            name="last_name"
            placeholder="Enter user's last name"
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "16px",
            }}
            onChange={formik.handleChange}
            value={formik.values.last_name}
          />

          <FormErrorMessage color="red" fontSize="14px">
            {formik.errors.last_name}
          </FormErrorMessage>
        </FormControl>
      </Flex>

      <FormControl isInvalid={!!formik.errors.email} h="80px">
        <FormLabel
          fontWeight={600}
          fontSize={{ base: "12px", lg: "14px" }}
          color="rgba(0, 38, 55, 1)"
          fontFamily="secondary"
        >
          EMAIL
        </FormLabel>
        <Input
          id="email"
          name="email"
          placeholder="Enter user's email"
          _placeholder={{
            color: "rgba(0, 38, 55, 0.5)",
            fontSize: "16px",
          }}
          onChange={formik.handleChange}
          value={formik.values.email}
        />

        <FormErrorMessage color="red" fontSize="14px">
          {formik.errors.email}
        </FormErrorMessage>
      </FormControl>

      {role === "doctor" && (
        <FormControl
          isInvalid={!!formik.errors.medical_code}
          mt="24px"
          h="80px"
        >
          <FormLabel
            fontWeight={600}
            fontSize={{ base: "12px", lg: "14px" }}
            color="rgba(0, 38, 55, 1)"
            fontFamily="secondary"
          >
            MEDICAL CODE
          </FormLabel>
          <Input
            id="medical_code"
            name="medical_code"
            placeholder="Enter user's medical code"
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "16px",
            }}
            onChange={formik.handleChange}
            value={formik.values.medical_code}
          />

          <FormErrorMessage color="red" fontSize="14px">
            {formik.errors.medical_code}
          </FormErrorMessage>
        </FormControl>
      )}

      <FormControl isInvalid={!!formik.errors.pnr} mt="24px" h="80px">
        <FormLabel
          fontWeight={600}
          fontSize={{ base: "12px", lg: "14px" }}
          color="rgba(0, 38, 55, 1)"
          fontFamily="secondary"
        >
          PERSONNUMER
        </FormLabel>
        <Input
          id="pnr"
          name="pnr"
          placeholder="Enter user's personal code"
          _placeholder={{
            color: "rgba(0, 38, 55, 0.5)",
            fontSize: "16px",
          }}
          onChange={formik.handleChange}
          value={formik.values.pnr}
        />

        <FormErrorMessage color="red" fontSize="14px">
          {formik.errors.pnr}
        </FormErrorMessage>
      </FormControl>

      {/* <FormControl isInvalid={!!formik.errors.hospital_name} mt="24px" h="80px">
        <FormLabel
          fontWeight={600}
          fontSize={{ base: "12px", lg: "14px" }}
          color="rgba(0, 38, 55, 1)"
          fontFamily="secondary"
        >
          HOSPITAL NAME
        </FormLabel>
        <Input
          id="hospital_name"
          name="hospital_name"
          placeholder="Enter hospital's name"
          _placeholder={{
            color: "rgba(0, 38, 55, 0.5)",
            fontSize: "16px",
          }}
          onChange={formik.handleChange}
          value={formik.values.hospital_name}
        />

        <FormErrorMessage color="red" fontSize="14px">
          {formik.errors.hospital_name}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={!!formik.errors.hospital_address}
        mt="24px"
        h="80px"
      >
        <FormLabel
          fontWeight={600}
          fontSize={{ base: "12px", lg: "14px" }}
          color="rgba(0, 38, 55, 1)"
          fontFamily="secondary"
        >
          HOSPITAL ADDRESS
        </FormLabel>
        <Input
          id="hospital_address"
          name="hospital_address"
          placeholder="Enter hospital's address"
          _placeholder={{
            color: "rgba(0, 38, 55, 0.5)",
            fontSize: "16px",
          }}
          onChange={formik.handleChange}
          value={formik.values.hospital_address}
        />

        <FormErrorMessage color="red" fontSize="14px">
          {formik.errors.hospital_address}
        </FormErrorMessage>
      </FormControl> */}

      <Flex>
        <Button
          variant={"unstyled"}
          bg={"blueish.500"}
          color={"white"}
          minW={"14rem"}
          h={"3rem"}
          fontSize={"1rem"}
          onClick={() => {
            formik.submitForm();
          }}
        >
          {t("Add User")}
        </Button>
      </Flex>
    </VStack>
  );
};

export default StaffForm;
