import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";

import SignPdfComponent from "features/signPDF/components/SignPdfComponent";
import {
  useFetchPrescriptionToPharmacyPdfForm,
  useGeneratePdfFromFrom,
  useGetMedicationsType,
} from "features/superadmin/api/client";

import { PDFValidationSchema } from "features/superadmin/services/validationSchemas";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ManualSelect from "ui/components/ManualSelect";

type PDFFormProps = {
  onClose: () => void;
  onReceivePdf(pdfBytes: any): void;
};

const PDFForm: React.FC<PDFFormProps> = ({ onClose, onReceivePdf }) => {
  const { t } = useTranslation();

  const { data: medicationType } = useGetMedicationsType();
  const medicationOptions = medicationType
    ? medicationType.map((medication) => ({
        value: medication.name,
        label: medication.name,
      }))
    : [];

  const { id } = useParams<{ id: string }>();
  const { trigger: sendPdfFormData } = useGeneratePdfFromFrom();

  const { data } = useFetchPrescriptionToPharmacyPdfForm(id);

  const [pdfBlob, setPdfBlob] = useState<string>("");
  const [localUpdatedPdfObjectUrl, setLocalUpdatedPdfObjectUrl] =
    useState<string>("");

  const options = [
    { label: "Man", value: "Man" },
    { label: "Kvinna", value: "Kvinna" },
    { label: "Other", value: "Other" },
  ];
  const formik = useFormik({
    initialValues: {
      medicationName: "",
      startDose: "",
      endDose: "",
      doctorName: "",
      doctorEmail: "",
      fullName: "",
      birthday: "",
      genre: "",
      email: "",
      treatment: "",
    },
    onSubmit: (values) => {
      debugger;
      sendPdfFormData(
        {
          doctor: {
            email: values.doctorEmail,
            name: values.doctorName,
          },
          medication: {
            end_dose: parseInt(values.endDose),
            name: values.medicationName,
            start_dose: parseInt(values.startDose),
          },
          user_info: {
            birthday: values.birthday,
            email: values.email,
            full_name: values.fullName,
            genre: values.genre,
            treatment: parseInt(values.treatment),
          },
        },
        {
          onSuccess: (data) => {
            onReceivePdf(data);
          },
        }
      );
    },
    validationSchema: PDFValidationSchema,
    validateOnMount: false,
  });

  useEffect(() => {
    formik.setValues({
      medicationName: data?.medication.name ?? "",
      startDose: data?.medication.start_dose.toString() ?? "",
      endDose: data?.medication.end_dose.toString() ?? "",
      doctorName: data?.doctor.name ?? "",
      doctorEmail: data?.doctor.email ?? "",
      fullName: data?.user_info.full_name ?? "",
      birthday: data?.user_info.birthday ?? "",
      genre: data?.user_info.genre ?? "",
      email: data?.user_info.email ?? "",
      treatment: data?.user_info.treatment?.toString() ?? "",
    });
  }, [data]);

  return (
    <Flex w="90%" h="80%" overflowY="auto" position="absolute" justify="center">
      <Flex w="100%" direction="column" gap="1rem">
        <Heading fontSize="2.5rem">
          {t("Prescription to pharmacy form")}
        </Heading>
        <Heading fontSize="1.25rem" color="rgba(88, 154, 175, 1)">
          {t("MEDICATION TYPE")}
        </Heading>
        <FormControl isInvalid={!!formik.errors.medicationName}>
          <FormLabel fontSize="1rem">{t("Medication Name")}:</FormLabel>
          <ManualSelect
            options={medicationOptions}
            value={formik.values.medicationName}
            onChange={(value) => {
              formik.setFieldValue("medicationName", value);
            }}
          />

          <FormErrorMessage color="red" fontSize="16px">
            {formik.errors.medicationName}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formik.errors.startDose}>
          <FormLabel fontSize="1rem">{t("Start Dose")}:</FormLabel>
          <Input
            type="number"
            name="startDose"
            placeholder="Please enter start dose"
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "1rem",
            }}
            onChange={formik.handleChange}
            value={formik.values.startDose}
          />
          <FormErrorMessage color="red" fontSize="16px">
            {formik.errors.startDose}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formik.errors.endDose}>
          <FormLabel fontSize="1rem">{t("End Dose")}:</FormLabel>
          <Input
            type="number"
            name="endDose"
            placeholder="Please enter end dose"
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "1rem",
            }}
            onChange={formik.handleChange}
            value={formik.values.endDose}
          />
          <FormErrorMessage color="red" fontSize="16px">
            {formik.errors.endDose}
          </FormErrorMessage>
        </FormControl>

        <Heading fontSize="1.25rem" color="rgba(88, 154, 175, 1)">
          {t("Doctor")}
        </Heading>
        <FormControl isInvalid={!!formik.errors.doctorName}>
          <FormLabel fontSize="1rem">{t("Doctor Name")}:</FormLabel>
          <Input
            type="text"
            name="doctorName"
            placeholder="Please enter doctor name"
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "1rem",
            }}
            onChange={formik.handleChange}
            value={formik.values.doctorName}
          />
          <FormErrorMessage color="red" fontSize="16px">
            {formik.errors.doctorName}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formik.errors.doctorEmail}>
          <FormLabel fontSize="1rem">{t("Doctor Email")}:</FormLabel>
          <Input
            type="email"
            name="doctorEmail"
            placeholder="Please enter doctor's email"
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "1rem",
            }}
            onChange={formik.handleChange}
            value={formik.values.doctorEmail}
          />
          <FormErrorMessage color="red" fontSize="16px">
            {formik.errors.doctorEmail}
          </FormErrorMessage>
        </FormControl>

        <Heading fontSize="1.25rem" color="rgba(88, 154, 175, 1)">
          {t("Patient Info")}
        </Heading>

        <FormControl isInvalid={!!formik.errors.fullName}>
          <FormLabel fontSize="1rem">{t("Full Name")}:</FormLabel>
          <Input
            type="text"
            name="fullName"
            placeholder="Please enter pacient full name"
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "1rem",
            }}
            onChange={formik.handleChange}
            value={formik.values.fullName}
          />
          <FormErrorMessage color="red" fontSize="16px">
            {formik.errors.fullName}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formik.errors.birthday}>
          <FormLabel fontSize="1rem">{t("Birthday")}:</FormLabel>
          <Input
            type="date"
            name="birthday"
            placeholder="Please enter pacient date of birth"
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "1rem",
            }}
            onChange={formik.handleChange}
            value={formik.values.birthday}
          />
          <FormErrorMessage color="red" fontSize="16px">
            {formik.errors.birthday}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formik.errors.genre}>
          <FormLabel fontSize="1rem">{t("Gender")}:</FormLabel>
          <ManualSelect
            options={options}
            onChange={(value) => formik.setFieldValue("genre", value)}
            value={formik.values.genre}
          />
          <FormErrorMessage color="red" fontSize="16px">
            {formik.errors.genre}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formik.errors.email}>
          <FormLabel fontSize="1rem">{t("Petient email")}:</FormLabel>
          <Input
            type="email"
            name="email"
            placeholder="Please enter pacient email"
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "1rem",
            }}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <FormErrorMessage color="red" fontSize="16px">
            {formik.errors.email}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formik.errors.treatment}>
          <FormLabel fontSize="1rem">{t("Treatment period(weeks)")}:</FormLabel>
          <Input
            type="number"
            name="treatment"
            placeholder="Please enter pacient treatment"
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "1rem",
            }}
            onChange={formik.handleChange}
            value={formik.values.treatment}
            min="0"
            step={4}
          />
          <FormErrorMessage color="red" fontSize="16px">
            {formik.errors.treatment}
          </FormErrorMessage>
        </FormControl>

        <Button
          type="submit"
          variant="dark"
          onClick={() => {
            formik.submitForm();
          }}
          isDisabled={!formik.isValid}
        >
          Submit
        </Button>
        {!formik.isValid && (
          <Text color={"red"} fontSize={"1rem"}>
            {t("Some fields are filled incorrectly, please try again")}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default PDFForm;
