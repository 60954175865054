import {
  Flex,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Input,
  VStack,
} from "@chakra-ui/react";
import { useGetTreatmentPlan } from "features/prescriptionForm/api/client";
import { useFetchTreatmentDurationOptions } from "features/userInfo/api/client";

import { useState, useEffect, FunctionComponent } from "react";
import DurationSlider from "./DurationSlider";
import DurationSldier from "./DurationSlider";
import AnimatedText from "./prescriptionAnimatedText";
import { useTranslation } from "react-i18next";

interface IPopupChangeProps {
  selectedPlan: number;
  setSelectedPlan: (planDuration: number) => void;
  isAdjustable: boolean;
  dashboardDisplay?: boolean;
  adminDisplayId?: string;
}

const PopupChange: FunctionComponent<IPopupChangeProps> = ({
  selectedPlan,
  setSelectedPlan,
  isAdjustable,
  dashboardDisplay,
  adminDisplayId,
}) => {
  const [sliderValue, setSliderValue] = useState(selectedPlan);

  const { t } = useTranslation();

  const { data: userTreatmentData } = useGetTreatmentPlan();
  const { data: treatmentData } = useFetchTreatmentDurationOptions(
    adminDisplayId ?? ""
  );

  const data = adminDisplayId ? treatmentData : userTreatmentData;

  const transitionStyles = {
    transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
    opacity: 1,
    transform: `translateY(0)`,
  };

  const handleSliderChange = (sliderValue: number) => {
    setSelectedPlan(sliderValue);
    console.log(`Setting slider value`, sliderValue);
  };

  return (
    <Flex
      style={transitionStyles}
      w={{ base: "100%", md: "100%", lg: "100%" }}
      direction="column"
      gap={2}
      pt={"0.5rem"}
    >
      {!dashboardDisplay && (
        <Flex justifyContent={"center"} w={"100%"}>
          {!isAdjustable && (
            <Text color="blueish.500" fontSize="15px" fontWeight={700}>
              {selectedPlan} {t("weeks")}
            </Text>
          )}
          {isAdjustable && (
            <VStack gap={0} spacing={0} mb={"2rem"}>
              <Flex
                w="25rem"
                h="3rem"
                bg="rgba(88, 154, 175, 0.2)"
                borderRadius="8px"
                alignItems="center"
                justify="center"
                key={"duration-manual-input-flex-wrapper"}
                mb={"0.25rem"}
              >
                <Input
                  value={selectedPlan}
                  key={"duration-manual-input-flex-input"}
                  w={"2.5rem"}
                  p={"0 !important"}
                  textAlign={"end"}
                  type={"number"}
                  minH={"0 !important"}
                  maxH={"100%"}
                  backgroundColor={"transparent !important"}
                  color="blueish.500"
                  fontSize="1rem"
                  fontWeight={700}
                  step={4}
                  onChange={(e) => {
                    e.stopPropagation();
                    setSelectedPlan(e.target.valueAsNumber);
                  }}
                  mr={"0.25rem"}
                  max={userTreatmentData?.max}
                  min={userTreatmentData?.min}
                />
                <Text
                  color="blueish.500"
                  fontSize="1rem"
                  textAlign="center"
                  fontWeight={700}
                >
                  {t("WEEKS LONG")}
                </Text>
              </Flex>
              {selectedPlan > (data?.max ?? 0) ? (
                <Text
                  color={"#D5738A"}
                  fontSize={"1rem"}
                  fontWeight={"400"}
                  opacity={1}
                  h={0}
                >
                  The maximum number of weeks is {data?.max}.
                </Text>
              ) : selectedPlan < (data?.min ?? 0) || !selectedPlan ? (
                <Text
                  color={"#D5738A"}
                  fontSize={"1rem"}
                  fontWeight={"400"}
                  opacity={1}
                  h={"0"}
                >
                  {t("The minimum number of weeks is")} {data?.min}.
                </Text>
              ) : selectedPlan % 4 !== 0 ? (
                <Text
                  color={"#D5738A"}
                  fontSize={"1rem"}
                  fontWeight={"400"}
                  opacity={1}
                  h={"0"}
                >
                  {t("Number of weeks should be a multiple of 4.")}
                </Text>
              ) : (
                <></>
              )}
            </VStack>
          )}
        </Flex>
      )}

      <DurationSlider
        min={data?.min}
        max={data?.max}
        defaultValue={data?.default ?? 0}
        selectedPlan={selectedPlan}
        onChange={handleSliderChange}
        isAdjustable={isAdjustable}
      />
    </Flex>
  );
};

export default PopupChange;
