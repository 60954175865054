import { Button } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { CiEdit } from "react-icons/ci";
import { useHistory } from "react-router-dom";

type EditPersonelButtonProps = {
  id: string;
};

const EditPersonelButton: FunctionComponent<EditPersonelButtonProps> = ({
  id,
}) => {
  const history = useHistory();
  return (
    <Button
      bg="gray.300"
      color="white"
      px="1rem"
      minWidth="none"
      fontSize="1.1rem"
      fontWeight="800"
      border="none"
      onClick={() => history.push(`/edit-profile/${id}`)}
      rightIcon={<CiEdit />}
    >
      Edit
    </Button>
  );
};

export default EditPersonelButton;
