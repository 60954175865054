import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import ScheduleItem from "features/dashboard/components/dashboardScheduleItem";
import CurrentDayMedication from "features/userInfo/components/CurrentDayMedication";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import circle_minus from "assets/icons/circle_minus.svg";
import left_arrow from "assets/icons/left_arrow.svg";
import right_arrow from "assets/icons/right_arrow.svg";
import today from "assets/icons/today.svg";

import { useAdminUserInfo } from "features/userInfo/api/client";
import {
  TreatmentDayResponseType,
  TreatmentResponseType,
} from "features/admin/api/types";

import { useFetchProfileInfo } from "features/dashboard/api/client";
import PatientsDoctor from "features/admin/components/PatientsDoctor";
import ChangeTreatmentStartDate from "../ChangeTreatmentStartDate";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";

const PatientSchedule = () => {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const { data: userData } = useAdminUserInfo(id);
  const { data: adminData } = useFetchProfileInfo();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const handlePreviousClick = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const spliceIntoChunks = (arr: any[], chunkSize: number) => {
    let res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  const [pagedScheduleData, setPagedScheduleData] = useState<
    TreatmentDayResponseType[][]
  >([]);

  useEffect(() => {
    if (userData?.treatment) {
      const treatmentsWithStrip = userData.treatment.days.map(
        (treatment, index) => {
          return {
            ...treatment,
            strip: Math.floor(index / 28) + 1,
          };
        }
      );

      const splicedData = spliceIntoChunks(treatmentsWithStrip, 7);

      setPagedScheduleData(splicedData);

      setCurrentPage(
        userData.is_active_treatment
          ? splicedData.findIndex((chunk: any[]) => {
              return chunk.some(
                (dayData: TreatmentResponseType) =>
                  dayData.date === new Date().toISOString().slice(0, 10)
              );
            })
          : 0
      );
    }
  }, [userData?.treatment]);

  const currentDayData = userData?.is_active_treatment
    ? userData?.treatment?.days
        .map((e, index) => ({ ...e, index: index }))
        .find(
          (e) =>
            e && new Date(e.date).toDateString() === new Date().toDateString()
        )
    : { ...userData?.treatment?.days[0], index: 0 };

  const startDate = useMemo(() => {
    if (userData && userData.treatment) {
      return new Date(userData.treatment.start_date);
    }
    return null;
  }, [userData]);

  return (
    <>
      {!!userData?.has_treatment && (
        <Flex
          w="100%"
          borderRadius=".75rem"
          bg="white"
          direction="column"
          borderEndRadius={"0.75rem"}
          overflow={"hidden"}
        >
          <Flex
            align="center"
            gap="1rem"
            direction={{ base: "column", sm: "row" }}
            bg={"#589AAF20"}
            p={"2rem"}
          >
            <Image src={today} h="3.75rem" />
            <Flex direction="column" w="full" gap={"0.25rem"}>
              <Heading
                color="blueish.500"
                fontSize="1rem"
                fontWeight={600}
                textTransform={"uppercase"}
              >
                DAGENS DOS
              </Heading>
              <HStack gap={"0.75rem"}>
                {userData &&
                  userData.treatment &&
                  userData.treatment.days.length > 0 &&
                  currentDayData && (
                    <>
                      <CurrentDayMedication
                        dayNumber={
                          (userData.is_active_treatment
                            ? currentDayData.index
                            : 1) + 1
                        }
                        tablets={currentDayData.tablets ?? []}
                        is_active_treatment={!!userData.is_active_treatment}
                      />
                    </>
                  )}
              </HStack>
            </Flex>
          </Flex>

          <Box p={"2rem"} pb={"1.5rem"}>
            <Flex
              justifyContent="space-between"
              align={{ base: "flex-start", sm: "center" }}
              direction={{ base: "column", sm: "row" }}
              id={"treatment_plan"}
            >
              <Box>
                <Heading
                  color="rgba(88, 154, 175, 1)"
                  fontSize="1.25rem"
                  fontWeight={600}
                >
                  {t("dosage schedule").toUpperCase()}
                </Heading>
                <Text
                  fontSize={"1rem"}
                  color={"blueish.900"}
                  fontWeight={400}
                  textTransform={"capitalize"}
                >
                  Startdatum:{" "}
                  <b>
                    {startDate?.toLocaleDateString(t("locale") ?? "en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}
                  </b>
                </Text>
              </Box>

              {/* <ChangeTreatmentStartDate /> */}
            </Flex>

            <SimpleGrid
              gridTemplateColumns={"1fr"}
              columnGap={"2.5rem"}
              gridTemplateRows={"masonry"}
            >
              <VStack alignItems={"stretch"} gap={"1rem"}>
                {(userData?.treatment?.days.length ?? 0) > 0 &&
                  pagedScheduleData?.length > 0 && (
                    <Box>
                      {!currentDayData && (
                        <Flex
                          w="100%"
                          align="center"
                          justify="center"
                          direction="column"
                          py="1rem"
                          gap={2}
                        >
                          <Image src={circle_minus} />
                          <Heading fontSize="20px" fontWeight={400}>
                            {t("No treatment plan for today.")}
                          </Heading>
                          <Text color="black" fontSize="16px" fontWeight={400}>
                            {t(
                              "User does not have a treatment plan ready for today"
                            )}
                          </Text>
                        </Flex>
                      )}
                    </Box>
                  )}
              </VStack>

              {(userData?.treatment?.days.length ?? 0) > 0 && (
                <VStack py="1rem">
                  {!userData?.is_active_treatment && (
                    <Flex
                      w="100%"
                      bg={"rgba(88,154,175,0.1)"}
                      borderRadius="8px"
                      py={{ base: ".5rem", sm: "2rem" }}
                      px={{ base: ".5em", sm: "1.5rem" }}
                      align="center"
                      justify="space-between"
                      direction={{ base: "column", sm: "row" }}
                      border={"2px solid #589AAF"}
                    >
                      Nedtrappning ännu inte startad
                    </Flex>
                  )}
                  {pagedScheduleData &&
                    pagedScheduleData[currentPage] &&
                    pagedScheduleData[currentPage].map((dayData, index) => {
                      const itemDate = new Date(dayData.date);
                      const isFaded =
                        !!userData?.start_treatment_date &&
                        itemDate.getTime() < Date.now();
                      return (
                        <ScheduleItem
                          isFaded={isFaded}
                          totalDose={dayData.dosage}
                          id={dayData.date}
                          day={currentPage * 7 + index + 1}
                          dayOfWeek={itemDate
                            .toLocaleDateString(t("locale") ?? "en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })
                            .replaceAll("-", ".")}
                          pillsDosage={dayData.tablets}
                          key={dayData.date}
                          strip={0}
                          isEditable={
                            false
                            // userData?.status === "prescription_completed" ||
                            // userData?.status === "meeting_with_doctor"
                          }
                          isCurrent={
                            userData?.is_active_treatment &&
                            dayData.date ===
                              new Date().toISOString().slice(0, 10)
                          }
                          isSkipped={dayData.is_skipped}
                          isWrongDose={dayData.has_wrong_dose}
                          wrongDose={dayData.wrong_dose}
                        />
                      );
                    })}
                </VStack>
              )}

              {(!userData?.treatment ||
                userData?.treatment.days.length === 0) && (
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                  py="2rem"
                  gap={2}
                >
                  <Image src={circle_minus} />
                  <Heading fontSize="20px" fontWeight={400}>
                    {t("No schedule.")}
                  </Heading>
                  <Text color="black" fontSize="16px" fontWeight={400}>
                    {t("User have not ordered the medication yet.")}
                  </Text>
                </Flex>
              )}
            </SimpleGrid>

            {pagedScheduleData && pagedScheduleData.length > 0 && (
              <Flex
                gap="40px"
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Flex
                  gap="1.25rem"
                  ml={{ base: "16", sm: "0" }}
                  alignItems={"center"}
                >
                  <Button
                    variant={"outline"}
                    style={{ padding: "0px" }}
                    onClick={handlePreviousClick}
                    disabled={currentPage === 0}
                    color={"blueish.500"}
                    border={"1px solid #589AAF !important"}
                    minH={"3rem"}
                    w={"3rem"}
                  >
                    <GoArrowLeft fontSize={"1.35rem"} />
                  </Button>

                  <Text
                    color="blackish.200"
                    fontSize="16px"
                    fontWeight={500}
                    whiteSpace="nowrap"
                  >
                    {t("Week")} {currentPage + 1} {t("of")}{" "}
                    {pagedScheduleData.length}
                  </Text>

                  <Button
                    variant={"outline"}
                    style={{ padding: "0px" }}
                    disabled={currentPage === pagedScheduleData.length - 1}
                    onClick={handleNextClick}
                    color={"blueish.500"}
                    border={"1px solid #589AAF !important"}
                    minH={"3rem"}
                    w={"3rem"}
                  >
                    <GoArrowRight fontSize={"1.35rem"} />
                  </Button>
                </Flex>
              </Flex>
            )}
          </Box>
        </Flex>
      )}
    </>
  );
};

export default PatientSchedule;
