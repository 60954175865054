import {
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Image,
} from "@chakra-ui/react";
import StabilizePatient from "features/superadmin/components/StabilizePatient";
import TaperingPlanner from "features/superadmin/components/TaperingPlanner";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import minus from "assets/icons/minus_icon.svg";
import up from "assets/icons/up.svg";
import PrescriptionCancelller from "features/superadmin/components/PrescriptionCanceller";

type AdjustPlanDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AdjustPlanDrawer: React.FC<AdjustPlanDrawerProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<string>("planner");
  const [expand, setExpand] = useState<boolean>(true);

  const drawerRef = useRef(null);

  const handleToggleExpand = () => setExpand((prev) => !prev);

  return (
    <Drawer
      placement="bottom"
      onClose={onClose}
      isOpen={isOpen}
      finalFocusRef={drawerRef}
      blockScrollOnMount={false}
      /* closeOnOverlayClick={false} */
    >
      <DrawerOverlay bg="transparent" />
      <DrawerContent
        borderTopRadius="1.25rem"
        border="1px solid rgb(88,154,175)"
        w={{ base: "", md: "90%", lg: "100%" }}
        maxW="1490px !important"
        position="relative"
        bottom="0"
        left="1"
        right="0"
        marginX="auto"
      >
        <DrawerHeader position="relative">
          <Flex gap="2.5rem" py="1.5rem" px="1rem" pb="0rem">
            {expand ? (
              <>
                <Heading
                  color="rgba(88, 154, 175, 0.8)"
                  fontSize="1.25rem"
                  fontWeight={600}
                  letterSpacing={0.7}
                  cursor="pointer"
                  onClick={() => {
                    setCurrentTab("planner");
                  }}
                  opacity={currentTab === "planner" ? 1 : 0.5}
                  borderBottom={
                    currentTab === "planner"
                      ? "3px solid rgba(88, 154, 175, 0.8)"
                      : "none"
                  }
                >
                  {t("TAPERING PLANNER")}
                </Heading>
                <Heading
                  color="rgba(88, 154, 175, 0.8)"
                  fontSize="1.25rem"
                  fontWeight={600}
                  letterSpacing={0.7}
                  cursor="pointer"
                  onClick={() => {
                    setCurrentTab("stabilizer");
                  }}
                  opacity={currentTab === "stabilizer" ? 1 : 0.5}
                  borderBottom={
                    currentTab === "stabilizer"
                      ? "3px solid rgba(88, 154, 175, 0.8)"
                      : "none"
                  }
                >
                  {t("Stabilize patient").toLocaleUpperCase()}
                </Heading>

                <Heading
                  color="rgba(88, 154, 175, 0.8)"
                  fontSize="1.25rem"
                  fontWeight={600}
                  letterSpacing={0.7}
                  cursor="pointer"
                  onClick={() => {
                    setCurrentTab("stopper");
                  }}
                  opacity={currentTab === "stopper" ? 1 : 0.5}
                  borderBottom={
                    currentTab === "stopper"
                      ? "3px solid rgba(88, 154, 175, 0.8)"
                      : "none"
                  }
                >
                  {t("Stop treatment").toLocaleUpperCase()}
                </Heading>

                <Divider w="93%" position="absolute" top={73} left={10} />
              </>
            ) : (
              <>
                <Heading
                  color="rgba(88, 154, 175, 0.8)"
                  fontSize="1.25rem"
                  fontWeight={600}
                  letterSpacing={0.7}
                  cursor="pointer"
                  onClick={() => {
                    setCurrentTab("planner");
                  }}
                  opacity={currentTab === "planner" ? 1 : 0.5}
                  borderBottom={
                    currentTab === "planner"
                      ? "3px solid rgba(88, 154, 175, 0.8)"
                      : "none"
                  }
                >
                  {t("TAPERING PLANNER")}
                </Heading>
              </>
            )}
          </Flex>
        </DrawerHeader>

        <Flex>
          {expand ? (
            <Image
              src={minus}
              opacity={0.5}
              position="absolute"
              top={3}
              right={12}
              onClick={handleToggleExpand}
              cursor="pointer"
            />
          ) : (
            <Image
              src={up}
              position="absolute"
              top={3}
              right={12}
              onClick={handleToggleExpand}
              cursor="pointer"
            />
          )}

          <DrawerCloseButton opacity={0.5} />
        </Flex>

        <DrawerBody overflow="hidden">
          <Collapse in={expand}>
            {currentTab === "planner" && (
              <TaperingPlanner
                expand={expand}
                handleToggleExpand={handleToggleExpand}
              />
            )}
            {currentTab === "stabilizer" && (
              <StabilizePatient expand={expand} />
            )}

            {currentTab === "stopper" && (
              <PrescriptionCancelller
                onTreatmentCancelled={() => {
                  onClose();
                }}
              />
            )}
          </Collapse>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default AdjustPlanDrawer;
