import {
  Box,
  Button,
  Flex,
  Hide,
  Image,
  Show,
  Text,
  Link as ChakraLink,
  Divider,
  Center,
} from "@chakra-ui/react";

import nurse from "assets/images/nurse.png";
import banner_general from "assets/images/banner_general.png";
import banner_general_mobile from "assets/images/banner_general_mobile.png";
import pills_general from "assets/images/pills_general.png";

const GeneralHero = () => {
  return (
    <Flex
      w="full"
      align="center"
      justify="center"
      direction="column"
      py="7rem"
      gap="6rem"
    >
      <Box position="relative">
        <Image src={nurse} />
        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize="20px"
          w={{ base: "85%", sm: "55%" }}
          textAlign="center"
          m="0px auto"
          py="1.5rem"
          fontFamily="secondary"
        >
          Få hjälp av läkare med specialistkunskap om nedtrappning av
          antidepressiva.
        </Text>

        <Divider
          h={{ base: "160px", sm: "180px" }}
          orientation="vertical"
          bgColor="rgba(0, 38, 55, 1)"
          position="absolute"
          w="1px"
          top={{ base: -135, sm: -145 }}
          left="50%"
          border="none !important"
        />
      </Box>

      <Box position="relative">
        <Show above="sm">
          <Image src={banner_general} />
        </Show>

        <Hide above="sm">
          <Image src={banner_general_mobile} minW="100vw" objectFit="cover" />
        </Hide>
        <Button
          position="absolute"
          bottom={{ base: "35%", sm: "30%" }}
          left={{ base: "18%", sm: "40%" }}
          maxW="270px"
          color="rgba(0, 38, 55, 1)"
          minH={{ base: "50px", sm: "auto" }}
          px={{ base: "3.5rem", sm: "2rem" }}
          /*  mt="3rem" */
          fontSize={{ base: "14px", sm: "18px" }}
        >
          <ChakraLink
            href="https://calendly.com/lamina-clinic/15-min-konsultation-kostnadsfritt"
            target="blank"
            textDecoration="none !important"
          >
            Boka gratis konsultation
          </ChakraLink>
        </Button>
      </Box>

      <Box position="relative">
        <Image src={pills_general} />
        <Text
          color="rgba(0, 38, 55, 0,1)"
          fontSize="20px"
          w={{ base: "95%", sm: "65%" }}
          textAlign="center"
          m="0px auto"
          py="1.5rem"
          fontFamily="secondary"
        >
          Individuellt anpassade nedtrappningsdoser för en tryggare
          nedtrappning.
        </Text>

        <Divider
          h={{ base: "160px", sm: "180px" }}
          orientation="vertical"
          bgColor="rgba(0, 38, 55, 1)"
          position="absolute"
          w="1px"
          top={{ base: -135, sm: -145 }}
          left="50%"
          border="none !important"
        />
      </Box>
    </Flex>
  );
};

export default GeneralHero;
