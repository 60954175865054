import {
  Box,
  Center,
  VStack,
  Image,
  Heading,
  Button,
  Text,
  Flex,
} from "@chakra-ui/react";
import { FunctionComponent, useEffect } from "react";
import CheckLarge from "assets/images/CheckLarge.svg";
import { useTranslation } from "react-i18next";
import { BsPerson } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useTokenStore } from "services/state/tokenStore";

interface PaymentSuccessfulProps {}

const PaymentSuccessful: FunctionComponent<PaymentSuccessfulProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { setAccess, setRefresh } = useTokenStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const access = params.get("access");
    const refresh = params.get("refresh");

    if (access && refresh) {
      setAccess(access);
      setRefresh(refresh);
      history.push("/payment-successful");
    }
  }, []);

  return (
    <Center bg={"white"} w={"100vw"} flexGrow={1} mb={"-6vh"} pb={"5rem"}>
      <VStack spacing={"0.25rem"} gap={0}>
        <Image src={CheckLarge} mb={"2rem"} />
        <Heading fontSize={"40px"} fontWeight={500}>
          {t("Payment successful")}
        </Heading>
        <Heading fontWeight={400} fontSize={"md"} mb={"4rem !important"}>
          {t("Thank you! Your order is successfully paid.")}
        </Heading>
        <Button
          variant={"transparent"}
          color={"#589AAF"}
          border={"1px solid #589AAF"}
          fontSize={"stnd"}
          fontWeight={"700"}
          w={"10.5rem"}
          onClick={() => history.push("/dashboard")}
          _hover={{
            color: "white",
            bg: "blueish.500",
          }}
        >
          {t("Back to dashboard")}
        </Button>
      </VStack>
    </Center>
  );
};

export default PaymentSuccessful;
