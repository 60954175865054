import {
  Box,
  Flex,
  Heading,
  Radio,
  VStack,
  Text,
  Center,
} from "@chakra-ui/react";
import { useFetchDefaultAddress } from "features/addressPopup/api/client";
import { UserAddressType } from "features/addressPopup/api/types";
import { formatPhoneNumber } from "features/addressPopup/helpers/helper";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { FiCheck } from "react-icons/fi";

interface DefaultAddressProps {
  isSelected: boolean;
  setIsSelected(newValue: boolean): void;
}

const DefaultAddress: FunctionComponent<DefaultAddressProps> = ({
  isSelected,
  setIsSelected,
}) => {
  const { t } = useTranslation();
  const textVariant = isSelected ? "light" : "dark";

  const { data: bankIdAddess } = useFetchDefaultAddress();

  return (
    <>
      {bankIdAddess && (
        <Box
          border={"1px solid rgba(88, 154, 175, 0.4)"}
          borderRadius={"8px"}
          bg={isSelected ? "#589AAF" : "transparent"}
          transition={"all 0.3s ease-out"}
          mt={"2rem"}
        >
          <Flex
            borderBottom={
              isSelected
                ? "1px solid rgba(255, 255, 255, 0.4)"
                : "1px solid rgba(88, 154, 175, 0.4)"
            }
            p={{
              base: "1rem 1.5rem 1.5rem 1.5rem",
              lg: "1.5rem 2rem 1.5rem 2rem",
            }}
            gap={"1rem"}
          >
            <Center
              h={"1.5rem"}
              minW={"1.5rem"}
              borderRadius={"24px"}
              border={"1px solid rgba(88, 154, 175, 0.4)"}
              cursor={"pointer"}
              bg={isSelected ? "#EEE8A9" : "transparent"}
              onClick={() => setIsSelected(!isSelected)}
            >
              {isSelected && <FiCheck style={{ color: "#589AAF" }} />}
            </Center>
            <Heading
              textTransform={"uppercase"}
              fontSize={"stnd"}
              color={isSelected ? "#EEE8A9" : "blueish.500"}
            >
              {t("Use this address for the delivery")}
            </Heading>
          </Flex>
          <VStack
            alignItems={"flex-start"}
            p={{ base: "1.5rem", lg: "2rem" }}
            pr={"3rem"}
            spacing={0}
          >
            <Heading fontSize={"md"} variant={textVariant} mb={"0.75rem"}>
              {bankIdAddess.first_name} {bankIdAddess.last_name}
            </Heading>
            <Text
              fontSize={"md"}
              variant={textVariant}
              mb={"0.75rem !important"}
            >
              {formatPhoneNumber(bankIdAddess.phone)}
            </Text>
            {bankIdAddess.city !== "" && bankIdAddess.country !== "" && (
              <Text fontSize={"md"} variant={textVariant}>
                {bankIdAddess.city}, {bankIdAddess.country}
              </Text>
            )}

            <Text fontSize={"md"} variant={textVariant} mb={"0.75rem"}>
              {bankIdAddess.address}
            </Text>
            <Text fontSize={"md"} variant={textVariant}>
              {bankIdAddess.zip_code}
            </Text>
            <Text fontSize={"md"} variant={textVariant}>
              {bankIdAddess.email}
            </Text>
          </VStack>
        </Box>
      )}
    </>
  );
};

export default DefaultAddress;
