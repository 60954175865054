import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Flex,
  Button,
  Collapse,
  Image,
  Text,
  Box,
  VStack,
} from "@chakra-ui/react";
import PatientMood from "../patientMood";

import { useUserListFiltered } from "features/admin/api/client";

import { useCallback, useEffect, useState } from "react";

import Pagination from "../adminPagination";

import AdminTableFilter from "../adminTableForm";
import { useHistory, useLocation } from "react-router-dom";
import AdminStatus from "../adminStatus";
import FilterIcon from "ui/iconComponents/FilterIcon";
import NotUseAppIcon from "ui/iconComponents/NotUseAppIcon";
import UseAppIcon from "ui/iconComponents/UseAppIcon";
import FilterIconYellow from "ui/iconComponents/FilterIconYellow";

import admin_cross from "assets/icons/admin_cross.svg";
import Loader from "features/admin/helper/Loader";
import FailLoad from "features/admin/helper/FailLoad";

import { useTranslation } from "react-i18next";
import NoResults from "features/admin/helper/NoResults";
import NoUsersInDatabase from "features/admin/helper/NoUsersInDatabase";
import { useAdminServiceStore } from "services/state/adminServiceStore";

const AdminTable = () => {
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [nameFilter, setNameFilter] = useState<string>("");
  const [savedNameFilter, setSavedNameFilter] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [appUsageFilter, setAppUsageFilter] = useState<string>("");
  const [medicineTypeFilter, setMedicineTypeFilter] = useState<string>("");
  const [moodFilter, setMoodFilter] = useState<string[]>([]);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [lastFilters, setLastFilters] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [excludeTestUsers, setExcludeTestUsers] = useState<boolean>(false);

  const [startingDosageFrom, setStartingDosageFrom] = useState<number>();
  const [startingDosageTo, setStartingDosageTo] = useState<number>();

  const [currentDoseFrom, setCurrentDoseFrom] = useState<number>();
  const [currentDoseTo, setCurrentDoseTo] = useState<number>();
  const { userListSize, setUserListSize } = useAdminServiceStore();
  const [selectedDoctors, setSelectedDoctors] = useState<string>("");

  const [taperingPlanDurationFrom, setTaperingPlanDurationFrom] =
    useState<number>();
  const [taperingPlanDurationTo, setTaperingPlanDurationTo] =
    useState<number>();

  const [show, setShow] = useState(false);

  const handleToggle = () => {
    setShow(!show);
  };

  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const limit = 10;

  /* SELECT FILTERS */
  const filters = [
    statusFilter,
    nameFilter,
    medicineTypeFilter,
    moodFilter,
    appUsageFilter,
    startingDosageFrom,
    startingDosageTo,
    currentDoseFrom,
    currentDoseTo,
    taperingPlanDurationFrom,
    taperingPlanDurationTo,
    excludeTestUsers,
    selectedDoctors,
  ];

  useEffect(() => {
    setFilterCount(countSelectedFilters());
    setIsFilterApplied(countSelectedFilters() > 0);
  }, [...filters]);

  const countSelectedFilters = () => {
    return filters.filter(
      (filter) =>
        filter &&
        filter !== "" &&
        (Array.isArray(filter) ? filter.length > 0 : true)
    ).length;
  };

  const { data, isLoading } = useUserListFiltered(currentPage, userListSize, {
    status: statusFilter,
    name: nameFilter,
    medicineType: medicineTypeFilter,
    mood: moodFilter,
    appUsage: appUsageFilter,
    startingDosageFrom: startingDosageFrom,
    startingDosageTo: startingDosageTo,
    currentDoseFrom: currentDoseFrom,
    currentDoseTo: currentDoseTo,
    excludeTestUsers: excludeTestUsers,
    taperingPlanDurationFrom: taperingPlanDurationFrom
      ? taperingPlanDurationFrom / 4
      : undefined,
    taperingPlanDurationTo: taperingPlanDurationTo
      ? taperingPlanDurationTo / 4
      : undefined,
    selectedDoctors: selectedDoctors,
  });

  useEffect(() => {
    if (data) {
      const pageCount = Math.ceil(data.count / limit);
      setPageCount(pageCount);
    }
  }, [data, limit]);

  const generateQueryParams = useCallback(() => {
    const queryParams = [];

    if (statusFilter) {
      queryParams.push(`status=${statusFilter}`);
    }

    if (nameFilter) {
      queryParams.push(`name=${nameFilter}`);
    }

    if (medicineTypeFilter) {
      queryParams.push(`medicineType=${medicineTypeFilter}`);
    }

    if (moodFilter.length > 0) {
      queryParams.push(`mood=${moodFilter.join(",")}`);
    }

    if (appUsageFilter) {
      queryParams.push(`appUsage=${appUsageFilter}`);
    }

    if (startingDosageFrom) {
      queryParams.push(`startingDosageFrom=${startingDosageFrom}`);
    }

    if (startingDosageTo) {
      queryParams.push(`startingDosageTo=${startingDosageTo}`);
    }

    if (currentDoseFrom) {
      queryParams.push(`currentDoseFrom=${currentDoseFrom}`);
    }

    if (currentDoseTo) {
      queryParams.push(`currentDoseTo=${currentDoseTo}`);
    }

    if (taperingPlanDurationFrom) {
      queryParams.push(`taperingPlanDurationFrom=${taperingPlanDurationFrom}`);
    }

    if (taperingPlanDurationTo) {
      queryParams.push(`taperingPlanDurationTo=${taperingPlanDurationTo}`);
    }

    if (excludeTestUsers) {
      queryParams.push("excludeTestUsers=" + excludeTestUsers.toString());
    }

    queryParams.push(`page=${currentPage}`);

    const queryString = queryParams.join("&");
    setLastFilters(queryString);
    return queryString;
  }, [
    statusFilter,
    nameFilter,
    medicineTypeFilter,
    moodFilter,
    appUsageFilter,
    currentPage,
    currentDoseFrom,
    currentDoseTo,
    startingDosageFrom,
    startingDosageTo,
    taperingPlanDurationFrom,
    taperingPlanDurationTo,
    excludeTestUsers,
  ]);

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(location.search);
    const status = currentSearchParams.get("status") || "";
    const name = currentSearchParams.get("name") || "";
    const medicineType = currentSearchParams.get("medicineType") || "";
    const mood = currentSearchParams.get("mood")?.split(",") || [];
    const appUsage = currentSearchParams.get("appUsage") || "";
    const page = parseInt(currentSearchParams.get("page") || "") || 1;
    const excludeTestUsers =
      currentSearchParams.get("excludeTestUsers") === "true";
    const selectedDoctors = currentSearchParams.get("doctors") || "";

    setCurrentPage(page);
    setStatusFilter(status);
    setNameFilter(name);
    setMedicineTypeFilter(medicineType);
    setMoodFilter(mood);
    setAppUsageFilter(appUsage);
    setExcludeTestUsers(excludeTestUsers);
    setSelectedDoctors(selectedDoctors);

    const isFilterApplied =
      !!status || !!name || !!medicineType || mood.length > 0 || !!appUsage;
    const filterCount =
      (status ? 1 : 0) +
      (name ? 1 : 0) +
      (medicineType ? 1 : 0) +
      mood.length +
      (appUsage ? 1 : 0);
    setIsFilterApplied(isFilterApplied);
    setFilterCount(filterCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryParams = generateQueryParams();
    if (queryParams !== lastFilters) {
      history.push({
        pathname: "/admin/users",
        search: queryParams,
        state: {
          filters: queryParams,
          page: currentPage,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFilters, currentPage, filters, isFilterApplied]);

  const handlePageChange = (currentPage: number) => {
    setCurrentPage(currentPage);
  };

  /* NAME FILTER */
  const handleNameFilterChange = (nameFilter: string) => {
    setNameFilter(nameFilter);
    setCurrentPage(1);
    setIsFilterApplied(true);
    setFilterCount(countSelectedFilters());
  };

  /* MEDICATION TYPE FILTER */
  const handleMedicationTypeChange = (medicineTypeFilter: string) => {
    setMedicineTypeFilter(medicineTypeFilter);
    setCurrentPage(1);
    setIsFilterApplied(true);
    setFilterCount(countSelectedFilters());
  };

  /* STATUS FILTER */
  const handleStatusFilterChange = (statusFilter: string) => {
    setStatusFilter(statusFilter);
    setCurrentPage(1);
    setIsFilterApplied(true);
    setFilterCount(countSelectedFilters());
  };

  /* MOOD FILTER */

  const handleMoodFilterChange = (moodFilter: string[]) => {
    setMoodFilter(moodFilter);
    setCurrentPage(1);
    setIsFilterApplied(true);
    setFilterCount(countSelectedFilters());
  };

  /* APP USAGE FILTER */
  const handleAppUsageFilterChange = (appUsageFilter: string) => {
    setAppUsageFilter(appUsageFilter);
    setCurrentPage(1);
    setIsFilterApplied(true);
    setFilterCount(countSelectedFilters());
  };

  /* CLEAR FILTERS */
  const handleClearFilter = () => {
    setSavedNameFilter("");
    setNameFilter("");
    setMedicineTypeFilter("");
    setMoodFilter([]);
    setStatusFilter("");
    setAppUsageFilter("");
    setFilterCount(0);
    setIsFilterApplied(false);

    setStartingDosageFrom(undefined);
    setStartingDosageTo(undefined);

    setCurrentDoseFrom(undefined);
    setCurrentDoseTo(undefined);
    setTaperingPlanDurationFrom(undefined);
    setTaperingPlanDurationTo(undefined);
  };

  const handleUserClick = (id: string | undefined) => {
    history.push(`/admin/${id}`);
  };

  useEffect(() => {
    if (
      data?.total_users &&
      data?.total_users !== userListSize &&
      userListSize !== 10
    ) {
      setUserListSize(data.total_users);
    }
  }, [data?.total_users]);

  const filteredUsers = data?.results || [];

  return (
    <>
      <Flex p="32px 32px 24px 32px" w="full">
        <Flex w="100%" align="center" justify="space-between">
          <Heading
            color="rgba(255, 255, 255, 1)"
            fontWeight={500}
            fontSize="28px"
          >
            Antal patienter: ({data?.count} patienter av totalt{" "}
            {data?.total_users})
          </Heading>

          {isFilterApplied && filterCount > 0 ? (
            <Flex align="center" justify="flex-end" gap={2}>
              <Button
                gap="1.5"
                onClick={handleClearFilter}
                variant="whited"
                color="rgba(255, 255, 255, 1)"
                bg="rgba(255, 255, 255, 0.2)"
                px="20px"
                letterSpacing={1.1}
              >
                <Image src={admin_cross} mb="0.1rem" />
                {t("Clear All")}
              </Button>
              <Button
                gap="1.5"
                variant={
                  show
                    ? "filter"
                    : isFilterApplied && filterCount > 0
                    ? "filter"
                    : "yellow"
                }
                onClick={handleToggle}
              >
                <FilterIcon />
                {t("Filter")}
                {isFilterApplied && filterCount > 0 && (
                  <Box
                    bg="rgba(222, 36, 83, 1)"
                    borderRadius="50%"
                    w={{ base: "20px", sm: "26px", lg: "26px" }}
                  >
                    <Box color="white">{filterCount}</Box>
                  </Box>
                )}
              </Button>
            </Flex>
          ) : (
            <Button
              gap="1.5"
              onClick={handleToggle}
              variant={show ? "filter" : "yellow"}
            >
              {show ? <FilterIcon /> : <FilterIconYellow />}
              {t("Filter")}
              {isFilterApplied && filterCount > 0 && (
                <Box
                  bg="rgba(222, 36, 83, 1)"
                  borderRadius="50%"
                  w={{ base: "20px", sm: "26px", lg: "26px" }}
                >
                  <Box color="white">{filterCount}</Box>
                </Box>
              )}
            </Button>
          )}
        </Flex>
      </Flex>

      <Collapse in={show} animateOpacity>
        <AdminTableFilter
          nameFilter={nameFilter}
          onNameFilterChange={handleNameFilterChange}
          medicineTypeFilter={medicineTypeFilter}
          onMedicineTypeFilterChange={handleMedicationTypeChange}
          moodFilter={moodFilter}
          onMoodFilterChange={handleMoodFilterChange}
          statusFilter={statusFilter}
          onStatusFilterChange={handleStatusFilterChange}
          appUsageFilter={appUsageFilter}
          onAppUsageFilterChange={handleAppUsageFilterChange}
          startingDosageFrom={startingDosageFrom}
          startingDosageTo={startingDosageTo}
          setStargingDosageFrom={setStartingDosageFrom}
          setStartingDosageTo={setStartingDosageTo}
          currentDoseFrom={currentDoseFrom}
          currentDoseTo={currentDoseTo}
          setCurrentDoseFrom={setCurrentDoseFrom}
          setCurrentDoseTo={setCurrentDoseTo}
          taperingPlanDurationFrom={taperingPlanDurationFrom}
          taperingPlanDurationTo={taperingPlanDurationTo}
          setTaperingPlanDurationFrom={setTaperingPlanDurationFrom}
          setTaperingPlanDurationTo={setTaperingPlanDurationTo}
          excludeTestUsers={excludeTestUsers}
          onSetExcludeTestUsers={setExcludeTestUsers}
          selectedDoctors={selectedDoctors}
          setSelectedDoctors={setSelectedDoctors}
        />
      </Collapse>

      {isLoading ? (
        <Loader />
      ) : filteredUsers ? (
        <Flex w="100%" flexDir="column" overflow="auto">
          <Table bg="white" borderTop="1px solid white" w="100% !important">
            <Thead>
              <Tr fontSize={{ base: "12px", sm: "12px", lg: "14px" }}>
                <Th
                  color="white"
                  fontWeight={600}
                  bg="blueish.500"
                  p="20px"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  fontSize={"inherit"}
                >
                  {t("NAME")}
                </Th>
                <Th
                  color="white"
                  fontWeight={600}
                  bg="blueish.500"
                  p="20px"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  fontSize={"inherit"}
                >
                  {t("MEDICATION TYPE")}
                </Th>
                <Th
                  color="white"
                  fontWeight={600}
                  bg="blueish.500"
                  p="1rem"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  fontSize={"inherit"}
                >
                  {t("STARTING DOSE")}
                </Th>

                <Th
                  color="white"
                  fontWeight={600}
                  bg="blueish.500"
                  p="1rem"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  fontSize={"inherit"}
                >
                  {t("CURRENT DOSE")}
                </Th>

                <Th
                  color="white"
                  fontWeight={600}
                  bg="blueish.500"
                  p="1rem"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  fontSize={"inherit"}
                >
                  {t("TAPERING PLAN")}
                </Th>
                <Th
                  color="white"
                  fontWeight={600}
                  bg="blueish.500"
                  p="1rem"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  fontSize={"inherit"}
                >
                  {t("MOOD")}
                </Th>
                <Th
                  color="white"
                  fontWeight={600}
                  bg="blueish.500"
                  p="20px"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  fontSize={"inherit"}
                >
                  {t("APP")}
                </Th>
                <Th
                  color="white"
                  fontWeight={600}
                  bg="blueish.500"
                  p="20px"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  fontSize={"inherit"}
                >
                  <Flex justify="flex-end">Behandlingsstatus</Flex>
                </Th>
              </Tr>
            </Thead>
            <Tbody
              fontWeight={400}
              fontSize={{ base: "12px", md: "14px", lg: "1rem" }}
            >
              {data?.results.map((item, index) => {
                return (
                  <Tr
                    key={index}
                    onClick={() => handleUserClick(item.id)}
                    cursor="pointer"
                  >
                    <Td
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {item.name}
                      {item.is_test_user && (
                        <Text
                          color={"#002637"}
                          opacity={0.3}
                          fontWeight={600}
                          fontSize={"1rem"}
                        >
                          Test user
                        </Text>
                      )}
                    </Td>

                    <Td>{item.medication?.name}</Td>

                    <Td p="1rem">
                      {item.dose != null ? `${item.dose} mg` : null}
                    </Td>

                    <Td p="1rem">
                      {item.current_dose != null
                        ? `${item.current_dose} mg`
                        : null}
                    </Td>

                    <Td p="1rem">
                      {item.treatment_duration === 0
                        ? t("no plan")
                        : item.treatment_duration * 4 + " " + t("weeks")}
                    </Td>

                    <Td
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                      p={"1rem"}
                    >
                      <PatientMood mood={item.mood} />
                    </Td>
                    <Td>
                      {item.app_usage !== "not_used" ? (
                        <UseAppIcon />
                      ) : (
                        <NotUseAppIcon />
                      )}
                    </Td>
                    <Td
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      <VStack alignItems={"flex-end"}>
                        <AdminStatus
                          status={item.status.toString()}
                          isDoctorChecked={
                            item.is_doctor_approve || item.is_doctor_rejected
                          }
                        />
                        <Text
                          color={"#002637"}
                          fontSize={{ base: "12px", lg: "14px" }}
                        >
                          <b>{t("Changed")}: </b>
                          {new Date(item.status_changed_date).toLocaleString(
                            t("locale") ?? "en-GB",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </Text>
                      </VStack>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          {filteredUsers.length === 0 && isFilterApplied && <FailLoad />}
          {filteredUsers.length === 0 && !isFilterApplied && (
            <NoUsersInDatabase />
          )}
          <Flex w="100%" p="38px">
            {filteredUsers.length > 0 && currentPage >= 1 && (
              <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                onPageChange={handlePageChange}
                size={userListSize}
                setSize={setUserListSize}
                maxSize={data?.count}
              />
            )}
          </Flex>
        </Flex>
      ) : (
        <NoResults />
      )}
    </>
  );
};

export default AdminTable;
