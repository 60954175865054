import {
  Flex,
  FormControl,
  FormLabel,
  Tag,
  TagCloseButton,
  Wrap,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { statusTextColor } from "features/admin/helper/columns";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface StatusSelectProps {
  statusFilter: string;
  onStatusFilterChange: (value: string) => void;
}

const statusOptions = [
  { value: "prescription_completed", label: "Recept skickat" },
  { value: "registered", label: "Registrerad" },
  { value: "meeting_with_doctor", label: "Möte med läkare" },
  { value: "meeting_with_nurse", label: "Möte med sköterska" },
  { value: "prescription_approved", label: "Recept godkänd" },
  { value: "order_placed", label: "Läkemedel beställt" },
  { value: "payment_processing", label: "Betalning i process" },
  { value: "payment_made", label: "Betalning gjord" },
  { value: "shipped", label: "Läkemedel skickat" },
  { value: "treatment_completed", label: "Färdigbehandlad" },
  { value: "on_treatment", label: "Under behandling" },
];

const StatusSelect: React.FC<StatusSelectProps> = ({
  statusFilter,
  onStatusFilterChange,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const selectedValuesRef = useRef<string[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (!statusFilter && selectedValuesRef.current.length > 0) {
      selectedValuesRef.current = [];
      setSelectedValues([]);
    }
  }, [statusFilter]);

  function getStatusTextColor(status: string) {
    const statusObject = statusTextColor.find(
      (obj: { value: any }) => obj.value === status
    );
    return statusObject ? statusObject.color : "";
  }

  return (
    <Flex w="100%" direction="column" gap="12px">
      <FormControl>
        <FormLabel
          fontSize="14px"
          fontWeight={600}
          color="rgba(255, 255, 255, 1)"
          lineHeight="20px"
          mb="0.9rem"
        >
          STATUS
        </FormLabel>

        <Select
          options={statusOptions}
          placeholder="Välj…"
          value={
            statusFilter.length !== 0
              ? undefined
              : { value: "", label: "Välj…" }
          }
          menuPortalTarget={document.body}
          onChange={(selectedOption) => {
            const selectedValue = selectedOption?.value ?? "";
            if (!selectedValuesRef.current.includes(selectedValue)) {
              selectedValuesRef.current = [
                ...selectedValuesRef.current,
                selectedValue,
              ];
              setSelectedValues(selectedValuesRef.current);
            }
            onStatusFilterChange(selectedValuesRef.current.join(","));
          }}
          chakraStyles={{
            dropdownIndicator: (provided, state) => ({
              ...provided,
              display: "none",
            }),
            control: () => ({
              p: "0px",
            }),
            valueContainer: (provided) => ({
              ...provided,
              border: "1px solid rgba(255, 255, 255, 1)",
              bg: "rgba(255, 255, 255, 0.1)",
              borderRadius: "8px",
              h: "49px",
              color: "white",
            }),
            placeholder: (provided) => ({
              ...provided,
              fontWeight: 400,
              opacity: 0.8,
              color: "white",
              fontSize: { base: "1rem", lg: "md" },
            }),
            indicatorSeparator: () => ({ display: "none" }),
            indicatorsContainer: (provided) => ({
              ...provided,
              cursor: "pointer",
            }),
            option: (provided) => ({
              ...provided,
              fontSize: "16px",
            }),
            menu: (provided) => ({
              ...provided,
              position: "relative",
              zIndex: 2,
            }),
          }}
        />
      </FormControl>

      <Wrap w="100%">
        {selectedValues.map((value) => (
          <Tag
            key={value}
            size="md"
            bg="rgba(255, 255, 255, 1)"
            p={{ base: "2px 10px", lg: "4px 12px" }}
            fontSize={{
              base: "8px",
              sm: "8px",
              md: "11px",
              lg: "16px",
            }}
            fontWeight={700}
            color={getStatusTextColor(value)}
            mr="4px"
            mb="4px"
            width="auto"
          >
            {t(
              value
                .replaceAll("_", " ")
                .split(" ")
                .map((word) => {
                  return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(" ")
            )}
            <TagCloseButton
              onClick={() => {
                setSelectedValues(selectedValues.filter((v) => v !== value));
                onStatusFilterChange(
                  selectedValues.filter((v) => v !== value).join(",")
                );
              }}
            />
          </Tag>
        ))}
      </Wrap>
    </Flex>
  );
};

export default StatusSelect;
