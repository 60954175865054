import { Flex, Skeleton, Td, Tr } from "@chakra-ui/react";

const ServiceRowSkeleton: React.FC = () => {
  return (
    <Tr>
      <Td>
        <Flex w="full" justify="space-between">
          <Skeleton
            height="2.5rem"
            width="7.5rem"
            borderRadius=".5rem"
            startColor="gray.300"
            endColor="gray.500"
          />
        </Flex>
      </Td>
      <Td>
        <Flex w="full" justify="space-between">
          <Skeleton
            height="1.5rem"
            width="5rem"
            startColor="gray.300"
            endColor="gray.500"
          />
        </Flex>
      </Td>
      <Td>
        <Skeleton
          height="1.5rem"
          width="5rem"
          startColor="gray.300"
          endColor="gray.500"
        />
      </Td>
      <Td>
        <Flex justify="flex-end" align="baseline" gap={2}>
          <Skeleton
            height="1.5rem"
            width="5rem"
            startColor="gray.300"
            endColor="gray.500"
          />
          <Skeleton
            height="1.5rem"
            width="1.5rem"
            borderRadius="50%"
            startColor="gray.300"
            endColor="gray.500"
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default ServiceRowSkeleton;
