import {
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  VStack,
  Flex,
  Box,
  Text,
  Button,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useAdminUserInfo, useFetchUserNotes } from "features/admin/api/client";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import { useLogAMeeting } from "features/doctors/api/client";
import { invokeBasicInfoModal } from "features/genericModal";
import {
  useCreateMeeting,
  useFetchCarePersonel,
  useFetchMeetingsLogs,
} from "features/staffCommons/api/client";
import CalendlyPopup from "features/staffCommons/components/CalendlyPopup";
import { useGetMeetingServicesByPersonel } from "features/superadmin/api/client";
import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";
import { GoQuestion } from "react-icons/go";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSWRConfig } from "swr";
import { selectServiceStyles, selectStaffStyles } from "./styles/styles";

interface MeetingLoggerModalProps {
  isOpen: boolean;
  onClose(): void;
  patientId?: string;
}

type Option = {
  label: string;
  value: string;
};

const MeetingLoggerModal: FunctionComponent<MeetingLoggerModalProps> = ({
  isOpen,
  onClose,
  patientId,
}) => {
  const { id: paramsId } = useParams<{ id: string }>();

  const id = patientId ?? paramsId ?? "";

  const { data: profileData } = useFetchProfileInfo();
  const [staffId, setStaffId] = useState<string | undefined>(profileData?.id);

  const { data: avalibleServices } = useGetMeetingServicesByPersonel(
    staffId || ""
  );

  const { data: patientData } = useAdminUserInfo(id);

  const { data: carePersonel } = useFetchCarePersonel(id);

  const { trigger: sendLogAMeetingRequest, isMutating: isRequestingLog } =
    useLogAMeeting(id, profileData?.id ?? "");

  const { data: meetingsData } = useFetchMeetingsLogs(id, 1);

  const anyMeetingsBooked = (meetingsData?.results?.length ?? 0) > 0;
  //added this in case we add proper initial meeting check on the back-end
  //replace anyMeetingsBooked with proper value
  //:)
  const initialMeetingBooked = anyMeetingsBooked;

  const personelSelectOptions: Option[] =
    (carePersonel?.care_personal
      .filter((e) => {
        return e.role !== "doctor" || initialMeetingBooked;
      })
      .map((personel) => ({
        label: `${personel.first_name} ${personel.last_name} (${personel.role})`,
        value: personel.id,
      })) as Option[]) || [];

  const { mutate } = useFetchUserNotes(id);
  const { mutate: mutateConfig } = useSWRConfig();
  const { trigger: getCalendlyLink, isMutating: isGettingLink } =
    useCreateMeeting();

  const [selectedServiceType, setSelectedServiceType] = useState<string | null>(
    null
  );

  const serviceOptions: Option[] = (avalibleServices?.service ?? []).map(
    (service) => ({
      label: service.name.replace("_", " "),
      value: service.id,
    })
  );
  useEffect(() => {
    if (!isOpen) {
      setSelectedServiceType(null);
    }

    if (profileData?.id) {
      setStaffId(profileData.id);
    }
  }, [isOpen, profileData?.id]);

  useEffect(() => {
    setSelectedServiceType(null);
  }, [staffId]);

  const [calendlyLink, setCalendlyLink] = useState<string>();

  const {
    isOpen: isCalendlyOpen,
    onClose: onCalendlyClose,
    onOpen: onCalendlyOpen,
  } = useDisclosure();

  const handleSaveClick = () => {
    if (selectedServiceType) {
      getCalendlyLink(
        {
          user_id: id,
          doctor_id: staffId ?? "",
          service_id: selectedServiceType,
        },
        {
          onSuccess: (response) => {
            setCalendlyLink((response as any).link);
            onCalendlyOpen();
          },
          onError: (error) => {
            toast.error("Something went wrong, please try again later");
          },
        }
      );

      return;
    }
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading
              fontFamily={"secondary"}
              fontSize={"2rem"}
              fontWeight={"400"}
            >
              Boka nytt möte med patient{" "}
              <b>{patientData?.first_name + " " + patientData?.last_name}</b>
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={"2rem"}>
            <VStack w={"100%"} alignItems={"stretch"} gap={"0.5rem"}>
              <Box>
                <Flex alignItems={"center"} gap={"0.2rem"}>
                  <Text
                    color={"blueish.900"}
                    fontWeight={"400 !important"}
                    fontSize={"1rem"}
                    zIndex={0}
                  >
                    {t("Select staff")}
                  </Text>
                  {!initialMeetingBooked && (
                    <Tooltip
                      label={
                        t(
                          "Initial consultation with nurse is required at first"
                        ) ?? ""
                      }
                    >
                      <Box>
                        <GoQuestion />
                      </Box>
                    </Tooltip>
                  )}
                </Flex>

                <Select<Option>
                  isSearchable={false}
                  selectedOptionStyle="check"
                  options={personelSelectOptions}
                  placeholder={t("Please select")}
                  name="doctor_id"
                  value={personelSelectOptions.find((e) => {
                    return e.value === staffId;
                  })}
                  variant={"outlined"}
                  onChange={(selectedOption) => {
                    if (selectedOption && typeof selectedOption !== "string") {
                      setStaffId((selectedOption as Option).value);
                    }
                  }}
                  chakraStyles={selectStaffStyles}
                />
              </Box>

              <Box>
                <Text
                  color={"blueish.900"}
                  fontWeight={"400 !important"}
                  fontSize={"1rem"}
                >
                  {t("Specify a service")}
                </Text>
                <Select<Option>
                  className="chakra-react-select"
                  classNamePrefix="chakra-react-select"
                  placeholder={`${t("Please select")}`}
                  isSearchable={false}
                  variant={"outlined"}
                  options={serviceOptions}
                  value={
                    serviceOptions.find(
                      (option) => option.value === selectedServiceType
                    ) || null
                  }
                  onChange={(e) => {
                    setSelectedServiceType(e?.value || null);
                  }}
                  chakraStyles={selectServiceStyles}
                />
              </Box>

              <Flex
                justifyContent={"flex-end"}
                w={"100%"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <Button
                  variant={"ghost"}
                  color={"bluesh.900"}
                  fontWeight={500}
                  px={"1.5rem"}
                  py={"0.5rem"}
                  onClick={onClose}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  variant={"outline"}
                  color={"blueish.900"}
                  borderColor={"blueish.900"}
                  px={"1.5rem"}
                  py={"0.5rem"}
                  fontSize={"1.25rem"}
                  fontWeight={"600"}
                  onClick={handleSaveClick}
                  isLoading={isRequestingLog}
                  isDisabled={!selectedServiceType}
                >
                  {t("Choose time")}
                </Button>
              </Flex>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <CalendlyPopup
        isOpen={isCalendlyOpen}
        onClose={onCalendlyClose}
        link={calendlyLink}
        meetingInfo={{
          type_name: "",
          patientId: id,
        }}
        patientId={id}
        callbacks={{
          onSuccess: () => {
            mutate();
            mutateConfig((key: string) => {
              return !!key?.includes("/meeting/filter/user/");
            });
            invokeBasicInfoModal(
              <>
                Patienten har fått en bekräftelse med datum och tid via email.
              </>,
              "Mötet är bokat",
              () => {
                onClose();
              },
              {
                text: "Stäng",
              }
            );
          },
        }}
      />
    </>
  );
};

export default MeetingLoggerModal;
