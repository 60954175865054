import {
  Box,
  Button,
  Collapse,
  Flex,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import MedicationTypeSearch from "features/admin/components/adminMedicationTypeSearch";
import { ReportsFilterType } from "features/reporting/api/type";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useURLParametrize } from "services/hooks/useUrlParametrize";
import DoctorsFilter from "../DoctorsFilter";
import MedicationFilter from "../MedicationFilter";
import NurseFilter from "../NurseFilter";
import PaceFilter from "../PaceFilter";
import PeriodFilter from "../PeriodFilter";
import ProfessionFilter from "../ProfessionFilter";

interface FiltersDropDownProps {
  isOpen: boolean;
  onClose(): void;
}

const FiltersDropDown: FunctionComponent<FiltersDropDownProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [filter, setFilter] = useState<ReportsFilterType>({});
  const { parametersAppendix } = useURLParametrize(filter);

  const applyFilters = () => {
    if (parametersAppendix) {
      history.push("/superadmin/reports" + parametersAppendix);
    }
  };

  return (
    <>
      <Collapse in={isOpen} animateOpacity>
        <HStack
          w={"100%"}
          minH={"8rem"}
          alignItems={"center"}
          flexWrap={"wrap"}
          rowGap={"0.5rem"}
          borderTop={"1px solid rgba(0, 0, 0, 0.1)"}
          pt={"2rem"}
          pb={"1rem"}
          bg={"white"}
          px={"2rem"}
        >
          <Box minW={"15rem"} overflowY={"visible"}>
            <Text color={"blueish.900"} opacity={0.5} fontSize={"1rem"}>
              {t("Profession")}:
            </Text>
            <ProfessionFilter
              value={filter.profession ?? ""}
              onChange={(newValue) => {
                setFilter({
                  ...filter,
                  profession: newValue,
                });
              }}
            />
          </Box>

          <Box mr={"2rem !important"}>
            <Text color={"blueish.900"} opacity={0.5} fontSize={"1rem"}>
              {t("Time period")}:
            </Text>
            <PeriodFilter
              value={{
                from: filter.timeStart,
                to: filter.timeEnd,
              }}
              onChange={(newDates) => {
                setFilter({
                  ...filter,
                  timeEnd: newDates.to,
                  timeStart: newDates.from,
                });
              }}
            />
          </Box>

          <Box minW={"15rem"}>
            <Text color={"blueish.900"} opacity={0.5} fontSize={"1rem"}>
              {t("Pace")}:
            </Text>
            <PaceFilter
              value={filter.pace}
              onChange={(newValue) => {
                setFilter({ ...filter, pace: newValue });
              }}
            />
          </Box>

          <Box minW={"15rem"} mr={"3rem !important"}>
            <Text color={"blueish.900"} opacity={0.5} fontSize={"1rem"}>
              {t("Medication type")}:
            </Text>
            <MedicationFilter
              value={filter.medication}
              onChange={(newValue) => {
                setFilter({ ...filter, medication: newValue });
              }}
            />
          </Box>

          <Flex gap={"0.5rem"} pr={"2rem"} ml={"0 !important"}>
            <Box minW={"6rem"}>
              <Text color={"blueish.900"} opacity={0.5} fontSize={"1rem"}>
                {t("Dose from")}:
              </Text>
              <Input
                type="number"
                minHeight={"3rem"}
                fontSize={"1rem"}
                maxH={"3rem"}
                h={"3rem"}
                py={"0 !important"}
                bg={"transparent !important"}
                border={"1px solid rgba(0, 0, 0, 0.1) !important"}
                value={filter.dose_start}
                onChange={(e) => {
                  setFilter({ ...filter, dose_start: e.target.valueAsNumber });
                }}
              />
            </Box>
            <Box minW={"6rem"}>
              <Text color={"blueish.900"} opacity={0.5} fontSize={"1rem"}>
                {t("Dose to")}:
              </Text>
              <Input
                type="number"
                minHeight={"3rem"}
                fontSize={"1rem"}
                maxH={"3rem"}
                h={"3rem"}
                py={"0 !important"}
                minWidth={"5rem"}
                bg={"transparent !important"}
                border={"1px solid rgba(0, 0, 0, 0.1) !important"}
                value={filter.dose_end}
                onChange={(e) => {
                  setFilter({ ...filter, dose_end: e.target.valueAsNumber });
                }}
              />
            </Box>
          </Flex>

          <Flex gap={"0.5rem"}>
            <Box minW={"6rem"}>
              <Text color={"blueish.900"} opacity={0.5} fontSize={"1rem"}>
                {t("Age from")}:
              </Text>
              <Input
                type="number"
                minHeight={"3rem"}
                fontSize={"1rem"}
                maxH={"3rem"}
                h={"3rem"}
                py={"0 !important"}
                minWidth={"5rem"}
                bg={"transparent !important"}
                border={"1px solid rgba(0, 0, 0, 0.1) !important"}
                value={filter.age_start}
                onChange={(e) => {
                  setFilter({ ...filter, age_start: e.target.valueAsNumber });
                }}
              />
            </Box>

            <Box minW={"6rem"}>
              <Text color={"blueish.900"} opacity={0.5} fontSize={"1rem"}>
                {t("Age to")}:
              </Text>
              <Input
                type="number"
                minHeight={"3rem"}
                fontSize={"1rem"}
                maxH={"3rem"}
                h={"3rem"}
                py={"0 !important"}
                minWidth={"5rem"}
                bg={"transparent !important"}
                border={"1px solid rgba(0, 0, 0, 0.1) !important"}
                value={filter.age_end}
                onChange={(e) => {
                  setFilter({ ...filter, age_end: e.target.valueAsNumber });
                }}
              />
            </Box>
          </Flex>
        </HStack>
        <Flex
          w={"100%"}
          justifyContent={"flex-end"}
          bg={"white"}
          alignItems={"center"}
          gap={"1rem"}
          p={"1rem"}
        >
          <Button
            variant={"ghost"}
            onClick={() => {
              history.push(location.pathname);
              setFilter({});
            }}
            isDisabled={Object.entries(filter).length === 0}
          >
            {t("Clear")}
          </Button>

          <Button
            variant={"dark"}
            minWidth={"none"}
            onClick={() => {
              applyFilters();
            }}
          >
            {t("Apply Filters")}
          </Button>
        </Flex>
      </Collapse>
    </>
  );
};

export default FiltersDropDown;
