import { Flex, Heading, Text, Image, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import left_arrow from "assets/icons/left_arrow.svg";

const medicine = [
  "Agomelatine (Valdoxan)",
  "Amitriptyline (Sarotex, Tryptizol)",
  "Bupropion (Wellbutrin, Zyban)",
  "Citalopram (Cipramil)",
  "Clomipramine (Anafranil)",
  "Fluoxetine (Prozac)",
  "Fluvoxamine (Fevarin)",
  "Mirtazapine (Remeron)",
  "Moclobemide (Aurorix)",
  "Nortriptyline (Nortrilen, Pamelor)",
  "Paroxetine (Seroxat, Paxil)",
  "Sertraline (Zoloft)",
  "Tranylcypromine",
  "Venlafaxine",
  "Doxepine (Sinequan)",
  "Duloxetine (Symbalta)",
  "Escitalopram (Cipralex, Lexapro)",
  "Imipramine (Tofranil)",
  "Maprotiline (Ludiomil)",
];

const Medicines = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Box pb={"5vh"} w="full">
      <Flex
        w="full"
        bg="rgba(255, 255, 255, 1)"
        borderRadius="24px"
        p="60px"
        align="flex-start"
        direction="column"
        gap="10px"
      >
        <Flex
          align="center"
          gap="14px"
          onClick={() => history.push("/")}
          cursor="pointer"
        >
          <Image src={left_arrow} />
          <Text fontSize="18px" color="black" fontWeight={500}>
            {t("Back")}
          </Text>
        </Flex>
        <Flex w="100%" align="center" justify="center" mb="30px">
          <Heading fontSize="4xl" color="blueish.500">
            {t("Which medicines do we support?")}
          </Heading>
        </Flex>

        <Heading textAlign={{ base: "start", sm: "center" }}>
          {t(
            "We write prescriptions that contain the same active substance with the only one the difference is that we provide completely unique doses that are adapted for step down. We currently support the following medicines"
          )}
        </Heading>

        <Flex
          w="full"
          direction="column"
          gap="8px"
          px={{ base: "5px", sm: "20px" }}
          mt="20px"
        >
          {medicine.map((item, index) => (
            <Heading fontSize="2xl" color="blueish.500" key={index}>
              {item}
            </Heading>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Medicines;
