import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

import MinusIcon from "ui/iconComponents/MunusIcon";
import PlusIcon from "ui/iconComponents/PlusIcon";

import frequently from "assets/icons/frequently.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { t } from "i18next";

type Faq = {
  hash: string;
  question: string;
  answer: JSX.Element;
};

type Section = {
  title: string;
  faqs: Faq[];
};

type FaqFrequentlyQuestionsProps = {
  searchQuery: string;
};

const FaqFrequentlyQuestions: React.FC<FaqFrequentlyQuestionsProps> = ({
  searchQuery,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const frequentlyQuestions: Section[] = [
    {
      title: "About Lamina Clinic",
      faqs: [
        {
          hash: "who-is-laminaclinic-for",
          question: t("What is Lamina Clinic?"),
          answer: (
            <div>
              <p>{t("faqQuestions$faq$answer_1")}</p>
            </div>
          ),
        },
        {
          hash: "what-is-the-difference",
          question: t(
            "What is the difference between Lamina Clinic and a health centre?"
          ),
          answer: (
            <div>
              <p>{t("faqQuestions$faq$answer_2")}</p>
            </div>
          ),
        },
        {
          hash: "why-should-step-down",
          question: t("Why should I step down with Lamina?"),
          answer: (
            <div>
              <p>{t("faqQuestions$faq$answer_3")}</p>
            </div>
          ),
        },
      ],
    },
    {
      title: "About downsizing with us",
      faqs: [
        {
          hash: "who-is-our-method",
          question: t("What is Lamina's method?"),
          answer: (
            <div>
              <p>{t("faqQuestions$faq$answer_4_1")}</p>
              {t("faqQuestions$faq$answer_4_2")}
            </div>
          ),
        },
        {
          hash: "what-is-the-difference",
          question: t("Can anyone become a patient?"),
          answer: (
            <div>
              <p>{t("faqQuestions$faq$answer_5_1")}</p>
              <p>{t("faqQuestions$faq$answer_5_2")}</p>

              <ul style={{ paddingLeft: "20px" }}>
                <li>{t("faqQuestions$faq$answer_5_4")}</li>
                <li>{t("faqQuestions$faq$answer_5_5")}</li>
                <li>{t("faqQuestions$faq$answer_5_6")}</li>
                <li>{t("faqQuestions$faq$answer_5_7")}</li>
                <li>{t("faqQuestions$faq$answer_5_8")}</li>
                <li>{t("faqQuestions$faq$answer_5_9")}</li>
                <li>{t("faqQuestions$faq$answer_5_10")}</li>
                <li>{t("faqQuestions$faq$answer_5_11")}</li>
                <li>{t("faqQuestions$faq$answer_5_12")}</li>
              </ul>
            </div>
          ),
        },
        {
          hash: "how-long-will-it-take",
          question: t("How long will it take?"),
          answer: (
            <div>
              <p>{t("faqQuestions$faq$answer_6")}</p>
            </div>
          ),
        },
        {
          hash: "medication-can-you-help-putting-out",
          question: t("Which medicines can you help with putting out?"),
          answer: (
            <div>
              <p>{t("faqQuestions$faq$answer_7")}</p>

              <ul style={{ paddingLeft: "20px" }}>
                <li>Amitryptilin</li>
                <li>Bupropion</li>
                <li>Citalopram</li>
                <li>Escitalopram</li>
                <li>Fluoxetin</li>
                <li>Mirtazapin</li>
                <li>Moclobemid</li>
                <li>Nortriptylin</li>
                <li>Paroxetin</li>
                <li>Sertralin</li>
                <li>Venlafaxin</li>
              </ul>
            </div>
          ),
        },
        {
          hash: "free-card-and-protection",
          question: t("Do free cards and high-cost protection apply?"),
          answer: (
            <div>
              <p>{t("faqQuestions$faq$answer_8")}</p>
            </div>
          ),
        },
        {
          hash: "care-form-without-bank-id",
          question: t("Can I seek care from you without a Bank ID?"),
          answer: (
            <div>
              <p>{t("faqQuestions$faq$answer_9")}</p>
            </div>
          ),
        },
        {
          hash: "what-does-it-cost",
          question: t("What does it cost?"),
          answer: (
            <div>
              <p>{t("faqQuestions$faq$answer_10_1")}</p>

              <p>
                Månadskostnaden landar därför för de flesta på{" "}
                {prices.nurse_meeting} kronor för den månatliga uppföljningen,
                plus {prices.nurse_meeting} kronor var tredje månad för
                förnyelse av recept. I början betalar du också för ett
                uppstartsmöte och ett initialt läkarbesök. Läkemedlen betalar du
                på apoteket precis som vanligt.
              </p>

              <p style={{ textDecoration: "underline" }}>
                {t("faqQuestions$faq$answer_10_2")}
              </p>
              <ul style={{ paddingLeft: "20px" }}>
                <li>
                  {prices.nurse_meeting} {t("faqQuestions$faq$answer_10_3")}
                </li>
                <li>{t("faqQuestions$faq$answer_10_4")}</li>
                <li>
                  {prices.nurse_meeting} {t("faqQuestions$faq$answer_10_5")}
                </li>
                <li>
                  {prices.nurse_meeting} {t("faqQuestions$faq$answer_10_6")}
                </li>
                <li>{t("faqQuestions$faq$answer_10_7")}</li>
                <li>
                  {prices.nurse_meeting} {t("faqQuestions$faq$answer_10_8")}
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
    {
      title: "Om utsättningssymptom",
      faqs: [
        {
          hash: "withdrawal-symptoms",
          question: t("Withdrawal symptoms, what are they?"),
          answer: (
            <div>
              <p>{t("faqQuestions$faq$answer_11")}</p>
            </div>
          ),
        },
        {
          hash: "how-common-are-withdrawal-sympthoms",
          question: t("How common are withdrawal symptoms?"),
          answer: (
            <div>
              <p>{t("faqQuestions$faq$answer_12")}</p>
            </div>
          ),
        },
      ],
    },
  ];

  const filteredSections = frequentlyQuestions
    .map((section) => ({
      ...section,
      faqs: section.faqs.filter((faq) =>
        faq.question.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    }))
    .filter((section) => section.faqs.length > 0);

  const hasResults = filteredSections.length > 0;

  return (
    <Flex
      w="full"
      mx="auto"
      maxW={{ base: "auto", sm: "1278px", md: "1352px", lg: "1652px" }}
      direction="column"
      gap=".5rem"
      py={{ base: "1.5rem", sm: "3.75rem" }}
      px={{ base: "2rem", sm: "7rem", md: "13rem", lg: "11rem" }}
    >
      <Image src={frequently} w={{ base: "8%", sm: "4%" }} />
      <Heading
        fontSize={{ base: "20px", sm: "2rem" }}
        color="rgba(0, 38, 55, 1)"
        fontWeight={500}
      >
        {t("Frequently Asked Questions")}
      </Heading>
      <Text color="rgba(0, 38, 55, 1)" fontSize="1rem" fontWeight={400}>
        {t("For users who want to discover more about Lamina Clinic")}.
      </Text>

      {hasResults ? (
        <Flex direction="column" gap=".5rem">
          {filteredSections.map((section, sectionIndex) => (
            <Box key={sectionIndex} mb="1rem">
              <Heading
                fontSize="24px"
                color="rgba(88, 154, 175, 1)"
                fontWeight={500}
              >
                {t(section.title)}
              </Heading>
              <Accordion
                allowToggle
                w="100%"
                bg="rgba(88, 154, 175, 0.1)"
                maxH={"fit-content"}
              >
                {section.faqs.map((faq, index) => (
                  <AccordionItem key={index}>
                    {({ isExpanded }) => (
                      <>
                        <Box id={faq.hash}>
                          <AccordionButton
                            w="full"
                            justifyContent="space-between"
                            py=".75rem"
                            px="1.25rem"
                            onClick={() => {
                              history.push("#" + faq.hash);
                            }}
                          >
                            <Text
                              flex="1"
                              textAlign="left"
                              fontWeight={700}
                              color={
                                isExpanded
                                  ? "rgba(88, 154, 175, 1)"
                                  : "rgba(0, 38, 55, 1)"
                              }
                              fontSize={{ base: "1rem", sm: "1.25rem" }}
                              whiteSpace="pre-wrap"
                            >
                              {t(faq.question)}
                            </Text>
                            {isExpanded ? <MinusIcon /> : <PlusIcon />}
                          </AccordionButton>
                        </Box>

                        <Box
                          w="full"
                          bg={isExpanded ? "white" : "rgba(238, 245, 247, 1)"}
                          p=".5rem"
                        />
                        <AccordionPanel
                          id={faq.hash}
                          pb={4}
                          bg="white"
                          fontSize="1rem"
                          fontWeight={400}
                          px="1.25rem"
                        >
                          {faq.answer}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
          ))}
        </Flex>
      ) : (
        <Text
          color="rgba(88, 154, 175, 1)"
          fontWeight={600}
          fontSize={{ base: "1rem", sm: "1.5rem" }}
        >
          {t("No results found for your search")}
        </Text>
      )}
    </Flex>
  );
};

export default FaqFrequentlyQuestions;

const prices = {
  nurse_meeting: 275,
};
