import { FunctionComponent, useState } from "react";
import { Flex, IconButton, Input, Text } from "@chakra-ui/react";
import { BsCheck2, BsX, BsXLg } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";
import { useUpdateDayDose } from "features/dashboard/api/client";

interface EditDosageComponentProps {
  id: string;
  dose: number;
  day: number;
  isEditable?: boolean;
}

const EditDosageComponent: FunctionComponent<EditDosageComponentProps> = ({
  dose,
  day,
  id,
  isEditable,
}) => {
  const { trigger, isMutating } = useUpdateDayDose(id);
  const [dayDose, setDayDose] = useState<number>(dose);

  return (
    <Flex alignItems={"center"}>
      {isEditable && (
        <Input
          type={"number"}
          value={dayDose}
          onChange={(e) => setDayDose(e.target.valueAsNumber)}
          fontSize={"1rem"}
          py={"0 !important"}
          px={"0 !important"}
          minHeight={"0 !important"}
          bg={"transparent !important"}
          w={"3rem"}
          h={"auto"}
          borderRadius={0}
          borderBottom={"1px solid rgba(0, 0, 0, 0.1)"}
        />
      )}
      {dayDose !== dose && isEditable && (
        <Flex alignItems={"center"}>
          <IconButton
            variant={"transparent"}
            color={"blueish.900"}
            minW={"0 !important"}
            p={"0 !important"}
            aria-label={""}
            icon={<BsCheck2 />}
            onClick={() => {
              trigger({ dosage: dayDose, day_of_tapering: day });
            }}
            isLoading={isMutating}
          />
          <IconButton
            variant={"transparent"}
            color={"blueish.900"}
            minW={"0 !important"}
            p={"0 !important"}
            aria-label={""}
            icon={<IoCloseOutline />}
            fontSize={"1.1rem"}
            onClick={() => setDayDose(dose)}
          />
        </Flex>
      )}
      <Text fontSize="1em" color="blackish.200" fontWeight={"bold"}>
        {!isEditable && dose}
      </Text>
    </Flex>
  );
};

export default EditDosageComponent;
