import {
  Divider,
  Flex,
  Heading,
  Table,
  TableContainer,
  VStack,
} from "@chakra-ui/react";

import { useGetAllOrders } from "features/superadmin/api/client";
import {
  OrderItem,
  Order,
  OrdersResponse,
} from "features/superadmin/api/types";

import OrdersTableHeader from "features/superadmin/components/OrdersRow/components/OrdersTableHeader";
import OrdersRow from "features/superadmin/components/OrdersRow";
import { useState, useEffect } from "react";

import Pagination from "features/admin/components/Pagination";
import OrdersRowSkeleton from "features/superadmin/components/OrdersRow/components/OrdersRowSkeleton";
import CurrentDate from "ui/components/CurrentDate";
import GoBackButton from "ui/components/GoBackButton";
import { useTranslation } from "react-i18next";

const OrdersPage: React.FC = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [orders, setOrders] = useState<OrdersResponse | undefined>(undefined);

  const { data, isLoading } = useGetAllOrders(
    currentPage,
    sortField,
    sortOrder
  );

  useEffect(() => {
    if (data) {
      setOrders(data);
    }
  }, [data]);

  const handleSortChange = (field: string) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const handleDiscountApply = (orderId: string, discountAmount: string) => {
    setOrders((prevOrders) => {
      if (prevOrders) {
        const updatedOrders = prevOrders.results.map((order: Order) =>
          order.id === orderId
            ? {
                ...order,
                discount: {
                  ...order.discount,
                  amount: discountAmount,
                },
              }
            : order
        );
        return { ...prevOrders, results: updatedOrders };
      }
      return prevOrders;
    });
  };

  return (
    <VStack w="full" py="5rem">
      <GoBackButton />

      <Heading fontFamily="secondary" fontSize="2rem" alignSelf="flex-start">
        {t("Orders")}
      </Heading>

      <CurrentDate />

      <Divider mb="5 !important" />

      <TableContainer
        w="full"
        overflowY="hidden"
        bg="white"
        borderRadius=".75rem"
        boxShadow="md"
      >
        <Table variant="simple">
          <OrdersTableHeader
            sortField={sortField}
            sortOrder={sortOrder}
            onSortChange={handleSortChange}
          />

          {isLoading ? (
            <OrdersRowSkeleton length={orders?.results.length || 10} />
          ) : (
            orders?.results &&
            orders.results.map((order: Order) =>
              order.items.map((item: OrderItem) => (
                <OrdersRow
                  key={`${order.id}-${item.id}`}
                  item={item}
                  order={order}
                  currentPage={currentPage}
                  onDiscountApply={handleDiscountApply}
                  sortField={sortField}
                  sortOrder={sortOrder}
                />
              ))
            )
          )}
        </Table>
      </TableContainer>

      <Flex w="full" align="center" justify="flex-end" py="2rem">
        {!!orders?.count && Math.ceil((orders?.count ?? 0) / 10) > 1 && (
          <Pagination
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            pageCount={Math.ceil((orders?.count ?? 0) / 10)}
          />
        )}
      </Flex>
    </VStack>
  );
};

export default OrdersPage;
