import { Flex, Text, Box } from "@chakra-ui/react";
import PopupChange from "features/prescriptionForm/components/prescriptionPopupChange";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface ConsentedTreatmentPlanProps {
  current_treatment?: number;
  date?: string;
  duration?: number;
  status_change_date?: string;
}

const ConsentedTreatmentPlan: React.FC<ConsentedTreatmentPlanProps> = ({
  current_treatment,
  status_change_date,
  date,
  duration,
}) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  return (
    <Flex w="100%" direction="column" gap="12px">
      <Flex w="100%" align="flex-start" direction="column">
        <Text color="blueish.500" fontSize="16px" fontWeight={500}>
          CONSENTED TREATMENT PLAN
        </Text>
        <Flex w="100%" gap="12px">
          <Text color="black" fontSize="16px" fontWeight={700}>
            {duration ? duration * 4 : 0} weeks
          </Text>
          <Text color="black" fontSize="16px">
            {new Date(status_change_date || "").toLocaleDateString(
              t("locale") ?? "en-GB",
              {
                month: "short",
                day: "numeric",
                year: "numeric",
              }
            )}
          </Text>
        </Flex>
      </Flex>

      <PopupChange
        isAdjustable={false}
        selectedPlan={(duration ?? 0) * 4}
        setSelectedPlan={() => {}}
        dashboardDisplay={true}
        adminDisplayId={id}
      />
    </Flex>
  );
};

export default ConsentedTreatmentPlan;
