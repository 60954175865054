import { APIClient } from "services/api/client";
import useSWRMutation from "swr/mutation";
import { CallLoggerRequestType } from "./types";

export const useCallUserAPI = () => {
  return useSWRMutation<any, CallLoggerRequestType>(
    "call/",
    (
      url: string,
      {
        arg,
      }: {
        arg: CallLoggerRequestType;
      }
    ) => {
      return APIClient.post(url, arg);
    }
  );
};

export const useEndCallAPI = (id: string) => {
  return useSWRMutation<any, CallLoggerRequestType>(
    "call/" + id + "/",
    (
      url: string,
      {
        arg,
      }: {
        arg: CallLoggerRequestType;
      }
    ) => {
      return APIClient.put(url, arg);
    }
  );
};
