import { Center, Heading, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const ExpiredWaitingPeriod: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Center
      bg={"white"}
      width={"100%"}
      mt={"0 !important"}
      borderRadius={"lg"}
      height={"80vh !important"}
      alignItems={"center"}
      flexDirection={"column"}
      p={"2vh"}
    >
      <Heading textAlign={{ base: "center" }}>
        {t("30 seconds waiting period has expired")}
      </Heading>
      <Text variant={"dark"}>{t("Please reload your page")}</Text>
    </Center>
  );
};

export default ExpiredWaitingPeriod;
