import {
  Flex,
  Heading,
  Text,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Spacer,
} from "@chakra-ui/react";
import i18n from "i18n";
import { getFormatedDate } from "features/dashboard/dateHelper";
import { useTranslation } from "react-i18next";

const DashboardNavbar = () => {
  const { i18n, t } = useTranslation();
  const currentDate = getFormatedDate();

  return (
    <Flex
      w="100%"
      align="flex-start"
      justify="space-between"
      direction="column"
    >
      <Flex w="100%">
        <Heading color="white" fontSize="40px">
          {t("Welcome to Lamina clinic")}
        </Heading>
        {/*TODO: uncomment*/}
        {/* <Spacer />
        <Flex display={{ base: "none", sm: "block" }}>
          <Popover arrowShadowColor="transparent">
            <PopoverTrigger>
              <Button variant="ghost" color="white">
                {i18n.language.toUpperCase()}
              </Button>
            </PopoverTrigger>
            <PopoverContent
              zIndex={4}
              bg={"#589AAF"}
              width="80px"
              border={"none"}
            >
              <PopoverArrow
                backgroundColor={"#589AAF"}
                border="none !important"
              />
              <PopoverBody
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Button
                  onClick={() => i18n.changeLanguage("en")}
                  variant="ghost"
                  color={"white"}
                >
                  EN
                </Button>
                <Button
                  onClick={() => i18n.changeLanguage("sv")}
                  variant="ghost"
                  color={"white"}
                >
                  SV
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex> */}
      </Flex>

      <Text color="white" fontSize="20px">
        {currentDate}
      </Text>
    </Flex>
  );
};

export default DashboardNavbar;
