import {
  Box,
  Heading,
  Hide,
  Show,
  useBreakpoint,
  VStack,
} from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import NavbarMobile from "../Navbar/NavbarMobile/NavbarMobile";
import { Switch, useLocation } from "react-router-dom";
import error_bg from "assets/images/error_bg.svg";
import bg from "assets/images/bg.svg";
import { routes } from "../../../routes";
import AnimatedPage from "features/routesAnimation/components/AnimatedPage";
import AnimatedDefaultLayoutNavbar from "features/routesAnimation/components/AnimatedDefaultLayoutNavbar";
import { useWindowSize } from "services/hooks/useWindowSize";
import ContactBubble from "features/contactForm/components/ContactBubble";

interface DefaultLayoutProps {
  children?: React.ReactNode[] | React.ReactNode;
  fullWidth?: boolean;
  minimalistic?: true;
}

const DefaultLayout: FunctionComponent<DefaultLayoutProps> = ({
  children,
  fullWidth,
  minimalistic,
}) => {
  const [background, setBackground] = useState<any>();

  const windowSize = useWindowSize();

  return (
    <VStack
      alignItems={"center"}
      justifyContent={"flex-start"}
      w={"100% !important"}
      minH={"100vh !important"}
      pt={"2rem"}
      position={"relative"}
      bgImage={background}
      backgroundSize={"cover"}
      spacing={0}
      overflow="hidden"
    >
      {windowSize[0] > 1200 ? (
        <Navbar superminimalistic={minimalistic} />
      ) : (
        <NavbarMobile />
      )}

      <VStack
        width={"100%"}
        justify="flex-start"
        align="center"
        maxWidth={{
          base: "100%",
          sm: "1250px",
          lg: "1680px",
        }}
        px={{ base: "3%", sm: "5%" }}
        flexGrow={"1"}
        pb={"6vh !important"}
      >
        <VStack
          width={fullWidth ? "100%" : { base: "98%", sm: "100%" }}
          flexGrow={"1"}
          pb={"0 !important"}
          justifyContent={"stretch"}
        >
          {children}
        </VStack>
      </VStack>
      <ContactBubble />
    </VStack>
  );
};

export default DefaultLayout;
