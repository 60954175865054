import { Hide, Show, VStack } from "@chakra-ui/react";
import WebinarAnnouncement from "features/webinar/components/webinarAnnouncement";
import WebinarAnnouncmentMobile from "features/webinar/components/webinarAnnouncmentMobile";
import WebinarHero from "features/webinar/components/webinarHero";
import WebinarHeroMobile from "features/webinar/components/webinarHeroMobile";
import WebinarSpeakers from "features/webinar/components/webinarSpeakers";
import { useEffect } from "react";

const Webinar = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "white";
    return () => {
      document.body.style.backgroundColor = "#9BC2CF";
    };
  }, []);

  return (
    <VStack w="full">
      <Show above="sm">
        <WebinarHero />
      </Show>
      <Hide above="sm">
        <WebinarHeroMobile />
      </Hide>
      <Show above="sm">
        <WebinarAnnouncement />
      </Show>
      <Hide above="sm">
        <WebinarAnnouncmentMobile />
      </Hide>
      <WebinarSpeakers />
    </VStack>
  );
};

export default Webinar;
