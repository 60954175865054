import { Button, ButtonGroup, Flex, Image } from "@chakra-ui/react";
import app_store_1 from "assets/icons/app_store_1.svg";
import google_play from "assets/images/google-play-badge.svg";
import { useTranslation } from "react-i18next";

type FaqLinksProps = {
  onContactUsClick: () => void;
};

export const FaqLinks: React.FC<FaqLinksProps> = ({ onContactUsClick }) => {
  const { t } = useTranslation();
  return (
    <ButtonGroup
      mt="3rem !important"
      flexDir={{ base: "column", md: "row" }}
      gap="1rem"
    >
      <Flex width={"100%"} gap="1rem" direction={{ base: "column", md: "row" }}>
        <Image
          src={app_store_1}
          alt={"App Store app link"}
          minW={"190px"}
          cursor={"pointer"}
          onClick={() => {
            window.open(
              "https://apps.apple.com/us/app/lamina-clinic/id6448011545",
              "_blank"
            );
          }}
        />

        <Image
          src={google_play}
          alt={"Play Market app link"}
          minW={"210px"}
          cursor={"pointer"}
          onClick={() => {
            window.open(
              "https://play.google.com/store/apps/details?id=com.laminaclinic.laminaclinic",
              "_blank"
            );
          }}
        />
      </Flex>
      {/* <Button onClick={onContactUsClick} fontSize="1rem">
        {t("Contact Us")}
      </Button> */}
    </ButtonGroup>
  );
};
