import {
  Box,
  Button,
  Divider,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from "@chakra-ui/react";
import { DateRangeType } from "features/userInfo/api/type";
import { FunctionComponent, useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import classes from "./styles.module.scss";
import { AiOutlineCalendar } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { default as svlocale } from "date-fns/locale/sv";
import ChartPeriodToggle from "features/admin/components/adminMoodCheckup/components/ChartPeriodToggle";

type FreeFormSelector = 0 | 1 | 3;

interface CalendarDateSelectionProps {
  dateRange: DateRangeType;
  setDateRange: (newDateRange: DateRangeType) => void;
}

const CalendarDateSelection: FunctionComponent<CalendarDateSelectionProps> = ({
  dateRange,
  setDateRange,
}) => {
  const { t } = useTranslation();
  const [localDateRange, setLocalDateRange] = useState<DateRangeType>({
    dateFrom: dateRange.dateFrom ?? dateRange.dateTo,
    dateTo: dateRange.dateTo,
  });

  const months1time = 30 * 24 * 60 * 60 * 1000;

  const months3time = 3 * months1time;

  const [activeButton, setActiveButton] = useState<FreeFormSelector>(0);

  const handleClick = (selectedButton: FreeFormSelector) => {
    if (selectedButton !== activeButton) {
      setActiveButton(selectedButton);

      switch (selectedButton) {
        case 0:
          setDateRange({
            dateFrom: undefined,
            dateTo: new Date(),
          });
          break;
        case 1:
          setDateRange({
            dateFrom: new Date(new Date().getTime() - months1time),
            dateTo: new Date(),
          });
          break;
        case 3:
          setDateRange({
            dateFrom: new Date(new Date().getTime() - months3time),
            dateTo: new Date(),
          });
          break;
        default:
          break;
      }
    }
  };

  return (
    <VStack>
      <Popover placement="bottom-end">
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                variant={"outline"}
                fontSize="1rem"
                borderColor={"#589AAF"}
                border={"1px solid"}
                color={isOpen ? "white" : "#589AAF"}
                leftIcon={<AiOutlineCalendar fontSize={"1.5rem"} />}
                px={"1.5rem"}
                lineHeight={"1rem"}
                bg={isOpen ? "rgba(88, 154, 175, 1)" : "transparent"}
                transition={"all 0.3s"}
                _hover={{ bg: "" }}
              >
                {dateRange.dateFrom
                  ? dateRange.dateFrom.toLocaleDateString(
                      t("locale") ?? "en-GB",
                      {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }
                    ) +
                    " - " +
                    dateRange.dateTo.toLocaleDateString(
                      t("locale") ?? "en-GB",
                      {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }
                    )
                  : dateRange.dateTo.toLocaleDateString(
                      t("locale") ?? "en-GB",
                      {
                        month: "long",
                        year: "numeric",
                      }
                    )}
              </Button>
            </PopoverTrigger>
            <PopoverContent
              boxShadow={"0px 4px 8px rgba(88, 154, 175, 0.5)"}
              border={"1px solid"}
              borderColor={"#589AAF"}
              overflow={"hidden"}
              pb={"2rem"}
              px={"1rem"}
              width={"fit-content"}
            >
              <VStack spacing={0}>
                <DateRange
                  classNames={{
                    dateDisplayWrapper: classes.hider,
                    dateDisplay: classes.hider,
                  }}
                  rangeColors={["#589AAF"]}
                  locale={svlocale}
                  ranges={[
                    {
                      startDate: localDateRange.dateFrom,
                      endDate: localDateRange.dateTo,
                    },
                  ]}
                  onChange={(values) => {
                    setLocalDateRange({
                      dateFrom: values.range1.startDate,
                      dateTo: values.range1.endDate ?? new Date(),
                    });
                  }}
                />

                <Button
                  variant={"outline"}
                  border={"1px solid"}
                  borderColor={"#589AAF"}
                  color={"#589AAF"}
                  maxH={"2.5em !important"}
                  w={"auto"}
                  px={"1.1em"}
                  fontSize={"1.125rem !important"}
                  onClick={() => {
                    setDateRange(structuredClone(localDateRange));
                    onClose();
                  }}
                >
                  {t("Set")}
                </Button>
              </VStack>
            </PopoverContent>
          </>
        )}
      </Popover>

      {/*  <Flex w="full" justify="flex-end" alignSelf="flex-end">
        <Box
          py=".375rem"
          px="1.25rem"
          border="1px solid #E5E9EB"
          borderLeftRadius=".5rem"
          bg={activeButton === 0 ? "#589AAF1A" : "transparent"}
          color={activeButton === 0 ? "#589AAF" : "rgba(0,38,55,0.5)"}
          fontWeight={activeButton === 0 ? 700 : 400}
          cursor="pointer"
          fontSize=".875rem"
          onClick={() => handleClick(0)}
        >
          See hala Perioden
        </Box>
        <Box
          py=".375rem"
          px="1.25rem"
          borderTop="1px solid #E5E9EB"
          borderBottom="1px solid #E5E9EB"
          borderLeft="none"
          borderRight="none"
          bg={activeButton === 1 ? "#589AAF1A" : "transparent"}
          color={activeButton === 1 ? "#589AAF" : "rgba(0,38,55,0.5)"}
          fontWeight={activeButton === 1 ? 700 : 400}
          cursor="pointer"
          fontSize=".875rem"
          onClick={() => handleClick(1)}
        >
          1 Månader
        </Box>
        <Box
          py=".375rem"
          px="1.25rem"
          border="1px solid #E5E9EB"
          borderRightRadius=".5rem"
          bg={activeButton === 3 ? "#589AAF1A" : "transparent"}
          color={activeButton === 3 ? "#589AAF" : "rgba(0,38,55,0.5)"}
          fontWeight={activeButton === 3 ? 700 : 400}
          cursor="pointer"
          fontSize=".875rem"
          onClick={() => handleClick(3)}
        >
          3 Månader
        </Box>
      </Flex> */}
    </VStack>
  );
};

export default CalendarDateSelection;
