import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Tag,
  TagCloseButton,
  Wrap,
  Text,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { statusTextColor } from "features/admin/helper/columns";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputType } from "services/types/types";
import ManualSelect from "ui/components/ManualSelect";

interface StatusSelectProps extends InputType<string[]> {}

const statusOptions = [
  { value: "prescription_completed", label: "Recept skickat" },
  { value: "registered", label: "Registrerad" },
  { value: "meeting_with_doctor", label: "Möte med läkare" },
  { value: "meeting_with_nurse", label: "Möte med sköterska" },
  { value: "prescription_approved", label: "Recept godkänd" },
  { value: "order_placed", label: "Läkemedel beställt" },
  { value: "payment_processing", label: "Betalning i process" },
  { value: "payment_made", label: "Betalning gjord" },
  { value: "shipped", label: "Läkemedel skickat" },
  { value: "treatment_completed", label: "Färdigbehandlad" },
  { value: "on_treatment", label: "Under behandling" },
];

const StatusSelect: React.FC<StatusSelectProps> = ({ value, onChange }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const { t } = useTranslation();

  function getStatusTextColor(status: string) {
    const statusObject = statusTextColor.find(
      (obj: { value: any }) => obj.value === status
    );
    return statusObject ? statusObject.color : "black";
  }

  useEffect(() => {
    onChange(selectedValues);
  }, [selectedValues]);

  //ISSUEPRONE
  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  const handleTagClose = (value: string) => {
    setSelectedValues(selectedValues.filter((e) => e !== value));
  };

  return (
    <Flex w="100%" direction="column" gap="12px">
      <FormControl>
        <FormLabel
          fontSize="14px"
          fontWeight={600}
          color="rgba(255, 255, 255, 1)"
          lineHeight="20px"
          mb="0.9rem"
        >
          STATUS
        </FormLabel>

        <ManualSelect
          closeOnSelect={false}
          options={statusOptions.map((e) => {
            return {
              value: e.value,
              label: (
                <Flex alignItems={"center"} gap={"1rem"}>
                  <Checkbox
                    variant={"admin"}
                    isChecked={selectedValues.includes(e.value)}
                    onChange={() => {
                      if (selectedValues.includes(e.value)) {
                        setSelectedValues(
                          selectedValues.filter((e1) => e1 !== e.value)
                        );
                      } else {
                        setSelectedValues([...selectedValues, e.value]);
                      }
                    }}
                  />
                  <Text fontSize={"1rem"}>{e.label}</Text>
                </Flex>
              ),
            };
          })}
          _containerStyles={{
            border: "1px solid rgba(255, 255, 255, 0.5)",
          }}
          _dropdownStyles={{
            borderTop: "1px solid rgba(255, 255, 255, 0.5)",
            maxH: "15rem",
            overflowY: "auto",
            gap: "0.5rem",
          }}
          _placeholderStyles={{
            color: "white",
          }}
          _valueStyles={{
            color: "white",
          }}
          customValueMapper={() => {
            if (selectedValues.length === 0) {
              return "";
            }
            return (
              <Text fontSize={"1rem"} opacity={"0.5"} fontWeight={"400"}>
                Please select...
              </Text>
            );
          }}
        />
      </FormControl>

      <Wrap w="100%">
        {selectedValues.map((value) => (
          <Tag
            key={value}
            size="md"
            bg="rgba(255, 255, 255, 1)"
            p={{ base: "2px 10px", lg: "4px 12px" }}
            fontSize={{
              base: "8px",
              sm: "8px",
              md: "11px",
              lg: "16px",
            }}
            fontWeight={700}
            color={getStatusTextColor(value)}
            mr="4px"
            mb="4px"
            width="auto"
          >
            {t(
              value
                .replaceAll("_", " ")
                .split(" ")
                .map((word) => {
                  return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(" ")
            )}
            <TagCloseButton
              onClick={() => {
                handleTagClose(value);
              }}
            />
          </Tag>
        ))}
      </Wrap>
    </Flex>
  );
};

export default StatusSelect;
