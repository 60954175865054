import { APIClient } from "services/api/client";
import useSWR from "swr";

export const useGetDayOffCSV = () => {
  return useSWR<Blob, Error>(
    `/working-days/download/`,
    (url: string) => APIClient.get(url),
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
    }
  );
};
