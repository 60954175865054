import { useAdminUserInfo } from "features/admin/api/client";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";
import { useParams } from "react-router-dom";
import { APIClient } from "services/api/client";
import { useOrderStore } from "services/state/orderStore";
import useSWR, { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";
import {
  CreateOrderRequestType,
  CreateOrderResponseType,
  DashboardInfoResponseType,
  UserProfileType,
  UserScheduleResponseType,
} from "./type";

export const useCreateOrder = () => {
  const { mutate } = useSWRConfig();
  const { beginCheckoutEvent } = useGoogleAnalytics();

  const { data: dashboardData, mutate: updateData } = useDashboardInfo();

  return useSWRMutation(
    "order/",
    (url: string, { arg }) => {
      return APIClient.post<CreateOrderRequestType, CreateOrderResponseType>(
        url,
        arg.order
      );
    },
    {
      onSuccess: async (data) => {
        await updateData();

        beginCheckoutEvent(
          dashboardData?.order?.currency ?? "KR",
          data.price,
          data.medication_type + " " + data.dosage + "mg"
        );
        mutate("dashboard");
      },
    }
  );
};

export const useGetOrderStatus = (order_ref: string) => {
  return useSWR(
    `collect_status/?order_ref=${order_ref}`,
    async (url) => {
      const res = await APIClient.get(url);
      return res.data;
    },
    {
      onSuccess(data) {
        console.log("Order status received successfully");
      },
      onError(error) {
        console.log("Error getting order status: ", error);
      },
    }
  );
};

export const useDashboardInfo = () => {
  const { setCurrentStep, setCurrentStepState } = useOrderStore();
  return useSWR<DashboardInfoResponseType>(
    "dashboard",
    (url) => APIClient.get(url),
    {
      onError() {
        setCurrentStep(0);
        setCurrentStepState("completed");
      },
    }
  );
};

export const useFetchProfileInfo = () => {
  return useSWR<UserProfileType>("profile/", (url) => APIClient.get(url));
};

export const usePatchProfile = () => {
  const { mutate, data: userData } = useFetchProfileInfo();

  return useSWRMutation(
    "profile/" + userData?.id + "/",
    (url: string, { arg }) => {
      return APIClient.patch<any, any>(url, arg);
    },
    {
      onSuccess: (data) => {
        mutate();
      },
    }
  );
};

export const usePatchAdminUserProfile = (id: string) => {
  const { mutate } = useAdminUserInfo(id);

  return useSWRMutation(
    `admin/users/${id}/`,
    (url: string, { arg }: { arg: any }) => {
      return APIClient.patch(url, arg);
    },
    {
      onSuccess: (data) => {
        mutate();
      },
      rollbackOnError: false,
    }
  );
};

export const useFetchUserSchedule = (page: number = 0) => {
  return useSWR<UserScheduleResponseType>("treatment-list", (url) =>
    APIClient.get(url)
  );
};

export const useUpdateDayDose = (treatmentId: string) => {
  const { id } = useParams<{ id: string }>();
  const { mutate } = useAdminUserInfo(id);

  return useSWRMutation(
    `admin/treatment/${treatmentId}`,
    (
      url,
      {
        arg,
      }: {
        arg: {
          dosage: number;
          day_of_tapering: number;
        };
      }
    ) => {
      return APIClient.put(url, arg);
    },
    {
      onSuccess: () => {
        mutate();
      },
    }
  );
};
