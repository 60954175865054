import { APIClient } from "services/api/client";
import useSWR from "swr";
import { NewTaperingResponseType } from "./types";
import useSWRMutation from "swr/mutation";

export const useFetchCSVTableByMedicineName = (
  medication_id: string,
  taperingRate: number,
  pharmacy?: string
) => {
  return useSWR<NewTaperingResponseType>(
    "admin/tapering/?medication_id=" +
      medication_id +
      "&type=" +
      taperingRate +
      (pharmacy ? "&factory=" + pharmacy : ""),
    (url) => {
      return APIClient.get(url);
    }
  );
};

export const useImportCSV = (
  medicineName: string,
  taperingRate: number,
  pharmacy?: string
) => {
  const { mutate } = useFetchCSVTableByMedicineName(medicineName, taperingRate);
  return useSWRMutation<any, any>(
    ["admin/tapering/", medicineName, pharmacy],
    ([url, medicineName]: string[], { arg }: { arg: any }) => {
      return APIClient.post(
        url + (pharmacy ? "?factory=" + pharmacy.toLocaleLowerCase() : ""),
        arg
      );
    },
    {
      onSuccess: () => {
        mutate();
      },
    }
  );
};

export const useFetchCSVFileFromServer = (
  medication_id: string,
  taperingRate: number,
  pharmacy?: string
) => {
  return useSWR<Blob, Error>(
    "admin/tapering/download/?medication_id=" +
    medication_id +
      "&type=" +
      taperingRate +
      (pharmacy ? "&factory=" + pharmacy : ""),
    (url) => {
      return APIClient.get(url);
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
    }
  );
};
