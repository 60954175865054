import {
  Flex,
  Link as ChakraLink,
  Heading,
  Text,
  Image,
  Box,
} from "@chakra-ui/react";
import FaqDashboardQuestions from "../faqDashboarQuestions";

import { useTranslation } from "react-i18next";

import pattern_faq from "assets/icons/pattern_faq.svg";
import { useHistory } from "react-router-dom";
import FaqQuestions from "../faqQuestions";

const FaqDashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Flex w="full" align="flex-start" direction="column" gap="24px">
      <Box w="full" borderRadius="10px" bg="rgba(255, 255, 255, 1)" p="40px">
        <FaqQuestions />
      </Box>

      <Flex
        w="full"
        p="40px"
        borderRadius="12px"
        bg="blueish.500"
        align="flex-start"
        direction="column"
        position="relative"
      >
        <Image
          src={pattern_faq}
          position="absolute"
          right={0}
          top={{ base: 5, sm: 0 }}
          bottom={0}
          objectFit="none"
          h="100%"
        />
        {/* <Heading
          fontSize={{ base: "24px", sm: "28px" }}
          color="white"
          fontWeight={500}
        >
          {t("Don't see your question answered here?")}
        </Heading>
        <Text fontSize={{ base: "16px", sm: "20px" }} fontWeight={400}>
          {t(
            "Please feel free to contact us and we will be happy to assist you."
          )}
        </Text> */}
        <ChakraLink
          onClick={() => {
            history.push("/contact-form");
          }}
          cursor="pointer"
        >
          <Text
            color="rgba(238, 232, 169, 1)"
            fontSize={{ base: "16px", sm: "20px" }}
            textDecoration="underline"
            fontWeight={400}
          >
            {t("Contact us")}
          </Text>
        </ChakraLink>
      </Flex>
    </Flex>
  );
};

export default FaqDashboard;
