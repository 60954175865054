import { useFetchProfileInfo } from "features/dashboard/api/client";
import {
  collection,
  doc,
  DocumentData,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "services/firebase/config";
import { FBUserRoomType, InternalCallType } from "../api/types";

export const useMyCalls = () => {
  const { data: profileData } = useFetchProfileInfo();

  const [outgoingCalls, setOutgoingCalls] = useState<FBUserRoomType[]>([]);
  const [incomingCalls, setIncomingCalls] = useState<FBUserRoomType[]>([]);

  const docProcessingFn = (
    doc: QueryDocumentSnapshot<DocumentData, DocumentData>
  ) => {
    return { id: doc.id, ...doc.data() } as FBUserRoomType;
  };

  useEffect(() => {
    const incomingCallsQuery = query(
      collection(db, "userRoomPool"),
      where("status", "==", "requesting"),
      where("to", "==", profileData?.id ?? "")
    );

    const outGoingCallsQuery = query(
      collection(db, "userRoomPool"),
      where("from", "==", profileData?.id ?? "")
    );

    const unsub = onSnapshot(outGoingCallsQuery, (collection) => {
      setOutgoingCalls(collection.docs.map(docProcessingFn));
    });

    const incomingUnsub = onSnapshot(incomingCallsQuery, (collection) => {
      setIncomingCalls(collection.docs.map(docProcessingFn));
    });

    return () => {
      console.log("UNSUBSCRIBED");
      unsub();
      incomingUnsub();
    };
  }, [profileData?.id]);

  return {
    outgoingCalls,
    incomingCalls,
    calls: [...incomingCalls, outgoingCalls],
  };
};
