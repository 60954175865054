import { Flex, Text, Heading } from "@chakra-ui/react";
import {
  useDashboardInfo,
  useFetchProfileInfo,
} from "features/dashboard/api/client";
import { UserTreatmentType } from "features/dashboard/api/type";
import { useTranslation } from "react-i18next";

const UserTreatment = () => {
  const { t } = useTranslation();
  const { data: dashboardData } = useDashboardInfo();
  const { data: profileData } = useFetchProfileInfo();

  return (
    <>
      <Flex direction="column" gap={"1rem"} w="100%">
        <Flex direction="column" gap={"0.25rem"}>
          <Heading fontSize="16px" color={"blueish.500"} fontWeight={500}>
            {t("MEDICATION TYPE")}
          </Heading>
          <Text color="blackish.200" fontSize="16px" fontWeight={500}>
            {dashboardData?.order?.medication_type}
          </Text>
        </Flex>
        <Flex direction="column" gap={"0.25rem"}>
          <Heading fontSize="16px" color={"blueish.500"} fontWeight={500}>
            {t("STARTING DOSAGE")}
          </Heading>
          <Text color="blackish.200" fontSize="16px" fontWeight={500}>
            {dashboardData?.order?.dosage} {t("milligram (mg) per day")}
          </Text>
        </Flex>
        <Flex direction="column" gap={"0.25rem"} w="100%">
          <Heading fontSize="16px" color={"blueish.500"} fontWeight={500}>
            {t("TREATMENT PLAN")}
          </Heading>
          <Text color="blackish.200" fontSize="16px" fontWeight={500}>
            {(profileData?.treatment_duration ?? 0) * 28}{" "}
            {t("days tapering plan")}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default UserTreatment;
