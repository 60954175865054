import {
  Flex,
  HStack,
  VStack,
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Heading,
  PopoverFooter,
  Box,
} from "@chakra-ui/react";
import { useChangeStartTreatmentDate } from "features/admin/api/client";
import { useAdminUserInfo } from "features/userInfo/api/client";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCalendar } from "react-icons/ai";
import { useParams } from "react-router-dom";
import DatePicker from "../DatePicker";

interface UserPlanStartDateChangeProps {}

const UserPlanStartDateChange: FunctionComponent<
  UserPlanStartDateChangeProps
> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: userData } = useAdminUserInfo(id);
  const { t } = useTranslation();
  const { trigger: changeDateCall, isMutating } = useChangeStartTreatmentDate(
    id ?? ""
  );

  const startDate = useMemo(() => {
    if (userData && userData.treatment) {
      return new Date(userData.treatment.start_date);
    }
    return null;
  }, [userData]);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>();

  useEffect(() => {
    console.log("🚀 ~ file: index.tsx:42 ~ startDate:", startDate);
    if (startDate) {
      setSelectedDate(new Date(startDate.getTime()));
    }
  }, [startDate]);

  return (
    <HStack w="full" gap={"1em"} justify="space-between">
      <VStack align="flex-start">
        <Heading
          color="rgba(88, 154, 175, 0.8)"
          fontSize={"20px"}
          fontWeight={600}
          letterSpacing={0.7}
        >
          {t("SUGGESTED TREATMENT PLAN")}
        </Heading>
        <Flex alignItems={"center"} gap={"0.25rem"}>
          <Text
            fontSize={{ md: "14px", lg: "stnd" }}
            color="rgba(0, 38, 55, 0.5)"
            fontWeight={400}
          >
            {t("Plan start date")}:
          </Text>
          <Text
            color="rgba(0, 38, 55, 1)"
            fontSize={{ md: "14px", lg: "1rem" }}
            fontWeight={700}
          >
            {startDate?.toLocaleDateString(t("locale") ?? "en-GB", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </Text>
        </Flex>
      </VStack>
      <Flex></Flex>
    </HStack>
  );
};

export default UserPlanStartDateChange;
