import { Box, Flex, Td, Text, Tr } from "@chakra-ui/react";
import { roleNames, serviceColors, serviceNames, textColor } from "./utils";
import EditServicePrice from "./components/EditServicePrice";
import { useTranslation } from "react-i18next";

type ServiceRowProps = {
  serviceID: string;
  type: string;
  role: string;
  name: string;
  price: string;
};

const ServiceRow: React.FC<ServiceRowProps> = ({
  serviceID,
  type,
  role,
  name,
  price,
}) => {
  const { t } = useTranslation();
  return (
    <Tr key={serviceID} cursor="pointer">
      <Td>
        <Flex w="full" justify="space-between">
          <Box py="2" px="3" bg={serviceColors[name]} borderRadius=".5rem">
            <Text
              color={textColor[name]}
              fontWeight={700}
              fontSize="1rem"
              textTransform="uppercase"
            >
              {t(serviceNames[name])}
            </Text>
          </Box>
        </Flex>
      </Td>
      <Td>
        <Flex w="full" justify="space-between">
          <Text color="black" fontWeight={600} opacity={0.7} fontSize="1rem">
            {type.toLowerCase()}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Flex w="full" justify="space-between">
          <Text
            color="black"
            fontWeight={600}
            opacity={0.7}
            fontSize="1rem"
            textTransform="capitalize"
          >
            {t(roleNames[role])}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Flex justify="flex-end" align="baseline" gap={2}>
          <Text
            color="black"
            fontWeight={700}
            textAlign="right"
            fontSize="1.2rem"
          >
            {price} SEK
          </Text>
          <EditServicePrice serviceID={serviceID} />
        </Flex>
      </Td>
    </Tr>
  );
};

export default ServiceRow;
