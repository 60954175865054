import {
  Container,
  Heading,
  VStack,
  Text,
  Flex,
  Divider,
  Image,
} from "@chakra-ui/react";
import FilterButton from "features/reporting/components/FilterButton";
import { useFetchAdminReport } from "features/reporting/api/client";
import GeneralStatsBar from "features/reporting/components/GeneralStatsBar";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import UserLists from "features/reporting/components/UserLists";

import { useHistory } from "react-router-dom";
import left_arrow from "assets/icons/left_arrow.svg";
import ReportLogs from "features/reporting/components/ReportLogs";

interface ReportsPageProps {}

const ReportsPage: FunctionComponent<ReportsPageProps> = () => {
  const { t } = useTranslation();
  const { data: adminReportData } = useFetchAdminReport();

  const history = useHistory();

  const handleGoBack = () => {
    history.push("/superadmin");
  };

  return (
    <>
      <VStack alignItems={"stretch"} w={"100%"} pt={"3rem"} pb={"3rem"}>
        <Flex gap={2} onClick={handleGoBack} cursor="pointer" pb="1rem">
          <Image src={left_arrow} />
          <Text fontSize="14px" color="rgba(0, 38, 55, 0.8)" fontWeight={700}>
            {t("Back")}
          </Text>
        </Flex>

        <Heading fontSize={"2rem"}>{t("General reports")}</Heading>
        <Text
          color="rgba(0, 38, 55, 0.5)"
          fontSize={{ base: "12px", md: "14px", lg: "16px" }}
          fontWeight={400}
          textTransform={"capitalize"}
        >
          {new Date().toLocaleDateString(t("locale") ?? "en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
            weekday: "long",
          })}
        </Text>
        <Flex w={"100%"} justifyContent={"flex-end"}>
          <FilterButton />
        </Flex>
        <Divider
          borderColor={"rgba(0, 0, 0, 0.2)"}
          mb={"1.5rem !important"}
          mt={"0 !important"}
        />
        <GeneralStatsBar />
        <UserLists />
        <ReportLogs />
      </VStack>
    </>
  );
};

export default ReportsPage;
