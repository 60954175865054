import { FunctionComponent, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAdminStore } from "services/state/adminStore";
import { useTokenStore } from "services/state/tokenStore";

interface ProtectedRouteProps {
  children: React.ReactNode | React.ReactNode[];
  [key: string]: any;
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
  children,
  ...rest
}) => {
  const { access, refresh } = useTokenStore();
  const { isAdmin } = useAdminStore();

  const isLoggedIn = access !== "" && refresh !== "" && !isAdmin;

  useEffect(() => {
    console.log("🚀 ~ file: index.tsx:23 ~ isLoggedIn:", isLoggedIn);
  }, [isLoggedIn]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }}
    ></Route>
  );
};

export default ProtectedRoute;
