import React, { FunctionComponent, useState } from "react";
import { Flex, Text, Checkbox, Input } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type TaperingDaysChekboxesProps = {
  value: number;
  onChange: (value: number) => void;
};

const TaperingDaysCheckboxes: FunctionComponent<TaperingDaysChekboxesProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const [customValue, setCustomValue] = useState<number>(7);
  const [isCustomSelected, setIsCustomSelected] = useState(false);

  const handleCheckboxChange = (newValue: number) => {
    onChange(newValue);
    setCustomValue(0);
    setIsCustomSelected(false);
  };

  const handleCustomInputChange = (e: { target: { value: any } }) => {
    const newValue = e.target.value;
    setCustomValue(newValue);
    onChange(parseInt(newValue));
    setIsCustomSelected(true);
  };

  return (
    <Flex w="full" direction="column" gap={3}>
      <Text
        color="#002637"
        opacity="0.5"
        fontSize=".875rem"
        fontFamily="secondary"
        fontWeight={400}
      >
        {t("Number of days between dose adjustments").toUpperCase()}
      </Text>
      <Flex gap="2.5rem" align="center">
        {[7, 14, 30, 60].map((days) => (
          <>
            <Checkbox
              style={{
                background:
                  value === days && !isCustomSelected ? "none" : "#589AAF33",
                borderRadius: "4px",
              }}
              _checked={{
                "& .chakra-checkbox__control": {
                  background: "rgba(88, 154, 175, 1)",
                  border: "none",
                },
              }}
              key={days}
              isChecked={value === days && !isCustomSelected}
              onChange={() => handleCheckboxChange(days)}
              size="lg"
            ></Checkbox>
            <Text
              ml="-8"
              color="#002637"
              fontSize="1rem"
              fontWeight={value === days && !isCustomSelected ? 700 : 400}
              opacity={value === days && !isCustomSelected ? 1 : 0.5}
            >
              {days} DAYS
            </Text>
          </>
        ))}
        <Checkbox
          isChecked={isCustomSelected}
          style={{
            background: isCustomSelected ? "none" : "#589AAF33",
            borderRadius: "4px",
          }}
          _checked={{
            "& .chakra-checkbox__control": {
              background: "rgba(88, 154, 175, 1)",
              border: "none",
            },
          }}
          onChange={() => setIsCustomSelected(!isCustomSelected)}
          size="lg"
          mr={4}
        ></Checkbox>
        <Input
          placeholder="Enter custom"
          _placeholder={{ fontSize: "1rem" }}
          minH="0.8rem !important"
          bg="rgba(0, 38, 55, 0.05) !important"
          ml="-10"
          type="number"
          value={isCustomSelected ? customValue : ""}
          onChange={handleCustomInputChange}
          width="200px"
          isDisabled={
            !isCustomSelected && value !== undefined && value !== null
          }
        />
        <Text
          ml="-4"
          color="#002637"
          fontSize="1rem"
          fontWeight={isCustomSelected ? 700 : 400}
          opacity={isCustomSelected ? 1 : 0.5}
        >
          (max 365) DAYS
        </Text>
      </Flex>
    </Flex>
  );
};

export default TaperingDaysCheckboxes;
