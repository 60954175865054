import { Button, Center, Heading, Image, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import check from "assets/icons/check.svg";

interface SuccessFormProps {
  password: string;
  onClose(): void;
}

const SuccessForm: FunctionComponent<SuccessFormProps> = ({
  password,
  onClose,
}) => {
  return (
    <Center flexDirection={"column"} w={"100%"} minH={"50vh"}>
      <Image src={check} mb={"1.25rem"} />
      <Heading
        fontFamily={"secondary"}
        fontSize={"2rem"}
        maxW={"20rem"}
        textAlign={"center"}
        lineHeight={"2.5rem"}
        mb={"2rem"}
      >
        New user has been successfully added!
      </Heading>
      <Text color={"blueish.900"} fontSize={"1.25rem"} mb={"2rem"}>
        User can authorize using BankID with corresponding personal number
        attached
      </Text>

      <Button
        variant={"unstyled"}
        bg={"blueish.500"}
        color={"white"}
        minW={"14rem"}
        h={"3rem"}
        fontSize={"1rem"}
        onClick={() => {
          onClose();
        }}
      >
        Close
      </Button>
    </Center>
  );
};

export default SuccessForm;
