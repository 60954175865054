import { Flex, Heading, VStack, Text, Image, Box } from "@chakra-ui/react";
import { FunctionComponent } from "react";

import pills_1 from "assets/icons/pills_1.svg";
import {
  useDashboardInfo,
  useFetchProfileInfo,
} from "features/dashboard/api/client";
import { useTranslation } from "react-i18next";
import { useCheckLogin } from "features/login/api/client";
import { StatusResponseType } from "features/login/api/types";
import { useFetchSubscription } from "features/addressPopup/api/client";

interface MedicationSidebarProps {}

const MedicationSidebar: FunctionComponent<MedicationSidebarProps> = () => {
  const { data: dashboardData } = useDashboardInfo();
  const { data: subscriptionData } = useFetchSubscription();
  const { data: profileData } = useFetchProfileInfo();

  const { t } = useTranslation();
  return (
    <Flex
      direction={"column"}
      justifyContent={"space-between"}
      bg={"rgba(88, 154, 175, 0.1)"}
      w={{ base: "auto", sm: "364px" }}
      p={"3.5rem 2.5rem 3.5rem 2.5rem"}
    >
      <VStack alignItems={"flex-start"} spacing={"1.5rem"}>
        <Image src={pills_1} alt={"pills decorative icon"} />

        <Box>
          <Heading
            textTransform={"uppercase"}
            color={"blueish.500"}
            fontWeight={"600"}
            fontSize={{ base: "0.8re0", lg: "stnd" }}
            mb={"0.25rem"}
          >
            {t("MEDICATION TYPE")}
          </Heading>
          <Text color={"blueish.900"} fontSize={"md"}>
            {dashboardData?.order?.medication_type ??
              dashboardData?.medication?.name}
          </Text>
        </Box>

        <Box>
          <Heading
            textTransform={"uppercase"}
            color={"blueish.500"}
            fontWeight={"600"}
            fontSize={{ base: "0.8re0", lg: "stnd" }}
            mb={"0.25rem"}
          >
            {t("STARTING DOSAGE")}
          </Heading>
          <Text
            color={"blueish.900"}
            fontSize={"md"}
            fontWeight={"400 !important"}
          >
            {dashboardData?.order?.dosage ?? dashboardData?.medication?.dose}{" "}
            {t("milligram (mg) per day")}
          </Text>
        </Box>

        <Box>
          <Heading
            textTransform={"uppercase"}
            color={"blueish.500"}
            fontWeight={"600"}
            fontSize={{ base: "0.8re0", lg: "stnd" }}
            mb={"0.25rem"}
          >
            {t("TREATMENT PLAN")}
          </Heading>
          <Text color={"blueish.900"} fontSize={"md"}>
            {(profileData?.treatment_duration ?? 0) * 28}{" "}
            {t("days tapering plan")}
          </Text>
        </Box>
      </VStack>

      <VStack alignItems={"flex-start"} spacing={0} mt={{ base: 5, sm: 0 }}>
        <Heading
          fontSize={{ base: "0.8re0", lg: "stnd" }}
          textTransform={"uppercase"}
          color={"blueish.500"}
          fontWeight={600}
          pb={"0.5rem"}
          lineHeight={"1em"}
        >
          {t("PRICE")}
        </Heading>

        <Text fontSize={"lg"} color={"blueish.900"} fontWeight={700}>
          {subscriptionData?.price} {subscriptionData?.currency}
        </Text>
      </VStack>
    </Flex>
  );
};

export default MedicationSidebar;
