import { Heading, HStack, VStack, Text, Flex, Select } from "@chakra-ui/react";
import { useFetchCSVTableByMedicineName } from "features/taperingCSV/api/client";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CiPillsBottle1 } from "react-icons/ci";

interface PharmacyBottlesProps {
  medicationType: string;
  taperingRate: number;
  pharmacy?: string;
}

const PharmacyBottles: FunctionComponent<PharmacyBottlesProps> = ({
  medicationType,
  taperingRate,
  pharmacy,
}) => {
  const { data: rawTableData } = useFetchCSVTableByMedicineName(
    medicationType,
    taperingRate,
    pharmacy
  );

  const { t } = useTranslation();

  const [selectedDuration, setSelectedDuration] = useState<number[]>([0, 84]);

  const treatmentOptions = useMemo(() => {
    return new Array(Math.ceil((rawTableData?.tapering.length ?? 0) / 84))
      .fill(undefined)
      .map((e, index) => {
        return {
          label: "months " + (1 + index * 3) + " to " + (index + 1) * 3,
          value: [index * 84, index * 84 + 84],
        };
      });
  }, [rawTableData]);

  const bottlesData = useMemo(() => {
    const slicedData =
      rawTableData?.tapering.slice(selectedDuration[0], selectedDuration[1]) ??
      [];

    return [
      {
        name: "Regular",
        bottles: slicedData
          .flatMap((e) => e.regular)
          .reduce((acc: any, curr) => {
            return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
          }, {}),
      },
      {
        name: "APL",
        bottles: slicedData
          .flatMap((e) => e.apl)
          .reduce((acc: any, curr) => {
            return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
          }, {}),
      },
      // {
      //   name: "RegenBoogen",
      //   bottles: slicedData
      //     .flatMap((e) => e.regenboogen)
      //     .reduce((acc: any, curr) => {
      //       return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
      //     }, {}),
      // },
    ];
  }, [rawTableData, selectedDuration]);

  return (
    <VStack
      alignItems={"flex-start"}
      mb={"2rem"}
      gap={"1rem"}
      maxW={"100%"}
      overflowX={"auto"}
    >
      <Flex mb={"-1rem !important"} alignItems={"center"} gap={"0.5rem"}>
        <Text
          color={"blueish.900"}
          w={"fit-content"}
          fontSize={"1rem"}
          opacity={0.6}
          whiteSpace={"nowrap"}
          zIndex={0}
        >
          {t("Medication needed for")}:
        </Text>
        <Select
          fontSize={"1rem"}
          variant="flushed"
          onChange={(e) => {
            setSelectedDuration(JSON.parse(e.target.value));
          }}
        >
          {treatmentOptions.map((e) => {
            return <option value={JSON.stringify(e.value)}>{e.label}</option>;
          })}
        </Select>
      </Flex>
      {bottlesData
        .filter((e) => Object.entries(e.bottles).length > 0)
        .map((bottle) => {
          return (
            <HStack alignItems={"center"}>
              <Heading mr={"1rem"} fontWeight={"400"}>
                {bottle.name}:
              </Heading>
              {bottle.bottles &&
                Object.entries(bottle.bottles).map((e: any[]) => {
                  return (
                    <VStack gap={0} spacing={0} minW={"3.5rem"}>
                      <CiPillsBottle1 fontSize={"1.3rem"} />
                      <Text
                        color={"blueish.900"}
                        opacity={0.4}
                        fontSize={"0.75rem"}
                        zIndex={0}
                      >
                        {e[0]}mg x {e[1]}
                      </Text>
                    </VStack>
                  );
                })}
            </HStack>
          );
        })}
      <HStack alignItems={"center"}>
        <Heading mr={"1rem"} fontWeight={"400"}>
          {t("RegenBoogen: total treatment plan duration is")}{" "}
          <b>{Math.round((rawTableData?.tapering?.length ?? 0) / 7)} weeks</b>
        </Heading>
      </HStack>
    </VStack>
  );
};

export default PharmacyBottles;
