import {
  Box,
  Flex,
  Heading,
  VStack,
  Text,
  HStack,
  Tag,
  Divider,
  Button,
  useDisclosure,
  Wrap,
  UnorderedList,
  ListItem,
  Stack,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { PlanType } from "features/superadmin/api/types";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart, Line, Scatter } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useSaveTreatment } from "features/superadmin/api/client";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import PrescriptionHelper from "features/prescriptionHelper/components/PrescriptionHelperModal";
import { useFetchPrescriptionHelper } from "features/prescriptionHelper/api/client";
import { object } from "yup";

import { AiOutlineDownload } from "react-icons/ai";
import { useFetchCSVFileFromServer } from "features/taperingCSV/api/client";
import DownloadCVSButton from "ui/components/DownloadCSVButton";
import { BsChevronDown } from "react-icons/bs";

ChartJS.register(...registerables);

interface GeneratedTaperingPlanSectionProps {
  plan: PlanType;
  id?: string;
  medicineName: string;
  taperingRate?: number;
  pharmacy: string;
  warning?: string;
  toggleTaperingPlanner: () => void;
  duration: number;
}

const pharmasBoxes = [
  {
    name: "Standard",
    color: "yellow.200",
  },
  {
    name: "APL",
    color: "orange.300",
  },
  {
    name: "Regenboogen",
    color: "red.100",
  },
];

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
};

const GeneratedTaperingPlanSection: FunctionComponent<
  GeneratedTaperingPlanSectionProps
> = ({
  plan,
  id: propsId,
  medicineName,
  pharmacy,
  taperingRate,
  warning,
  toggleTaperingPlanner,
  duration,
}) => {
  const { id: paramsId } = useParams<{ id: string }>();
  const {
    isOpen: isPrescriptionHelperOpen,
    onOpen: onPrescriptionHelperOpen,
    onClose: onPrescriptionHelperClose,
  } = useDisclosure();

  const id = propsId ?? paramsId ?? "";
  const { trigger: saveTreatment, isMutating: isRequestPending } =
    useSaveTreatment(id);

  const { data: profileData } = useFetchProfileInfo();
  const { isLoading: isPrescriptionHelpeLoading } =
    useFetchPrescriptionHelper(id);

  const onClickSavePlan = () => {
    saveTreatment(
      {
        tapering_pace: parseFloat(plan.pace),
        medication: plan.tapering[0].medication.id,
        tapering: plan.tapering.map(({ medication, ...keepers }) => keepers),
        period_duration: duration,
      },
      {
        onSuccess: () => {
          toast.success("Plan successfully saved");
          onPrescriptionHelperOpen();
        },
        onError: () => {
          toast.error("Something went wrong, please try again later");
        },
      }
    );
  };

  const transferPointSearch = (inputArr: any[]) => {
    let valuesArr = [];

    for (let i = 0; i < inputArr.length; i++) {
      if (typeof inputArr[i] === typeof inputArr[i + 1]) {
        valuesArr.push(undefined);
      } else {
        valuesArr.push(inputArr[i]);
      }
    }
    return valuesArr;
  };

  const { t } = useTranslation();

  const chartData = useMemo(() => {
    const regenBoogens = plan.tapering.filter(
      (e) => e.regenboogen.length !== 0
    );

    const standards = plan.tapering.filter((e) => e.regenboogen.length === 0);

    return {
      labels: plan.tapering.map((e) => "") ?? [],
      datasets: [
        {
          type: "line" as const,
          label: "Standard",
          data:
            plan.tapering.map((e) =>
              e.regular.length !== 0 ? e.dose : undefined
            ) ?? [],
          borderColor: "#ffe600",
          backgroundColor: "#ffe600",
          datalabels: {
            display: false,
          },
        },
        {
          type: "line" as const,
          label: "APL",
          data:
            plan.tapering.map((e) =>
              e.apl.length !== 0 ? e.dose : undefined
            ) ?? [],
          borderColor: "orange",
          backgroundColor: "orange",
          datalabels: {
            display: false,
          },
        },
        {
          type: "line" as const,
          label: "Regenboogen",
          data:
            plan.tapering.map((e) =>
              e.regenboogen.length !== 0 ? e.dose : undefined
            ) ?? [],
          borderColor: "red",
          backgroundColor: "#ff697d",
          datalabels: {
            display: false,
          },
        },

        // {
        //   type: "scatter" as const,
        //   label: "Pharmacy change",
        //   data: transferPointSearch(
        //     plan.tapering.map((e) =>
        //       e.regenboogen.length === 0 ? e.dose : undefined
        //     ) ?? []
        //   ),
        //   borderColor: "orange",
        //   backgroundColor: "orange",
        //   pointRadius: 5,
        //   datalabels: {
        //     display: false,
        //     anchor: "end" as const,
        //     ofset: 200,
        //   },
        // },
      ],
    };
  }, [plan]);

  return (
    <Flex px="1rem" py="20px">
      <PrescriptionHelper
        isOpen={isPrescriptionHelperOpen}
        onClose={onPrescriptionHelperClose}
      />

      <VStack
        alignItems={"stretch"}
        w="full"
        direction="column"
        position="relative"
      >
        <Flex
          pt={{ md: ".5rem", lg: "1.25rem" }}
          gap={"1rem"}
          w={"100%"}
          direction={{ base: "column", sm: "row" }}
          /* maxH={{ md: "37vh", lg: "50vh" }} */
          minH="50vh"
        >
          <Box>
            <Heading
              fontSize={{ md: "1.1rem", lg: "1.25rem" }}
              fontWeight={600}
              color={"#589AAF"}
              textTransform="uppercase"
            >
              {t("Tapering plan")}
            </Heading>
            <VStack
              alignItems={"flex-start"}
              spacing={0}
              gap={0}
              mt={{ md: "0px", lg: ".5rem" }}
            >
              <Flex direction="column">
                <Text
                  color={"blueish.900"}
                  fontSize={{ md: ".8rem", lg: "1rem" }}
                >
                  {t("Pace")}
                </Text>
                <Text
                  color={"blueish.900"}
                  fontSize={{ md: ".8rem", lg: "1rem" }}
                >
                  <b>{" " + plan.pace} %</b>
                </Text>
              </Flex>

              <Flex direction="column">
                <Text
                  color={"blueish.900"}
                  fontSize={{ md: ".8rem", lg: "1rem" }}
                >
                  {t("Time")}
                </Text>
                <Text
                  color={"blueish.900"}
                  fontSize={{ md: ".8rem", lg: "1rem" }}
                >
                  <b>
                    {" " + plan.time} {t("weeks")}
                  </b>
                </Text>
              </Flex>

              <Flex direction="column">
                <Text
                  color={"blueish.900"}
                  fontSize={{ md: ".8rem", lg: "1rem" }}
                >
                  {t("Pharmacy")}
                </Text>
                <Wrap maxW={"10rem"}>
                  {plan.pharmacy.map((pharmacy, index) => {
                    return (
                      <Tag
                        key={index}
                        fontSize={"0.875rem"}
                        fontWeight={"700"}
                        bg={
                          pharmasBoxes.find((e) => e.name === pharmacy)?.color
                        }
                      >
                        {pharmacy}
                      </Tag>
                    );
                  })}
                </Wrap>
              </Flex>

              <Flex gap={"0.5rem"} direction={"column"} mt={"1rem !important"}>
                {(Object.entries(plan.pills.regular).length > 0 ||
                  Object.entries(plan.pills.apl).length > 0) && (
                  <UnorderedList>
                    <ListItem>
                      <Flex
                        gap={{ md: ".2rem", lg: "1rem" }}
                        direction="column"
                      >
                        <Text
                          color={"blueish.900"}
                          fontSize={{ md: ".8rem", lg: "1rem" }}
                          minW={"5rem"}
                        >
                          {t("Regular")}
                        </Text>
                        <VStack
                          maxH={"10rem"}
                          spacing={0}
                          gap={"0.5rem"}
                          align="flex-start"
                          overflowY="auto"
                        >
                          {Object.entries(plan.pills.regular).map(
                            ([key, value]) => {
                              return (
                                <Text
                                  color={"blueish.900"}
                                  fontSize={{ md: ".8rem", lg: "1rem" }}
                                  px="3"
                                >
                                  {key + "mg x" + value}
                                </Text>
                              );
                            }
                          )}
                        </VStack>
                      </Flex>
                    </ListItem>

                    <ListItem>
                      <Flex gap={"1rem"} direction="column">
                        <Text
                          color={"blueish.900"}
                          fontSize={{ md: ".8rem", lg: "1rem" }}
                          minW={"5rem"}
                        >
                          APL
                        </Text>
                        <VStack
                          maxH={"5rem"}
                          spacing={0}
                          gap={"0.5rem"}
                          align="flex-start"
                          overflowY="auto"
                        >
                          {Object.entries(plan.pills.apl).map(
                            ([key, value]) => {
                              return (
                                <Text
                                  color={"blueish.900"}
                                  fontSize={{ md: ".8rem", lg: "1rem" }}
                                  px="3"
                                  fontWeight={700}
                                >
                                  {key + "mg x" + value}
                                </Text>
                              );
                            }
                          )}
                        </VStack>
                      </Flex>
                    </ListItem>
                  </UnorderedList>
                )}
              </Flex>
            </VStack>
          </Box>

          {warning && (
            <Stack spacing={3} w="20%">
              <Alert status="warning" borderRadius="md" fontSize=".8rem">
                <AlertIcon />
                {warning}
              </Alert>
            </Stack>
          )}

          <Flex
            flex="1"
            overflow="hidden"
            maxH={{ md: "35vh", lg: "35vh" }}
            justify="flex-end"
          >
            <Chart
              type={"line"}
              plugins={[ChartDataLabels]}
              options={{
                ...options,
                scales: {
                  y: {
                    suggestedMax: plan.tapering[0].dose + 2,
                  },
                },
              }}
              data={chartData}
            />
          </Flex>
        </Flex>
        {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
        <Flex
          position="absolute"
          bottom={{ md: 65, lg: 0 }}
          right={{ md: 0, lg: 0 }}
        >
          <Button
            variant={"filledBlue"}
            fontSize={"1rem"}
            py="12px"
            px={"1.5rem"}
            isLoading={isRequestPending || isPrescriptionHelpeLoading}
            onClick={onClickSavePlan}
          >
            {t("Save")}
          </Button>
        </Flex>
      </VStack>
    </Flex>
  );
};

export default GeneratedTaperingPlanSection;
