import { APIClient } from "services/api/client";
import useSWR from "swr";
import { JournalItemResponseType } from "./types";
import useSWRMutation from "swr/mutation";

export const useFetchPatientJournal = (patientId: string) => {
  return useSWR<JournalItemResponseType[]>(
    `admin/users/${patientId}/journal`,
    (url) => {
      return APIClient.get(url);
    }
  );
};

export const useCreateJournalRecord = (patientId: string, staffId: string) => {
  const { mutate } = useFetchPatientJournal(patientId);

  return useSWRMutation<any, any>(
    [`/admin/journal/`, staffId],
    (
      [url, staffid]: string[],
      {
        arg,
      }: {
        arg: {
          comment: string;
          tag: string;
        };
      }
    ) => {
      return APIClient.post(url, {
        patient_id: patientId,
        staff_member_id: staffId,
        comment: arg.comment,
        tag: arg.tag
      }).then(() => {
        mutate();
      });
    }
  );
};
