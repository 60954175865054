import { create } from "zustand";
import { TokensState } from "./types";
import { persist, createJSONStorage } from "zustand/middleware";

export const useTokenStore = create<TokensState>((set) => ({
  access: "",
  refresh: "",
  auth2fa: false,
  setAccess: (token) => set(() => ({ access: token })),
  removeAccess: () => set(() => ({ access: "" })),
  setRefresh: (token) => set(() => ({ refresh: token })),
  removeRefresh: () => set(() => ({ refresh: "" })),
  setAuth2fa: (isAuthorized) => set(() => ({ auth2fa: isAuthorized })),
  clearTokens: () => set(() => ({ access: "", refresh: "", auth2fa: false })),
}));
