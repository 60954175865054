import { Flex, Heading, Text } from "@chakra-ui/react";

import AdminTable from "features/admin/components/adminTable";
import { getFormatedDate } from "features/dashboard/dateHelper";

import { useTranslation } from "react-i18next";

const AdminPanel = () => {
  const { t } = useTranslation();

  const currentDate = getFormatedDate();
  return (
    <>
      <Flex w="100%" direction="column" gap="2.5rem" py="32px">
        <Flex w="100%" align="center" justify="space-between">
          <Heading color="white" fontSize="40px" fontWeight={500}>
            {t("Welcome Admin")}
          </Heading>
          <Text fontSize="20px" fontWeight={400}>
            {currentDate}
          </Text>
        </Flex>
      </Flex>
      <Flex
        w="100%"
        bg="rgba(88, 154, 175, 1)"
        borderRadius="12px"
        direction="column"
        gap={2}
      >
        <AdminTable />
      </Flex>
    </>
  );
};

export default AdminPanel;
