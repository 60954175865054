import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
  Box,
  useDisclosure,
  Collapse,
  Button,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { useEffect } from "react";
import Collapsible from "react-collapsible";
import { useHistory, useLocation } from "react-router-dom";
import { useScrollToLocation } from "services/hooks/useScrollToLocation";
import MinusIcon from "ui/iconComponents/MunusIcon";
import PlusIcon from "ui/iconComponents/PlusIcon";

const FaqQuestions = () => {
  const history = useHistory();
  useScrollToLocation(true);
  const { hash } = useLocation();

  return (
    <>
      <Flex direction="column" gap=".5rem">
        <Heading fontSize="24px" color="rgba(88, 154, 175, 1)" fontWeight={500}>
          Om Lamina Clinic
        </Heading>
        <Accordion
          allowToggle
          w="100%"
          bg="rgba(88, 154, 175, 0.1)"
          border="1px solid white"
          maxH={"150vh"}
          defaultIndex={
            hash
              ? [section1.findIndex((e) => "#" + e.hash === hash)]
              : undefined
          }
        >
          {section1.map((faq, index) => (
            <AccordionItem key={index}>
              {({ isExpanded }) => (
                <>
                  <Box id={faq.hash}>
                    <AccordionButton
                      w="full"
                      justifyContent="space-between"
                      py="12px"
                      px="20px"
                      borderRadius={".5rem !important"}
                      onClick={() => {
                        history.push("#" + faq.hash);
                      }}
                    >
                      <Text
                        flex="1"
                        textAlign="left"
                        fontWeight={700}
                        color={
                          isExpanded
                            ? "rgba(88, 154, 175, 1)"
                            : "rgba(0, 38, 55, 1)"
                        }
                        fontSize={{ base: "16px", sm: "20px" }}
                        whiteSpace="pre-wrap"
                      >
                        {faq.question}
                      </Text>
                      {isExpanded ? <MinusIcon /> : <PlusIcon />}
                    </AccordionButton>
                  </Box>

                  <Box w="full" h="15px" bg="white" />
                  <AccordionPanel
                    id={faq.hash}
                    pb={4}
                    bg="white"
                    fontSize="16px"
                    fontWeight={400}
                    px="20px"
                  >
                    {faq.answer}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>

      <Flex direction="column" gap=".5rem">
        <Heading fontSize="24px" color="rgba(88, 154, 175, 1)" fontWeight={500}>
          Om nedtrappning med oss
        </Heading>
        <Accordion
          allowToggle
          w="100%"
          bg="rgba(88, 154, 175, 0.1)"
          border="1px solid white"
          maxH={"150vh"}
          defaultIndex={
            hash
              ? [section2.findIndex((e) => "#" + e.hash === hash)]
              : undefined
          }
        >
          {section2.map((faq, index) => (
            <AccordionItem key={index}>
              {({ isExpanded }) => (
                <>
                  <Box id={faq.hash}>
                    <AccordionButton
                      w="full"
                      justifyContent="space-between"
                      py="12px"
                      px="20px"
                      borderRadius={"0.5rem !important"}
                      onClick={() => {
                        history.push("#" + faq.hash);
                      }}
                    >
                      <Text
                        flex="1"
                        textAlign="left"
                        fontWeight={700}
                        color={
                          isExpanded
                            ? "rgba(88, 154, 175, 1)"
                            : "rgba(0, 38, 55, 1)"
                        }
                        fontSize={{ base: "16px", sm: "20px" }}
                        whiteSpace="pre-wrap"
                      >
                        {faq.question}
                      </Text>
                      {isExpanded ? <MinusIcon /> : <PlusIcon />}
                    </AccordionButton>
                  </Box>

                  <Box w="full" h="15px" bg="white" />
                  <AccordionPanel
                    id={faq.hash}
                    pb={4}
                    bg="white"
                    fontSize="16px"
                    fontWeight={400}
                    px="20px"
                  >
                    {faq.answer}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>

      <Flex direction="column" gap=".5rem">
        <Heading fontSize="24px" color="rgba(88, 154, 175, 1)" fontWeight={500}>
          Varför nedtrappning är svårt
        </Heading>
        <Accordion
          allowToggle
          w="100%"
          bg="rgba(88, 154, 175, 0.1)"
          border="1px solid white"
          maxH={"150vh"}
          defaultIndex={
            hash
              ? [section3.findIndex((e) => "#" + e.hash === hash)]
              : undefined
          }
        >
          {section3.map((faq, index) => (
            <AccordionItem key={index}>
              {({ isExpanded }) => (
                <>
                  <Box id={faq.hash}>
                    <AccordionButton
                      w="full"
                      justifyContent="space-between"
                      py="12px"
                      px="20px"
                      borderRadius={"0.5rem !important"}
                      onClick={() => {
                        history.push("#" + faq.hash);
                      }}
                    >
                      <Text
                        flex="1"
                        textAlign="left"
                        fontWeight={700}
                        color={
                          isExpanded
                            ? "rgba(88, 154, 175, 1)"
                            : "rgba(0, 38, 55, 1)"
                        }
                        fontSize={{ base: "16px", sm: "20px" }}
                        whiteSpace="pre-wrap"
                      >
                        {faq.question}
                      </Text>
                      {isExpanded ? <MinusIcon /> : <PlusIcon />}
                    </AccordionButton>
                  </Box>

                  <Box w="full" h="15px" bg="white" />
                  <AccordionPanel
                    id={faq.hash}
                    pb={4}
                    bg="white"
                    fontSize="16px"
                    fontWeight={400}
                    px="20px"
                  >
                    {faq.answer}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>

      <Flex direction="column" gap=".5rem">
        <Heading fontSize="24px" color="rgba(88, 154, 175, 1)" fontWeight={500}>
          Akut hjälp
        </Heading>

        <Accordion
          allowToggle
          w="100%"
          bg="rgba(88, 154, 175, 0.1)"
          border="1px solid white"
          maxH={"150vh"}
          defaultIndex={
            hash
              ? [section4.findIndex((e) => "#" + e.hash === hash)]
              : undefined
          }
        >
          {section4.map((faq, index) => (
            <AccordionItem key={index}>
              {({ isExpanded }) => (
                <>
                  <Box id={faq.hash}>
                    <AccordionButton
                      w="full"
                      justifyContent="space-between"
                      py="12px"
                      px="20px"
                      borderRadius={"0.5rem !important"}
                      onClick={() => {
                        history.push("#" + faq.hash);
                      }}
                    >
                      <Text
                        flex="1"
                        textAlign="left"
                        fontWeight={700}
                        color={
                          isExpanded
                            ? "rgba(88, 154, 175, 1)"
                            : "rgba(0, 38, 55, 1)"
                        }
                        fontSize={{ base: "16px", sm: "20px" }}
                        whiteSpace="pre-wrap"
                      >
                        {faq.question}
                      </Text>
                      {isExpanded ? <MinusIcon /> : <PlusIcon />}
                    </AccordionButton>
                  </Box>

                  <Box w="full" h="15px" bg="white" />
                  <AccordionPanel
                    id={faq.hash}
                    pb={4}
                    bg="white"
                    fontSize="16px"
                    fontWeight={400}
                    px="20px"
                  >
                    {faq.answer}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
    </>
  );
};

export default FaqQuestions;

//Om Lamina Clinic
const section1 = [
  {
    hash: "who-is-laminaclinic-for",
    question: "Vad är Lamina Clinic?",
    answer: `Vi är en klinik specialiserad på nedtrappning och utsättning av antidepressiva läkemedel. Vi hjälper patienter trappa ner i ett lugnt tempo med hjälp av individuella nedtrappningsplaner och specialtillverkade läkemedelsdoser. Vi följer upp ofta och regelbundet och hjälper på så sätt våra patienter till en så smärtfri nedtrappning som möjligt med maximalt stöd.`,
  },
  {
    hash: "what-is-the-difference",
    question: "Vad är det för skillnad på Lamina Clinic och en vårdcentral?",
    answer: `Lamina Clinic är specialiserade på nedtrappning av antidepressiva och stöttar dig under din nedtrappning. Om du blir sjuk eller har besvär som inte har med nedtrappningen att göra så ska du vända dig till din vanliga vårdcentral eller vårdgivare. Om det är akut ska du alltid ringa 112 eller ta dig till närmaste akutmottagning.`,
  },
  {
    hash: "why-should-step-down",
    question: "Varför ska jag trappa ned med Lamina?",
    answer: `Med Lamina får du en individuell nedtrappningsplan och dessutom recept på specialtillverkad medicin i mycket små nedtrappningsdoser. Det gör nedtrappningen skonsam och minskar risken för att du ska må dåligt när du trappar ned. Dessutom håller ditt vårdteam tät kontakt med dig så att du får snabb hjälp om du mot förmodan skulle känna av tuffa utsättningssymptom.`,
  },
];

const prices = {
  nurse_meeting: 275,
};

//Om nedtrappning med oss
const section2 = [
  {
    hash: "who-is-our-method",
    question: "Vilken är Laminas metod?",
    answer: (
      <div>
        <p>
          Vår metod för nedtrappning av antidepressiva är en modell för hyperbol
          nedtrappning, att vi trappar ned snabbare i början och långsammare mot
          slutet av nedtrappningen. Både forskning och erfarenhet har visat att
          de flesta av sina läkemedel utan vare sig återfall eller svåra
          utsättningssymptom om de trappar ned just hyperbolt.
        </p>
        Det beror på att dessa mediciner påverkar hjärnan icke-linjärt. Det
        betyder att dubbel dos inte ger dubbel effekt, som man kanske kan tro.
        Istället har en mycket liten dos av medicinen har den proportionerligt
        största effekten, för att därefter minska per dosökning. Om du till
        exempel äter 20 mg Citalopram, då står 1 mg för 50 % av hela effekten du
        får. De andra 19 är värda lika mycket effekt som det första. Det här är
        också varför de flesta som försökt trappa ned själva upplever stora
        problem mot slutet av nedtrappningen.
        <p></p>
      </div>
    ),
  },
  {
    hash: "what-is-the-difference",
    question: "Kan vem som helst bli patient?",
    answer: (
      <div>
        <p>
          Vi tar emot dig som är över 18 år och som tar antidepressiv medicin
          men vill gå ned i dos eller sluta helt. Vi kan dock inte hjälpa dig om
          du samtidigt har sjukdomar eller tar andra mediciner som påverkas av
          de antidepressiva läkemedlet. Läkaren gör alltid en individuell
          bedömning av dig och din situation och ger besked om du kan trappa ned
          med oss.
        </p>
        <p>
          De vanligaste orsakerna till att vi inte kan behandla en person är att
          han eller hon:
        </p>

        <ul style={{ paddingLeft: "20px" }}>
          <li>Är i en pågående livskris</li>
          <li>Är suicidal</li>
          <li>Har försökt ta självmord under det senaste året</li>
          <li>Har ett allvarligt (aktivt) beroende av droger eller alkohol</li>
          <li>Har diagnostiserad demens</li>
          <li>Har diagnostiserad schizofreni eller schizoaffektiv sjukdom</li>
          <li>
            Har varit inlagd på sjukhus för bipolär sjukdom under det senaste
            året
          </li>
          <li>Äter blodförtunnande som Warfarin eller Waran </li>
          <li>Tar läkemedel mot epilepsi</li>
        </ul>
      </div>
    ),
  },
  {
    hash: "how-long-will-it-take",
    question: "Hur lång tid tar det?",
    answer: `Det skiljer sig mycket från person till person, men minst 3 månader. Läkaren och du gör upp en plan baserat på din situation, din historik och dina mediciner för att hitta en takt som du och din kropp mår bra av.`,
  },
  {
    hash: "medication-can-you-help-putting-out",
    question: "Vilka läkemedel kan ni hjälpa till med att sätta ut?",
    answer: (
      <div>
        <p>Vi trappar ned alla läkemedel som har följande aktiva substanser:</p>

        <ul style={{ paddingLeft: "20px" }}>
          <li>Agomelatin</li>
          <li>Amitryptilin</li>
          <li>Bupropion</li>
          <li>Citalopram</li>
          <li>Klomipramin</li>
          <li>Escitalopram</li>
          <li>Fluoxetin</li>
          <li>Fluvoxamin</li>
          <li>Mirtazapin</li>
          <li>Moclobemid</li>
          <li>Nortriptylin</li>
          <li>Paroxetin</li>
          <li>Sertralin</li>
          <li>Tranylcopromin</li>
          <li>Trimipramin</li>
          <li>Venlafaxin</li>
        </ul>
      </div>
    ),
  },
  {
    hash: "free-card-and-protection",
    question: "Gäller frikort och högkostnadsskydd?",
    answer: `Då vi är en privat vårdgivare täcks vårdbesöken hos oss inte av högkostnadsskyddet. Medicinerna täcks av högkostnadsskyddet i de allra flesta fall. Ett fåtal av läkemedlen vi erbjuder är ovanligare i Sverige och kan ännu inte levereras i nedtrappningsdoser under högkostnadsskyddet. Vi jobbar hårt på att lösa detta.`,
  },
  {
    hash: "care-form-without-bank-id",
    question: "Kan jag söka vård hos er utan Bank-ID?",
    answer: `Nej, du måste ha svenskt Bank-ID för att kunna använda Laminas tjänst.`,
  },
  {
    hash: "what-does-it-cost",
    question: "Vad kostar det?",
    answer: (
      <div>
        <p>
          Vi försöker hålla priserna så låga som möjligt för att nedtrappning
          ska vara tillgängligt för alla, oavsett inkomst. Appen och
          nedtrappningsplanen är gratis och det finns ingen månadskostnad. Det
          enda du betalar för är möten med vårdpersonal.{" "}
        </p>

        <p>
          Månadskostnaden landar därför för de flesta på {prices.nurse_meeting}{" "}
          kronor för den månatliga uppföljningen, plus {prices.nurse_meeting}{" "}
          kronor var tredje månad för förnyelse av recept. I början betalar du
          också för ett uppstartsmöte och ett initialt läkarbesök. Läkemedlen
          betalar du på apoteket precis som vanligt.
        </p>

        <p style={{ textDecoration: "underline" }}>PRISER</p>
        <ul style={{ paddingLeft: "20px" }}>
          <li>{prices.nurse_meeting} kr | Uppstartsmöte & första bedömning</li>
          <li>499 kr | Initialt läkarbesök med nedtrappningsplan & recept </li>
          <li>
            {prices.nurse_meeting} kr | Uppföljningsmöte, en gång per månad
          </li>
          <li>{prices.nurse_meeting} kr | Receptförnyelse, var tredje månad</li>
          <li>499 kr | Extra läkarbesök </li>
          <li>{prices.nurse_meeting} kr | Extra uppföljningsmöte</li>
        </ul>
      </div>
    ),
  },
];

// Varför nedtrappning är svårt
const section3 = [
  {
    hash: "withdrawal-symptoms",
    question: "Utsättningssymptom, vad är det?",
    answer: `Utsättningssymptom är de besvär som uppstår när man sänker dosen av antidepressiva och som gör att det kan vara väldigt svårt att sluta med medicinen. Symptomen kan vara både psykiska och fysiska och kan vara mycket svåra, rent av omöjliga att hantera. Hos vissa går de över fort medan de sitter i länge för andra. Det är dessutom lätt att missta utsättningssymptom för tecken på att man återfallit i depression. `,
  },
  {
    hash: "how-common-are-withdrawal-sympthoms",
    question: "Hur vanligt är utsättningssymptom?",
    answer: `Av de som försöker sluta ta antidepressiva får majoriteten, ungefär 56 %, utsättningssymtom. `,
  },
];

// Akut hjälp
const section4 = [
  /* {
    hash: "if-need-emergency-help",
    question: "Vem kan jag vända mig till om jag är i behov av akut hjälp?",
    answer: `Vi på Lamina Clinic är specialiserade på just nedtrappning och utsättning av antidepressiva läkemedel. Våra läkare har spetskunskap inom nedtrappningsområdet och dessutom mångårig erfarenhet från arbete med patienter i en nedtrappningssituation. I vårt kliniska team finns dessutom läkare och vårdpersonal som själva upplevt svårigheterna med att sätta ut antidepressiva läkemedel. Därför kan du som patient förvänta dig en annan förståelse och stöd för din unika situation.`,
  },
  {
    hash: "how-the-dosage-works",
    question: "Hur fungerar doseringen om jag trappar ned med er?",
    answer: `Dosering med Lamina är enkelt. Nedtrappningsdoserna av dina mediciner kommer färdigförpackade med rätt dos i enskilda doseringspåsar, en för varje dag. Var 28:e dag får du hem en ny förpackning med doseringspåsar, var och en med rätt dos varje dag i enlighet med din personliga nedtrappningsplan. Allt du behöver göra är att ta de tabletter som ligger i dagens påse. Precis så enkelt som det ska vara.`,
  },
  {
    hash: "minimizing-risks-withdrawal",
    question: "Minimerar Laminas metod risken för utsättningssymptom?",
    answer: `Laminas nedtrappning baseras helt och hållet på vetenskaplig, evidensbaserad forskning. Vi tar fram en skräddarsydd nedtrappningsplan åt dig och tar alla faktorer som vi vet kan påverka nedtrappningsbehoven med i beräkningen. Dessutom baseras alla våra nedtrappningsplaner baseras på vad som kallas hyperbol nedtrappning, där medicinen minskas i större steg i början men i mindre och personligt anpassade inkrement i de lägre doserna. Denna metod är väl beforskad och ger långt färre biverkningar och utsättningssymptom vid nedtrappning av antidepressiva, jämfört med utsättningstakten de flesta vårdcentraler använder i dagsläget.`,
  },
  {
    hash: "is-it-easy-to-taper",
    question:
      "Min läkare säger att det är enkelt att sluta med antidepressiva. Stämmer inte det?",
    answer: `Många patienter och även läkare tror att det är relativt enkelt att sätta ut antidepressiva. För vissa är det så, och för dem går en utsättning lätt med få eller mycket milda besvär under tiden. Ny forskning har dock under de senaste åren kunnat slå fast att utsättningssymtom är mycket vanligare än vad man tidigare trott - faktum är att majoriteten upplever svåra utsättningssymptom. Dessutom vet vi nu att besvären håller i sig under en längre tid än vi trott tidigare, ofta ett antal månader och i ovanliga fall så länge som ett eller två år. I dagsläget kan vi inte avgöra på förhand vem som kommer drabbas av svårast utsättningssymptom. Därför förespråkar vi på Lamina en hyperbol nedtrappningstakt för alla patienter.`,
  },
  {
    hash: "withdrawal-symptoms",
    question:
      "Vilka är de vanligaste utsättningssymptomen? Kan jag undvika dem med Lamina?",
    answer: `Nedan listar vi de vanligaste utsättningssymptomen, i enlighet med forskning från Harvard Medical School, en av världens främsta medicinska forsknings- och universitetsinstitutioner. Med Laminas hyperbola nedtrappning så minskar du risken för och svårighetsgraden av dessa symptom markant.

Svåra känslor
Humörsvängningar, att känna sig upprörd, stark oro, maniskhet, känsla av depression, irritation, förvirring, paranoia, suicidala känslor och impulser

Mage
Kräkningar, kramper, diarré, minskad aptit
    
Blodkärl
Överdriven svettning, stark rodnad, känslighet för värme
    
Sömnrubbningar
Svårt att sova, livliga drömmar, mardrömmar
    
Balans
Yrsel, känsla av svimfärdighet, upplevelse att benen känns eller rör sig konstigt när du går - så kallade sjöben
    
Kroppskontroll
Skakningar, krypningar eller oro i benen, ojämn och ostadig gång, svårigheter att koordinera talet, svårighet att tugga
    
Andra kroppsliga symptom
Oförklarlig smärta eller domningar, överkänslighet för ljud, tinnitus eller ringningar i öronen, så kallade brain-zaps eller hjärnrysningar: en känsla som upplevs som en elektrisk stöt mot huvudet eller i hjärnan`,
  },
  {
    hash: "how-common-are-withdrawal-symptoms",
    question: "Hur vanligt är utsättningssymptom?",
    answer: `Forskningen visar att fler än hälften, 56 %, av de som försöker sluta med eller trappa ned på sina antidepressiva drabbas av svåra eller mycket svåra utsättningssymtom.`,
  },
  {
    hash: "how-long-withdrawal-symptoms-last",
    question: "Hur länge brukar utsättningssymptom kännas av?",
    answer: `Hur länge en person får utsättningssymptom under nedtrappningen är mycket individuellt. Ny forskning visar att symptomen kan hålla i sig i många veckor, ibland månadsvis och för en liten del av patienterna upp till ett eller två år. Nyckeln till att minska eller helt slippa utsättningssymptom är därför att ta det mycket sakta, använda den hyperbola nedtrappningsmetoden och att justera doseringen efter behov under nedtrappningen. Därför är minsta nedtrappningstid hos oss 12 veckor. Exakt hur lång tid din nedtrappning beräknas ta får du reda på när du haft ditt första möte med vår läkare.`,
  },
  {
    hash: "free-cards",
    question: "Gäller frikort och högkostnadsskydd hos er?",
    answer: `Nej, Lamina Clinic är en privat vårdgivare, så frikort gäller inte hos oss. De specialtillverkade läkemedel som våra läkare skriver ut och vår apotekspartner tillverkar på beställning ingår därför inte heller i högkostnadsskyddet. Vi arbetar hårt för för att förändra det för att kunna göra säker nedtrappning av antidepressiva tillgänglig för fler i framtiden.`,
  }, */
  {
    hash: "emergency-help",
    question: "Vem kan jag vända mig till om jag är i behov av akut hjälp?",
    answer: (
      <div>
        <p>
          Om du mår så dåligt att situationen känns outhärdlig eller om du har
          planer på att ta ditt liv ska du genast söka vård på en psykiatrisk
          akutmottagning, eller ringa 112 om situationen är akut.
        </p>
        <p>Du kan också söka hjälp via:</p>
        <ul>
          <li>
            MIND
            <br />
            Tel: 90 101
            <br />
            <a
              href="https://mind.se/hitta-hjalp/sjalvmordslinjen/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#589AAF" }}
            >
              Mind Självmordslinjen - För dig som har tankar om att ta ditt liv
            </a>
            <br />
            Anonym support vid självmordstankar.
            <br />
            Tillgängligt via telefon eller chatt.
          </li>
          <li>
            1177 Vårdguiden
            <br />
            Tel: 1177
            <br />
            <a
              href="http://1177.se"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#589AAF" }}
            >
              1177 Vårdguiden
            </a>
            <br />
            Ring 1177 för att få hjälp att hitta närmsta psykiatriska
            akutmottagning.
          </li>
          <li>
            Jourhavande präst
            <br />
            Ring 112 och be om att bli kopplad till jourhavande präst.
            <br />
            <a
              href="https://www.svenskakyrkan.se/jourhavandeprast"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#589AAF" }}
            >
              Jourhavande präst
            </a>
            <br />
            Support nattetid (21.00-06.00)
          </li>
          <li>
            Jourhavande medmänniska
            <br />
            Tel: 08 – 702 16 80
            <br />
            <a
              href="https://www.jourhavande-medmanniska.se/prata-med-oss/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#589AAF" }}
            >
              Prata med oss
            </a>
            <br />
            Support under nattetid (21.00-06.00).
            <br />
            Vanlig telefontaxa.
          </li>
        </ul>
      </div>
    ),
  },
];
