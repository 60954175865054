import { Center } from "@chakra-ui/react";
import { UserInfo } from "features/admin/api/types";
import { FunctionComponent } from "react";
import NotUseAppIcon from "ui/iconComponents/NotUseAppIcon";
import UseAppIcon from "ui/iconComponents/UseAppIcon";

interface AppUsageProps {
  userInfo: UserInfo;
}

const AppUsage: FunctionComponent<AppUsageProps> = ({ userInfo }) => {
  return (
    <Center>
      
      {userInfo.app_usage !== "not_used" ? (
        <UseAppIcon />
      ) : (
        <NotUseAppIcon />
      )}
    </Center>
  );
};

export default AppUsage;
