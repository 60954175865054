import { useHistory } from "react-router-dom";

import initials from "assets/images/initials.svg";
import { IoCloseOutline } from "react-icons/io5";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaqLinks } from "../faqDiscoverTwo/components/FaqLinks";
import { useTranslation } from "react-i18next";

const FaqDiscoverThree = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleContactUsClick = () => {
    history.push("/faq#contact-form");
  };
  return (
    <VStack
      w="full"
      align="flex-start"
      py="3rem"
      px={{ base: ".5rem", sm: "5rem", md: "10rem" }}
      justify="center"
    >
      <Button
        onClick={() => history.goBack()}
        variant="unstyled"
        alignSelf="flex-end"
      >
        <Flex align="center">
          <IoCloseOutline size={25} />
          <Box as="span" mt={1}>
            {t("Close")}
          </Box>
        </Flex>
      </Button>

      <VStack
        w="full"
        align="flex-start"
        justify="center"
        px={{ base: "1rem", sm: "2rem", md: "10rem" }}
        py="2rem"
        spacing={5}
      >
        <Image src={initials} />
        <Heading
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1.5rem", sm: "2rem" }}
          fontWeight={500}
        >
          {t("Extemporaneous medicine, what is it?")}
        </Heading>
        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "Extemporaneous medicines are specially adapted medicines that are manufactured on ordering. In Sweden, extempore can only be manufactured if mass-produced drugs are not available in the strength or variant that the patient needs. Examples of when an extempore prescription is made are if a patient cannot tolerate an excipient and needs a specific medicine but this particular one, if an adult medicine is to be given to a child and you need it in a lower dose than what is available in the pharmacy, or, in order to you need small doses to taper off a drug"
          )}
        </Text>

        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "What we at Lamina prescribe is therefore your usual medicine, but in different strengths. Since a safe taper is a taper in small steps, that is the key to why tapering with us is so much gentler and less stressful on your body."
          )}
        </Text>

        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "Extempore is manufactured in Sweden by the state-run Apotek Produktion & Laboratories (APL), which is also one of Europe leading companies in the manufacture of extempore and stock preparations. From APL we can prescribe small doses of most common antidepressants. The medicine manufactured by APL falls under the high cost protection for medicines."
          )}
        </Text>

        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "Doctors at Lamina Clinic are also able to prescribe extemporaneous drugs from Regenboog Apotheek, a Dutch compounding pharmacy that works almost exclusively with the manufacture of personal tapering doses. From Regenboog, we can also prescribe more unusual medicines, and here it is also possible to manufacture preparations in even lower strengths of the usual medicines. If you are very, very sensitive, it may therefore happen that we recommend prescribing medicines from here. However, the medicine manufactured by Regenboog falls outside the Swedish high-cost protection for medicines and is paid for entirely by the patient himself."
          )}
        </Text>

        <FaqLinks onContactUsClick={handleContactUsClick} />

        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "Get started and book your first appointment by downloading the app."
          )}
        </Text>
      </VStack>
    </VStack>
  );
};

export default FaqDiscoverThree;
