import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const circular = definePartsStyle({
  control: defineStyle({
    rounded: "full",
  }),
});

const filter = definePartsStyle({
  control: defineStyle({
    borderRadius: "4px",
    _hover: {
      background: undefined,
    },

    _checked: {
      background: "white",
      color: "#589AAF",
      borderColor: "white",

      _hover: {
        background: "white",
        borderColor: "white",
      },
    },
  }),

  icon: defineStyle({
    _checked: {
      _hover: {
        background: "white",
        color: "white",
      },
    },
  }),
});

const admin = definePartsStyle({
  control: defineStyle({
    height: "1.5rem",
    width: "1.5rem",
    borderRadius: "4px",
    border: "none",
    bg: "#589AAF33",
    transition: "all 0.2s ease-out",
    _hover: {
      background: undefined,
    },

    _checked: {
      background: "#589AAF",
      color: "white",
      borderColor: "white",
    },
  }),

  icon: defineStyle({
    _checked: {
      _hover: {
        background: "white",
        color: "white",
      },
    },
  }),
});

export const Checkbox = defineMultiStyleConfig({
  variants: { circular, filter, admin },
});
