import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { useBookACall } from "features/CalendlyWidget/api/client";
import { t } from "i18next";
import { FunctionComponent } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory } from "react-router-dom";

interface CalendlyBookPageProps {}

const CalendlyBookPage: FunctionComponent<CalendlyBookPageProps> = () => {
  const { trigger: sendBookRequest } = useBookACall();
  const history = useHistory();

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      sendBookRequest({ payload: e.data.payload });
    },
  });

  return (
    <Flex w="full" align="flex-start" direction="column" gap="24px">
      <Box
        w="full"
        borderRadius="10px"
        minH={"75vh"}
        bg="rgba(255, 255, 255, 1)"
        p="40px"
      >
        <Flex>
          <Button
            p={0}
            px={0}
            minWidth={0}
            lineHeight={"1.1rem"}
            leftIcon={<BsArrowLeft />}
            variant={"transparent"}
            color={"#002637"}
            opacity={0.5}
            onClick={() => history.push('/dashboard')}
            fontSize={{ base: "sm", sm: "sm", md: "md" }}
          >
            {t("Back")}
          </Button>
        </Flex>
        <InlineWidget url="https://calendly.com/lamina-clinic/15-min" />
      </Box>
    </Flex>
  );
};

export default CalendlyBookPage;
