import { Flex, Image, Text } from "@chakra-ui/react";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import arrow_footer from "assets/icons/arrow_footer.svg";
import footer_logo from "assets/icons/footer_logo.svg";

const GeneralFooter = () => {
  const { basicEvent } = useGoogleAnalytics();

  const { t } = useTranslation();
  const history = useHistory();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <Flex
      w="full"
      p="40px"
      px={{ base: "3%", sm: "12vw" }}
      bg="rgba(0, 38, 55, 1)"
      justify="space-between"
    >
      <Flex
        gap="20px"
        align="center"
        justify="space-between"
        w="full"
        direction={{ base: "column", sm: "row" }}
        mr="3rem"
      >
        <Image src={footer_logo} alt="Lamina clinic logo" h="58px" />
        <Flex
          gap="40px"
          align="center"
          ml={{ base: "2.5rem", sm: "0rem" }}
          direction={{ base: "column", sm: "row" }}
        >
          <Text
            fontSize={{ base: "12px", sm: "16px" }}
            fontWeight={400}
            cursor="pointer"
            onClick={() => {
              scrollToTop();
              history.push("/terms-of-purchase");
              basicEvent("Contact", "User clicked Terms of Purchase link");
            }}
          >
            {t("Terms of Purchase")}
          </Text>
          <Text
            fontSize={{ base: "12px", sm: "16px" }}
            fontWeight={400}
            cursor="pointer"
            onClick={() => {
              scrollToTop();
              history.push("/privacy-policy");
              basicEvent("Contact", "User clicked Privacy Policy link");
            }}
          >
            {t("Privacy Policy")}
          </Text>
          <Text
            fontSize={{ base: "12px", sm: "16px" }}
            fontWeight={400}
            cursor="pointer"
            onClick={() => {
              history.push("/cookie-policy");
              basicEvent("Contact", "User clicked Cookie Policy link");
            }}
          >
            {t("Cookie Policy")}
          </Text>
        </Flex>
        <Image
          src={arrow_footer}
          alt="arrow icon"
          onClick={() =>
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
          cursor="pointer"
          ml="3rem"
        />
      </Flex>
    </Flex>
  );
};

export default GeneralFooter;
