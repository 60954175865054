import { useEffect, useState } from "react";

const useScript = (url: string, innerHtml?: string) => {
  const [isScriptMounted, setIsScriptMounted] = useState<boolean>(false);
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;

    if (innerHtml) {
      script.innerHTML = innerHtml;
    }

    document.body.appendChild(script);
    setIsScriptMounted(true);

    return () => {
      document.body.removeChild(script);
      setIsScriptMounted(false);
    };
  }, [url]);

  return { isScriptMounted };
};

export default useScript;
