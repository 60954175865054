import { Button, Flex, Text, Image, Divider } from "@chakra-ui/react";

import pagination_left_arrow from "assets/icons/pagination_left_arrow.svg";
import pagination_right_arrow from "assets/icons/pagination_right_arrow.svg";

import { useTranslation } from "react-i18next";
import { useAdminServiceStore } from "services/state/adminServiceStore";

interface PaginationProps {
  pageCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  size?: number;
  setSize?(newSize: number): void;
  maxSize?: number;
}

const Pagination = ({
  pageCount,
  currentPage,
  onPageChange,
  maxSize,
}: PaginationProps) => {
  const { t } = useTranslation();

  const { userListSize, setUserListSize } = useAdminServiceStore();

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === pageCount;

  const handlePageChange = (page: number) => {
    if (page < 1) {
      onPageChange(1);
    } else if (page > pageCount) {
      onPageChange(pageCount);
    } else {
      onPageChange(page);
    }
  };

  const handlePreviousPageClick = () => {
    handlePageChange(currentPage - 1);
  };

  const handleNextPageClick = () => {
    handlePageChange(currentPage + 1);
  };

  const pageNumbers = Array.from({ length: pageCount }, (_, i) => i + 1);

  return (
    <Flex align="center" justify="space-between" w="100%">
      <Flex>
        <Flex align="center">
          <Button
            variant="transparent"
            disabled={isFirstPage}
            onClick={handlePreviousPageClick}
            fontSize="18px"
            fontWeight={400}
            gap="16px"
          >
            <Image src={pagination_left_arrow} />
            {t("Prev")}
          </Button>

          {pageNumbers.map((page) => (
            <Flex
              key={page}
              align="center"
              justify="center"
              bg={
                currentPage === page ? "rgba(238, 232, 169, 1)" : "transparent"
              }
              color={
                currentPage === page
                  ? "rgba(0, 38, 55, 1)"
                  : "rgba(255, 255, 255, 1)"
              }
              borderRadius="4px"
              fontWeight={800}
              w="32px"
              h="32px"
              cursor="pointer"
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Flex>
          ))}

          <Button
            variant="transparent"
            disabled={isLastPage}
            onClick={handleNextPageClick}
            fontSize="18px"
            fontWeight={400}
            gap="16px"
          >
            {t("Next")}
            <Image src={pagination_right_arrow} />
          </Button>
        </Flex>

        <Flex>
          <Button
            variant={"transparent"}
            onClick={() => {
              setUserListSize(maxSize ?? 0);
            }}
            isDisabled={userListSize === maxSize}
          >
            {t("See all")}
          </Button>
          <Divider orientation="vertical" />
          <Button
            variant={"transparent"}
            onClick={() => {
              setUserListSize(10);
            }}
            isDisabled={userListSize === 10}
          >
            {t("10 per page")}
          </Button>
        </Flex>
      </Flex>

      {/* PAGE COUNT */}
      <Flex align="center" gap="20px">
        <Text fontSize="18px" fontWeight={400}>
          {t("Page")}
        </Text>
        <Button
          variant="solid"
          bg="rgba(255, 255, 255, 0.1)"
          border="1px solid rgba(255, 255, 255, 1)"
          color="white"
        >
          {currentPage}
        </Button>
        <Text fontSize="18px" fontWeight={400}>
          of {pageCount}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Pagination;
