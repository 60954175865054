import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import circle_minus from "assets/icons/circle_minus.svg";
import ScheduleItem from "features/dashboard/components/dashboardScheduleItem";
import CurrentDayMedication from "features/userInfo/components/CurrentDayMedication";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import left_arrow from "assets/icons/left_arrow.svg";
import right_arrow from "assets/icons/right_arrow.svg";
import { useAdminUserInfo } from "features/userInfo/api/client";
import { TreatmentResponseType } from "features/admin/api/types";
import PatientsDoctor from "../PatientsDoctor";
import { useFetchProfileInfo } from "features/dashboard/api/client";

const AdminFullSchedule = () => {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const { data: userData } = useAdminUserInfo(id);
  const { data: adminData } = useFetchProfileInfo();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const handlePreviousClick = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const spliceIntoChunks = (arr: any[], chunkSize: number) => {
    let res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  const [pagedScheduleData, setPagedScheduleData] = useState<
    TreatmentResponseType[][]
  >([]);

  useEffect(() => {
    if (userData?.treatment) {
      const treatmentsWithStrip = userData.treatment.days.map(
        (treatment, index) => {
          return {
            ...treatment,
            strip: Math.floor(index / 28) + 1,
          };
        }
      );

      setPagedScheduleData(spliceIntoChunks(treatmentsWithStrip, 7));
    }
  }, [userData]);

  const currentDayData = userData?.is_active_treatment
    ? userData?.treatment?.days.find(
        (e) =>
          e && new Date(e.date).toDateString() === new Date().toDateString()
      )
    : userData?.treatment?.days[0];

  return (
    <Flex
      w="100%"
      p="44px 32px"
      pt={"0.5rem"}
      borderRadius="12px"
      bg="white"
      direction="column"
    >
      <SimpleGrid
        gridTemplateColumns={"1fr 2fr"}
        py={"1.5rem"}
        columnGap={"2.5rem"}
      >
        <Heading color="blueish.500" fontSize="20px" fontWeight={500}>
          {t("TODAY")}
        </Heading>

        <Flex justifyContent={"space-between"}>
          <Heading color="blueish.500" fontSize="20px" fontWeight={500}>
            {t("FULL SCHEDULE")}
          </Heading>
          {pagedScheduleData.length > 0 && (
            <Flex gap="40px" alignItems={"center"}>
              <Text color="blackish.200" fontSize="16px" fontWeight={500}>
                {t("Week")} {currentPage + 1} of {pagedScheduleData.length}
              </Text>
              <Flex gap="22px">
                <Button
                  variant={"outline"}
                  style={{ padding: "0px" }}
                  border={"none"}
                  onClick={handlePreviousClick}
                  disabled={currentPage === 0}
                >
                  <Image src={left_arrow} />
                </Button>

                <Button
                  variant={"outline"}
                  style={{ padding: "0px" }}
                  minH={"2rem !important"}
                  border={"none"}
                  onClick={handleNextClick}
                  disabled={currentPage === pagedScheduleData.length - 1}
                >
                  <Image src={right_arrow} />
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>
      </SimpleGrid>

      <SimpleGrid
        gridTemplateColumns={"1fr 2fr"}
        columnGap={"2.5rem"}
        gridTemplateRows={"masonry"}
      >
        <VStack alignItems={"stretch"} gap={"1rem"}>
          {(userData?.treatment?.days.length ?? 0) > 0 && (
            <Box>
              {currentDayData && (
                <CurrentDayMedication
                  dayNumber={
                    userData?.is_active_treatment
                      ? currentDayData.day_of_tapering
                      : 0
                  }
                  is_active_treatment={!!userData?.is_active_treatment}
                  tablets={currentDayData.tablets}
                />
              )}
              {!currentDayData && (
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                  py="1rem"
                  gap={2}
                >
                  <Image src={circle_minus} />
                  <Heading fontSize="20px" fontWeight={400}>
                    {t("No treatment plan for today.")}
                  </Heading>
                  <Text color="black" fontSize="16px" fontWeight={400}>
                    {t("User does not have a treatment plan ready for today")}
                  </Text>
                </Flex>
              )}
            </Box>
          )}
          {adminData?.is_admin && <PatientsDoctor />}
        </VStack>

        {(userData?.treatment?.days.length ?? 0) > 0 && (
          <VStack>
            {!userData?.is_active_treatment && (
              <Flex
                w="100%"
                bg={"rgba(88,154,175,0.1)"}
                borderRadius="8px"
                py={{ base: ".5rem", sm: "2rem" }}
                px={{ base: ".5em", sm: "1.5rem" }}
                align="center"
                justify="space-between"
                direction={{ base: "column", sm: "row" }}
                border={"2px solid #589AAF"}
              >
                Nedtrappning ännu inte startad
              </Flex>
            )}

            {(pagedScheduleData[currentPage] ?? []).map((dayData, index) => {
              return (
                <ScheduleItem
                  totalDose={dayData.dosage}
                  id={dayData.id}
                  day={dayData.day_of_tapering}
                  dayOfWeek={new Date(dayData.date).toLocaleDateString(
                    t("locale") ?? "en-GB",
                    {
                      weekday: "long",
                    }
                  )}
                  pillsDosage={dayData.tablets}
                  isCurrent={false}
                  key={dayData.id}
                  strip={dayData.strip}
                  isEditable={
                    userData?.status === "prescription_completed" ||
                    userData?.status === "meeting_with_doctor"
                  }
                />
              );
            })}
          </VStack>
        )}

        {(!userData?.treatment || userData?.treatment.days.length === 0) && (
          <Flex
            w="100%"
            align="center"
            justify="center"
            direction="column"
            py="1rem"
            gap={2}
          >
            <Image src={circle_minus} />
            <Heading fontSize="20px" fontWeight={400}>
              {t("No schedule.")}
            </Heading>
            <Text color="black" fontSize="16px" fontWeight={400}>
              {t("User have not ordered the medication yet.")}
            </Text>
          </Flex>
        )}
      </SimpleGrid>
    </Flex>
  );
};

export default AdminFullSchedule;
