import {
  Button,
  useDisclosure,
  Text,
  Modal,
  Flex,
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
} from "@chakra-ui/react";
import {
  useAdminUserInfo,
  useRequestUserPrescriptionPDF,
  useSaveFileUrl,
  useSendUserPrescriptionPDF,
} from "features/admin/api/client";
import { downloadByUrl } from "features/admin/helper/helper_functions";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import { useUploadFile } from "features/fileUploads/api/client";
import { invokeBasicInfoModal } from "features/genericModal";
import SignPdfComponent from "features/signPDF/components/SignPdfComponent";
import PDFModal from "features/superadmin/components/PatientInfo/components/PDFFormModal";
import { t } from "i18next";
import { FunctionComponent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFileSignature } from "react-icons/fa";
import { VscFilePdf } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import { usePrescriptionToPharmacyStore } from "services/state/prescriptionToPharmacyStore";

interface PrescriptionToPharmacyPdfModalProps {}

const PrescriptionToPharmacyPdfModal: FunctionComponent<
  PrescriptionToPharmacyPdfModalProps
> = () => {
  const { t } = useTranslation();

  const [pdfObjectURL, setPdfObjectUrl] = useState<string>("");
  const [localUpdatedPdfObjectUrl, setLocalUpdatedPdfObjectUrl] =
    useState<string>("");
  const { position, setPosition } = usePrescriptionToPharmacyStore();

  const { id } = useParams<{ id: string }>();
  const { data: profileData } = useFetchProfileInfo();
  const { data: userData } = useAdminUserInfo(id ?? "");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { trigger: prescriptionPDF, isMutating } =
    useRequestUserPrescriptionPDF(id ?? "");

  const [isSignatureProcessing, setIsSignatureProcessing] =
    useState<boolean>(false);

  const { trigger: sendPrescriptionPDF } = useSendUserPrescriptionPDF(id);
  const { trigger: uploadFileRequest } = useUploadFile();
  const { trigger: sendUploadedFileUrl } = useSaveFileUrl(
    id ?? "",
    "type_form"
  );

  const prescriptionRef = useRef<any>(null);

  const handleSavePrescriptionPDF = () => {
    downloadByUrl(
      pdfObjectURL,
      `${userData?.name
        .toLocaleLowerCase()
        .replaceAll(" ", "_")}_prescription.pdf`
    );
    onClose();
  };

  const handleSendUserPrescriptionPDF = () => {
    sendPrescriptionPDF();
    onClose();
    invokeBasicInfoModal(
      `${t("Users Prescription was sent to Your email")}`,
      `${t("Prescription sent")}`
    );
  };

  const handleSignAndUpload = async () => {
    try {
      setIsSignatureProcessing(true);

      const data = await fetch(localUpdatedPdfObjectUrl).then((res) =>
        res.blob()
      );
      //setPosition(prescriptionRef.current.signaturePosition);
      let formData = new FormData();

      formData.append("file", data, new Date().toISOString() + ".pdf");
      formData.append("folder", "signed_prescription_to_pharmacy");

      uploadFileRequest(formData, {
        onSuccess: (data) => {
          sendUploadedFileUrl({ file: data.url });
        },
      });
      invokeBasicInfoModal(t("Document successfully signed"), undefined, () => {
        onClose();
      });
      setIsSignatureProcessing(false);
    } catch (error) {
      setIsSignatureProcessing(false);
    }
  };

  //form pdf logic
  const {
    isOpen: isPdfFormOpen,
    onClose: onPdfFormClose,
    onOpen: onPdfFormOpen,
  } = useDisclosure();

  const hanldePrescriptionPDF = () => {
    onPdfFormOpen();
    // prescriptionPDF(undefined, {
    //   onSuccess: (data) => {
    //     const blobURL = URL.createObjectURL(data);
    //     setPdfObjectUrl(blobURL);
    //     onOpen();
    //   },
    //   onError: () => {
    //     onPdfFormOpen();
    //   },
    // });
  };

  return (
    <>
      {profileData?.role && (
        <Button
          onClick={hanldePrescriptionPDF}
          //onClick={onPdfFormOpen}
          variant="transparent"
          color="black"
          rightIcon={<VscFilePdf fontSize={"1.5rem"} />}
          px={"0rem"}
          minW={"18rem"}
          maxH={"3.5rem"}
          isLoading={isMutating}
          _loading={{ p: "0" }}
          w={"100%"}
          justifyContent={"space-between"}
        >
          <Text pt={"0.25em"} fontSize={"1rem"} color="black">
            {t("Download prescription to pharmacy")}
          </Text>
        </Button>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading color="blueish.500" fontSize="32px" fontWeight={500}>
              {userData?.name} {t("prescription to pharmacy")}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {pdfObjectURL ? (
              <Flex w="full" h="65vh" overflow="auto">
                <SignPdfComponent
                  pdfUrl={pdfObjectURL}
                  ref={prescriptionRef}
                  defaultPosition={position}
                  setNewPdfUrl={setLocalUpdatedPdfObjectUrl}
                />
                {/* <Document
                        file={pdfObjectURL}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={(error: any) => {
                          console.error("Error loading PDF:", error.message);
                          console.error("Error stack:", error.stack);
                        }}
                      >
                        {Array.from({ length: numPages }, (_, index) => (
                          <>
                            <Page
                              key={index + 1}
                              pageNumber={index + 1}
                              renderTextLayer={false}
                              renderAnnotationLayer={false}
                              scale={1.38}
                            />
                            {index !== numPages - 1 && (
                              <Flex justifyContent="flex-start">
                                <Divider
                                  width="100%"
                                  h="1px"
                                  background="blueish.500"
                                />
                              </Flex>
                            )}
                          </>
                        ))}
                      </Document> */}
              </Flex>
            ) : (
              <Text color="black">Loading PDF...</Text>
            )}
          </ModalBody>
          <ModalFooter justifyContent={"space-between"}>
            <Button
              variant={"adjust"}
              leftIcon={<FaFileSignature />}
              isLoading={isSignatureProcessing}
              onClick={handleSignAndUpload}
              isDisabled={localUpdatedPdfObjectUrl === ""}
            >
              {t("Save signed document")}
            </Button>
            <Flex>
              <Button
                ml={2}
                variant="adjust"
                onClick={handleSavePrescriptionPDF}
              >
                {t("Save PDF")}
              </Button>
              <Button
                ml={2}
                bg="rgba(88, 154, 175, 1)"
                color="white"
                onClick={handleSendUserPrescriptionPDF}
                isDisabled={true}
              >
                {t("Send email to pharmacy")}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <PDFModal
        isOpen={isPdfFormOpen}
        onClose={onPdfFormClose}
        onReceivePdf={(pdfBytes) => {
          const blobURL = URL.createObjectURL(pdfBytes);
          setPdfObjectUrl(blobURL);
          onOpen();
          onPdfFormClose();
        }}
      />
    </>
  );
};

export default PrescriptionToPharmacyPdfModal;
