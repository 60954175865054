import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

import MinusIcon from "ui/iconComponents/MunusIcon";
import PlusIcon from "ui/iconComponents/PlusIcon";

import React from "react";
import treatment_info from "assets/icons/treatment_info.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

type FaqTreatmentQuestionsBlockProps = {
  searchQuery: string;
};

type Faq = {
  hash?: string;
  question: string;
  answer: JSX.Element;
};

type Section = {
  title: string;
  faqs: Faq[];
};

const FaqTreatmentQuestionsBlock: React.FC<FaqTreatmentQuestionsBlockProps> = ({
  searchQuery,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const treatmentQuestions: Section[] = [
    {
      title: "",
      faqs: [
        {
          question: t("What does Start Downgrade mean in the app?"),
          answer: (
            <div>
              <p>{t("faqQuestions$medicalInformation$answer_1_1")}</p>
              <p>{t("faqQuestions$medicalInformation$answer_1_2")}</p>
              <p>{t("faqQuestions$medicalInformation$answer_1_3")}</p>
            </div>
          ),
        },
        {
          question: t("I started my taper on the wrong day. What do I do?"),
          answer: (
            <div>
              <p>{t("faqQuestions$medicalInformation$answer_2")}</p>
            </div>
          ),
        },
        {
          question: t("How do I know which dose to take?"),
          answer: (
            <div>
              <p>{t("faqQuestions$medicalInformation$answer_3_1")}</p>
              <p>{t("faqQuestions$medicalInformation$answer_3_2")}</p>
            </div>
          ),
        },
        {
          question: t("What do I do if I missed a dose?"),
          answer: (
            <div>
              <p>{t("faqQuestions$medicalInformation$answer_4_1")}</p>
              <p>{t("faqQuestions$medicalInformation$answer_4_2")}</p>
              <p>{t("faqQuestions$medicalInformation$answer_4_3")}</p>
            </div>
          ),
        },
        {
          question: t("What do I do if I took the wrong dose?"),
          answer: (
            <div>
              <p>{t("faqQuestions$medicalInformation$answer_5_1")}</p>
              <p>{t("faqQuestions$medicalInformation$answer_5_2")}</p>
              <p>{t("faqQuestions$medicalInformation$answer_5_3")}</p>
            </div>
          ),
        },
        {
          question: t("What do I do if I feel unwell?"),
          answer: (
            <div>
              <p>{t("faqQuestions$medicalInformation$answer_6")}</p>
            </div>
          ),
        },
        {
          question: t("Can I pause the tapering?"),
          answer: (
            <div>
              <p>{t("faqQuestions$medicalInformation$answer_7")}</p>
            </div>
          ),
        },
        {
          question: t("Can I change my dose or change the tapering rate?"),
          answer: (
            <div>
              <p>{t("faqQuestions$medicalInformation$answer_8")}</p>
            </div>
          ),
        },
        {
          question: t("Can I book an extra appointment with my care team?"),
          answer: (
            <div>
              <p>{t("faqQuestions$medicalInformation$answer_9")}</p>
            </div>
          ),
        },
      ],
    },
    {
      title: "Emergency help",
      faqs: [
        {
          hash: "emergency-help",
          question: t("Who can I turn to if I need emergency help?"),
          answer: (
            <div>
              <p>{t("faqQuestions$faq$answer_13_1")}</p>
              <p>{t("faqQuestions$faq$answer_13_2")}</p>
              <ul style={{ padding: "10px" }}>
                <li>
                  MIND
                  <br />
                  Tel: 90 101
                  <br />
                  <a
                    href="https://mind.se/hitta-hjalp/sjalvmordslinjen/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "$589AAF" }}
                  >
                    {t("faqQuestions$faq$answer_13_3")}
                  </a>
                  <br />
                  {t("faqQuestions$faq$answer_13_4")}
                  <br />
                  {t("faqQuestions$faq$answer_13_5")}
                </li>
                <li>
                  1177 Vårdguiden
                  <br />
                  Tel: 1177
                  <br />
                  <a
                    href="http://1177.se"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#589AAF" }}
                  >
                    1177 Vårdguiden
                  </a>
                  <br />
                  {t("faqQuestions$faq$answer_13_6")}
                </li>
                <li>
                  {t("faqQuestions$faq$answer_13_7")}
                  <br />
                  {t("faqQuestions$faq$answer_13_8")}
                  <br />
                  <a
                    href="https://www.svenskakyrkan.se/jourhavandeprast"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#589AAF" }}
                  >
                    {t("faqQuestions$faq$answer_13_7")}
                  </a>
                  <br />
                  {t("faqQuestions$faq$answer_13_9")}
                </li>
                <li>
                  {t("faqQuestions$faq$answer_13_10")}
                  <br />
                  Tel: 08 – 702 16 80
                  <br />
                  <a
                    href="https://www.jourhavande-medmanniska.se/prata-med-oss/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#589AAF" }}
                  >
                    {t("faqQuestions$faq$answer_13_11")}
                  </a>
                  <br />
                  {t("faqQuestions$faq$answer_13_12")}
                  <br />
                  {t("faqQuestions$faq$answer_13_13")}
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
  ];

  const filteredSections = treatmentQuestions
    .map((section) => ({
      ...section,
      faqs: section.faqs.filter((faq) => {
        return faq.question.toLowerCase().includes(searchQuery.toLowerCase());
      }),
    }))
    .filter((section) => section.faqs.length > 0);

  const hasResults = filteredSections.length > 0;
  return (
    <Flex
      w="full"
      mx="auto"
      maxW={{ base: "auto", sm: "1278px", md: "1352px", lg: "1652px" }}
      direction="column"
      gap=".5rem"
      py={{ base: "1.5rem", sm: "3.75rem" }}
      px={{ base: "2rem", sm: "7rem", md: "13rem", lg: "11rem" }}
    >
      <Image src={treatment_info} w={{ base: "8%", sm: "4%" }} />
      <Heading
        fontSize={{ base: "20px", sm: "2rem" }}
        color="rgba(0, 38, 55, 1)"
        fontWeight={500}
      >
        {t("Treatment Information")}
      </Heading>
      <Text color="rgba(0, 38, 55, 1)" fontSize="1rem" fontWeight={400}>
        {t("For users who want to discover more about Lamina Clinic")}.
      </Text>

      {hasResults ? (
        <Flex direction="column" gap=".5rem">
          {filteredSections.map((section, sectionIndex) => (
            <Box key={sectionIndex} mb="1rem">
              <Heading
                fontSize="24px"
                color="rgba(88, 154, 175, 1)"
                fontWeight={500}
              >
                {t(section.title)}
              </Heading>
              <Accordion
                allowToggle
                w="100%"
                bg="rgba(88, 154, 175, 0.1)"
                maxH={"fit-content"}
              >
                {section.faqs.map((faq, index) => (
                  <AccordionItem key={index}>
                    {({ isExpanded }) => (
                      <>
                        <Box id={faq.hash}>
                          <AccordionButton
                            w="full"
                            justifyContent="space-between"
                            py=".75rem"
                            px="1.25rem"
                            onClick={() => {
                              history.push("#" + faq.hash);
                            }}
                          >
                            <Text
                              flex="1"
                              textAlign="left"
                              fontWeight={700}
                              color={
                                isExpanded
                                  ? "rgba(88, 154, 175, 1)"
                                  : "rgba(0, 38, 55, 1)"
                              }
                              fontSize={{ base: "1rem", sm: "1.25rem" }}
                              whiteSpace="pre-wrap"
                            >
                              {t(faq.question)}
                            </Text>
                            {isExpanded ? <MinusIcon /> : <PlusIcon />}
                          </AccordionButton>
                        </Box>

                        <Box w="full" h=".875rem" bg="white" />
                        <AccordionPanel
                          id={faq.hash}
                          pb={4}
                          bg="white"
                          fontSize="1rem"
                          fontWeight={400}
                          px="1.25rem"
                        >
                          {faq.answer}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
          ))}
        </Flex>
      ) : (
        <Text
          color="rgba(88, 154, 175, 1)"
          fontWeight={600}
          fontSize={{ base: "1rem", sm: "1.5rem" }}
        >
          {t("No results found for your search")}
        </Text>
      )}
    </Flex>
  );
};

export default FaqTreatmentQuestionsBlock;
