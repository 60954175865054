import { Select } from "chakra-react-select";
import { FunctionComponent } from "react";

interface PaceFilterProps {
  value?: number;
  onChange(newValue: number): void;
}

const options = [
  { label: "15%", value: 15 },
  { label: "10%", value: 10 },

  { label: "5%", value: 5 },
];

const PaceFilter: FunctionComponent<PaceFilterProps> = ({
  value,
  onChange,
}) => {
  return (
    <Select
      className="chakra-react-select"
      classNamePrefix="chakra-react-select"
      selectedOptionStyle="check"
      variant={"outlined"}
      options={options}
      value={options.find((e) => e.value === value)}
      menuPlacement="bottom"
      menuPortalTarget={document.body}
      onChange={(e) => {
        if (e) {
          onChange(e?.value);
        }
      }}
      chakraStyles={{
        menu: (prev) => ({
          ...prev,
          zIndex: "1000000",
          overflowY: "visible",
        }),
        control: (prev) => ({
          ...prev,
          bg: "transparent !important",
          border: "1px solid rgba(0, 0, 0, 0.1) !important",
          minH: "3rem !important",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          bg: "transparent",
          p: 0,
          w: 6,
          mx: 2,
          cursor: "inherit",
        }),
        valueContainer: (provided) => ({
          ...provided,
          fontSize: "1rem",
          px: 0,
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: "none",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          cursor: "pointer",
        }),
      }}
    />
  );
};

export default PaceFilter;
