import { Axios } from "axios";
import { APIClient } from "services/api/client";
import useSWR, { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";
import { SubscriptionsResponseType, UserAddressType } from "./types";
import { useAdminUserInfo } from "features/admin/api/client";

export const usePostNewAddress = () => {
  return useSWRMutation<any, UserAddressType>(
    "delivery/",
    (url: string, { arg }: { arg: any }) => {
      return APIClient.post(url, arg.address);
    }
  );
};

export const useEditAddress = (addressId?: string) => {
  return useSWRMutation(
    ["delivery/", addressId],
    ([url, addressId], { arg }) => {
      return APIClient.patch(url + addressId + "/", arg.address);
    }
  );
};

export const useFetchAddressById = (id?: string) => {
  return useSWR<UserAddressType>(["delivery/", id], ([url, id]) => {
    return APIClient.get(url + id + "/");
  });
};

export const useFetchDefaultAddress = () => {
  return useSWR<UserAddressType>("address/", (url) => {
    return APIClient.get(url, {
      transformResponse: (data) => {
        return JSON.parse(data)[0];
      },
    });
  });
};

export const useCreateDefaultAddress = () => {
  const { mutate } = useSWRConfig();
  return useSWRMutation<any, UserAddressType>(
    "address/",
    (url: string, { arg }: { arg: any }) => {
      return APIClient.post(url, arg.address);
    },
    {
      onSuccess: () => {
        mutate("address/");
      },
    }
  );
};

export const useGetDefaultAddressById = (addressId: string) => {
  const config = useSWRConfig();
  return useSWR<any, UserAddressType>(
    ["address/", addressId],
    ([url, addressId]) => {
      return APIClient.get(url + addressId + "/");
    },
    {}
  );
};

export const useUpdateDefaultAddress = (
  addressId: string,
  patientId?: string
) => {
  const config = useSWRConfig();

  const { mutate } = useAdminUserInfo(patientId ?? "");

  return useSWRMutation(
    ["address/", addressId],
    ([url, addressId], { arg }) => {
      return APIClient.patch(url + addressId + "/", arg.address);
    },
    {
      onSuccess: () => {
        config.mutate("address/");
        config.mutate("address/" + addressId + "/");
        mutate();
      },
    }
  );
};

export const useFetchSubscription = () => {
  return useSWR<SubscriptionsResponseType>("subscriptions/", (url) =>
    APIClient.get(url)
  );
};
