import { Center, Flex, VStack, Button, Text, Link } from "@chakra-ui/react";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface DoctorRejectedProps {}

const DoctorRejected: FunctionComponent<DoctorRejectedProps> = () => {
  const { t } = useTranslation();
  const { data: profileData } = useFetchProfileInfo();
  const history = useHistory();

  return (
    <Flex w="100%" bg="white" borderRadius="20px">
      <Center
        w="100%"
        justifyContent={"space-between"}
        py={{ base: "1rem", lg: "4rem" }}
        h={{ base: "75vh", lg: "80vh" }}
        px={"10rem"}
      >
        <Flex
          flexDir="column"
          justifyContent={"space-between"}
          h={"100%"}
          w={"100%"}
        >
          <VStack
            alignItems={"flex-start"}
            px={"5rem"}
            maxW={"60rem"}
            gap={"1rem"}
            mt={"15%"}
          >
            <Text color={"blueish.900"} fontWeight={"600"} fontSize={"2rem"}>
              {t("Sorry! Our doctor rejected your treamtent")}
            </Text>
            <Text
              color={"blueish.900"}
              fontSize={{ base: "1.2rem", lg: "1.5rem" }}
            >
              Our doctor has rejected your treatment for the following reason:
              {profileData?.reject_comment}
            </Text>
            <Text
              color={"blueish.900"}
              fontSize={{ base: "1.2rem", lg: "1.5rem" }}
            >
              {t("Stay strong and keep up your amazing work!")}
            </Text>
            <Text
              color={"blueish.900"}
              fontSize={{ base: "1.2rem", lg: "1.5rem" }}
            >
              {t("Lamina clinic team")}
            </Text>
          </VStack>
          <Button
            variant={"transparent"}
            color={"#002637"}
            opacity={0.5}
            onClick={() => history.push("/")}
            fontSize={{ base: "sm", sm: "sm", md: "md" }}
          >
            {t("Back")}
          </Button>
        </Flex>
      </Center>
    </Flex>
  );
};

export default DoctorRejected;
