import {
  Flex,
  HStack,
  VStack,
  Heading,
  Image,
  Text,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import CalendlyModalWidget from "features/CalendlyWidget";
import PopupChange from "features/prescriptionForm/components/prescriptionPopupChange";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface BookMeetingPromptProps {}

const BookMeetingPrompt: FunctionComponent<BookMeetingPromptProps> = () => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      w="100%"
      bg="white"
      p="2.5rem"
      borderRadius="12px"
      align="flex-start"
      direction="column"
      gap={2.5}
    >
      <Flex w="100%" align="flex-start" justify="space-between">
        {/* <Image src={treatment} w="72px" alt={"treatment icon"} /> */}

        <HStack spacing={"2.5rem"}></HStack>
      </Flex>
      <Flex direction="column" w="100%" alignItems={"flex-start"}>
        <Heading color="rgba(88, 154, 175, 1)" fontWeight={500} fontSize="20px">
          {t("DOWNLOAD THE APP TO GET STARTED")}
        </Heading>

        <Text color={"blueish.900"} fontSize={"1rem"} mt={"1rem"} opacity={0.6}>
          {t(
            "Get started by booking your first assessment appointment with one of our experienced nurses. The meeting costs SEK 275."
          )}
        </Text>

        <Text
          color={"blueish.900"}
          fontSize={"1rem"}
          mt={"0.5rem"}
          opacity={0.6}
        >
          {t(
            "When you have booked an appointment, you download the app. This is where you pay and receive the call."
          )}
        </Text>

        {/* <Button
          alignSelf="flex-end"
          py="0.85rem"
          mt={"1.5rem"}
          px="1.75rem !important"
          minWidth={"none"}
          variant="filledBlue"
          fontSize={{ base: "14px", md: "16px" }}
          onClick={onOpen}
          _hover={{
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          }}
          bg={"#2C8475"}
          transition={"all 0.3s ease-out"}
        >
          Boka bedömningsmöte
        </Button> */}
      </Flex>

      <CalendlyModalWidget isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

export default BookMeetingPrompt;
