import { Image, Flex, Heading, Text, Box } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import hands from "assets/images/hands.png";
import vector from "assets/images/vector.svg";
import AnimatedText from "ui/components/AnimatedText";

const HomeMotto = () => {
  const { t } = useTranslation();

  return (
    <>
      <Flex
        w={"100%"}
        px={{ base: "1rem", sm: "2rem", md: "5rem" }}
        gap={{ base: "1rem", sm: "10%" }}
        align="center"
        alignItems={"stretch"}
        position={"relative"}
        m={{ base: "1rem", sm: "0rem !important" }}
      >
        <Image
          src={hands}
          alt="Decorative hands image"
          display={{ base: "none", sm: "block" }}
          w={{ base: "40%", sm: "50%" }}
          h={"100% !important"}
        />
        <Flex
          direction={"column"}
          /* mb={"-1rem"} */
          mt="-5rem"
          p={["1rem", "0rem", "0rem"]}
          gap={{ base: 5, sm: 5 }}
          justifyContent={{
            base: "flex-start",
            md: "center",
          }}
          pt={{ base: "7%", md: "0" }}
        >
          <Box>
            <Heading
              variant={"light"}
              fontSize={{
                base: "30px",
                sm: "30px",
                md: "40px",
                lg: "xxl",
              }}
              lineHeight={"1em"}
              w={{ base: "50%", sm: "45%" }}
              whiteSpace={"pre-line"}
              textAlign={{ base: "center", sm: "inherit" }}
              mb={0}
              mt={{ base: "", sm: "-3rem", lg: "-2rem" }}
            >
              <AnimatedText>{t("mottoHeader")}</AnimatedText>
            </Heading>
            {/* <Heading
              variant={"light"}
              fontSize={{
                base: "30px",
                sm: "30px",
                md: "40px",
                lg: "xxl",
              }}
              lineHeight={"1em"}
              w={{
                base: "50%",
                sm: "60%",
                md: "50%",
                lg: "40%",
              }}
              textAlign={{ base: "center", sm: "inherit" }}
            >
              {t("mottoHeader").split(" ").slice(1).join(" ")}
            </Heading> */}
          </Box>

          <Image
            src={vector}
            alt={"decorative fragments"}
            position={"absolute"}
            top={{
              base: "0",
              sm: "-90%",
              md: "-86%",
              lg: "-80%",
            }}
            left={{
              base: "0",
              sm: "40%",
              md: "36%",
              lg: "34%",
            }}
            h={{ base: "130%", sm: "110%", md: "110%" }}
            zIndex={"0"}
            display={{ base: "none", sm: "block" }}
            maxW={{ base: "50%", md: "none" }}
          />
          <Text
            fontSize={{
              base: "16px",
              sm: "20px",
              md: "24px",
            }}
            w={"100%"}
          >
            <AnimatedText>{t("mottoDiscription")}</AnimatedText>
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default HomeMotto;
