import { VStack, Heading, HStack } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import PillIcon from "ui/iconComponents/Pillicon";

interface TablePillsComponentProps {
  pills: number[];
}

const TablePillsComponent: FunctionComponent<TablePillsComponentProps> = ({
  pills,
}) => {
  return (
    <HStack
      alignItems={"flex-start"}
      flexWrap={"wrap"}
      py={"0.5rem"}
      spacing={0}
      px={'1rem'}
      rowGap={'0.5rem'}
    >
      {pills.map((pill) => {
        return (
          <VStack alignItems={"flex-start"} spacing={0} gap={0} w={"3rem"}>
            <PillIcon />
            <Heading
              fontSize={"0.75rem"}
              color={"blueish.900"}
              fontWeight={"400"}
              opacity={0.5}
            >
              {pill} mg
            </Heading>
          </VStack>
        );
      })}
    </HStack>
  );
};

export default TablePillsComponent;
