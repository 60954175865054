import { Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const CurrentDate = () => {
  const { t } = useTranslation();
  return (
    <Text
      color="rgba(0, 38, 55, 0.5)"
      fontSize={{ base: "12px", md: "14px", lg: "16px" }}
      fontWeight={400}
      textTransform={"capitalize"}
      alignSelf="flex-start"
      pb="5"
    >
      {new Date().toLocaleDateString(t("locale") ?? "en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
        weekday: "long",
      })}
    </Text>
  );
};

export default CurrentDate;
