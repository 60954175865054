import { FunctionComponent, useEffect, useState } from "react";
import { Select } from "chakra-react-select";
import { type FilterOptionType } from "features/reporting/api/type";
import { useTranslation } from "react-i18next";

type FunctionComponentProps = {
  value: string;
  onChange(newValue: string): void;
};
const logsActionsOptions: FilterOptionType[] = [
  { label: "message", value: "message" },
  { label: "stabilization", value: "stabilization" },
  { label: "call", value: "call" },
  { label: "new tapering", value: "new tapering" },
  { label: "dose change", value: "dose change" },
  { label: "change medical type", value: "change medical type" },
  { label: "decline treatment", value: "decline treatment" },
  {
    label: "change treatment start date",
    value: "change treatment start date",
  },
  {
    label: "new medical personal",
    value: "new medical personal",
  },
  {
    label: "removed medical personal",
    value: "removed medical personal",
  },
];

const EventFilter: FunctionComponent<FunctionComponentProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = useState<string>(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleFilterChange = (newValue: FilterOptionType | null) => {
    if (newValue) {
      setSelectedValue(newValue.value);
      onChange(newValue.value);
    }
  };
  return (
    <>
      <Select
        key={selectedValue}
        className="chakra-react-select"
        classNamePrefix="chakra-react-select"
        selectedOptionStyle="check"
        variant={"outlined"}
        options={logsActionsOptions}
        value={logsActionsOptions.find((e) => e.value === selectedValue)}
        menuPlacement="bottom"
        menuPortalTarget={document.body}
        onChange={handleFilterChange}
        placeholder={`${t("Försök igen")}`}
        chakraStyles={{
          menu: (prev) => ({
            ...prev,
            zIndex: "1000000",
            overflowY: "visible",
          }),
          control: (prev) => ({
            ...prev,
            bg: "transparent !important",
            border: "1px solid rgba(0, 0, 0, 0.1) !important",
            minH: "3rem !important",
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            bg: "transparent",
            p: 0,
            w: 6,
            mx: 2,
            cursor: "inherit",
          }),
          valueContainer: (provided) => ({
            ...provided,
            fontSize: "1rem",
            px: 0,
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            cursor: "pointer",
          }),
        }}
      />
    </>
  );
};

export default EventFilter;
