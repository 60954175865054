import { Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";

const FailLoad = () => {
  return (
    <Flex w="100%" justifyContent="center" alignItems="center" py="5rem">
      <Flex direction="column" alignItems="center" bg="white" w="100%">
        <Text p="1rem" fontWeight="bold" color="blueish.500">
          {t("No users found")}
        </Text>
      </Flex>
    </Flex>
  );
};

export default FailLoad;
