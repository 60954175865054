import { useState, useEffect } from "react";
import { db } from "services/firebase/config";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";

import { GroupedMessages, type MessageItem } from "../api/types";

export const useChat = (chat_id: string | undefined) => {
  const [groupedMessages, setGroupedMessages] = useState<GroupedMessages>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (chat_id) {
      const chatRef = collection(db, "chats", chat_id, "messages");

      const getChatMessages = onSnapshot(
        query(chatRef, orderBy("publishedAt")),
        (snapshot) => {
          const groupedMessages: GroupedMessages = {};

          snapshot.forEach((doc: any) => {
            const messageItem = {
              publishedBy: doc.id,
              ...doc.data(),
            } as MessageItem;

            const date = new Date(messageItem.publishedAt);
            const formattedDate = `${date.getFullYear()}-${
              date.getMonth() + 1
            }-${date.getDate()}`;

            if (!groupedMessages[formattedDate]) {
              groupedMessages[formattedDate] = [];
            }

            groupedMessages[formattedDate].push(messageItem);
          });

          setGroupedMessages(groupedMessages);
          setIsLoading(false);
        }
      );

      return () => {
        getChatMessages();
      };
    } else {
      setIsLoading(false);
    }
  }, [chat_id]);

  return { messages: groupedMessages, isLoading };
};
