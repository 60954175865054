import {
  Flex,
  Heading,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Text,
  Image,
} from "@chakra-ui/react";

import { useHistory } from "react-router-dom";

import left_arrow_white from "assets/icons/left_arrow_white.svg";

import { useTranslation } from "react-i18next";

const FaqNavbar = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  return (
    <Flex w="full" direction="column">
      <Flex w="full" justify="space-between">
        <Flex
          align="center"
          gap="14px"
          onClick={() => history.goBack()}
          cursor="pointer"
        >
          <Image src={left_arrow_white} />
          <Text fontSize="18px">{t("Back")}</Text>
        </Flex>

        {/* LANDUAGE */}
        {/*TODO: uncomment language switch */}
        {/* <Flex>
          <Popover arrowShadowColor="transparent">
            <PopoverTrigger>
              <Button variant="ghost" color="white">
                {i18n.language.toUpperCase()}
              </Button>
            </PopoverTrigger>
            <PopoverContent
              zIndex={4}
              bg={"#589AAF"}
              width="80px"
              border={"none"}
            >
              <PopoverArrow
                backgroundColor={"#589AAF"}
                border="none !important"
              />
              <PopoverBody
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Button
                  onClick={() => i18n.changeLanguage("en")}
                  variant="ghost"
                  color={"white"}
                >
                  EN
                </Button>
                <Button
                  onClick={() => i18n.changeLanguage("sv")}
                  variant="ghost"
                  color={"white"}
                >
                  SV
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex> */}
      </Flex>

      <Flex w="full" direction="column">
        <Heading
          color="rgba(255, 255, 255, 1)"
          fontSize={{ base: "36px", sm: "40px" }}
          fontWeight={500}
        >
          Vanliga frågor
        </Heading>
        <Text
          fontSize={{ base: "16px", sm: "18px", md: "20px", lg: "20px" }}
          w={{ base: "100%", sm: "70%", md: "100%", lg: "100%" }}
        >
          {t(
            "Here you will find answers to the most commonly asked questions about Lamina Clinic."
          )}
        </Text>
      </Flex>
    </Flex>
  );
};

export default FaqNavbar;
