import * as Yup from "yup";

export const ContactValidationSchema = Yup.object().shape({
  /*  email: Yup.string().email("Försök igen").required("Försök igen"),
  phone_number: Yup.string()
    .required("Försök igen")
    .matches(
      /^(\+\d{1,3}\s?)?(\(\d{1,3}\)|\d{1,3})[-.\s]?\d{1,3}[-.\s]?\d{1,4}$/,
      "Phone number is not valid"
    ), */
  name: Yup.string().required("Försök igen"),
});
