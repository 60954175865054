import { UserAddressType } from "features/addressPopup/api/types";
import { t } from "i18next";
import * as Yup from "yup";

export const AddressSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "First name is too short")
    .required("Försök igen"),
  last_name: Yup.string()
    .min(2, "Last name is too short")
    .required("Försök igen"),
  email: Yup.string().email("Invalid email address").required("Försök igen"),
  phone: Yup.string().required("Försök igen"),
  zip_code: Yup.string().required("Försök igen"),
  country: Yup.string().required("Försök igen"),
  city: Yup.string().required("Försök igen"),
  address: Yup.string().required("Försök igen"),
});

export const PersonalInformationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "First name is too short")
    .required("Försök igen"),
  last_name: Yup.string()
    .min(2, "Last name is too short")
    .required("Försök igen"),
  phone: Yup.string().required("Försök igen"),
  email: Yup.string().email("Invalid email address").required("Försök igen"),
});

export const DeliveryAddressSchema = Yup.object().shape({
  zip_code: Yup.string().nullable().required("Försök igen"),
  country: Yup.string().required("Försök igen"),
  city: Yup.string().required("Försök igen"),
  address: Yup.string().required("Försök igen"),
});
