import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
  Editable,
  EditablePreview,
  EditableInput,
  ButtonGroup,
  useEditableControls,
  IconButton,
} from "@chakra-ui/react";

import DoctorDayPicker from "../DoctorDayPicker";

import { getFormatedDate } from "features/dashboard/dateHelper";

import date from "assets/icons/date.svg";
import {
  useFetchProfileInfo,
  usePatchAdminUserProfile,
} from "features/dashboard/api/client";
import { HiStatusOnline } from "react-icons/hi";
import { useHistory, useParams } from "react-router-dom";
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from "react-icons/ai";

import AddSignatureButton from "../AddSignatureButton";
import { useAdminUserInfo } from "features/admin/api/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CiEdit } from "react-icons/ci";
import DoctorPrescriptionButton from "../DoctorPrescriptionButton";

function EditableControls() {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton
        aria-label=""
        variant={"ghost"}
        icon={<AiOutlineCheck />}
        {...getSubmitButtonProps()}
      />
      <IconButton
        aria-label=""
        variant={"ghost"}
        icon={<AiOutlineClose />}
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton
        aria-label=""
        variant={"ghost"}
        icon={<AiOutlineEdit />}
        {...getEditButtonProps()}
      />
    </Flex>
  );
}

const DoctorHero = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { data: profileData } = useFetchProfileInfo();
  const history = useHistory();
  const formattedDate = getFormatedDate("day-month-year");

  const formattedMonth = formattedDate.replace(
    /(?<=, )\w+/,

    (match) => match.charAt(0).toUpperCase() + match.slice(1)
  );

  const { trigger: updateProfile } = usePatchAdminUserProfile(
    profileData?.id ?? ""
  );

  const [pharmacyCodeValue, setPharmacyCode] = useState<string>("");

  useEffect(() => {
    if (profileData?.pharmacy_code) setPharmacyCode(profileData?.pharmacy_code);
  }, [profileData?.pharmacy_code]);

  return (
    <Flex w="full" direction="column" py="48px">
      <Flex
        justify="space-between"
        direction={{ base: "column", sm: "row" }}
        gap={{ base: "5", sm: "0" }}
      >
        <Flex direction="column">
          <Flex alignItems={"baseline"} gap={"1rem"}>
            <Heading
              fontSize={{ base: "28px", md: "30px", lg: "32px" }}
              fontWeight={600}
              letterSpacing={0.8}
            >
              {t("Welcome")}, {profileData?.first_name}
            </Heading>
            <Button
              opacity={"0.5"}
              variant={"transparent"}
              color={"blueish.900"}
              fontFamily={"secondary"}
              fontWeight={400}
              fontSize={"0.875rem"}
              rightIcon={<CiEdit />}
              onClick={() => {
                history.push("/doctor/edit-profile");
              }}
            >
              {t("Edit profile")}
            </Button>
          </Flex>

          <Text
            color="rgba(0, 38, 55, 0.5)"
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            fontWeight={400}
          >
            {formattedMonth}
          </Text>
        </Flex>

        <Flex
          align="center"
          gap="12px"
          direction={{ base: "column", sm: "row" }}
        >
          <Text
            color="rgba(0, 38, 55, 0.5)"
            fontFamily="secondary"
            fontSize="14px"
            fontWeight={600}
          >
            {t("PRESCRIBER CODE")}
          </Text>
          <Box
            py="4px"
            px="12px"
            bg="rgba(88, 154, 175, 0.5)"
            borderRadius="8px"
          >
            <Editable
              defaultValue={profileData?.pharmacy_code}
              value={pharmacyCodeValue}
              fontSize="16px"
              fontWeight={700}
              color="rgba(88, 154, 175, 1)"
              onChange={(value) => {
                setPharmacyCode(value);
              }}
              onSubmit={(value) => {
                updateProfile({ pharmacy_code: value });
              }}
            >
              <Flex alignItems={"center"}>
                <EditablePreview />
                <EditableInput />
                <EditableControls />
              </Flex>
            </Editable>
          </Box>
          {/* <Button ml="20px" variant="adjust">
            <Image src={date} mr="6px" />
            Set Leave Dates
          </Button> */}
          <Button
            variant={"transparent"}
            fontSize={"1rem"}
            color={"blueish.900"}
            px={0}
            ml={"1rem"}
            opacity={0.5}
            onClick={() => {
              history.push("/doctor/tapering");
            }}
          >
            {t("View tapering")}
          </Button>

          <AddSignatureButton />

          <DoctorPrescriptionButton />
        </Flex>
      </Flex>
      {/* 
      {/* 
      <Box py="24px">
        <DoctorDayPicker />
      </Box> */}
    </Flex>
  );
};

export default DoctorHero;
