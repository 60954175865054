import {
  Button,
  Heading,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFetchUserLoggedEvents } from "features/admin/api/client";
import { FaAngleDown } from "react-icons/fa6";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const PatientLoggedEvents = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const { medicalHistory, isLoading, hasNextPage, loadMore } =
    useFetchUserLoggedEvents(id);

  const trimDate = (dateString: string) => {
    return dateString.substring(0, 10);
  };

  const eventsCount = medicalHistory.reduce(
    (total, historyEvent) => total + historyEvent.results.length,
    0
  );

  return (
    <Stack
      bg="white"
      borderRadius="0.75rem"
      p="2rem"
      height="fit-content"
      maxW="100%"
      minW="23rem"
      spacing="1.5rem"
      position="relative"
      overflowY={hasNextPage ? "auto" : "hidden"}
      maxHeight={hasNextPage ? "60rem" : "fit-content"}
    >
      <Stack>
        <Heading
          fontSize="1rem"
          fontWeight={600}
          fontFamily="secondary"
          color="#589AAF"
        >
          {t("LOGGED EVENTS")}
        </Heading>

        {!isLoading &&
          medicalHistory.map((historyEvent) =>
            historyEvent.results.map((event) => (
              <VStack align="flex-start" key={event.id}>
                <Heading
                  fontSize=".875rem"
                  textTransform="uppercase"
                  fontWeight={500}
                  color="#002637"
                  opacity={0.5}
                >
                  {trimDate(event.date)}
                </Heading>
                <Text fontSize="1rem" fontWeight={400} color="#000000">
                  {event.text}
                </Text>
              </VStack>
            ))
          )}

        {isLoading && (
          <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blueish.500"
            alignSelf="center"
          />
        )}

        {!isLoading &&
          medicalHistory.every((history) => history.results.length === 0) && (
            <Text color="#002637" fontSize="1rem" opacity={0.5}>
              {t("No logged events")}
            </Text>
          )}

        {eventsCount >= 10 && hasNextPage && (
          <Button
            rightIcon={<FaAngleDown />}
            color="#002637"
            fontSize="1rem"
            px="0 !important"
            alignSelf="flex-start"
            variant="tertiary"
            onClick={loadMore}
            isLoading={isLoading}
          >
            {isLoading ? t("Loading...") : t("Show More")}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default PatientLoggedEvents;
