import {
  APIClient,
  UninterceptedAPIClient,
} from "../../../services/api/client";
import useSWR, { useSWRConfig } from "swr";
import {
  AnimatedQrCodeResponseType,
  StatusResponseType,
  TokensResponseType,
  UserResponseType,
} from "./types";
import useSWRMutation from "swr/mutation";
import { useCookies } from "react-cookie";
import { useTokenStore } from "services/state/tokenStore";
import useSWRImmutable from "swr/immutable";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";
import { useAdminStore } from "services/state/adminStore";
import { useHistory } from "react-router-dom";

export const useCheckLogin = () => {
  const { data: tokenData } = useLogin();
  const { mutate } = useSWRConfig();
  const { setRefresh, setAccess } = useTokenStore();
  const { loginEvent } = useGoogleAnalytics();
  const { setAdmin } = useAdminStore();

  return useSWR<StatusResponseType | AnimatedQrCodeResponseType>(
    () =>
      tokenData?.order_ref
        ? `collect_status/?order_ref=${tokenData.order_ref}`
        : null,
    (url: string) =>
      UninterceptedAPIClient.get(url).then((res) => {
        return {
          code: res.status,
          data: res.status !== 202 || res.data === "" ? undefined : res.data,
          qrData: res.status === 201 ? res.data : undefined,
        };
      }),
    {
      onSuccess: (latestData) => {
        if (latestData.code === 202) {
          if ("data" in latestData) {
            mutate("profile/");
            loginEvent();
            setAccess(latestData.data?.access ?? "");
            setRefresh(latestData.data?.refresh ?? "");
            setAdmin(false);
          }
        }
      },
      refreshInterval: (latestData) => {
        if (latestData?.code === 202 || latestData?.code === 205) {
          return 0;
        }
        return 1000;
      },
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
    }
  );
};

export const useTestLogin = () => {
  const { data: tokenData } = useLogin();
  const { setRefresh, setAccess } = useTokenStore();
  const { mutate } = useSWRConfig();

  const { loginEvent } = useGoogleAnalytics();

  return useSWRMutation<UserResponseType>(
    () =>
      tokenData?.order_ref
        ? process.env.REACT_APP_ENV === "preprod"
          ? "login_test_mikael/"
          : `login_test/`
        : null,
    (url: string) => {
      return UninterceptedAPIClient.get(url).then((res) => {
        return res.data;
      });
    },

    {
      onSuccess: (latestData) => {
        if (latestData) {
          loginEvent();
          setAccess(latestData?.access ?? "");
          setRefresh(latestData?.refresh ?? "");
        }
      },
    }
  );
};

export const useGeneralLoginData = () => {
  const checkLoginData: StatusResponseType | undefined = useCheckLogin().data;
  const testLoginData = useTestLogin().data;
  if (checkLoginData) {
    return checkLoginData.data;
  }
  return testLoginData;
};

export const useLogin = () => {
  return useSWRImmutable<TokensResponseType>(
    `login/`,
    (url) => APIClient.get(url),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );
};

export const useVerify = () => {
  const { removeAccess, access, refresh } = useTokenStore();
  const { trigger } = useVerifyRefreshToken();

  return useSWR(
    [access ? "token/verify/" : null, access],
    ([url, access]) => {
      if (!url) {
        return;
      }
      return APIClient.post(url, { token: access });
    },
    {
      revalidateOnFocus: true,
      refreshInterval: 10000,
      onError: () => {
        if (refresh !== "") {
          //removeAccess();
          trigger();
        }
      },
    }
  );
};

export const useVerifyRefreshToken = () => {
  const { refresh, removeRefresh, removeAccess, setAccess } = useTokenStore();
  const history = useHistory();

  return useSWRMutation(
    ["token/refresh/", refresh],
    ([url, refresh]) => {
      return APIClient.post(url, { refresh: refresh });
    },
    {
      onSuccess: (data: any) => {
        setAccess(data.access);
      },
      onError: (data) => {
        history.push("/");
        removeRefresh();
        removeAccess();
      },
    }
  );
};

export const useLogOut = () => {
  const { clearTokens, access } = useTokenStore();
  const { mutate } = useSWRConfig();

  return useSWRMutation(
    "token/logout/",
    (url) => {
      APIClient.post(url, { token: access });
    },
    {
      onSuccess(data, key, config) {
        console.log("LOGGED OUT SUCCESSFULLY");
        clearTokens();
        //clearing all previous cache
        mutate((key) => true, undefined, { revalidate: false });
      },
    }
  );
};

export const useClearTestAccount = () => {
  return useSWRMutation("clean/test-user", (url) => {
    UninterceptedAPIClient.get(url);
  });
};
