import { Flex, Heading, Text } from "@chakra-ui/react";
import TestPageForm from "features/testPage/components/testPageForm";

const TestPage = () => {
  return (
    <Flex
      w="100%"
      borderRadius="20px"
      bg="white"
      direction="column"
      p={{ base: "32px 20px", sm: "60px 100px", lg: "60px 160px" }}
      gap="24px"
    >
      <TestPageForm />
    </Flex>
  );
};

export default TestPage;
