export enum JournalEntryType {
  Meeting = "Meeting",
  /*  JSONTreatment = "JSONTreatment", */
  Triage = "Triage",
  Treatment = "Treatment",
  Prescription = "Prescription",
}

export type TriageAnswersType = {
  question: string;
  answer: string | string[];
};

export type TriageEventType = {
  answers: any[];
  completion_date: string;
  id: string;
  patient: string;
  status: string;
};

export type JSONTreatmentEventType = {
  created_at: string;
  duration: number;
  end_date: string;
  id: string;
  is_active: boolean;
  medication: string;
  start_date: string;
  updated_at: string;
  patient: string;
};

export type MeetingEventCallParticipantType = {
  first_name: string;
  id: string;
  last_call_date?: string;
  last_name: string;
  name: string;
  role: string;
};

export type MeetingEventType = {
  id: string;
  call_from: MeetingEventCallParticipantType;
  call_to: MeetingEventCallParticipantType;
  created_at: string;
  type: {
    id: string;
    type: string;
    role: string;
  };
  comment: string;
  timestamp?: string;
  payment?: string;
  status: string;
  cancel_url?: string;
  reschedule_url?: string;
};

export type JournalItemResponseType = {
  tag: string;
  comment?: string;
  event: JournalEventType;
  event_type: JournalEntryType;
  id: string;
  patient: string;
  created_at: string;
  details: any;
  staff_member?: {
    first_name: string;
    id: string;
    last_name: string;
    name: string;
    role: string;
  };
};

export type JournalEntryDisplayType = {
  action: string;
  id: string;
  status: string;
  action_key?: string;
  title?: string;
  writer?: string;
  date: string;
  allAnswers?: Record<string, string>;
  details: any;
  prescriptionEvent: PrescriptionEvent;
};

export type PrescriptionDetailsType = {
  apl: Record<string, number>;
  regular: Record<string, number>;
  regenboogen: Record<string, number>;
};

export type PrescriptionEvent = {
  date: string;
  id: string;
  patient: string;
  pills: PrescriptionDetailsType;
};

export type JournalEventType =
  | JSONTreatmentEventType
  | PrescriptionEvent
  | MeetingEventType
  | TriageEventType;
