import { Flex, Heading, Text, Image, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import left_arrow from "assets/icons/left_arrow.svg";

const BuyAtLaminaClinic = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Box pb={"5vh"} w="full">
      <Flex
        w="full"
        bg="rgba(255, 255, 255, 1)"
        borderRadius="24px"
        p="60px"
        direction="column"
        gap="10px"
      >
        <Flex
          align="center"
          gap="14px"
          onClick={() => history.goBack()}
          cursor="pointer"
        >
          <Image src={left_arrow} />
          <Text fontSize="18px" color="black" fontWeight={500}>
            {t("Back")}
          </Text>
        </Flex>
        <Flex w="100%" align="center" justify="center" mb="30px">
          <Heading
            fontSize={{ base: "1.5rem", sm: "4xl" }}
            color="blueish.500"
            fontWeight={500}
            mr={{ base: "1rem", sm: "0px" }}
          >
            {t("BUY AT LAMINA CLINIC")}
          </Heading>
        </Flex>

        {/*  Prescription */}
        <div id="order">
          <Flex w="full" direction="column">
            <Heading
              fontSize={{ base: "1.5rem", sm: "2rem" }}
              textAlign={{ base: "start", sm: "center" }}
              fontWeight={500}
            >
              {t("Ordering")}
            </Heading>
            <Text color="black" fontSize={{ base: "16", sm: "20px" }}>
              {t(
                "Your prescription serves as your order for your tapering medications."
              )}
            </Text>
          </Flex>
        </div>

        {/* What medication do we support? */}
        <div id="delivery">
          <Flex w="100%" mb="30px" direction="column">
            <Heading
              fontSize={{ base: "1.5rem", sm: "2rem" }}
              textAlign={{ base: "start", sm: "center" }}
              fontWeight={500}
            >
              {t("Delivery")}
            </Heading>
            <Text color="black" fontSize={{ base: "16", sm: "20px" }}>
              {t(
                "Your tapering medications will be sent directly to the address that you given as delivery address."
              )}
            </Text>
          </Flex>
        </div>

        {/* Payment */}
        <div id="payment">
          <Flex w="100%" mb="30px" direction="column">
            <Heading
              fontSize={{ base: "1.5rem", sm: "2rem" }}
              textAlign={{ base: "start", sm: "center" }}
              fontWeight={500}
            >
              {t("Payment")}
            </Heading>
            <Text color="black" fontSize={{ base: "16", sm: "20px" }}>
              {t(
                "You can pay with debet/credit cards (Visa and Mastercard) with us.When you approve a tapering schedule, this becomes a subscription for you that runs until you are finished with your treatment. You can safely terminate your subscription at any time for any reason,and the notice period is only 14 days."
              )}
            </Text>
          </Flex>
        </div>

        {/* Safe payments */}
        <div id="safe-payments">
          <Flex w="100%" mb="30px" direction="column">
            <Heading
              fontSize={{ base: "1.5rem", sm: "2rem" }}
              textAlign={{ base: "start", sm: "center" }}
              fontWeight={500}
            >
              {t("Safe payments")}
            </Heading>
            <Text color="black" fontSize={{ base: "16", sm: "20px" }}>
              {t(
                "Lamina clinic uses safe payments through our payment partner NETS."
              )}
            </Text>
          </Flex>
        </div>

        {/* Returns & Complaints */}
        <div id="returns-complaints">
          <Flex w="100%" mb="30px" direction="column">
            <Heading
              fontSize={{ base: "1.5rem", sm: "2rem" }}
              textAlign={{ base: "start", sm: "center" }}
              fontWeight={500}
            >
              {t("Returns & Complaints")}
            </Heading>
            <Text color="black" fontSize={{ base: "16", sm: "20px" }}>
              {t(
                "Please note that medicines (prescription products) are exempt from the right of returning goods and cannot be returned. If a medicine is returned, it will be destroyed and charged to the customer.This also applies to uncollected and undelivered packages/letters that are returned from the shipping company to Lamina Clinic, as well as packages that are returned due to incomplete address information from the customer. Medicines that are returned to us must be disposed of by law."
              )}
            </Text>
          </Flex>
        </div>
      </Flex>
    </Box>
  );
};

export default BuyAtLaminaClinic;
