import { useChatsList } from "features/doctors/components/ChatDrawer/hooks/useChatList";
import { FunctionComponent, createContext } from "react";

interface ChatContextProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

const ChatContext = createContext<ReturnType<typeof useChatsList>>({
  users: undefined,
  chatsList: [],
  isLoading: false,
  hanldeClearUnread: (chatId: string) => {},
});

const ChatContextProvider: FunctionComponent<ChatContextProviderProps> = ({
  children,
}) => {
  const hookData = useChatsList();
  return (
    <ChatContext.Provider value={{ ...hookData }}>
      {children}
    </ChatContext.Provider>
  );
};

export { ChatContextProvider, ChatContext };
