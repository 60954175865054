import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import AddressForm from "../addressForm";
import MedicationSidebar from "../medicationSidebar";

interface AddressPopupProps {
  isOpen: boolean;
  onClose(): void;
  editMode?: boolean;
}

const AddressPopup: FunctionComponent<AddressPopupProps> = ({
  isOpen,
  onClose,
  editMode,
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        mt={{ base: "85rem", sm: "0rem" }}
        maxW={{ base: "1300px", lg: "1680px" }}
        minH={{ base: "80vh", lg: "85vh" }}
        flexDir={{ base: "column", sm: "row" }}
        alignItems={"stretch"}
        borderRadius={"20px"}
        w="94%"
      >
        <MedicationSidebar />
        <AddressForm
          onClose={() => {
            onClose();
          }}
          editMode={editMode}
        />
      </ModalContent>
    </Modal>
  );
};

export default AddressPopup;
