import {
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import logo from "assets/images/logo.svg";
import burger from "assets/images/hamburger-menu.svg";
import background from "assets/images/background.svg";
import SidebarMobileDrawer from "features/dashboard/dashboardSidebarMobileDrawer";

const SidebarMobile = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const history = useHistory();
  return (
    <Flex w="100%">
      <Flex
        justify={"space-between"}
        width={"100%"}
        alignItems={"center"}
        p="5px"
        mt="10px"
      >
        <Image
          src={logo}
          alt="Lamina Clinic logo"
          w={"calc(150px + 9%)"}
          minW={"100px"}
          cursor={"pointer"}
          onClick={() => history.push("/")}
        />

        <Center
          backgroundColor={"yellowish.400"}
          borderRadius={"full"}
          h={"2rem"}
          w={"2rem"}
        >
          <Image alt={"burger menu icon"} src={burger} onClick={onOpen} />
        </Center>
      </Flex>

      <Drawer isOpen={isOpen} onClose={onClose} size={"full"}>
        <DrawerOverlay />
        <DrawerContent bgImage={background} py={"3%"}>
          <DrawerBody>
            {/* <DrawerView onClose={onClose} /> */}
            <SidebarMobileDrawer onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default SidebarMobile;
