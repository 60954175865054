import patient from "assets/icons/patient.svg";
import doctor from "assets/icons/doctor.svg";
import nurse_icon from "assets/icons/nurse_icon.svg";
import customer_care from "assets/icons/customer_care.svg";
import non_care from "assets/icons/non_care.svg";

import {
  Button,
  Flex,
  Heading,
  Text,
  HStack,
  Box,
  VStack,
} from "@chakra-ui/react";
import RoleItem from "../RoleItem";
import React, { FunctionComponent, useEffect, useState } from "react";
import { getFormatedDate } from "features/dashboard/dateHelper";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import { useFetchAllNurses } from "features/superadmin/api/client";
import { useFetchAllDoctors } from "features/doctors/api/client";
import { useUserListFiltered } from "features/admin/api/client";
import { useTranslation } from "react-i18next";

import DayOffCSV from "features/dayoffCSV";
import { Link, useHistory } from "react-router-dom";

interface RoleSelectProps {
  setSelectedRoleType?(newRole: string): void;
}

const RoleSelect: FunctionComponent<RoleSelectProps> = ({
  setSelectedRoleType,
}) => {
  const { t } = useTranslation();

  const [localSelectedRoleType, setLocalSelectedRoleType] =
    useState<string>("patient");

  const { data: profileData } = useFetchProfileInfo();

  const history = useHistory();

  const formattedDate = getFormatedDate("day-month-year");
  const formattedMonth = formattedDate.replace(
    /(?<=, )\w+/,
    (match) => match.charAt(0).toUpperCase() + match.slice(1)
  );

  const { data: nurses } = useFetchAllNurses();
  const { data: doctors } = useFetchAllDoctors();
  const { data: users } = useUserListFiltered();

  const roles = [
    {
      id: "1",
      icon: patient,
      roleCode: "patient",
      role: t("Patients"),
      count: users?.total_users,
    },
    {
      id: "2",
      icon: doctor,
      roleCode: "doctor",
      role: t("Doctors"),
      count: doctors?.doctors.filter((e) => !e.is_deleted).length,
    },
    {
      id: "3",
      icon: nurse_icon,
      roleCode: "nurse",
      role: t("Nurses"),
      count: nurses?.nurses.filter((e) => !e.is_deleted).length,
    },
    // {
    //   id: "4",
    //   icon: customer_care,
    //   roleCode: "customer-care",
    //   role: "Customer-care",
    //   count: "3",
    // },
    // {
    //   id: "5",
    //   icon: non_care,
    //   roleCode: "devs",
    //   role: "Non-care",
    //   count: "2",
    // },
  ];

  useEffect(() => {
    setSelectedRoleType?.(localSelectedRoleType);
  }, [localSelectedRoleType]);

  return (
    <Flex w="100%" pt="48px" justify="flex-start" direction="column">
      <Flex direction="column">
        <Heading
          fontSize={{ base: "28px", md: "30px", lg: "32px" }}
          fontWeight={600}
          letterSpacing={0.8}
        >
          {t("Welcome")}, {profileData?.first_name}
        </Heading>
        <Text
          color="rgba(0, 38, 55, 0.5)"
          fontSize={{ base: "12px", md: "14px", lg: "16px" }}
          fontWeight={400}
        >
          {formattedMonth}
        </Text>
      </Flex>
      <Flex
        py="24px"
        gap="10px"
        direction={{ base: "column", sm: "row" }}
        w="full"
      >
        {roles.map((role) => (
          <React.Fragment key={role.id}>
            <RoleItem
              icon={role.icon}
              role={role.role}
              count={role.count}
              isActive={localSelectedRoleType === role.roleCode}
              onClick={() => {
                setLocalSelectedRoleType(role.roleCode);
              }}
            />
          </React.Fragment>
        ))}
        <VStack align="flex-end " spacing={2} flex={1}>
          <Flex>
            <Button
              variant={"transparent"}
              fontSize={"1rem"}
              color={"blueish.900"}
              px={0}
              mr="2"
              opacity={0.5}
              onClick={() => {
                history.push("/doctor/tapering");
              }}
            >
              {t("View tapering")}
            </Button>

            <DayOffCSV />
          </Flex>

          <Flex gap={3}>
            <Link to={"/services"}>
              <Button
                variant="filledBlue"
                transition="all 0.3s"
                _hover={{
                  transform: "scale(1.02)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                {t("Services")}
              </Button>
            </Link>

            <Link to="/orders">
              <Button
                variant="filledBlue"
                transition="all 0.3s"
                _hover={{
                  transform: "scale(1.02)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                {t("Orders")}
              </Button>
            </Link>
          </Flex>
        </VStack>
      </Flex>
    </Flex>
  );
};

export default RoleSelect;
