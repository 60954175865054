import {
  VStack,
  Flex,
  Heading,
  Button,
  Icon,
  Text,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import { UserInfo } from "features/admin/api/types";
import { useFetchAllDoctors } from "features/doctors/api/client";
import { DoctorProfileResponseType } from "features/doctors/api/types";
import { FunctionComponent } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { TableHeaderItemType } from "ui/components/AdminTable/api/types";
import Table from "ui/components/AdminTable/components/Table";
import DeletePersonnelButton from "../DeletePersonnelButton";
import AddUserModal from "./components/AddStaffModal";
import { useTranslation } from "react-i18next";
import ChangeLog from "../ChangeLog";

import EditPersonelButton from "../EditPersonelButton";

interface DoctorsTableProps {}

const DoctorsTable: FunctionComponent<DoctorsTableProps> = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useFetchAllDoctors();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const columns: TableHeaderItemType[] = [
    {
      name: "name",
      title: t("Name"),
      type: "string",
      columnStyle: {
        minW: "15rem",
      },
      style: {
        minW: "15rem",
      },
    },
    { name: "patients", title: t("Patients"), type: "string" },
    {
      name: "secondary_care",
      title: t("SECONDARY CARE"),
      type: "component",
    },
    {
      name: "leave",
      title: t("LEAVE"),
      type: "component",
    },
    { name: "substitute", title: t("SUBSTITUTE"), type: "string" },
    { name: "email", title: "EMAIL (LOGIN)", type: "string" },
    {
      name: "prescription_code",
      title: t("PRESCRIPTION CODE"),
      type: "string",
      columnStyle: {
        letterSpacing: "0.5rem",
      },
    },
    {
      name: "edit_button",
      title: "",
      type: "component",
    },
    {
      name: "delete_button",
      title: "",
      type: "component",
    },
    {
      name: "changelog_button",
      title: "",
      type: "component",
    },
  ];

  const transformFunction = (doctor: DoctorProfileResponseType) => {
    return {
      name: doctor.first_name + " " + doctor.last_name,
      email: doctor.email,
      prescription_code: doctor.pharmacy_code,
      edit_button: <EditPersonelButton id={doctor.id} />,
      delete_button: <DeletePersonnelButton id={doctor.id} />,
      changelog_button: <ChangeLog id={doctor.id} />,
    };
  };

  return (
    <VStack w={"100%"} alignItems={"stretch"} pb={"5rem"}>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Heading
          textTransform={"uppercase"}
          fontWeight={"600"}
          fontSize={"1.5rem"}
          color={"blueish.900"}
        >
          {t("Doctors")}
        </Heading>

        <Button
          variant={"unstyled"}
          fontSize={"1rem"}
          color={"white"}
          bg={"blueish.500"}
          px={"2rem !important"}
          py={"0.5rem"}
          height={"3rem"}
          onClick={() => {
            onOpen();
          }}
        >
          <Flex alignItems={"center"} gap={"0.5rem"}>
            <HiOutlinePlus fontSize={"1.2rem"} />
            <Text fontSize={"1rem"} mt={"0.15rem"}>
              {t("Add New User")}
            </Text>
          </Flex>
        </Button>
      </Flex>

      <Table
        columns={columns}
        rows={
          data?.doctors.filter((e) => !e.is_deleted).map(transformFunction) ??
          []
        }
        isLoading={isLoading}
      />
      <AddUserModal isOpen={isOpen} onClose={onClose} role={"doctor"} />
    </VStack>
  );
};

export default DoctorsTable;
