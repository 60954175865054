import * as Yup from "yup";

export const AdminLoginSchema = Yup.object().shape({
  email: Yup.string().email("Ogiltig e-postadress").required("Försök igen"),
  password: Yup.string().required("Försök igen"),
});

export const UserFirstLoginSchema = Yup.object().shape({
  email: Yup.string().email("Ogiltig e-postadress").required("Försök igen"),
  password: Yup.string().required("Försök igen"),
  new_password: Yup.string().required("Försök igen"),
  confirm_password: Yup.string()
    .required("Försök igen")
    .oneOf([Yup.ref("new_password"), null], "Lösenorden måste matcha"),
});

export const NewPasswordSchema = Yup.object().shape({
  newpassword: Yup.string().required("Försök igen"),
  confirmpassword: Yup.string()
    .required("Försök igen")
    .oneOf([Yup.ref("newpassword"), null], "Lösenorden måste matcha"),
});

export const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Ogiltig e-postadress").required("Försök igen"),
});

export const SignUpFormStepSchema = Yup.object().shape({
  username: Yup.string().required("Försök igen"),
  pharmacy_code: Yup.string().required("Försök igen"),
});
