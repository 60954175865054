import { APIClient } from "services/api/client";

import useSWRMutation from "swr/mutation";
import {
  CreateUserType,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SetNewPasswordRequest,
  SetNewPasswordResponse,
  SignUpPrescription,
  UserFirstLoginType,
} from "./types";
import { useHistory } from "react-router-dom";
import { useTokenStore } from "services/state/tokenStore";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import { string } from "yup/lib/locale";

export const useCreateUser = () => {
  return useSWRMutation<any, CreateUserType>(
    "user/",
    (url: string, { arg }: { arg: CreateUserType }) => {
      return APIClient.post(url, arg);
    },
    {
      onSuccess: () => {},
    }
  );
};

export const useUserFirstLogin = () => {
  const { setAccess, setRefresh } = useTokenStore();

  return useSWRMutation<any, any>(
    "user/create/",
    (url: string, { arg }: { arg: UserFirstLoginType }) => {
      return APIClient.post(url, arg);
    },
    {
      onSuccess: (response) => {
        setAccess(response.access ?? "");
        setRefresh(response.refresh ?? "");
      },
    }
  );
};

export const useSignUpPrescription = () => {
  const history = useHistory();

  const { data } = useFetchProfileInfo();

  return useSWRMutation<any, SignUpPrescription>(
    "/profile/" + data?.id + "/",
    (url: string, { arg }: { arg: { pharmacy_code: string } }) => {
      return APIClient.patch(url, {
        pharmacy_code: arg.pharmacy_code,
      });
    }
  );
};

export const useResetPassword = () => {
  return useSWRMutation<any, ResetPasswordRequest>(
    "user/password/reset/",
    (url: string, { arg }: { arg: { email: string } }) => {
      return APIClient.post<any, ResetPasswordResponse>(url, {
        email: arg.email,
      });
    }
  );
};

export const useSetNewPassword = (id: string, token: string) => {
  return useSWRMutation<any, SetNewPasswordResponse>(
    "user/password/reset/confirm/" + token + "/" + id + "/",
    (url: string, { arg }: { arg: SetNewPasswordRequest }) => {
      return APIClient.post<SetNewPasswordRequest, SetNewPasswordResponse>(
        url,
        {
          password: arg.password,
          confirm_password: arg.confirm_password,
        }
      );
    }
  );
};
