import {
  Button,
  Center,
  Flex,
  Heading,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";

const WaitList = () => {
  return (
    <Flex
      w="full"
      bg="#FFF"
      direction="column"
      p="2.5rem"
      borderRadius=".75rem"
      h={{
        base: "calc(60vh - 2rem)",
        sm: "calc(70vh - 2rem)",
        md: "calc(75vh - 2rem)",
      }}
      align="center"
      justify="center"
    >
      <Center
        flexDirection="column"
        gap="1rem"
        py="3rem"
        px={{ base: "1rem", md: "10rem" }}
      >
        <Heading
          fontSize="1.75rem"
          color="rgba(88, 154, 175, 1)"
          textAlign="center"
          fontWeight={500}
          letterSpacing={0.9}
        >
          Är du intresserad av att trappa ned med Lamina?
        </Heading>
        <Text
          fontSize="1.25rem"
          color="#rgba(0, 38, 55, 0.1)"
          textAlign="center"
          letterSpacing={0.9}
          fontFamily="secondary"
        >
          Vi har just nu väntelista. Klicka på knappen för att få ett meddelande
          när det finns lediga platser igen.
        </Text>
        <Button
          variant="outline"
          mt="1rem"
          w="240px !important"
          p="1rem"
          color="blueish.500"
          _hover={{ bgColor: "blueish.500", color: "white" }}
          borderColor="blueish.500"
          fontWeight={500}
        >
          <ChakraLink
            _hover={{ textDecorationLine: "none" }}
            href="https://form.typeform.com/to/bkJc66lo"
          >
            Få en påminnelse
          </ChakraLink>
        </Button>
      </Center>
    </Flex>
  );
};

export default WaitList;
