import useSWRMutation from "swr/mutation";
import { GetTokenParams, GetTokenResposeType } from "./types";
import { APIClient } from "services/api/client";

export const useGetProdToken = () => {
  return useSWRMutation<GetTokenResposeType, any, string>(
    "/zegocloud/token ",
    (url, { arg }: { arg: GetTokenParams }) => {
      return APIClient.post(url, {
        room: arg.roomId,
        user_id: arg.user_id,
      });
    }
  );
};
