import useSWR from "swr";
import { APIClient } from "services/api/client";
import useSWRMutation from "swr/mutation";
import { CalendlyURL, EventRequestType } from "./types";

export const useBookACall = () => {
  return useSWRMutation<any>(
    `calendly-data`,
    (url: string, { arg }: { arg: { payload: EventRequestType } }) =>
      APIClient.post<any, EventRequestType>(url, arg.payload)
  );
};

export const useGetCalendlyCallURL = () => {
  return useSWR<CalendlyURL, Error>(
    `/calendly/create-call/initial`,
    (url: string) => APIClient.get(url)
  );
};
