import {
  Button,
  Text,
  HStack,
  Heading,
  VStack,
  Textarea,
  Flex,
  Box,
  useOutsideClick,
} from "@chakra-ui/react";
import { useCreateNote } from "features/admin/api/client";
import { FunctionComponent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateJournalRecord } from "../../api/client";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import { toast } from "react-toastify";
import ResizeTextarea from "react-textarea-autosize";

type AddRecordDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  userID: string;
};

const typeOptions = [
  {
    label: "Nurse meeting",
    value: "NURSE_MEETING",
  },
  {
    label: "Doctor meeting",
    value: "DOCTOR_MEETING",
  },
  {
    label: "First prescription",
    value: "INITIAL_PRESCRIPTION",
  },
  {
    label: "Renewal prescription",
    value: "RENEWAL_PRESCRIPTION",
  },
  {
    label: "Other_type", //<---translation key because "Other" key already exists
    value: "OTHER",
  },
];

const AddRecordDrawer: FunctionComponent<AddRecordDrawerProps> = ({
  isOpen,
  onClose,
  userID,
}) => {
  const { t } = useTranslation();
  const [noteText, setNoteText] = useState<string>("");

  const { data: profileData } = useFetchProfileInfo();

  const [selectedType, setSelectedType] = useState<string>(
    profileData?.role === "nurse" ? "NURSE_MEETING" : "DOCTOR_MEETING"
  );

  const ref = useRef<HTMLDivElement>(null);

  const { trigger: createNoteRequest, isMutating: isSendingANote } =
    useCreateJournalRecord(userID, profileData?.id!);

  useOutsideClick({
    ref: ref,
    handler: onClose,
  });

  return isOpen ? (
    <Box
      position="fixed"
      bottom="0"
      right="0"
      width="30rem"
      bg="white"
      boxShadow="lg"
      border="1px solid rgba(88, 154, 175, 1)"
      borderRadius=".75rem .75rem 0 0"
      zIndex={101}
      ref={ref}
    >
      <HStack
        bg="white"
        p="1rem"
        borderTopLeftRadius={".75rem"}
        border="1px solid rgba(88, 154, 175, 0.2)"
        cursor="pointer"
        onClick={onClose}
      >
        <Heading
          color="#589AAF"
          textTransform="uppercase"
          fontSize="1.25rem"
          fontWeight={600}
          whiteSpace="nowrap"
        >
          {t("See Journal Notes")}
        </Heading>
      </HStack>
      <Box maxHeight="50vh" overflow="auto">
        <VStack align="flex-start" p="2rem" pb={"0rem"}>
          <Flex maxW={"100%"} flexWrap={"wrap"} gap={"0.5rem"}>
            {typeOptions.map((type) => {
              return (
                <Button
                  variant={selectedType === type.value ? "filledBlue" : "edit"}
                  padding={"0"}
                  px={"0.5rem !important"}
                  minWidth={0}
                  onClick={() => {
                    setSelectedType(type.value);
                  }}
                  opacity={"0.7"}
                >
                  {t(type.label)}
                </Button>
              );
            })}
          </Flex>
          <Text
            color="rgba(0, 38, 55, 0.5)"
            textTransform="uppercase"
            fontSize=".75rem"
            fontWeight={500}
          >
            {t("Write a record")}
          </Text>

          <Textarea
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
            color="rgba(0, 38, 55, 0.8)"
            bg="white"
            borderRadius=".5rem"
            _focusVisible={{ borderColor: "rgba(88, 154, 175, 0.5)" }}
            _placeholder={{
              color: "rgba(0, 38, 55, 0.5)",
              fontSize: "1rem",
            }}
            placeholder={t("Add a record to the journal") ?? ""}
            size="sm"
            maxH={"15rem"}
            as={ResizeTextarea}
          />

          <Flex w="full" gap=".75rem" py="2rem">
            <Button
              variant="edit"
              px="3rem"
              bg="transparent"
              border="1px solid black"
              color="black"
              onClick={() => {
                onClose();
                setNoteText("");
              }}
              textTransform="capitalize"
            >
              {t("cancel")}
            </Button>
            <Button
              variant="filledBlue"
              onClick={() => {
                createNoteRequest(
                  {
                    comment: noteText,
                    tag: selectedType,
                  },
                  {
                    onSuccess: () => {
                      setNoteText("");
                      toast.success("Note successfully created");
                    },
                  }
                );
              }}
              isLoading={isSendingANote}
              disabled={!noteText.trim()}
            >
              {t("Add Record")}
            </Button>
          </Flex>
        </VStack>
      </Box>
    </Box>
  ) : null;
};

export default AddRecordDrawer;
