import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { inputAnatomy } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

export const baseStyle = definePartsStyle({
  field: {
    backgroundColor: "rgba(88, 154, 175, 0.1) !important",
    fontFamily: "Circe",
    boxShadow: "none !important",
    border: "none !important",
    borderWidth: "0px",
    p: "1.25rem 1.25rem 1.25rem 1.25rem !important",
    minHeight: { base: "3rem", lg: "4rem" },

    _placeholder: {
      opacity: 0.5,
      fontSize: { base: "1rem", lg: "md" },
      fontWeight: 500,
    },

    _invalid: {
      bg: "rgba(213, 115, 138, 0.1) !important",
      border: "1px solid rgba(213, 115, 138, 0.5) !important",
    },
  },
});

export const Input = defineMultiStyleConfig({ baseStyle });
