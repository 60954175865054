import SvgIconConstituentValues from "./types/svgPropsType";

const FilterIconYellow = (props: SvgIconConstituentValues) => (
  <svg
    width={18}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 22H11C11.2652 22 11.5196 21.8946 11.7071 21.7071C11.8946 21.5196 12 21.2652 12 21V9.333L17.8 1.6C17.9114 1.45143 17.9793 1.27477 17.996 1.08981C18.0126 0.904844 17.9775 0.718892 17.8944 0.552786C17.8114 0.386681 17.6837 0.246984 17.5257 0.149349C17.3678 0.0517147 17.1857 0 17 0H1C0.814289 0 0.632245 0.0517147 0.474269 0.149349C0.316293 0.246984 0.188626 0.386681 0.105573 0.552786C0.0225203 0.718892 -0.0126368 0.904844 0.00404117 1.08981C0.0207191 1.27477 0.0885733 1.45143 0.2 1.6L6 9.333V21C6 21.2652 6.10536 21.5196 6.29289 21.7071C6.48043 21.8946 6.73478 22 7 22ZM3 2H15L10.2 8.4C10.0702 8.5731 10 8.78363 10 9V20H8V9C8 8.78363 7.92982 8.5731 7.8 8.4L3 2Z"
      fill="#EEE8A9"
    />
  </svg>
);

export default FilterIconYellow;
