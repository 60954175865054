import { Divider, VStack } from "@chakra-ui/react";
import AdminNavbar from "features/admin/components/adminNavbar";
import React, { FunctionComponent } from "react";

interface AdminLayoutProps {
  children?: React.ReactNode[] | React.ReactNode;
  fullWidth?: boolean;
}

const AdminLayout: FunctionComponent<AdminLayoutProps> = ({
  children,
  fullWidth,
}) => {
  return (
    <VStack
      alignItems={"center"}
      justifyContent={"flex-start"}
      w={"100% !important"}
      minH={"100vh !important"}
      pt={"2rem"}
      position={"relative"}
      bg="rgba(155, 194, 207, 1)"
    >
      <AdminNavbar />
      <Divider borderColor="blueish.500" />
      <VStack
        width={"100%"}
        justify="flex-start"
        align="center"
        maxWidth={{
          base: "100%",
          sm: "1250px",
          lg: "1680px",
        }}
        px={{ base: "3%", md: "1.5%", sm: "5%" }}
        flexGrow={"1"}
      >
        <VStack
          width={fullWidth ? "100%" : { base: "98%", sm: "100%" }}
          flexGrow={"1"}
          mb={"5vh"}
        >
          {children}
        </VStack>
      </VStack>
    </VStack>
  );
};

export default AdminLayout;
