import { Center, Circle, Flex, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { InputType } from "services/types/types";

interface MoodFilterProps extends InputType<number[]> {}

const filterOptions = [
  { id: 1, color: "#944366" },
  { id: 2, color: "#8B5687" },
  { id: 3, color: "#796A9F" },
  { id: 4, color: "#647CAD" },
  { id: 5, color: "#568CB2" },
  { id: 6, color: "#589AAF" },
  { id: 7, color: "#43B3BA" },
];

const MoodFilter: React.FC<MoodFilterProps> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const [selectedMoods, setSelectedMoods] = useState<number[]>([]);

  const handleMoodClick = (moodId: number) => {
    if (selectedMoods.includes(moodId)) {
      setSelectedMoods(selectedMoods.filter((e) => e !== moodId));
    } else {
      setSelectedMoods([...selectedMoods, moodId]);
    }
  };

  useEffect(() => {
    onChange(selectedMoods);
  }, [selectedMoods]);

  //ISSUEPRONE
  useEffect(() => {
    setSelectedMoods(value);
  }, [value]);

  return (
    <Flex direction="column" gap="12px" w="auto">
      <Text
        fontSize="14px"
        fontWeight={600}
        color="rgba(255, 255, 255, 1)"
        lineHeight="20px"
      >
        {t("MOOD ( 1-TERRIBLE, 7-GOOD )")}
      </Text>
      <Flex gap={0.5} cursor="pointer" w="100%">
        {filterOptions.map((option) => (
          <Flex
            key={option.id}
            bg={
              selectedMoods.includes(option.id)
                ? "rgba(255, 255, 255, 1)"
                : "rgba(255, 255, 255, 0.5)"
            }
            p="10px"
            borderRadius="8px"
            onClick={() => handleMoodClick(option.id)}
            cursor={"pointer"}
            transition={"all 0.2s"}
          >
            <Circle size="32px" bg={option.color}>
              <Center
                color="white"
                fontSize="1rem"
                pt={"0.125rem"}
                fontWeight={700}
              >
                {option.id}
              </Center>
            </Circle>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default MoodFilter;
