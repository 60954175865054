import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { SignatureStore } from "./types";

export const usePrescriptionToPharmacyStore = create(
  persist<SignatureStore>(
    (set) => ({
      position: undefined,
      setPosition: (newPosition) => set(() => ({ position: newPosition })),
    }),
    {
      name: "prescription-to-pharmacy-store",
    }
  )
);
