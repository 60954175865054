import { Flex, Text } from "@chakra-ui/react";

import { statusColor, statusTextColor } from "features/admin/helper/columns";
import { useTranslation } from "react-i18next";

interface AdminStatusProps {
  status: string;
  isDoctorChecked?: boolean;
}

const AdminStatus: React.FC<AdminStatusProps> = ({
  status,
  isDoctorChecked,
}) => {
  const { t } = useTranslation();
  const trueStatus =
    !isDoctorChecked && status === "prescription_completed"
      ? "triage_completed"
      : status;

  function getStatusColor(status: string) {
    const statusObject = statusColor.find(
      (obj: { value: string }) => obj.value === status
    );
    return statusObject ? statusObject.color : "#589AAF34";
  }

  function getStatusTextColor(status: string) {
    const statusObject = statusTextColor.find(
      (obj: { value: string }) => obj.value === status
    );
    return statusObject ? statusObject.color : "#589AAF";
  }
  return (
    <Flex
      style={{
        backgroundColor: getStatusColor(trueStatus),
      }}
      w="max-content"
      p="4px 12px"
      borderRadius="8px"
    >
      <Text
        fontSize={"1rem"}
        p="0"
        style={{ color: getStatusTextColor(trueStatus) }}
        fontWeight={700}
        w="100%"
      >
        {t(
          trueStatus
            .replace(/_/, " ")
            .replaceAll("_", " ")
            .split(" ")
            .map((word) => {
              return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(" ")
        )}
      </Text>
    </Flex>
  );
};

export default AdminStatus;
