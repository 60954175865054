import {
  useFetchMedicalHistory,
  useUserChart,
} from "features/admin/api/client";
import { useFetchAllDoctors } from "features/doctors/api/client";
import { useFetchPrescriptionHelper } from "features/prescriptionHelper/api/client";
import { useAdminUserInfo } from "features/userInfo/api/client";
import { APIClient } from "services/api/client";

import useSWR, { mutate, SWRResponse, useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

import {
  AssignPersonelRequestType,
  AvailableStabilisingDosesResponseType,
  BusyWorkingHours,
  ChangeLog,
  CreateNewUserRequestType,
  MedicationTypes,
  NursesListResponseType,
  OrdersResponse,
  PDFDocument,
  PdfFormData,
  PlanResponseType,
  SendInvoicePayload,
  Service,
  ServicesResponse,
  SetOrderDiscount,
  StabilisePatientRequestType,
  TreatmentDayItem,
  WorkingDaysObject,
} from "./types";
import { PrimitivePaginationType } from "features/admin/api/types";
import { useMemo } from "react";
import { useFetchPatientJournal } from "features/staffCommons/components/JournalRecords/api/client";
import { url } from "inspector";

export const useFetchAllNurses = () => {
  return useSWR<NursesListResponseType>(`admin/nurses`, (url: string) =>
    APIClient.get(url)
  );
};

export const useAssignPersonnel = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation<any, AssignPersonelRequestType>(
    "user/care-personal",
    (url: string, { arg }: { arg: AssignPersonelRequestType }) => {
      return APIClient.post(url, arg);
    },
    {
      onSuccess: () => {
        mutate(
          (key: any) => {
            if (Array.isArray(key) && key[0] === "admin/users/") {
              return true;
            }
          },
          undefined,
          {
            revalidate: true,
          }
        );
      },
    }
  );
};

export const useCreateNewStaffUser = () => {
  const { mutate: mutateDoctors } = useFetchAllDoctors();
  const { mutate: mutateNurses } = useFetchAllNurses();

  return useSWRMutation<any, CreateNewUserRequestType>(
    "user/",
    (url: string, { arg }: { arg: CreateNewUserRequestType }) => {
      return APIClient.post(url, arg);
    },
    {
      onSuccess: () => {
        mutateDoctors();
        mutateNurses();
      },
    }
  );
};

export const useFetchPrescriptionToPharmacyPdfForm = (id: string) => {
  return useSWR<PdfFormData>("admin/prescription/?user_id=" + id, (url) => {
    return APIClient.get(url);
  });
};

export const useGeneratePdfFromFrom = () => {
  return useSWRMutation(
    "admin/prescription/",
    (url, { arg }: { arg: PdfFormData }) => {
      return APIClient.post(url, arg, {
        responseType: "blob",
      });
    }
  );
};

export const useDeletePersonnelById = (id: string) => {
  const { mutate: mutateNurses } = useFetchAllNurses();
  const { mutate: mutateDoctors } = useFetchAllDoctors();

  return useSWRMutation(
    `profile/${id}/`,
    (url: string) => {
      return APIClient.delete(url, {
        data: {
          user: id,
        },
      });
    },
    {
      onSuccess: () => {
        mutateDoctors();
        mutateNurses();
      },
      revalidate: false,
    }
  );
};

export const useFetchUserPDFView = (id: string, fileType: string) => {
  return useSWR<PDFDocument>(`admin/user/${id}/files/${fileType}`, (url) => {
    return APIClient.get(url);
  });
};

export const useFetchAvailableStabilizingDoses = (userId: string) => {
  return useSWR<AvailableStabilisingDosesResponseType, Error>(
    `admin/user/${userId}/stabilization/available-doses`,
    (url) => {
      return APIClient.get(url);
    }
  );
};

export const useStabiliseUserRequest = (userId: string) => {
  const { mutate } = useFetchPrescriptionHelper(userId);
  const { mutate: refetchChart } = useUserChart(userId);
  const { mutate: refetchAdminInfo } = useAdminUserInfo(userId ?? "");
  const { mutate: refetchMedicalHistory } = useFetchMedicalHistory(userId);
  const { mutate: mutateJournal } = useFetchPatientJournal(userId);

  return useSWRMutation(
    `admin/users/${userId}/stabilization/start`,
    (url: string, { arg }: { arg: StabilisePatientRequestType }) => {
      return APIClient.post(url, arg).then(() => {
        mutate();
        refetchChart();
        refetchAdminInfo();
        refetchMedicalHistory();
        mutateJournal();
      });
    }
  );
};

export const useCalculateTreatmentPlan = (
  //medicine_type: string = "",
  medication_id: string = "",
  start_dose: number = 0,
  end_dose: number = 0,
  percent: number = 0,
  duration: number
) => {
  return useSWRMutation<any, PlanResponseType>(
    `admin/tapering-planner?medication_id=${medication_id}&start_dose=${start_dose}&end_dose=${end_dose}&percent=${percent}&duration=${duration}`,
    (url: string, { arg }: { arg?: { pharmacy: string } }) => {
      return APIClient.get(
        url + (arg?.pharmacy ? "&factory=" + arg.pharmacy : "")
      );
    }
  );
};

export const useSaveTreatment = (userId: string) => {
  const { mutate } = useAdminUserInfo(userId);
  const { mutate: mutateHelper } = useFetchPrescriptionHelper(userId);
  const { mutate: mutateChart } = useUserChart(userId);
  const { mutate: mutateJournal } = useFetchPatientJournal(userId);

  return useSWRMutation(
    `admin/tapering/${userId}/`,
    (
      url: string,
      {
        arg,
      }: {
        arg: {
          tapering: Omit<TreatmentDayItem, "medication">[];
          tapering_pace: number;
          medication: string;
          period_duration: number;
        };
      }
    ) => {
      return APIClient.put(url, arg).then(() => {
        mutate();
        mutateHelper();
        mutateChart();
        mutateJournal();
      });
    }
  );
};

export const useGetChangeLog = (personelId: string, page: number = 1) => {
  return useSWR<PrimitivePaginationType<ChangeLog>>(
    `personal-user-action/?doctor=${personelId}&page=${page}&size=10`,
    (url: string) => APIClient.get(url)
  );
};

export const useGetMedicationsType = () => {
  const swrData = useSWR<MedicationTypes[]>(`/medication/`, (url: string) =>
    APIClient.get(url)
  );

  const sortedMedicationType = swrData.data
    ? [...swrData.data].sort((a, b) => a.name.localeCompare(b.name))
    : [];

  return { ...swrData, data: sortedMedicationType };
};

export const useGetMedicationById = (id: string) => {
  const { data } = useGetMedicationsType();

  return useMemo<MedicationTypes | undefined>(() => {
    return data.find((e) => e.id === id);
  }, [data, id]);
};

export const usePersonelAvailabilityHours = (personelId: string) => {
  return useSWR<WorkingDaysObject, Error>(
    `get/user/availability/${personelId}`,
    (url: string) => APIClient.get(url)
  );
};

export const usePerrsonelBusyHours = (
  personelId: string,
  startOfWeek: string,
  endOfWeek: string
) => {
  return useSWR<BusyWorkingHours, Error>(
    `get/user/busy/${personelId}/${startOfWeek}/${endOfWeek}`,
    (url: string) => APIClient.get(url)
  );
};

export const useCancelCalendarMeeting = (meetingId: string) => {
  return useSWRMutation<any, Error>(
    `calendly/cancel-url/${meetingId}/`,
    (url: string) => APIClient.get(url),
    {
      onSuccess: () => {
        mutate(
          "get/user/busy/",
          (data: any) => {
            return data.filter((meeting: any) => meeting.id !== meetingId);
          },
          false
        );
      },
    }
  );
};

export const useResheduleCalendarMeeting = (meetingId: string) => {
  return useSWRMutation<any, Error>(
    `calendly/reschedule-url/${meetingId}/`,
    (url: string) => APIClient.get(url),
    {
      onSuccess: () => {
        mutate(
          "get/user/busy/",
          (data: any) => {
            return data;
          },
          false
        );
      },
    }
  );
};

export const useGetAllServices = () => {
  return useSWR<Service[], Error>(`/services/`, (url: string) =>
    APIClient.get(url)
  );
};

export const useChangeServicePrice = (serviceID: string) => {
  const { mutate: mutatePrice } = useGetAllServices();
  return useSWRMutation(
    `/services/${serviceID}/price/`,
    (
      url: string,
      {
        arg,
      }: {
        arg: any;
      }
    ) => {
      return APIClient.patch(url, arg).then(() => {
        mutatePrice();
      });
    }
  );
};

export const useGetMeetingServicesByPersonel = (staffID: string) => {
  return useSWR<ServicesResponse, Error>(
    `/services/meeting/?user=${staffID}`,
    (url: string) => APIClient.get(url)
  );
};

export const useGetAllOrders = (
  page: number,
  sortField: string = "",
  sortOrder: "asc" | "desc" = "asc"
): SWRResponse<OrdersResponse, Error> => {
  const params: Record<string, string> = { page: page.toString() };

  if (sortField) {
    params["sortField"] = sortField;
    params["sortOrder"] = sortOrder;
  }

  const urlSearchParams = new URLSearchParams(params);
  urlSearchParams.sort();
  const sortedParams = urlSearchParams.toString();

  const url = `admin/orders/?${sortedParams}`;

  return useSWR<OrdersResponse, Error>(url, (url: string) =>
    APIClient.get(url)
  );
};

export const useSetOrderDiscount = (
  orderId: string,
  page: number,
  sortField: string = "",
  sortOrder: "asc" | "desc" = "asc"
) => {
  const { mutate } = useGetAllOrders(page, sortField, sortOrder);

  return useSWRMutation(
    `admin/orders/${orderId}/apply-discount/`,
    (url: string, { arg }: { arg: SetOrderDiscount }) => {
      return APIClient.patch(url, arg);
    },
    {
      onSuccess: () => {
        mutate();
      },
    }
  );
};

export const useSendOrderInvoice = (
  page: number,
  sortField: string = "",
  sortOrder: "asc" | "desc" = "asc"
) => {
  const { mutate } = useGetAllOrders(page, sortField, sortOrder);

  return useSWRMutation(
    `/stripe/invoice/`,
    (url: string, { arg }: { arg: SendInvoicePayload }) => {
      return APIClient.post(url, arg);
    },
    {
      onSuccess: () => {
        mutate();
      },
    }
  );
};
