import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useURLParametrize = (parametrizedObject?: any) => {
  const [parametersAppendix, setParametersAppendix] = useState<string>("");

  useEffect(() => {
    if (parametrizedObject) {
      const url = new URL(window.location.href);

      const paramsString = new URLSearchParams(parametrizedObject).toString();

      setParametersAppendix("?" + paramsString);
    }
  }, [parametrizedObject]);

  return { parametersAppendix };
};
