export const convertDateToISODate = (date: Date, compensate = true) => {
  const tzoffset = compensate ? date.getTimezoneOffset() * 60000 : 0; //offset in milliseconds
  return new Date(date.getTime() - tzoffset).toISOString();
};

const isWeekend = (date: Date) => {
  const weekday = date.toLocaleDateString("en-GB", { weekday: "short" });
  return weekday === "Sat" || weekday === "Sun";
};

export const calcWeekends = (startDate: Date, endDate: Date) => {
  if (startDate.getTime() > endDate.getTime()) return [];
  let date = new Date(startDate);
  let dates = [];

  while (date.getTime() < endDate.getTime()) {
    if (isWeekend(date)) dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

export const millisecondsToTimeLeft = (milliseconds: number): string => {
  // Convert milliseconds to seconds
  let seconds: number = Math.floor(milliseconds / 1000);

  // Calculate the remaining time components
  let days: number = Math.floor(seconds / (24 * 60 * 60));
  seconds -= days * 24 * 60 * 60;
  let hours: number = Math.floor(seconds / (60 * 60));
  seconds -= hours * 60 * 60;
  let minutes: number = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  // Construct the time left string
  let timeLeft: string = "";
  if (days > 0) {
    timeLeft += days + "d ";
  }
  if (hours > 0) {
    timeLeft += hours + "h ";
  }
  if (minutes > 0) {
    timeLeft += minutes + "m ";
  }
  timeLeft += seconds + "s";

  return timeLeft;
};

export function formatDateTime(dateString: string, locale: any) {
  const date = new Date(Date.parse(dateString));
  const formattedDate = date.toLocaleDateString(locale ?? "en-GB", {
    day: "numeric",
    month: "long",
  });
  const formattedTime = date.toLocaleTimeString(locale ?? "en-GB", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
    hourCycle: "h23",
  });
  return `${formattedDate} ${formattedTime}`;
}
