import { Box, Button, Center, Flex, Input, VStack } from "@chakra-ui/react";
import { useFetchAddressById } from "features/addressPopup/api/client";
import { useDashboardInfo } from "features/dashboard/api/client";
import {
  useCompleteOrder,
  useFetchKlarnaInitToken,
  useFetchOrderAuthObject,
} from "features/klarnaPayments/api/client";
import useScript from "features/klarnaPayments/hooks/useScript";
import { useFetchPaymentID } from "features/netsEasyPayments/api/client";
import { FunctionComponent, useEffect, useState } from "react";
import countryList from "react-select-country-list";

const TestPaymentPage: FunctionComponent = () => {
  const [paymentId, setPaymentId] = useState<string>("");
  const { isScriptMounted: isNetesEasySDKMounted } = useScript(
    "https://test.checkout.dibspayment.eu/v1/checkout.js?v=1"
  );

  const reinitialize = () => {
    if (!isNetesEasySDKMounted) {
      return;
    }

    if (paymentId) {
      const checkoutOptions = {
        checkoutKey: "test-checkout-key-6fca333643a1448f98e77c4d33cc7856", // Replace!
        paymentId: paymentId,
        containerId: "checkout-container-div",
      };
      try {
        const Dibs = (window as any).Dibs;
        const checkout = new Dibs.Checkout(checkoutOptions);
        checkout.on("payment-completed", function (response: any) {
          alert("Payment completed");
        });
      } catch (error) {
        alert("Error caught at request");
        console.log(error);
      }
    } else {
      alert("ERROR: no paymentID provided");
      console.log("Expected a paymentId"); // No paymentId provided,
    }
  };

  useEffect(() => {}, [isNetesEasySDKMounted]);

  return (
    <Center
      bg={"white"}
      width={"100%"}
      mt={"0 !important"}
      borderRadius={"lg"}
      height={"80vh !important"}
      flexDirection={"column"}
      p={"2vh"}
      position={"relative"}
      //id={"klarna-payments-container"}
    >
      <Flex>
        <Input
          value={paymentId}
          onChange={(e) => setPaymentId(e.target.value)}
        />
        <Button onClick={reinitialize}>Reinitialize checkout</Button>
      </Flex>
      <Box h={"100%"} overflowY={"scroll"} w={"100%"}>
        <div
          id="checkout-container-div"
          style={{ width: "100% !important" }}
        ></div>
      </Box>
    </Center>
  );
};

export default TestPaymentPage;
