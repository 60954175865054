import { Button, Divider, Flex, IconButton, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

interface PaginationProps {
  currentPage: number;
  pageCount: number;
  onPageChange(page: number): void;
  startIndex?: number;
}

const Pagination: FunctionComponent<PaginationProps> = ({
  currentPage,
  pageCount,
  onPageChange,
}) => {
  const { t } = useTranslation();
  return (
    <Flex alignItems={"center"} gap={"0.25rem"}>
      <IconButton
        minWidth={"none"}
        aria-label={""}
        icon={<BsChevronLeft />}
        px={"0.5rem !important"}
        py={"0.5rem !important"}
        bg={"rgba(0, 0, 0, 0.05)"}
        border={"none"}
        isDisabled={currentPage === 1}
        onClick={() => {
          onPageChange(currentPage - 1);
        }}
      />

      {pageCount > 1 && (
        <Text
          px={"0.75rem !important"}
          py={"0.1rem !important"}
          fontSize={"1rem"}
          color="black"
          fontWeight={600}
        >
          {currentPage} {t("of")} {pageCount}
        </Text>
      )}

      <IconButton
        minWidth={"none"}
        aria-label={""}
        icon={<BsChevronRight />}
        px={"0.5rem !important"}
        py={"0.5rem !important"}
        bg={"rgba(0, 0, 0, 0.05)"}
        border={"none"}
        isDisabled={currentPage === pageCount}
        onClick={() => {
          onPageChange(currentPage + 1);
        }}
      />
    </Flex>
  );
};

export default Pagination;
