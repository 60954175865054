import { VStack } from "@chakra-ui/react";

import React, { FunctionComponent } from "react";
import TestPageNavbar from "../Navbar/TestPageNavbar/TestPageNavbar";

interface TestPageLayoutProps {
  children?: React.ReactNode[] | React.ReactNode;
  fullWidth?: boolean;
}

const TestPageLayout: FunctionComponent<TestPageLayoutProps> = ({
  children,
  fullWidth,
}) => {
  return (
    <VStack
      alignItems={"center"}
      justifyContent={"flex-start"}
      w={"100% !important"}
      minH={"100vh !important"}
      pt={"2rem"}
      position={"relative"}
      bg="rgba(155, 194, 207, 1)"
    >
      <VStack
        width={"100%"}
        justify="flex-start"
        align="center"
        maxWidth={{
          base: "100%",
          sm: "1250px",
          lg: "1680px",
        }}
        px={{ base: "3%", sm: "5%" }}
        flexGrow={"1"}
      >
        <TestPageNavbar />
        <VStack
          width={fullWidth ? "100%" : { base: "98%", sm: "100%" }}
          flexGrow={"1"}
          mb={"5vh"}
        >
          {children}
        </VStack>
      </VStack>
    </VStack>
  );
};

export default TestPageLayout;
