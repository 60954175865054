export const orderStatusColors: { [key: string]: string } = {
  UNPAID: "gray.100",
  PAID: "pink.100",
  PAYMENT_FAILED: "red.100",
  INVOICED: "purple.100",
};

export const orderStatusTextColor: { [key: string]: string } = {
  UNPAID: "gray.500",
  PAID: "pink.400",
  PAYMENT_FAILED: "red.400",
  INVOICED: "purple.400",
};

export const orderStatusNames: { [key: string]: string } = {
  UNPAID: "UNPAID",
  PAID: "PAID",
  PAYMENT_FAILED: "PAYMENT_FAILED",
  INVOICED: "INVOICED",
};

export const formatDiscount = (amount: string) => {
  const numericAmount = parseFloat(amount);
  return isNaN(numericAmount)
    ? "No discount"
    : Math.trunc(numericAmount).toString();
};
