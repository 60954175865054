import { Box, Button, Flex, Heading, Input, Text } from "@chakra-ui/react";
import {
  useCreateDefaultAddress,
  useFetchAddressById,
  useFetchDefaultAddress,
  useGetDefaultAddressById,
  usePostNewAddress,
  useUpdateDefaultAddress,
} from "features/addressPopup/api/client";
import { useAdminUserInfo } from "features/userInfo/api/client";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import {
  useFetchProfileInfo,
  usePatchAdminUserProfile,
} from "features/dashboard/api/client";
import { invokeBasicInfoModal } from "features/genericModal";
import { toast } from "react-toastify";

interface AdminUserDetailsProps {}

const AdminUserDetails: React.FC<AdminUserDetailsProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: userData } = useAdminUserInfo(id);

  const { data: profileData } = useFetchProfileInfo();

  const { t } = useTranslation();

  const { data: addressData } = useGetDefaultAddressById(
    userData?.address?.id ?? ""
  );

  const { trigger: updateAddress, isMutating: isEditAddressLoading } =
    useUpdateDefaultAddress(userData?.address?.id ?? "");

  const { trigger: createAddress, isMutating: isCreatingAddressLoading } =
    useCreateDefaultAddress();

  const { trigger: updateUserData } = usePatchAdminUserProfile(id);

  const isSuperAdmin = profileData?.role === "superadmin";
  const isLoading = isEditAddressLoading || isCreatingAddressLoading;

  const formik = useFormik<any>({
    initialValues: {
      first_name: userData?.first_name ?? "",
      last_name: userData?.last_name ?? "",
      phone: addressData?.phone,
      email: userData?.email,
      username: userData?.username,
      address: addressData?.address,
      zip_code: addressData?.zip_code,
      city: addressData?.city,
      country: addressData?.country,
    },
    onSubmit: async (value) => {
      if (addressData) {
        await updateAddress({ address: value });
        toast.info("Address successfully updated");
      } else {
        const newAddressId = await createAddress({
          address: { ...value, user: id },
        });

        updateUserData({
          address: newAddressId.id,
        });
        toast.info("Address successfully updated");
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      first_name: addressData?.first_name ?? userData?.first_name ?? "",
      last_name: addressData?.last_name ?? userData?.last_name ?? "",
      phone: addressData?.phone,
      email: addressData?.email ?? userData?.email,
      username: userData?.username,
      address: addressData?.address,
      zip_code: addressData?.zip_code,
      city: addressData?.city,
      country: addressData?.country,
    });
  }, [addressData, userData, formik.status]);

  const isChanged = useMemo(() => {
    return (
      JSON.stringify(formik.values) ===
      JSON.stringify({
        first_name: addressData?.first_name ?? userData?.first_name ?? "",
        last_name: addressData?.last_name ?? userData?.last_name ?? "",
        phone: addressData?.phone,
        email: addressData?.email ?? userData?.email,
        username: userData?.username,
        address: addressData?.address,
        zip_code: addressData?.zip_code,
        city: addressData?.city,
        country: addressData?.country,
      })
    );
  }, [addressData, userData, formik.values]);

  return (
    <Flex
      w="100%"
      p="44px 32px"
      borderRadius="12px"
      bg="white"
      direction="column"
      gap="2rem"
    >
      <Heading
        color="rgba(88, 154, 175, 0.8)"
        fontSize={"20px"}
        fontWeight={600}
        letterSpacing={0.7}
      >
        {t("USER DETAILS")}
      </Heading>

      <Flex w="100%" direction="column" gap="2rem">
        <Flex w="100%" gap={4}>
          <Flex direction="column" w="100%">
            <Text
              color="rgba(0, 38, 55, 0.5)"
              fontSize="0.875rem"
              fontWeight={500}
            >
              {t("First Name")}
            </Text>
            <Input
              bg="rgba(88, 154, 175, 0.1)"
              borderRadius=".5rem"
              py="0.875rem"
              px="1.25rem"
              fontSize="1rem"
              fontWeight={500}
              minH="3.25rem"
              name="first_name"
              id="first_name"
              value={formik.values?.first_name}
              onChange={formik.handleChange}
              //isReadOnly={!isSuperAdmin}
            />
          </Flex>
          <Flex direction="column" w="100%">
            <Text
              color="rgba(0, 38, 55, 0.5)"
              fontSize="0.875rem"
              fontWeight={500}
            >
              {t("Last Name")}
            </Text>
            <Input
              bg="rgba(88, 154, 175, 0.1)"
              borderRadius=".5rem"
              py="0.875rem"
              px="1.25rem"
              fontSize="1rem"
              fontWeight={500}
              minH="3.25rem"
              name="last_name"
              id="last_name"
              value={formik.values?.last_name}
              onChange={formik.handleChange}
              //isReadOnly={!isSuperAdmin}
            />
          </Flex>

          <Flex direction="column" w="100%">
            <Text
              color="rgba(0, 38, 55, 0.5)"
              fontSize="0.875rem"
              fontWeight={500}
            >
              {t("Personal number")}
            </Text>
            <Input
              bg="rgba(88, 154, 175, 0.1)"
              borderRadius=".5rem"
              py="0.875rem"
              px="1.25rem"
              fontSize="1rem"
              fontWeight={500}
              minH="3.25rem"
              name="username"
              id="username"
              value={formik.values?.username}
              onChange={formik.handleChange}
              //isReadOnly={!isSuperAdmin}
            />
          </Flex>
        </Flex>

        {/* First Set */}
        <Flex w="100%" gap={4}>
          <Flex direction="column" w="100%">
            <Text
              color="rgba(0, 38, 55, 0.5)"
              fontSize="0.875rem"
              fontWeight={500}
            >
              {t("Phone Number")}
            </Text>
            <Input
              bg="rgba(88, 154, 175, 0.1)"
              borderRadius=".5rem"
              py="0.875rem"
              px="1.25rem"
              fontSize="1rem"
              fontWeight={500}
              minH="3.25rem"
              name="phone"
              id="phone"
              value={formik.values?.phone}
              onChange={formik.handleChange}
              //isReadOnly={!isSuperAdmin}
            />
          </Flex>
          <Flex direction="column" w="100%">
            <Text
              color="rgba(0, 38, 55, 0.5)"
              fontSize="0.875rem"
              fontWeight={500}
            >
              Email
            </Text>
            <Input
              bg="rgba(88, 154, 175, 0.1)"
              borderRadius=".5rem"
              py="0.875rem"
              px="1.25rem"
              fontSize="1rem"
              fontWeight={500}
              minH="3.25rem"
              name="email"
              id="email"
              value={formik.values?.email}
              onChange={formik.handleChange}
              //isReadOnly={!isSuperAdmin}
            />
          </Flex>
          <Flex direction="column" w="100%">
            <Text
              color="rgba(0, 38, 55, 0.5)"
              fontSize="0.875rem"
              fontWeight={500}
            >
              {t("Address")}
            </Text>
            <Input
              bg="rgba(88, 154, 175, 0.1)"
              borderRadius=".5rem"
              py="0.875rem"
              px="1.25rem"
              fontSize="1rem"
              fontWeight={500}
              minH="3.25rem"
              name="address"
              id="address"
              value={formik.values?.address}
              onChange={formik.handleChange}
              //isReadOnly={!isSuperAdmin}
            />
          </Flex>
        </Flex>

        {/* Second Set */}
        <Flex w="100%" gap={4}>
          <Flex direction="column" w="100%">
            <Text
              color="rgba(0, 38, 55, 0.5)"
              fontSize="0.875rem"
              fontWeight={500}
            >
              {t("ZIP-Code")}
            </Text>
            <Input
              bg="rgba(88, 154, 175, 0.1)"
              borderRadius=".5rem"
              py="0.875rem"
              px="1.25rem"
              fontSize="1rem"
              fontWeight={500}
              minH="3.25rem"
              name="zip_code"
              id="zip_code"
              value={formik.values?.zip_code}
              onChange={formik.handleChange}
              //isReadOnly={!isSuperAdmin}
            />
          </Flex>
          <Flex direction="column" w="100%">
            <Text
              color="rgba(0, 38, 55, 0.5)"
              fontSize="0.875rem"
              fontWeight={500}
            >
              {t("City/Town")}
            </Text>
            <Input
              bg="rgba(88, 154, 175, 0.1)"
              borderRadius=".5rem"
              py="0.875rem"
              px="1.25rem"
              fontSize="1rem"
              fontWeight={500}
              minH="3.25rem"
              name="city"
              id="city"
              value={formik.values?.city}
              onChange={formik.handleChange}
              //isReadOnly={!isSuperAdmin}
            />
          </Flex>
          <Flex direction="column" w="100%">
            <Text
              color="rgba(0, 38, 55, 0.5)"
              fontSize="0.875rem"
              fontWeight={500}
            >
              {t("Country")}
            </Text>
            <Input
              bg="rgba(88, 154, 175, 0.1)"
              borderRadius=".5rem"
              py="0.875rem"
              px="1.25rem"
              fontSize="1rem"
              fontWeight={500}
              minH="3.25rem"
              name="country"
              id="country"
              value={formik.values?.country}
              onChange={formik.handleChange}
              //isReadOnly={!isSuperAdmin}
            />
          </Flex>
        </Flex>
      </Flex>

      {true && (
        <Flex justifyContent={"flex-end"}>
          <Button
            onClick={() => {
              formik.submitForm();
            }}
            isLoading={isLoading}
            isDisabled={isChanged}
          >
            {t("Save changes")}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default AdminUserDetails;
