import { Box, SimpleGrid } from "@chakra-ui/react";
import { useAdminUserInfo } from "features/userInfo/api/client";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import CalendarDayItem from "../CalendarDayItem";
import { startOfDay, isEqual } from "date-fns";
import { MoodItem } from "features/userInfo/api/type";
import { useTranslation } from "react-i18next";

interface MoodCheckupCalendarProps {
  dateFrom?: Date;
  dateTo: Date;
}

const MoodCheckupCalendar: FunctionComponent<MoodCheckupCalendarProps> = ({
  dateFrom,
  dateTo,
}) => {
  const { id } = useParams<{ id: string }>();
  const { data: userData } = useAdminUserInfo(id);
  const { t } = useTranslation();

  const daysOfTheWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const localStartDate =
    dateFrom ?? new Date(dateTo.getFullYear(), dateTo.getMonth(), 1);

  const localEndDate = dateFrom
    ? dateTo
    : new Date(dateTo.getFullYear(), dateTo.getMonth() + 1, 0);

  var getDaysArray = (start: Date, end: Date) => {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  return (
    <SimpleGrid
      w="full"
      maxW={{ base: "780px", lg: "1100px" }}
      gridTemplateColumns={"repeat(7, 1fr)"}
      columnGap={{ base: ".2rem", md: "1.5rem", lg: "1rem" }}
      rowGap={{ base: ".2rem", md: "1.5rem", lg: "1rem" }}
      mt="4"
    >
      {daysOfTheWeek.map((day) => (
        <Box
          opacity={0.5}
          color={"blueish.900"}
          key={day}
          fontSize={{ base: ".7rem", md: "1rem" }}
        >
          {t(day)}
        </Box>
      ))}
      {new Array((localStartDate.getDay() + 6) % 7).fill(1).map((_, index) => (
        <CalendarDayItem
          key={`padding-${index}`}
          isPadding
          moodData={[]}
          dayNumber={0}
          tolerableMoodComments={[]}
          intolerableMoodComments={[]}
        />
      ))}

      {getDaysArray(localStartDate, localEndDate).map((date) => {
        const dayMoodData =
          userData?.mood
            .filter(
              (mood) =>
                new Date(mood.date).toLocaleDateString("en-GB") ===
                date.toLocaleDateString("en-GB")
            )
            .map((mood) => mood.value) ?? [];

        const dayDosage = userData?.treatment?.days.find(
          (e) => e.date === date.toISOString().slice(0, 10)
        );

        const moodItems = userData?.mood.filter(
          (mood) =>
            new Date(mood.date).toLocaleDateString("en-GB") ===
            date.toLocaleDateString("en-GB")
        );

        const isTolerable =
          moodItems?.every((mood) => mood.is_tolerable) ?? false;

        const tolerableMoodComments =
          moodItems
            ?.filter((mood) => mood.is_tolerable)
            .flatMap((mood) => mood.comments) ?? [];

        const intolerableMoodComments =
          moodItems
            ?.filter((mood) => !mood.is_tolerable)
            .flatMap((mood) => mood.comments) ?? [];

        return (
          <CalendarDayItem
            key={date.toISOString()}
            moodData={dayMoodData}
            dayNumber={date.getDate()}
            dosage={dayDosage?.dosage}
            tolerableMoodComments={tolerableMoodComments}
            intolerableMoodComments={intolerableMoodComments}
          />
        );
      })}
    </SimpleGrid>
  );
};

export default MoodCheckupCalendar;
