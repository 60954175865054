import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import { useGetMedicationById } from "features/superadmin/api/client";
import {
  useFetchCSVFileFromServer,
  useFetchCSVTableByMedicineName,
  useImportCSV,
} from "features/taperingCSV/api/client";

import ImportCSVButton from "features/taperingCSV/components/ImportCSVButton";
import ImportRegenBoogenCSVButton from "features/taperingCSV/components/ImportRegenBoogenCSVButton";
import MedicineSelector from "features/taperingCSV/components/MedicineSelector";
import PercentageSelector from "features/taperingCSV/components/PercentageSelector";
import PharmacyBottles from "features/taperingCSV/components/PharmacyBottles";
import PharmacySelector from "features/taperingCSV/components/PharmacySelector";
import TaperingTable from "features/taperingCSV/components/TaperingTable";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineDownload } from "react-icons/ai";
import { BiArrowFromRight } from "react-icons/bi";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import DownloadCVSButton from "ui/components/DownloadCSVButton";

interface TaperingCSVPageProps {}

const TaperingCSVPage: FunctionComponent<TaperingCSVPageProps> = () => {
  const [selectedMedicine, setSelectedMedicine] = useState<string>("");
  const [selectedPercentage, setSelectedPercentage] = useState<number>(5);
  const [selectedPharmacy, setSelectedPharmacy] = useState<string>("");

  const medicationData = useGetMedicationById(selectedMedicine);

  const { data: medicineType } = useFetchCSVTableByMedicineName(
    selectedMedicine,
    selectedPercentage,
    selectedPharmacy
  );

  const { data: CSVFile } = useFetchCSVFileFromServer(
    selectedMedicine,
    selectedPercentage,
    selectedPharmacy
  );

  const history = useHistory();

  const { t } = useTranslation();

  return (
    <Box
      mt={"7vh"}
      borderRadius={"1rem"}
      bg={"white"}
      w={"100%"}
      minH={"10rem"}
      p={"2rem"}
    >
      <Flex justifyContent={"space-between"} alignItems={"center"} mb={"2rem"}>
        <Box w={"full"}>
          <Button
            variant={"transparent"}
            color={"#002637"}
            opacity={0.8}
            onClick={() => history.goBack()}
            fontSize={"1rem"}
            leftIcon={<BsArrowLeft />}
            px={0}
          >
            {t("Back")}
          </Button>
          <Flex
            gap={"1rem"}
            width={"full"}
            direction={{ base: "column", md: "row" }}
          >
            <Box w={"full"}>
              <Text color={"blueish.500"} fontSize={"1rem"}>
                {t("Choose medication type")}:{" "}
              </Text>

              <MedicineSelector
                value={selectedMedicine}
                onChange={setSelectedMedicine}
              />
            </Box>

            <Box w={"full"}>
              <Text color={"blueish.500"} fontSize={"1rem"}>
                {t("Choose tapering rate")}:
              </Text>
              <PercentageSelector
                value={selectedPercentage?.toString()!}
                onChange={(newVal) => {
                  setSelectedPercentage(parseInt(newVal));
                }}
              />
            </Box>

            <Box w={"full"}>
              <Text color={"blueish.500"} fontSize={"1rem"}>
                {t("Choose pharmacy")}:
              </Text>
              <PharmacySelector
                value={selectedPharmacy}
                onChange={(newVal) => {
                  setSelectedPharmacy(newVal);
                }}
              />
            </Box>
          </Flex>
        </Box>
      </Flex>

      {selectedMedicine !== "" && medicineType?.tapering?.length !== 0 && (
        <Flex>
          <PharmacyBottles
            medicationType={selectedMedicine}
            taperingRate={selectedPercentage}
            pharmacy={selectedPharmacy}
          />
        </Flex>
      )}
      <Flex justifyContent={"space-between"} mb={"1rem"}>
        <Heading fontSize={"2rem"}>{medicationData?.name}</Heading>
        <Flex gap={"1rem"} alignItems={"center"}>
          {CSVFile && (
            <DownloadCVSButton
              medicineName={selectedMedicine}
              percentage={selectedPercentage}
              pharmacy={selectedPharmacy}
            />
          )}

          <ImportRegenBoogenCSVButton
            medicineName={selectedMedicine}
            taperingRate={selectedPercentage}
          />
          <ImportCSVButton
            medicineName={selectedMedicine}
            taperingRate={selectedPercentage}
          />
        </Flex>
      </Flex>
      {selectedMedicine === "" && (
        <Center h={"20vh"}>
          <Heading opacity={"0.5"}>{t("Please select medicine type")}</Heading>
        </Center>
      )}
      {medicineType?.tapering?.length === 0 && selectedMedicine !== "" && (
        <Center h={"20vh"}>
          <Heading opacity={"0.5"}>
            {t("No treatment plan for this medication type")}
          </Heading>
        </Center>
      )}
      {medicineType?.tapering && medicineType?.tapering?.length !== 0 && (
        <TaperingTable
          medicineType={selectedMedicine}
          taperingRate={selectedPercentage}
          pharmacy={selectedPharmacy}
        />
      )}
    </Box>
  );
};

export default TaperingCSVPage;
