import { useFetchProfileInfo } from "features/dashboard/api/client";
import { FunctionComponent, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAdminStore } from "services/state/adminStore";

import { useTokenStore } from "services/state/tokenStore";

interface StaffProtectedRouteProps {
  children: React.ReactNode | React.ReactNode[];
  role?: string;
  [key: string]: any;
}

const StaffProtectedRoute: FunctionComponent<StaffProtectedRouteProps> = ({
  children,
  role,
  ...rest
}) => {
  const { access, refresh } = useTokenStore();
  const { isAdmin } = useAdminStore();
  const { data } = useFetchProfileInfo();

  const isLoggedIn = access !== "" && refresh !== "";

  const isCorrectRole = data?.role === role || data?.role === "superadmin";

  console.log("🚀 ~ isCorrectRole:", isCorrectRole);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: "/staff-login",
                state: { from: location },
              }}
            />
          );
        } else if (isCorrectRole) {
          return children;
        }
      }}
    ></Route>
  );
};

export default StaffProtectedRoute;
