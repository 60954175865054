import { Flex, Heading } from "@chakra-ui/react";
import { useUserHistory } from "../api/client";
import { useParams } from "react-router-dom";
import UserHistoryCard from "../notificationUserHistoryCard";
import { useTranslation } from "react-i18next";

const UserHistory = () => {
  const { id } = useParams<{ id: string }>();
  const { data: userHistory } = useUserHistory(id);
  const { t } = useTranslation();
  return (
    <Flex w="100%" direction="column">
      <Heading fontSize="24px" fontWeight={500}>
        {t("History of treatment plan changes")}
      </Heading>
      {userHistory?.request.map((item) => (
        <UserHistoryCard
          key={item.id}
          status={item.status}
          created_at={item.created_at}
          updated_at={item.updated_at}
          expected_duration={item.expected_duration}
          id={id}
        />
      ))}
    </Flex>
  );
};

export default UserHistory;
