import {
  Divider,
  Flex,
  Heading,
  VStack,
  Box,
  Image,
  Button,
  Center,
  useDisclosure,
  HStack,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  forwardRef,
  FunctionComponent,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { useParams } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useRequestUserPrescriptionPDF } from "features/admin/api/client";
import { pdfjs } from "react-pdf";
import Draggable, { ControlPosition } from "react-draggable";
import { number } from "yup";
import { PDFDocument } from "pdf-lib";
import { downloadByUrl } from "features/admin/helper/helper_functions";
import signature from "./PlaceholderSign.png";

import { IoMdAdd } from "react-icons/io";
import SignatureDraggable from "../SignatureDraggable";
import SignPopup from "../SignPopup";
import { BsArrowLeft, BsArrowRight, BsDownload } from "react-icons/bs";
import SignatureAddButton from "../SignatureAddButton";
import { toast } from "react-toastify";
import axios from "axios";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface SignPdfComponentProps {
  pdfUrl: string;
  defaultPosition?: ControlPosition;
  setNewPdfUrl?(newPdfUrl: string): void;
  disableSignature?: true;
}

const SignPdfComponent = forwardRef(
  (
    {
      pdfUrl,
      defaultPosition,
      setNewPdfUrl,
      disableSignature,
    }: SignPdfComponentProps,
    ref
  ) => {
    const [proxyPdfUrl, setProxyPdfUrl] = useState<string>("");
    const [signatureUrl, setSignatureUrl] = useState<string>("");
    const [numPages, setNumPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const { id } = useParams<{ id: string }>();

    const docContainerRef = useRef<any>(null);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
    };

    const [signaturePosition, setSignaturePosition] = useState<{
      x: number;
      y: number;
    }>({ x: 0, y: 0 });

    const insertAnchored = async () => {
      let pngImageBytes;

      try {
        pngImageBytes = await axios
          .get<any, any>(signatureUrl, {
            responseType: "arraybuffer",

            headers: {
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Expires: "0",
            },
          })
          .then((res: any) => {
            return res.data;
          });
      } catch (error) {
        console.log("FETCHING IMAGE FAILED WITH THE FOLLOWING ERROR");
        console.log(error);
        console.log(signatureUrl);
        throw new Error("Image did not fetch");
      }

      const existingPdfBytes = await fetch(pdfUrl).then((res) => {
        return res.arrayBuffer();
      });

      const documentInstance = await PDFDocument.load(existingPdfBytes);

      const pngImage = await documentInstance.embedPng(pngImageBytes);

      const form = await documentInstance.getForm();

      try {
        const signatureField = form.getTextField("signature");
        signatureField.setImage(pngImage);
        signatureField.enableReadOnly();
      } catch (error) {
        throw new Error("Image did not insert");
      }

      const pdfBytes = await documentInstance.save();

      var blob = new Blob([pdfBytes], { type: "application/pdf" }); // change resultByte to bytes

      return window.URL.createObjectURL(blob);
    };

    useImperativeHandle(ref, () => ({
      insertAndDownload: async () => {
        const pngImageBytes = await fetch(signatureUrl).then((res) =>
          res.arrayBuffer()
        );

        const existingPdfBytes = await fetch(pdfUrl).then((res) => {
          return res.arrayBuffer();
        });

        const documentInstance = await PDFDocument.load(existingPdfBytes);

        const page = (await documentInstance).getPage(currentPage - 1);
        const pngImage = await documentInstance.embedPng(pngImageBytes);

        debugger;
        const form = documentInstance.getForm();

        try {
          const signatureField = form.getTextField("signature");
          signatureField.setImage(pngImage);
          signatureField.enableReadOnly();
        } catch (error) {
          debugger;
        }

        // hairField.setText("24 years");

        debugger;
        const pdfBytes = await documentInstance.save();

        var blob = new Blob([pdfBytes], { type: "application/pdf" }); // change resultByte to bytes

        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        link.download = "myFileName.pdf";
        link.click();
      },

      insertAnchored: insertAnchored,

      insertAndReturn: async () => {
        const pngImageBytes = await fetch(signatureUrl).then((res) =>
          res.arrayBuffer()
        );

        const existingPdfBytes = await fetch(pdfUrl).then((res) =>
          res.arrayBuffer()
        );
        const documentInstance = await PDFDocument.load(existingPdfBytes);

        const page = (await documentInstance).getPage(currentPage - 1);
        const pngImage = await documentInstance.embedPng(pngImageBytes);

        page.drawImage(pngImage, {
          x:
            signaturePosition.x *
            (page.getWidth() / docContainerRef.current.clientWidth),
          y:
            signaturePosition.y *
              -1 *
              (page.getHeight() / docContainerRef.current.clientHeight) -
            50,
          width: 100,
          height: 50,
        });

        const pdfBytes = await documentInstance.save();

        var blob = new Blob([pdfBytes], { type: "application/pdf" });

        return blob;
      },

      signaturePosition: signaturePosition,
      isDocumentSigned: signatureUrl !== "",
    }));

    useEffect(() => {
      const asyncHandler = async () => {
        if (signatureUrl !== "") {
          try {
            const newPdfUrl = await insertAnchored();
            setProxyPdfUrl(newPdfUrl);
            setNewPdfUrl?.(newPdfUrl);
          } catch (error) {
            console.log(error);
            toast.error("Something went wrong");
          }
        }
      };

      asyncHandler();
    }, [signatureUrl]);

    return (
      <VStack maxH={"100% !important"}>
        <Box position={"relative"}>
          <Box ref={docContainerRef}>
            <Document
              file={proxyPdfUrl === "" ? pdfUrl : proxyPdfUrl}
              onLoadError={(error: any) => {
                console.error("Error loading PDF:", error.message);
                console.error("Error stack:", error.stack);
              }}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from({ length: numPages }, (_, index) => (
                <>
                  <Page
                    key={index + 1}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    scale={1.38}
                  />
                  {index !== numPages - 1 && (
                    <Flex justifyContent="flex-start">
                      <Box
                        width="100%"
                        h="1rem"
                        background="blueish.500"
                        opacity={0.1}
                      />
                    </Flex>
                  )}
                </>
              ))}
            </Document>
          </Box>

          {/* {signatureUrl !== "" && (
            <SignatureDraggable
              signatureURL={signatureUrl}
              onChangePosition={setSignaturePosition}
              onDelete={() => setSignatureUrl("")}
              defaultPosition={
                defaultPosition ?? {
                  x: 0,
                  y: -1 * docContainerRef.current.clientHeight + 50,
                }
              }
            />
          )} */}
        </Box>

        {!disableSignature && (
          <SignatureAddButton setSignatureUrl={setSignatureUrl} />
        )}

        <Box
          position={"absolute"}
          bottom={"6rem"}
          borderRadius={"0.5rem"}
          boxShadow={"0px 0px 8px rgba(0, 0, 0, 0.15)"}
          bg={"white"}
        >
          {/* <HStack alignItems={"center"} spacing={"0.6rem"}>
            <IconButton
              icon={<BsArrowLeft />}
              aria-label={""}
              variant={"transparent"}
              color={"black"}
              p={"0 !important"}
              minH={"2.5rem !important"}
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              disabled={currentPage === 1}
            />
            <Text color={"blueish.900"} fontSize={"stnd"}>
              {currentPage}/{numPages}
            </Text>
            <IconButton
              icon={<BsArrowRight />}
              aria-label={""}
              variant={"transparent"}
              color={"black"}
              p={"0 !important"}
              minH={"2rem !important"}
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === numPages}
            />
          </HStack> */}
        </Box>
      </VStack>
    );
  }
);

export default SignPdfComponent;
