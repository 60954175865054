import { Select } from "chakra-react-select";
import { FunctionComponent } from "react";

interface PharmacySelectorProps {
  value: string;
  onChange(newValue: string): void;
}

const PharmacySelector: FunctionComponent<PharmacySelectorProps> = ({
  value,
  onChange,
}) => {
  const options = [
    { value: "", label: "Standard/APL" },
    { value: "regenboogen", label: "RegenBoogen" },
  ];

  return (
    <Select
      options={options}
      onChange={(newVal) => {
        onChange(newVal?.value ?? "");
      }}
      defaultValue={{ value: "", label: "Standard/APL" }}
    />
  );
};

export default PharmacySelector;
