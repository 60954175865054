import {
  Box,
  Button,
  Flex,
  Heading,
  Hide,
  Image,
  Show,
  Text,
  VStack,
} from "@chakra-ui/react";
import { IoCloseOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";

import calculate from "assets/icons/calculate.svg";
import DiscoverTable from "./components/DiscoverTable";
import { FaqLinks } from "./components/FaqLinks";
import DiscoverTableMobile from "./components/DiscoverTableMobile";
import { useTranslation } from "react-i18next";

const FaqDiscoverTwo = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleContactUsClick = () => {
    history.push("/faq#contact-form");
  };
  return (
    <VStack
      w="full"
      align="flex-start"
      py="3rem"
      px={{ base: "1rem", sm: "2rem", md: "10rem" }}
      justify="center"
    >
      <Button
        onClick={() => history.goBack()}
        variant="unstyled"
        alignSelf="flex-end"
      >
        <Flex align="center">
          <IoCloseOutline size={25} />
          <Box as="span" mt={1}>
            {t("Close")}
          </Box>
        </Flex>
      </Button>

      <VStack
        w="full"
        align="flex-start"
        justify="center"
        px={{ base: ".5rem", sm: "1rem", md: "10rem" }}
        py="2rem"
        spacing={5}
      >
        <Image src={calculate} />
        <Heading
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1.5rem", sm: "2rem" }}
          fontWeight={500}
        >
          {t("How much does it cost to step down with Lamina?")}
        </Heading>
        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "Lamina Clinic is a private step-down clinic. This means that visits to our nurses and doctors are paid for by the patient himself. In order to be as accessible as possible to as many people as possible, we keep the prices as low as possible, and you only pay for visits and medicines, no subscriptions or hidden costs."
          )}
        </Text>

        <Heading
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1.25rem", sm: "1.5rem" }}
          fontWeight={500}
        >
          {t("Visit us")}
        </Heading>
        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "A nurse meeting with us costs SEK 275. A doctors visit costs SEK 499, which also includes the creation of your personal tapering plan and prescription. If you need to renew your prescription but do not need a doctors visit, it costs SEK 275."
          )}
        </Text>

        <Heading
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1.25rem", sm: "1.5rem" }}
          fontWeight={500}
        >
          {t("Medication type")}
        </Heading>
        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "We prescribe specially manufactured medicines, but they are manufactured by the pharmacy, not by us. You collect and pay there, just as usual. The drugs we usually prescribe are included in the drug benefit, so you pay a maximum of SEK 2,850 per 12-month period."
          )}
        </Text>

        <Heading
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1.25rem", sm: "1.5rem" }}
          fontWeight={500}
        >
          {t("Understand your total cost")}
        </Heading>

        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "Below you will find a price example so that you can understand how a reduction where the medicines are covered by the high-cost protection can look like."
          )}

          <Box as="span" fontWeight="bold">
            {t(
              "Keep in mind that it is an example, not a guarantee or maximum cost."
            )}
          </Box>

          {t(
            "If you need or want more meetings, the cost of these will be added. Every tapering journey is unique, and always expect that you may need one or more extra appointments if, against the odds, you feel worse during the tapering."
          )}
        </Text>

        <Show above="sm">
          <DiscoverTable />
        </Show>
        <Hide above="sm">
          <DiscoverTableMobile />
        </Hide>

        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "The principle is that you have a follow-up every 4 weeks and prescription renewal every 3 months until you have finished tapering off. The picture shows our standard procedure, but of course you can book as many extra appointments as you want with both the nurse and the doctor."
          )}
        </Text>

        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "Also keep in mind that we may want to schedule an extra follow-up with you if we think you need to adjust your plan or dose."
          )}
        </Text>

        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "You can always end the tapering whenever you want and you are not committing to anything."
          )}
        </Text>

        <FaqLinks onContactUsClick={handleContactUsClick} />

        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "1rem", sm: "1.25rem" }}
          fontWeight={400}
        >
          {t(
            "Get started and book your first appointment by downloading the app."
          )}
        </Text>
      </VStack>
    </VStack>
  );
};

export default FaqDiscoverTwo;
