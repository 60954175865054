import SvgIconConstituentValues from "./types/svgPropsType";

const NotificationAccept = (props: SvgIconConstituentValues) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 0.833252C8.18702 0.833252 6.41474 1.37087 4.90729 2.37811C3.39984 3.38536 2.22492 4.817 1.53112 6.49199C0.837315 8.16698 0.655785 10.0101 1.00948 11.7882C1.36318 13.5664 2.23622 15.1997 3.5182 16.4817C4.80018 17.7637 6.43353 18.6368 8.21169 18.9904C9.98985 19.3441 11.833 19.1626 13.5079 18.4688C15.1829 17.775 16.6146 16.6001 17.6218 15.0926C18.6291 13.5852 19.1667 11.8129 19.1667 9.99992C19.1638 7.56965 18.1971 5.23974 16.4787 3.52127C14.7602 1.80281 12.4303 0.836119 10 0.833252ZM10 17.4999C8.51665 17.4999 7.06661 17.0601 5.83324 16.2359C4.59987 15.4118 3.63857 14.2405 3.07092 12.87C2.50326 11.4996 2.35474 9.9916 2.64412 8.53674C2.93351 7.08188 3.64782 5.74551 4.69671 4.69662C5.74561 3.64772 7.08198 2.93342 8.53684 2.64403C9.99169 2.35464 11.4997 2.50316 12.8701 3.07082C14.2406 3.63848 15.4119 4.59977 16.236 5.83314C17.0601 7.06651 17.5 8.51656 17.5 9.99992C17.4976 11.9883 16.7066 13.8945 15.3006 15.3005C13.8946 16.7065 11.9884 17.4975 10 17.4999ZM14.7808 7.35325C14.8548 7.4339 14.9122 7.52834 14.9497 7.63116C14.9872 7.73399 15.004 7.84319 14.9993 7.95253C14.9946 8.06187 14.9684 8.16922 14.9222 8.26843C14.876 8.36764 14.8107 8.45678 14.73 8.53075L9.73001 13.1141C9.57171 13.2592 9.36347 13.3377 9.14874 13.333C8.93401 13.3284 8.72937 13.241 8.57751 13.0891L6.07751 10.5891C5.92126 10.4327 5.83352 10.2207 5.8336 9.99962C5.83367 9.77857 5.92156 9.56659 6.07793 9.41033C6.2343 9.25408 6.44633 9.16634 6.66739 9.16642C6.88845 9.16649 7.10042 9.25438 7.25668 9.41075L9.19251 11.3466L13.6042 7.30242C13.767 7.15326 13.9824 7.07483 14.203 7.08436C14.4237 7.09389 14.6315 7.1906 14.7808 7.35325Z"
      fill="white"
    />
  </svg>
);

export default NotificationAccept;
