import {
  Flex,
  Heading,
  Text,
  Image,
  Box,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import left_arrow from "assets/icons/left_arrow.svg";
import useGoogleAnalytics from "features/analytics/hooks/useGoogleAnalytics";

const PrescriptionAndMedication = () => {
  const { t } = useTranslation();
  const { basicEvent } = useGoogleAnalytics();

  const medicine = [
    t("Amitriptyline (Sarotex, Tryptizol)"),
    t("Bupropion (Wellbutrin,Zuban)"),
    t("Citalopram"),
    t("Escitalopram"),
    t("Fluoxetine (Prozac)"),
    t("Mirtazapine (Remeron)"),
    t("Moklobemid"),
    t("Nortriptyline (Nortrilen, Pamelor)"),
    t("Paroxetine (Seroxat, Paxil)"),
    t("Sertraline (Zoloft)"),
    t("Venlafaxine"),
  ];

  const researchLinks = [
    {
      title: "Addictive Behaviors:",
      url: "https://www.sciencedirect.com/science/article/pii/S0306460318308347?via%3Dihub",
      text: "A systematic review into the incidence, severity and duration of antidepressant withdrawal effects: Are guidelines evidence-based?",
    },
    {
      title: "Therapeutic Advances in Psychopharmacology:",
      url: "https://pubmed.ncbi.nlm.nih.gov/33224468/",
      text: "The 'patient voice': patients who experience antidepressant withdrawal symptoms are often dismissed, or misdiagnosed with relapse, or a new medical condition.",
    },
    {
      title: "British Journal of General Practice:",
      url: "https://bjgp.org/content/73/728/138#:~:text=Antidepressants%20should%20be%20tapered%20through,not%20stopping%20their%20antidepressant%20abruptly",
      text: "Withdrawing from SSRI antidepressants: advice for primary care",
    },
    {
      title: "Cambridge University Press:",
      url: "https://www.cambridge.org/core/journals/epidemiology-and-psychiatric-sciences/article/antidepressant-withdrawal-the-tide-is-finally-turning/8394C10FE317CA5A39B62B86793FC3ED",
      text: "Antidepressant withdrawal – the tide is finally turning",
    },
    {
      title: "Harvard Health Publishing:",
      url: "https://www.health.harvard.edu/diseases-and-conditions/going-off-antidepressants#:~:text=Discontinuation%20symptoms%20often%20include%20physical,itself%20takes%20weeks%20to%20work",
      text: "Going off antidepressants",
    },
    {
      title: "The Royal College of Psychiatrists:",
      url: "https://www.rcpsych.ac.uk/mental-health/treatments-and-wellbeing/stopping-antidepressants",
      text: "Guidance for stopping antidepressants",
    },

    {
      title: "National institute for health and care excellence:",
      url: "https://www.nice.org.uk/news/nice-draft-quality-standard-depression-adults-update-2023",
      text: "Adults with depression who want to quit antidepressants should be given support on how to do it safely over time, says NICE",
    },
    {
      title: "The Royal College of Psychiatrists:",
      url: "https://www.rcpsych.ac.uk/news-and-features/latest-news/detail/2023/01/17/rcpsych-comments-on-new-nice-guidance-regarding-patients-withdrawing-from-antidepressants#:~:text=The%20new%20draft%20quality%20standard,and%20severity%20of%20withdrawal%20symptoms",
      text: "RCPsych comments on new NICE guidance regarding patients withdrawing from antidepressants",
    },
    {
      title: "GOV.UK:",
      url: "https://www.gov.uk/government/publications/prescribed-medicines-review-report",
      text: "Prescribed medicines review: reports",
    },
    {
      title: "APPG for Prescribed Drug Dependence:",
      url: "http://prescribeddrug.org/research/",
      text: "Research and Reports",
    },
  ];

  const halfLength = Math.ceil(medicine.length / 2);
  const firstColumn = medicine.slice(0, halfLength);
  const secondColumn = medicine.slice(halfLength);

  const history = useHistory();

  return (
    <Box pb={"5vh"} w="full">
      <Flex
        w="full"
        bg="rgba(255, 255, 255, 1)"
        borderRadius="24px"
        p="60px"
        direction="column"
        gap="10px"
      >
        <Flex
          align="center"
          gap="14px"
          onClick={() => history.goBack()}
          cursor="pointer"
        >
          <Image src={left_arrow} />
          <Text fontSize="18px" color="black" fontWeight={500}>
            {t("Back")}
          </Text>
        </Flex>
        <Flex w="100%" align="center" justify="center" mb="30px">
          <Heading
            fontSize={{ base: "1.5rem", sm: "4xl" }}
            color="blueish.500"
            fontWeight={500}
            mr={{ base: "1rem", sm: "0px" }}
          >
            Behandling
          </Heading>
        </Flex>

        {/*  Prescription */}
        {/*  <div id="prescription">
          <Flex w="full" direction="column">
            <Heading
              fontSize={{ base: "1.5rem", sm: "2rem" }}
              textAlign="start"
              fontWeight={500}
            >
              {t("Prescription")}
            </Heading>
            <Text color="black" fontSize={{ base: "16", sm: "18px" }}>
              {t(
                "Lamina Clinic has its own Swedish prescribing physicians, so you can go directly through us to get your prescription for tapering or discontinuation."
              )}
            </Text>
          </Flex>
        </div> */}

        {/* What medication do we support? */}
        <div id="medication-support">
          <Flex w="100%" align="center" justify="center">
            <Heading
              w="full"
              fontSize={{ base: "1.5rem", sm: "2rem" }}
              alignContent="flex-start"
              fontWeight={500}
            >
              {t("Medicines")}
            </Heading>
          </Flex>

          <Text color="black" fontSize={{ base: "16", sm: "18px" }}>
            {t(
              "We write prescriptions that contain the same active substance with the only one the difference is that we provide completely unique doses that are adapted for step down. We currently support the following medicines"
            )}
          </Text>
        </div>
        <Flex
          w="full"
          direction="column"
          gap="8px"
          px={{ base: "5px", sm: "20px" }}
          mt="20px"
        >
          <div id="medication">
            <Flex
              direction={{ base: "column", sm: "row" }}
              w="full"
              gap={{ base: 2, sm: 20 }}
            >
              <Flex direction="column" gap="8px">
                {firstColumn.map((item, index) => (
                  <Heading
                    fontSize={{ base: "1rem", sm: "1.5rem" }}
                    color="blueish.500"
                    fontWeight={500}
                    key={index}
                  >
                    {item}
                  </Heading>
                ))}
              </Flex>
              <Flex direction="column" gap="8px">
                {secondColumn.map((item, index) => (
                  <Heading
                    fontSize={{ base: "1rem", sm: "1.5rem" }}
                    color="blueish.500"
                    fontWeight={500}
                    key={index}
                  >
                    {item}
                  </Heading>
                ))}
              </Flex>
            </Flex>
          </div>
        </Flex>

        {/* Research and Evidence */}
        <div id="research">
          <Flex w="full" direction="column" gap="1rem" mt="2rem">
            <Heading
              textAlign="start"
              fontSize={{ base: "1.5rem", sm: "2rem" }}
              fontWeight={500}
            >
              {t("Research and Evidence")}
            </Heading>
            <Text
              color="black"
              display="flex"
              flexDirection={{ base: "column", md: "row" }}
              gap=".5rem"
              fontSize={{ base: "16", sm: "18px" }}
            >
              {t(
                "We base our tapering and discontinuation method on the latest research and evidence. Here you can read more about the studies,research, and other evidence that shows that our method not only works but is currently the best and safest."
              )}
            </Text>

            {researchLinks.map((link, index) => (
              <Flex direction="column" key={index}>
                <Heading fontSize="18px" fontWeight={500}>
                  {link.title}
                </Heading>
                <ChakraLink
                  color={"white !important"}
                  href={link.url}
                  target="_blank"
                  onClick={() => {
                    basicEvent("Contact", "User clicked footer link");
                  }}
                >
                  <Text
                    color="blueish.500"
                    fontSize={{ base: "16", sm: "16px" }}
                    textDecor="underline"
                  >
                    {link.text}
                  </Text>
                </ChakraLink>
              </Flex>
            ))}
          </Flex>
        </div>
      </Flex>
    </Box>
  );
};

export default PrescriptionAndMedication;
