import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  Text,
  Heading,
  VStack,
  Center,
  Image,
} from "@chakra-ui/react";
import { UserInfo } from "features/admin/api/types";
import { useFetchAllDoctors } from "features/doctors/api/client";
import { useAdminUserInfo } from "features/userInfo/api/client";
import {
  useAssignPersonnel,
  useFetchAllNurses,
} from "features/superadmin/api/client";
import { FunctionComponent, useEffect, useState } from "react";
import ManualSelect from "ui/components/ManualSelect";
import CheckLarge from "assets/images/CheckLarge.svg";
import { useTranslation } from "react-i18next";

interface AssignPersonnelModalProps {
  patientId?: string[];
  patient?: string;
  patientsList: UserInfo[];
  isOpen: boolean;
  onClose(): void;
}

type SelectedType = "main" | "secondary";

const AssignPersonnelModal: FunctionComponent<AssignPersonnelModalProps> = ({
  patientId,
  patient,
  patientsList,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const { data: allDoctorsList } = useFetchAllDoctors();
  const { data: allNursesList } = useFetchAllNurses();

  const [isPersonnelSet, setIsPersonnelSet] = useState<boolean>(false);

  const { trigger: sendSetPersonnelRequest, isMutating: isAssigning } =
    useAssignPersonnel();

  const [selectedType, setSelectedType] = useState<SelectedType>("main");

  const [selectedDoctors, setSelectedDoctors] = useState<string[]>(["", ""]);
  const [selectedNurses, setSelectedNurses] = useState<string[]>(["", ""]);
  const { data: patientData } = useAdminUserInfo(
    (patientId && patientId[0]) || ""
  );

  const availableTypes = ["main", "secondary"];

  const selectDoctor = (doctorId: string) => {
    setSelectedDoctors((prevDoctors) => {
      if (selectedType === "main") {
        return [doctorId, prevDoctors[1]];
      } else {
        return [prevDoctors[0], doctorId];
      }
    });
  };

  const selectNurse = (nurseId: string) => {
    setSelectedNurses((prevNurses) => {
      if (selectedType === "main") {
        return [nurseId, prevNurses[1]];
      } else {
        return [prevNurses[0], nurseId];
      }
    });
  };

  const doctorOptions =
    allDoctorsList?.doctors
      .filter((e) => !e.is_deleted)
      .map((e) => {
        return {
          label: e.first_name + " " + e.last_name,
          value: e.id,
        };
      }) ?? [];

  const nurseOptions =
    allNursesList?.nurses
      .filter((e) => !e.is_deleted)
      .map((e) => {
        return {
          label: e.first_name + " " + e.last_name,
          value: e.id,
        };
      }) ?? [];

  useEffect(() => {
    if (patientData) {
      setSelectedDoctors((prevDoctors) => {
        const newDoctors = ["", ""];
        patientData.doctors?.forEach((doctorId, index) => {
          const position = selectedDoctors.indexOf(doctorId);
          if (position !== -1) {
            newDoctors[position] = doctorId;
          } else {
            newDoctors[index] = doctorId;
          }
        });
        return newDoctors;
      });

      setSelectedNurses((prevNurses) => {
        const newNurses = ["", ""];
        patientData.nurses?.forEach((nurseId, index) => {
          const position = selectedNurses.indexOf(nurseId);
          if (position !== -1) {
            newNurses[position] = nurseId;
          } else {
            newNurses[index] = nurseId;
          }
        });
        return newNurses;
      });
    }
  }, [patientData]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minW={"40vw"} minH={"70vh"}>
        <ModalCloseButton size={"3rem"} top={"1rem"} right={"1rem"} />
        <ModalBody pt={"1.75rem"}>
          {!isPersonnelSet && (
            <>
              <Flex mb={"1rem"}>
                <Text fontSize={"1rem"} color={"blueish.900"} mr={"0.5rem"}>
                  Patienter:
                </Text>

                <Text
                  color={"blueish.500"}
                  fontWeight={"700"}
                  textDecor={"underline"}
                  fontSize="1rem"
                >
                  {patient}
                  {patientsList
                    .map((e) => {
                      return e.first_name + " " + e.last_name;
                    })
                    .join(", ")}
                </Text>
              </Flex>
              <Heading fontSize={"2rem"} fontWeight={"600"} mb={"1rem"}>
                {t("Assign Care Personnel")}
              </Heading>
              <Flex gap={"0.5rem"} mb={"2rem"}>
                {availableTypes.map((type) => {
                  return (
                    <Button
                      variant={"unstyled"}
                      bg={selectedType === type ? "blueish.900" : "transparent"}
                      color={selectedType === type ? "white" : "blueish.900"}
                      fontSize={"1rem"}
                      border={"1px solid #002637"}
                      minW={"6.25rem"}
                      onClick={() => {
                        if (availableTypes.includes(type as SelectedType)) {
                          setSelectedType(type as SelectedType);
                        }
                        if (type === "secondary") {
                          setSelectedType("secondary");
                        }
                      }}
                      textTransform={"capitalize"}
                    >
                      {t(type)}
                    </Button>
                  );
                })}
              </Flex>
              {selectedType === "main" && (
                <>
                  <VStack alignItems={"flex-start"} spacing="0" mb={"2rem"}>
                    <Heading fontSize={"1rem"}>
                      {t("Doctor").toUpperCase()}
                    </Heading>
                    <ManualSelect
                      onChange={selectDoctor}
                      value={selectedDoctors[0]}
                      options={doctorOptions.filter(
                        (e) => e.value !== selectedDoctors[1]
                      )}
                    />
                  </VStack>

                  <VStack alignItems={"flex-start"} spacing="0">
                    <Heading fontSize={"1rem"}>
                      {t("Nurse").toUpperCase()}
                    </Heading>
                    <ManualSelect
                      onChange={selectNurse}
                      value={selectedNurses[0]}
                      options={nurseOptions.filter(
                        (e) => e.value !== selectedNurses[1]
                      )}
                    />
                  </VStack>
                </>
              )}
              {selectedType === "secondary" && (
                <>
                  <VStack alignItems={"flex-start"} spacing="0" mb={"2rem"}>
                    <Heading fontSize={"1rem"}>
                      {t("Doctor").toUpperCase()} (SEKUNDÄR)
                    </Heading>
                    <ManualSelect
                      onChange={selectDoctor}
                      value={selectedDoctors[1]}
                      options={doctorOptions.filter(
                        (e) => e.value !== selectedDoctors[0]
                      )}
                    />
                  </VStack>

                  <VStack alignItems={"flex-start"} spacing="0">
                    <Heading fontSize={"1rem"}>
                      {t("Nurse").toUpperCase()} (SEKUNDÄR)
                    </Heading>
                    <ManualSelect
                      onChange={selectNurse}
                      value={selectedNurses[1]}
                      options={nurseOptions.filter(
                        (e) => e.value !== selectedNurses[0]
                      )}
                    />
                  </VStack>
                </>
              )}
              <Flex justifyContent={"flex-start"} mt={"2rem"}>
                <Button
                  variant={"unstyled"}
                  bg={"blueish.500"}
                  color={"white"}
                  minW={"14rem"}
                  h={"3rem"}
                  fontSize={"1rem"}
                  isLoading={isAssigning}
                  onClick={() => {
                    const cleanedSelectedDoctors = selectedDoctors.map(
                      (doctor) => (doctor === null ? "" : doctor)
                    );
                    const cleanedSelectedNurses = selectedNurses.map((nurse) =>
                      nurse === null ? "" : nurse
                    );

                    const filteredSelectedDoctors =
                      cleanedSelectedDoctors.filter(Boolean);
                    const filteredSelectedNurses =
                      cleanedSelectedNurses.filter(Boolean);

                    sendSetPersonnelRequest(
                      {
                        doctors: filteredSelectedDoctors,
                        nurses: filteredSelectedNurses,
                        users: patientId || patientsList.map((e) => e.id),
                      },
                      {
                        onSuccess: () => {
                          setIsPersonnelSet(true);
                        },
                      }
                    );
                  }}
                >
                  {t("Assign")}
                </Button>
              </Flex>
            </>
          )}

          {isPersonnelSet && (
            <Center minH={"40vh"}>
              <VStack alignItems={"stretch"}>
                <Image src={CheckLarge} mb={"1rem"} h={"12rem"} />
                <Heading
                  fontSize={"40px"}
                  fontWeight={500}
                  mb={"2rem !important"}
                >
                  Staff successfully reassigned
                </Heading>
                <Text fontSize={"1rem"} color={"blueish.900"} mr={"0.5rem"}>
                  {t("Patients")}{" "}
                  <Text
                    color={"blueish.500"}
                    fontWeight={"700"}
                    textDecor={"underline"}
                    fontSize="1rem"
                    display={"inline"}
                  >
                    {patient}
                    {patientsList
                      .map((e) => {
                        return e.first_name + " " + e.last_name;
                      })
                      .join(", ")}
                  </Text>{" "}
                  <Text fontSize={"1rem"} color={"blueish.900"} mr={"0.5rem"}>
                    has been assigned the following staff
                  </Text>
                </Text>
                {selectedDoctors[0] !== "" && (
                  <VStack alignItems={"flex-start"}>
                    <Heading fontSize={"1rem"} fontWeight={"400"}>
                      {t("Doctor").toUpperCase()}:{" "}
                      <b>
                        {
                          doctorOptions.find(
                            (e) => e.value === selectedDoctors[0]
                          )?.label
                        }
                      </b>
                    </Heading>
                  </VStack>
                )}
                {selectedNurses[0] !== "" && (
                  <VStack alignItems={"flex-start"}>
                    <Heading fontSize={"1rem"} fontWeight={"400"}>
                      {t("Nurse").toUpperCase()}:{" "}
                      <b>
                        {
                          nurseOptions.find(
                            (e) => e.value === selectedNurses[0]
                          )?.label
                        }
                      </b>
                    </Heading>
                  </VStack>
                )}

                {selectedDoctors[1] !== "" && (
                  <VStack alignItems={"flex-start"}>
                    <Heading fontSize={"1rem"} fontWeight={"400"}>
                      {t("Doctor").toUpperCase()}(SEKUNDÄR):{" "}
                      <b>
                        {
                          doctorOptions.find(
                            (e) => e.value === selectedDoctors[1]
                          )?.label
                        }
                      </b>
                    </Heading>
                  </VStack>
                )}
                {selectedNurses[1] !== "" && (
                  <VStack alignItems={"flex-start"}>
                    <Heading fontSize={"1rem"} fontWeight={"400"}>
                      {t("Nurse").toUpperCase()} (SEKUNDÄR):{" "}
                      <b>
                        {
                          nurseOptions.find(
                            (e) => e.value === selectedNurses[1]
                          )?.label
                        }
                      </b>
                    </Heading>
                  </VStack>
                )}
                <Button
                  variant={"transparent"}
                  color={"blueish.500"}
                  onClick={() => {
                    setIsPersonnelSet(false);
                    onClose();
                  }}
                >
                  {t("Okay")}
                </Button>
              </VStack>
            </Center>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AssignPersonnelModal;
