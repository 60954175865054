import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  IconButton,
  Box,
  Portal,
  Center,
  Input,
} from "@chakra-ui/react";
import { FunctionComponent, memo, useContext, useRef, useState } from "react";
import { BiMicrophone } from "react-icons/bi";
import {
  BsCameraVideo,
  BsCameraVideoOff,
  BsMic,
  BsMicMute,
} from "react-icons/bs";
import { MdOutlineCallEnd } from "react-icons/md";
import CallContextProvider from "../CallContext";
import CallInfo from "../CallInfo";
import { ContextProvider, SocketContext } from "../VideocallContext";
import VideoPlayer from "../VideoPlayer";

interface CallModalProps {
  isOpen: boolean;
  onClose(): void;
}

const CallModal: FunctionComponent<CallModalProps> = ({ isOpen, onClose }) => {
  const videoplayerRef = useRef<any>(null);
  const {
    endCall,
    isCallOngoing,
    disableVideo,
    enableVideo,
    videoEnabled,
    audioEnabled,
    enableMic,
    disableMic,
    answerCall,
    callUser,
    call,
  } = useContext(SocketContext);

  return (
    <>
      <Center
        position={"fixed"}
        zIndex={1000}
        visibility={isOpen ? "visible" : "hidden"}
        h={"100vh"}
        w={"100vw"}
        bg={"rgba(0 ,0, 0, 0.2)"}
        backdropFilter={"blur(2px)"}
        top={0}
      >
        <Box bg={"white"} p={"2rem"} borderRadius={"1rem"} maxW={'80%'}>
          <VideoPlayer />
          <Center pt={"2rem"} gap={"1rem"}>
            <IconButton
              icon={audioEnabled ? <BsMic /> : <BsMicMute />}
              fontSize={"1.75rem"}
              aria-label={""}
              minWidth={0}
              bg={audioEnabled ? "gray" : "white"}
              color={audioEnabled ? "white" : "gray"}
              border={"1px solid gray"}
              borderColor={'gray'}
              height={"4rem"}
              width={"4rem"}
              padding={"0 !important"}
              borderRadius={"50%"}
              _hover={{
                boxShadow: "0px 0px 1rem gray",
              }}
              onClick={() => {
                if (audioEnabled) {
                  disableMic();
                } else {
                  enableMic();
                }
              }}
            />
            <IconButton
              icon={videoEnabled ? <BsCameraVideo /> : <BsCameraVideoOff />}
              fontSize={"1.75rem"}
              aria-label={""}
              minWidth={0}
              bg={videoEnabled ? "gray" : "white"}
              color={videoEnabled ? "white" : "gray"}
              border={"1px solid gray"}
              borderColor={'gray'}
              height={"4rem"}
              width={"4rem"}
              padding={"0 !important"}
              borderRadius={"50%"}
              _hover={{
                boxShadow: "0px 0px 1rem gray",
              }}
              onClick={() => {
                if (videoEnabled) {
                  disableVideo();
                } else {
                  enableVideo();
                }
              }}
            />
            <IconButton
              icon={<MdOutlineCallEnd />}
              fontSize={"1.75rem"}
              aria-label={""}
              minWidth={0}
              bg={"#e65a50"}
              border={"none"}
              height={"4rem"}
              width={"4rem"}
              padding={"0 !important"}
              borderRadius={"50%"}
              color={"white"}
              _hover={{
                transform: "translateY(0.25rem)",
                boxShadow: "0px 0px 1rem #e65a50",
              }}
              onClick={() => {
                endCall();
              }}
            />
          </Center>
        </Box>
      </Center>
    </>
  );
};

export default memo(CallModal);
