import { Flex, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { formatTime } from "../../services/dateFormater";

type ChatMessageProps = {
  message?: string;
  publishedAt?: string;
  firstName: string;
  lastName: string;
  role: string;
};

const ChatMessage: React.FC<ChatMessageProps> = ({
  firstName,
  lastName,
  message,
  publishedAt,
  role,
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      p="12px"
      bg="rgba(229, 233, 235, 1)"
      direction="column"
      w="max-content"
      borderRadius="8px"
      borderTopLeftRadius="none"
    >
      <Flex align="center" gap=".5rem">
        <Heading color="rgba(0, 38, 55, 1)" fontSize="14px" fontWeight={600}>
          {firstName} {lastName}
        </Heading>
        <Text color="rgba(0, 38, 55, 0.5)" fontSize="12px" fontWeight={400}>
          {role}
        </Text>
      </Flex>
      <Text
        color="rgba(0, 38, 55, 1)"
        fontSize="16px"
        fontWeight={400}
        fontFamily="secondary"
        maxW={"35rem"}
      >
        {message}
      </Text>
      <Text
        color="rgba(0, 38, 55, 0.5)"
        fontSize="12px"
        fontWeight={400}
        fontFamily="secondary"
        alignSelf="flex-end"
      >
        {new Date(
          new Date(publishedAt ?? "").getTime() -
            new Date().getTimezoneOffset() * 60 * 1000
        ).toLocaleTimeString(t("locale") ?? "en-GB", {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </Text>
    </Flex>
  );
};

export default ChatMessage;
