import {
  Image,
  Input,
  InputGroup,
  InputRightElement,
  InputProps,
} from "@chakra-ui/react";

import { FunctionComponent, useState } from "react";

import eye from "assets/icons/eye.svg";

interface PasswordInputProps extends InputProps {}

const PasswordInput: FunctionComponent<PasswordInputProps> = (props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <InputGroup>
      <Input
        type={showPassword ? "text" : "password"}
        _placeholder={{
          color: "rgba(0, 38, 55, 0.5)",
          fontSize: "16px",
        }}
        minH={{ base: "3rem", lg: "3.5rem !important" }}
        {...props}
      ></Input>
      <InputRightElement
        width="4.5rem"
        onClick={() => setShowPassword(!showPassword)}
      >
        <Image
          src={eye}
          mt={{ base: ".7rem", md: ".6rem", lg: "1.3rem" }}
          cursor="pointer"
        />
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInput;
