import check from "assets/icons/check.svg";
import { Link, useHistory } from "react-router-dom";
import { Center, Heading, Image, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const ResetFormSuccess = () => {
  const { t } = useTranslation();

  const history = useHistory();
  return (
    <>
      <Center
        flexDirection="column"
        w="full"
        py="6rem"
        px={{ base: "0rem", md: "4rem" }}
      >
        <Image src={check} mb="2rem" />
        <Heading
          color="rgba(0, 38, 55, 1)"
          fontWeight={600}
          fontSize="32px"
          letterSpacing={1.6}
          textAlign="center"
        >
          {t("Your request has been sent")}!
        </Heading>

        <Text
          fontFamily="secondary"
          color="rgba(0, 38, 55, 0.4)"
          fontWeight={400}
          fontSize="16px"
          textAlign="center"
          px={{ base: "1rem", sm: "0rem" }}
        >
          {t("Check your email and open the link we sent to continue")}.
        </Text>
      </Center>
      <Center mt="11rem">
        <Text
          color="rgba(0, 38, 55, 0.5)"
          fontSize="16px"
          fontWeight={700}
          fontFamily="secondary"
          textDecor="underline"
          onClick={() => history.goBack()}
          cursor="pointer"
        >
          <Link to="/admin-login-reset">{t("Back to Login")}</Link>
        </Text>
      </Center>
    </>
  );
};

export default ResetFormSuccess;
