import {
  Box,
  Center,
  VStack,
  Image,
  Heading,
  Button,
  Text,
  Flex,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import CrossFailedLargeC from "assets/images/CrossLargeC.svg";
import { useTranslation } from "react-i18next";
import { BsPerson } from "react-icons/bs";
import { useHistory } from "react-router-dom";

interface PaymentFailedProps {}

const PaymentFailed: FunctionComponent<PaymentFailedProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Center bg={"white"} w={"100vw"} flexGrow={1} mb={"-6vh"} pb={"5rem"}>
      <VStack spacing={"0.25rem"} gap={0}>
        <Image src={CrossFailedLargeC} mb={"2rem"} />
        <Heading fontSize={"40px"} fontWeight={500}>
          {t("Payment failed")}
        </Heading>
        <Heading fontWeight={400} fontSize={"md"} mb={"4rem !important"}>
          {t(
            "It looks like your order could not be paid at this time. Please try again."
          )}
        </Heading>
        <Button
          variant={"transparent"}
          color={"#589AAF"}
          border={"1px solid #589AAF"}
          fontSize={"stnd"}
          fontWeight={"700"}
          w={"7.5rem"}
          onClick={() => history.push("/")}
          _hover={{
            color: "white",
            bg: "blueish.500",
          }}
        >
          {t("Back")}
        </Button>
      </VStack>
      <Button
        position={"absolute"}
        bottom={{ base: "2rem", lg: "4rem" }}
        variant={"transparent"}
        color={"blueish.900"}
        _hover={{ color: "blueish.500" }}
        fontSize={"18px"}
        fontWeight={700}
        onClick={() => history.push("/login")}
      >
        <Flex alignItems={"center"} gap={"0.7rem"}>
          <BsPerson fontSize={"1.7rem"} />
          <Text
            fontSize={"inherit"}
            fontWeight={"inherit"}
            lineHeight={"1.1rem"}
            h={"1rem"}
            color={"inherit"}
          >
            {t("Log into your account")}
          </Text>
        </Flex>
      </Button>
    </Center>
  );
};

export default PaymentFailed;
