import { Flex, Text } from "@chakra-ui/react";
import DeliveryAddress from "features/settingsComponents/settingsDeliveryAdress";
import PersonalInformation from "features/settingsComponents/settingsPersonalInformation";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { t } = useTranslation();

  const date = new Date();
  return (
    <Flex direction="column" w="100%" gap="20px">
      <Flex
        w="100%"
        bg="white"
        borderRadius="0.75rem"
        p="2.5rem"
        direction="column"
        align="flex-start"
      >
        <PersonalInformation />
      </Flex>
      <Flex
        w="100%"
        bg="white"
        borderRadius="0.75rem"
        p="2.5rem"
        gap={4}
        direction="column"
        align="flex-start"
      >
        <DeliveryAddress />
      </Flex>

      <Flex
        w="100%"
        direction="column"
        mt="2.5rem"
        justify="flex-end"
        align="flex-end"
      >
        <Text fontSize="1rem" fontWeight={400}>
          {t("This account was created on")}{": "}
          {date.toLocaleDateString(t("locale") ?? "en-GB")}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Settings;
