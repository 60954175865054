import {
  AdminUserListRequest,
  MedicalHistoryResponseItemType,
  PrimitivePaginationType,
  TriageResponseType,
  UserById,
  UserChartResponse,
  UserNotesResponseType,
} from "features/admin/api/types";

import {
  APIClient,
  OnlyResponseAPIClient,
} from "./../../../services/api/client";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import useSWRInfinite from "swr/infinite";

import { AdminLoginResponse } from "./types";
import { useTokenStore } from "services/state/tokenStore";

export const useAdminLogin = () => {
  const { setAccess } = useTokenStore();

  return useSWRMutation<AdminLoginResponse>(
    `admin/login/`,
    (url: string, { arg }: { arg: { username: string; password: string } }) =>
      OnlyResponseAPIClient.post<any, AdminLoginResponse>(url, {
        username: arg.username,
        password: arg.password,
      }),
    {
      onSuccess: (response) => {
        setAccess(response.access ?? "");
      },
      onError(error) {
        console.log("Can not login", error);
      },
    }
  );
};

export const useAdminUserInfo = (id: string) => {
  return useSWR<UserById, Error>(
    `admin/users/${id}/`,
    (url: string) => APIClient.get(url),
    {
      isPaused: () => !id || id.trim() === "",
      shouldRetryOnError: false,
    }
  );
};

export const useUserListFiltered = (
  page?: number,
  limit?: number,
  filters?: {
    status?: string;
    name?: string;
    medicineType?: string;
    medication_id?: string;
    mood?: string[];
    appUsage?: string;
    startingDosageFrom?: number;
    startingDosageTo?: number;
    currentDoseFrom?: number;
    currentDoseTo?: number;
    taperingPlanDurationFrom?: number;
    taperingPlanDurationTo?: number;
    excludeTestUsers: boolean;
    selectedDoctors?: string;
    nurses?: string;
    unassigned?: string;
    sorting?: string;
    is_archived?: string;
    is_declined?: string;
  }
) => {
  const {
    status,
    name,
    medicineType,
    mood,
    appUsage,
    startingDosageFrom,
    startingDosageTo,
    currentDoseFrom,
    currentDoseTo,
    taperingPlanDurationFrom,
    taperingPlanDurationTo,
    excludeTestUsers,
    selectedDoctors,
    nurses,
    unassigned,
    sorting,
    is_archived,
    is_declined,
    medication_id,
  } = filters || {};

  const queryParams = new URLSearchParams();
  page && queryParams.set("page", page.toString());
  limit && queryParams.set("limit", limit.toString());
  status && queryParams.set("status", status);
  name && queryParams.set("name", name);
  medicineType && queryParams.set("medicine_type", medicineType);
  medication_id && queryParams.set("medication_id", medication_id);
  mood?.forEach((m) => queryParams.append("mood", m));
  appUsage && queryParams.set("app_usage", appUsage);

  if (startingDosageFrom) {
    queryParams.set(`startingDosageFrom`, startingDosageFrom.toString());
  }

  if (startingDosageTo) {
    queryParams.set(`startingDosageTo`, startingDosageTo.toString());
  }

  if (currentDoseFrom) {
    queryParams.set(`currentDoseFrom`, currentDoseFrom.toString());
  }

  if (currentDoseTo) {
    queryParams.set(`currentDoseTo`, currentDoseTo.toString());
  }

  if (taperingPlanDurationFrom) {
    queryParams.set(
      `taperingPlanDurationFrom`,
      taperingPlanDurationFrom.toString()
    );
  }

  if (taperingPlanDurationTo) {
    queryParams.set(
      `taperingPlanDurationTo`,
      taperingPlanDurationTo.toString()
    );
  }

  if (excludeTestUsers) {
    queryParams.set("excludeTestUsers", excludeTestUsers.toString());
  }

  if (selectedDoctors) {
    queryParams.set("doctors", selectedDoctors);
  }

  if (nurses) {
    queryParams.set("nurses", nurses);
  }

  if (unassigned) {
    queryParams.set("unassigned", unassigned);
  }

  if (is_archived) {
    queryParams.set("is_archived", is_archived);
  }

  if (is_declined) {
    queryParams.set("is_declined", is_declined);
  }

  if (sorting) {
    queryParams.set("order", sorting);
  }

  const queryString = queryParams.toString();
  const url = `admin/users/`;

  return useSWR<AdminUserListRequest>(
    [url, queryString],
    ([url, queryString]) => APIClient.get(url + "?" + queryString)
  );
};

export const useRequestUserPrescriptionPDF = (userId: string) => {
  return useSWRMutation<any>(`admin/file?user=${userId}`, (url: string) =>
    APIClient.post(
      url,
      {},
      {
        responseType: "blob",
      }
    )
  );
};

export const useUserChart = (userId: string) => {
  return useSWR<UserChartResponse, Error>(
    `admin/charts/${userId}`,
    (url: string) => APIClient.get(url)
  );
};

export const useRequestUserTaperingPDF = (userId: string) => {
  return useSWRMutation<any>(
    `admin/tapering/file?user=${userId}`,
    (url: string) =>
      APIClient.post(
        url,
        {},
        {
          responseType: "blob",
        }
      )
  );
};

export const useSendUserPrescriptionPDF = (userId: string) => {
  return useSWRMutation<any>(
    `admin/prescription/send?user=${userId}`,
    (url: string) => APIClient.get(url)
  );
};

export const useChangeStartTreatmentDate = (userId: string) => {
  const { mutate } = useAdminUserInfo(userId);

  return useSWRMutation<any, any>(
    `admin/treatment/adjust/${userId}/`,
    (url: string, { arg }: { arg: { date: string } }) => {
      return APIClient.put(url, arg).then(() => {
        mutate();
      });
    },
    {
      onSuccess: (data) => {
        mutate();
      },
    }
  );
};

export const useAdminChangeTreatmentduration = (userId: string) => {
  const { mutate } = useAdminUserInfo(userId);

  return useSWRMutation<any, any>(
    `admin/treatment/adjust/duration/${userId}/`,
    (url: string, { arg }: { arg: { duration: number } }) => {
      return APIClient.put(url, {
        treatment_duration: arg.duration,
      }).then(() => {
        mutate();
      });
    },
    {
      onSuccess: (data) => {
        mutate();
      },
    }
  );
};

export const useRejectTreatmentPlan = (userId: string) => {
  const { mutate } = useAdminUserInfo(userId);

  return useSWRMutation<any, any>(
    `admin/user/decline/access/${userId}`,
    (url: string, { arg }: { arg: { reason: string } }) => {
      return APIClient.put(url, {
        reject_comment: arg.reason,
      });
    },
    {
      onSuccess: (data) => {
        mutate();
      },
    }
  );
};

export const useApproveTreatmentPlan = (userId: string) => {
  const { mutate } = useAdminUserInfo(userId);

  return useSWRMutation<any, any>(
    `/admin/user/approve/access/${userId}`,
    (url: string) => {
      return APIClient.put(url);
    },
    {
      onSuccess: (data) => {
        mutate();
      },
    }
  );
};

export const useCreateNote = (userId: string) => {
  const { mutate } = useFetchUserNotes(userId);

  return useSWRMutation<any, any>(
    `/admin-notes/`,
    (url: string, { arg }: { arg: { text: string } }) => {
      return APIClient.post(url, {
        text: arg.text,
        user: userId,
      }).then(() => {
        mutate();
      });
    },
    {
      onSuccess: (data) => {
        mutate();
      },
    }
  );
};

export const useFetchUserTriageData = (userId: string) => {
  return useSWR<TriageResponseType>(
    `/admin/user/typeform/${userId}`,
    (url: string) => APIClient.get(url)
  );
};

export const useClearUserRequiest = (username: string, userId: string) => {
  const { mutate } = useAdminUserInfo(userId);

  return useSWRMutation<any, any>(
    `clean/test-user/${username}/`,
    (url: string) => {
      return APIClient.get(url).then(() => {
        mutate();
      });
    },
    {
      onSuccess: (data) => {
        mutate();
      },
    }
  );
};

export const useSaveFileUrl = (userId: string, type: string = "type_form") => {
  return useSWRMutation<any, any>(
    `signet-document/`,
    (url: string, { arg }: { arg: { file: string } }) => {
      return APIClient.post(url, { file: arg.file, type: type, user: userId });
    }
  );
};

export const useFetchUserNotes = (userId: string) => {
  const { access } = useTokenStore();

  return useSWR<UserNotesResponseType>(
    [`/admin-notes/?user=${userId}`, access, userId],
    (url: string[]) => APIClient.get(url[0])
  );
};

export const useFetchMedicalHistory = (userId: string, page = 1) => {
  return useSWR<PrimitivePaginationType<MedicalHistoryResponseItemType>>(
    `/medical-history/?user=${userId}&page=${page}&size=10`,
    (url) => APIClient.get(url)
  );
};

export const useFetchUserLoggedEvents = (userId: string) => {
  const getKey = (pageIndex: number) =>
    `/medical-history/?user=${userId}&page=${pageIndex + 1}&size=10`;

  const { data, error, size, setSize, isLoading } = useSWRInfinite<
    PrimitivePaginationType<MedicalHistoryResponseItemType>
  >(getKey, (url) => APIClient.get(url));

  const allData = data ? data.flat() : [];
  const hasNextPage = data && data.length === size;

  return {
    medicalHistory: allData,
    error,
    isLoading,
    hasNextPage,
    loadMore: () => setSize(size + 1),
  };
};
