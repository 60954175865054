import { Spinner } from "@chakra-ui/react";
import { Widget } from "@typeform/embed-react";
import { useSendTypeformData } from "features/prescriptionForm/api/client";

import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSWRConfig } from "swr";
import PrescriptionPopup from "../prescriptionPopup";
import { useFetchProfileInfo } from "features/dashboard/api/client";

interface MemoizedWidgetProps {}

const MemoizedWidget: React.FunctionComponent<MemoizedWidgetProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { trigger } = useSendTypeformData();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { mutate } = useFetchProfileInfo();

  const handleSubmit = ({ responseId }: { responseId: string }) => {
    console.log("form submitted, response has id", responseId);

    trigger(
      {
        form_id: `${t("prescription_form_id")}`,
        response_id: responseId,
      },
      {
        onSuccess: () => {
          mutate().then(() => {
            history.push("/dashboard");
          });
        },
      }
    );
    setIsLoading(true);
  };

  return (
    <>
      {!isLoading && (
        <Widget
          id={t("prescription_form_id")}
          style={{
            width: "100%",
            height: "100%",
            fontFamily: "secondary",
          }}
          keepSession={true}
          /* onEndingButtonClick={() => {
          history.push("/dashboard");
        }} */
          onSubmit={handleSubmit}
        />
      )}
      {isLoading && <Spinner />}
    </>
  );
};

export default React.memo(MemoizedWidget);
