import { Box } from "@chakra-ui/layout";
import { Image, useBreakpoint, useBreakpointValue } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import logo from "assets/images/logo_square.svg";
import { useHistory, useLocation } from "react-router-dom";

interface ContactBubbleProps {}

const ContactBubble: FunctionComponent<ContactBubbleProps> = () => {
  const history = useHistory();
  const location = useLocation();

  const currentBreakpoint = useBreakpoint();

  const show = !(
    currentBreakpoint === "base" &&
    (location.pathname === "/prescription" ||
      location.pathname === "/choose-treatment-duration")
  );

  return (
    <>
      {show && (
        <Box
          borderRadius={"50%"}
          bg={"blueish.500"}
          position={"fixed"}
          bottom={"2rem"}
          right={"2rem"}
          zIndex={9999999}
          p={"0.65rem"}
          cursor={"pointer"}
          _hover={{
            transform: "scale(1.1)",
          }}
          transition={"all 0.3s ease-out"}
          onClick={() => {
            history.push("/faq#contact-form");
          }}
        >
          <Image src={logo} h={"2.5rem"} />
        </Box>
      )}
    </>
  );
};

export default ContactBubble;
