import React, {
  createContext,
  useState,
  useRef,
  useEffect,
  FunctionComponent,
  useCallback,
} from "react";

import { ZegoCallsOmnipresentContextType } from "features/ZegoCallsOmnipresentContext/api/types";
import { useLocalZegoCalls } from "features/zegoCalls/hooks/useLocalZegoCalls";
import Draggable from "react-draggable";
import { Box, Button, Flex, HStack } from "@chakra-ui/react";
import { UserById } from "features/admin/api/types";
import { invokeConfirmationModal } from "features/genericModal";
import { useTranslation } from "react-i18next";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import classes from "./style.module.scss";
import { MeetingLogItemResponseType } from "features/staffCommons/api/types";

const ZegoCallsOmnipresentContext =
  createContext<ZegoCallsOmnipresentContextType>({
    callUser: (pateintInfo) => {},
    callUserByMeeting: (patientInfo) => {},
    isCallOngoing: false,
    callId: "",
  });

interface ContextProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

const ZegoCallsOmnipresentContextProvider: FunctionComponent<
  ContextProviderProps
> = ({ children }) => {
  const [isCallOngoing, setIsCallOngoing] = useState<boolean>(false);
  const [callId, setCallid] = useState<string>();

  const boxRef = useRef(null);
  const { t } = useTranslation();
  const { data: profileData } = useFetchProfileInfo();

  const resetCallback = function () {
    setIsCallOngoing(() => false);
    setCallid(undefined);
  };

  const { callUser } = useLocalZegoCalls(boxRef, {
    onEndCall: () => {
      resetCallback();
      setIsCallOngoing(false);
    },
    onError: () => {
      resetCallback();
      setIsCallOngoing(false);
    },
  });

  const hanldeOnCall = (
    patientInfo: UserById,
    callId?: string,
    callbacks?: {
      onCallEnd?(): void;
    }
  ) => {
    const callback = async () => {
      await setIsCallOngoing(true);
      await setCallid(callId);
      await callUser(
        {
          id: profileData?.id!,
          name: profileData?.first_name + " " + profileData?.last_name,
        },
        {
          id: patientInfo.id,
          name: patientInfo?.first_name + " " + patientInfo?.last_name,
        },
        {
          onCallEnd() {
            callbacks?.onCallEnd?.();
          },
        }
      );
    };

    if (patientInfo?.payment_status === "PENDING") {
      invokeConfirmationModal(
        t(
          "This user has not completed their payment, would you like to proceed?"
        ),
        callback,
        t("CALL NOT PAID")!,
        t("CONTINUE")!
      );
    } else if (!patientInfo?.payment_status) {
      invokeConfirmationModal(
        t("This user has not booked any calls, would you like to proceed?"),
        callback,
        t("CALL NOT BOOKED")!,
        t("CONTINUE")!
      );
    } else {
      callback();
    }
  };

  const hanldeOnCallByMeeting = (
    patientInfo: UserById,
    meetingsList: MeetingLogItemResponseType[],
    callId?: string,
    callbacks?: {
      onCallEnd?(): void;
    }
  ) => {
    const callback = async () => {
      await setIsCallOngoing(true);
      await setCallid(callId);
      await callUser(
        {
          id: profileData?.id!,
          name: profileData?.first_name + " " + profileData?.last_name,
        },
        {
          id: patientInfo.id,
          name: patientInfo?.first_name + " " + patientInfo?.last_name,
        },
        {
          onCallEnd() {
            callbacks?.onCallEnd?.();
          },
        }
      );
    };

    if (meetingsList?.length === 0) {
      invokeConfirmationModal(
        t("This user has not booked any calls, would you like to proceed?"),
        callback,
        t("CALL NOT BOOKED")!,
        t("CONTINUE")!
      );
    } else if (
      !meetingsList.some((e) => e.payment && e.payment.status === "PAID")
    ) {
      invokeConfirmationModal(
        t(
          "This user has not completed their payment, would you like to proceed?"
        ),
        callback,
        t("CALL NOT PAID")!,
        t("CONTINUE")!
      );
    } else {
      callback();
    }
  };

  return (
    <>
      <ZegoCallsOmnipresentContext.Provider
        value={{
          isCallOngoing: isCallOngoing,
          callUser: hanldeOnCall,
          callUserByMeeting: hanldeOnCallByMeeting,
          callId: callId ?? "",
        }}
      >
        <Flex gap={"0"} alignItems={"start !important"}>
          <Box
            display={isCallOngoing ? "block" : "none"}
            zIndex={10}
            minW={"33vw"}
            height={"100vh"}
            //bg={"gray.100"}
            overflow={"hidden"}
            boxShadow={"0px 0px 0.5rem 0.5rem rgba(0, 0, 0, 0.05)"}
            position={"sticky"}
            top={0}
            bg={""}
          >
            <Box
              ref={boxRef}
              w={"100%"}
              h={"100%"}
              className={classes.zegoContainer}
            ></Box>
            {/* <IconButton
            top={"1rem"}
            right={"1rem"}
            position={"absolute"}
            icon={<IoMdClose />}
            aria-label={""}
            minWidth={"0 !important"}
            fontSize={"1.5rem"}
            onClick={() => {
              onCallEnd();
            }}
            px={"1rem"}
            py={"1rem"}
            bg={"rgba(255, 255, 255, 0.25)"}
            zIndex={1000}
            border={"none"}
          ></IconButton> */}
          </Box>
          <Box flexGrow={1}> {children}</Box>
        </Flex>
      </ZegoCallsOmnipresentContext.Provider>

      {/* <Draggable>

      </Draggable> */}
    </>
  );
};

export { ZegoCallsOmnipresentContextProvider, ZegoCallsOmnipresentContext };
