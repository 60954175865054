import { Box, Flex } from "@chakra-ui/react";
import FaqDiscoverOne from "features/faqInfo/components/faqDiscoverOne";
import FaqDiscoverTwo from "features/faqInfo/components/faqDiscoverTwo";
import FaqDiscoverThree from "features/faqInfo/components/faqDiscoverThree";

import { useParams } from "react-router-dom";

const FaqDiscover = () => {
  const { id } = useParams<{ id: string }>();

  const faqDiscoverComponents: { [key: string]: JSX.Element } = {
    discover1: <FaqDiscoverOne />,
    discover2: <FaqDiscoverTwo />,
    discover3: <FaqDiscoverThree />,
  };

  return (
    <Flex direction="column" minH="100vh">
      <Box bg="white" minH="100vh" w="full">
        {faqDiscoverComponents[id] || <></>}
      </Box>
    </Flex>
  );
};

export default FaqDiscover;
