import { Circle, HStack } from "@chakra-ui/react";
import { FunctionComponent } from "react";

interface MoodComponentProps {
  moodValues: number[];
}

const MoodComponent: FunctionComponent<MoodComponentProps> = ({
  moodValues,
}) => {
  const getCircleColor = (mood: number) => {
    switch (mood) {
      case 1:
        return "#43B3BA";
      case 2:
        return "#589AAF";
      case 3:
        return "#568CB2";
      case 4:
        return "#647CAD";
      case 5:
        return "#796A9F";
      case 6:
        return "#8B5687";
      case 7:
        return "#944366";
      default:
        return "gray.500";
    }
  };

  const showAllMood =
    moodValues.length >= 4 && moodValues.every((m) => m >= 5);

  return (
    <HStack
      p={showAllMood ? "5px" : "none"}
      borderRadius={showAllMood ? "50px" : "none"}
      border={showAllMood ? "1px solid rgba(148, 67, 102, 1)" : "none"}
      spacing="0.25rem"
    >
      {moodValues.map((e) => {
        return (
          <Circle
            size={"1.5rem"}
            bg={getCircleColor(e)}
            color={"white"}
            fontWeight={"800"}
            lineHeight={"1.5rem"}
          >
            {e}
          </Circle>
        );
      })}
    </HStack>
  );
};

export default MoodComponent;
