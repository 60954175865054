import { Flex, Image, Heading, Text, Box } from "@chakra-ui/react";

import pills_1 from "assets/icons/pills_1.svg";
import { useDashboardInfo } from "features/dashboard/api/client";
import { useTranslation } from "react-i18next";

const UserMedication = () => {
  const { t } = useTranslation();
  const { data } = useDashboardInfo();

  return (
    <Flex
      bg="white"
      p="2.5rem"
      borderRadius="12px"
      w={{ base: "100%", sm: "100%" }}
      direction="column"
      gap={2.5}
    >
      <Box display={{ base: "none", sm: "block" }}>
        <Image src={pills_1} alt={"pills icon"} />
      </Box>
      <Box>
        <Heading color={"blueish.500"} fontSize={"20px"} fontWeight={500}>
          {t("MEDICATION TYPE")}
        </Heading>
        <Text color={"blackish.200"} fontSize={"24px"} fontWeight={400}>
          {data?.medication?.name}
        </Text>
        <Text color={"blackish.200"} fontSize={"16px"}>
          {data?.medication?.dose ?? 0} {t("milligram (mg) per day")}
        </Text>
      </Box>
    </Flex>
  );
};

export default UserMedication;
