import { Image, Heading, Text, Box, Flex, Center } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import phone from "assets/images/phone.png";

import app_store_1 from "../../../../assets/icons/app_store_1.svg";
import google_play from "../../../../assets/images/google-play-badge.svg";
import { triggerAnnouncementModal } from "features/genericModal";
import AnimatedText from "ui/components/AnimatedText";

const HomeContactUsMobile = () => {
  const { t } = useTranslation();

  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      w={"100%"}
      px={"15%"}
      py={"10%"}
      gap={"0.5em"}
      borderRadius={["lg", "0px", "0px"]}
      direction={"column"}
      mb={"2rem !important"}
    >
      <Flex direction={"column"} w={"100%"} alignItems={"center"}>
        <Heading
          variant={"light"}
          fontSize={{ base: "48px", sm: "auto" }}
          lineHeight={"52px"}
          mb={"12px"}
          textAlign={"center"}
        >
          <AnimatedText>{t("contactUsHeaderMobile")}</AnimatedText>
        </Heading>
        <Text
          fontSize={"md"}
          mb={"32px"}
          textAlign={"center"}
          whiteSpace={"pre-line"}
        >
          {/*  Vår tjäns lanseras inom kort. */}
          {t("contactUsDiscription")}
        </Text>
      </Flex>

      <Image
        src={phone}
        alt="Phone Icon"
        w={"100%"}
        minW={"200px"}
        maxW={"300px"}
        mb={"2rem"}
      />

      <Flex
        align="center"
        gap={5}
        direction={["column", "row", "row"]}
        w={"100%"}
      >
        <Flex
          flexDirection="column"
          gap={"1rem"}
          w={"100%"}
          alignItems={"center"}
        >
          <Center flexDirection={"column"} gap={"1rem"}>
            <Box width={"100%"}>
              <Image
                src={app_store_1}
                alt={"App Store app link"}
                minW={"200px"}
                cursor={"pointer"}
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/us/app/lamina-clinic/id6448011545",
                    "_blank"
                  );
                }}
              />
            </Box>

            <Box width={"100%"}>
              <Image
                src={google_play}
                alt={"Play Market app link"}
                minW={"200px"}
                cursor={"pointer"}
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.laminaclinic.laminaclinic",
                    "_blank"
                  );
                }}
              />
            </Box>
          </Center>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HomeContactUsMobile;
