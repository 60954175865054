import { useEffect, useState } from "react";

const useGoogleAnalytics = () => {
  const pageView = (path: string) => {
    (window as any).gtag("event", "page_view", { page_path: path });
  };

  const basicEvent = (category: string, action: string) => {
    console.log(window);
    (window as any).gtag("event", category, { action: action });
  };

  const downloadEvent = (from: string) => {
    (window as any).gtag("event", "download", { action: from });
  };

  const loginEvent = (method = "BankID") => {
    (window as any).gtag("event", "login", { method: method });
  };

  const beginCheckoutEvent = (
    currency: string,
    value: number,
    item: string
  ) => {
    (window as any).gtag("event", "begin_checkout", {
      currency: currency,
      value: value,
      items: [
        {
          item_id: "",
          item_name: item,
        },
      ],
    });
  };

  const purchaseEvent = (
    currency: string,
    transaction_id: string,
    value: string,
    item: string
  ) => {
    (window as any).gtag("event", "purchase", {
      currency: currency,
      transaction_id: transaction_id,
      value: value,
      items: [
        {
          item_id: "",
          item_name: item,
        },
      ],
    });
  };

  return {
    pageView,
    basicEvent,
    loginEvent,
    downloadEvent,
    beginCheckoutEvent,
    purchaseEvent,
  };
};

export default useGoogleAnalytics;
