import {
  Button,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useLogOut } from "features/login/api/client";
import Notifications from "features/notifications/notificationMain";

import logo_group from "assets/icons/Logo.svg";
import logout_black from "assets/icons/logout_black.svg";
import dashboard_blueish from "assets/icons/dashboard_blueish.svg";
import { useTranslation } from "react-i18next";
import { useFetchProfileInfo } from "features/dashboard/api/client";
import ChatList from "features/doctors/components/ChatDrawer/components/ChatList/ChatList";
import { invokeBasicInfoModal } from "features/genericModal";
import { useEffect, useRef } from "react";
import { MdOutlineQueryStats } from "react-icons/md";
import React from "react";

const SuperAdminNavbar = ({ userId }: { userId: string }) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { trigger } = useLogOut();
  const location = useLocation();

  const { data: userData } = useFetchProfileInfo();

  const logOutFunction = () => {
    trigger();
    history.push("/");
  };

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const handleModalOpen = () => {
    if (location.pathname === "/prescription") {
      invokeBasicInfoModal(
        `${t(
          "If you switch the language, unfortunately, the entire prescription process will not be saved. Therefore, the prescription needs to be completed in one language."
        )}`,
        `${t("Sorry")}`
      );
    }
  };

  const roleTranslations = {
    doctor: t("Doctor"),
    nurse: t("Nurses"),
    superadmin: t("superadmin"),
  };

  return (
    <Flex
      w="100%"
      overflow={"hidden"}
      align="center"
      justify="space-between"
      direction={{ base: "column", sm: "column", md: "row" }}
      gap={{ base: ".625rem", sm: "none" }}
    >
      <Image
        src={logo_group}
        alt="logo_group"
        h="3rem"
        cursor={"pointer"}
        onClick={() => {
          if (userData?.role === "doctor") {
            history.push("/doctor");
          } else if (userData?.role === "nurse") {
            history.push("/nurse");
          } else if (userData?.role === "superadmin") {
            history.push("/superadmin");
          } else {
            history.push("/");
          }
        }}
      />

      <Flex gap={1}>
        <Text
          color="rgba(0, 38, 55, 1)"
          fontSize={{ base: "", md: "1rem", lg: "1.125rem" }}
        >
          {t("Role")}:
        </Text>
        <Text
          color="rgba(0, 38, 55, 0.8)"
          fontSize={{ base: "", md: "1rem", lg: "1.125rem" }}
          fontWeight={700}
          textTransform={"capitalize"}
        >
          {userData
            ? roleTranslations[
                userData.role as keyof typeof roleTranslations
              ] || t("superadmin")
            : t("superadmin")}
        </Text>
      </Flex>

      <Flex
        w={{ base: "100%", sm: "100%", md: "auto" }}
        align="center"
        justify={{ base: "", sm: "space-between" }}
        gap={{ base: "2.25rem", sm: "2.375", md: "2.5rem", lg: "3rem" }}
        direction={{ base: "column", sm: "row" }}
      >
        <Popover
          arrowShadowColor="transparent"
          returnFocusOnClose={true}
          initialFocusRef={ref}
          closeOnBlur={true}
        >
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Button
                  variant="ghost"
                  color={"black"}
                  onClick={handleModalOpen}
                  ref={ref}
                >
                  {i18n.language.toUpperCase()}
                </Button>
              </PopoverTrigger>
              <PopoverContent zIndex={4} bg={"#FFF"} width={"80px"}>
                <PopoverArrow
                  backgroundColor={"white"}
                  border="1px"
                  borderBottomColor={"transparent"}
                  borderRightColor={"transparent"}
                  borderLeftColor={"#e4dada "}
                  borderTopColor={"#e4dada "}
                />
                {/*  */}
                <PopoverBody
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Button
                    onClick={() => handleLanguageChange("en")}
                    variant="ghost"
                    color={"black"}
                  >
                    EN
                  </Button>
                  <Button
                    onClick={() => handleLanguageChange("sv")}
                    variant="ghost"
                    color={"black"}
                  >
                    SV
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
        {/* <Flex gap={2}>
          <Image src={dashboard_blueish} alt={"dashboard icon"} mt="-2px" />
          <Text
            color="rgba(88, 154, 175, 0.8)"
            fontSize={{ base: "", md: "1rem", lg: "1.125rem" }}
            fontWeight={700}
          >
            <Link to="/dashboard">{t("Dashboard")}</Link>
          </Text>
        </Flex> */}
        <ChatList />

        <Notifications />

        {(userData?.role === "admin" || userData?.role === "superadmin") && (
          <Flex
            gap={2}
            align="center"
            onClick={() => {
              history.push("/superadmin/reports");
            }}
            cursor="pointer"
          >
            <MdOutlineQueryStats />
            <Text
              color="rgba(0, 38, 55, 0.9)"
              fontWeight={700}
              fontSize={{ base: "", md: "1rem", lg: "1.125rem" }}
              whiteSpace="nowrap"
            >
              {t("Statistics")}
            </Text>
          </Flex>
        )}

        <Flex gap={2} align="center">
          <Image src={logout_black} alt="Log out icon" />
          <Text
            color="rgba(0, 38, 55, 0.9)"
            fontWeight={700}
            fontSize={{ base: "", md: "1rem", lg: "1.125rem" }}
            onClick={logOutFunction}
            cursor="pointer"
            whiteSpace="nowrap"
          >
            {t("Logout")}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SuperAdminNavbar;

// export default React.memo(
//   SuperAdminNavbar,
//   (prevProps, nextProps) => prevProps.userId === nextProps.userId
// );
