import {
  Button,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IoMdMore } from "react-icons/io";
import prescriptionIcon from "assets/icons/prescription.svg";
import bulletListIcon from "assets/icons/bulletlist.svg";
import testuserIcon from "assets/icons/testuser.svg";
import stopIcon from "assets/icons/stop.svg";
import PrescriptionHelper from "features/prescriptionHelper/components/PrescriptionHelperModal";
import TriageModal from "features/admin/components/TriageModal";
import { usePatchAdminUserProfile } from "features/dashboard/api/client";
import { useAdminUserInfo } from "features/admin/api/client";
import { invokeConfirmationModal } from "features/genericModal";
import { toast } from "react-toastify";
import { IoCheckmarkSharp } from "react-icons/io5";

interface MoreButtonProps {
  patientId: string;
}

const MoreButton: FunctionComponent<MoreButtonProps> = ({ patientId }) => {
  const { t } = useTranslation();

  const { data: patientData } = useAdminUserInfo(patientId);

  const { trigger: patchUserProfile, isMutating: isPatchingProfile } =
    usePatchAdminUserProfile(patientId);

  const {
    trigger: patchUserProfileForTreatment,
    isMutating: isPatchingProfileForTreatment,
  } = usePatchAdminUserProfile(patientId);

  const {
    isOpen: isPrescriptionHelperOpen,
    onOpen: onPrescriptionHelperOpen,
    onClose: onPrescriptionHelperClose,
  } = useDisclosure();

  const {
    isOpen: isTriageOpen,
    onOpen: onTriageOpen,
    onClose: onTriageClose,
  } = useDisclosure();

  const onSetAsTestUserClick = () => {
    patchUserProfile({ is_test_user: !patientData?.is_test_user });
  };

  const changeTreatmentStatusRequest = () => {
    patchUserProfileForTreatment(
      {
        is_treatment_declined: !patientData?.is_treatment_declined,
      },
      {
        onSuccess: () => {
          toast.info(
            `User has been ${
              patientData?.is_treatment_declined ? "" : "un"
            }approved for treatment`
          );
        },
        onError: () => {
          toast.error("Something went wrong, please try again later");
        },
      }
    );
  };

  const handleDeclineApprovePatientsTreatment = () => {
    invokeConfirmationModal(
      (!patientData?.is_treatment_declined
        ? t("You are about to decline patients treatment")
        : t("You are about to approve patient for treatment")) +
        ", " +
        t("do you wish to proceed"),
      changeTreatmentStatusRequest
    );
  };

  return (
    <>
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <Button
            variant="outline"
            borderColor="rgb(88,154,175)"
            color="#589AAF"
            fontSize="1rem"
            h={"100%"}
            _hover={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transform: "scale(1.02)",
              transition: "0.3s",
            }}
            leftIcon={<IoMdMore fontSize={"1.5rem"} />}
            pl={"0.5rem !important"}
          >
            {t("More")}
          </Button>
        </PopoverTrigger>
        <PopoverContent border={"1px solid"} borderColor={"blueish.500"} p={0}>
          <PopoverBody p={"0 !important"}>
            <Stack alignItems={"stretch"} spacing={"0.5rem"} py={"1rem"}>
              <Button
                variant={"unstyled"}
                fontSize={"1rem"}
                leftIcon={<Image src={prescriptionIcon} />}
                display={"inline-flex"}
                justifyContent={"flex-start"}
                iconSpacing={"0.5rem"}
                pl={"2rem !important"}
                py={"1rem !important"}
                onClick={() => {
                  onPrescriptionHelperOpen();
                }}
              >
                {t("Prescription Helper")}
              </Button>

              <Button
                variant={"unstyled"}
                fontSize={"1rem"}
                leftIcon={<Image src={bulletListIcon} />}
                display={"inline-flex"}
                justifyContent={"flex-start"}
                iconSpacing={"0.5rem"}
                pl={"2rem !important"}
                py={"1rem !important"}
                onClick={onTriageOpen}
              >
                {t("See triage")}
              </Button>
              <Button
                variant={"unstyled"}
                fontSize={"1rem"}
                leftIcon={<Image src={testuserIcon} />}
                display={"inline-flex"}
                justifyContent={"flex-start"}
                iconSpacing={"0.5rem"}
                pl={"2rem !important"}
                py={"1rem !important"}
                onClick={onSetAsTestUserClick}
                textTransform={"capitalize"}
                isLoading={isPatchingProfile}
              >
                {patientData?.is_test_user
                  ? t("Set default user")
                  : t("Set test account")}
              </Button>
              <Button
                variant={"unstyled"}
                fontSize={"1rem"}
                leftIcon={
                  !patientData?.is_treatment_declined ? (
                    <Image src={stopIcon} />
                  ) : (
                    <IoCheckmarkSharp />
                  )
                }
                display={"inline-flex"}
                justifyContent={"flex-start"}
                iconSpacing={"0.5rem"}
                pl={"2rem !important"}
                py={"1rem !important"}
                color={
                  !patientData?.is_treatment_declined ? "#DE2453" : "green.700"
                }
                textTransform={"capitalize"}
                onClick={handleDeclineApprovePatientsTreatment}
                isLoading={isPatchingProfileForTreatment}
              >
                {!patientData?.is_treatment_declined
                  ? t("Decline patients treatment")
                  : t("Approve patients treatment")}
              </Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <PrescriptionHelper
        isOpen={isPrescriptionHelperOpen}
        onClose={onPrescriptionHelperClose}
      />

      <TriageModal isOpen={isTriageOpen} onClose={onTriageClose} />
    </>
  );
};

export default MoreButton;
